import { useEffect, useState, useRef, useCallback } from "react";
import { usePostHog } from "posthog-js/react";

import AvatarSpeechData from "../Classes/AvatarSpeechData";
import AvatarGptData from "../Classes/AvatarGptData";
import UserTranscriptData from "../Classes/UserTranscriptData";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { sleep } from "../UtilityFunctions/sleep.js";
import { CreateEventHistory } from "../UtilityFunctions/CreateEventHistory.js";
import usePollingIntervals from "../UtilityFunctions/usePollingIntervals"; // Assuming you've placed the hook in a hooks folder

const toWav = require("audiobuffer-to-wav");

function wordsPerMinute(string, seconds) {
	// count the number of words in the string
	let wordCount;

	if (string === null || string === undefined) {
		wordCount = 0;
	} else {
		wordCount = string.split(" ").length;
	}

	// convert duration from seconds to minutes
	const minutes = seconds / 60;

	// calculate words per minute
	const wpm = wordCount / minutes;

	return { wpm, wordCount, minutes };
}

function rmsToDb(rms) {
	return 20 * Math.log10(rms);
}

function dbToRms(db) {
	return 10 ** (db / 20);
}
const STATES = {
	SILENCE: "SILENCE",
	VOICE: "VOICE"
};
function useLiveData({
	userLanguageRef,

	audioContext,
	sendMessage2,
	userMediaStreamRef,
	user,
	localUser,
	activeSessionDataRef,
	setUserSpeaking,
	OnVoiceStart = () => {},
	OnVoiceEnd = () => {},
	characterNameRef,
	saveAudio,
	setAllowSimControls,
	handleDisconnect = () => {}
}) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;
	const isTalkingRef = useRef(false);
	const vadRef = useRef(STATES.SILENCE);
	const posthog = usePostHog();
	// use effect
	// useEffect(() => {
	// 	console.log("useEffect audioContext livedata", audioContextProp);
	// 	if (audioContextProp) {
	// 		audioContext.current = audioContextProp;
	// 	}
	// }, [audioContextProp]);

	const deepGramOnRef = useRef(false);
	// Reference to the microphone
	const microphoneRef = useRef(null);
	const [microphoneStarted, setMicrophoneStarted] = useState(false);

	const audioBitsRef = useRef([]);
	const RecordingBufferRef = useRef([]);

	const scriptNodeRef = useRef(null);
	// References to avatar speech data and GPT data

	const mediaRecorderRef = useRef(null);
	const intervalRef = useRef(null);
	const partCountsRef = useRef(0);

	const avatarSpeechDataRef = useRef({});
	const avatarGptDataRef = useRef({});
	const userTranscriptDataRef = useRef({});

	const speechDeltaTrackerRef = useRef({
		added: {},
		modified: {}
	});
	const gptDeltaTrackerRef = useRef({
		added: {},
		modified: {}
	});
	const transcriptDeltaTrackerRef = useRef({
		added: {},
		modified: {}
	});

	async function finishGptEvent(gptId, { functionOutput, promptLayer_request_id, metadata }) {
		console.log("finishGptEvent", gptId);
		const data = getAvatarGptData(gptId);
		data.finishEvent(functionOutput, promptLayer_request_id, metadata);
		gptDeltaTrackerRef.current.modified[gptId] = data;
		console.log("finishGptEvent after", gptId);

		// try {
		// 	posthog?.capture("instage_gptevent_finish", data);
		// } catch (error) {
		// 	console.error("Posthog error:", error);
		// }
	}
	async function finishSpeechEvent(speechId) {
		console.log("finishSpeechEvent", speechId);
		const data = getAvatarSpeechData(speechId);

		speechDeltaTrackerRef.current.modified[speechId] = data;
		// deep copy data
		console.log("finishSpeechEvent after", speechId);

		// try {
		// 	const copy = JSON.parse(JSON.stringify(data));
		// 	delete copy.audioQueue;
		// 	delete copy.wordQueue;
		// 	delete copy.visemeQueue;
		// 	delete copy.animation;
		// 	// posthog?.capture("instage_speechevent_finish", copy);
		// } catch (error) {
		// 	console.error("Posthog error:", error);
		// }
	}

	function finishTranscriptEvent(transcriptId) {
		// console.error("finishTranscriptEvent", transcriptId);

		const data = getTranscriptData(transcriptId);
		data.finishEvent();
		transcriptDeltaTrackerRef.current.modified[transcriptId] = data;
		// console.log("finishTranscriptEvent after", transcriptId);

		// try {
		// 	posthog?.capture("instage_transcriptevent_finish", data);
		// } catch (error) {
		// 	console.error("Posthog error:", error);
		// }
		// console.log("finishTranscriptEvent2", transcriptDeltaTrackerRef.current)
	}

	/**
	 * This function creates a new AvatarGptData object and stores it in avatarGptDataRef.current.
	 * @param {number} transcriptId - The transcript ID of the GPT.
	 * @param {boolean} isIdleCheck - Flag to show that this ran because of the idle check.
	 * @returns {AvatarGptData}
	 */
	function createAvatarGptData(transcriptId, isIdleCheck) {
		const data = new AvatarGptData(transcriptId, isIdleCheck);

		// Count number of keys in avatarGptDataRef.current as the id
		data.gptId = Object.keys(avatarGptDataRef.current).length;

		avatarGptDataRef.current[data.gptId] = data;
		gptDeltaTrackerRef.current.added[data.gptId] = data;
		// try {
		// 	posthog?.capture("instage_gptevent_start", data);
		// } catch (error) {
		// 	console.error("Posthog error:", error);
		// }
		return data;
	}
	/**
	 *  this function creates a new AvatarSpeechData object and stores it in avatarSpeechDataRef.current.
	 * @param {number} gptId
	 * @param {*} text
	 * @returns {AvatarSpeechData}
	 */
	function createAvatarSpeechData(gptId, text) {
		const data = new AvatarSpeechData(gptId, text);

		// Count number of keys in avatarSpeechDataRef.current as the id
		data.speechId = Object.keys(avatarSpeechDataRef.current).length;
		// Store the new AvatarSpeechData object in avatarSpeechDataRef.current
		avatarSpeechDataRef.current[data.speechId] = data;
		speechDeltaTrackerRef.current.added[data.speechId] = data;

		// try {
		// 	posthog?.capture("instage_speechevent_start", data);
		// } catch (error) {
		// 	console.error("Posthog error:", error);
		// }
		return data;
	}

	/**
	 * This function creates a new UserTranscriptData object and stores it in userTranscriptDataRef.current.
	 * @param {string} transcript - The transcript text.
	 * @param {boolean} [isFinal=false] - Flag to check if the transcript is final.
	 * @param {boolean} [speechFinal=false] - Flag to check if the speech is final.
	 * @param {number} [duration=0] - The duration of the transcript.
	 * @returns {UserTranscriptData} The newly created UserTranscriptData object.
	 */
	function createUserTranscriptData(transcript = "", isFinal = false, speechFinal = false, duration = 0) {
		//  console.log(getUserTranscriptData(getLastTranscriptId()))
		//
		const data = new UserTranscriptData(transcript, isFinal, speechFinal, duration);
		// Set the transcript ID
		data.transcriptId = Object.keys(userTranscriptDataRef.current).length;

		// Store the new UserTranscriptData object in userTranscriptDataRef.current
		userTranscriptDataRef.current[data.transcriptId] = data;
		transcriptDeltaTrackerRef.current.added[data.transcriptId] = data;
		// try {
		// 	posthog?.capture("instage_transcriptevent_start", data);
		// } catch (error) {
		// 	console.error("Posthog error:", error);
		// }
		return data;
	}

	const { setPollingInterval, clearPollingInterval } = usePollingIntervals();

	const vad = {
		start() {},
		pause() {}
	};
	const handleDataAvailable = async (event) => {
		console.log("data-available");
		if (event.data.size > 0) {
			// You can create a blob from the data and download or send it to server
			const blob = new Blob([event.data], { type: "video/webm" });
			const fileName = await uploadVideoToS3(blob);
			console.log(fileName);
		}
	};
	async function uploadVideoToS3(blob) {
		// console.log("uploadVideoToS3")
		const fileArrayBuffer = await blob.arrayBuffer();
		const fileArray = new Uint8Array(fileArrayBuffer);

		const CHUNK_SIZE = 768 * 1024; // Size of chunks in bytes
		const totalChunks = Math.ceil(fileArray.length / CHUNK_SIZE);

		const responseCount = partCountsRef.current;
		const responseAudioCount = 0;
		const { session_id, instage_id, client_id } = activeSessionDataRef.current;

		// console.log(url, "/api/upload-init")
		try {
			const initResponse = await axiosLimitedPost(
				`${url}/api/upload-init`,
				{
					client_id,
					instage_id,
					session_id,
					totalChunks,
					responseCount,
					responseAudioCount,
					type: "webm"
				},
				1
			);

			// console.log("totalChunks", totalChunks)
		} catch (error) {
			console.error(`/api/upload-init${error}`);
			throw new Error(`/api/upload-init${error}`);
		}
		// Initialize upload
		// Upload chunks
		for (let i = 0; i < totalChunks; i++) {
			const start = i * CHUNK_SIZE;
			const end = Math.min(fileArray.length, start + CHUNK_SIZE);
			const chunkArray = fileArray.slice(start, end);
			const chunkBlob = new Blob([chunkArray], { type: "video/webm" });

			const formData = new FormData();
			formData.append("audio", chunkBlob, "video.webm");
			formData.append("instage_id", instage_id);
			formData.append("session_id", session_id);
			formData.append("client_id", client_id);

			formData.append("responseCount", responseCount);
			formData.append("type", "webm");

			formData.append("index", i);
			//  console.log("/api/upload-chunk")
			// eslint-disable-next-line no-await-in-loop
			await axiosLimitedPost(`${url}/api/upload-chunk`, formData, 3, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
		}

		// Finalize upload
		const finalizeData = {
			client_id,
			instage_id,
			session_id,
			responseCount,
			responseAudioCount,
			type: "webm"
		};

		return new Promise((resolve, reject) => {
			// console.log("/api/upload-finalize")
			axiosLimitedPost(`${url}/api/upload-finalize`, finalizeData, 1)
				.then((data) => {
					console.log("uploadToS3 data", data);
					partCountsRef.current += 1;

					resolve(data.data.Key);
				})
				.catch((error) => {
					throw new Error(`/api/upload-finalize"${error}`);
				});
		});
	}

	/**
	 * Returns the current avatar speech data.
	 * @returns {Object} The current avatar speech data.
	 */
	const getAvatarSpeechDataObject = () => avatarSpeechDataRef.current;

	/**
	 * Returns the current avatar GPT data.
	 * @returns {array} The current avatar GPT data.
	 */
	const getAvatarGptDataObject = () => avatarGptDataRef.current;

	/**
	 * Returns the current user transcript data.
	 * @returns {array} The current user transcript data.
	 */
	const getUserTranscriptDataObject = () => userTranscriptDataRef.current;
	// console.log("user",user)

	function saveData() {
		try {
			const eventHistory = getEventHistory();
			const chatHistory = getChatHistory(eventHistory, true);
			saveResponse(chatHistory);
			saveEventData();
		} catch (error) {
			console.error(error);
		}
	}

	function saveEventData() {
		// make use of the delta tracker to only send the data that has changed
		const transcript_data = JSON.parse(JSON.stringify(transcriptDeltaTrackerRef.current));
		const gpt_data = JSON.parse(JSON.stringify(gptDeltaTrackerRef.current));
		const avatar_speech_data = JSON.parse(JSON.stringify(speechDeltaTrackerRef.current));

		const { session_id, instage_id } = activeSessionDataRef.current;
		if (Object.keys(transcript_data.added).length !== 0 || Object.keys(transcript_data.modified).length !== 0) {
			axiosLimitedPost(`${url}/api/sessionData/session/eventdata`, { session_id, instage_id, transcript_data }, 1)
				.then((response) => {
					// Remove only the keys that were sent from the delta trackers
					Object.keys(transcript_data.added).forEach((key) => delete transcriptDeltaTrackerRef.current.added[key]);
					Object.keys(transcript_data.modified).forEach((key) => delete transcriptDeltaTrackerRef.current.modified[key]);
				})
				.catch((error) => {
					console.error(error);
					// posthog?.capture("instage_eventdata_error", error);
				});
		}
		if (Object.keys(gpt_data.added).length !== 0 || Object.keys(gpt_data.modified).length !== 0) {
			axiosLimitedPost(`${url}/api/sessionData/session/eventdata`, { session_id, instage_id, gpt_data }, 1)
				.then((response) => {
					// Remove only the keys that were sent from the delta trackers
					Object.keys(gpt_data.added).forEach((key) => delete gptDeltaTrackerRef.current.added[key]);
					Object.keys(gpt_data.modified).forEach((key) => delete gptDeltaTrackerRef.current.modified[key]);
				})
				.catch((error) => {
					console.error(error);
					// posthog?.capture("instage_eventdata_error", error);
				});
		}
		if (Object.keys(avatar_speech_data.added).length !== 0 || Object.keys(avatar_speech_data.modified).length !== 0) {
			axiosLimitedPost(`${url}/api/sessionData/session/eventdata`, { session_id, instage_id, avatar_speech_data }, 1)
				.then((response) => {
					// Remove only the keys that were sent from the delta trackers
					Object.keys(avatar_speech_data.added).forEach((key) => delete speechDeltaTrackerRef.current.added[key]);
					Object.keys(avatar_speech_data.modified).forEach((key) => delete speechDeltaTrackerRef.current.modified[key]);
				})
				.catch((error) => {
					console.error(error);
					// posthog?.capture("instage_eventdata_error", error);
				});
		}
		// reset the delta trackers
	}

	async function saveResponse(chat_history) {
		const { instage_id, session_id } = activeSessionDataRef.current;

		const data = {
			instage_id,
			session_id,
			chat_cache: { chat_history }
		};
		// console.log(data)
		const response = await axiosLimitedPost(`${url}/api/sessionData/session/chat-history`, data, 1);
		// console.log(response)
		return response.data;
	}

	/**
	 * This function retrieves the AvatarSpeechData object associated with a given speech ID.
	 *
	 * @param {number} speechId - The ID of the speech.
	 * @returns {AvatarSpeechData} The AvatarSpeechData object associated with the given speech ID.
	 */
	function getAvatarSpeechData(speechId) {
		return avatarSpeechDataRef.current[speechId];
	}
	/**

       *
       * @param {number} speechId - The ID of the speech.
       * @returns {AvatarSpeechData[]} The AvatarSpeechData object associated with the given speech ID.
       */
	function getAvatarSpeechDataByGptId(gptId) {
		const results = Object.values(avatarSpeechDataRef.current).filter((speechData) => speechData.gptId === gptId);

		if (results.length === 1) {
			return results[0];
		}
		return results;
	}
	/**

        *
        * @param {number} speechId - The ID of the speech.
        * @returns {AvatarSpeechData[]} The AvatarSpeechData object associated with the given speech ID.
        */
	function getAvatarSpeechsDataByGptId(gptId) {
		const results = Object.values(avatarSpeechDataRef.current).filter((speechData) => speechData.gptId === gptId);
		if (results.length === 1) {
			return results;
		}
		return results;
	}

	/**
	 * This function retrieves the currently playing AvatarSpeechData object.
	 *
	 * @returns {AvatarSpeechData} The currently playing AvatarSpeechData object.
	 */
	function getCurrentlyPlayingAvatarSpeechData() {
		return Object.values(avatarSpeechDataRef.current).find((speechData) => speechData.playing) || null;
	}

	function isResponseStale(speechId) {
		const eventHistory = getEventHistory();
		const eventIndex = eventHistory.findIndex((event) => event.speechId === speechId);

		// console.log(speechId, eventIndex)
		let nonAvatarSpeechDataEvent = null;
		for (let i = eventIndex; i >= 0; i--) {
			if (!(eventHistory[i] instanceof AvatarSpeechData)) {
				nonAvatarSpeechDataEvent = eventHistory[i];
				break;
			}
		}

		// console.log("nonAvatarSpeechDataEvent", nonAvatarSpeechDataEvent)
		if (nonAvatarSpeechDataEvent == null) {
			// console.log("response not stale")
			return false;
		}
		if (nonAvatarSpeechDataEvent instanceof AvatarGptData) {
			// console.log("response not stale")

			return false;
		}
		console.error("response stale");

		return true;
	}
	function isPreviousRequestSynthesized(speechId) {
		const data = getAvatarSpeechData(speechId);
		const previousData = getAvatarSpeechData(speechId - 1);

		if (previousData && data.gptId === previousData.gptId) {
			if (data.speechId > 0 && !previousData.generationComplete && !previousData.played) {
				//   console.log("Previous request is still being synthesized.");
				return false;
			}
			return true;
		}
		return true;
	}
	/**
	 *
	 * @param {*} transcriptId
	 * @returns {UserTranscriptData}
	 */
	function getUserTranscriptData(transcriptId) {
		return userTranscriptDataRef.current[transcriptId];
	}
	/**
	 *
	 * @param {*} transcriptId
	 * @returns {AvatarGptData}
	 */
	function getAvatarGptData(gptId) {
		return avatarGptDataRef.current[gptId];
	}
	/**
	 *
	 * @param {*} transcriptId
	 * @returns {AvatarGptData}
	 */
	function getAvatarGptDataByTranscriptId(transcriptId) {
		Object.keys(avatarGptDataRef.current).forEach((gptId) => {
			const data = getAvatarGptData(gptId);
			if (data.transcriptId === transcriptId) {
				return data;
			}
			return null;
		});
		return null;
	}

	/**
	 *
	 * @param {number} transcriptId
	 * @returns {UserTranscriptData}
	 */
	function getTranscriptData(transcriptId) {
		return userTranscriptDataRef.current[transcriptId];
	}

	function getLastTranscriptId() {
		return Object.keys(userTranscriptDataRef.current).length - 1;
	}
	/**
	 *
	 * @returns {UserTranscriptData}
	 */
	function getLastNonEmptyTranscript() {
		let transcriptId = getLastTranscriptId();
		let transcriptData = getUserTranscriptData(transcriptId);

		while (transcriptData && transcriptData.transcript === "") {
			transcriptId -= 1;
			transcriptData = getUserTranscriptData(transcriptId);
		}

		return transcriptData;
	}

	/**
	 * Loads data into the current references.
	 *
	 * @param {Object} avatarSpeechData - The avatar speech data to be loaded.
	 * @param {Object} avatarGptData - The avatar GPT data to be loaded.
	 * @param {Object} userTranscriptData - The user transcript data to be loaded.
	 */
	function loadData(avatarSpeechData, avatarGptData, userTranscriptData) {
		// Load avatar speech data from the provided object, converting each item to an instance of AvatarSpeechData
		avatarSpeechDataRef.current = Object.keys(avatarSpeechData).map((key) => AvatarSpeechData.fromObject(avatarSpeechData[key])) || {};
		// Load avatar GPT data from the provided object, converting each item to an instance of AvatarGptData
		avatarGptDataRef.current = Object.keys(avatarGptData).map((key) => AvatarGptData.fromObject(avatarGptData[key])) || {};
		// Load user transcript data from the provided object, converting each item to an instance of UserTranscriptData
		userTranscriptDataRef.current = Object.keys(userTranscriptData).map((key) => UserTranscriptData.fromObject(userTranscriptData[key])) || {};
	}
	// Function to update avatar speech data
	const updateAvatarSpeechData = (id, data) => {
		avatarSpeechDataRef.current[id] = data;
	};

	// Function to update GPT data
	const updateAvatarGptData = (id, data) => {
		avatarGptDataRef.current[id] = data;
	};

	// Function to update user transcript data
	const updateUserTranscriptData = (id, data) => {
		userTranscriptDataRef.current[id] = data;
	};

	function getTokenCount() {
		const gptData = Object.values(getAvatarGptDataObject());
		// loop through gptData to get the token count from the metadata
		let tokenCount = 0;
		gptData.forEach((data) => {
			if (data.metadata && data.metadata.token_count) {
				tokenCount += data.metadata.token_count;
			}
		});
		return tokenCount;
	}
	function getEventHistory() {
		const gptData = Object.values(getAvatarGptDataObject());
		const speechData = Object.values(getAvatarSpeechDataObject());
		const transcriptData = Object.values(getUserTranscriptDataObject());

		const history = CreateEventHistory(transcriptData, speechData, gptData);

		// history = history.filter(event => event.startTime !== 0 && event.startTime !== null && event.startTime >= 10000);

		//  history.sort((a, b) => a.startTime - b.startTime);

		return history;
	}

	function getEventHistoryOld() {
		const history = [];

		const firstMessage = getAvatarSpeechDataByGptId(-1);
		if (firstMessage) {
			history.push(firstMessage);
		}

		Object.keys(userTranscriptDataRef.current).forEach((transcriptId) => {
			const transcriptData = getTranscriptData(transcriptId);
			if (transcriptData) {
				history.push(transcriptData);
				const avatarGptdata = getAvatarGptDataByTranscriptId(transcriptData.transcriptId);
				if (avatarGptdata) {
					history.push(avatarGptdata);
					const avatarSpeechData = getAvatarSpeechDataByGptId(avatarGptdata.gptId);
					if (Array.isArray(avatarSpeechData) && avatarSpeechData.length) {
						history.push(...avatarSpeechData);
					} else if (avatarSpeechData instanceof AvatarSpeechData) {
						history.push(avatarSpeechData);
					}
				}
			}
		});
		return history;
	}

	/**
	 * Function to get the chat history.
	 * @param {Array} eventHistory - The event history data. If not provided, it will be fetched using getEventHistory function.
	 * @param {boolean} useIndex - A flag to indicate whether to use index in the message content or not.
	 * @returns {Array} chatHistory - The chat history data.
	 */
	function getChatHistory(inputEventHistory = null, useIndex = false) {
		// console.log("getChatHistory",characterNameRef.current)
		const chatHistory = [];
		let eventHistory = [];
		if (!inputEventHistory) {
			eventHistory = getEventHistory();
		} else {
			eventHistory = inputEventHistory;
		}
		// Loop through event history to create a chatHistory
		eventHistory.forEach((event) => {
			const message = {};
			if (event instanceof UserTranscriptData) {
				message.role = "user";
				const rolePrefix =
					{
						interview: "Interviewee: ",
						presentation: "Presenter: ",
						discovery: "Sales Rep: "
					}[activeSessionDataRef.current?.setup_type] || "User: ";
				//  console.log(event)
				if (event.transcript !== "") {
					const lastMessage = chatHistory[chatHistory.length - 1];
					if (lastMessage && lastMessage.role === "user") {
						message.content = event.transcript;

						lastMessage.content = `${lastMessage.content} ${message.content}`;
					} else {
						message.content = rolePrefix + event.transcript;
						chatHistory.push(message);
					}
				}
			} else if (event instanceof AvatarSpeechData) {
				message.role = "assistant";
				message.content = `${characterNameRef.current}: ${event.spokenText}`;
				if (event.spokenText !== "") {
					// type{AvatarGptData}
					const gptData = getAvatarGptData(event.gptId);
					if (gptData?.functionOutput.question_list_index !== undefined) {
						if (useIndex) {
							message.content += ` (question_list_index: ${gptData?.functionOutput.question_list_index})`;
						}
					}
					message.metadata = gptData?.metadata;
					if (message.metadata) {
						message.metadata.promptLayer_request_id = gptData?.promptLayer_request_id;
					}
					// console.log("event", event);
					message.speechId = event.speechId;

					chatHistory.push(message);
				}
			}
		});
		// console.log("chatHistory", chatHistory);
		return chatHistory;
	}
	function getChatHistoryV2(inputEventHistory = null) {
		const chatHistory = [];
		let eventHistory = [];

		if (!inputEventHistory) {
			eventHistory = getEventHistory();
		} else {
			eventHistory = inputEventHistory;
		}
		// Loop through event history to create a chatHistory
		eventHistory.forEach((event) => {
			const message = {};
			if (event instanceof UserTranscriptData) {
				message.role = "user";
				const rolePrefix =
					{
						interview: "Interviewee: ",
						presentation: "Presenter: ",
						discovery: "Sales Rep: "
					}[activeSessionDataRef.current?.setup_type] || "User: ";
				//  console.log(event)
				if (event.transcript !== "") {
					// check if the last message is from the user
					const lastMessage = chatHistory[chatHistory.length - 1];
					if (lastMessage && lastMessage.role === "user") {
						message.content = event.transcript;

						lastMessage.content = `${lastMessage.content} ${message.content}`;
					} else {
						message.content = rolePrefix + event.transcript;
						chatHistory.push(message);
					}
				}
			} else if (event instanceof AvatarGptData) {
				const speechs = getAvatarSpeechsDataByGptId(event.gptId);

				// console.log(event.gptId, speechs)
				let dialogue = "";
				speechs.forEach((speech) => {
					if (speech.spokenText !== "") {
						dialogue += speech.spokenText;
					}
				});
				// eslint-disable-next-line no-param-reassign
				event.functionOutput.dialogue = dialogue;
				message.role = "assistant";
				if (event?.metadata.id) {
					message.tool_calls = [
						{
							function: {
								name: event?.metadata.tool_choice,
								arguments: JSON.stringify(event?.functionOutput)
							},
							type: "function",
							id: event?.metadata?.id
						}
					];
					message.content = null;

					const message2 = {
						tool_call_id: event?.metadata.id,
						role: "tool",
						name: event?.metadata.tool_choice || "",
						content: JSON.stringify(event?.functionOutput)
					};

					//  console.log(message)
					if (event.functionOutput.dialogue !== "" && message2.name !== "") {
						chatHistory.push(message);
						chatHistory.push(message2);
					}
				} else {
					//	message.content = `${characterNameRef.current}: ${dialogue}`;

					// deep copy functionOutput
					const copy = JSON.parse(JSON.stringify(event?.functionOutput));

					delete copy.Question_List_Index;
					delete copy.Dialogue;

					message.content = JSON.stringify(copy);

					if (dialogue !== "") {
						chatHistory.push(message);
					}
				}

				// characterNameRef.current+": "+event.spokenText;
			}
		});
		const initialMessage = {
			role: "assistant",

			content: `${characterNameRef.current}: ${eventHistory[0].spokenText}`
		};
		chatHistory.unshift(initialMessage);
		return chatHistory;
	}

	function getOldSpeechDataByTranscriptId(transcriptId) {
		let speech_data = null;

		/**
		 * type{user}
		 */
		const transcriptData = getTranscriptData(transcriptId);
		if (transcriptData) {
			const data = {
				db: transcriptData.db,
				rms: transcriptData.rms,
				filler_count: transcriptData.fillerCount,
				speak_time: transcriptData.duration,
				transcript: transcriptData.transcript,
				word_count: transcriptData.words,
				minutes: transcriptData.duration / 60,
				pauses: transcriptData.pauses,
				wpm: transcriptData.wordsPerMinute,
				file_name: transcriptData.audioFileName
			};
			speech_data = data;
		}

		return speech_data;
	}

	function printEventHistory() {
		// console.log("userTranscriptDataRef",userTranscriptDataRef.current)
		//  console.log("avatarGptDataRef",avatarGptDataRef.current)
		//  console.log("avatarSpeechDataRef",avatarSpeechDataRef.current)
		const eventHistory = getEventHistory();
		const chatHistory = getChatHistoryV2(eventHistory);
		console.log(eventHistory);
		console.log(chatHistory);
	}

	async function startAudioFile(transcriptId) {
		console.log("start audio file", transcriptId, "samplerate", audioContext.current.sampleRate);

		if (scriptNodeRef.current.startRecording === true) {
			return;
		}

		scriptNodeRef.current.startRecording = true;
		let audioChunks = audioBitsRef.current;
		let { sampleRate } = audioContext.current;
		const seconds = 1.5;
		sampleRate *= seconds;
		// Assuming the sample rate is 44100 Hz, 1 second of audio is 44100 samples
		// Make sure we have more samples than the sample rate

		if (audioChunks.length > sampleRate) {
			audioChunks = audioChunks.slice(-sampleRate);
			// return;
		} else {
			audioChunks = audioBitsRef.current;
		}
		RecordingBufferRef.current = audioChunks;
		//  let oldLength = audioChunks.length;

		// keep the last second of samples

		//   console.log(oldLength, audioChunks.length, sampleRate)

		// audioBitsRef.current = audioChunks;
	}

	async function finishAudioFile(transcriptId) {
		// console.log("finish audio file ", transcriptId, "samplerate", audioContext.sampleRate);
		if (scriptNodeRef.current.startRecording === false) {
			console.error("audio file cant be finished since not started");
			return;
		}

		// remove last item from audioBitsRef.current
		setUserSpeaking(false);

		scriptNodeRef.current.startRecording = false;
		// audioBitsRef.current.shift();
		// audioBitsRef.current.pop();
		const audioChunks = RecordingBufferRef.current;

		if (audioChunks.length === 0) {
			return;
		}
		// console.log("audioBitsRef.current",audioBitsRef.current,audioBitsRef.current.length)
		const rms = computeRMS(audioChunks);
		const db = rmsToDb(rms);
		//    console.log("RMS Value:", rms, "DB Value:", db);

		const totalLength = audioChunks.reduce((total, chunk) => total + chunk.length, 0);
		const samples = new Float32Array(audioChunks.length);
		const offset = 0;
		samples.set(audioChunks, offset);

		const data = getTranscriptData(transcriptId);
		data.db = db;
		data.rms = rms;
		data.sampleRate = audioContext.current.sampleRate;
		// for (let chunk of audioChunks) {
		//     samples.set(chunk, offset);
		//     offset += chunk.length;
		// }
		// Encode samples to WAV format
		// const wav = encodeWAV(samples, audioContext.current);
		// 	const { session_id, instage_id, client_id } = activeSessionDataRef.current;

		//	let fileName = "";
		//let deepgram_payload = null;
		try {
			//	const { Key, deepgram_data } = await uploadToS3(wav.buffer, transcriptId, instage_id, session_id, client_id);
			//	fileName = Key;
			//	deepgram_payload = deepgram_data;
		} catch (e) {
			console.error(e);
		}
		//	data.audioFileName = fileName;
		// data.deepgram_data = deepgram_payload;
		// saveSpeechData(session_id, transcriptId);
		saveData();
		RecordingBufferRef.current = [];
	}

	/**
	 *
	 * @param {*} wavBuffer
	 * @param {*} transcriptId
	 * @param {*} instage_id
	 * @param {*} session_id
	 * @returns filename
	 */
	async function uploadToS3(wavBuffer, transcriptId, instage_id, session_id, client_id) {
		if (!saveAudio.current) {
			return null;
		}

		const wavArray = new Uint8Array(wavBuffer);
		//  console.log("wavBuffer",wavBuffer)
		//  console.log("wavArray",wavArray)
		//  console.log("wavArray.length",wavArray.length)
		const CHUNK_SIZE = 768 * 1024; // Size of chunks in bytes
		const totalChunks = Math.ceil(wavArray.length / CHUNK_SIZE);

		const responseCount = transcriptId;

		// console.log(url, "/api/upload-init",        instage_id,
		// session_id,
		// totalChunks,
		// responseCount,)
		try {
			const initResponse = await axiosLimitedPost(
				`${url}/api/upload-init`,
				{
					client_id,
					instage_id,
					session_id,
					totalChunks,
					responseCount,

					type: "wav"
				},
				1
			);

			//  console.log("totalChunks", totalChunks)
		} catch (error) {
			console.error(`/api/upload-init${error}`);
			throw Error(`/api/upload-init${error}`);
		}
		// Initialize upload
		// Upload chunks
		for (let i = 0; i < totalChunks; i++) {
			const start = i * CHUNK_SIZE;
			const end = Math.min(wavArray.length, start + CHUNK_SIZE);
			const chunkArray = wavArray.slice(start, end);
			const chunkBlob = new Blob([chunkArray], { type: "audio/wav" });

			const formData = new FormData();
			formData.append("audio", chunkBlob, "audio.wav");
			formData.append("instage_id", instage_id);
			formData.append("session_id", session_id);
			formData.append("client_id", client_id);

			formData.append("responseCount", responseCount);
			formData.append("type", "wav");

			formData.append("index", i);
			// console.log("/api/upload-chunk")
			// eslint-disable-next-line no-await-in-loop
			await axiosLimitedPost(`${url}/api/upload-chunk`, formData, 3, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			});
		}

		// Finalize upload
		const finalizeData = {
			client_id,
			instage_id,
			session_id,
			responseCount,
			type: "wav"
		};
		try {
			const data = await axiosLimitedPost(`${url}/api/upload-finalize`, finalizeData, 1);
			// console.log("uploadToS3 data", data);
			return data.data; // This will be wrapped in a Promise automatically
		} catch (error) {
			console.error(`/api/upload-finalize"${error}`);
			throw error; // This will reject the Promise returned by the async function
		}
	}
	// Constants

	// Event Handlers
	const onVoiceStart = () => {
		console.log("Voice Started!");
		isTalkingRef.current = true;
		setUserSpeaking(true);
		OnVoiceStart();

		// Add additional event-handling logic if needed
	};

	let canCallOnVoiceEnd = true;

	const onVoiceEnd = () => {
		// console.log("Voice Ended!");
		isTalkingRef.current = false;
		setUserSpeaking(false);
		if (canCallOnVoiceEnd) {
			console.log("Voice Ended!");

			OnVoiceEnd();
			canCallOnVoiceEnd = false; // Set the flag to false

			// Set a timer to allow the call to OnVoiceEnd after 5 seconds
			setTimeout(() => {
				console.log("Voice Ended Can Nod Again");
				canCallOnVoiceEnd = true;
			}, 5000);
		}
	};

	const calculateRMS = (data) => Math.sqrt(data.reduce((acc, val) => acc + val * val, 0) / data.length);
	const rmsBufferRef = useRef([]);
	const RMS_BUFFER_SIZE = 5; // Size of the buffer, adjust as needed for smoothing
	const calculateSmoothedRMS = (newRms) => {
		const buffer = rmsBufferRef.current;
		buffer.push(newRms);
		if (buffer.length > RMS_BUFFER_SIZE) {
			buffer.shift(); // Remove the oldest RMS value
		}
		rmsBufferRef.current = buffer;
		return buffer.reduce((acc, val) => acc + val, 0) / buffer.length; // Calculate average
	};

	async function processStream(stream) {
		const workletUrl = `${url}/my-worklet-processor.js`;
		// console.log("processStream", audioContext.current);
		try {
			await audioContext.current.audioWorklet.addModule(workletUrl);
		} catch (err) {
			console.error("Error adding audio worklet module:", err);
		}

		const source = audioContext.current.createMediaStreamSource(stream);
		let myWorkletNode = null;
		try {
			myWorkletNode = new AudioWorkletNode(audioContext.current, "my-worklet-processor");
		} catch (err) {
			console.error("Error creating audio worklet Node:", err);
		}

		if (myWorkletNode) {
			scriptNodeRef.current = {};
			scriptNodeRef.current.startRecording = false;
			scriptNodeRef.current.node = myWorkletNode;
			scriptNodeRef.current.source = source;
			scriptNodeRef.current.printCount = 0;
			source.connect(myWorkletNode);
			myWorkletNode.connect(audioContext.current.destination);
			const VAD_THRESHOLD = 0.01; // Adjust as necessary

			let silenceFrameCounter = 0; // Counter for silence frames
			let speechFrameCounter = 0; // Counter for speech frames
			const silenceFrameThreshold = 1.5; // Threshold for silence frames
			const speechFrameThreshold = 0.2; // Threshold for speech frames
			let { sampleRate } = audioContext.current; // Get the sample rate from the audio context
			const bufferSize = 128; // Replace with actual buffer size or handle dynamically for AudioWorkletNode
			const frameDuration = bufferSize / sampleRate; // Duration of each frame in seconds

			myWorkletNode.port.onmessage = (event) => {
				const channelData = event.data;
				if (channelData) {
					const rms = calculateRMS(channelData);
					const smoothedRms = calculateSmoothedRMS(rms); // Calculate smoothed RMS

					// Check against threshold
					// const isVoiceDetected = smoothedRms > VAD_THRESHOLD;
					const isVoiceDetected = smoothedRms > VAD_THRESHOLD;

					// console.log("isVoiceDetected: ", isVoiceDetected);
					// console.log("rms: ", rms);
					// console.log("smoothedRMS: ", smoothedRms);
					// State Transition Logic

					// State Transition Logic
					if (isVoiceDetected) {
						// console.log("Voice Detected");
						if (silenceFrameCounter !== 0) {
							//   console.warn(`rms ${smoothedRms} silenceFrameCounter ${silenceFrameCounter} silenceFrameThreshold ${silenceFrameThreshold}`)
						}
						silenceFrameCounter = 0; // Reset silence counter when voice is detected
						speechFrameCounter += frameDuration; // Increment speech counter when voice is detected
						if (speechFrameCounter >= speechFrameThreshold && vadRef.current === STATES.SILENCE) {
							vadRef.current = STATES.VOICE;
							// console.warn("Voice Started Being Detected", Date.now(), '\n', ` speechFrameCounter ${speechFrameCounter} speechFrameThreshold ${speechFrameThreshold}`,);
							onVoiceStart();
						}
					} else {
						// console.log("Voice NOT Detected");
						if (speechFrameCounter !== 0) {
							//    console.warn(`rms ${smoothedRms} speechFrameCounter ${speechFrameCounter} speechFrameThreshold ${speechFrameThreshold}`)
						}
						speechFrameCounter = 0; // Reset speech counter when silence is detected
						silenceFrameCounter += frameDuration; // Increment silence counter when silence is detected
						if (silenceFrameCounter >= silenceFrameThreshold && vadRef.current === STATES.VOICE) {
							vadRef.current = STATES.SILENCE;
							// console.warn("Voice Stopped Being Detected", Date.now(), '\n', `silenceFrameCounter ${silenceFrameCounter} silenceFrameThreshold ${silenceFrameThreshold}`);
							onVoiceEnd();
						}
					}
					// Now, channelData is a Float32Array containing the PCM data
					// You can process, store, or send this data as needed
					audioBitsRef.current.push(...channelData);

					let seconds = 3;
					if (scriptNodeRef.current.startRecording === true) {
						RecordingBufferRef.current.push(...channelData);
						seconds = 60 * 3;
					}
					sampleRate *= seconds;
					if (audioBitsRef.current.length > sampleRate) {
						audioBitsRef.current = audioBitsRef.current.slice(-sampleRate);
					}
				}
			};
		}
	}
	function parseUserAgent(userAgent) {
		let browserName = "";
		let browserVersion = "";
		let os = "";

		// Detecting Browser Name and Version
		if (userAgent.includes("Edg")) {
			browserName = "Microsoft Edge";
			browserVersion = userAgent.match(/Edg\/[\d.]+/)[0].replace("Edg/", "");
		} else if (userAgent.includes("Chrome")) {
			browserName = "Google Chrome";
			browserVersion = userAgent.match(/Chrome\/[\d.]+/)[0].replace("Chrome/", "");
		} else if (userAgent.includes("Firefox")) {
			browserName = "Mozilla Firefox";
			browserVersion = userAgent.match(/Firefox\/[\d.]+/)[0].replace("Firefox/", "");
		} else if (userAgent.includes("Safari")) {
			browserName = "Safari";
			browserVersion = userAgent.match(/Version\/[\d.]+/)[0].replace("Version/", "");
		}

		// Detecting Operating System
		if (userAgent.includes("Windows NT 10.0")) {
			os = "Windows 10";
		} else if (userAgent.includes("Windows NT 6.1")) {
			os = "Windows 7";
		} else if (userAgent.includes("Windows NT 6.3")) {
			os = "Windows 8.1";
		} else if (userAgent.includes("Windows NT 6.2")) {
			os = "Windows 8";
		} else if (userAgent.includes("Windows NT 6.3") || userAgent.includes("Windows NT 10.0; Win64; ARM64")) {
			os = "Windows 11"; // Windows 11 might be identified as Windows NT 10.0 on some systems
		} else if (userAgent.includes("Macintosh")) {
			os = "macOS";
		} else if (userAgent.includes("Linux")) {
			os = "Linux";
		}

		// Formatting the output
		return `Browser: ${browserName} (Version: ${browserVersion})\nOperating System: ${os}`;
	}

	// Usage
	// console.log('Browser Info:', parseUserAgent(navigator.userAgent));

	// Usage

	function printAudioDetails(audioTrack) {
		console.log("audioTrack", audioTrack);
		console.log(`Microphone device: ${audioTrack.label}`);
		console.log("audioTrack.getSettings()", audioTrack.getSettings());
		console.log("Browser Info:", navigator.userAgent);
		// const parsedInfo = parseUserAgent(navigator.userAgent);
		// console.log("parsedInfo", parsedInfo);
	}
	async function handleDeepgramConnection(connected) {
		if (connected) {
			// If media stream has a video track, create a new stream with just the audio track
			let audioStream;
			if (userMediaStreamRef.current.getVideoTracks().length > 0) {
				const audioTracks = userMediaStreamRef.current.getAudioTracks();
				// printAudioDetails(audioTracks[0]);
				// console.log('Microphone device: ' + audioTracks[0].label);
				// console.log("Sample rate: " + audioTracks[0].getSettings().sampleRate);

				audioStream = new MediaStream(audioTracks);
			} else {
				audioStream = userMediaStreamRef.current;
				const audioTracks = userMediaStreamRef.current.getAudioTracks();
				// printAudioDetails(audioTracks[0]);
			}
			//  console.log("startDeepGram track: ", audioStream.getTracks());

			vad.start();

			microphoneRef.current = new MediaRecorder(audioStream, { audioBitsPerSecond: 16 * 1000 }); //{ mimeType: "audio/webm; codecs=opus" }
			const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

			if (!isIOS) {
				processStream(audioStream);
			} else {
				processStream(audioStream);

				scriptNodeRef.current = {};
				scriptNodeRef.current.startRecording = false;

				scriptNodeRef.current.printCount = 0;
			}
			await microphoneRef.current.start(500);
			microphoneRef.current.onstart = () => {
				console.log("client: microphone opened");
			};

			microphoneRef.current.onstop = () => {
				console.log("client: microphone closed");
				// finishAudioFile()
			};

			microphoneRef.current.ondataavailable = (e) => {
				// console.log("client: microphone data available",e.data?.size);
				if (deepGramOnRef.current === false) {
					console.error("cant send data to deepgram since not connected");
					return;
				}
				// console.log("audio size", e.data.size);
				//    audioBitsRef.current.push(audioBlob);

				//  console.log("client: sent data to websocket",e.data.size);

				try {
					let { printCount } = scriptNodeRef.current;
					if (e.data.size > 0) {
						if (printCount < 3) {
							// console.log("sending packet", e.data);
							const reader = new FileReader();
							reader.onload = function () {
								// const arrayBuffer = this.result;
								// const array = new Uint8Array(arrayBuffer);
								// console.log(array.slice(0, 40));
							};
							reader.readAsArrayBuffer(e.data);
							printCount += 1;
							scriptNodeRef.current.printCount = printCount;
						}

						sendMessage2("packet-sent", e.data);
					} else if (e.data.size === 0) {
						console.error("cant send blank data to deepgram", e.data);
					}
				} catch (error) {
					console.error(error);
				}
			};
			setMicrophoneStarted(true);
		} else {
			deepGramOnRef.current = false;
		}
	}
	/**
	 * Starts the DeepGram service.
	 *
	 * This function sets the deepGramOnRef.current to true, emits a "connect-deepgram" event to the socket,
	 * and starts the MediaRecorder with a timeslice of 500ms. It also sets up event handlers for the MediaRecorder's
	 * start, stop, and dataavailable events. If the dataavailable event is fired and deepGramOnRef.current is false,
	 * the function returns early. If the dataavailable event is fired and the data size is greater than 0, it emits
	 * a "packet-sent" event to the socket with the data. If the data size is 0, it logs an error.
	 *
	 * Finally, it sets the microphoneStarted state to true.
	 *
	 * TODO: Add more information if needed.
	 *
	 * @async
	 */
	async function startDeepGram() {
		console.log("startDeepGram");
		deepGramOnRef.current = true;
		console.log("connect-deepgram", userLanguageRef.current);
		sendMessage2("connect-deepgram", userLanguageRef.current);
		setAllowSimControls(true);

		await sleep(500);
		if (deepGramOnRef.current === false) {
			console.log("connect-deepgram", userLanguageRef.current);

			sendMessage2("connect-deepgram", userLanguageRef.current);
		}
		await sleep(500);
	}

	/**
	 * Stops the DeepGram service.
	 *
	 * This function sets the deepGramOnRef.current to false, stops the MediaRecorder if it's running,
	 * emits a "disconnect-deepgram" event to the socket, and sets the microphoneStarted state to false.
	 *
	 * @async
	 */
	async function stopDeepGram() {
		console.log("stopDeepGram");
		vad.pause();

		// Set deepGramOnRef.current to false
		deepGramOnRef.current = false;
		if (scriptNodeRef.current) {
			if (scriptNodeRef.current.node) {
				scriptNodeRef.current.node.port.onmessage = undefined;
				scriptNodeRef.current.node.disconnect();
				scriptNodeRef.current.node = undefined;
			}
			if (scriptNodeRef.current.source) {
				scriptNodeRef.current.source.disconnect();
				scriptNodeRef.current.source = undefined;
			}
		}

		// If the MediaRecorder is running, stop it
		if (microphoneRef.current) {
			microphoneRef.current.stop();
			microphoneRef.current.onstart = undefined;
			microphoneRef.current.onstop = undefined;
			microphoneRef.current.ondataavailable = undefined;
			microphoneRef.current = undefined;
		}

		try {
			// Emit a "disconnect-deepgram" event to the socket
			sendMessage2("disconnect-deepgram");
		} catch (e) {
			// Log any errors
			console.error(e);
		}
		printEventHistory();
		// Set the microphoneStarted state to false
		setMicrophoneStarted(false);
	}

	return {
		// avatarSpeechData: avatarSpeechDataRef.current,
		// avatarGptData: avatarGptDataRef.current,
		// userTranscriptData: userTranscriptDataRef.current,
		// updateAvatarSpeechData,
		// updateAvatarGptData,
		// updateUserTranscriptData,
		getTokenCount,
		getAvatarGptDataByTranscriptId,
		getAvatarSpeechData,
		createAvatarSpeechData,
		isPreviousRequestSynthesized,
		isResponseStale,
		getAvatarGptData,
		createAvatarGptData,
		getUserTranscriptData,
		getLastTranscriptId,
		createUserTranscriptData,
		stopDeepGram,
		startDeepGram,
		handleDeepgramConnection,
		microphoneStarted,
		finishAudioFile,
		startAudioFile,
		getChatHistory,
		getChatHistoryV2,
		getEventHistory,
		saveData,
		loadData,
		isTalkingRef,
		getCurrentlyPlayingAvatarSpeechData,
		getLastNonEmptyTranscript,

		finishGptEvent,
		finishSpeechEvent,
		finishTranscriptEvent
	};
}

function computeRMS(data) {
	const squaresSum = data.reduce((sum, value) => sum + value * value, 0);
	const meanSquare = squaresSum / data.length;
	const rms = Math.sqrt(meanSquare);
	// console.log("RMS Value:", rms);
	return rms;
}

export default useLiveData;
