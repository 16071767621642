import React, { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { FaCheck, FaTimes } from "react-icons/fa";
import r from "../../styleModules/reportStyles.module.css";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export default function Overall({
	sessionData,
	evaluationLoading,
	questionSummaryPercent,
	setQuestionSummaryPercent,
	currentSetupConfig,
	score,
	setScore,
	tempGrades,
	setTempGrades,
	contentGrade,
	setContentGrade,
	deliveryGrade,
	setDeliveryGrade,
	completionGrade,
	setCompletionGrade,
	otherGrade,
	setOtherGrade,
	preliminariesGrade,
	setPreliminariesGrade,
	spin1Grade,
	setSpin1Grade,
	spin2Grade,
	setSpin2Grade,
	budgetGrade,
	setBudgetGrade,
	fab1Grade,
	setFab1Grade,
	fab2Grade,
	setFab2Grade,
	objection1Grade,
	setObjection1Grade,
	objection2Grade,
	setObjection2Grade,
	closingGrade,
	setClosingGrade,
	overallRating,
	preliminariesRating,
	process1Rating,
	process2Rating,
	setRatings,
	outcome,
	setOutcome,
	summary,
	positives,
	improvements
}) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { runTranslation, adminAccess, tenantId, userLanguage, translateDictText, translateSingleText, performTranslation } =
		useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Content: "Content",
		Delivery: "Delivery",
		Completion: "Completion",
		Other: "Other",
		Score: "Score",
		Outcome: outcome,
		OnlyAdminsSeeScores: "Only admins see scores",
		WhatWentWell: "What went well",
		AreasToImprove: "Areas to improve",
		Weight: "weight",
		Positives: positives,
		Improvements: improvements,
		// ContentGrade: contentGrade,
		// DeliveryGrade: deliveryGrade,
		// CompletionGrade: completionGrade,
		// OtherGrade: otherGrade,
		Excellent: "Excellent",
		Good: "Good",
		Satisfactory: "Satisfactory",
		Overall: "Overall"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		// console.log("Grading Criteria: ", gradingCriteria);
		setTimeout(() => {
			const translateText = async () => {
				await performTranslation(pageText, setPageText);
			};
			translateText();
		}, 500);
	}, [userLanguage, positives, improvements, sessionData, score]); // Depend on pageText to trigger re-translation

	const Fedcap = "08bbffb4-7bac-4fdc-8a4c-dbf6e3230473";

	const [inputError, setInputError] = useState(false);
	const [pendingUpdates, setPendingUpdates] = useState({});
	const [outcomeChanged, setOutcomeChanged] = useState(false);

	const [translatedOutcomeText, setTranslatedOutcomeText] = useState("");
	const [translatedPositivesText, setTranslatedPositivesText] = useState("");
	const [translatedImprovementsText, setTranslatedImprovementsText] = useState("");
	const [translatedContentGradeText, setTranslatedContentGradeText] = useState("");
	const [translatedDeliveryGradeText, setTranslatedDeliveryGradeText] = useState("");
	const [translatedCompletionGradeText, setTranslatedCompletionGradeText] = useState("");

	useEffect(() => {
		const translateOutcomeText = async () => {
			if (userLanguage !== "en") {
				setTranslatedOutcomeText(await translateSingleText(outcome, userLanguage));
				setTranslatedPositivesText(await translateSingleText(positives, userLanguage));
				setTranslatedImprovementsText(await translateSingleText(improvements, userLanguage));
			} else {
				setTranslatedOutcomeText(outcome);
				setTranslatedPositivesText(positives);
				setTranslatedImprovementsText(improvements);
			}
			setTranslatedContentGradeText(contentGrade);
			setTranslatedDeliveryGradeText(deliveryGrade);
			setTranslatedCompletionGradeText(completionGrade);
		};
		translateOutcomeText();
	}, [outcome, pageText]);

	useEffect(() => {
		const UpdateScoreDB = async () => {
			// console.warn("Session Data: ", sessionData);
			if (sessionData.setup_type !== "interview") {
				return;
			}
			if (!score) {
				return;
			}
			const data = {
				overall_score: score.toString(),
				session_id: sessionData.session_id
			};
			// console.log("Score Data: ", data);
			try {
				const resp = await axiosLimitedPost(`${url}/api/report/${sessionData.setup_type}/UpdateOverallScore`, data, 1, {}, 10000);
				if (resp.status === 200) {
					// console.log("Score Updated To: ", score);
				}
			} catch (error) {
				console.log("Error updating summary grades:", error);
			}
		};

		UpdateScoreDB();
	}, [score]);

	useEffect(() => {
		setPageText({
			...LocalLanguageDict,
			Outcome: outcome,
			Positives: positives,
			Improvements: improvements
			// ContentGrade: contentGrade,
			// DeliveryGrade: deliveryGrade,
			// CompletionGrade: completionGrade,
			// OtherGrade: otherGrade
		});
	}, [outcome, positives, improvements, contentGrade, deliveryGrade, completionGrade, otherGrade]);

	// Grading
	const validKeys = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "Delete", "Tab", "ArrowLeft", "ArrowRight"];
	const gradingCriteria = [
		{
			key: "content",
			label: pageText.Content,
			grade: tempGrades.content ?? translatedContentGradeText,
			weight: sessionData.setup_output?.content_weight || 40
		},
		{
			key: "delivery",
			label: pageText.Delivery,
			grade: tempGrades.delivery ?? translatedDeliveryGradeText,
			weight: sessionData.setup_output?.delivery_weight || 40
		},
		{
			key: "completion",
			label: pageText.Completion,
			grade: tempGrades.completion ?? translatedCompletionGradeText,
			weight: sessionData.setup_output?.completion_weight || 20
		},
		// Include the "Other" field conditionally
		...(sessionData.setup_output?.other_weight > 0
			? [
					{
						key: "other",
						label: pageText.Other,
						grade: tempGrades.other ?? otherGrade,
						weight: sessionData.setup_output?.other_weight || 0
					}
			  ]
			: [])
	];

	function getColor(grade) {
		if (grade === null || grade === undefined) {
			return "#16013E"; // Default color
		}
		if (grade < 50) {
			return "#ee4188";
		}
		if (grade >= 80) {
			return "#00a9af";
		}
		return "#16013E"; // Default color for grades between 50 and 80
	}

	function getGradeColor(decimal) {
		const percentage = decimal * 100;
		if (percentage >= 70) return "#00a9af";
		if (percentage < 50) return "#ee4188";
		return "#16013E";
	}

	function getRatingColor(rating) {
		if (rating === pageText.Excellent) return "#00a9af";
		if (rating === pageText.Good) return "#16013E";
		if (rating === pageText.Satisfactory) return "#16013E";
		return "#ee4188";
	}

	const calculateTotalScore = (content, delivery, completion, other, weights) => {
		const totalWeight = weights.content_weight + weights.delivery_weight + weights.completion_weight + weights.other_weight;
		return Math.round(
			(content * weights.content_weight +
				delivery * weights.delivery_weight +
				completion * weights.completion_weight +
				other * weights.other_weight) /
				totalWeight
		);
	};

	const getWeights = (setup_output) => ({
		content_weight: setup_output?.content_weight || 40,
		delivery_weight: setup_output?.delivery_weight || 40,
		completion_weight: setup_output?.completion_weight || 20,
		other_weight: setup_output?.other_weight || 0
	});

	const handleGradeChange = (category, newValue) => {
		setInputError(false);
		setTempGrades({
			...tempGrades,
			[category]: newValue
		});
		setPendingUpdates({
			...pendingUpdates,
			[category]: true
		});
	};

	const saveAllGrades = async () => {
		// Initialize a dictionary to hold parsed grades for each category
		const parsedGrades = {
			content: tempGrades.content !== null ? parseInt(tempGrades.content, 10) : contentGrade,
			delivery: tempGrades.delivery !== null ? parseInt(tempGrades.delivery, 10) : deliveryGrade,
			completion: tempGrades.completion !== null ? parseInt(tempGrades.completion, 10) : completionGrade,
			other: tempGrades.other !== null ? parseInt(tempGrades.other, 10) : otherGrade,

			// Sales Sims
			preliminaries: tempGrades.preliminaries !== null ? parseInt(tempGrades.preliminaries, 10) : preliminariesGrade,
			spin1: tempGrades.spin1 !== null ? parseInt(tempGrades.spin1, 10) : spin1Grade,
			spin2: tempGrades.spin2 !== null ? parseInt(tempGrades.spin2, 10) : spin2Grade,
			budget: tempGrades.budget !== null ? parseInt(tempGrades.budget, 10) : budgetGrade,
			fab1: tempGrades.fab1 !== null ? parseInt(tempGrades.fab1, 10) : fab1Grade,
			fab2: tempGrades.fab2 !== null ? parseInt(tempGrades.fab2, 10) : fab2Grade,
			objection1: tempGrades.objection1 !== null ? parseInt(tempGrades.objection1, 10) : objection1Grade,
			objection2: tempGrades.objection2 !== null ? parseInt(tempGrades.objection2, 10) : objection2Grade,
			closing: tempGrades.closing !== null ? parseInt(tempGrades.closing, 10) : closingGrade
		};

		// Validate each parsed grade
		Object.entries(parsedGrades).every(([category, grade]) => {
			if (Number.isNaN(grade) || grade < 0 || grade > 100) {
				console.log(`Invalid input for ${category}`);
				setInputError(true);
				return false;
			}
			return true;
		});

		// Calculate the new total score based on the grades
		const weights = getWeights(sessionData.setup_output);
		const newTotalScore = calculateTotalScore(parsedGrades.content, parsedGrades.delivery, parsedGrades.completion, parsedGrades.other, weights);

		// Create the data object to be sent to the API, including the calculated total
		const data = {
			session_data: {
				audience: sessionData.audience,
				session_id: sessionData.session_id,
				setup_type: sessionData.setup_type,
				passing_grade: parseInt(sessionData.setup_output?.passing_grade, 10) || 50
			},
			total: newTotalScore,
			...parsedGrades
		};

		// Perform the API call
		if (["discovery_spin", "closing"].includes(sessionData.setup_type)) {
			try {
				const apiPath = `/api/report/${sessionData.setup_type}`;
				const resp = await axiosLimitedPost(`${url}${apiPath}/GradeOverride`, data, 1, {}, 10000);
				// console.log("Grade override response:", resp.data.outcome);
				if (resp.status === 200) {
					// Update the outcome
					setOutcome(resp.data.outcome);
					// Update the state variables to reflect the new grades
					setPreliminariesGrade(parsedGrades.preliminaries);
					setSpin1Grade(parsedGrades.spin1);
					setSpin2Grade(parsedGrades.spin2);
					setBudgetGrade(parsedGrades.budget);
					setFab1Grade(parsedGrades.fab1);
					setFab2Grade(parsedGrades.fab2);
					setObjection1Grade(parsedGrades.objection1);
					setObjection2Grade(parsedGrades.objection2);
					setClosingGrade(parsedGrades.closing);
					let process1Value;
					let process2Value;
					if (sessionData.setup_type === "discovery_spin") {
						process1Value = parsedGrades.spin1 + parsedGrades.spin2 + parsedGrades.budget;
					} else if (sessionData.setup_type === "closing") {
						process1Value = parsedGrades.fab1 + parsedGrades.fab2 + parsedGrades.closing;
						process2Value = parsedGrades.objection1 + parsedGrades.objection2;
					}
					const overallValue = parsedGrades.preliminaries + process1Value + (process2Value || 0);
					setRatings({
						currentSetupType: sessionData.setup_type,
						preliminariesValue: parsedGrades.preliminaries,
						process1Value,
						process2Value,
						overallValue
					});

					// Reset the temporary grades and clear pending updates
					setPendingUpdates({});
				}
			} catch (error) {
				console.log("Error updating grades:", error);
			}
		} else {
			try {
				const resp = await axiosLimitedPost(`${url}/api/report/${sessionData.setup_type}/QuestionSummaryOverride`, data, 1, {}, 10000);
				if (resp.status === 200) {
					// Update the state variables to reflect the new grades
					setContentGrade(parsedGrades.content);
					setDeliveryGrade(parsedGrades.delivery);
					setCompletionGrade(parsedGrades.completion);
					setOtherGrade(parsedGrades.other);
					setScore(newTotalScore);
					setOutcomeChanged(true);
					setOutcome(resp.data.outcome);

					// Reset the temporary grades and clear pending updates
					setPendingUpdates({});
				}
			} catch (error) {
				console.log("Error updating summary grades:", error);
			}
		}
	};

	function gradingSection(sectionCriteriaKeys, sectionTitle) {
		let rating;
		switch (sectionTitle) {
			case "Opening":
				rating = preliminariesRating;
				break;
			case "Needs Discovery":
				rating = process1Rating;
				break;
			case "Sales Process":
				rating = process1Rating;
				break;
			case "Objection Handling":
				rating = process2Rating;
				break;
			default:
				rating = "N/A";
				break;
		}
		// useEffect(() => {
		// 	runTranslation();
		// });
		return (
			<>
				<p className={r.reportStatusText}>
					{sectionTitle}:<span style={{ color: getRatingColor(rating) }}> {rating}</span>
				</p>
				{sectionCriteriaKeys.map((key) => {
					const { label, grade, possibleTotal } = currentSetupConfig.gradingCriteria.find((crit) => crit.key === key);
					const value = tempGrades[key] !== null ? tempGrades[key] : grade;

					return (
						<div key={key} className={r.overallScoreInputContainer}>
							<label className={r.overallScoreLabel}>{label}: </label>
							{adminAccess ? (
								<input
									className={r.overallScoreInput}
									value={value}
									onChange={(e) => handleGradeChange(key, e.target.value)}
									onKeyDown={(e) => {
										if (!validKeys.includes(e.key)) {
											e.preventDefault();
										}
									}}
								/>
							) : (
								<p className={r.overallScorePossibleTotal} style={{ color: getGradeColor(value / possibleTotal) }}>
									{value}
								</p>
							)}
							<p className={r.overallScorePossibleTotal}>/ {possibleTotal}</p>
						</div>
					);
				})}
			</>
		);
	}

	return (
		<div className={r.reportSectionContainer}>
			{evaluationLoading && (
				<>
					{sessionData.setup_type === "interview" ? (
						<LoadingBar state={questionSummaryPercent} setter={setQuestionSummaryPercent} sessionData={sessionData} />
					) : (
						<div className={r.spinnerContainer}>
							<LoadingSpinner height={50} width={50} thickness={5} color="#00a9af" />
						</div>
					)}
				</>
			)}
			{!evaluationLoading && (
				<div>
					{sessionData &&
						(!sessionData.setup_output?.assignment_type ||
							adminAccess ||
							["discovery_spin", "closing"].includes(sessionData.setup_type)) && (
							<>
								{outcome && !outcomeChanged && tenantId !== Fedcap && (
									<div
										id="outcomeContainer"
										className={r.outcomeContainer}
										style={{ backgroundColor: outcome.includes("Unsuccessful") ? "#7d7d7d" : "#4BA6AD" }}
									>
										<p className={r.overallText}>
											<span
												id="outcomeIconCircle"
												className={r.outcomeIconCircle}
												style={{ backgroundColor: outcome.includes("Unsuccessful") ? "#AFAFAF" : "#87BAC0" }}
											>
												{outcome.includes("Unsuccessful") ? (
													<FaTimes id="outcomeIcon" className={r.outcomeIcon} />
												) : (
													<FaCheck id="outcomeIcon" className={r.outcomeIcon} />
												)}
											</span>
											{translatedOutcomeText}
										</p>
									</div>
								)}
								{outcome && outcomeChanged && tenantId !== Fedcap && (
									<div
										id="outcomeContainer"
										className={r.outcomeContainer}
										style={{ backgroundColor: outcome.includes("Unsuccessful") ? "#7d7d7d" : "#4BA6AD" }}
									>
										<div
											id="outcomeIconCircle"
											className={r.outcomeIconCircle}
											style={{ backgroundColor: outcome.includes("Unsuccessful") ? "#AFAFAF" : "#87BAC0" }}
										>
											{outcome.includes("Unsuccessful") ? (
												<FaTimes className={r.outcomeIcon} />
											) : (
												<FaCheck className={r.outcomeIcon} />
											)}
										</div>
										<p id="outcomeText" className={r.overallText}>
											{outcome}
										</p>
									</div>
								)}
							</>
						)}
					{["discovery_spin", "closing"].includes(sessionData.setup_type) && (
						<>
							<div className={r.overallScoreInputSection}>
								{gradingSection(["preliminaries"], "Opening")}

								{sessionData.setup_type === "discovery_spin" && gradingSection(["spin1", "spin2", "budget"], "Needs Discovery")}

								{sessionData.setup_type === "closing" && (
									<>
										{gradingSection(["fab1", "fab2", "closing"], "Sales Process")}
										{gradingSection(["objection1", "objection2"], "Objection Handling")}
									</>
								)}
								{Object.keys(pendingUpdates).length > 0 && (
									<div className={r.saveScoreBtnContainer}>
										<button type="button" className={r.saveScoreBtn} onClick={saveAllGrades}>
											Save All
										</button>
										<p className={r.saveScoreError}>{inputError ? "Scores must be 0-100" : ""}</p>
									</div>
								)}
							</div>

							<p className={r.reportStatusText}>
								{pageText.Overall}:<span style={{ color: getRatingColor(overallRating) }}> {overallRating}</span>
							</p>
							<div className={r.overallScoreInputContainer}>
								<p className={r.overallScoreLabel}>Overall: </p>
								<p className={r.overallScorePossibleTotal}>
									{currentSetupConfig.gradingCriteria.reduce((total, item) => total + Number(item.grade), 0)}
								</p>
								<p className={r.overallScorePossibleTotal}>
									/ {currentSetupConfig.gradingCriteria.reduce((acc, item) => acc + item.possibleTotal, 0)}
								</p>
							</div>
						</>
					)}
					{adminAccess && sessionData.setup_type === "interview" && score !== null && (
						<div className={r.overallScoreContainer}>
							<p id="scoreText" className={r.outcomeSubheading}>
								{pageText.Score}: <span style={{ width: "3.5rem", color: getColor(score) }}>{score}</span>
							</p>
							<div className={r.overallScoreInputSection}>
								{gradingCriteria &&
									gradingCriteria.map(({ key, label, grade, weight }) => (
										<div key={key} className={r.overallScoreInputContainer}>
											<label htmlFor="scoreInput" className={r.overallScoreLabel}>
												{label}:
											</label>
											<input
												id="scoreInput"
												className={r.overallScoreInput}
												value={grade}
												onChange={(e) => handleGradeChange(key, e.target.value)}
												onKeyDown={(e) => {
													if (!validKeys.includes(e.key)) {
														e.preventDefault();
													}
												}}
											/>
											<p className={r.overallScoreWeight}>
												({weight}% {pageText.Weight})
											</p>
										</div>
									))}
								{Object.keys(pendingUpdates).length > 0 && (
									<div className={r.saveScoreBtnContainer}>
										<button type="button" className={r.saveScoreBtn} onClick={saveAllGrades}>
											Save All
										</button>
										<p className={r.saveScoreError}>{inputError ? "Scores must be 0-100" : ""}</p>
									</div>
								)}
							</div>
							<p className={r.overallScoreNote}>*{pageText.OnlyAdminsSeeScores}</p>
						</div>
					)}
				</div>
			)}

			{["discovery_spin", "closing"].includes(sessionData.setup_type) ? (
				<div style={{ marginTop: "2rem" }}>{!evaluationLoading && <p className={r.reportText}>{summary}</p>}</div>
			) : (
				<>
					{!evaluationLoading && (
						<>
							<h2 className={r.outcomeSubheading}>{pageText.WhatWentWell}</h2>
							<p className={r.reportText}>{translatedPositivesText}</p>

							<h2 className={r.outcomeSubheading}>{pageText.AreasToImprove}</h2>
							<p className={r.reportText}>{translatedImprovementsText}</p>
						</>
					)}
				</>
			)}
		</div>
	);
}

function LoadingBar({ state, setter, sessionData }) {
	const questionSummaryLoadTime = 150;

	const { runTranslation, adminAccess, tenantId, userLanguage, translateDictText, translateSingleText, performTranslation } =
		useContext(AppContext);
	const { axiosLimitedPost } = useAxiosLimited();

	const [hasReset, setHasReset] = useState(false);

	// Static Translation
	const LocalLanguageDict = {
		Generating: "Generating...",
		ReportIsTakingLonger: "Report is taking longer to load than usual. Please check back later.",
		RetryGenerate: "Retry Generate"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const resetReport = async () => {
		console.log("Resetting report");
		const { session_id, setup_type } = sessionData;
		if (!session_id || !setup_type) {
			console.error("Session data is missing session_id or setup_type");
			return;
		}
		try {
			const resp = await axiosLimitedPost(`${url}/api/sessionData/session/reset`, { session_id, setup_type }, 1, {});
			window.location.reload();
		} catch (error) {
			console.error("Error resetting report:", error);
		}
	};

	// Usage
	useEffect(() => {
		// console.log("Grading Criteria: ", gradingCriteria);
		setTimeout(() => {
			const translateText = async () => {
				await performTranslation(pageText, setPageText);
			};
			translateText();
		}, 500);
	}, [userLanguage, pageText]); // Depend on pageText to trigger re-translation

	useEffect(() => {
		const intervalId = setInterval(() => {
			setter((oldPercent) => {
				if (oldPercent >= 100) {
					clearInterval(intervalId);
					return 100;
				}
				return oldPercent + 100 / questionSummaryLoadTime;
			});
		}, 1000); // every second

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		if (state >= 100 && !hasReset) {
			// Check if reset has not been called and session cache does not indicate a reset has occurred
			// if (!sessionStorage.getItem("reportReset")) {
			// 	resetReport(); // Call resetReport if loading takes too long
			// 	sessionStorage.setItem("reportReset", true); // Set session cache to prevent further resets
			// }
			setHasReset(true); // Set hasReset to true to prevent further calls
		}
	}, [state, hasReset]); // Add hasReset as a dependency

	return (
		<>
			{state < 100 ? (
				<div className={r.loadingBar}>
					<div className={r.loadingFill} style={{ width: `${state}%` }}>
						<p className={r.loadingText}>{pageText.Generating}</p>
					</div>
				</div>
			) : (
				<>
					<p className={r.reportErrorText}>{pageText.ReportIsTakingLonger}</p>
					<button type="button" className={r.resetReportBtn} onClick={resetReport}>
						{pageText.RetryGenerate}
					</button>
				</>
			)}
		</>
	);
}
