import { useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

function SharePage() {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		// Decode the redirectUrl to ensure special characters are correctly interpreted
		const decodedRedirectUrl = decodeURIComponent(params["*"]);
		// Extract the base path before "/accId" and preserve the rest of the path and query params
		const basePath = decodedRedirectUrl.split("/accId")[0];
		// Reconstruct the URL with the base path and original query parameters
		const newUrl = `/${basePath}${location.search}`;

		navigate(newUrl);
	}, [params, navigate, location.search]);

	return null;
}

export default SharePage;
