import React, { useState, useRef, useEffect } from "react";
import styles from "../../styleModules/adminSystemStyles.module.css";

export default function ReflectionDataTest() {
	const [selectedWeek, setSelectedWeek] = useState("all");
	const [selectedTab, setSelectedTab] = useState("overall-summary");

	const weeks = [
		{ id: "all", label: "All Weeks", date: "" },
		{ id: "1", label: "Week 1", date: "July 12" },
		{ id: "2", label: "Week 2", date: "July 19" },
		{ id: "3", label: "Week 3", date: "July 26" },
		{ id: "4", label: "Week 4", date: "Aug 2", disabled: true }
	];

	const allWeeksTabs = ["Overall Summary", "Aggregate Themes"];
	const weeklyTabs = [
		"Summary",
		"Skills",
		"Success Stories",
		"Challenges",
		"Areas to Improve",
		"Student Feedback",
		"Potentially Concerning Comments"
	];

	const allWeeksData = {
		overallSummary: {
			reflectionsAssigned: "11/12",
			completionRate: "92%",
			overallSatisfactionScore: 8.5
		},
		aggregateThemes: {
			overallSatisfaction:
				"Students generally expressed high levels of satisfaction with their co-op experiences. Common positive feedback included appreciation for hands-on experience with cutting-edge technologies, opportunities to bridge academic knowledge with practical applications, and personal and professional growth through challenging projects.",
			skillsEngaged: [
				"Machine Learning and AI techniques",
				"Data analysis and preprocessing",
				"Problem-solving and troubleshooting",
				"Collaboration and teamwork",
				"Research and continuous learning",
				"Audio and video processing",
				"Real-time application development"
			],
			challenges: [
				"Technical difficulties with specialized software and hardware",
				"Balancing performance and efficiency in model development",
				"Adapting to real-world project constraints and requirements",
				"Keeping up with rapidly advancing technologies",
				"Resource limitations (e.g., GPU access, processing power)"
			],
			areasToImprove: [
				"More guidance on advanced preprocessing techniques",
				"Additional support for handling latency issues in real-time applications",
				"More resources for selecting appropriate models and technologies for specific project goals",
				"Improved access to high-performance computing resources",
				"More opportunities for collaboration and knowledge sharing among peers"
			],
			studentFeedback: [
				"Emphasis on the importance of continuous learning and adaptation",
				"Recognition of the value of practical experience in enhancing academic knowledge",
				"Appreciation for the opportunity to work on real-world projects with industry-relevant technologies",
				"Desire for more networking opportunities within the industry",
				"Interest in exploring niche areas within AI and machine learning for future specialization"
			]
		},
		trendAnalysis: {
			participationTrends: [
				{ week: 1, completionRate: 100 },
				{ week: 2, completionRate: 100 },
				{ week: 3, completionRate: 75 }
			],
			satisfactionTrends: [
				{ week: 1, satisfaction: 8 },
				{ week: 2, satisfaction: 8.5 },
				{ week: 3, satisfaction: 9 }
			],
			skillDevelopmentTrends: [
				{
					week: 1,
					skills: {
						"Machine Learning/AI": 5,
						"Data Analysis": 4,
						"Problem-solving": 3,
						Collaboration: 3,
						Research: 4,
						"Audio/Video Processing": 5,
						"Real-time Development": 2
					}
				},
				{
					week: 2,
					skills: {
						"Machine Learning/AI": 5,
						"Data Analysis": 4,
						"Problem-solving": 4,
						Collaboration: 4,
						Research: 3,
						"Audio/Video Processing": 5,
						"Real-time Development": 4
					}
				},
				{
					week: 3,
					skills: {
						"Machine Learning/AI": 5,
						"Data Analysis": 5,
						"Problem-solving": 5,
						Collaboration: 4,
						Research: 4,
						"Audio/Video Processing": 5,
						"Real-time Development": 5
					}
				}
			],
			challengeTrends: [
				{
					week: 1,
					challenges: {
						"Technical Difficulties": 3,
						"Performance Optimization": 4,
						"Real-world Constraints": 3,
						"Keeping Up with Tech": 5,
						"Resource Limitations": 2
					}
				},
				{
					week: 2,
					challenges: {
						"Technical Difficulties": 5,
						"Performance Optimization": 5,
						"Real-world Constraints": 4,
						"Keeping Up with Tech": 4,
						"Resource Limitations": 4
					}
				},
				{
					week: 3,
					challenges: {
						"Technical Difficulties": 4,
						"Performance Optimization": 5,
						"Real-world Constraints": 5,
						"Keeping Up with Tech": 3,
						"Resource Limitations": 5
					}
				}
			]
		}
	};

	const week1Data = {
		summary: {
			totalStudents: 4,
			completionRate: "100%",
			positiveExperiences: 4,
			flags: 0,
			satisfactionWithExperience:
				"All four students reported high levels of satisfaction with their Industry Project experiences. Common positive feedback included significant personal and professional growth, hands-on experience bridging academic knowledge with real-world applications, increased technical skills and confidence in tackling complex tasks, and reinforced passion for continuous learning and adaptation to new technologies."
		},
		skillsEngaged: [
			"Machine learning model integration and optimization",
			"Video generation and animation techniques",
			"Data preprocessing and handling large datasets",
			"Real-time processing and low-latency performance optimization",
			"Emotion recognition and sentiment analysis",
			"Natural Language Processing (NLP)",
			"Audio-to-text and audio-to-face conversions",
			"GitHub and version control"
		],
		successStories: [
			"A student successfully integrated the Wave2Vec model into a Streamlit application for voice transcriptions.",
			"One student optimized 3D animations using phonemes, improving efficiency without GPU acceleration.",
			"A student trained and retrained an emotion classification model using Hugging Face on textual data.",
			"One student worked on a complex NLP project involving Omniverse audio-to-face and audio-to-text conversions with a large Chinese dataset."
		],
		challenges: [
			"Staying updated with rapidly advancing technologies in machine learning",
			"Ensuring low-latency performance in real-time applications",
			"Familiarization with new and advanced models",
			"Balancing research with practical application considerations",
			"Working with resource-intensive models and large datasets",
			"Implementing complex components (e.g., audio-to-face conversion)"
		],
		areasToImprove: [
			"Provide more guidance on balancing research and practical application",
			"Offer support for accessing and managing computational resources",
			"Enhance training on troubleshooting and problem-solving for specialized software",
			"Facilitate networking opportunities with industry professionals",
			"Provide strategies for efficient technology selection and evaluation"
		],
		studentFeedback: [
			"Strong appreciation for hands-on experience with cutting-edge technologies",
			"Desire to specialize in niche areas of machine learning and AI",
			"Recognition of the importance of continuous learning and adaptability",
			"Interest in developing both technical and soft skills",
			"Enthusiasm for applying academic knowledge to real-world challenges"
		],
		potentiallyConcerningComments: {
			highUrgency: [],
			moderateUrgency: [],
			lowUrgency: []
		}
	};

	const week2Data = {
		summary: {
			totalStudents: 4,
			completionRate: "100%",
			positiveExperiences: 4,
			flags: 0,
			satisfactionWithExperience:
				"All four students continued to show high levels of engagement and satisfaction with their projects. They reported significant progress in overcoming technical challenges, gaining valuable insights into real-world applications, and improving their adaptability in the work environment."
		},
		skillsEngaged: [
			"Integration of machine learning models into practical applications",
			"Real-time performance optimization for ML models",
			"3D animation optimization using phonemes",
			"Audio-to-face technology implementation",
			"Emotion recognition model comparison (audio vs. text)",
			"Problem-solving and troubleshooting complex software",
			"Collaboration and seeking feedback from colleagues and online communities"
		],
		successStories: [
			"A student successfully integrated the Wave2Vec model into a Streamlit application for voice transcriptions.",
			"One student optimized 3D animations using phonemes, significantly enhancing efficiency without GPU acceleration.",
			"A student gained valuable insights into the limitations and capabilities of audio vs. text emotion recognition models.",
			"One student demonstrated resilience in tackling complex technical challenges with NVIDIA's audio-to-face technology."
		],
		challenges: [
			"Balancing model accuracy with real-time performance requirements",
			"Hardware and resource limitations (e.g., GPU constraints, limited laptop specs)",
			"Implementing parallel processing with limited hardware capabilities",
			"Navigating complex software installations and troubleshooting (e.g., NVIDIA audio-to-face software)",
			"Finding resources and support for specialized technologies",
			"Managing long training times and GPU usage limits"
		],
		areasToImprove: [
			"Provide more guidance on efficient resource management and alternative platforms for model training",
			"Offer support for troubleshooting and problem-solving with specialized software",
			"Enhance training on balancing research with practical application",
			"Facilitate more opportunities for feedback and collaboration within the company",
			"Provide strategies for setting realistic goals and selecting appropriate technologies for projects"
		],
		studentFeedback: [
			"Increased appreciation for the importance of real-time performance in practical applications",
			"Recognition of the value of seeking feedback and exploring different approaches",
			"Growing comfort and adaptability in the work environment",
			"Awareness of the need for proactive career development and industry engagement",
			"Enthusiasm for applying insights from challenges to improve future work"
		],
		potentiallyConcerningComments: {
			highUrgency: [],
			moderateUrgency: [],
			lowUrgency: [
				"Some students experienced frustration with hardware limitations and complex software requirements, which might need addressing to ensure continued progress."
			]
		}
	};

	const week3Data = {
		summary: {
			totalStudents: 4,
			completionRate: "75%",
			positiveExperiences: 3,
			flags: 2,
			satisfactionWithExperience:
				"Three out of four reporting students showed high levels of engagement, enthusiasm, and proactivity in their respective projects. They demonstrated progress in overcoming technical challenges and applying their skills to real-world problems."
		},
		skillsEngaged: [
			"Machine Learning and AI implementation",
			"Data analysis and model improvement",
			"Problem-solving for technical challenges",
			"Collaboration and teamwork",
			"Research on relevant technologies and models",
			"Speech recognition and emotional recognition systems",
			"Integration of ML models with animations",
			"Working with NVIDIA technology on AWS"
		],
		successStories: [
			"A student successfully integrated their machine learning model with animations, making significant progress in lip synchronization and audio processing tasks.",
			"One student advanced in researching live streaming technologies and working with NVIDIA technology on AWS.",
			"Another student made strides in developing speech recognition models and identified essential models for their project goals."
		],
		challenges: [
			"Technical issues with app installation and model performance",
			"Model optimization for improved latency and accuracy",
			"Selecting appropriate technologies for specific project goals",
			"Handling audio offsets and synchronization",
			"Working with limited hardware capabilities"
		],
		areasToImprove: [
			"Provide guidance on advanced preprocessing techniques for improved model performance",
			"Offer support in handling latency issues for real-time applications",
			"Enhance resources for selecting appropriate models and technologies for specific project goals",
			"Facilitate more opportunities for collaboration and knowledge sharing among students"
		],
		studentFeedback: [
			"Appreciation for working on cutting-edge technologies and real-world problems",
			"Positive sentiment towards collaboration and teamwork",
			"Value placed on the iterative learning process and continuous improvement",
			"Recognition of the importance of preprocessing and iterative testing",
			"Interest in exploring various models and datasets for optimal performance"
		],
		potentiallyConcerningComments: {
			highUrgency: [],
			moderateUrgency: [],
			lowUrgency: [
				"Students might benefit from additional resources or guidance on advanced preprocessing techniques and handling latency issues in real-time applications.",
				"More support in selecting appropriate models and technologies for specific project goals could be beneficial."
			]
		}
	};

	const renderContent = () => {
		if (selectedWeek === "all") {
			switch (selectedTab) {
				case "overall-summary":
					return (
						<div className={styles.subsectionContent}>
							<h3 className={styles.sectionTitle}>Overall Summary</h3>
							<p>Reflections Assigned: {allWeeksData.overallSummary.reflectionsAssigned}</p>
							<p>Completion Rate: {allWeeksData.overallSummary.completionRate}</p>
							<p>Overall Satisfaction Score: {allWeeksData.overallSummary.overallSatisfactionScore}</p>
						</div>
					);
				case "aggregate-themes":
					return (
						<div className={styles.subsectionContent}>
							<h3 className={styles.sectionTitle}>Aggregate Themes</h3>
							<h4 className={styles.subsectionTitle}>Overall Satisfaction</h4>
							<p>{allWeeksData.aggregateThemes.overallSatisfaction}</p>
							<h4 className={styles.subsectionTitle}>Skills Engaged</h4>
							<ul className={styles.bulletList}>
								{allWeeksData.aggregateThemes.skillsEngaged.map((skill, index) => (
									<li key={index}>{skill}</li>
								))}
							</ul>
							<h4 className={styles.subsectionTitle}>Challenges</h4>
							<ul className={styles.bulletList}>
								{allWeeksData.aggregateThemes.challenges.map((challenge, index) => (
									<li key={index}>{challenge}</li>
								))}
							</ul>
							<h4 className={styles.subsectionTitle}>Areas to Improve</h4>
							<ul className={styles.bulletList}>
								{allWeeksData.aggregateThemes.areasToImprove.map((area, index) => (
									<li key={index}>{area}</li>
								))}
							</ul>
							<h4 className={styles.subsectionTitle}>Student Feedback</h4>
							<ul className={styles.bulletList}>
								{allWeeksData.aggregateThemes.studentFeedback.map((feedback, index) => (
									<li key={index}>{feedback}</li>
								))}
							</ul>
						</div>
					);
				default:
					return null;
			}
		} else {
			const weekData =
				{
					1: week1Data,
					2: week2Data,
					3: week3Data
				}[selectedWeek] || week3Data;
			switch (selectedTab) {
				case "summary":
					return (
						<div className={styles.subsectionContent}>
							<h3 className={styles.sectionTitle}>Summary</h3>
							<p>
								<strong>Total Students:</strong> {weekData.summary.totalStudents}
							</p>
							<p>
								<strong>Completion Rate:</strong> {weekData.summary.completionRate}
							</p>
							<p>
								<strong>Positive Experiences:</strong> {weekData.summary.positiveExperiences}
							</p>
							<p>
								<strong>Flags:</strong> {weekData.summary.flags}
							</p>
							<h4 className={styles.subsectionTitle}>Satisfaction with Experience</h4>
							<p>{weekData.summary.satisfactionWithExperience}</p>
						</div>
					);
				case "skills":
					return (
						<div className={styles.subsectionContent}>
							<h3 className={styles.sectionTitle}>Skills Engaged</h3>
							<ul className={styles.bulletList}>
								{weekData.skillsEngaged.map((skill, index) => (
									<li key={index}>{skill}</li>
								))}
							</ul>
						</div>
					);
				case "success-stories":
					return (
						<div className={styles.subsectionContent}>
							<h3 className={styles.sectionTitle}>Success Stories</h3>
							<ul className={styles.bulletList}>
								{weekData.successStories.map((story, index) => (
									<li key={index}>{story}</li>
								))}
							</ul>
						</div>
					);
				case "challenges":
					return (
						<div className={styles.subsectionContent}>
							<h3 className={styles.sectionTitle}>Challenges</h3>
							<ul className={styles.bulletList}>
								{weekData.challenges.map((challenge, index) => (
									<li key={index}>{challenge}</li>
								))}
							</ul>
						</div>
					);
				case "areas-to-improve":
					return (
						<div className={styles.subsectionContent}>
							<h3 className={styles.sectionTitle}>Areas to Improve</h3>
							<ul className={styles.bulletList}>
								{weekData.areasToImprove.map((area, index) => (
									<li key={index}>{area}</li>
								))}
							</ul>
						</div>
					);
				case "student-feedback":
					return (
						<div className={styles.subsectionContent}>
							<h3 className={styles.sectionTitle}>Student Feedback</h3>
							<ul className={styles.bulletList}>
								{weekData.studentFeedback.map((feedback, index) => (
									<li key={index}>{feedback}</li>
								))}
							</ul>
						</div>
					);
				case "potentially-concerning-comments":
					return (
						<div className={styles.subsectionContent}>
							<h3 className={styles.sectionTitle}>Potentially Concerning Comments</h3>
							{Object.entries(weekData.potentiallyConcerningComments).map(([urgency, comments]) => (
								<div key={urgency}>
									<h4 className={styles.subsectionTitle}>
										{urgency.replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase())}
									</h4>
									<ul className={styles.bulletList}>
										{comments.length > 0 ? comments.map((comment, index) => <li key={index}>{comment}</li>) : <li>N/A</li>}
									</ul>
								</div>
							))}
						</div>
					);
				default:
					return null;
			}
		}
	};

	return (
		<div className={styles.adminPage}>
			<div className={styles.adminPageContainer}>
				<h1 className={styles.title}>NE x InStage Studio Program</h1>
				<h2 className={styles.subtitle}>Aggregate Report Data for All Reflections</h2>

				<div className={styles.weekSelector}>
					{weeks.map((week) => (
						<button
							key={week.id}
							onClick={() => {
								if (!week.disabled) {
									setSelectedWeek(week.id);
									setSelectedTab(week.id === "all" ? "overall-summary" : "summary");
								}
							}}
							className={`${styles.weekButton} ${
								week.disabled ? styles.weekButtonDisabled : selectedWeek === week.id ? styles.weekButtonActive : ""
							}`}
							disabled={week.disabled}
						>
							<div>{week.label}</div>
							<div className={styles.weekDate}>{week.date}</div>
						</button>
					))}
				</div>

				<div className={styles.subsectionContainer}>
					<div className={styles.subsectionTabContainer}>
						{(selectedWeek === "all" ? allWeeksTabs : weeklyTabs).map((tab) => (
							<button
								key={tab}
								onClick={() => setSelectedTab(tab.toLowerCase().replace(/ /g, "-"))}
								className={`${styles.tabButton} ${
									selectedTab === tab.toLowerCase().replace(/ /g, "-") ? styles.tabButtonActive : ""
								}`}
							>
								{tab}
							</button>
						))}
					</div>

					<div className="p-6">{renderContent()}</div>
				</div>
			</div>
		</div>
	);
}
