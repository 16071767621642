import React, { useState } from "react";
import { IoClose } from "react-icons/io5/index.esm.js";
import styles from "../../styleModules/modalStyles.module.css";
import ModalWrapper from "./ModalWrapper.js";
import { validateEmail } from "../UtilityFunctions/ValidateInputs.js";

export function PostSessionModal({ show, onHide, onSave }) {
	const [value, setValue] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const handleSave = () => {
		if (validateEmail(value)) {
			console.log("onSave", value);
			onSave(value);
			onHide();
		} else {
			setErrorMessage("Please enter a valid email address");
		}
	};

	return (
		<>
			{show && (
				<ModalWrapper closeModal={() => onHide()}>
					<div className={styles.modal}>
						<span className={styles.modalCloseIcon}>
							<IoClose title="Close icon" onClick={() => onHide()} />
						</span>
						<h1 className={styles.modalTitle}>Thanks for Trying InStage!</h1>
						<div>
							<p style={{ fontSize: "20px" }}>
								We hope you enjoyed your experience. To help us improve and to keep you updated with new features, please provide your
								email below.
							</p>
							<input
								className={styles.modalInput}
								style={{ margin: "0 0 25px", fontSize: "20px" }}
								type="email"
								value={value}
								placeholder="Your email address"
								onChange={(e) => {
									setErrorMessage("");
									setValue(e.target.value);
								}}
							/>
							<label className="form-check-label" style={{ fontSize: "15px" }} htmlFor="flexCheckDefault">
								By providing my email, I agree to receive updates from InStage and accept the InStage{" "}
								<a target="_blank" href="https://www.instagepractice.com/terms" rel="noreferrer">
									Terms of Service
								</a>{" "}
								and{" "}
								<a target="_blank" href="https://www.instagepractice.com/privacy" rel="noreferrer">
									Privacy Policy
								</a>
								.
							</label>
						</div>
						<p style={{ width: "100%", color: "red", margin: "25px 0 0" }}>{errorMessage}</p>
						<div className={styles.modalBtnContainer}>
							<button className={styles.modalCancelBtn} onClick={onHide}>
								Cancel
							</button>
							<button className={styles.modalConfirmBtn} onClick={handleSave}>
								Accept
							</button>
						</div>
					</div>
				</ModalWrapper>
			)}
		</>
	);
}

export default PostSessionModal;
