import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import FlipNumbers from "react-flip-numbers";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { Tab } from "./Tab.js";
import { SimActivity } from "./SimActivity.js";
import { AggregateStatsList } from "./AggregateStatsList.js";
import styles from "../../styleModules/adminSystemStyles.module.css";

export const url = process.env.REACT_APP_BACKEND_STATIC_URL;

// function ScreenRecord() {
// 	const videoRef = useRef();
// 	const mediaRecorderRef = useRef();
// 	const [captureOn, setCaptureOn] = useState(false);

// 	useEffect(() => {
// 		if (!navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
// 			console.log("getDisplayMedia is not supported");
// 		}
// 	}, []);
// 	// Handling the recorded chunks
// 	const handleDataAvailable = (event) => {
// 		console.log("data-available");
// 		if (event.data.size > 0) {
// 			// You can create a blob from the data and download or send it to server
// 			const blob = new Blob([event.data], { type: "video/webm" });
// 			const url = URL.createObjectURL(blob);
// 			const a = document.createElement("a");
// 			document.body.appendChild(a);
// 			a.style = "display: none";
// 			a.href = url;
// 			a.download = "test.webm";
// 			a.click();
// 			window.URL.revokeObjectURL(url);
// 		}
// 	};

// 	// Start screen capture
// 	const startCapture = async () => {
// 		let captureStream = null;
// 		try {
// 			captureStream = await navigator.mediaDevices.getDisplayMedia();
// 			videoRef.current = captureStream;
// 		} catch (err) {
// 			console.error(`Error: ${err}`);
// 			return;
// 		}
// 		//  videoRef.current.srcObject = captureStream;
// 		//   videoRef.current.play();
// 		setCaptureOn(true);

// 		// Start recording
// 		mediaRecorderRef.current = new MediaRecorder(captureStream);
// 		mediaRecorderRef.current.ondataavailable = handleDataAvailable;
// 		mediaRecorderRef.current.start();
// 	};

// 	// Stop screen capture
// 	const stopCapture = () => {
// 		setCaptureOn(false);
// 		const tracks = videoRef.current.getTracks();

// 		tracks.forEach((track) => track.stop());
// 		videoRef.current.srcObject = null;

// 		// Stop recording
// 		mediaRecorderRef.current.stop();
// 	};

// 	return (
// 		<div>
// 			{captureOn && (
// 				<button id="stop" onClick={stopCapture}>
// 					Stop Recording
// 				</button>
// 			)}
// 			{!captureOn && (
// 				<button id="start" onClick={startCapture}>
// 					Start Recording
// 				</button>
// 			)}

// 			{/* <video ref={videoRef} autoPlay></video> */}
// 		</div>
// 	);
// }

// function MeetingReport() {
// 	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
// 	const [data, setData] = useState([]);
// 	const [activeTab, setActiveTab] = useState(2);
// 	const [sortField, setSortField] = useState(null);
// 	const [sortDirection, setSortDirection] = useState(null);

// 	const fetchData = async (days) => {
// 		const result = await axiosLimitedGet(`${url}/api/dashboard/sessioncount`, 1, { params: { days } });
// 		console.log(result.data);
// 		// filter out the data
// 		const filteredData = result.data.filter((item) => item.incomplete_percentage !== "%");

// 		setData(filteredData);
// 	};
// 	const sortData = (field) => {
// 		setSortDirection(sortDirection === "asc" || sortDirection === null ? "desc" : "asc");
// 		setSortField(field);
// 	};
// 	useEffect(() => {
// 		switch (activeTab) {
// 			case 1:
// 				fetchData(1);
// 				break; // last day
// 			case 2:
// 				fetchData(7);
// 				break; // last 7 days
// 			case 3:
// 				fetchData(30);
// 				break; // last month
// 			case 4:
// 				fetchData(5000);
// 				break; // all time
// 			default:
// 				break;
// 		}
// 	}, [activeTab]);
// 	useEffect(() => {
// 		if (sortField !== null) {
// 			const sortedData = [...data].sort((a, b) => {
// 				if (sortDirection === "asc") {
// 					return a[sortField] > b[sortField] ? 1 : -1;
// 				}
// 				return a[sortField] < b[sortField] ? 1 : -1;
// 			});
// 			setData(sortedData);
// 		}
// 	}, [sortField, sortDirection]);

// 	const headers = [
// 		"setup_type_with_company",
// 		"allmeeting_count",
// 		"unfinished_count",
// 		"incomplete_count",
// 		"unsuccessful_count",
// 		"success_count",
// 		"unfinished_percentage",
// 		"incomplete_percentage",
// 		"unsuccessful_percentage",
// 		"success_percentage",
// 		"sum",
// 		"average"
// 	];
// 	const displayHeaders = [
// 		"Type",
// 		"All",
// 		"Unfinished",
// 		"Incomplete",
// 		"Unsuccessful",
// 		"Success",
// 		"Unfinished %",
// 		"Incomplete %",
// 		"Unsuccessful %",
// 		"Success %",
// 		"Sum",
// 		"avg"
// 	];
// 	const colors = [
// 		"#303d7c",
// 		"lightblue",
// 		"pink",

// 		"lightsalmon",
// 		"indianred",
// 		"lightgreen",
// 		"pink",
// 		"lightsalmon",

// 		"indianred",
// 		"lightgreen",
// 		"#303d7c",
// 		"#303d7c"
// 	];
// 	return (
// 		<div style={{ padding: "20px" }}>
// 			<h1 style={{ textAlign: "center" }}>Meeting Completion Stats</h1>
// 			<div style={{ display: "flex", gap: "10px" }}>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 1} tabIndex={1}>
// 					Last Day
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 2} tabIndex={2}>
// 					Last 7 Days
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 3} tabIndex={3}>
// 					Last Month
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 4} tabIndex={4}>
// 					All Time
// 				</Tab>
// 			</div>
// 			<table style={{ width: "100%", marginTop: "20px", textAlign: "center" }}>
// 				<thead>
// 					<tr style={{ borderBottom: "1px solid grey" }}>
// 						{headers.map((header, index) => (
// 							<th key={header} style={{ cursor: "pointer" }} onClick={() => sortData(header)}>
// 								{displayHeaders[index] && <>{displayHeaders[index]}</>}
// 								{!displayHeaders[index] && <>{header}</>}

// 								{sortField === header && (sortDirection === "asc" ? " ↑" : " ↓")}
// 							</th>
// 						))}
// 					</tr>
// 				</thead>
// 				<tbody>
// 					{data.map((item, index) => (
// 						<tr key={index}>
// 							{headers.map((header, rowIndex) => (
// 								<td key={header + index + item.tag} style={{ backgroundColor: colors[rowIndex] }}>
// 									{item[header]}
// 								</td>
// 							))}
// 						</tr>
// 					))}
// 				</tbody>
// 			</table>
// 		</div>
// 	);
// }
// function ApiReport() {
// 	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
// 	const [data, setData] = useState([]);
// 	const [activeTab, setActiveTab] = useState(2);
// 	const [sortField, setSortField] = useState(null);
// 	const [sortDirection, setSortDirection] = useState(null);

// 	const fetchData = async (minutes) => {
// 		const result = await axiosLimitedGet(`${url}/api/dashboard/apicount`, 1, { params: { minutes } });
// 		console.log(result.data);
// 		setData(result.data);
// 	};
// 	const sortData = (field) => {
// 		setSortDirection(sortDirection === "asc" || sortDirection === null ? "desc" : "asc");
// 		setSortField(field);
// 	};
// 	useEffect(() => {
// 		switch (activeTab) {
// 			case 1:
// 				fetchData(5);
// 				break; // last houre
// 			case 2:
// 				fetchData(60);
// 				break; // last 1 days
// 			case 3:
// 				fetchData(60 * 24);
// 				break; // last week
// 			case 4:
// 				fetchData(60 * 24 * 7);
// 				break; // all year
// 			case 5:
// 				fetchData(60 * 24 * 365);
// 				break; // all year

// 			default:
// 				break;
// 		}
// 	}, [activeTab]);
// 	useEffect(() => {
// 		if (sortField !== null) {
// 			const sortedData = [...data].sort((a, b) => {
// 				if (sortDirection === "asc") {
// 					return a[sortField] > b[sortField] ? 1 : -1;
// 				}
// 				return a[sortField] < b[sortField] ? 1 : -1;
// 			});
// 			setData(sortedData);
// 		}
// 	}, [sortField, sortDirection]);

// 	const headers = ["tag", "average_response_time", "failed_percentage", "failed_count", "call_count"];
// 	return (
// 		<div style={{ padding: "20px" }}>
// 			<h1 style={{ textAlign: "center" }}>LLM API Counts</h1>
// 			<div style={{ display: "flex", gap: "10px" }}>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 1} tabIndex={1}>
// 					5 minutes
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 2} tabIndex={2}>
// 					Last Hour
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 3} tabIndex={3}>
// 					Last 24 Hour
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 4} tabIndex={4}>
// 					Last Week
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 5} tabIndex={5}>
// 					last Year
// 				</Tab>
// 			</div>
// 			<table style={{ width: "100%", marginTop: "20px", textAlign: "center" }}>
// 				<thead>
// 					<tr>
// 						{headers.map((header) => (
// 							<th key={header} style={{ borderBottom: "1px solid grey", cursor: "pointer" }} onClick={() => sortData(header)}>
// 								{header}
// 								{sortField === header && (sortDirection === "asc" ? " ↑" : " ↓")}
// 							</th>
// 						))}
// 					</tr>
// 				</thead>
// 				<tbody>
// 					{data.map((item, index) => (
// 						<tr key={index}>
// 							{headers.map((header) => (
// 								<td key={header + index + item.tag} style={{}}>
// 									{item[header]}
// 								</td>
// 							))}
// 						</tr>
// 					))}
// 				</tbody>
// 			</table>
// 		</div>
// 	);
// }
// function ApiList() {
// 	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
// 	const [data, setData] = useState([]);
// 	const [selectedItem, setSelectedItem] = useState(null);
// 	const [filter, setFilter] = useState("");
// 	const [filteredData, setFilteredData] = useState([]);

// 	const [activeTab, setActiveTab] = useState(1);
// 	const [sortField, setSortField] = useState(null);
// 	const [sortDirection, setSortDirection] = useState(null);

// 	const fetchData = async (days) => {
// 		const result = await axiosLimitedGet(`${url}/api/dashboard/llmApiCalls`, 1, { params: { days } });
// 		console.log(result.data);
// 		setData(result.data);
// 	};
// 	const sortData = (field) => {
// 		setSortDirection(sortDirection === "asc" || sortDirection === null ? "desc" : "asc");
// 		setSortField(field);
// 	};
// 	useEffect(() => {
// 		switch (activeTab) {
// 			case 1:
// 				fetchData(1);
// 				break; // last houre
// 			case 2:
// 				fetchData(7);
// 				break; // last 1 days
// 			case 3:
// 				fetchData(30);
// 				break; // last week
// 			case 4:
// 				fetchData(365 * 5);
// 				break; // all year
// 			default:
// 				break;
// 		}
// 	}, [activeTab]);
// 	useEffect(() => {
// 		if (filter) {
// 			setFilteredData(
// 				data.filter(
// 					(item) =>
// 						item.tags.some((tag) => tag.toLowerCase().includes(filter.toLowerCase())) ||
// 						item.instage_id?.toLowerCase().includes(filter.toLowerCase()) ||
// 						item.gpt_session_id?.toString().toLowerCase().includes(filter.toLowerCase())
// 				)
// 			);
// 		} else {
// 			setFilteredData(data);
// 		}
// 	}, [data, filter]);
// 	useEffect(() => {
// 		if (sortField !== null) {
// 			const sortedData = [...data].sort((a, b) => {
// 				if (sortDirection === "asc") {
// 					return a[sortField] > b[sortField] ? 1 : -1;
// 				}
// 				return a[sortField] < b[sortField] ? 1 : -1;
// 			});
// 			setData(sortedData);
// 		}
// 	}, [sortField, sortDirection]);

// 	const headers = ["id", "gpt_session_id", "instage_id", "response_time"];
// 	const rightHeaders = ["request_timestamp", "response_timestamp", "token_cost", "api_endpoint"];
// 	function AutoSizeTextArea({ value: propValue, onChange: propOnChange }) {
// 		const [value, setValue] = useState(propValue);
// 		const [isEditing, setIsEditing] = useState(false);
// 		const textAreaRef = useRef(null);

// 		useEffect(() => {
// 			if (!isEditing) {
// 				if (value !== propValue) {
// 					propOnChange(value);
// 				}
// 			}
// 		}, [value, propValue, isEditing]);

// 		useEffect(() => {
// 			// Save current scroll position
// 			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

// 			textAreaRef.current.style.height = "auto";
// 			textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;

// 			// Restore scroll position
// 			window.scrollTo(0, scrollTop);
// 		}, [value]);

// 		const handleChange = (e) => {
// 			setValue(e.target.value);
// 			// propOnChange(e);
// 		};

// 		return (
// 			<textarea
// 				ref={textAreaRef}
// 				value={value}
// 				onChange={handleChange}
// 				onFocus={() => setIsEditing(true)}
// 				onBlur={() => setIsEditing(false)}
// 				style={{
// 					backgroundColor: isEditing ? "#303d7c" : "#303d7f",
// 					whiteSpace: "pre-wrap",
// 					wordWrap: "break-word",
// 					width: "100%",
// 					border: "none",
// 					resize: "none",
// 					outline: "none",
// 					overflow: "auto",
// 					color: "white"
// 				}}
// 			/>
// 		);
// 	}

// 	// Render function for the list items
// 	const renderListItem = (item, index) => {
// 		// console.log(item)
// 		// console.log(selectedItem)

// 		const isSelected = selectedItem && selectedItem.id === item.id;
// 		const bgColor = isSelected ? "gray" : "#303d7f";
// 		return (
// 			<div
// 				key={index}
// 				onClick={() => setSelectedItem(item)}
// 				style={{
// 					border: "1px solid black",
// 					cursor: "pointer",
// 					padding: "10px",
// 					backgroundColor: bgColor
// 				}}
// 			>
// 				{item.id} |{item.gpt_session_id} <br />
// 				{item.request_timestamp} <br />
// 				{item.tags.map((tag) => (
// 					<span key={tag} style={{ backgroundColor: "#00a9af", marginRight: "10px" }}>
// 						{tag}
// 					</span>
// 				))}
// 			</div>
// 		);
// 	};
// 	// Render function for the item details
// 	const renderDetails = (item) => (
// 		<div>
// 			<div
// 				style={{
// 					border: "1px solid black",
// 					backgroundColor: "#303d7c",
// 					margin: "10px",
// 					padding: "10px"
// 				}}
// 			>
// 				<div style={{ display: "flex" }}>
// 					<span style={{ margin: "10px", padding: "10px", width: "50%" }}>
// 						{headers.map((header) => (
// 							<p key={header}>
// 								<strong>{header}:</strong> {item[header]}
// 							</p>
// 						))}
// 					</span>

// 					<span style={{ margin: "10px", padding: "10px", width: "50%" }}>
// 						{rightHeaders.map((header) => (
// 							<p key={header}>
// 								<strong>{header}:</strong> {item[header]}
// 							</p>
// 						))}
// 					</span>
// 				</div>
// 			</div>

// 			<div style={{ margin: "10px" }}>
// 				{item.tags.map((tag) => (
// 					<span key={tag} style={{ backgroundColor: "#00a9af", marginRight: "10px" }}>
// 						{tag}
// 					</span>
// 				))}
// 			</div>

// 			<div style={{ display: "flex", padding: "10px" }}>
// 				<div
// 					style={{
// 						border: "1px solid black",
// 						margin: "20px",
// 						overflowY: "scroll",
// 						overflowX: "wrap",
// 						height: "400px",
// 						width: "50%"
// 					}}
// 				>
// 					<h2>Prompt:</h2>
// 					{item.input_text.map((message, index) => (
// 						<div key={index + message.content} style={{ backgroundColor: "#303d7c", marginRight: "10px" }}>
// 							<b>
// 								{message.role} <br />
// 							</b>
// 							<AutoSizeTextArea
// 								value={message.content}
// 								onChange={(val) => {
// 									const temp = { ...item };
// 									temp.input_text[index].content = val;
// 									setSelectedItem(temp);
// 								}}
// 							/>
// 						</div>
// 					))}
// 				</div>
// 				<div
// 					style={{
// 						border: "1px solid black",
// 						margin: "20px",
// 						overflowY: "scroll",
// 						height: "400px",
// 						width: "50%"
// 					}}
// 				>
// 					<h2>Response:</h2>

// 					<p key={item.output_text} style={{ backgroundColor: "#303d7c", marginRight: "10px" }}>
// 						<b>
// 							{item.output_text?.role} <br />
// 						</b>
// 						<div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>{item.output_text?.content}</div>
// 					</p>
// 				</div>
// 			</div>

// 			<button
// 				onClick={async () => {
// 					const temp1 = { ...item };

// 					temp1.output_text = { role: "", content: "" };

// 					setSelectedItem(temp1);
// 					const result = await axiosLimitedPost(`${url}/api/dashboard/reRun`, { item }, 1, {}, 50000);
// 					const temp = { ...item };
// 					temp.output_text = result.data;
// 					setSelectedItem(temp);
// 				}}
// 			>
// 				Re Send
// 			</button>
// 		</div>
// 	);
// 	return (
// 		<div style={{ padding: "20px" }}>
// 			<h1 style={{ textAlign: "center" }}>InStage Layer</h1>
// 			<div style={{ display: "flex", gap: "10px" }}>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 1} tabIndex={1}>
// 					Last day
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 2} tabIndex={2}>
// 					Last week
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 3} tabIndex={3}>
// 					Last month
// 				</Tab>
// 				<Tab onTabClick={setActiveTab} isActive={activeTab === 4} tabIndex={4}>
// 					last Year
// 				</Tab>
// 			</div>
// 			<div style={{ border: "1px solid black", display: "flex", padding: "20px" }}>
// 				<div
// 					style={{
// 						border: "1px solid black",
// 						marginRight: "20px",
// 						overflowY: "scroll",
// 						height: "700px",
// 						width: "15%"
// 					}}
// 				>
// 					<input
// 						type="text"
// 						value={filter}
// 						onChange={(e) => setFilter(e.target.value)}
// 						placeholder="Filter by tag,instage_id, gpt_session_id"
// 					/>
// 					{filteredData.map(renderListItem)}
// 				</div>
// 				<div
// 					style={{
// 						border: "1px solid black",
// 						marginRight: "20px",
// 						overflowY: "scroll",
// 						height: "700px",
// 						width: "85%"
// 					}}
// 				>
// 					{selectedItem && renderDetails(selectedItem)}
// 				</div>
// 			</div>
// 		</div>
// 	);
// }

function LiveTicker() {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const [recordCount, setRecordCount] = useState(5);
	const [activeTab, setActiveTab] = useState(4);

	// Function to fetch record_count from the backend
	const fetchRecordCount = async (activeTab) => {
		try {
			let days = null;
			switch (activeTab) {
				case 1:
					days = 1;
					break; // last day
				case 2:
					days = 7;
					break; // last 7 days
				case 3:
					days = 30;
					break; // last month
				case 4:
					days = null;
					break; // all time
				default:
					break;
			}
			let result;
			if (days) {
				result = await axiosLimitedGet(`${url}/api/dashboard/session-count`, 1, { params: { days } });
			} else {
				result = await axiosLimitedGet(`${url}/api/dashboard/session-count`, 1, { params: { days } });
			}
			// console.log(result.data.record_count);

			if (result.data.record_count) {
				setRecordCount(result.data.record_count);
			}
		} catch (error) {
			console.error("Error fetching record count:", error);
		}
	};

	// Set up an interval to fetch the record_count every 5 seconds
	useEffect(() => {
		const intervalId = setInterval(() => fetchRecordCount(activeTab), 5000);
		fetchRecordCount(activeTab); // Initial fetch
		return () => clearInterval(intervalId); // Cleanup interval on component unmount
	}, [activeTab]);

	return (
		<div className="live-ticker">
			<h1 style={{ textAlign: "center" }}>Completed Session Ticker</h1>
			<div style={{ display: "flex", gap: "10px" }}>
				<Tab onTabClick={setActiveTab} isActive={activeTab === 1} tabIndex={1}>
					Last Day
				</Tab>
				<Tab onTabClick={setActiveTab} isActive={activeTab === 2} tabIndex={2}>
					Last 7 Days
				</Tab>
				<Tab onTabClick={setActiveTab} isActive={activeTab === 3} tabIndex={3}>
					Last Month
				</Tab>
				<Tab onTabClick={setActiveTab} isActive={activeTab === 4} tabIndex={4}>
					All Time
				</Tab>
			</div>

			<FlipNumbers height={40} width={28} color="black" background="white" perspective={300} play numbers={recordCount.toString()} />
		</div>
	);
}

function AnalyticsDashboard() {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const navigate = useNavigate();

	const { user, adminAccess, showSidebar } = useContext(AppContext);

	useEffect(() => {
		if (user) {
			if (!adminAccess) {
				navigate("/dashboard");
			}
		}
	}, [user]);

	return (
		<>
			<Helmet>
				<title>InStage Dashboard</title>
				<meta name="description" content="Simulation analytics dashboard" />
				<meta name="robots" content="noindex" />
			</Helmet>

			<div className={styles.adminPage}>
				<div
					className={styles.adminPageContainer}
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "100px"
					}}
				>
					<LiveTicker />
					{/* <ScreenRecord></ScreenRecord> */}
					{/* <MeetingReport></MeetingReport> */}

					{/* <SimActivity /> */}

					{/* <AggregateStatsList></AggregateStatsList> */}

					{/* <ApiReport></ApiReport> */}

					{/* <ApiList /> */}
				</div>
			</div>
		</>
	);
}
export default AnalyticsDashboard;
