import React, { useState, useEffect, useContext } from "react";
import { FaUser } from "react-icons/fa/index.esm.js";
import { FiUser, FiThumbsUp, FiThumbsDown, FiRotateCcw } from "react-icons/fi/index.esm.js";
import { RiVolumeUpFill } from "react-icons/ri/index.esm.js";
import r from "../../styleModules/reportStyles.module.css";
import { DialogueFeedbackModal, useSendScore, ReplayConfirmModal } from "../Setup/SetupElements/SetupModals.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export function TranscriptElement({ transcript, audience, setup_type, index, simulation_fk, disable_retry, session_id, isAudioReady, isTable }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const sendScore = useSendScore();

	const [isFeedbackClicked, setIsFeedbackClicked] = useState([]);
	const [feedbackStatus, setFeedbackStatus] = useState(false);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [selectedMetadata, setSelectedMetadata] = useState(null);
	const [isHovered, setIsHovered] = useState(null);
	const [selectedReplayMessage, setSelectedReplayMessage] = useState(null);
	const [showReplayConfirmModal, setShowReplayConfirmModal] = useState(false);
	const { userLanguage, performTranslation, handleNavigate } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Interviewee: "Interviewee",
		Presenter: "Presenter",
		SalesRep: "Sales Rep",
		User: "User"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
		// console.log("Changes");
	}, [userLanguage]); // Depend on pageText to trigger re-translation

	function formatTime(seconds) {
		const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
		date.setSeconds(seconds); // Set the seconds directly on the date object
		return date.toISOString().slice(14, 19);
	}

	const handleReplayClick = (message) => {
		const data = {
			session_id,
			speechId: message.speechId,
			makeCopy: true
		};
		setSelectedReplayMessage(data);
		console.log(data, message);
		setShowReplayConfirmModal(true);
	};
	const ReplaySection = async () => {
		const resp = await axiosLimitedPost(`${url}/api/sessionData/replay`, selectedReplayMessage, 1);
		console.log(resp);
		console.log(setup_type);

		const newId = resp.data.session_id;
		const simUrl = `/simulation/${setup_type}/${simulation_fk}/${newId}`;
		console.log(simUrl);

		// navigate to simUrl
		handleNavigate(simUrl, true);
	};
	const handleFeedbackClick = (status, metadata) => {
		setIsFeedbackClicked((prevState) => {
			const newState = [...prevState];
			newState[index] = true;
			return newState;
		});
		setShowFeedbackModal(true);
		setFeedbackStatus(status);
		if (metadata) {
			setSelectedMetadata(metadata);
			// console.log(metadata);
			sendScore(metadata.promptLayer_request_id, status);
		}
	};
	const rolePrefix = {
		interview: `${pageText.Interviewee}: `,
		presentation: `${pageText.Presenter}: `,
		discovery: `${pageText.SalesRep}: `,
		discovery_spin: `${pageText.SalesRep}: `,
		closing: `${pageText.SalesRep}: `,
		intro: `${pageText.User}: `,
		freestyle: `${pageText.User}: `,
		patient: `${pageText.User}: `,
		pitch: `${pageText.User}: `,
		business_pitch: `${pageText.SalesRep}: `,
		retail: `${pageText.SalesRep}: `,
		reflection: `${pageText.User}: `
	};
	let speakers = ["avater", "user"];
	if (audience) {
		speakers = [audience[0], rolePrefix[setup_type]];
	}
	const icons = [
		<img
			className={r.transcriptPortrait}
			src={`https://avatarportraits.s3.ca-central-1.amazonaws.com/${audience ? audience[0] : "John"}.png`}
			alt="portrait"
		/>,
		<FaUser className={r.transcriptUserIcon} />
	];
	let audioButtonStyleObj = {};
	if (isTable) {
		audioButtonStyleObj = {
			right: "0rem",
			top: "1.5rem"
		};
	}

	return (
		<div onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
			{transcript.speaker !== undefined && (
				<div className={r.speakerInfoContainer}>
					<div className={r.speakerInfo}>
						<div className={r.speakerIcon}>{icons[transcript.speaker]}</div>
						<div className={r.speakerName}>
							<span>{speakers[transcript.speaker]}</span>
						</div>

						{isHovered && (
							<div className={r.thumbsContainer}>
								{!isFeedbackClicked[index] &&
									speakers[transcript.speaker] !== "Interviewee: " &&
									speakers[transcript.speaker] !== "Sales Rep: " &&
									speakers[transcript.speaker] !== "Presenter: " &&
									speakers[transcript.speaker] !== "User: " && (
										<>
											{transcript.metadata.promptLayer_request_id && (
												<>
													<FiThumbsUp
														className={r.thumbsUpIcon}
														onClick={(e) => {
															// stop event propagation
															e.stopPropagation();
															handleFeedbackClick(true, transcript.metadata);
															// console.log(transcript);
														}}
													/>
													<FiThumbsDown
														className={r.thumbsDownIcon}
														onClick={(e) => {
															e.stopPropagation();
															handleFeedbackClick(false, transcript.metadata);
															// console.log(transcript);
														}}
													/>
												</>
											)}

											{!disable_retry && index !== 0 && (
												<FiRotateCcw
													className={r.thumbsUpIcon}
													onClick={(e) => {
														e.stopPropagation();
														handleReplayClick(transcript);
													}}
												/>
											)}
										</>
									)}
							</div>
						)}
					</div>
					{isAudioReady && transcript.start >= 0 && <div className={r.timestamp}>{formatTime(transcript.start)}</div>}
				</div>
			)}
			<div className={r.sentence_container}>
				<span className="sentence">{transcript.text}</span>
				{/* {paragraph.sentences && paragraph.sentences.map((sentence, i) => (
sentence.text && (

)
))} */}
			</div>
			{isAudioReady && <RiVolumeUpFill style={audioButtonStyleObj} className={r.paragraphPlayBtn} />}
			{showFeedbackModal && (
				<DialogueFeedbackModal
					setShowFeedbackModal={setShowFeedbackModal}
					feedbackStatus={feedbackStatus}
					selectedPromptId={selectedMetadata.promptLayer_request_id}
					metadata={selectedMetadata}
					session_id={session_id}
					index={index}
				/>
			)}
			{showReplayConfirmModal && (
				<ReplayConfirmModal
					title="Start simulation at this point"
					subTitle="This will generate a new report on completion"
					setShowConfirmModal={setShowReplayConfirmModal}
					handleModalCancel={() => {}}
					handleModalConfirm={ReplaySection}
				/>
			)}
		</div>
	);
}
