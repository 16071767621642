import React, { useState, useEffect, useContext, useRef } from "react";
import { FiInfo, FiMessageSquare } from "react-icons/fi/index.esm.js";
import { MdOndemandVideo } from "react-icons/md/index.esm.js";
import { RiVolumeMuteFill } from "react-icons/ri/index.esm.js";
import r from "../../styleModules/reportStyles.module.css";
import { VideoContext, AppContext } from "../UtilityFunctions/AppContext.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner";

import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { TranscriptElement } from "./TranscriptElement.js";
import { LuAlertTriangle } from "react-icons/lu";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;
function createChatHistory(deepgram_data, speakers) {
	const history = [];

	const paragraphsArray = deepgram_data.paragraphs.paragraphs;

	for (let i = 0; i < paragraphsArray.length; i++) {
		const paragraph = paragraphsArray[i];
		const message = { role: "", content: "" };

		if (paragraph.speaker == 0) {
			message.role = "assistant";
		} else {
			message.role = "user";
		}
		message.content = `${speakers[paragraph.speaker]}:  `;
		for (let j = 0; j < paragraph.sentences.length; j++) {
			const sentence = paragraph.sentences[j];
			message.content += `${sentence.text} `;
		}
		message.content = message.content.trim();
		history.push(message);
	}
	console.log(history);
}
export default function TranscriptV2({
	disable_retry,
	audioFileExists,
	videoFileExists,
	simulation_fk,
	session_id,
	audience,
	transcriptv3,
	fileKey,
	videofileKey,
	setup_type,
	audioFiles
}) {
	const { VideoRef, playingVideoIndex, setPlayingVideoIndex, setVideoDownloadLink } = useContext(VideoContext);

	const [paragraphs, setParagraphs] = useState(null);
	const [activeParagraphIndex, setActiveParagraphIndex] = useState(null);
	const [isAudioReady, setIsAudioReady] = useState(false);
	const [isAudioAvailable, setIsAudioAvailable] = useState(false);

	const [isVideoAvailable, setIsVideoAvailable] = useState(false);
	const [isAudioControlDisabled, setIsAudioControlDisabled] = useState(false);
	const currentTimeRef = useRef(0);

	const AudioRef = useRef(null);
	// const VideoRef = useRef(null);

	const paragraphRefs = useRef(new Array());

	const audioLoaderCondition = (!isAudioReady && isAudioAvailable) || fileKey === undefined;

	const { userLanguage, translateDictText, performTranslation, localUser } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		ProcessingAudio: "Processing Audio",
		AudioError: "Your browser does not support the audio element.",
		NoTranscriptAvailable: "No transcript available",
		MediaUnavailable: "You may have to wait up to 24 hours for it to be ready. Please check back in later."
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage, audioLoaderCondition]);

	// Check if any of the audio files are video files
	useEffect(() => {
		const hasVideoFiles = audioFiles?.some((file) => file.endsWith(".mp4") || file.endsWith(".webm"));

		setIsVideoAvailable(hasVideoFiles && videoFileExists === true);

		// const hasAudioFiles = audioFiles?.some((file) => file.endsWith("fullAudioFile.wav"));

		const hasAudioFiles = fileKey !== undefined;
		// console.log("setIsAudioAvailable", "fileKey", fileKey, "hasAudioFiles", hasAudioFiles);

		if (audioFileExists !== undefined) {
			setIsAudioAvailable(hasAudioFiles && audioFileExists === true);
		} else {
			setIsAudioAvailable(hasAudioFiles);
		}

		const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		if (hasAudioFiles) {
			setIsAudioReady(true);
		}
	}, [audioFiles, audioFileExists, fileKey]);

	useEffect(() => {
		// console.log(transcriptv3);
		if (transcriptv3 == null || transcriptv3.length === 0) {
			return;
		}
		const goodParagraphs = [];
		const badParagraphs = [];
		const adjustedParagraphs = transcriptv3.map((paragraph) => {
			const newStart = paragraph.start === -1 ? -1 : paragraph.start / 1000;
			const newEnd = paragraph.end === -1 ? -1 : paragraph.end / 1000;
			const audioControlDisabled = newStart === -1 || newEnd === -1;
			if (audioControlDisabled === false) {
				goodParagraphs.push(paragraph);
			} else {
				badParagraphs.push(paragraph);
			}
			return {
				...paragraph,
				start: newStart,
				end: newEnd,
				audioControlDisabled
			};
		});
		if (badParagraphs.length > goodParagraphs.length) {
			setIsAudioControlDisabled(true);
		}
		setParagraphs(adjustedParagraphs);
	}, [transcriptv3]);
	const AudioRefCurrent = AudioRef.current;
	useEffect(() => {
		// console.log(AudioRef.current, fileKey && audioFileExists);
		if (AudioRef.current && fileKey && fileKey !== "" && audioFileExists === true) {
			console.log("src set", `${url}/Audio2?key=${encodeURI(fileKey)}`);
			AudioRef.current.src = `${url}/Audio2?key=${encodeURI(fileKey)}`;
			const handleTimeUpdate = () => {
				if (AudioRef.current) {
					currentTimeRef.current = AudioRef.current.currentTime;
					updateActiveParagraph();
				}
			};
			AudioRef.current.addEventListener("timeupdate", handleTimeUpdate);

			// Cleanup function
			return () => {
				if (AudioRef.current) {
					AudioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
				}
			};
		}
	}, [AudioRefCurrent, fileKey, audioFileExists, isAudioAvailable]);

	// useEffect(() => {
	// 	if (
	// 		VideoRef.current
	// 		// && videofileKey
	// 		) {
	// 		// VideoRef.current.src = url + "/Audio2?key=" + encodeURI(videofileKey);
	// 		// VideoRef.current.src = url + "/Audio2?key=" + encodeURI("audiofile_v2/a72b2ed9-2c88-4dc9-9fda-8f015d1c02e0/2701/mp4/0_1702341817993.mp4");
	// 		VideoRef.current.src = "http://localhost:5000/Audio3/audiofile_v2/a72b2ed9-2c88-4dc9-9fda-8f015d1c02e0/2701/webm/0_1702341817993.webm";
	// 		const handleTimeUpdate = () => {
	// 			currentTimeRef.current = AudioRef.current.currentTime;
	// 			updateActiveParagraph();
	// 		};
	// 	//	AudioRef.current.addEventListener('timeupdate', handleTimeUpdate);

	// 		// Cleanup function
	// 		return () => {
	// 			// if (AudioRef.current) {
	// 			// 	AudioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
	// 			// }
	// 		};
	// 	}

	// }, [VideoRef.current, videofileKey]);

	const playVideo = () => {
		// let tempLink = "http://localhost:5000/Audio3/audiofile_v2/a72b2ed9-2c88-4dc9-9fda-8f015d1c02e0/2701/webm/0_1702341817993.webm";
		const tempLink = `${url}/Audio2?key=${encodeURI(videofileKey)}`;

		const downloadLink = `${url}/Audio2Dl?key=${encodeURI(videofileKey)}`;
		if (VideoRef.current) {
			setVideoDownloadLink(downloadLink);
			if (VideoRef.current.src !== tempLink) {
				VideoRef.current.src = tempLink;
			}
			VideoRef.current.onerror = (event) => {
				// console.error("Video loading error: ", event);
				// setIsVideoAvailable(false);
				setPlayingVideoIndex(null);
			};
			if (!VideoRef.current.paused) {
				setPlayingVideoIndex(null);
				VideoRef.current.pause();
			} else {
				setPlayingVideoIndex(0);
				VideoRef.current.play().catch((e) => {
					console.error("Error attempting to play video:", e);
				});
			}

			VideoRef.current.addEventListener("ended", () => {
				setPlayingVideoIndex(null);
			});
		}
	};

	const handleParagraphClick = (time) => {
		if (AudioRef.current && isAudioReady) {
			if (isAudioControlDisabled) return;
			console.log("handleParagraphClick", time);
			AudioRef.current.currentTime = time;
			AudioRef.current.play().catch((e) => {
				console.error("Error attempting to play audio:", e);
			});
			// Update the active paragraph index after a delay to allow the audio currentTime to update
			setTimeout(() => {
				updateActiveParagraph();
			}, 0);
		}
	};

	const updateActiveParagraph = () => {
		if (!paragraphs) return;
		if (isAudioControlDisabled) return;
		// eslint-disable-next-line arrow-body-style
		const activeIndex = paragraphs.findIndex((paragraph) => {
			return currentTimeRef.current >= paragraph.start && currentTimeRef.current < paragraph.end && paragraph.start >= 0;
		});

		if (activeIndex !== -1 && activeIndex !== activeParagraphIndex) {
			setActiveParagraphIndex(activeIndex);
			scrollToActiveParagraph(activeIndex);
		}
	};

	const scrollToActiveParagraph = (index) => {
		if (paragraphRefs.current[index]) {
			paragraphRefs.current[index].scrollIntoView({ behavior: "smooth", block: "nearest" });
		}
	};

	return (
		<>
			{paragraphs != null && (
				<>
					{/* {fileKey?.toString()}
					<br />
					audioFileExists {audioFileExists?.toString()}
					<br />
					isAudioReady {isAudioReady.toString()}
					<br />
					isAudioAvailable {isAudioAvailable.toString()} */}
					{(!isVideoAvailable && videoFileExists) ||
						(!isAudioAvailable && audioFileExists && (
							<div className={r.reportTypeContainerWarning} style={{ marginBottom: "10px" }}>
								<span className={r.reportTypeText}>
									<LuAlertTriangle style={{ fontSize: "2rem", display: "inline-flex", alignItems: "center" }} />
									{pageText.MediaUnavailable}
								</span>
							</div>
						))}
					<div className={r.mediaPlayer}>
						{audioLoaderCondition && (
							<div className={r.spinnerContainerAudio}>
								<LoadingSpinner height={50} width={50} thickness={5} color="#00a9af" />
								<div className={r.mediaPlayerTextContainer}>
									<p className={r.mediaPlayerText}>{pageText.ProcessingAudio}</p>
								</div>
							</div>
						)}
						{/* <video
							ref={VideoRef}
							controls
							style={{ display: isAudioReady ? 'block' : 'none' }}
						>
							<source type="video/webm" />
							Your browser does not support the audio element.
						</video> */}
						{isAudioAvailable && (
							<audio
								ref={AudioRef}
								controls
								canplay={isAudioReady.toString()}
								onLoadedData={() => setIsAudioReady(true)}
								onError={(e) => {
									setTimeout(() => {
										if (e.target.error) {
											// console.error("error with audio", e.target);
											// console.error("error with audio", e.target.error);
											// 	console.error("error with audio", e.target.error.code);
										} else {
											// 	console.error("error with audio", e);
										}
									}, 2);

									// setIsAudioAvailable(false);
								}}
								style={{ display: isAudioReady ? "block" : "none" }}
							>
								<source type="audio/wav" />
								{pageText.AudioError}
							</audio>
						)}
						<MdOndemandVideo
							className={`${r.videoPlayBtn} ${!isVideoAvailable ? r.disabledVideoPlayBtn : ""}`}
							onClick={isVideoAvailable ? playVideo : undefined}
							disabled={!isVideoAvailable}
						/>
					</div>
					<div className={r.paragraphContainer}>
						{paragraphs ? (
							paragraphs.map((paragraph, index) => (
								<div
									key={index}
									ref={(el) => (paragraphRefs.current[index] = el)}
									className={`${r.paragraph} ${index === activeParagraphIndex ? r.activeParagraph : ""} ${
										paragraph.audioControlDisabled ? "" : `${r.paragraphWorking}`
									}`}
									onClick={() => handleParagraphClick(paragraph.start)}
								>
									<TranscriptElement
										transcript={paragraph}
										audience={audience}
										setup_type={setup_type}
										index={index}
										simulation_fk={simulation_fk}
										disable_retry={disable_retry}
										session_id={session_id}
										isAudioReady={isAudioReady}
										isTable={false}
									/>
								</div>
							))
						) : (
							<p>{pageText.NoTranscriptAvailable}</p>
						)}
					</div>
				</>
			)}
		</>
	);
}
