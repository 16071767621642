import { useEffect, useState, useRef, useCallback } from "react";
import io from "socket.io-client";

export const connectionStatusOptions = {
	Connected: "Connected",
	Disconnected: "Disconnected",
	Error: "Error"
};
export const useSocket = ({
	url,
	getTimeStamp,
	stopDeepGram,
	handleSynthesisStarted,
	handleSynthesisCompleted,
	handleGptUserStatementComplete,
	handleSynthesisError,
	handleAudioBroadcast,
	handleVisemes,
	handleWords,
	handleTranscript,
	handleUtteranceEnd,
	handleSpeechStarted,
	instage_id,
	handleDeepgramConnection,
	handleConnect,
	handleDisconnect,
	handleContentFilterError = () => {
		console.log("Azure Content Filter Error");
	}
}) => {
	const socket = useRef(null);
	// State for connection status, audio started status, and speech API status
	const [connectionStatus, setConnectionStatus] = useState(connectionStatusOptions.Disconnected);
	const [deepgramStatus, setDepgramStatus] = useState(connectionStatusOptions.Disconnected);

	useEffect(() => {
		// Initialize the socket connection
		socket.current = io(url, {
			withCredentials: true
			// transports: ["websocket"]
		});

		socket.current.on("log", async (data) => {
			// console.log("BACKEND LOG", data, getTimeStamp());
		});

		socket.current.on("speechStarted", handleSpeechStarted);
		socket.current.on("utteranceEnd", handleUtteranceEnd);

		socket.current.on("transcript", handleTranscript);
		socket.current.on("connect", onConnect);
		socket.current.on("disconnect", onDisconnect);
		socket.current.on("gpt-user-statement-complete", handleGptUserStatementComplete);

		socket.current.on("deepgram-connected", async () => {
			console.error("deepgram-connected");
			handleDeepgramConnection(true);

			setDepgramStatus(connectionStatusOptions.Connected);
		});

		socket.current.on("deepgram-disconnected", async () => {
			console.error("deepgram-disconnected");
			setDepgramStatus(connectionStatusOptions.Disconnected);
			// handleDeepgramConnection(false);
			stopDeepGram();
		});
		socket.current.on("content_filter_error", handleContentFilterError);
		socket.current.on("deepgram-connection-error", async () => {
			console.error("deepgram-connection-error");
			setDepgramStatus(connectionStatusOptions.Error);
			stopDeepGram();
		});
		// Disconnect the socket when the component is unmounted
		return () => {
			if (socket && socket.current) {
				socket.current.disconnect();
			}
		};
	}, [url]);

	useEffect(() => {
		if (connectionStatus === connectionStatusOptions.Connected && instage_id) {
			socket.current.emit("instage_id", instage_id);
		}
	}, [connectionStatus, instage_id]);

	function onConnect(data) {
		// console.log(socket.current);
		setConnectionStatus(connectionStatusOptions.Connected);
		handleConnect(socket.current);
	}

	function onDisconnect(params) {
		console.log(params);
		setConnectionStatus(connectionStatusOptions.Disconnected);
		setDepgramStatus(connectionStatusOptions.Disconnected);
		handleDisconnect();
	}

	const sendMessage = useCallback((event, msg) => {
		if (socket.current) {
			socket.current.emit(event, msg);
		}
	}, []);
	const addEventListener = useCallback((event, handler) => {
		if (socket.current) {
			socket.current.on(event, handler);
		}
	}, []);

	const removeEventListener = useCallback((event, handler) => {
		if (socket.current) {
			socket.current.off(event, handler);
		}
	}, []);
	return {
		socket,
		addEventListener,
		removeEventListener,
		connectionStatus,
		deepgramStatus,
		sendMessage
	};
};
