import React, { useState, useEffect, useRef, useContext } from "react";
import { FaCircle } from "react-icons/fa";
import { FiPlay, FiPause, FiLogOut, FiStopCircle, FiVideo, FiMic } from "react-icons/fi/index.esm.js";
import { MdCheck } from "react-icons/md";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { FaDotCircle, FaStopCircle } from "react-icons/fa/index.esm.js";
import styles from "../../styleModules/simulationStyles.module.css";
import { ProductTourModal } from "../Setup/SetupElements/SetupModals";
import { AppContext } from "../UtilityFunctions/AppContext";
import DeviceSelector from "../SecondaryComponents/DeviceSelector";

export function SimControls({
	runTranslation,
	allowSimControls,
	setAllowSimControls,
	showSidebar,
	isMobileDevice,
	captureOn,
	audioPlaying,
	videoOn,
	toggleVideo,
	toggleCapture,
	hasStoppedCapture,
	setHasStoppedCapture,
	togglePauseSim,
	simPaused,
	presentationFinished,
	setPresentationFinished,
	manualEndSim,
	ManualMuteMic,
	setup_type,
	stream,
	userSpeaking,
	isThinking,
	userMicText,
	canSendDeepgramDataRef,
	getElapsedTime,
	getRemainingTime,
	targetTime,
	getChatHistory
}) {
	const [safeToClick, setSafeToClick] = useState(false);
	const [micClicked, setMicClicked] = useState(false);
	const [remainingTime, setRemainingTime] = useState(parseInt(targetTime, 10) || 0);
	const [countdownKey, setCountdownKey] = useState(0);
	const [isNegativeTime, setIsNegativeTime] = useState(false);
	const [forcedRenderSecondsVar, setForcedRenderSecondsVar] = useState(0);
	const { userLanguage, performTranslation } = useContext(AppContext);
	const [buttonCooldown, setButtonCooldown] = useState(false);
	const [pauseTimer, setPauseTimer] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			if (pauseTimer < 2000) {
				setPauseTimer((prev) => prev + 100); // Increment pauseTimer by 100ms
			}
		}, 100); // Update every 100ms

		return () => clearInterval(interval); // Cleanup on unmount
	}, []);

	useEffect(() => {
		console.log("simPaused: ", simPaused);
		if (simPaused) {
			setAllowSimControls(true);
		}
		if (!audioPlaying) {
			setAllowSimControls(true);
		}
	}, [simPaused, audioPlaying]);

	// Static Translation
	const LocalLanguageDict = {
		StopRecording: "Stop recording",
		StartRecording: "Start recording videos will be automatically deleted after 90 days",
		Pause: "Pause",
		Play: "Play",
		ToggleVideo: "Toggle video",
		EndSimulation: "End simulation",
		Thinking: "Thinking",
		EndPresentation: "End presentation"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	function formatTime(seconds) {
		const date = new Date(0); // The 0 there is the key, which sets the date to the epoch
		date.setSeconds(seconds); // Set the seconds directly on the date object
		return date.toISOString().slice(14, 19);
	}

	useEffect(() => {
		if (!simPaused) {
			const interval = setInterval(() => {
				setForcedRenderSecondsVar((prevSeconds) => {
					return prevSeconds + 1;
				});
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [forcedRenderSecondsVar, simPaused]);

	useEffect(() => {
		if (userSpeaking && canSendDeepgramDataRef.current) {
			setSafeToClick(true);
		} else {
			setSafeToClick(false);
		}
	}, [canSendDeepgramDataRef.current, userSpeaking]);

	useEffect(() => {
		if (userSpeaking) {
			setMicClicked(false);
		}
	}, [userSpeaking]);

	useEffect(() => {
		if (setup_type === "pitch" || setup_type === "presentation" || setup_type === "business_pitch") {
			setRemainingTime(parseInt(targetTime, 10) || 0);
			setIsNegativeTime(false);
		}
	}, [setup_type, targetTime]);

	useEffect(() => {
		if (setup_type === "pitch" || setup_type === "presentation" || setup_type === "business_pitch") {
			if (userSpeaking) {
				setCountdownKey((prevKey) => prevKey + 1);
			} else {
				setRemainingTime(parseInt(targetTime, 10) || 0);
				setIsNegativeTime(false);
			}
		}
	}, [userSpeaking, setup_type, targetTime]);

	useEffect(() => {
		if ((setup_type === "pitch" || setup_type === "presentation" || setup_type === "business_pitch") && userSpeaking && !simPaused) {
			const intervalId = setInterval(() => {
				setRemainingTime((prevTime) => {
					const newTime = prevTime - 1;
					if (newTime < 0) {
						setIsNegativeTime(true);
					}
					return newTime;
				});
			}, 1000);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, [userSpeaking, setup_type, countdownKey, simPaused]);

	// MICROPHONE VISUALIZER
	const analyserRef = useRef(null);
	const animationFrameIdRef = useRef(null);
	const [volume, setVolume] = useState(0);
	const volumes = useRef([]); // a queue to keep track of the last N volume levels
	const numVolumes = 10; // the number of past volume levels to consider

	// Scale volume to control the glow effect (Adjust the divisor as per your requirement)
	const scaledVolume = Math.min(1, volume / 50); // Option 1
	// const scaledVolume = Math.sqrt(volume / 50); // Option 2
	const boxShadow = `0px 0px ${10 * scaledVolume}px ${10 * scaledVolume}px #DB4F86`;

	const micIconContainerClass = `${styles.micIconContainer} ${
		simPaused || !userSpeaking || (userSpeaking && micClicked) ? styles.disabledMic : ""
	} ${safeToClick && !micClicked ? styles.micIconHoverable : ""}`;

	const micIconStyle = {
		boxShadow: userSpeaking ? boxShadow : "none"
	};

	useEffect(() => {
		if (!stream) return;

		// Create an audio context
		const audioContext = new (window.AudioContext || window.webkitAudioContext)();
		const analyser = audioContext.createAnalyser();

		// Connect the stream to the analyser
		const source = audioContext.createMediaStreamSource(stream);
		source.connect(analyser);

		// Set up the analyser and byte array to hold the audio data
		analyser.fftSize = 256; // you can tweak this value
		const bufferLength = analyser.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);

		// Function to check the volume
		const checkVolume = () => {
			analyser.getByteFrequencyData(dataArray);

			// Compute the volume
			let volume = dataArray.reduce((a, b) => a + b) / bufferLength;

			// Update our volume values and calculate the average volume
			volumes.current.push(volume);
			if (volumes.current.length > numVolumes) {
				volumes.current.shift(); // remove the oldest volume level if we have more than numVolumes levels
			}
			volume = volumes.current.reduce((a, b) => a + b) / volumes.current.length;

			setVolume(volume);

			// Update the animation frame
			animationFrameIdRef.current = requestAnimationFrame(checkVolume);
		};

		// Store the analyzer in a ref so we can access it later
		analyserRef.current = analyser;

		// Start checking the volume
		checkVolume();

		// Clean up function
		return () => {
			if (animationFrameIdRef.current) {
				cancelAnimationFrame(animationFrameIdRef.current);
			}

			analyser.disconnect();
			source.disconnect();
		};
	}, [stream]);

	// useEffect(() => {
	// 	runTranslation();
	// });

	return (
		<div
			className={styles.simControlsContainer}
			style={{
				// right: window.innerWidth > 768 ? (!showSidebar ? "90px " : "25%") : "88px"
				right: window.innerWidth > 1000 ? "27%" : "90px"
			}}
		>
			{!isMobileDevice() && !window.location.href.includes("preview") && (
				<>
					<div className={styles.tooltipContainer}>
						<DeviceSelector />
					</div>
					<div className={styles.tooltipContainer}>
						<button
							type="button"
							className={`${styles.simControlBtn} ${
								simPaused || !allowSimControls || !videoOn || hasStoppedCapture ? styles.simControlBtnDisabled : ""
							}`}
							disabled={simPaused || !allowSimControls || !videoOn || hasStoppedCapture}
							id={captureOn ? "stop" : "start"}
							data-attr={captureOn ? "simcontrol_stopcapture" : "simcontrol_startcapture"}
							onClick={() => {
								if (allowSimControls && videoOn && !(hasStoppedCapture && !captureOn)) {
									toggleCapture();
									if (captureOn) {
										setHasStoppedCapture(true);
									}
								}
							}}
						>
							{captureOn ? (
								<FaStopCircle className={`${styles.simControlBtnIcon} ${styles.pulseRedIcon}`} />
							) : (
								<FaDotCircle className={styles.simControlBtnIcon} />
							)}
						</button>
						<span className={styles.tooltipText}>{captureOn ? pageText.StopRecording : pageText.StartRecording}</span>
					</div>
				</>
			)}
			<div className={styles.tooltipContainer}>
				<button
					type="button"
					data-attr={videoOn ? "simcontrol_stopwebcam" : "simcontrol_startwebcam"}
					className={`${styles.simControlBtn} ${videoOn ? styles.activeBtn : ""} ${
						simPaused || !allowSimControls ? styles.simControlBtnDisabled : ""
					}`}
					disabled={simPaused || !allowSimControls}
					onClick={allowSimControls ? toggleVideo : null}
				>
					{videoOn ? (
						<FiVideo className={styles.simControlBtnIcon} />
					) : (
						<>
							<div className={`${styles.simControlBtnIcon} ${styles.inactiveBtn}`} />
							<FiVideo className={styles.simControlBtnIcon} />
						</>
					)}
				</button>
				<span className={styles.tooltipText}>{pageText.ToggleVideo}</span>
			</div>
			<div className={styles.tooltipContainer}>
				<button
					type="button"
					className={micIconContainerClass}
					style={micIconStyle}
					onClick={() => {
						if (safeToClick && !micClicked) {
							ManualMuteMic();
							setMicClicked(true);
							if (!presentationFinished) {
								setPresentationFinished(true);
							}
						}
					}}
				>
					{isThinking ? (
						<LoadingDots />
					) : safeToClick ? (
						<MdCheck className={styles.simControlBtnIcon} />
					) : (
						<FiMic className={styles.simControlBtnIcon} />
					)}
				</button>
				{userSpeaking && !simPaused && <span className={styles.tooltipText}>{isThinking ? pageText.Thinking : userMicText}</span>}
			</div>
			<div className={styles.tooltipContainer}>
				<button
					type="button"
					className={`${styles.simControlBtn} ${!allowSimControls || buttonCooldown ? styles.simControlBtnDisabled : ""} ${
						styles.simControlBtnPlayPause
					}`}
					data-attr={simPaused ? "simcontrol_pause" : "simcontrol_play"}
					onClick={() => {
						if (buttonCooldown) return; // Prevent clicking if in cooldown
						if (pauseTimer < 1500) {
							return; // Prevent action if pauseTimer is less than 1500ms
						}
						if (allowSimControls) {
							togglePauseSim();
							console.log("Time value", getElapsedTime());
							setPauseTimer(0); // Reset pause timer after action
							setButtonCooldown(true); // Set cooldown to true
							setTimeout(() => setButtonCooldown(false), 1500); // Reset cooldown after 1.5 seconds
						}
					}}
				>
					{buttonCooldown ? (
						<LoadingDots />
					) : !simPaused ? (
						<FiPause className={styles.simControlBtnIcon} />
					) : (
						<FiPlay className={styles.simControlBtnIcon} />
					)}
				</button>
				<span className={styles.tooltipText}>{!simPaused ? pageText.Pause : pageText.Play}</span>
			</div>

			{(setup_type === "presentation" || setup_type === "business_pitch") && !presentationFinished && (
				<div className={styles.tooltipContainer}>
					<button
						data-attr="simcontrol_end_presentation"
						aria-label="End presentation"
						type="button"
						className={`${styles.simControlBtn} ${styles.endSimBtn} ${
							audioPlaying || !allowSimControls ? styles.simControlBtnDisabled : ""
						}`}
						disabled={!allowSimControls || audioPlaying}
						onClick={() => (allowSimControls ? setPresentationFinished(true) : null)}
					>
						<FiStopCircle className={styles.simControlBtnIcon} />
					</button>
					<span className={styles.tooltipText}>{pageText.EndPresentation}</span>
				</div>
			)}
			{((setup_type !== "presentation" && setup_type !== "business_pitch") || presentationFinished) && (
				<div className={styles.tooltipContainer}>
					<button
						data-attr="simcontrol_end_simulation"
						aria-label="End simulation"
						type="button"
						className={`${styles.simControlBtn} ${styles.endSimBtn} ${!allowSimControls ? styles.simControlBtnDisabled : ""}`}
						disabled={simPaused || !allowSimControls}
						onClick={() => (allowSimControls ? manualEndSim() : null)}
					>
						<FiLogOut className={styles.simControlBtnIcon} />
					</button>
					<span className={styles.tooltipText}>{pageText.EndSimulation}</span>
				</div>
			)}
			{(setup_type === "presentation" || setup_type === "business_pitch") && !presentationFinished && userSpeaking && (
				<div className={styles.presentationTimer}>
					{/* <p className={styles.presentationTimerText}>{formatTime(getElapsedTime() / 1000)}</p> */}
					<p className={`${styles.presentationTimerText} ${isNegativeTime ? styles.negativeTime : ""}`}>
						{formatTime(Math.abs(remainingTime))}
					</p>
				</div>
			)}
			{setup_type === "pitch" && userSpeaking && (
				<div className={styles.presentationTimer}>
					<p className={`${styles.presentationTimerText} ${isNegativeTime ? styles.negativeTime : ""}`}>
						{formatTime(Math.abs(remainingTime))}
					</p>
				</div>
			)}

			{/* <span className={styles.infoIconContainer}>
				<span
					className={styles.infoIconHeading}
					onClick={(event) => {
						event.stopPropagation();
						setShowPTModal(true);
					}}
				>
					<RxQuestionMarkCircled
						style={{ color: isHovered ? "#00a9af" : "inherit" }}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					/>
				</span>
				<span onClick={(event) => event.stopPropagation()}>
					{showPTModal && (
						<ProductTourModal
							setShowPTModal={setShowPTModal}
							content={[
								{
									videoLink: "ProductTour_Sim_1.mp4",
									title: "Tools",
									description:
										'"Transcript" is where you can view entire chat history & submit feedback on the users responses. The "Checklist" tab provides you with useful tips to keep in mind. The "Resources" tab is for extra help (Not used in interviews)',
									adminOrNot: false
								},
								{
									videoLink: "ProductTour_Sim_2.mp4",
									title: "Action Buttons",
									description:
										'"Record" allows you to record your video to view later. "Toggle Video" allows you to turn on or off your webcam. "Play/Pause" allows you to stop or resume the simulation. \n End Sim: Allows you to conclude the simulation and generate a report.',
									adminOrNot: false
								},
								{
									videoLink: "ProductTour_Sim_3.mp4",
									title: "Generate Report",
									description:
										'Click "End Simulation" when you are finished. This will ask you to confirm. Once you do this, you can select "Generate Report" to view feedback and details about your simulation.',
									adminOrNot: false
								}
							]}
						/>
					)}
				</span>
			</span> */}
		</div>
	);
}

function LoadingDots() {
	return (
		<div className={styles.loadingDots}>
			<FaCircle className={styles.dot} />
			<FaCircle className={styles.dot} />
			<FaCircle className={styles.dot} />
		</div>
	);
}
