function levenshteinDistance(s1, s2) {
	const matrix = [];

	// Increment along the first column of each row
	let i;
	for (i = 0; i <= s2.length; i++) {
		matrix[i] = [i];
	}

	// Increment each column in the first row
	let j;
	for (j = 0; j <= s1.length; j++) {
		matrix[0][j] = j;
	}

	// Fill in the rest of the matrix
	for (i = 1; i <= s2.length; i++) {
		for (j = 1; j <= s1.length; j++) {
			if (s2.charAt(i - 1) === s1.charAt(j - 1)) {
				matrix[i][j] = matrix[i - 1][j - 1];
			} else {
				matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, Math.min(matrix[i][j - 1] + 1, matrix[i - 1][j] + 1));
			}
		}
	}

	return matrix[s2.length][s1.length];
}
function tokenize(str) {
	if (!str) {
		throw new Error("Invalid input: input string is null or undefined");
	}
	const words = str.match(/\w+/g);
	if (!words) {
		return [];
	}
	return words.map((word) => word.toLowerCase());
}

function createWordFrequencyVector(tokens, allUniqueTokens) {
	const vector = new Array(allUniqueTokens.length).fill(0);
	tokens.forEach((token) => {
		const index = allUniqueTokens.indexOf(token);
		if (index !== -1) {
			vector[index]++;
		}
	});
	return vector;
}

function cosineSimilarity(vec1, vec2) {
	let dotProduct = 0;
	let normVec1 = 0;
	let normVec2 = 0;

	for (let i = 0; i < vec1.length; i++) {
		dotProduct += vec1[i] * vec2[i];
		normVec1 += vec1[i] * vec1[i];
		normVec2 += vec2[i] * vec2[i];
	}

	return dotProduct / (Math.sqrt(normVec1) * Math.sqrt(normVec2));
}

export function compareStringsUsingCosine(str1, str2) {
	if (!str1 || !str2) {
		return 0;
	}
	const tokens1 = tokenize(str1);
	const tokens2 = tokenize(str2);

	const allUniqueTokens = Array.from(new Set([...tokens1, ...tokens2]));

	const vector1 = createWordFrequencyVector(tokens1, allUniqueTokens);
	const vector2 = createWordFrequencyVector(tokens2, allUniqueTokens);

	return cosineSimilarity(vector1, vector2);
}

function generateNGrams(str, n) {
	const nGrams = [];
	const words = str.split(/\s+/);

	for (let i = 0; i < words.length - n + 1; i++) {
		nGrams.push(words.slice(i, i + n).join(" "));
	}

	return nGrams;
}

function jaccardSimilarity(setA, setB) {
	const intersection = new Set([...setA].filter((x) => setB.has(x)));
	const union = new Set([...setA, ...setB]);
	return intersection.size / union.size;
}

export function compareStringsUsingNGrams(str1, str2, n) {
	if (!str1 || !str2) {
		return 0;
	}
	const nGrams1 = new Set(generateNGrams(str1, n));
	const nGrams2 = new Set(generateNGrams(str2, n));

	return jaccardSimilarity(nGrams1, nGrams2);
}

export function similarityPercentageLevenshtein(s1, s2) {
	if ((typeof s1 === "undefined" || s1 === null) && (typeof s2 === "undefined" || s2 === null)) return 100.0;
	const maxLength = Math.max(s1 ? s1.length : 0, s2 ? s2.length : 0);
	return (1 - levenshteinDistance(s1 || "", s2 || "") / maxLength) * 100;
}
