import React, { useState, useEffect, useContext, useRef } from "react";
import { RiDownload2Fill, RiDeleteBin5Line, RiVolumeUpFill, RiVolumeMuteFill } from "react-icons/ri/index.esm.js";
import { MdOndemandVideo } from "react-icons/md/index.esm.js";
import { AppContext, VideoContext } from "../UtilityFunctions/AppContext.js";
import { PLANS } from "../../plans.config.js";
import r from "../../styleModules/reportStyles.module.css";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";

export default function AudioButton({ item, index }) {
	const { VideoRef, playingVideoIndex, setPlayingVideoIndex, setVideoDownloadLink } = useContext(VideoContext);
	const { tenantPlan, customFeatures, showLockedModal } = useContext(AppContext);
	const defaultAccess = PLANS[tenantPlan]?.features?.proReport;
	const proAccess = customFeatures?.proReport ?? defaultAccess;
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [showButton, setShowButton] = useState(true);
	const [isHovered, setIsHovered] = useState(false);
	const [audioLoaded, setAudioLoaded] = useState(false);

	useEffect(() => {
		if (AudioRef.current) {
			AudioRef.current.addEventListener("canplaythrough", () => {
				// console.log("Audio is ready to play");
				setAudioLoaded(true);
			});
		}

		// Don't forget to clean up the event listener on component unmount
		return () => {
			if (AudioRef.current) {
				AudioRef.current.removeEventListener("canplaythrough", () => {});
			}
		};
	}, []);
	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};
	const AudioRef = useRef(null);
	const audioValid = item.audio && item.audio !== "" && item.audio !== "error" && item.audio !== undefined && item.audio !== null;
	const videoValid = item.video && item.video !== "" && item.video !== "error" && item.video !== undefined && item.video !== null;

	//	console.log("actions ", audioValid ,videoValid,item.video)

	//
	const playAudio = () => {
		console.log(item.audio);
		if (AudioRef.current) {
			if (AudioRef.current.src === "") {
				AudioRef.current.src = item.audio;
			}
			if (AudioRef.current.paused) {
				setAudioPlaying(true);
				AudioRef.current.play();
			} else {
				setAudioPlaying(false);
				AudioRef.current.pause();
			}

			AudioRef.current.addEventListener("ended", () => {
				setAudioPlaying(false);
			});
		}
	};
	const playVideo = (downloadLink) => {
		if (VideoRef.current) {
			setVideoDownloadLink(downloadLink);
			if (VideoRef.current.src !== item.video) {
				VideoRef.current.src = item.video;
			}
			if (VideoRef.current.paused) {
				setPlayingVideoIndex(index);
				VideoRef.current.play();
			} else {
				setPlayingVideoIndex(null);
				VideoRef.current.pause();
			}

			VideoRef.current.addEventListener("ended", () => {
				setPlayingVideoIndex(null);
			});
		}
	};
	return (
		<>
			<audio ref={AudioRef}>
				<source type="audio/wav" />
				Your browser does not support the audio element.
			</audio>
			<div className={r.transcriptControls} style={{ width: "100%" }}>
				{showButton && audioValid && (
					<>
						{!audioPlaying && (
							<RiVolumeUpFill
								title="Audio playback icon"
								className={r.transcriptPlayBtn}
								onClick={(e) => {
									e.stopPropagation();
									playAudio();
								}}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
							/>
						)}

						{AudioRef.current && audioLoaded == false && audioPlaying && isHovered && (
							<div className={r.spinnerContainer}>
								<LoadingSpinner height={50} width={50} thickness={5} color="#00a9af" />
							</div>
						)}

						{AudioRef.current && audioLoaded == true && audioPlaying && isHovered && (
							<RiVolumeMuteFill
								title="Pause audio playback icon"
								className={r.transcriptPlayBtn}
								style={{ color: "#ee4188" }}
								onClick={(e) => {
									e.stopPropagation();
									playAudio();
								}}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
							/>
						)}
						{AudioRef.current && audioLoaded == true && audioPlaying && !isHovered && (
							<RiVolumeUpFill
								title="Playing audio playback icon"
								className={r.transcriptPlayBtn}
								style={{ color: "#ee4188" }}
								onClick={(e) => {
									e.stopPropagation();
									playAudio();
								}}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
							/>
						)}
						{/* {isUser && (
							<a
								className={r.downloadAudioBtn+ " AudioDownload"}
								style={{ marginTop: "10px" }}
								href={downloadLink}>
								<RiDownload2Fill title="Download icon"/>
							</a>
						)} */}
					</>
				)}
				{showButton && VideoRef.current && videoValid && (
					<MdOndemandVideo
						title="Video playback icon"
						className={r.transcriptPlayBtn}
						style={{ color: !proAccess ? "#7d7d7d" : playingVideoIndex !== index ? "#00a9af" : "#ee4188" }}
						onClick={() => {
							if (proAccess) {
								playVideo(item.videoDownload);
							} else {
								showLockedModal("Video Playback");
							}
						}}
					/>
				)}
				{/* {showButton && VideoRef.current && videoValid && (
					<a className={r.transcriptPlayBtn} href={item.videoDownload}><FiDownload /></a>
				)} */}
			</div>
		</>
	);
}
