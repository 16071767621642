import React, { useState, useContext } from "react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import axios from "axios";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";

import { AppContext } from "../UtilityFunctions/AppContext.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

const inputStyles = {
	width: "100%",
	padding: "10px 12px",
	border: "1px solid #ddd",
	borderRadius: "6px",
	fontSize: "14px",
	transition: "border-color 0.2s ease",
	outline: "none"
};

const labelStyles = {
	display: "block",
	marginBottom: "8px",
	fontSize: "14px",
	fontWeight: "500",
	color: "#333"
};

const buttonStyles = {
	backgroundColor: "#0066cc",
	color: "white",
	padding: "10px 20px",
	borderRadius: "6px",
	border: "none",
	fontSize: "14px",
	fontWeight: "500",
	cursor: "pointer",
	transition: "background-color 0.2s ease",
	":hover": {
		backgroundColor: "#0052a3"
	}
};

function SupportWebinar() {
	const [link, setLink] = useState("");
	const [currentClientId, setCurrentClientId] = useState("");
	const [clientIds, setClientIds] = useState([]);
	const [date, setDate] = useState("");
	const [isSending, setIsSending] = useState(false);
	const [error, setError] = useState("");
	const { axiosLimitedPost } = useAxiosLimited();
	const { localUser } = useContext(AppContext);
	const [emailsSentTo, setEmailsSentTo] = useState([]);

	const handleClientIdSubmit = (e) => {
		if (e.key === "Enter" && currentClientId.trim()) {
			setClientIds([...clientIds, currentClientId.trim()]);
			setCurrentClientId("");
		}
	};

	const removeClientId = (idToRemove) => {
		setClientIds(clientIds.filter((id) => id !== idToRemove));
	};

	const handleSend = async () => {
		if (!link || !date || clientIds.length === 0) {
			setError("Please fill in all fields and add at least one client ID");
			return;
		}

		setIsSending(true);
		setError("");

		console.log("date", date);
		console.log("link", link);
		console.log("clientIds", clientIds);

		try {
			const dateObj = new Date(date);
			const options = {
				weekday: "long",
				month: "long",
				day: "numeric",
				hour: "numeric",
				minute: "2-digit",
				timeZone: "America/New_York",
				timeZoneName: "short"
			};
			const formattedDate = dateObj.toLocaleString("en-US", options);
			const resp = await axiosLimitedPost(`${url}/api/users/sendWebinarInviteEmail`, {
				client_ids: clientIds,
				date: formattedDate,
				url: link,

				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});
			console.log("resp", resp);
			setEmailsSentTo(resp.data);

			// Clear form after successful send
			setLink("");
			setClientIds([]);
			setDate("");
		} catch (err) {
			setError(err.response?.data?.message || "Failed to send invites");
		} finally {
			setIsSending(false);
		}
	};

	return (
		<div
			style={{
				padding: "24px",
				background: "white",
				borderRadius: "8px",
				boxShadow: "0 2px 4px rgba(0,0,0,0.05)"
			}}
		>
			<h3
				style={{
					marginTop: 0,
					marginBottom: "24px",
					fontSize: "20px",
					fontWeight: "600",
					color: "#1a1a1a"
				}}
			>
				Support Webinar Details
			</h3>

			<div style={{ marginBottom: "20px" }}>
				<label htmlFor="webinar-link" style={labelStyles}>
					Webinar Link
				</label>
				<input
					id="webinar-link"
					type="url"
					value={link}
					onChange={(e) => setLink(e.target.value)}
					placeholder="Enter webinar link"
					style={inputStyles}
				/>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<label htmlFor="client-id" style={labelStyles}>
					Client IDs
				</label>
				<input
					id="client-id"
					type="text"
					value={currentClientId}
					onChange={(e) => setCurrentClientId(e.target.value)}
					onKeyPress={handleClientIdSubmit}
					placeholder="Enter client ID and press Enter"
					style={inputStyles}
				/>
				<div
					style={{
						marginTop: "12px",
						display: "flex",
						flexWrap: "wrap",
						gap: "8px"
					}}
				>
					{clientIds.map((id) => (
						<span
							key={id}
							style={{
								background: "#f0f0f0",
								padding: "6px 10px",
								borderRadius: "16px",
								fontSize: "14px",
								display: "flex",
								alignItems: "center",
								color: "#444",
								border: "1px solid #e0e0e0"
							}}
						>
							{id}
							<button
								type="button"
								onClick={() => removeClientId(id)}
								style={{
									marginLeft: "6px",
									border: "none",
									background: "none",
									cursor: "pointer",
									padding: "0 4px",
									color: "#666",
									fontSize: "16px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
								aria-label={`Remove client ID ${id}`}
							>
								×
							</button>
						</span>
					))}
				</div>
			</div>

			<div style={{ marginBottom: "20px" }}>
				<label htmlFor="webinar-date" style={labelStyles}>
					Date
				</label>
				<input id="webinar-date" type="datetime-local" value={date} onChange={(e) => setDate(e.target.value)} style={inputStyles} />
			</div>

			{error && <div style={{ color: "#dc3545", marginBottom: "16px", fontSize: "14px" }}>{error}</div>}

			<button
				onClick={handleSend}
				disabled={isSending}
				style={{
					...buttonStyles,
					opacity: isSending ? 0.7 : 1,
					cursor: isSending ? "not-allowed" : "pointer"
				}}
			>
				{isSending ? "Sending..." : "Send Invites"}
			</button>
			{emailsSentTo.length > 0 && <p>Emails Sent To: {emailsSentTo}</p>}
		</div>
	);
}

export default function EmailSending() {
	const sendingManualEmails = useFeatureFlagEnabled("EmailSendingPage");

	const emailOptions = [
		{
			label: "Support Webinar",
			component: <SupportWebinar />
		}
	];

	if (sendingManualEmails) {
		// do something
	}
	return (
		<div>
			{sendingManualEmails && (
				<div>
					{emailOptions.map((option) => (
						<div>
							{option.label}
							{option.component}
						</div>
					))}
				</div>
			)}
		</div>
	);
}
