import React from "react";
import styles from "../../styleModules/TemplateSelector.module.css";
import { FaRegFileWord } from "react-icons/fa";

const templates = [
	{
		id: 1,
		name: "Chronological Resume",
		url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/BasicChronologicalResumePlaceholders18.docx",
		imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/BasicChronologicalResumeImage2.JPG"
	},
	{
		id: 2,
		name: "Professional Highlights Resume",
		url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ProfessionalHighlightsResumePlaceholders3.docx",
		imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ProfessionalHighlightsImage.JPG"
	},
	{
		id: 3,
		name: "Creative Resume",
		url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/CreativeResume18.docx",
		imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/CreativeResume.JPG"
	},
	{
		id: 4,
		name: "Classic Clear Resume",
		url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ClassicClearResumePlaceholders2.docx",
		imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ClassicClearResumeImage.JPG"
	},
	{
		id: 5,
		name: "Two Column Resume",
		url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/TwoColumnResumeTemplate10.docx",
		imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/TwoColumnResumeImage.JPG"
	},
	{
		id: 6,
		name: "GPT Resume 1",
		url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/GPT_Resume_1.docx",
		imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/TwoColumnResumeImage.JPG"
	}
	// Add more templates here in the future
];

// eslint-disable-next-line
const TemplateSelector = ({ onSelectTemplate }) => {
	return (
		<div className={styles.templateSelector}>
			<div style={{ display: "flex", alignItems: "center" }}>
				<FaRegFileWord
					style={{
						fontSize: "2rem",
						justifyContent: "center",
						alignItems: "center",
						marginTop: "6rem",
						marginBottom: "2rem",
						marginRight: "1rem"
					}}
				/>
				<h1 className={styles.mainTitle}>Select Resume Template</h1>
			</div>

			{/* <h2 className={styles.subtitle}>Select a Resume Template</h2> */}
			<div className={styles.templateGrid}>
				{templates.map((template) => (
					<button
						key={template.id}
						className={styles.templateItem}
						onClick={() => onSelectTemplate(template)}
						onKeyDown={(e) => {
							if (e.key === "Enter" || e.key === " ") {
								onSelectTemplate(template);
							}
						}}
					>
						<img src={template.imageUrl} alt={template.name} className={styles.templateImage} />
						{/* <p className={styles.templateName}>{template.name}</p> */}
					</button>
				))}
			</div>
		</div>
	);
};

export default TemplateSelector;
