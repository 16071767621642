import { useState, useEffect } from "react";

const useTypewriterEffect = (text, prevCompleted, typingSpeed = 50, byWord = true, delay = 0, skipable) => {
	const [typedText, setTypedText] = useState("");
	const [isTyping, setIsTyping] = useState(false);
	const [completed, setCompleted] = useState(false);

	const getNextText = (byWord, currentText, fullText) => {
		if (!byWord) {
			return fullText.slice(0, currentText.length + 1);
		}

		const words = fullText.split(" ");
		const currentWords = currentText.split(" ");
		const nextWordIndex = currentWords.length - 1;

		if (nextWordIndex < words.length) {
			return currentWords.concat(words[nextWordIndex]).join(" ");
		}

		return fullText;
	};

	useEffect(() => {
		let timer;

		if (prevCompleted && !isTyping) {
			timer = setTimeout(() => {
				setIsTyping(true);
			}, delay);
		}

		if (isTyping) {
			if (typedText.length < text.length) {
				timer = setTimeout(() => {
					const currentWords = typedText.split(" ");

					if (currentWords.length % 10 === 0 && Math.random() < 0.4) {
						const stallTime = Math.random() * 200 + 100; // Stall time between 100ms and 300ms
						setTimeout(() => setTypedText(getNextText(byWord, typedText, text)), stallTime);
					} else {
						setTypedText(getNextText(byWord, typedText, text));
					}
				}, typingSpeed);
			} else {
				setCompleted(true);
			}
		}

		return () => clearTimeout(timer);
	}, [text, typedText, isTyping, prevCompleted, typingSpeed, byWord, delay]);

	useEffect(() => {
		// Attach event listener to document for mouse clicks
		document.addEventListener("mousedown", handleClick);

		// Clean up event listener when component unmounts
		return () => {
			document.removeEventListener("mousedown", handleClick);
		};
	}, []);

	function handleClick() {
		if (skipable) {
			setCompleted(true);
		}
	}

	return [typedText, completed];
};

export default useTypewriterEffect;
