import React, { useState, useEffect, useContext } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, PieChart, Pie, Cell, Legend } from "recharts";
import styles from "../../styleModules/adminSystemStyles.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { axiosRetryGet } from "../UtilityFunctions/axiosRetry";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { CSVLink } from "react-csv";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";

// Generic DashboardItem component
function DashboardItem({ title, size = "medium", onClick, expanded, children }) {
	return (
		<div
			className={`${styles.dataItem} ${styles[size]} ${expanded ? styles.expanded : ""}`}
			onClick={onClick}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					onClick();
				}
			}}
			role="button"
			tabIndex={0}
			style={{ display: expanded ? "flex" : "" }}
		>
			<h3 className={styles.dataItemTitle}>{title}</h3>
			{expanded && (
				<button
					type="button"
					className={styles.closeButton}
					onClick={(e) => {
						e.stopPropagation();
						onClick();
					}}
				>
					&times;
				</button>
			)}
			<div className={`${styles.mainField} ${expanded ? styles.expandedField : ""}`}>{children}</div>
		</div>
	);
}

// Small item with just a value
function DashboardItemSmall({ title, value, onClick, expanded }) {
	return (
		<DashboardItem title={title} size="small" onClick={onClick} expanded={expanded}>
			<p className={styles.dataItemValue}>{value}</p>
		</DashboardItem>
	);
}

// Item with circular progress
function DashboardItemPercent({ title, value, maxValue, onClick, expanded, onExport, showExportButton, cardSize }) {
	const [percentage, setPercentage] = useState(0);

	useEffect(() => {
		setPercentage((value / maxValue) * 100);
	}, [value, maxValue]);

	return (
		<DashboardItem title={title} size={cardSize} onClick={onClick} expanded={expanded}>
			<div className={styles.circularProgressContainer}>
				<CircularProgressbar
					value={percentage}
					// text={`${value}`}
					styles={buildStyles({
						textSize: "28px",
						pathColor: percentage >= 100 ? "#ee4188" : "#00a9af",
						textColor: "#344054",
						trailColor: "#e6e8f4"
					})}
				/>
				<div className={styles.circularProgressBarText}>{value}</div>
				<div className={styles.maxValueOverlay}>/ {maxValue}</div>
			</div>
			{showExportButton && (
				<button
					type="button"
					className={styles.exportButton}
					onClick={(e) => {
						e.stopPropagation();
						onExport();
					}}
				>
					Export CSV
				</button>
			)}
		</DashboardItem>
	);
}

// Item with bar chart
function DashboardItemBarChart({ title, data, onClick, expanded, itemSize }) {
	const chartData = data.map((item) => ({
		month: item.month.trim().substring(0, 3),
		count: parseInt(item.session_count, 10)
	}));

	return (
		<DashboardItem title={title} size={itemSize} onClick={onClick} expanded={expanded}>
			<ResponsiveContainer width="100%" height={expanded ? "90%" : "80%"}>
				<BarChart data={chartData}>
					<XAxis dataKey="month" />
					<YAxis />
					<Tooltip />
					<Bar dataKey="count" fill="#00a9af" />
				</BarChart>
			</ResponsiveContainer>
		</DashboardItem>
	);
}

function DashboardItemBarChart2({ title, data, onClick, expanded, itemSize }) {
	const chartData = [
		{
			name: "Uploads",
			resumes: data.total_resumes_uploaded,
			coverLetters: data.total_cover_letters_uploaded
		}
	];

	return (
		<DashboardItem title={title} size={itemSize} onClick={onClick} expanded={expanded}>
			<ResponsiveContainer width="100%" height={expanded ? "90%" : "80%"}>
				<BarChart data={chartData}>
					<XAxis dataKey="name" />
					<YAxis />
					<Tooltip />
					<Bar dataKey="resumes" fill="#00a9af" />
					<Bar dataKey="coverLetters" fill="#ee4188" />
				</BarChart>
			</ResponsiveContainer>
		</DashboardItem>
	);
}

// Item with pie chart
function DashboardItemPieChart({ title, data, onClick, expanded }) {
	const COLORS = [
		"#00a9af", // Blue
		"#ee4188", // Light Pink
		"#8A2BE2", // BlueViolet
		"#3CB371", // MediumSeaGreen
		"#FF1493", // DeepPink
		"#00CED1", // DarkTurquoise
		"#32CD32", // LimeGreen
		"#FF6347", // Tomato
		"#1E90FF", // DodgerBlue
		"#FF8C00" // DarkOrange
	];

	const pieData = data
		.filter((item) => item.setup_type !== null && item.session_count > 0)
		.map((item, index) => ({
			name: item.setup_type,
			value: parseInt(item.session_count, 10),
			color: COLORS[index % COLORS.length]
		}));

	if (pieData.length === 0) {
		return (
			<DashboardItem title={title} onClick={onClick} expanded={expanded}>
				<p>No data available</p>
			</DashboardItem>
		);
	}

	const CustomTooltip = React.memo(({ active, payload }) => {
		if (active && payload && payload.length) {
			const tooltipData = payload[0].payload;
			return (
				<div className={styles.customTooltip}>
					<p>{`${tooltipData.name}: ${tooltipData.value}`}</p>
				</div>
			);
		}
		return null;
	});

	return (
		<DashboardItem title={title} size="large" onClick={onClick} expanded={expanded}>
			<ResponsiveContainer width="100%" height={expanded ? "90%" : "80%"}>
				<PieChart>
					<Pie
						data={pieData}
						dataKey="value"
						nameKey="name"
						cx="50%"
						cy="50%"
						innerRadius={expanded ? "60%" : "80%"}
						outerRadius={expanded ? "90%" : "100%"}
						fill="#8884d8"
						scale={1.25}
					>
						{pieData.map((entry, index) => (
							<Cell key={`cell-${entry.name}`} fill={entry.color} />
						))}
					</Pie>
					<Tooltip content={<CustomTooltip />} />
					{expanded && <Legend layout="vertical" verticalAlign="middle" wrapperStyle={{ position: "absolute", right: 0 }} />}
				</PieChart>
			</ResponsiveContainer>
		</DashboardItem>
	);
}

export function TenantStats() {
	const { localUser } = useContext(AppContext);
	const instageId = localUser.userId;
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;

	const [numOfUsers, setNumOfUsers] = useState(0);
	const [numOfLicenses, setNumOfLicenses] = useState(0);

	const [totalSimsRun, setTotalSimsRun] = useState(0);
	const [totalSimsRun6Months, setTotalSimsRun6Months] = useState([]);
	const [totalSimsRunBySetupType, setTotalSimsRunBySetupType] = useState([]);
	const [expandedItem, setExpandedItem] = useState(null);

	const [totalResumesUploaded, setTotalResumesUploaded] = useState(0);
	const [totalCoverLettersUploaded, setTotalCoverLettersUploaded] = useState(0);
	const [totalResumesAndCoverLettersUploaded, setTotalResumesAndCoverLettersUploaded] = useState(0);
	const [totalTimeSpentInSessions, setTotalTimeSpentInSessions] = useState(0);
	const [dateFilter, setDateFilter] = React.useState([]);

	const [metadata, setMetadata] = useState({});

	const [licenseUsers, setLicenseUsers] = useState([]);

	const [csvLinkRef, setCsvLinkRef] = useState(null);

	const getMetadata = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/users/GetClientMetaData`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});
			const retrievedMetadata = response.data.clientMetadata[0].metadata;
			if (!retrievedMetadata) {
				return;
			}
			setMetadata(retrievedMetadata);
			console.log("Metadata", retrievedMetadata);

			// Set initial date filter based on license start date
			if (retrievedMetadata.licenseStartDate) {
				setDateFilter([retrievedMetadata.licenseStartDate, new Date().toISOString().split("T")[0]]);
			} else {
				const today = new Date();
				const twoYearsAgo = new Date(today.getFullYear() - 2, 0, 1); // First day of the year, 2 years ago
				setDateFilter([new Date(twoYearsAgo).toISOString().split("T")[0], new Date().toISOString().split("T")[0]]);
			}
		} catch (err) {
			console.error("Error fetching metadata:", err);
		}
	};

	const compareUniqueUsersToLicenses = async () => {
		if (dateFilter.length !== 2) {
			return;
		}
		try {
			const response = await axiosRetryGet(`${url}/api/users/CompareUniqueUsersToLicenses`, 1, {
				params: {
					start_date: dateFilter[0],
					end_date: dateFilter[1]
				},
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});
			console.log("startDate", dateFilter[0]);
			console.log("endDate", dateFilter[1]);
			if (!response.data.maxLicenses) {
				return;
			}
			const { maxLicenses, currentUsers } = response.data;

			setNumOfUsers(currentUsers);
			setNumOfLicenses(maxLicenses);
		} catch (err) {
			console.error("Error fetching license data:", err);
		}
	};

	// Fetch metadata when component mounts
	useEffect(() => {
		getMetadata();
	}, []);

	// Call compareUniqueUsersToLicenses when dateFilter changes
	useEffect(() => {
		if (dateFilter.length === 2) {
			compareUniqueUsersToLicenses();
		}
	}, [dateFilter]);

	// useEffect(() => {
	// 	if (metadata.licenseStartDate) {
	// 		const startDate = new Date(metadata.licenseStartDate);
	// 		const endDate = new Date(); // Today's date

	// 		setDateFilter([startDate, endDate]);
	// 	} else {
	// 		const startDate = new Date("2023-01-01");
	// 		const endDate = new Date();

	// 		setDateFilter([startDate, endDate]);
	// 	}
	// }, [metadata]);

	const exportLicenseCSV = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/users/GetLicenseCSVInfo`, 1, {
				params: {
					start_date: dateFilter[0],
					end_date: dateFilter[1]
				},
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});

			const { currentUsers } = response.data;
			// console.log("currentUsers", currentUsers);
			setLicenseUsers(currentUsers);
			if (csvLinkRef) {
				csvLinkRef.link.click();
			}
		} catch (err) {
			console.error("Error fetching license data:", err);
		}
	};

	// useEffect(() => {
	// 	exportLicenseCSV();
	// }, []);

	const getTotalSimsRun = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetSessionCountByClientId`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` },
				params: { client_id: localUser.tenantId, start_date: dateFilter[0], end_date: dateFilter[1] }
			});
			setTotalSimsRun(response.data);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};
	useEffect(() => {
		if (dateFilter.length === 2) {
			getTotalSimsRun();
		}
	}, [dateFilter]);

	const getTotalSimsRun6Months = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetSessionCountByClientIdPast6Months`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` },
				params: { client_id: localUser.tenantId }
			});
			setTotalSimsRun6Months(response.data);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};

	useEffect(() => {
		getTotalSimsRun6Months();
	}, []);

	const getSimsRunBySetupType = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetSessionCountBySetupType`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` },
				params: { client_id: localUser.tenantId, start_date: dateFilter[0], end_date: dateFilter[1] }
			});
			setTotalSimsRunBySetupType(response.data);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};

	useEffect(() => {
		if (dateFilter.length === 2) {
			getSimsRunBySetupType();
		}
	}, [dateFilter]);

	const getNumberOfResumesAndCoverLettersUploaded = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetNumberOfResumesAndCoverLettersUploaded`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` },
				params: { client_id: localUser.tenantId, start_date: dateFilter[0], end_date: dateFilter[1] }
			});
			// console.log(response.data.total_resumes_uploaded);
			setTotalResumesUploaded(response.data.total_resumes_uploaded);
			setTotalCoverLettersUploaded(response.data.total_cover_letters_uploaded);
			setTotalResumesAndCoverLettersUploaded(response.data);
			console.log(response.data);
			console.log(totalResumesUploaded, totalCoverLettersUploaded);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};

	useEffect(() => {
		if (dateFilter.length === 2) {
			getNumberOfResumesAndCoverLettersUploaded();
		}
	}, [dateFilter]);

	const getTotalTimeSpentInSessions = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/analytics/GetTotalTimeSpentInSessions`, 1, {
				params: {
					client_id: localUser.tenantId,
					start_date: dateFilter[0],
					end_date: dateFilter[1]
				},
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});
			console.log(response.data);
			setTotalTimeSpentInSessions(response.data[0].total_time_hours.toFixed(2));
			console.log(totalTimeSpentInSessions);
			console.log(response.data);
		} catch (error) {
			console.error("Error fetching total sims run:", error);
		}
	};

	useEffect(() => {
		if (dateFilter.length === 2) {
			getTotalTimeSpentInSessions();
		}
	}, [dateFilter]);

	const handleItemClick = (itemType) => {
		setExpandedItem(expandedItem === itemType ? null : itemType);
	};

	const ranges = [
		{
			label: "All time",
			value: [new Date("2023-01-01"), new Date()]
		},
		{
			label: "License Period",
			value: metadata && metadata.licenseStartDate ? [new Date(metadata.licenseStartDate), new Date()] : [new Date("2023-01-01"), new Date()]
		}
	];

	return (
		<div className={styles.adminPageContainer}>
			<h1 className={styles.dashboardHeading}>Aggregate Analytics</h1>
			<p style={{ marginBottom: "0" }}>Filter By Date:</p>
			<DateRangePicker
				format="yyyy-MM-dd"
				onChange={(value) => {
					if (value && value.length === 2) {
						setDateFilter(value);
						console.log("value", value);
					} else {
						const today = new Date();
						const septFirst = new Date(today.getFullYear(), 8, 1); // September 1st of the current year
						const janFirst = new Date(today.getFullYear(), 0, 1); // January 1st of the current year
						if (metadata.clientType === "edu") {
							setDateFilter([new Date(septFirst).toISOString().split("T")[0]], new Date(today).toISOString().split("T")[0]);
							console.log("dateFilter", dateFilter);
						} else {
							setDateFilter([new Date(janFirst).toISOString().split("T")[0]], new Date(today).toISOString().split("T")[0]);
							console.log("dateFilter", dateFilter);
						}
					}
					console.log("value", value);
				}}
				value={
					dateFilter.length === 2 ? dateFilter.map((date) => new Date(date)) : [new Date(new Date().getFullYear() - 2, 0, 1), new Date()]
				}
				after={new Date("2023-01-01").toISOString()}
				before={new Date().toISOString()}
				showOneCalendar
				showHeader={false}
				ranges={ranges}
				style={{ marginBottom: "1rem" }}
			/>

			<div className={`${styles.dataContainer} ${expandedItem ? styles.expanded : ""}`}>
				{(!expandedItem || expandedItem === "licenses") && (
					<DashboardItemPercent
						cardSize="large"
						title={metadata.clientType === "edu" ? "Assignment Licenses Used" : "Licenses Used"}
						value={numOfUsers}
						maxValue={numOfLicenses}
						onClick={() => handleItemClick("licenses")}
						expanded={expandedItem === "licenses"}
						onExport={exportLicenseCSV}
						showExportButton={true}
					/>
				)}
				{/* {(!expandedItem || expandedItem === "licenses") && (
					<DashboardItemPercent
						cardSize="medium"
						title={metadata.clientType === "edu" ? "Assignment Licenses Used" : "Licenses Used"}
						value={numOfUsers}
						maxValue={numOfLicenses}
						onClick={() => handleItemClick("licenses")}
						expanded={expandedItem === "licenses"}
						onExport={exportLicenseCSV}
						showExportButton={true}
					/>
				)} */}
				<div className={styles.stackedDataContainer}>
					{(!expandedItem || expandedItem === "totalSims") && (
						<DashboardItemSmall
							title="Total Sims Run"
							value={totalSimsRun.count}
							onClick={() => handleItemClick("totalSims")}
							expanded={expandedItem === "totalSims"}
						/>
					)}

					{(!expandedItem || expandedItem === "totalTime") && (
						<DashboardItemSmall
							title="Total Session Time (hrs)"
							value={totalTimeSpentInSessions}
							onClick={() => handleItemClick("totalTime")}
							expanded={expandedItem === "totalTime"}
						/>
					)}
				</div>
				{(!expandedItem || expandedItem === "resCovuploads") && (
					<DashboardItemBarChart2
						itemSize="medium"
						title="Resumes & Cover Letters Uploaded"
						data={totalResumesAndCoverLettersUploaded}
						onClick={() => handleItemClick("resCovuploads")}
						expanded={expandedItem === "resCovuploads"}
					/>
				)}
				{(!expandedItem || expandedItem === "monthlySims") && (
					<DashboardItemBarChart
						itemSize="large"
						title="Monthly Sim Usage"
						data={totalSimsRun6Months}
						onClick={() => handleItemClick("monthlySims")}
						expanded={expandedItem === "monthlySims"}
					/>
				)}
				{(!expandedItem || expandedItem === "simsBySetup") && totalSimsRunBySetupType.length > 0 && (
					<DashboardItemPieChart
						title="Sims Run by Setup Type"
						data={totalSimsRunBySetupType}
						onClick={() => handleItemClick("simsBySetup")}
						expanded={expandedItem === "simsBySetup"}
					/>
				)}
			</div>
			{licenseUsers.length > 0 && (
				<CSVLink data={licenseUsers} filename="license_users.csv" className={styles.hiddenCSVLink} ref={setCsvLinkRef} target="_blank" />
			)}
		</div>
	);
}
