export function validateString(str) {
	const singleFillers = ["nuh-uh", "uh-huh", "uh-uh", "mm-mm", "mmhm", "eh", "ah", "uh", "uh uh", "um", "uh,", "oh"];

	if (str === null || str === undefined) {
		return false;
	}

	str = str.toLowerCase().trim();
	str = str.replace(/,/g, ""); // Remove all commas

	if (str === "" || str === " " || str.split(" ").join("") === "") {
		return false;
	}
	if (singleFillers.includes(str)) {
		return false;
	}
	return true;
}
