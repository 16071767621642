import React, { useState, useEffect, useContext } from "react";
import { FiWifi, FiClock, FiActivity, FiPackage, FiInfo, FiChevronRight, FiChevronUp } from "react-icons/fi";
import ModalWrapper from "./ModalWrapper.js";
import { IoClose } from "react-icons/io5/index.esm.js";
import s2 from "../../styleModules/modalStyles.module.css";
import { AppContext } from "../UtilityFunctions/AppContext.js";

const speedThresholds = { good: 15, moderate: 8 };
const latencyThresholds = { good: 5, moderate: 15 };
const jitterThresholds = { good: 50, moderate: 100 };
const packetLossThresholds = { good: 10, moderate: 30 };
const fpsThresholds = { good: 60, moderate: 30 };
const ramThresholds = { good: 450, moderate: 700 };

const colors = {
	1: "#00a9af", // teal
	2: "#fdd835", // yellow
	3: "#ee4188" // magenta
};

const getQualityColor = (value, thresholds) => {
	if (thresholds.good > thresholds.moderate) {
		if (value >= thresholds.good) return colors[1];
		if (value >= thresholds.moderate) return colors[2];
	} else {
		if (value <= thresholds.good) return colors[1];
		if (value <= thresholds.moderate) return colors[2];
	}
	return colors[3];
};

const getQualityStatus = (value, thresholds) => {
	if (thresholds.good > thresholds.moderate) {
		if (value >= thresholds.good) return 1;
		if (value >= thresholds.moderate) return 2;
	} else {
		if (value <= thresholds.good) return 1;
		if (value <= thresholds.moderate) return 2;
	}
	return 3;
};

const getQualityStatusText = (value, thresholds) => {
	if (thresholds.good > thresholds.moderate) {
		if (value >= thresholds.good) return "Normal";
		if (value >= thresholds.moderate) return "Moderate";
	} else {
		if (value <= thresholds.good) return "Normal";
		if (value <= thresholds.moderate) return "Moderate";
	}
	return "Poor";
};

const useTranslation = (initialDict) => {
	const { performTranslation, userLanguage, performanceMode, setPerformanceMode } = useContext(AppContext);
	const [pageText, setPageText] = useState(initialDict);
	const [hasTranslatedText, setHasTranslatedText] = useState(false);

	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage]);

	return pageText;
};

const getOverallStatus = (statuses) => {
	const statusCounts = statuses.reduce((acc, status) => {
		acc[status] = (acc[status] || 0) + 1;
		return acc;
	}, {});

	if (statusCounts[3]) return 3;
	if (statusCounts[2]) return 2;
	return 1;
};

const getOverallStatusText = (statuses) => {
	const statusCounts = statuses.reduce((acc, status) => {
		acc[status] = (acc[status] || 0) + 1;
		return acc;
	}, {});

	if (statusCounts[3]) return "Poor";
	if (statusCounts[2]) return "Moderate";
	return "Good";
};

const MetricDisplay = ({ label, value, unit, description, thresholds }) => {
	const color = getQualityColor(value, thresholds);
	const status = getQualityStatusText(value, thresholds);

	const containerStyle = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		marginBottom: "12px",
		paddingTop: "10px"
	};

	const labelStyle = {
		color: "#16013e !important",
		fontSize: "14px",
		fontWeight: "bold"
	};

	const descriptionStyle = {
		color: "#a0a0a0",
		fontSize: "12px",
		marginTop: "2px"
	};

	const valueStyle = {
		color: "#a0a0a0",
		fontSize: "14px",
		textAlign: "right"
	};

	const statusStyle = {
		color,
		fontSize: "14px",
		fontWeight: "bold",
		textAlign: "right"
	};

	return (
		<div style={containerStyle}>
			<div>
				<div style={labelStyle}>{label}</div>
				<div style={descriptionStyle}>{description}</div>
			</div>
			<div>
				<div style={statusStyle}>{status}</div>
				<div style={valueStyle}>
					{value}
					{unit}
				</div>
			</div>
		</div>
	);
};

const FPSTracker = ({ thresholds }) => {
	const [fps, setFps] = useState(0);
	const status = getQualityStatusText(fps, thresholds);
	const color = getQualityColor(fps, thresholds);

	const pageText = useTranslation({
		FramesPerSecond: "Frames per second"
	});

	const labelStyle = {
		color: "#16013e !important",
		fontSize: "14px",
		fontWeight: "bold"
	};

	const descriptionStyle = {
		color: "#a0a0a0",
		fontSize: "12px",
		marginTop: "2px"
	};

	const statusStyle = {
		color,
		fontSize: "14px",
		fontWeight: "bold",
		textAlign: "right"
	};

	const containerStyle = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		marginBottom: "12px",
		paddingTop: "10px"
	};

	const valueStyle = {
		color: "#a0a0a0",
		fontSize: "14px",
		textAlign: "right"
	};

	useEffect(() => {
		let frameCount = 0;
		let lastTime = performance.now();
		let frameId;

		const updateFps = () => {
			const now = performance.now();
			const delta = now - lastTime;
			frameCount += 1;

			if (delta >= 1000) {
				const currentFps = Math.round((frameCount * 1000) / delta);
				setFps(currentFps);
				frameCount = 0;
				lastTime = now;
			}

			frameId = requestAnimationFrame(updateFps);
		};

		updateFps();

		return () => {
			cancelAnimationFrame(frameId);
		};
	}, []);

	return (
		<div style={containerStyle}>
			<div>
				<div style={labelStyle}>{"FPS"}</div>
				<div style={descriptionStyle}>{pageText.FramesPerSecond}</div>
			</div>
			<div>
				<div style={statusStyle}>{status}</div>
				<div style={valueStyle}>{fps}</div>
			</div>
		</div>
	);
};

const RAMTracker = ({ thresholds }) => {
	const [ramUsage, setRamUsage] = useState(0);
	const [isSupported, setIsSupported] = useState(true);
	const status = getQualityStatusText(ramUsage, thresholds);
	const color = getQualityColor(ramUsage, thresholds);

	const pageText = useTranslation({
		RamUsage: "RAM Usage",
		RAMUsageExplanation: "RAM usage (MB) the lower the better",
		RAMUsageNotSupported: "RAM usage tracking is not supported in this browser."
	});

	const labelStyle = {
		color: "#16013e !important",
		fontSize: "14px",
		fontWeight: "bold"
	};

	const descriptionStyle = {
		color: "#a0a0a0",
		fontSize: "12px",
		marginTop: "2px"
	};

	const statusStyle = {
		color,
		fontSize: "14px",
		fontWeight: "bold",
		textAlign: "right"
	};

	const containerStyle = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "flex-start",
		marginBottom: "12px",
		paddingTop: "10px"
	};

	const valueStyle = {
		color: "#a0a0a0",
		fontSize: "14px",
		textAlign: "right"
	};

	useEffect(() => {
		if (!performance.memory) {
			setIsSupported(false);
			return;
		}

		const updateRamUsage = () => {
			if (performance.memory) {
				const usedJSHeapSize = performance.memory.usedJSHeapSize / 1048576; // Convert to MB
				setRamUsage(usedJSHeapSize);
			}
		};

		const intervalId = setInterval(updateRamUsage, 1000);

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	if (!isSupported) {
		return <div>{pageText.RAMUsageNotSupported}</div>;
	}

	return (
		<div style={containerStyle}>
			<div>
				<div style={labelStyle}>{pageText.RamUsage}</div>
				<div style={{ ...descriptionStyle, width: "175px", wordWrap: "break-word" }}>{pageText.RAMUsageExplanation}</div>
			</div>
			<div>
				<div style={statusStyle}>{status}</div>
				<div style={valueStyle}>{ramUsage.toFixed(2)} MB</div>
			</div>
		</div>
	);
};

export default function NetworkQualityDisplay({ socket }) {
	const [_speed, setSpeed] = useState(speedThresholds.good);
	const [_latency, setLatency] = useState(latencyThresholds.good);
	const [_jitter, setJitter] = useState(jitterThresholds.good);
	const [_packetLoss, setPacketLoss] = useState(packetLossThresholds.good);
	const [showMetrics, setShowMetrics] = useState(false);
	const [_fps, setFps] = useState(fpsThresholds.good);
	const [_ram, setRam] = useState(ramThresholds.good);

	const [showMoreNetwork, setShowMoreNetwork] = useState(false);
	const [showMorePerformance, setShowMorePerformance] = useState(false);
	const { performTranslation, userLanguage, performanceMode, setPerformanceMode } = useContext(AppContext);
	let currentFps = 61;

	const pageText = useTranslation({
		Normal: "Normal",
		Moderate: "Moderate",
		Poor: "Poor",
		Speed: "Speed",
		Latency: "Latency",
		Jitter: "Jitter",
		PacketLoss: "Packet loss",
		SpeedOfConnection: "The speed of the connection",
		TimeForDataToTravel: "Time for data to travel between participants",
		VarianceOrFluctuation: "Variance or fluctuation in latency",
		AmountOfDataLost: "Amount of data lost during transfer",
		Network: "Network",
		Performance: "Performance",
		YourNetworkConnectionIs: "Your network connection is",
		ThisIndicatesTheQualityOfYourDevicesNetworkConnection: "This indicates the quality of your devices network connection.",
		YourPerformanceIs: "Your performance is",
		ThisIndicatesHowWellYourDeviceIsHandlingTheAppPerformance: "This indicates how well your device is handling the apps performance.",
		ShowMore: "Show More",
		ShowLess: "Show Less",
		PerformanceMode: "Performance Mode"
	});

	const translationDictionary = {
		qualityStatuses: {
			1: pageText.Normal,
			2: pageText.Moderate,
			3: pageText.Poor
		},
		metricLabels: {
			speed: pageText.Speed,
			latency: pageText.Latency,
			jitter: pageText.Jitter,
			packetLoss: pageText.PacketLoss
		},
		metricDescriptions: {
			speed: pageText.SpeedOfConnection,
			latency: pageText.TimeForDataToTravel,
			jitter: pageText.VarianceOrFluctuation,
			packetLoss: pageText.AmountOfDataLost
		},
		units: {
			speed: "Mbps",
			latency: "ms",
			jitter: "ms",
			packetLoss: "%"
		},
		otherLabels: {
			networkTitle: pageText.Network,
			performanceTitle: pageText.Performance
		}
	};

	const containerStyle = {
		color: "#16013e !important",
		position: "relative",
		backgroundColor: "#f6f3ed",
		padding: "16px",
		borderRadius: "8px",
		maxWidth: "600px",
		minWidth: "600px",
		margin: "0 auto"
	};

	const headerStyle = {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "16px"
	};

	useEffect(() => {
		let frameCount = 0;
		let lastTime = performance.now();
		let frameId;

		const updateFps = () => {
			const now = performance.now();
			const delta = now - lastTime;
			frameCount += 1;

			if (delta >= 1000) {
				currentFps = Math.round((frameCount * 1000) / delta);
				setFps(currentFps);
				frameCount = 0;
				lastTime = now;
			}

			frameId = requestAnimationFrame(updateFps);
		};

		const checkFps = () => {
			// console.log("FPS: ", currentFps);
			if (currentFps < 20 && !performanceMode) {
				setShowMetrics(true);
			}
		};

		setTimeout(checkFps, 20000);

		updateFps();

		return () => {
			cancelAnimationFrame(frameId);
		};
	}, []);

	const calculateAndSendResults = (results, packetsLost) => {
		const avgLatency = results.reduce((sum, r) => sum + r.latency, 0) / results.length;
		const jitter = Math.sqrt(results.reduce((sum, r) => sum + (r.latency - avgLatency) ** 2, 0) / results.length);
		const avgThroughput = results.reduce((sum, r) => sum + r.throughput, 0) / results.length;
		const packetLoss = ((packetsLost.sent - packetsLost.received) / packetsLost.sent) * 100;

		setLatency(avgLatency.toFixed(2));
		setJitter(jitter.toFixed(2));
		setPacketLoss(packetLoss.toFixed(2));
		const cappedSpeed = Math.min(avgThroughput, 1000000000); // Cap speed at 1 gigabit (1,000,000,000 bits)
		setSpeed((cappedSpeed / 1000000).toFixed(0)); // Convert to Mbps for display

		if (performance.memory) {
			const usedJSHeapSize = performance.memory.usedJSHeapSize / 1048576; // Convert to MB
			setRam(usedJSHeapSize);
		}
		if (socket && socket.current && socket.current.connected) {
			socket.current.emit("connectionQuality", {
				avgLatency: avgLatency.toFixed(2),
				jitter: jitter.toFixed(2),
				speed: (cappedSpeed / 1000000).toFixed(0),
				packetLoss: packetLoss.toFixed(2)
			});
		}
	};

	const measureConnectionQuality = () => {
		if (socket && socket.current && socket.current.connected) {
			const packetSizes = [10, 100, 1000, 10000]; // in bytes
			const results = [];
			const packetsLost = { sent: 0, received: 0 };

			packetSizes.forEach((size, index) => {
				setTimeout(() => {
					const data = new Array(size).fill("a").join("");
					const sendTime = Date.now();
					packetsLost.sent += 1;

					socket.current.emit("pingTest", { data, sendTime }, (response) => {
						packetsLost.received += 1;
						const receiveTime = Date.now();
						const latency = receiveTime - sendTime;
						const throughput = (size * 8) / (latency / 1000); // bits per second

						results.push({ size, latency, throughput });

						if (index === packetSizes.length - 1) {
							calculateAndSendResults(results, packetsLost);
						}
					});
				}, index * 1000); // Stagger the tests by 1 second each
			});
		}
	};

	useEffect(() => {
		measureConnectionQuality();
		const intervalId = setInterval(() => {
			measureConnectionQuality();
		}, 10000); // 10 seconds

		return () => clearInterval(intervalId); // Cleanup interval on component unmount
	}, []);

	const networkStatuses = [
		// getQualityStatus(_speed, speedThresholds),
		getQualityStatus(_latency, latencyThresholds),
		getQualityStatus(_jitter, jitterThresholds),
		getQualityStatus(_packetLoss, packetLossThresholds)
	];

	const performanceStatuses = [getQualityStatus(_fps, fpsThresholds), getQualityStatus(_ram, ramThresholds)];

	const networkOverallStatus = getOverallStatus(networkStatuses);
	const performanceOverallStatus = getOverallStatus(performanceStatuses);

	if (showMetrics) {
		return (
			<ModalWrapper closeModal={() => setShowMetrics(false)}>
				<div style={containerStyle}>
					<span className={s2.modalCloseIcon} style={{ right: "0px", top: "0px" }}>
						<IoClose title="Close icon" onClick={() => setShowMetrics(false)} />
					</span>
					<div style={headerStyle}>
						<div>
							<span style={{ marginRight: "8px" }}>{translationDictionary.otherLabels.networkTitle}</span>
							<FiInfo
								onMouseEnter={(event) => {
									event.target.style.color = "#009399";
								}}
								onMouseLeave={(event) => {
									event.target.style.color = "#808080";
								}}
								onClick={(event) => {
									event.stopPropagation();
									window.open("https://help.instagepractice.com/en/articles/9577961-device-requirements", "_blank");
									event.target.style.color = "#808080";
								}}
							/>
						</div>

						<div style={{ display: "flex", alignItems: "center" }}>
							<span
								style={{
									color: colors[networkOverallStatus],
									marginRight: "12px",
									fontSize: "18px",
									fontWeight: "bold"
								}}
							>
								{translationDictionary.qualityStatuses[networkOverallStatus]}
							</span>
						</div>
					</div>
					<span className={`${s2.connectionQualityBanner} ${s2[`connectionQualityBanner-${networkOverallStatus}`]}`}>
						<div className={s2.connectionQualityBannerTexts}>
							<p className={`${s2.connectionQualityBannerTitle} ${s2[`connectionQualityBannerTitle-${networkOverallStatus}`]}`}>
								{pageText.YourNetworkConnectionIs} {translationDictionary.qualityStatuses[networkOverallStatus]}
							</p>
							<p className={`${s2.connectionQualityBannerSubtext} ${s2[`connectionQualityBannerSubtext-${networkOverallStatus}`]}`}>
								{pageText.ThisIndicatesTheQualityOfYourDevicesNetworkConnection}
							</p>
						</div>
					</span>
					<button
						onClick={() => setShowMoreNetwork(!showMoreNetwork)}
						style={{
							display: "flex",
							alignItems: "center",
							background: "none",
							border: "none",
							color: "#009399",
							cursor: "pointer",
							fontSize: "14px",
							fontWeight: "bold",
							marginTop: "10px"
						}}
					>
						{showMoreNetwork ? pageText.ShowLess : pageText.ShowMore}
						{showMoreNetwork ? <FiChevronUp style={{ marginLeft: "5px" }} /> : <FiChevronRight style={{ marginLeft: "5px" }} />}
					</button>
					{showMoreNetwork && (
						<>
							{/* <MetricDisplay
								icon={FiWifi}
								label={translationDictionary.metricLabels.speed}
								value={_speed}
								description={translationDictionary.metricDescriptions.speed}
								unit={translationDictionary.units.speed}
								thresholds={speedThresholds}
							/> */}
							<MetricDisplay
								icon={FiClock}
								label={translationDictionary.metricLabels.latency}
								value={_latency}
								unit={translationDictionary.units.latency}
								description={translationDictionary.metricDescriptions.latency}
								thresholds={latencyThresholds}
							/>
							<MetricDisplay
								icon={FiActivity}
								label={translationDictionary.metricLabels.jitter}
								value={_jitter}
								unit={translationDictionary.units.jitter}
								description={translationDictionary.metricDescriptions.jitter}
								thresholds={jitterThresholds}
							/>
							<MetricDisplay
								icon={FiPackage}
								label={translationDictionary.metricLabels.packetLoss}
								value={_packetLoss}
								unit={translationDictionary.units.packetLoss}
								description={translationDictionary.metricDescriptions.packetLoss}
								thresholds={packetLossThresholds}
							/>
						</>
					)}
					<hr style={{ width: "100%", margin: "20px 0" }} />
					<div>
						<div style={headerStyle}>
							<div>
								<span style={{ marginRight: "8px" }}>{translationDictionary.otherLabels.performanceTitle}</span>
								<FiInfo
									onMouseEnter={(event) => {
										event.target.style.color = "#009399";
									}}
									onMouseLeave={(event) => {
										event.target.style.color = "#808080";
									}}
									onClick={(event) => {
										event.stopPropagation();
										window.open("https://help.instagepractice.com/en/articles/9577961-device-requirements", "_blank");
										event.target.style.color = "#808080";
									}}
								/>
							</div>

							<div style={{ display: "flex", alignItems: "center" }}>
								<span
									style={{
										color: colors[performanceOverallStatus],
										marginRight: "12px",
										fontSize: "18px",
										fontWeight: "bold"
									}}
								>
									{translationDictionary.qualityStatuses[performanceOverallStatus]}
								</span>
							</div>
						</div>

						<span className={`${s2.connectionQualityBanner} ${s2[`connectionQualityBanner-${performanceOverallStatus}`]}`}>
							<div className={s2.connectionQualityBannerTexts}>
								<p className={`${s2.connectionQualityBannerTitle} ${s2[`connectionQualityBannerTitle-${performanceOverallStatus}`]}`}>
									{pageText.YourPerformanceIs} {translationDictionary.qualityStatuses[performanceOverallStatus]}
								</p>
								<p
									className={`${s2.connectionQualityBannerSubtext} ${
										s2[`connectionQualityBannerSubtext-${performanceOverallStatus}`]
									}`}
								>
									{pageText.ThisIndicatesHowWellYourDeviceIsHandlingTheAppPerformance}
								</p>
							</div>
						</span>
						<button
							onClick={() => setShowMorePerformance(!showMorePerformance)}
							style={{
								display: "flex",
								alignItems: "center",
								background: "none",
								border: "none",
								color: "#009399",
								cursor: "pointer",
								fontSize: "14px",
								fontWeight: "bold",
								marginTop: "10px"
							}}
						>
							{showMorePerformance ? pageText.ShowLess : pageText.ShowMore}
							{showMorePerformance ? <FiChevronUp style={{ marginLeft: "5px" }} /> : <FiChevronRight style={{ marginLeft: "5px" }} />}
						</button>
						{showMorePerformance && (
							<>
								<FPSTracker thresholds={fpsThresholds} />
								<RAMTracker thresholds={ramThresholds} />
							</>
						)}
						<br />
						<input
							type="checkbox"
							checked={performanceMode}
							onChange={() => {
								setPerformanceMode(!performanceMode);
								window.location.reload();
							}}
							id="performanceMode"
						/>
						<label htmlFor="performanceMode" style={{ marginBottom: "0" }}>
							{pageText.PerformanceMode}
						</label>
					</div>
				</div>
			</ModalWrapper>
		);
	}
	return (
		<FiWifi
			style={{
				color: colors[networkOverallStatus],
				fontSize: "24px",
				cursor: "pointer"
			}}
			onClick={() => setShowMetrics(true)}
		/>
	);
}
