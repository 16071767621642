import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { usePostHog } from "posthog-js/react";
import { openDB } from "idb";
import { IoArrowBack, IoArrowForward } from "react-icons/io5";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { getRandomArrayElement } from "../UtilityFunctions/getRandomArrayElement.js";
import useChatMessages from "../UtilityFunctions/useChatMessages.js";
import styles from "../../styleModules/setupStyles.module.css";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import { AudienceContainer } from "./AudienceContainer.js";
import AppPreview from "../../images/AppPreview.png";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export default function Preview() {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const location = useLocation();
	const navigate = useNavigate();
	const posthog = usePostHog();
	const { updateChatMessages } = useChatMessages();

	// GLOBAL VARIABLES
	const typingRefs1 = useRef(null);
	const { localUser, setupData, setSetupData, setActiveSessionData, translateDictText, userLanguage, performTranslation, handleNavigate } =
		useContext(AppContext);

	const LocalLanguageDict = {
		Start: "Start",
		Generating: "Generating",
		VideoOn: "Video On",
		NetworkingEvent: "Networking Event",
		Conference: "Conference",
		FirstDayAtWork: "First Day at Work",
		Colleague: "Colleague",
		PotentialClient: "Potential Client",
		EventOrganizer: "Event Organizer",
		Attendee: "Attendee",
		Speaker: "Speaker",
		PanelModerator: "Panel Moderator",
		Manager: "Manager",
		TeamMember: "Team Member",
		HRRepresentative: "HR Representative"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	// STATE VARIABLES
	const [generateStarted, setGenerateStarted] = useState(false);
	const [generateError, setGenerateError] = useState(false);
	const [intro_event, set_intro_event] = useState("");
	const [intro_details, set_intro_details] = useState("");
	const [screenState, setScreenState] = useState(0);
	const [firstMessageName, setFirstMessageName] = useState("");
	const [firstMessageText, setFirstMessageText] = useState("");
	const [hoveredAvatar, setHoveredAvatar] = useState(null);
	const [changeCount, setChangeCount] = useState(0);
	const [lastReset, setLastReset] = useState(Date.now());
	const [toastVisible, setToastVisible] = useState(false);
	const [toastAnimation, setToastAnimation] = useState("");
	const [toastMessage, setToastMessage] = useState("");
	const [showStartSimBtn, setShowStartSimBtn] = useState(true);
	const [videoOn, setVideoOn] = useState(true);

	const eventOptions = [pageText.NetworkingEvent, pageText.Conference, pageText.FirstDayAtWork];
	const detailsOptions = {
		[pageText.NetworkingEvent]: [pageText.Colleague, pageText.PotentialClient, pageText.EventOrganizer],
		[pageText.Conference]: [pageText.Attendee, pageText.Speaker, pageText.PanelModerator],
		[pageText.FirstDayAtWork]: [pageText.Manager, pageText.TeamMember, pageText.HRRepresentative]
	};
	const changeCountLimit = 6;
	const audienceOptions = ["David", "Nina"];
	const personalityOptions = [
		{ value: "Playful", label: "Playful" },
		{ value: "Cheeky", label: "Cheeky" }
	];
	const [personalities, setPersonalities] = useState({});
	const [randomAudience, setRandomAudience] = useState();
	const [randomPersonality, setRandomPersonality] = useState();

	const formatTime = () => {
		const date = new Date();
		let hours = date.getHours();
		let minutes = date.getMinutes();
		const ampm = hours >= 12 ? "PM" : "AM";
		hours = hours % 12 || 12;
		minutes = minutes < 10 ? `0${minutes}` : minutes;
		return `${hours}:${minutes} ${ampm}`;
	};

	const shuffleArray = (array) => {
		const shuffledArray = [...array];
		for (let i = shuffledArray.length - 1; i > 0; i--) {
			const j = Math.floor(Math.random() * (i + 1));
			[shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
		}
		return shuffledArray;
	};

	const showToast = (message) => {
		setToastMessage(message);
		setToastVisible(true); // Make the toast container visible
		setToastAnimation(styles.showToast); // Start the show animation

		setTimeout(() => {
			setToastAnimation(styles.hideToast); // Initiate hide animation

			// Wait for the hide animation to complete before setting toastVisible to false
			setTimeout(() => {
				setToastVisible(false);
			}, 300); // Match this duration with your CSS transition duration
		}, 2700); // Adjust based on desired visibility duration, ensuring total matches previous 3000ms
	};

	const checkChangeCount = () => {
		let allowed;

		// Limit the number of changes
		const now = Date.now();
		const timePassed = now - lastReset;

		// Reset the count every X milliseconds (e.g., 60000 for one minute)
		if (timePassed >= 60000) {
			setChangeCount(0);
			setLastReset(now);
		}

		// Check if the change limit has been reached
		if (changeCount >= changeCountLimit) {
			// Notify the user that the limit has been reached
			showToast("You have reached the limit for changing the avatar.");
			allowed = false;
		} else {
			allowed = true;
			setChangeCount(changeCount + 1);
		}

		return allowed;
	};

	// // Temp useEffect
	// useEffect(() => {
	// 	setSetupData({
	// 		...setupData,
	// 		simulation_id: 1353,
	// 		audience_role: "Conference Attendee",
	// 		user_role: "Expert Speaker"
	// 	});
	// }, []);

	const generateIntro = async () => {
		setGenerateStarted(true);

		// const tempAudience = ["David"];
		// const tempPersonality = ["Cheeky", "Playful", "Sincere", "Reserved"][Math.floor(Math.random() * 4)];

		const data = {
			instage_id: localUser.id,
			intro_event,
			intro_details,
			free_preview: true
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/intro/GenerateIntroDetails`, data, 1, {}, 30000);
			console.log("Generated details", resp.data);

			const question_list = [
				{
					index: 0,
					type: "General",
					text: ""
				},
				{
					index: 1,
					type: "General",
					text: ""
				}
			];

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "intro",
				setup_name: intro_event,
				setup_input: intro_details,

				setup_output: {
					audience_role: resp.data.generated_details.audience_role,
					user_role: resp.data.generated_details.user_role,
					question_list
				},
				created_date: start_time,
				last_modified: start_time,
				shared: false
			};

			// Save the simulation
			const resp2 = await axiosLimitedPost(`${url}/api/saved/simulation`, savedData, 1, {}, 30000);
			console.log("Saved simulation", resp2.data);

			setSetupData((prevSetupData) => ({
				...prevSetupData,
				simulation_id: resp2.data.simulation_id,
				audience_role: savedData.setup_output.audience_role,
				user_role: savedData.setup_output.user_role,
				intro_event,
				intro_details,
				question_list
			}));

			setScreenState(3);
		} catch (error) {
			console.error(error);
			setGenerateError(true);
		}
	};

	const changeAvatar = async ({ newAudience, newPersonality }) => {
		// Check if the change limit has been reached
		const allowed = checkChangeCount();
		if (!allowed) {
			return;
		}

		let englishPersonality = newPersonality;

		if (newPersonality === "Effronté") {
			englishPersonality = "Cheeky";
		} else if (newPersonality === "Espiègle") {
			englishPersonality = "Playful";
		} else if (newPersonality === "En colère") {
			englishPersonality = "Angry";
		} else if (newPersonality === "Réservé") {
			englishPersonality = "Reserved";
		}

		console.log("EnglishPersonality: ", englishPersonality);

		setShowStartSimBtn(false);
		setSetupData((prevSetupData) => ({
			...prevSetupData,
			audience: newAudience,
			personality: englishPersonality
		}));

		// Update the database with the new audience and personality
		const data1 = {
			simulation_id: setupData.simulation_id,
			value: newAudience,
			field: "audience"
		};
		const data2 = {
			simulation_id: setupData.simulation_id,
			value: englishPersonality,
			field: "personality"
		};
		await axiosLimitedPatch(`${url}/api/saved/simulation`, data1, 1, {}, 30000);
		await axiosLimitedPatch(`${url}/api/saved/simulation`, data2, 1, {}, 30000);

		const strTime = formatTime();

		let currentAudience;
		let currentPersonality;

		if (newAudience[0] === "Random") {
			currentAudience = [getRandomArrayElement(audienceOptions.filter((option) => option !== "Random"))];
			currentPersonality = getRandomArrayElement(personalityOptions);
			setRandomAudience(currentAudience);
			setRandomPersonality(currentPersonality);
		} else {
			currentAudience = newAudience;
			currentPersonality = newPersonality;
		}

		const data = {
			setup_name: intro_event,
			instage_id: localUser.id,
			creator_instage_id: localUser.id,
			simulation_id: setupData.simulation_id,
			current_time: strTime,
			audience: currentAudience,
			personality: currentPersonality,
			intro_event,
			intro_details,
			audience_role: setupData.audience_role,
			user_role: setupData.user_role,
			question_list: setupData.question_list
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/intro/GetFirstMessage`, data, 1, {}, 30000);

			setFirstMessageName(resp.data.content.split(": ")[0].trim());
			setFirstMessageText(resp.data.content.split(":").slice(1).join(" "));
			setShowStartSimBtn(true);
		} catch (error) {
			console.error(error);
		}
	};

	const handlePersonalityChange = (audienceMember, personality) => {
		// Check if the change limit has been reached
		const allowed = checkChangeCount();
		if (!allowed) {
			return;
		}
		setPersonalities({ ...personalities, [audienceMember]: personality });
		setSetupData((prevSetupData) => ({
			...prevSetupData,
			personality
		}));
		changeAvatar({ newAudience: [audienceMember], newPersonality: personality });
	};

	const startSim = async () => {
		// Stop any ongoing simulations
		const ongoing = await axiosLimitedGet(`${url}/api/sessionData/session/ongoing`, 1, { params: { instage_id: localUser.id } });
		if (ongoing.data) {
			await axiosLimitedPost(`${url}/api/sessionData/session/ongoing`, { session_id: ongoing.session_id, value: false }, 1);
		}

		setShowStartSimBtn(false);

		// Reset the chat messages
		updateChatMessages([]);
		let newSimId;

		try {
			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const sessionData = {
				simulation_fk: setupData.simulation_id,
				instage_id: localUser.id,
				client_id: "free_preview",
				start_time,
				duration: 0,
				audience: randomAudience || setupData.audience,
				personality: randomPersonality || setupData.personality,
				submitted: false,
				chat_history: JSON.stringify([{ role: "assistant", content: `${firstMessageName}: ${firstMessageText}` }]),
				tempUser: true,
				setup_name: intro_event,
				setup_type: "intro",
				intro_event,
				intro_details,
				audience_role: setupData.audience_role,
				user_role: setupData.user_role,
				question_list: setupData.question_list
			};
			try {
				posthog?.capture("instage_preview_start_sim", {
					captureOn: false,
					videoOn,
					setup_id: "intro",
					audience: sessionData.audience,
					personality: sessionData.personality
				});
			} catch (error) {
				console.error("Posthog error:", error);
			}

			const createSessionResp = await axiosLimitedPost(`${url}/api/sessionData/session`, sessionData, 1);
			console.log("Session Created: ", createSessionResp.data);
			setActiveSessionData(createSessionResp.data);
			newSimId = createSessionResp.data.session_id;

			try {
				const db = await initDB();
				await db.put("simulation_db", videoOn, "videoAccess");
			} catch (e) {
				console.error(e);
			}
			handleNavigate(`/preview-simulation/intro/${setupData.simulation_id}/${newSimId}`, true);
		} catch (e) {
			setShowStartSimBtn(true);
			console.log(e);
			console.error(e);
			throw e;
		}
	};

	async function initDB() {
		return openDB("simulation", 1, {
			upgrade(db) {
				db.createObjectStore("simulation_db");
			}
		});
	}

	const toggleVideo = async () => {
		const db = await initDB();
		await db.put("simulation_db", !videoOn, "videoAccess");
		setVideoOn((prevVideoOn) => {
			const newVideoOn = !prevVideoOn;
			return newVideoOn;
		});
	};

	useEffect(() => {
		if (intro_details !== "" && generateStarted === false) {
			generateIntro();
		}
	}, [intro_details]);

	useEffect(() => {
		const shuffledPersonalities = shuffleArray(personalityOptions);
		const updatedPersonalities = audienceOptions.reduce((acc, option, index) => {
			if (option === "Random") {
				acc[option] = "???";
			} else {
				acc[option] = shuffledPersonalities[index];
			}
			return acc;
		}, {});

		setPersonalities(updatedPersonalities);
	}, []);

	return (
		<>
			<Helmet>
				<title>InStage | Preview</title>
				<meta name="description" content="InStage Preview" />
				<meta name="robots" content="noindex" />
			</Helmet>

			<div className={styles.previewPage}>
				<div className={`${styles.toastNotification} ${toastAnimation}`}>{toastMessage}</div>
				{screenState > 0 && screenState < 3 && (
					<div className={styles.previewBackBtnContainer}>
						<div className={styles.previewBackBtnSubContainer}>
							<IoArrowBack className={styles.previewBackBtn} onClick={() => setScreenState(screenState - 1)} />
						</div>
					</div>
				)}
				<div className={styles.previewPageContainer} style={{ paddingTop: screenState !== 0 ? "0" : "2rem" }}>
					{screenState === 0 && <FirstScreen setScreenState={setScreenState} />}
					{screenState === 1 && (
						<div className={styles.previewScreen}>
							<InputScreen1
								eventOptions={eventOptions}
								onSelect={(value) => {
									set_intro_event(value);
									setScreenState(2);
								}}
							/>
						</div>
					)}
					{screenState === 2 && (
						<div className={styles.previewScreen}>
							<InputScreen2
								detailsOptions={detailsOptions[intro_event]}
								onSelect={(value) => {
									set_intro_details(`Audience role: ${value}`);
									setScreenState(3);
								}}
							/>
						</div>
					)}
					{screenState === 3 && (
						<div className={styles.previewScreen}>
							<ExplainScreen generateError={generateError} setScreenState={setScreenState} setupData={setupData} />
						</div>
					)}
					{screenState === 4 && setupData && (
						<>
							<h1 className={styles.previewHeading}>Choose who you will introduce yourself to</h1>
							<div className={styles.previewAvatarCarousel}>
								{audienceOptions?.map((option, index) => {
									const selected = setupData.audience && setupData.audience.length > 0 && setupData.audience[0] === option;

									if (changeCount === changeCountLimit && !selected) {
										return null;
									}

									return (
										<div
											key={option}
											tabIndex={0}
											role="button"
											aria-label={option}
											onMouseOver={() => setHoveredAvatar(option)}
											onMouseOut={() => setHoveredAvatar(null)}
											onClick={() => {
												const selectedOptionPersonality = personalities[option];
												changeAvatar({ newAudience: [option], newPersonality: selectedOptionPersonality.value });
												// console.log("Selected Option Personality: ", selectedOptionPersonality);
												// console.log("Selected Option Personality Value: ", selectedOptionPersonality.value);
											}}
											onKeyDown={(event) => {
												if (event.key === "Enter") {
													const selectedOptionPersonality = personalities[option];
													changeAvatar({ newAudience: [option], newPersonality: selectedOptionPersonality.value });
												}
											}}
											className={`${styles.previewAvatarContainer} ${selected && styles.selectedAvatarContainer}`}
										>
											<AudienceContainer
												audience={[option]}
												typingRefs1={typingRefs1}
												isHovered={hoveredAvatar === option}
												personalityOptions={personalityOptions}
												personality={personalities[option]}
												handlePersonalityChange={handlePersonalityChange}
											/>
										</div>
									);
								})}
							</div>
							<div className={styles.previewStartContainer} style={{ position: "relative" }}>
								<div className={styles.startOptionContainer}>
									{/* <IoArrowBack
										className={styles.previewBackBtn}
										style={{ position: "absolute", left: "0" }}
										onClick={() => {
											set_intro_event("");
											set_intro_details("");
											setGenerateStarted(false);
											setScreenState(1);
										}}
									/> */}
									<input type="checkbox" id="startVideo" checked={videoOn} onChange={toggleVideo} />
									<label htmlFor="startVideo" style={{ marginBottom: "0", color: "grey" }}>
										{pageText.VideoOn}
									</label>
								</div>
								{!showStartSimBtn && <button className={`${styles.startBtn} ${styles.generateLoading}`}>Generating...</button>}
								{showStartSimBtn && (
									<button
										type="button"
										className={`${styles.startBtn} ${!setupData.audience?.length > 0 ? styles.disabled : ""}`}
										disabled={!setupData.audience?.length > 0}
										onClick={() => startSim()}
									>
										{pageText.Start}
									</button>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
}

function FirstScreen({ setScreenState }) {
	const posthog = usePostHog();
	const { localUser, translateDictText, userLanguage, performTranslation } = useContext(AppContext);

	const LocalLanguageDict = {
		FreePreview: "Free Preview",
		SelfIntroduction: "Self-Introduction",
		Experience: "Experience the power of conversational simulations.",
		Description1: "InStage is designed for instructors and career counsellors to provide Simulation Assignments to their students/clients.",
		Description2: "Users can engage in assigned simulations or create their own.",
		Description3: "*InStage is not available for individual purchase.",
		Description4: "**Mobile devices are not currently supported",
		ByContinuing: "By continuing I agree to the InStage",
		GetStarted: "Get Started",
		TermsOfService: "Terms of Service",
		PrivacyPolicy: "Privacy Policy",
		And: "and"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	const startPreviewSetup = () => {
		try {
			posthog?.capture("instage_preview_get_started", {
				instage_id: localUser.id
			});
			setScreenState(1);
		} catch (error) {
			console.error("Posthog error:", error);
		}
	};

	return (
		<div className={styles.previewSplitScreen}>
			<div className={styles.previewSplitLeft}>
				<h1 className={styles.previewMainHeading}>{pageText.FreePreview}</h1>
				<h1 className={styles.previewMainHeading}>
					<span style={{ color: "#DB4F86" }}> {pageText.SelfIntroduction}</span>
				</h1>
				<p className={styles.previewSubheading}>{pageText.Experience}</p>
				<p className={styles.previewDescription}>{pageText.Description1}</p>
				<p className={styles.previewDescription}>{pageText.Description2}</p>
				<div className={styles.previewNoteContainer}>
					<p className={styles.previewNote}>{pageText.Description3}</p>
					<p className={styles.previewNote}>{pageText.Description4}</p>
				</div>
			</div>
			<div className={styles.previewSplitRight}>
				<img src={AppPreview} alt="InStage App Preview" className={styles.previewImage} />
				<button type="button" className={styles.previewStartBtn} onClick={() => startPreviewSetup()}>
					{pageText.GetStarted}
				</button>
				<p className={styles.previewTerms}>
					{pageText.ByContinuing}{" "}
					<a target="_blank" href="https://instage.io/terms-service" rel="noreferrer">
						{pageText.TermsOfService}
					</a>{" "}
					{pageText.And}{" "}
					<a target="_blank" href="https://instage.io/privacy-policy" rel="noreferrer">
						{pageText.PrivacyPolicy}
					</a>
					.
				</p>
			</div>
		</div>
	);
}

function InputScreen1({ eventOptions, onSelect }) {
	const posthog = usePostHog();
	const { localUser, translateDictText, userLanguage, performTranslation } = useContext(AppContext);

	const LocalLanguageDict = {
		PersonalizeSim: "Let's personalize the simulation for you.",
		WhereAreYouIntroducingYourself: "Where are you introducing yourself?",
		EnterDifferentEvent: "Enter a different event..."
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);
	const [customEvent, setCustomEvent] = useState("");

	const handleSelection = (event) => {
		try {
			posthog?.capture("instage_preview_input_1", {
				instage_id: localUser.id,
				inputType: customEvent !== "" ? "custom" : "preset",
				inputValue: event
			});
			onSelect(event);
		} catch (error) {
			console.error("Posthog error:", error);
		}
	};

	return (
		<>
			<p className={styles.previewInputHeading}>{pageText.PersonalizeSim}</p>
			<h1 className={styles.previewHeading}>{pageText.WhereAreYouIntroducingYourself}</h1>
			<div className={styles.previewSelectContainer}>
				<div className={styles.previewInputContainer}>
					<input
						className={styles.previewInput}
						type="text"
						placeholder={pageText.EnterDifferentEvent}
						value={customEvent}
						onChange={(e) => setCustomEvent(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter" && customEvent !== "") {
								handleSelection(customEvent);
							}
						}}
					/>
					<button
						type="button"
						aria-label="Confirm"
						className={`${styles.previewInputConfirm} ${customEvent === "" ? styles.previewDisabledConfirm : ""}`}
						disabled={customEvent === ""}
						onClick={() => handleSelection(customEvent)}
					>
						<IoArrowForward className={styles.previewInputArrow} />
					</button>
				</div>
				<div className={styles.previewBtnContainer}>
					{eventOptions.map((event, index) => (
						<button key={index} type="button" className={styles.previewBtn} onClick={() => handleSelection(event)}>
							{event}
							<IoArrowForward />
						</button>
					))}
				</div>
			</div>
		</>
	);
}

function InputScreen2({ detailsOptions, onSelect }) {
	const posthog = usePostHog();
	const { localUser, translateDictText, userLanguage } = useContext(AppContext);

	const LocalLanguageDict = {
		EnterDifferentEvent: "Enter a different event..."
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		async function performTranslation() {
			const keys = Object.keys(pageText); // Use pageText keys
			const values = Object.values(pageText); // Use pageText values
			if (!values || values.length === 0) {
				console.error("values is undefined or empty");
				return; // Exit if values are not valid
			}

			try {
				const translatedTexts = await translateDictText(values, userLanguage);
				let tempPageText = {};
				for (let i = 0; i < keys.length; i++) {
					let key = keys[i];
					// Update with translated text if available, otherwise use original
					tempPageText[key] = translatedTexts[i] || values[i];
				}
				setPageText(tempPageText); // Update state with new translated texts
			} catch (error) {
				console.error("Error translating texts:", error);
			}
		}

		performTranslation();
	}, [userLanguage]); // Depend on pageText to trigger re-translation
	const [customEvent, setCustomEvent] = useState("");
	const [customDetail, setCustomDetail] = useState("");

	const handleSelection = (event) => {
		try {
			posthog?.capture("instage_preview_input_2", {
				instage_id: localUser.id,
				inputType: customDetail !== "" ? "custom" : "preset",
				inputValue: event
			});
			onSelect(event);
		} catch (error) {
			console.error("Posthog error:", error);
		}
	};

	return (
		<>
			<p className={styles.previewInputHeading}>Let&apos;s personalize the simulation for you.</p>
			<h1 className={styles.previewHeading}>Who are you introducing yourself to?</h1>
			<div className={styles.previewSelectContainer}>
				<div className={styles.previewInputContainer}>
					<input
						className={styles.previewInput}
						type="text"
						placeholder={pageText.EnterDifferentEvent}
						value={customDetail}
						onChange={(e) => setCustomDetail(e.target.value)}
						onKeyDown={(e) => {
							if (e.key === "Enter" && customDetail !== "") {
								handleSelection(customDetail);
							}
						}}
					/>
					<button
						type="button"
						aria-label="Confirm"
						className={`${styles.previewInputConfirm} ${customDetail === "" ? styles.previewDisabledConfirm : ""}`}
						disabled={customDetail === ""}
						onClick={() => handleSelection(customDetail)}
					>
						<IoArrowForward className={styles.previewInputArrow} />
					</button>
				</div>
				<div className={styles.previewBtnContainer}>
					{detailsOptions?.map((detail, index) => (
						<button key={index} type="button" className={styles.previewBtn} onClick={() => handleSelection(detail)}>
							{detail}
							<IoArrowForward />
						</button>
					))}
				</div>
			</div>
		</>
	);
}

function ExplainScreen({ generateError, setScreenState, setupData }) {
	const posthog = usePostHog();

	const { localUser, translateDictText, userLanguage, performTranslation } = useContext(AppContext);

	const LocalLanguageDict = {
		Next: "Next"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	const handleNext = () => {
		try {
			posthog?.capture("instage_preview_explain_next", {
				instage_id: localUser.id
			});
			setScreenState(4);
		} catch (error) {
			console.error("Posthog error:", error);
		}
	};

	return (
		<>
			{generateError && (
				<>
					<h1 className={styles.previewHeading}>Service Unavailable</h1>
					<p className={styles.previewText}>Please try again later.</p>
				</>
			)}
			{!generateError && (
				<>
					<h1 className={styles.previewHeading}>Generating your simulation...</h1>
					<p className={styles.previewText}>On the next page, select an avatar and start the simulation.</p>
					<p className={styles.previewText}>Afterwards, you&apos;ll receive a feedback report on your performance.</p>
					{!setupData ? (
						<div className={styles.spinnerContainerPreview}>
							<LoadingSpinner height={58} width={58} thickness={5} color="#00a9af" />
						</div>
					) : (
						<button type="button" className={styles.previewStartBtn} style={{ marginTop: "3rem" }} onClick={() => handleNext()}>
							{pageText.Next}
						</button>
					)}
				</>
			)}
		</>
	);
}
