const senecaTenant = {
	name: "Seneca",
	logo: {
		desktop: "/images/senecaLogo.png",
		mobile: "/images/senecaLogoIcon.png"
	},
	poweredBy: true
};

const tenants = {
	"001-76544-76699-74447": senecaTenant,
	"002-46546-66025-56742": senecaTenant,
	default: {
		name: "InStage",
		logo: {
			desktop: "/images/logoLarge.png",
			mobile: "/images/logoIcon.png"
		}
	}
};

export default tenants;
