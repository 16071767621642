import React, { useState } from "react";
import { IoClose } from "react-icons/io5/index.esm.js";
import s from "../../styleModules/modalStyles.module.css";

export function SoftPopup({ show, onHide, Text }) {
	return (
		<>
			{show && (
				<div className={`${s.softModal} ${show ? s.show : s.hide}`}>
					<IoClose className={s.modalCloseIcon} title="Close icon" onClick={() => onHide()} />
					<div className={s.softModalTextContainer}>
						<p className={s.modalText}>{Text}</p>
					</div>
				</div>
			)}
		</>
	);
}
