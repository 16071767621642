import React from "react";
import s from "../../styleModules/secondaryStyles.module.css";

export const LoadingSpinner = ({ height, width, thickness, color }) => {
	return (
		<div
			className={s.loadingSpinner}
			style={{
				height: height - thickness * 2,
				width: width - thickness * 2,
				border: `${thickness}px solid transparent`,
				borderTopColor: color,
				borderRightColor: color,
				borderBottomColor: color,
			}}
		></div>
	);
};
