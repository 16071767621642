import React, { useState, useContext, useEffect } from "react";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { IoClose, IoCopyOutline } from "react-icons/io5";
import styles from "../../styleModules/modalStyles.module.css";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import ModalWrapper from "./ModalWrapper.js";
import { LoadingSpinner } from "./LoadingSpinner";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export function ResumeModal({ show, onHide, showExplainer = false }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();

	const { userLanguage, translateDictText, performTranslation, localUser, setLocalUser } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Save: "Save",
		Edit: "Edit",
		Delete: "Delete",
		Done: "Done",
		Cancel: "Cancel",
		Okay: "Okay"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	const [showMainContent, setShowMainContent] = useState(!showExplainer);
	const [loading, setLoading] = useState(false);
	const [resume, setResume] = useState("");
	const [editMode, setEditMode] = useState(false);
	const [deleteConfirmStep, setDeleteConfirmStep] = useState(false);

	const saveResume = async () => {
		setLoading(true);
		const body = {
			instage_id: localUser.id,
			resume
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/users/saveResumeDetails`, body, 1, {}, 30000);
			console.log(resp.data);
			if (resp.data.generated_details) {
				setLocalUser((prevState) => ({ ...prevState, resume_details: resp.data.generated_details }));
				setEditMode(false);
			}
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};

	// Function to toggle edit mode and pre-fill textarea with existing resume details when entering edit mode
	const toggleEditMode = () => {
		if (!editMode) {
			setResume(localUser.resume_details.resume_input); // Pre-fill textarea when entering edit mode
		} else {
			setResume(""); // Optionally, clear the textarea when exiting edit mode
		}
		setEditMode(!editMode);
	};

	const deleteResume = async () => {
		setLoading(true);
		setResume("");
		const body = {
			instage_id: localUser.id
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/users/deleteResumeDetails`, body, 1, {}, 30000);
			console.log(resp.data);
			setLocalUser((prevState) => ({ ...prevState, resume_details: null }));
			setEditMode(false);
			setDeleteConfirmStep(false);
		} catch (error) {
			console.error(error);
		}
		setLoading(false);
	};

	return (
		<>
			{show && (
				<ModalWrapper closeModal={() => onHide()}>
					{!showMainContent ? (
						<div className={styles.modal}>
							<div className={styles.modalTextContainer}>
								<h1 className={styles.modalTitle}>Please add your resume before continuing</h1>
							</div>
							<div className={styles.modalBtnContainer}>
								<button className={styles.modalConfirmBtn} onClick={() => setShowMainContent(true)}>
									{pageText.Okay}
								</button>
							</div>
						</div>
					) : (
						<div className={styles.modal} style={{ minHeight: "50vh" }}>
							<span className={styles.modalCloseIcon}>
								<IoClose title="Close icon" onClick={() => onHide()} />
							</span>

							<div className={styles.modalTextContainer}>
								<h1 className={styles.modalTitle}>Your Resume Summary</h1>
							</div>

							{localUser && localUser.resume_details && !editMode && (
								<div className={styles.modalResumeDetails}>
									<label className={styles.modalResumeDetailLabel}>Experience:</label>
									<p>{localUser.resume_details.experience.summary}</p>
									<ul className={styles.modalResumeDetailList}>
										{localUser.resume_details.experience.roles.map((role, index) => (
											<li key={index} className={styles.modalResumeDetailRole}>
												<p className={styles.modalResumeDetailTitle}>
													{role.title}{" "}
													<span className={styles.modalResumeDetailDate}>
														({role.start_date} -{role.end_date})
													</span>
												</p>
												<p className={styles.modalResumeDetailText}>{role.organization}</p>
												<p className={styles.modalResumeDetailText}>{role.location}</p>
											</li>
										))}
									</ul>
									<label className={styles.modalResumeDetailLabel}>Skills:</label>
									<p>{localUser.resume_details.skills}</p>
									<label className={styles.modalResumeDetailLabel}>Education:</label>
									<p>{localUser.resume_details.education}</p>
									<div className={styles.modalBtnContainer}>
										{!deleteConfirmStep && (
											<>
												<button className={styles.modalConfirmBtn} onClick={() => onHide()}>
													{pageText.Done}
												</button>
												<button className={styles.modalCancelBtn} onClick={toggleEditMode}>
													{pageText.Edit}
												</button>
												<button className={styles.modalCancelBtn} onClick={() => setDeleteConfirmStep(true)}>
													{pageText.Delete}
												</button>
											</>
										)}
										{deleteConfirmStep && (
											<>
												<p className={styles.modalDeleteConfirmText}>Are you sure?</p>
												<button className={styles.modalResumeDeleteBtn} onClick={() => deleteResume()}>
													{pageText.Delete}
												</button>
												<button
													className={styles.modalCancelBtn}
													onClick={() => {
														setDeleteConfirmStep(false);
													}}
												>
													{pageText.Cancel}
												</button>
											</>
										)}
									</div>
								</div>
							)}
							{(editMode || !localUser.resume_details) && (
								<>
									<textarea
										className={styles.modalTextarea}
										style={{ minHeight: "20rem" }}
										placeholder="Paste your resume here..."
										value={resume}
										onChange={(e) => setResume(e.target.value)}
									/>
									<div className={styles.modalBtnContainer}>
										{loading && (
											<div className={styles.modalSpinnerContainer}>
												<LoadingSpinner height={64} width={64} thickness={8} color="#00a9af" />
											</div>
										)}
										{!loading && (
											<>
												<button
													className={resume.length > 0 ? styles.modalConfirmBtn : styles.modalDisabledBtn}
													disabled={resume.length === 0}
													onClick={() => {
														saveResume();
													}}
												>
													{pageText.Save}
												</button>
												<button
													className={styles.modalCancelBtn}
													onClick={() => {
														onHide();
													}}
												>
													{pageText.Cancel}
												</button>
											</>
										)}
									</div>
								</>
							)}
						</div>
					)}
				</ModalWrapper>
			)}
		</>
	);
}

export default ResumeModal;
