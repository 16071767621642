import React, { useEffect, useState, useContext, useRef } from "react";
import Draggable from "react-draggable";
import { FiDownload, FiMaximize, FiPlay, FiPause, FiVolume2, FiVolumeX, FiX } from "react-icons/fi";
import { VideoContext } from "../UtilityFunctions/AppContext.js";
import r from "../../styleModules/reportStyles.module.css";

function VideoPlayer() {
	const { VideoRef, playingVideoIndex, setPlayingVideoIndex, videoDownloadLink } = useContext(VideoContext);

	const [isPlaying, setIsPlaying] = useState(true);
	const [isMuted, setIsMuted] = useState(false);

	const TimeDisplayRef = useRef(null);
	const ScrubberRef = useRef(null);
	const VolumeRef = useRef(null);

	const handlePlayPause = () => {
		if (VideoRef.current.paused) {
			VideoRef.current.play();
			setIsPlaying(true);
		} else {
			VideoRef.current.pause();
			setIsPlaying(false);
		}
	};

	const handleScrub = () => {
		VideoRef.current.currentTime = ScrubberRef.current.value;
	};

	const handleVolumeChange = () => {
		VideoRef.current.volume = VolumeRef.current.value;
		if (VolumeRef.current.value === "0") {
			setIsMuted(true);
		} else if (VolumeRef.current.value !== "0") {
			setIsMuted(false);
		}
	};

	const toggleVolume = () => {
		if (VideoRef.current.volume === 0) {
			VideoRef.current.volume = 1;
			VolumeRef.current.value = 1;
			setIsMuted(false);
		} else {
			VideoRef.current.volume = 0;
			VolumeRef.current.value = 0;
			setIsMuted(true);
		}
	};

	const handleFullscreen = () => {
		if (document.fullscreenElement) {
			document.exitFullscreen();
		} else {
			VideoRef.current.requestFullscreen();
		}
	};

	// Update the time display and scrubber as the video plays
	useEffect(() => {
		setIsPlaying(true);
		if (VideoRef.current) {
			VideoRef.current.ontimeupdate = () => {
				const { currentTime } = VideoRef.current;
				const { duration } = VideoRef.current;
				TimeDisplayRef.current.textContent = `${formatTime(currentTime)}`;
				ScrubberRef.current.value = currentTime;
				ScrubberRef.current.max = duration;
			};
		}
	}, []);

	// Function to format time in MM:SS format
	const formatTime = (seconds) => {
		const minutes = Math.floor(seconds / 60);
		const secs = Math.floor(seconds % 60)
			.toString()
			.padStart(2, "0");
		return `${minutes}:${secs}`;
	};

	const handlePlaybackRateChange = (event) => {
		if (VideoRef.current) {
			VideoRef.current.playbackRate = event.target.value;
		}
	};

	return (
		<Draggable>
			<div className={r.transcriptVideoContainer} style={{ visibility: playingVideoIndex !== null ? "visible" : "hidden" }}>
				<video
					ref={VideoRef}
					// controls
					style={{ width: "100%" }}
				>
					<source type="video/webm" />
					Your browser does not support the audio element.
				</video>
				<div className={r.transcriptVideoHeader}>
					<FiX
						className={r.transcriptVideoCloseBtn}
						onClick={() => {
							VideoRef.current.src = "";
							setPlayingVideoIndex(null);
						}}
					/>
				</div>
				<div className={r.transcriptVideoFooter}>
					<div className={r.transcriptVideoSlider}>
						<input type="range" ref={ScrubberRef} onChange={handleScrub} className={r.transcriptVideoScrubber} />
					</div>
					<div className={r.transcriptVideoControls}>
						<div className={r.transcriptVideoControlsLeft}>
							<div onClick={handlePlayPause}>
								{isPlaying ? <FiPause className={r.transcriptVideoIcon} /> : <FiPlay className={r.transcriptVideoIcon} />}
							</div>
							<div className={r.transcriptVideoSpeedControlWrapper}>
								<select className={r.transcriptVideoSpeedControl} defaultValue="1" onChange={handlePlaybackRateChange}>
									<option value="0.5">0.5x</option>
									<option value="0.75">0.75x</option>
									<option value="1">1x</option>
									<option value="1.25">1.25x</option>
									<option value="1.5">1.5x</option>
									<option value="2">2x</option>
								</select>
							</div>
							<div onClick={toggleVolume}>
								{isMuted ? (
									<FiVolumeX className={r.transcriptVideoIcon} style={{ color: "#ee4188" }} />
								) : (
									<FiVolume2 className={r.transcriptVideoIcon} />
								)}
							</div>
							<div className={r.transcriptVideoSlider}>
								<input type="range" ref={VolumeRef} onChange={handleVolumeChange} min="0" max="1" step="0.01" />
							</div>
							<span ref={TimeDisplayRef} />
						</div>
						<div className={r.transcriptVideoControlsRight}>
							<a className={r.videoDownloadLink} href={videoDownloadLink}>
								<FiDownload className={r.transcriptVideoIcon} />
							</a>
							<FiMaximize className={r.transcriptVideoIcon} onClick={handleFullscreen} />
						</div>
					</div>
				</div>
			</div>
		</Draggable>
	);
}

export default VideoPlayer;
