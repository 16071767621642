import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import s2 from "../../styleModules/modalStyles.module.css";
import { useFeatureFlagEnabled } from "posthog-js/react";

function LanguageDropDownComponent() {
	const { axiosLimitedPost } = useAxiosLimited();
	const { localUser, userLanguage, setUserLanguage, runTranslation } = useContext(AppContext);
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;
	const ngrokURL = "https://b512-45-44-183-85.ngrok-free.app";
	const multiLanguageFlag = useFeatureFlagEnabled("multi-language-support");

	const languageOptions = [
		{ value: "en", label: "English" },
		{ value: "fr", label: "French" }
		// Add more languages as needed
	];

	// Find the option that matches the current userLanguage
	const findLanguageOption = (languageCode) => languageOptions.find((option) => option.value === languageCode) || languageOptions[0];

	const [selectedLanguage, setSelectedLanguage] = useState(findLanguageOption(userLanguage));

	useEffect(() => {
		// Update selectedLanguage when userLanguage changes
		if (userLanguage === undefined || userLanguage === null) {
			setUserLanguage("en");
		}
		setSelectedLanguage(findLanguageOption(userLanguage));
	}, [userLanguage]);

	// useEffect(() => {
	// 	runTranslation();
	// });

	const handleLanguageChange = async (selectedOption) => {
		setUserLanguage(selectedOption.value);
		setSelectedLanguage(selectedOption);

		const body = {
			instage_id: localUser.id,
			language: selectedOption.value
		};
		try {
			await axiosLimitedPost(`${url}/api/users/UpdateLanguagePreference`, body, 1, {}, 30000);
			if (!window.location.href.includes("signup")) {
				window.location.reload();
			}
		} catch (err) {
			console.log(err);
			console.log("Error Updating Preference");
		}
	};

	return (
		<Select
			name="language"
			options={languageOptions}
			value={selectedLanguage}
			onChange={handleLanguageChange}
			placeholder="Select Language"
			menuPortalTarget={document.body} // Renders the dropdown menu in the body element
			styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }} // Ensures the dropdown is on top
		/>
	);
}

export default LanguageDropDownComponent;
