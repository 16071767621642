import React, { useState, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module";
import * as THREE from "three";
import { HDRCubeTextureLoader } from "three/examples/jsm/loaders/HDRCubeTextureLoader.js";

// let loader = new HDRCubeTextureLoader();
// let hdrTexture = loader.load('emptyroom_hdr.hdr');

// scene.environment = hdrTexture;

const useMeshoptGLTFLoader = (url, onProgress) => {
	const [gltf, setGltf] = useState();
	const [error, setError] = useState();

	useEffect(() => {
		if (!url) return;
		console.log("loading animation ...", url);
		const loader = new GLTFLoader();
		loader.setMeshoptDecoder(MeshoptDecoder);

		loader.load(
			url,
			(loadedGltf) => {
				// loadedGltf.scene.traverse((child) => {
				//   if (child.isMesh && child.name === "Side_part_wavy") {
				//     console.error(child);
				//     child.material.transparent = false;
				//     child.material.side = THREE.DoubleSide;
				//     console.error(child.material.side);
				//   }
				// });
				setGltf(loadedGltf);
			},
			onProgress,
			(err) => {
				console.error(err);
				setError(err);
			}
		);

		// Optional: clean up function if the component unmounts during loading
		return () => {
			loader.manager.itemStart(url); // This prevents the loader from calling onLoad after unmount
		};
	}, [url, onProgress]);

	return [gltf, error];
};

export default useMeshoptGLTFLoader;
