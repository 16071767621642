import { useEffect } from "react";
import { chatEventEmitter } from "./eventEmitter";

const useChatMessages = ({ OnPauseEvent = null } = {}) => {
	const updateChatMessages = (newMessage) => {
		chatEventEmitter.emit("updateChatMessages", newMessage);
	};

	const updateMediaControlsAllowed = (isAllowed) => {
		chatEventEmitter.emit("updateMediaControlsAllowed", isAllowed);
	};

	useEffect(() => {
		if (OnPauseEvent) {
			// console.log("useChatMessages register OnPauseEvent");
			chatEventEmitter.on("togglePause", OnPauseEvent);

			// Cleanup function
			return () => {
				chatEventEmitter.off("togglePause", OnPauseEvent);
			};
		}

		return () => {};
	}, [OnPauseEvent]); // Only re-run if OnPauseEvent changes

	return {
		updateChatMessages,
		updateMediaControlsAllowed
	};
};

export default useChatMessages;
