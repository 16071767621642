import React, { useEffect, useState, useRef, useContext } from "react";
import { FaCircle } from "react-icons/fa";
import { FiMic } from "react-icons/fi";
import { MdCheck } from "react-icons/md";
import styles from "../../styleModules/simulationStyles.module.css";
import ConnectionQualityDisplay from "../SecondaryComponents/ConnectionQualityDisplay.js";
import { usePostHog } from "posthog-js/react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { AppContext } from "../UtilityFunctions/AppContext.js";

export function UserVideoComponent({
	socket,
	ManualMuteMic,
	videoOn,
	userSpeaking,
	isThinking,
	stream,
	userMicText,
	canSendDeepgramDataRef,
	performanceModeEnabled
}) {
	const videoRef = useRef();

	const [safeToClick, setSafeToClick] = useState(false);
	const [micClicked, setMicClicked] = useState(false);
	const useMetricTracker = useFeatureFlagEnabled("use-metric-tracker");

	useEffect(() => {
		if (userSpeaking && canSendDeepgramDataRef.current) {
			setSafeToClick(true);
		} else {
			setSafeToClick(false);
		}
	}, [canSendDeepgramDataRef.current, userSpeaking]);

	useEffect(() => {
		if (userSpeaking) {
			setMicClicked(false);
		}
	}, [userSpeaking]);

	// MICROPHONE VISUALIZER
	const analyserRef = useRef(null);
	const animationFrameIdRef = useRef(null);
	const [volume, setVolume] = useState(0);
	const volumes = useRef([]); // a queue to keep track of the last N volume levels
	const numVolumes = 10; // the number of past volume levels to consider

	// Scale volume to control the glow effect (Adjust the divisor as per your requirement)
	const scaledVolume = Math.min(1, volume / 50); // Option 1
	// const scaledVolume = Math.sqrt(volume / 50); // Option 2
	const boxShadow = `0px 0px ${10 * scaledVolume}px ${10 * scaledVolume}px #00a9af`;

	const { translateDictText, userLanguage, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Thinking: "Thinking",
		You: "You"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
		}
	}, [userLanguage, pageText]);

	useEffect(() => {
		if (videoOn && stream) {
			// console.log("tracks", stream.getTracks());
			const videoTracks = stream.getVideoTracks();

			// find the live video track
			const videoTrack = videoTracks.find((track) => track.readyState === "live");
			if (videoTrack) {
				//  console.log("video track found", videoTrack);
				//  console.log(stream);
				const video = videoRef.current;
				video.srcObject = stream;
				video.play();
			}
			// set_characterCount(names.length + 1);
		} else {
			// set_characterCount(names.length);
		}
		// console.log("useEffect stream", stream)
	}, [videoOn, stream]);

	useEffect(() => {
		if (!stream) return;

		// Create an audio context
		const audioContext = new (window.AudioContext || window.webkitAudioContext)();
		const analyser = audioContext.createAnalyser();

		// Connect the stream to the analyser
		const source = audioContext.createMediaStreamSource(stream);
		source.connect(analyser);

		// Set up the analyser and byte array to hold the audio data
		analyser.fftSize = 256; // you can tweak this value
		const bufferLength = analyser.frequencyBinCount;
		const dataArray = new Uint8Array(bufferLength);

		// Function to check the volume
		const checkVolume = () => {
			analyser.getByteFrequencyData(dataArray);

			// Compute the volume
			let volume = dataArray.reduce((a, b) => a + b) / bufferLength;

			// Update our volume values and calculate the average volume
			volumes.current.push(volume);
			if (volumes.current.length > numVolumes) {
				volumes.current.shift(); // remove the oldest volume level if we have more than numVolumes levels
			}
			volume = volumes.current.reduce((a, b) => a + b) / volumes.current.length;

			setVolume(volume);

			// Update the animation frame
			animationFrameIdRef.current = requestAnimationFrame(checkVolume);
		};

		// Store the analyzer in a ref so we can access it later
		analyserRef.current = analyser;

		// Start checking the volume
		checkVolume();

		// Clean up function
		return () => {
			if (animationFrameIdRef.current) {
				cancelAnimationFrame(animationFrameIdRef.current);
			}

			analyser.disconnect();
			source.disconnect();
		};
	}, [stream]);

	const containerStyle = {
		border: userSpeaking ? "5px solid #DB4F86" : "5px solid #96928a"
		// boxShadow: userSpeaking ? "0px 3px 12px #00000086" : "none",
	};

	const micIconContainerClass = `${styles.micIconContainer} ${!userSpeaking || (userSpeaking && micClicked) ? styles.disabledMic : ""} ${
		safeToClick && !micClicked ? styles.micIconHoverable : ""
	}`;

	const micIconStyle = {
		boxShadow: userSpeaking ? boxShadow : "none"
		// backgroundColor: isThinking ? "red" : "#303d7c",
	};

	return (
		<div className={performanceModeEnabled ? styles.simCharacterWindowPerformanceMode : styles.simCharacterWindow} style={containerStyle}>
			{videoOn && stream ? (
				<video
					autoPlay
					playsInline
					muted
					style={{
						width: "100%",
						height: "100%",
						backgroundColor: "black",
						transform: "scaleX(-1)"
					}}
					ref={videoRef}
					id="UserVideo"
				/>
			) : (
				<img className={styles.simAvatarVideo} src="https://avatarportraits.s3.ca-central-1.amazonaws.com/User.png" alt="user placeholder" />
			)}

			{/* <div
				onClick={() => {
					if (safeToClick && !micClicked) {
						ManualMuteMic();
						setMicClicked(true);
					}
				}}
				className={micIconContainerClass}
				style={micIconStyle}
			>
				{isThinking ? <LoadingDots /> : safeToClick ? <MdCheck className={styles.micIcon} /> : <FiMic className={styles.micIcon} />}
			</div> */}

			{/* {userSpeaking && (
				<div className={styles.speakingInstructionsContainer}>
					<div className={styles.speakingInstructions}>{isThinking ? "Thinking" : userMicText}</div>
				</div>
			)} */}
			<div className={styles.simCharacterName}>{pageText.You}</div>
			{useMetricTracker && (
				<div
					style={{
						display: "flex",
						margin: "10px",
						padding: "0px",
						position: "absolute",
						width: "20px",
						bottom: "0",
						right: "0",
						top: "unset",
						backgroundColor: "transparent"
					}}
					className={styles.simCharacterName}
				>
					<ConnectionQualityDisplay socket={socket} />
				</div>
			)}
		</div>
	);
}

function LoadingDots() {
	return (
		<div className={styles.loadingDots}>
			<FaCircle className={styles.dot} />
			<FaCircle className={styles.dot} />
			<FaCircle className={styles.dot} />
		</div>
	);
}
