import { useCallback, useEffect, useRef, useState, useContext } from "react";
import { AppContext } from "./AppContext.js";

export const useUploadManager = () => {
	const queueRef = useRef([]);
	const [isUploading, setIsUploading] = useState(false);
	const [progress, setProgress] = useState(0); // Progress as a percentage
	const totalSizeRef = useRef(0); // Total size of all blobs
	const uploadedSizeRef = useRef(0); // Size of blobs already uploaded
	const isUploadingRef = useRef(false); // Add a ref to track uploading status
	const { uploadProgressReal, setUploadProgressReal } = useContext(AppContext);

	const updateProgress = () => {
		if (totalSizeRef.current === 0) {
			// console.log("totalSizeRef.current === 0");
			setProgress(100);
		} else {
			const percentage = (uploadedSizeRef.current / totalSizeRef.current) * 100;
			console.log("percentage: ", percentage);
			setProgress(percentage);
			setUploadProgressReal(Number(percentage.toFixed(2)));
		}
	};

	// useEffect(() => {
	// 	console.log("progress: ", progress);
	// }, [progress]);

	const processQueue = useCallback(async () => {
		if (isUploadingRef.current || queueRef.current.length === 0) {
			return;
		}
		isUploadingRef.current = true;
		setIsUploading(true);
		const { uploadFunction, params, blobSize } = queueRef.current.shift();
		uploadedSizeRef.current += blobSize; // Update uploaded size

		try {
			// console.log("Uploading...");
			await uploadFunction(...params);
			updateProgress(); // Update progress after each upload
		} catch (error) {
			console.error("Upload failed", error);
		} finally {
			// console.log("Done...");
			isUploadingRef.current = false;
			setIsUploading(false);

			if (queueRef.current.length > 0) {
				// console.log("processQueue()...processQueue", queueRef.current.length);

				processQueue(); // Process the next item in the queue
			}
		}
	}, [updateProgress]);

	useEffect(() => {
		processQueue();
	}, [processQueue]);

	const registerUpload = useCallback(
		(uploadFunction, params, blobSize) => {
			// console.log("registerUpload() blobSize: ", blobSize);
			queueRef.current.push({ uploadFunction, params, blobSize });
			totalSizeRef.current += blobSize; // Update total size
			updateProgress(); // Update progress when new upload is added

			if (!isUploadingRef.current) {
				// console.log("processQueue()...registerUpload");
				processQueue();
			}
		},
		[processQueue, updateProgress]
	);

	return { registerUpload, progress };
};
