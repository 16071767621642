import React, { useContext, useEffect, useState } from "react";
import styles from "../../styleModules/generateStyles.module.css";
import { AppContext } from "../UtilityFunctions/AppContext.js";

export function GenerateButton({
	inputRef,
	selectRef,
	textareaRef,
	dropdownRef1,
	dropdownRef2,
	generateStarted,
	generateSim,
	setError1,
	error1,
	error2,
	error3,
	error1Text,
	error3Text,
	handleModal
}) {
	const { activeSub, setupData, translateDictText, userLanguage, performTranslation } = useContext(AppContext);
	const generateDisabled = !activeSub;

	// Static Translation
	const LocalLanguageDict = {
		Generate: "Generate"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage, setupData]);

	return (
		<div className={styles.simLoadingContainer}>
			{generateDisabled && (
				<p className={styles.error} style={{ margin: "0" }}>
					You must have an active subscription to generate a simulation.
				</p>
			)}
			{error1 && (
				<p className={styles.error} style={{ margin: "0" }}>
					{error1Text}
				</p>
			)}
			{error3 && (
				<p className={styles.error} style={{ margin: "0" }}>
					{error3Text}
				</p>
			)}
			{!generateStarted && (
				<button
					type="button"
					className={`${styles.startBtn} ${
						((setupData?.setup_type === "reflection" && setupData.experience_type === "") ||
							(setupData?.setup_type === "patient" && setupData.user_role === "") ||
							(setupData?.setup_type === "patient" && setupData.visit_type === "") ||
							(inputRef && inputRef.current && inputRef.current.value.length === 0) ||
							generateDisabled ||
							error1 ||
							error2) &&
						styles.disabled
					}`}
					onClick={() => {
						if (setupData?.setup_type === "reflection" && setupData.experience_type === "") {
							dropdownRef1.current.focus();
							return;
						}
						if (setupData?.setup_type === "patient") {
							if (setupData.user_role === "") {
								dropdownRef1.current.focus();
								return;
							} else if (setupData.visit_type === "") {
								dropdownRef2.current.focus();
								return;
							}
						}
						if (error1 || (inputRef && inputRef.current && inputRef.current.value.length === 0)) {
							setError1(true);
							const refToUse = inputRef.current ? inputRef : selectRef;
							refToUse.current.focus();
							if (refToUse.current.scrollIntoView) {
								refToUse.current.scrollIntoView({ block: "center" });
							}
							return;
						}
						if (error2) {
							textareaRef.current.focus();
							textareaRef.current.scrollIntoView({ block: "center" });
							return;
						}
						handleModal();
						generateSim();
					}}
				>
					{pageText.Generate}
				</button>
			)}
		</div>
	);
}
