/**
 * Class representing the data related to a GPT request.
 * @property {number} gptId - The unique identifier of the GPT.
 * @property {string} text - The text content of the GPT request.
 * @property {boolean} generationComplete - A flag indicating whether the generation process is complete.
 * @property {boolean} generationStarted - A flag indicating whether the generation process has started.
 * @property {number} startTime - The timestamp indicating the start time of the generation process.
 * @property {number} endTime - The timestamp indicating the end time of the generation process.
 * @property {object} functionOutput - The output returned by the Gpt function.
 * @property {number} transcriptId - The transcript ID associated with the GPT.
 * @property {boolean} isIdleCheck - A flag indicating whether the GPT is in idle check mode.
 * @property {Date} timeStamp - The timestamp of the GPT request.
 */
class AvatarGptData {
	/**
	 * Create a new AvatarGptData object.
	 */
	constructor(transcriptId, isIdleCheck = false) {
		/**
		 * The ID of the GPT.
		 * @type {number}
		 */
		this.gptId = 0;
		/**
		 * The transcript ID of the GPT.
		 * @type {number}
		 */
		this.transcriptId = transcriptId;
		/**
		 * The text of the GPT request.
		 * @type {string}
		 */
		this.text = "";

		/**
		 * Flag to check if the generation is complete.
		 * @type {boolean}
		 */
		this.generationComplete = false;

		/**
		 * Flag to check if the generation has started.
		 * @type {boolean}
		 */
		this.generationStarted = false;

		/**
		 * The start time of the generation.
		 * @type {Date}
		 */
		this.startTime = Date.now();

		this.timeStamp = null;
		/**
		 * The end time of the generation.
		 * @type {Date}
		 */
		this.endTime = -1;

		/**
		 * The duration of the generation.
		 * @type {number}
		 */
		this.duration = -1;

		this.attempt;
		/**
		 * The output of the Gpt function.
		 * @type {object}
		 */
		this.functionOutput = {};
		/**
		 * flag to show that this ran because of the idle check .
		 * @type {boolean}
		 */
		this.isIdleCheck = isIdleCheck;

		/**
		 * The request id of the prompt layer request.
		 * @type {string}
		 * @default null
		 */
		this.promptLayer_request_id = null;
		/**
		 * The url of the prompt layer request.
		 */
		this.promptLayerUrl = "";

		/**
		 * The metadata of the Gpt function.
		 */
		this.metadata = "";
	}

	finishEvent(functionOutput, promptLayer_request_id, metadata) {
		this.generationComplete = true;
		this.endTime = Date.now();
		this.functionOutput = functionOutput;
		this.duration = this.endTime - this.startTime;

		this.promptLayer_request_id = promptLayer_request_id;
		this.promptLayerUrl = `https://promptlayer.com/workspace/8301/request/${promptLayer_request_id}`;

		this.metadata = metadata;

		//  console.log(`finishGeneration functionOutput ${JSON.stringify(functionOutput)} this ${JSON.stringify(this)}`)
	}

	/**
	 * Static function to convert an object to an instance of AvatarGptData.
	 * @param {Object} data - The data object.
	 * @return {AvatarGptData} The instance of AvatarGptData.
	 */
	static fromObject(data) {
		const avatarGptData = new AvatarGptData(data.transcriptId);
		Object.assign(avatarGptData, data);
		if (data.startTime) avatarGptData.startTime = new Date(data.startTime);
		if (data.endTime) avatarGptData.endTime = new Date(data.endTime);
		if (!data.duration) avatarGptData.duration = avatarGptData.endTime - avatarGptData.startTime;
		return avatarGptData;
	}
}

export default AvatarGptData;
