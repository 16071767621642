import React, { useContext, useState, useEffect } from "react";
import { IoClose } from "react-icons/io5/index.esm.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import s from "../../styleModules/modalStyles.module.css";

export function PresentationPopUp({ show, onHide, setPresentationFinished }) {
	const { showSidebar } = useContext(AppContext);
	const [endingTimer, setEndingTimer] = useState(15);

	useEffect(() => {
		if (show) {
			const timer = setInterval(() => {
				setEndingTimer((endingTimer) => {
					if (endingTimer === 1) {
						clearInterval(timer);
						console.log("auto end sim");
						setPresentationFinished(true);
						onHide();
					}
					return endingTimer - 1;
				});
			}, 1000);
			return () => clearInterval(timer);
		}
	}, [show]);

	return (
		<>
			{show && (
				<div
					className={`${s.presentationModalContainer} ${show ? s.show : s.hide}`}
					style={{ right: window.innerWidth > 768 ? (!showSidebar ? "90px " : "25%") : "2%" }}
				>
					<div className={s.presentationModal}>
						<span className={s.modalCloseIcon}>
							<IoClose
								title="Close icon"
								onClick={() => {
									setEndingTimer(15);
									onHide();
								}}
							/>
						</span>
						<p className={s.presentationModalTitle} style={{ textAlign: "center" }}>
							Are you finished your presentation?
						</p>
						<div className={s.modalBtnContainer} style={{ margin: "1rem 0 0" }}>
							<button
								className={s.modalCancelBtn}
								onClick={() => {
									setEndingTimer(15);
									onHide();
								}}
							>
								No
							</button>
							<button
								className={s.modalConfirmBtn}
								onClick={() => {
									onHide();
									setPresentationFinished(true);
								}}
							>
								Yes
							</button>
						</div>
						<p
							className={s.modalText}
							style={{
								margin: "1rem 0 0",
								textAlign: "center"
							}}
						>
							Automatically ending in{" "}
							<span style={{ fontFamily: "BoldFont", color: "#D92D20", marginLeft: "0.25rem" }}>{endingTimer}</span>
							...
						</p>
					</div>
				</div>
			)}
		</>
	);
}
