import React, { useContext } from "react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi/index.esm.js";
import styles from "../../styleModules/generateStyles.module.css";
import { usePostHog } from "posthog-js/react";

export default function ThumbButton({ type, docType, isClicked, onClick }) {
	const { localUser } = useContext(AppContext);
	const posthog = usePostHog();

	return (
		<div className={styles.tooltipContainer}>
			{type === "up" ? (
				<FiThumbsUp
					className={`${styles.thumbIcon} ${styles.thumbsUp} ${isClicked ? styles.thumbsUpFixed : ""}`}
					onClick={(e) => {
						try {
							posthog?.capture(`instage_${docType}_thumbs_up`, { instage_id: localUser.id });
						} catch (error) {
							console.error("Posthog error:", error);
						}
						onClick(e);
					}}
				/>
			) : (
				<FiThumbsDown
					className={`${styles.thumbIcon} ${styles.thumbsDown} ${isClicked ? styles.thumbsDownFixed : ""}`}
					onClick={(e) => {
						try {
							posthog?.capture(`instage_${docType}_thumbs_down`, { instage_id: localUser.id });
						} catch (error) {
							console.error("Posthog error:", error);
						}
						onClick(e);
					}}
				/>
			)}
			<span className={styles.tooltipText}>{type === "up" ? "Good feedback" : "Bad feedback"}</span>
		</div>
	);
}
