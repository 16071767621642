import React, { useState, useEffect, useRef } from "react";
import { RiDeleteBin5Line, RiAddLine } from "react-icons/ri/index.esm.js";

export function SelectWithAddOption() {
	const [options, setOptions] = useState(["Option 1", "Option 2", "Option 3"]);
	const [newOption, setNewOption] = useState("");

	const handleAddOption = () => {
		if (newOption.trim() !== "" && !options.includes(newOption)) {
			setOptions([...options, newOption]);
			setNewOption("");
		}
	};

	return (
		<div>
			<select>
				{options.map((option, index) => (
					<option key={index} value={option}>
						{option}
					</option>
				))}
			</select>
			<input type="text" value={newOption} onChange={(e) => setNewOption(e.target.value)} placeholder="Type new option" />
			<button type="button" onClick={handleAddOption}>
				Add Option
			</button>
		</div>
	);
}

export function ListWithAddOption() {
	const [options, setOptions] = useState(["Option 1", "Option 2", "Option 3"]);
	const [newOption, setNewOption] = useState("");

	const handleAddOption = () => {
		if (newOption.trim() !== "" && !options.includes(newOption)) {
			setOptions([...options, newOption]);
			setNewOption("");
		}
	};

	return (
		<div>
			<ul>
				{options.map((option, index) => (
					<li key={index}>{option}</li>
				))}
			</ul>
			<input type="text" value={newOption} onChange={(e) => setNewOption(e.target.value)} placeholder="Type new option" />
			<button type="button" onClick={handleAddOption}>
				Add Option
			</button>
		</div>
	);
}
export function ListWithInputOptions({ placeholder, initialData, onOptionsChange }) {
	const [options, setOptions] = useState(initialData ? [...initialData] : []);
	const [newOption, setNewOption] = useState("");
	const enterKeyPressed = useRef(false); // Add this line
	const [showDelete, setShowDelete] = useState("");

	useEffect(() => {
		// console.log(initialData, options);
		// Call the callback function whenever options change
		onOptionsChange(options);
	}, [options]);

	const handleOptionChange = (value, index) => {
		// const newOptions = [...options];
		// newOptions[index] = value;
		// setOptions(newOptions);
	};
	const handleDeleteOption = (index) => {
		const newOptions = options.filter((_, optionIndex) => optionIndex !== index);
		setOptions(newOptions);
	};
	const handleNewOptionChange = (e) => {
		setNewOption(e.target.value);
	};

	const handleNewOptionBlur = () => {
		if (newOption.trim() !== "" && !enterKeyPressed.current) {
			setOptions([...options, newOption]);
			setNewOption("");
		}
		enterKeyPressed.current = false; // Reset the flag
	};

	const handleNewOptionKeyDown = (e) => {
		if (e.key === "Enter") {
			enterKeyPressed.current = true; // Set the flag
			if (e.target.value.trim() !== "") {
				setOptions([...options, e.target.value]);
				setNewOption("");
			}
			e.preventDefault(); // Prevent the default action to avoid triggering onBlur
		}
	};
	const optionContainerStyle = {
		display: "flex",
		alignItems: "center"
	};

	const deleteButtonStyle = {
		borderRadius: "0.5rem",
		color: "#fff",
		background: "#00a9af",
		border: "none",
		cursor: "pointer",
		opacity: 0,
		transition: "opacity 0.2s ease-in-out"
	};

	return (
		<div>
			{/* {initialData[0].map((value, index) => {
				console.log(initialData, typeof initialData);
				return (
					<>
						{value} {index} {initialData.length} {typeof initialData}
						<br />
					</>
				);
			})} 
			<hr />
            */}
			{options.map((option, index) => (
				<div key={index} style={optionContainerStyle} onMouseEnter={() => setShowDelete(index)} onMouseLeave={() => setShowDelete(null)}>
					<input type="text" value={option} onChange={(e) => handleOptionChange(e.target.value, index)} />
					<button
						style={{ ...deleteButtonStyle, opacity: showDelete === index ? 1 : 0, background: "#db4f86" }}
						type="button"
						className="delete-button"
						onClick={() => handleDeleteOption(index)}
						aria-label="Delete option"
					>
						<RiDeleteBin5Line />
					</button>
				</div>
			))}
			<div>
				<input
					type="text"
					value={newOption}
					onKeyDown={handleNewOptionKeyDown}
					placeholder={placeholder}
					onChange={handleNewOptionChange}
					onBlur={handleNewOptionBlur}
				/>
				<button
					style={{ ...deleteButtonStyle, opacity: "1" }}
					type="button"
					className="delete-button"
					onClick={handleNewOptionBlur}
					aria-label="Delete option"
				>
					<RiAddLine />
				</button>
			</div>
		</div>
	);
}
