export const getSetupTourContent = (setupType, generateStarted) => {
	switch (setupType) {
		case "interview":
			if (!generateStarted) {
				return [
					{
						videoLink: "ProductTour_Interview_InterviewSetup_CustomInterview_comp2.mp4",
						title: "Custom Interview",
						description:
							"Copy & Paste a job title & description directly into InStage and generate a virtual interview simulation in seconds!",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Interview_SimSetup_ChoosingInterviewer.mp4",
						title: "Choose Interviewer",
						description: "Choose from our provided avatars and even assign them a personality type for more variety and practice",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Interview_SimSetup_AssignmentInstructions.mp4",
						title: "Assignment Instructions",
						description:
							'Press "click here" under the "Create assignment instructions" to create the instructions for others who will be using this simulation.',
						adminOrNot: "(Admin)"
					}
				];
			} else {
				return [
					{
						videoLink: "ProductTour_Interview_Overview_ReviewInfo.mp4",
						title: "Review Information",
						description:
							"Here you can review the generated information. Out of the box this will be good to go. However, if there are specific things you wish to include/exclude, you can edit the information here.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Interview_Overview_GenerateQuestions.mp4",
						title: "Generate Questions",
						description:
							"Questions will be randomly generated during the interview unless they are generated here beforehand. You can select generate which will auto populate the question list. You can edit the questions manually with the pencil icon and even change the order with the arrows.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Interview_Overview_ShareSimulation.mp4",
						title: "Share Simulation",
						description:
							"After creating your simulation, now is the time to share it if you would like others to use it. This is the link students will need if you are creating an assignment.",
						adminOrNot: "(Admin)"
					}
				];
			}
		case "discovery":
			if (!generateStarted) {
				return [
					{
						videoLink: "ProductTour_Sales_Setup_1.mp4",
						title: "Custom Sales Meeting",
						description:
							"Enter in your product and a description about it into InStage and generate a virtual sales simulation in seconds!",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Sales_Setup_2.mp4",
						title: "Choose Buyer",
						description: "Choose from our provided avatars and even assign them a personality type for more variety and practice",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Sales_Setup_3.mp4",
						title: "Assignment Instructions",
						description:
							'Press "click here" under the "Create assignment instructions" to create the instructions for others who will be using this simulation.',
						adminOrNot: "(Admin)"
					}
				];
			} else {
				return [
					{
						videoLink: "ProductTour_Sales_Overview_1.mp4",
						title: "Review Information",
						description:
							"Here you can review the generated information. Out of the box this will be good to go. However, if there are specific things you wish to include/exclude, you can edit the information here.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Sales_Overview_2.2.mp4",
						title: "Generate Questions",
						description:
							"Questions will be randomly generated during the meeting unless they are generated here beforehand. You can select generate which will auto populate the question list. You can edit the questions manually with the pencil icon and even change the order with the arrows.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Sales_Overview_3.mp4",
						title: "Share Simulation",
						description:
							"After creating your simulation, now is the time to share it if you would like others to use it. This is the link students will need if you are creating an assignment.",
						adminOrNot: "(Admin)"
					}
				];
			}
		case "presentation":
			if (!generateStarted) {
				return [
					{
						videoLink: "ProductTour_Presentation_Setup_1.mp4",
						title: "Custom Sales Meeting",
						description:
							"Enter in your presentation title and a description about it into InStage and generate a virtual presentation simulation in seconds!",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Presentation_Setup_2.mp4",
						title: "Choose Buyer",
						description: "Choose from our provided avatars and even assign them a personality type for more variety and practice",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Presentation_Setup_3.mp4",
						title: "Assignment Instructions",
						description:
							'Press "click here" under the "Create assignment instructions" to create the instructions for others who will be using this simulation.',
						adminOrNot: "(Admin)"
					}
				];
			} else {
				return [
					{
						videoLink: "ProductTour_Presentation_Overview_1.mp4",
						title: "Review Information",
						description:
							"Here you can review the generated information. Out of the box this will be good to go. However, if there are specific things you wish to include/exclude, you can edit the information here.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Presentation_Overview_2.mp4",
						title: "Generate Questions",
						description:
							"Questions will be randomly generated during the simulation unless they are generated here beforehand. You can select 'generate' which will auto populate the question list. You can edit the questions manually with the pencil icon and even change the order with the arrows.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_Presentation_Overview_3.mp4",
						title: "Share Simulation",
						description:
							"After creating your simulation, now is the time to share it if you would like others to use it. This is the link students will need if you are creating an assignment.",
						adminOrNot: "(Admin)"
					}
				];
			}
		case "patient":
			if (!generateStarted) {
				return [
					// {
					// 	videoLink: "ProductTour_Presentation_Setup_1.mp4",
					// 	title: "Patient Simulation Overview",
					// 	description:
					// 		"The patient simulation can be used to practice speaking to patients at a hospital or clinic. You can practice and get familiar with what questions to ask and how to ask these sometimes difficult questions to help make a diagnosis or discover treatment methods for said patients.",
					// 	adminOrNot: "(Admin/Member)"
					// },
					// {
					// 	videoLink: "ProductTour_Presentation_Setup_2.mp4",
					// 	title: "Fill Out Information",
					// 	description:
					// 		"You can select your desired role, purpose of the patients visit, and even enter information about the patient and their medical history with symptoms & illnesses.",
					// 	adminOrNot: "(Admin/Member)"
					// },
					// {
					// 	videoLink: "ProductTour_Presentation_Setup_3.mp4",
					// 	title: "Generate",
					// 	description:
					// 		"Once you are satisfied with your sim information, you can go ahead and click on generate to go over the details before starting.",
					// 	adminOrNot: "(Admin)"
					// }
				];
			} else {
				return [
					// {
					// 	videoLink: "ProductTour_Presentation_Overview_1.mp4",
					// 	title: "Review Information",
					// 	description:
					// 		"Here you can review the generated information. Out of the box this will be good to go. However, if there are specific things you wish to include/exclude, you can edit the information here.",
					// 	adminOrNot: "(Admin/Member)"
					// },
					// {
					// 	videoLink: "ProductTour_Presentation_Overview_2.mp4",
					// 	title: "Generate Questions",
					// 	description:
					// 		"Questions will be randomly generated during the simulation unless they are generated here beforehand. You can select 'generate' which will auto populate the question list. You can edit the questions manually with the pencil icon and even change the order with the arrows.",
					// 	adminOrNot: "(Admin/Member)"
					// },
					// {
					// 	videoLink: "ProductTour_Presentation_Overview_3.mp4",
					// 	title: "Share Simulation",
					// 	description:
					// 		"After creating your simulation, now is the time to share it if you would like others to use it. This is the link students will need if you are creating an assignment.",
					// 	adminOrNot: "(Admin)"
					// }
				];
			}
		case "closing":
			if (!generateStarted) {
				return [
					{
						videoLink: "ProductTour_ClosingCustom_Pre1.mp4",
						title: "Closing Simulation Overview",
						description:
							"The Closing Call is to present your solution, addressing the previously expressed buyer needs. Your objective is to get agreement from your buyer to buy your solution. This simulation offers a safe practice environment for alleviating concern and ultimately getting the sale.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_ClosingCustom_Pre2.mp4",
						title: "Fill Out Information",
						description:
							"The first step is to enter in the details about your company and product. You will then need to provide the buyers needs and possible objections.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_ClosingCustom_Pre3.mp4",
						title: "Generate",
						description: "Once you are finished entering in the details, you can click on Generate to continue to the next step.",
						adminOrNot: "(Admin/member)"
					}
				];
			} else {
				return [
					{
						videoLink: "ProductTour_ClosingCustom_Post1.mp4",
						title: "Review Information",
						description:
							"Here you can review the generated information. Out of the box this will be good to go. However, if there are specific things you wish to include/exclude, you can edit the information here.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_ClosingCustom_Post2.mp4",
						title: "Select Avatar",
						description: "Now you can select an avatar and even choose a personality type for more variety when practicing.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_ClosingCustom_Post3.mp4",
						title: "Share Simulation",
						description:
							"After creating your simulation, you can either share it from the top right or start the simulation by clicking on the start button from the bottom right.",
						adminOrNot: "(Admin/Member)"
					}
				];
			}
		case "intro":
			if (!generateStarted) {
				return [
					{
						videoLink: "ProductTour_IntroCustom_Pre1.mp4",
						title: "Patient Simulation Overview",
						description:
							"Intro sims are used to practice self introductions for events, presentations, networking events, or any other situation that you feel like you need a good introduction.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_IntroCustom_Pre2.mp4",
						title: "Fill Out Information",
						description:
							"You start by entering in key information about where you are introducing yourself as well as who you are introducing yourself to and why.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_IntroCustom_Pre3.mp4",
						title: "Generate",
						description:
							"Once this is filled out, you can click on the generate button to bring you to an overview page before starting.",
						adminOrNot: "(Admin)"
					}
				];
			} else {
				return [
					{
						videoLink: "ProductTour_IntroCustom_Post1.mp4",
						title: "Review Information",
						description: "Now you can do a quick once over of the information and edit it if you need to.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_IntroCustom_Post2.mp4",
						title: "Avatar Selection",
						description: "You can now select an avatar and even give them a personality type to practice with.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_IntroCustom_Post3.mp4",
						title: "Question List",
						description:
							"At the bottom of the page is a question list that you can leave blank to generate live in the simulation or fill out before hand. You can also edit, rearrange, add, and delete questions. Once you are ready, you can click start.",
						adminOrNot: "(Admin)"
					}
				];
			}
		case "discovery_spin":
			if (!generateStarted) {
				return [
					{
						videoLink: "ProductTour_DiscoverySpin_Pre1.mp4",
						title: "Discovery Spin Overview",
						description:
							"The purpose of the Discovery spin is to delve into the situation and requirements of your buyer. It is not intended for selling or promoting your product or service.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_DiscoverySpin_Pre2.mp4",
						title: "Fill Out Information",
						description:
							"The first step is to enter the information about your product as well as the current situations of the buyers. For example, what is their need for the product.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_DiscoverySpin_Pre3.mp4",
						title: "Generate",
						description:
							"Once this is filled out, you can click on the generate button to bring you to an overview page before starting.",
						adminOrNot: "(Admin/ Member)"
					}
				];
			} else {
				return [
					{
						videoLink: "ProductTour_DiscoverySpin_Post1.mp4",
						title: "Review Information",
						description: "Look over the information to make sure it is accurate. You can always edit it from this page as well.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_DiscoverySpin_Post2.mp4",
						title: "Avatar Selection",
						description:
							"Select an avatar and a personality to go along with them. This avatar will be who you speak with during the simulation.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTour_DiscoverySpin_Post3.mp4",
						title: "Share Simulation & Start",
						description:
							"If you would like to share the simulation, you can do so from the top by clicking share and either copy the link or enter in the recipients email. If you would like to run the simulation, you can click on start at the bottom.",
						adminOrNot: "(Admin/Member)"
					}
				];
			}
		default:
			return [];
	}
};

export const getReportTourContent = (setupType) => {
	switch (setupType) {
		case "interview":
			return [
				{
					videoLink: "ProductTour_Interview_Report_MainInfo.mp4",
					title: "Overview",
					description:
						"InStage gives you feedback about what you did well as well as areas to improve. It also gives you a breakdown of each sections questions and responses. You will also be able to see useful metrics such as volume, pace, and use of filler words such as 'um' or 'um'. You can see where you are in comparison to the blue target zone.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_Interview_Report_Sharing.mp4",
					title: "Sharing Your Report",
					description:
						"If you need to share your report with an instructor or similar, you can click the share button in the top right and copy this link. If anyone opens it, they will be able to see your results.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_Interview_Report_AdminScores.mp4",
					title: "Instructor Grade Metrics",
					description:
						"If you are an instructor or similar, when you open a students report you will be able to see extra metrics such as an overall score as well as individual scores for each section.",
					adminOrNot: "(Admin)"
				}
			];
		case "discovery":
			return [
				{
					videoLink: "ProductTour_Presentation_Report_1.mp4",
					title: "Overview",
					description:
						"InStage gives you feedback about what you did well as well as areas to improve. It also gives you a breakdown of each sections questions and responses. You will also be able to see useful metrics such as volume, pace, and use of filler words such as 'um' or 'um'. You can see where you are in comparison to the blue target zone.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_Sales_Report_2.1.mp4",
					title: "Sharing Your Report",
					description:
						"If you need to share your report with an instructor or similar, you can click the share button in the top right and copy this link. If anyone opens it, they will be able to see your results.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_Sales_Report_3.1.mp4",
					title: "Instructor Grade Metrics",
					description:
						"If you are an instructor or similar, when you open a students report you will be able to see extra metrics such as an overall score as well as individual scores for each section.",
					adminOrNot: "(Admin)"
				}
			];
		case "presentation":
			return [
				{
					videoLink: "ProductTour_Presentation_Report_1.mp4",
					title: "Overview",
					description:
						"InStage gives you feedback about what you did well as well as areas to improve. It also gives you a breakdown of each sections questions and responses. You will also be able to see useful metrics such as volume, pace, and use of filler words such as 'um' or 'um'. You can see where you are in comparison to the blue target zone.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_Sales_Report_2.1.mp4",
					title: "Sharing Your Report",
					description:
						"If you need to share your report with an instructor or similar, you can click the share button in the top right and copy this link. If anyone opens it, they will be able to see your results.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_Sales_Report_3.1.mp4",
					title: "Instructor Grade Metrics",
					description:
						"If you are an instructor or similar, when you open a students report you will be able to see extra metrics such as an overall score as well as individual scores for each section.",
					adminOrNot: "(Admin)"
				}
			];
		case "closing":
			return [
				{
					videoLink: "ProductTour_ClosingCustom_Report1.mp4",
					title: "Report Overview",
					description:
						"InStage generates a report for you based on your performance. At the top, It has grade metrics that are only visible to instructors or admin accounts so if you are a student, you will not be able to see these grades.",
					adminOrNot: "(Admin)"
				},
				{
					videoLink: "ProductTour_ClosingCustom_Report2.mp4",
					title: "Feedback",
					description:
						"There is a detailed breakdown of your simulation that gives you individual feedback for each portion of the closing call. to see where you can improve on. Below this you will also be able to see useful metrics such as volume, pace, and use of filler words such as 'um' or 'um'. You can see where you are in comparison to the blue target zone.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_ClosingCustom_Report3.mp4",
					title: "Sharing Your Report",
					description:
						"If you need to share your report with an instructor or similar, you can click the share button in the top right and copy this link. If anyone opens it, they will be able to see your results.",
					adminOrNot: "(Admin/Member)"
				}
			];
		case "intro":
			return [
				{
					videoLink: "ProductTour_IntroCustom_Report1.mp4",
					title: "Overview",
					description:
						"Your report provides a comprehensive analysis of your performance, highlighting both your strengths and areas for improvement.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_IntroCustom_Report2.mp4",
					title: "Sharing Your Report",
					description:
						"Below this is a checklist of important things to keep in mind when doing an introduction. It offers feedback for each section of the introduction.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_IntroCustom_Report3.mp4",
					title: "Instructor Grade Metrics",
					description:
						"Below this is the communication section that gives you specific metrics about how you spoke. If you are submitting as an assignment or want to share your results, go ahead and click the share button and copy the link or send it to someone directly.",
					adminOrNot: "(Admin)"
				}
			];
		case "discovery_spin":
			return [
				{
					videoLink: "ProductTour_DiscoverySpin_Report1.mp4",
					title: "Overview",
					description:
						"InStage generates a report for you based on your performance. At the top, It has grade metrics that are only visible to instructors or admin accounts so if you are a student, you will not be able to see these grades.",
					adminOrNot: "(Admin)"
				},
				{
					videoLink: "ProductTour_DiscoverySpin_Report2.mp4",
					title: "Feedback",
					description:
						"There is a detailed breakdown of your simulation that gives you individual feedback for each portion of the simulation to see where you can improve on.",
					adminOrNot: "(Admin/Member)"
				},
				{
					videoLink: "ProductTour_DiscoverySpin_Report3.mp4",
					title: "Communication Section & Sharing",
					description:
						"Below this you will also be able to see useful metrics such as volume, pace, and use of filler words such as 'um' or 'um'. You can see where you are in comparison to the blue target zone. If you would like to share your report, you can do that by clicking on the share button in the top right and copying the link or by entering in the recipients email directly.",
					adminOrNot: "(Admin/Member)"
				}
			];
		default:
			return [];
	}
};

export const getAssistTourContent = (setupType, generated) => {
	switch (setupType) {
		case "resume":
			if (!generated) {
				return [
					{
						videoLink: "ProductTourResumeAssist_Pre1.mp4",
						title: "Resume Assist Overview",
						description:
							"InStage offers a resume assist feature that will let you know how well your resume fits with the job posting and how you can improve it.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTourResumeAssist_Pre2.mp4",
						title: "Upload Your Resume",
						description:
							"The first step is to upload your resume by dragging it into the box below or you can click on the space below and navigate to your resume file location and opening it.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTourResumeAssist_Pre3.mp4",
						title: "Review Breakdown",
						description:
							"Now you can review your resume in a detailed breakdown. You can click on confirm and then generate to bring you to the feedback and edit resume page.",
						adminOrNot: "(Admin/Member)"
					}
				];
			} else {
				return [
					{
						videoLink: "ProductTourResumeAssist_Post1.mp4",
						title: "Resume Assist Feedback",
						description:
							"This page shows you how well suited your resume/qualifications are by comparing the resume contents to the job posting. It will show you a compatibility match score and even feedback that you can use to improve it.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTourResumeAssist_Post2.mp4",
						title: "Resume Editor",
						description:
							"Below this is the editor, you can use AI to generate feedback and even rewrite a more compatible or professional version for each section of the resume.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTourResumeAssist_Post3.mp4",
						title: "Check Score",
						description:
							"After you have made the appropriate changes based on your feedback, you can go back to the top and click the regenerate button to see your new compatibility score.",
						adminOrNot: "(Admin/Member)"
					}
				];
			}

		default:
			if (!generated) {
				return [
					{
						videoLink: "ProductTourCoverLetterAssist_Pre1.mp4",
						title: "Cover Letter Assist Overview",
						description:
							"InStage offers a cover letter assist feature that will let you know how well your cover letter fits with the job posting and how you can improve it.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTourCoverLetterAssist_Pre2.mp4",
						title: "Sharing Your Report",
						description:
							"The first step is to upload your cover letter by dragging it into the box below or you can click on the space below and navigate to your cover letter file location and opening it.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTourCoverLetterAssist_Pre3.mp4",
						title: "Instructor Grade Metrics",
						description:
							"Now you can review your cover letter in a detailed breakdown. You can click on confirm and then generate to bring you to the feedback and edit cover letter page.",
						adminOrNot: "(Admin/Member)"
					}
				];
			} else {
				return [
					{
						videoLink: "ProductTourCoverLetterAssist_Post1.mp4",
						title: "Cover Letter Assist Feedback",
						description:
							"This page shows you how well suited your cover letter is by comparing it to the job posting and by checking if it follows general cover letter guidelines appropriately.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTourCoverLetterAssist_Post2.mp4",
						title: "Cover Letter Editor",
						description:
							"Below this, you will find the cover letter editor. This will allow you to use AI to rewrite areas of your cover letter that could use a rework. It will give specific feedback for each section.",
						adminOrNot: "(Admin/Member)"
					},
					{
						videoLink: "ProductTourCoverLetterAssist_Post3_v2.mp4",
						title: "Check Score",
						description:
							"You can now go back to the top and regenerate to see your score improve. You can repeat this process as many times as you would like until you have a score that you are satisfied with.",
						adminOrNot: "(Admin/Member)"
					}
				];
			}
	}
};
