// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes TypingText-module-blink {
	0%,
	100% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
}

.TypingText-module-blinking_caret {
	display: inline-block;
	width: 0.7em;
	height: 1em;
	background-color: currentColor;
	margin-left: 2px;
	animation: TypingText-module-blink 1s step-start infinite;
}
`, "",{"version":3,"sources":["webpack://./src/components/UtilityFunctions/TypingText.module.css"],"names":[],"mappings":"AAAA;CACC;;EAEC,UAAU;CACX;CACA;EACC,UAAU;CACX;AACD;;AAEA;CACC,qBAAqB;CACrB,YAAY;CACZ,WAAW;CACX,8BAA8B;CAC9B,gBAAgB;CAChB,yDAAuC;AACxC","sourcesContent":["@keyframes blink {\n\t0%,\n\t100% {\n\t\topacity: 1;\n\t}\n\t50% {\n\t\topacity: 0;\n\t}\n}\n\n.blinking_caret {\n\tdisplay: inline-block;\n\twidth: 0.7em;\n\theight: 1em;\n\tbackground-color: currentColor;\n\tmargin-left: 2px;\n\tanimation: blink 1s step-start infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blinking_caret": `TypingText-module-blinking_caret`,
	"blink": `TypingText-module-blink`
};
export default ___CSS_LOADER_EXPORT___;
