import React, { useState, useEffect, useRef, useContext, useMemo, forwardRef, useImperativeHandle } from "react";

import { FiCheckSquare, FiSquare, FiInfo } from "react-icons/fi/index.esm.js";
import { openDB } from "idb";
import s from "../../../styleModules/setupStyles.module.css";
import { GetScreen } from "./GetUserMedias.js";
import { AppContext } from "../../UtilityFunctions/AppContext.js";
import styles from "../../../styleModules/generateStyles.module.css";
import { useFeatureFlagEnabled } from "posthog-js/react";
import LanguageDict from "../LanguageDict.js";
import { getEdgeOffset } from "rsuite/esm/List/helper/utils.js";

const useOutsideClick = (ref, callback) => {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};

function ToggleIcon({ isActive, onToggle, className, ...rest }) {
	const Icon = isActive ? FiCheckSquare : FiSquare;
	return <Icon className={className} onClick={onToggle} {...rest} />;
}

async function initDB() {
	return openDB("simulation", 1, {
		upgrade(db) {
			db.createObjectStore("simulation_db");
		}
	});
}

export function StartButtonSection({
	setShowAssignmentModal,
	allowedToStart,
	showStartSimBtn,
	videoOn,
	setVideoOn,
	saveAudio,
	setSaveAudio,
	captureOn,
	setCaptureOn,
	audience,
	startSimHandler,
	screenShareRef
}) {
	const iconStyles = {
		backgroundColor: "unset",
		boxShadow: "unset",
		width: "35px",
		height: "35px",
		borderRadius: "unset",
		color: "grey"
	};
	const { activeSub, userLanguage, translateSingleText, translateDictText, performTranslation } = useContext(AppContext);

	const startSessionFlag = useFeatureFlagEnabled("start-session-flag");
	const [buttonPressed, setButtonPressed] = useState(false);

	const LocalLanguageDict = {
		Start: "START",
		Generating: "Generating",
		VideosWillBeDeleted: "Videos will be automatically deleted after 90 days",
		StartWithVideo: "Start With Video",
		RecordVideo: "Record Video",
		StartSessionError: "Try again shortly"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	// Static Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage]);

	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const toggleVideo = async () => {
		const db = await initDB();
		await db.put("simulation_db", !videoOn, "videoAccess");
		setVideoOn((prevVideoOn) => {
			const newVideoOn = !prevVideoOn;
			if (!newVideoOn && captureOn) {
				setCaptureOn(false);
			}
			return newVideoOn;
		});
	};
	// const [language, setLanguage] = useState("en");

	// useEffect(() => {
	// 	if (userLanguage) {
	// 		setLanguage(userLanguage);
	// 	}
	// }, [userLanguage]);

	return (
		<div className={s.simLoadingContainer}>
			{!showError && (
				<>
					{/* <div className={s.startOptionContainer}>
						<p style={{ marginBottom: "0", color: "grey" }}>Start with video</p>
						<ToggleIcon
							isActive={videoOn}
							onToggle={toggleVideo}
							className={s.simControlBtn}
							style={iconStyles}
						/>
					</div>
					<div className={s.startOptionContainer}>
						<p style={{ marginBottom: "0", color: "grey" }}>Record video</p>
						<ToggleIcon
							isActive={captureOn}
							onToggle={() => {
								setCaptureOn(!captureOn);
								if (captureOn) {
									if (screenShareRef.current) {
										screenShareRef.current.getTracks().forEach(track => track.stop());
										screenShareRef.current.srcObject = null;
									}
								} else {
									GetScreen().then(stream => {
										if (screenShareRef) {
											screenShareRef.current = stream;
										}
									});
								}
							}}
							className={s.simControlBtn}
							style={iconStyles}
						/>
					</div>
					<div className={s.startOptionContainer}>
						<p style={{ marginBottom: "0", color: "grey" }}>Save audio files</p>
						<ToggleIcon
							isActive={saveAudio}
							onToggle={() => setSaveAudio(!saveAudio)}
							className={s.simControlBtn}
							style={iconStyles}
						/>
					</div> */}
					<div className={s.startOptionContainer}>
						<input type="checkbox" id="startVideo" checked={videoOn} onChange={toggleVideo} />
						<label htmlFor="startVideo" style={{ marginBottom: "0", color: "grey" }}>
							{pageText.StartWithVideo}
						</label>
					</div>
					{!window.location.pathname.includes("preview") && (
						<div className={s.startOptionContainer}>
							<div className={styles.tooltipContainer}>
								<input
									type="checkbox"
									id="recordVideo"
									checked={captureOn}
									onChange={() => {
										// console.log("Capture On... ", captureOn);
										setCaptureOn(!captureOn);
										if (captureOn) {
											// if (screenShareRef.current) {
											// 	screenShareRef.current.getTracks().forEach(track => track.stop());
											// 	screenShareRef.current.srcObject = null;
											// }
										} else {
											// GetScreen().then(stream => {
											// 	if (screenShareRef) {
											// 		screenShareRef.current = stream;
											// 	}
											// });
										}
									}}
									disabled={!videoOn}
									style={videoOn ? {} : { cursor: "not-allowed", opacity: 0.6 }}
								/>

								<label
									htmlFor="recordVideo"
									style={{ marginBottom: "0", color: "grey", cursor: videoOn ? "pointer" : "not-allowed" }}
								>
									{pageText.RecordVideo}
								</label>

								<FiInfo className={styles.infoIconHeading} />
								<span className={styles.tooltipText}>{pageText.VideosWillBeDeleted}</span>
							</div>
						</div>
					)}
					{/* <div className={s.startOptionContainer}>
						<input type="checkbox" id="startAudio" checked={saveAudio} onChange={() => setSaveAudio(!saveAudio)} />
						<label htmlFor="startAudio" style={{ marginBottom: "0", color: "grey" }}>Save audio files</label>
					</div> */}
				</>
			)}
			{showError && (
				<p className={s.error} style={{ margin: "0" }}>
					{errorMessage}
				</p>
			)}
			{!showStartSimBtn && <button className={`${s.startBtn} ${s.generateLoading}`}>{pageText.Generating}...</button>}
			{showStartSimBtn && audience?.length > 0 && (
				<div style={{ display: "flex", flexDirection: "column", position: "relative", alignItems: "center" }}>
					{buttonPressed && startSessionFlag && (
						<p style={{ position: "absolute", top: "-25px", color: "gray" }}>{pageText.StartSessionError}</p>
					)}

					<button
						type="button"
						className={`${s.startBtn} ${!activeSub ? s.disabled : ""}`}
						onClick={async () => {
							setButtonPressed(true);
							if (startSessionFlag) {
								setTimeout(() => {
									setButtonPressed(false);
								}, 2000);
								return;
							}
							if (activeSub) {
								startSimHandler();
							}
							// else if (activeSub && !allowedToStart) {
							// 	setShowError(true);
							// 	setErrorMessage("You must fill out required assignment details to start a simulation.");
							// 	await new Promise((resolve) => setTimeout(resolve, 2000));
							// 	setShowAssignmentModal(true);
							// }
							else {
								setShowError(true);
								setErrorMessage("You must have an active subscription to start a simulation.");
							}
						}}
					>
						{pageText.Start}
					</button>
				</div>
			)}
		</div>
	);
}
