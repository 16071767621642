import React, { useState, useEffect, useRef, useContext } from "react";
import Select from "react-select";

import { IoClose } from "react-icons/io5";
import s2 from "../../styleModules/modalStyles.module.css";
import ModalWrapper from "./ModalWrapper.js";

import { AppContext } from "../UtilityFunctions/AppContext.js";
import LanguageDropDownComponent from "./LanguageDropDownComponent.js";
import { RxQuestionMarkCircled } from "react-icons/rx";
import circles from "../../images/circles.png";

function LanguageSettingsModal({ pauseSimRef, setLanguageModalOpen }) {
	const { localUser, userLanguage, setUserLanguage, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		LanguageSelect: "Language Select"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	// Static Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage, localUser]);

	useEffect(() => {
		if (typeof pauseSimRef.current === "function") {
			pauseSimRef.current();
		}
		// Code to be executed when the component mounts
	}, [pauseSimRef]); // The empty array ensures this effect runs once on mount

	// Function to handle closing of the modal and cleanup
	const closeModal = () => {
		setLanguageModalOpen(false);
	};

	return (
		<ModalWrapper closeModal={() => closeModal()}>
			<div className={s2.modalAnimatedResize}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={s2.modalHeaderIconContainer}>
					<RxQuestionMarkCircled className={s2.modalHeaderIcon} />
				</div>
				<span className={s2.modalCloseIcon}>
					<IoClose title="Close icon" onClick={() => closeModal()} />
				</span>
				<h2 className={s2.modalSubtitle} style={{ margin: "0" }}>
					{pageText.LanguageSelect}
				</h2>
				<div className={s2.modalTextContainerUpper}>
					<LanguageDropDownComponent />
				</div>
			</div>
		</ModalWrapper>
	);
}

export default LanguageSettingsModal;
