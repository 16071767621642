// MultiSelect.js
import React, { useState, useRef, useEffect, useContext } from "react";
import { AppContext } from "../UtilityFunctions/AppContext";
import styles from "../../styleModules/generateStyles.module.css";

function MultiSelect({ competencies, selectedCompetencies, onCompetencyChange }) {
	const [isOpen, setIsOpen] = useState(false);
	const menuRef = useRef(null);

	const { setSetupData, userLanguage, translateDictText, performTranslation } = useContext(AppContext);
	const [selectedOption, setSelectedOption] = useState(null);

	// Static Translation
	const LocalLanguageDict = {
		SelectCompetencies: "Select Competencies",
		YouCanSelectMaximumOf5Competencies: "You can select a maximum of 5 competencies."
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	const [dynamicPageText, setDynamicPageText] = useState([]);
	// Dynamic Usage
	useEffect(() => {
		async function performTranslations() {
			if (!competencies || competencies.length === 0) {
				// console.error("sessionList is undefined or empty");
				return;
			}

			const propertiesToTranslate = ["label", "group", "description"];
			let updatedCompetencies = [...competencies];

			try {
				// eslint-disable-next-line no-restricted-syntax
				for (const property of propertiesToTranslate) {
					const texts = updatedCompetencies.map((item) => item[property]);
					const translatedTexts = await translateDictText(texts, userLanguage);
					updatedCompetencies = updatedCompetencies.map((item, index) => ({
						...item,
						[property]: translatedTexts[index] || item[property] // Use translated text if available, otherwise use original
					}));
				}
				setDynamicPageText(updatedCompetencies);
			} catch (error) {
				console.error("Error translating properties:", error);
			}
		}
		// console.log("Competencies:", competencies);
		performTranslations();
		// console.log("Translated Competencies:", dynamicPageText);
	}, [competencies, userLanguage, translateDictText]);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleOptionClick = (option) => {
		const currentIndex = selectedCompetencies.findIndex((selected) => selected.name === option.name);
		const newSelectedCompetencies = [...selectedCompetencies];

		if (currentIndex === -1) {
			if (newSelectedCompetencies.length < 5) {
				newSelectedCompetencies.push(option);
			} else {
				// Optionally, you can show an alert or message to the user
				alert(pageText.YouCanSelectMaximumOf5Competencies);
				return;
			}
		} else {
			newSelectedCompetencies.splice(currentIndex, 1);
		}

		onCompetencyChange(newSelectedCompetencies);
	};

	const handleClickOutside = (event) => {
		if (menuRef.current && !menuRef.current.contains(event.target)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const groupedCompetencies = dynamicPageText.reduce((groups, competency) => {
		const group = competency.group || "Other";
		if (!groups[group]) {
			groups[group] = [];
		}
		groups[group].push(competency);
		return groups;
	}, {});

	return (
		<div className={styles.multiSelect} ref={menuRef}>
			<div className={styles.selectedOptions} onClick={handleToggle}>
				{selectedCompetencies.length > 0 ? (
					selectedCompetencies.map((competency) => competency.label).join(", ")
				) : (
					<span className={styles.placeholder}>{pageText.SelectCompetencies}</span>
				)}
			</div>
			{isOpen && (
				<div className={styles.options}>
					{Object.keys(groupedCompetencies).map((group) => (
						<div key={group}>
							<h4 className={styles.optionGroupHeading}>{group}</h4>
							<ul>
								{groupedCompetencies[group].map((competency) => (
									<li key={competency.label} className={styles.option} onClick={() => handleOptionClick(competency)}>
										<input
											type="checkbox"
											checked={selectedCompetencies.some((selected) => selected.label === competency.label)}
											readOnly
										/>
										<span>{competency.label}</span>
									</li>
								))}
							</ul>
						</div>
					))}
				</div>
			)}
		</div>
	);
}

export default MultiSelect;
