import React from "react";

function Survey() {
	let surveyLink = "";

	if (window.location.href.includes("member")) {
		surveyLink = "https://airtable.com/embed/appLohFHY5J1k7yUp/pagVTtDIf1sOE6Gvt/form";
	} else if (window.location.href.includes("admin")) {
		surveyLink = "https://airtable.com/embed/appLohFHY5J1k7yUp/pagRIMdO6oebIYlLZ/form";
	}

	return (
		<iframe
			className="airtable-embed"
			src={surveyLink}
			frameBorder="0"
			onMouseWheel=""
			width="100%"
			height="100% - 200px"
			style={{
				background: "transparent",
				border: "1px solid #ccc",
				position: "absolute",
				top: "60px",
				left: "0"
			}}
			title="Survey"
		/>
	);
}

export default Survey;
