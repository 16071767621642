import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { openDB } from "idb";
import { Helmet } from "react-helmet";
import { FaLock } from "react-icons/fa/index.esm.js";
import { FiChevronRight, FiEdit, FiEdit2, FiInfo, FiRotateCcw, FiTrash2 } from "react-icons/fi/index.esm.js";
import { IoClose } from "react-icons/io5/index.esm.js";
import { PiSparkleBold } from "react-icons/pi";
import { RiListCheck } from "react-icons/ri/index.esm.js";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import s from "../../styleModules/setupStyles.module.css";
import styles2 from "../../styleModules/modalStyles.module.css";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import SimulationList from "../Admin/SimulationList.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import styles from "../../styleModules/generateStyles.module.css";
import a from "../../styleModules/adminSystemStyles.module.css";
import { ProductTourModal } from "./SetupElements/SetupModals.js";
import splashBG from "../../images/Backgrounds/splashBG.png";
import discoveryBackground from "../../images/Backgrounds/discoveryBackground.webp";
import decisionBackground from "../../images/Backgrounds/decisionBackground.webp";
import presentationBackground from "../../images/Backgrounds/presentationBackground.webp";
import reflectionBackground from "../../images/Backgrounds/reflectionBackground.webp";
import circles from "../../images/circles.png";
import usePageVisitTracker from "../SecondaryComponents/usePageVisitTracker";
import ModalWrapper from "../SecondaryComponents/ModalWrapper";
import OverLimitBanner from "../SecondaryComponents/OverLimitBanner.js";

async function initDB() {
	const db = await openDB("visitDB", 1, {
		upgrade(db) {
			db.createObjectStore("visitCount");
			console.log(db); // print the database object to the console
		}
	});
	return db;
}

export default function Dashboard(props) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { admin } = props;
	const { showSimList } = props;

	const {
		localUser,
		tenantMetaData,
		showSidebar,
		setShowSidebar,
		SetupList,
		setSetupData,
		resumeAssistAccess,
		sessionList,
		setSessionList,
		runTranslation,
		translateDictText,
		userLanguage,
		performTranslation,
		reflectionAppAccess
	} = useContext(AppContext);

	const url = process.env.REACT_APP_BACKEND_STATIC_URL;
	const instage_id = localUser ? localUser.id : null;
	const match = useMatch("/pen");
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [ongoingSession, setOngoingSession] = useState(null);
	const [selectedSessionId, setSelectedSessionId] = useState(-1);
	const [sessionDisplayLimit, setSessionDisplayLimit] = useState(4);
	const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);
	const [showInfo_instructions, setShowInfo_instructions] = useState(false);
	const [showPTModal, setShowPTModal] = useState(false);
	// const [isFirstLogin, hasShownFirstTimePopup] = useState(false);
	// const [hasClickedOffModal, setHasClickedOffModal] = useState(false);
	const refClickOffModal = useRef();

	const interviewSimAccess = useFeatureFlagEnabled("interview_sim_access");
	const salesSimAccess = useFeatureFlagEnabled("sales_sim_access");
	const presentationSimAccess = useFeatureFlagEnabled("presentation_sim_access");

	// Static Translation
	const LocalLanguageDict = {
		YourSessions: "Your Recent Sessions",
		Created: "Created",
		ShowMore: "Show More",
		ShowLess: "Show Less",
		GenerateNewSession: "Generate New Session",
		Start: "Start",
		JobPrep: "Job Prep",
		B2BSales: "B2B Sales",
		Presentation: "Presentations",
		Reflection: "Reflections",
		CardTextInterviewResumeAssist: "Practice interviewing for any job or fine tune your resume & cover letter.",
		CardTextInterviewNoResumeAssist: "Practice interviewing for any job",
		CardTextDiscovery: "Conduct a discovery meeting",
		CardTextPresentation: "Practice with a partner",
		CardTextReflection: "Engage in guided reflections with a facilitator",
		InterviewListOption1: "Mock Interviews",
		InterviewListOption2: "Resume Assist",
		InterviewListOption3: "Cover Letter Assist",
		PresentationListOption1: "General Presentation",
		PresentationListOption2: "Self-Introduction",
		PresentationListOption3: "Elevator/Business Pitch",
		DeleteSession: "Delete Session",
		AreYouSureYouWantToDeleteThisSession: "Are you sure you want to delete this session? This action cannot be undone.",
		Cancel: "Cancel",
		Delete: "Delete",
		ThisIsTheMainPageWhereYouCanCreateCustomSimulationsAndShareThemForOthersToTry:
			"This is the main page where you can create custom simulations and share them for others to try.",
		WelcomeToInStage: "Welcome To InStage",
		PickASimulation: "Pick A Simulation",
		ChooseFromOneOfOurSimulationTypesToGetStartedDevelopingYourInterview:
			"Choose from one of our simulation types to get started developing your interview, sales, and presentation skills.",
		NeedMoreHelp: "Need More Help?",
		CheckOutOurHelpCenterAtTheBottomRightCornerOfTheScreenToViewOurHelpDocsOrSpeakWithOneOfOurTeamMembersDirectly:
			"Check out our help center at the bottom right corner of the screen to view our help docs or speak with one of our team members directly!"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	// Dynamic Translation
	const [dynamicPageText, setDynamicPageText] = useState([]);
	// Dynamic Usage
	useEffect(() => {
		async function performTranslations() {
			if (!sessionList || sessionList.length === 0) {
				// console.error("sessionList is undefined or empty");
				return;
			}

			const propertiesToTranslate = ["setup_name", "setup_type"];
			let updatedSessionList = [...sessionList];

			try {
				// eslint-disable-next-line no-restricted-syntax
				for (const property of propertiesToTranslate) {
					const texts = updatedSessionList.map((item) => item[property]);
					const translatedTexts = await translateDictText(texts, userLanguage);
					updatedSessionList = updatedSessionList.map((item, index) => ({
						...item,
						[property]: translatedTexts[index] || item[property] // Use translated text if available, otherwise use original
					}));
				}
				setDynamicPageText(updatedSessionList);
			} catch (error) {
				console.error("Error translating properties:", error);
			}
		}

		performTranslations();
	}, [sessionList, userLanguage, translateDictText]);

	// useEffect(() => {
	//     async function checkFirstLogin() {
	//         const db = await initDB();
	//         const firstLoginStatus = await db.get("visitCount", "isFirstLogin");
	//         if (firstLoginStatus === undefined) {
	//             setShowInfo_instructions(true);
	//             setShowPTModal(true);
	//             hasShownFirstTimePopup(true);
	//             setHasClickedOffModal(false);
	//             const putResult = await db.put("visitCount", false, "isFirstLogin");
	//             console.log(putResult);
	//         } else {
	//             setShowInfo_instructions(false);
	//             setShowPTModal(false);
	//             hasShownFirstTimePopup(false);
	//             console.log("You have already logged in");
	//         }
	//     }
	//     checkFirstLogin();
	// }, []);

	const { checkPageVisit } = usePageVisitTracker();

	// useEffect(() => {
	// 	runTranslation();
	// });

	useEffect(() => {
		const checkVisit = async () => {
			const isFirstVisit = await checkPageVisit("dashboard");
			// console.log(`Is first visit: ${isFirstVisit}`); // Debugging
			// these are flipped
			if (isFirstVisit) {
				// console.log("Welcome back to this page");
			} else {
				// console.log("This is your first visit to this page.");
				setShowPTModal(true);
				setShowInfo_instructions(true);
				// if (window.Intercom) {
				// 	window.Intercom("show");
				// }
				// Logic for subsequent visits
			}
		};

		checkVisit();
	}, [checkPageVisit]);

	useEffect(() => {
		if (tenantMetaData !== undefined) {
			setIsLoading(false);
		}
	}, [tenantMetaData]);

	useEffect(() => {
		setShowSidebar(false);
		setSetupData(undefined);
	}, []);

	useEffect(() => {
		axiosLimitedGet(`${url}/api/sessionData/session/ongoing`, 1, { params: { instage_id } }).then((res) => {
			setOngoingSession(res.data);
		});
	}, []);

	const handleStart = (setupType) => {
		navigate(`/setup/${setupType}`);
	};

	const isLargeScreen = window.innerWidth > 768;

	const accessibleSetups = SetupList.filter((sim) => {
		if (tenantMetaData?.setup_type_list) {
			return tenantMetaData.setup_type_list.includes(sim.setup_type.split("_")[0]);
		}
		return true;
	});

	const inaccessibleSetups = SetupList.filter((sim) => !accessibleSetups.includes(sim));

	const sortedSetups = [...accessibleSetups, ...inaccessibleSetups];
	const backgrounds = {
		interview: splashBG,
		discovery: discoveryBackground,
		discovery_spin: discoveryBackground,
		closing: decisionBackground,
		retail: discoveryBackground,
		presentation: presentationBackground,
		intro: splashBG,
		freestyle: splashBG,
		patient: splashBG
	};
	const ongoingTitles = {
		interview: "Job Interview",
		discovery: "Discovery Meeting",
		discovery_spin: "Discovery Meeting",
		closing: "Closing Meeting",
		retail: "Retail Meeting",
		presentation: "Presentation",
		intro: "Self-Introduction",
		freestyle: "Freestyle",
		patient: "Patient Interview"
	};

	const ongoingSetupId = {
		interview: "interview",
		discovery: "discovery",
		discovery_spin: "discovery_spin",
		closing: "closing",
		retail: "retail",
		presentation: "presentation",
		intro: "intro",
		freestyle: "freestyle",
		patient: "patient"
	};

	const cardCopy = {
		interview: {
			title: pageText.JobPrep,
			text: resumeAssistAccess ? `${pageText.CardTextInterviewResumeAssist}` : `${pageText.CardTextInterviewNoResumeAssist}`,
			list: resumeAssistAccess
				? [`${pageText.InterviewListOption1}`, `${pageText.InterviewListOption2}`, `${pageText.InterviewListOption3}`]
				: "",
			access: interviewSimAccess
		},
		discovery: {
			title: pageText.B2BSales,
			text: `${pageText.CardTextDiscovery}`,
			access: salesSimAccess
		},
		discovery_spin: {
			title: pageText.B2BSales,
			text: `${pageText.CardTextDiscovery}`,
			access: salesSimAccess
		},
		presentation: {
			title: pageText.Presentation,
			text: `${pageText.CardTextPresentation}`,
			list: [`${pageText.PresentationListOption1}`, `${pageText.PresentationListOption2}`, `${pageText.PresentationListOption3}`],
			access: presentationSimAccess
		},
		reflection: {
			title: pageText.Reflection,
			text: pageText.CardTextReflection,
			access: reflectionAppAccess
		}
	};

	const fetchSessionList = async () => {
		// console.log("fetchSessionList navHeader", localUser);
		const result = await axiosLimitedGet(`${url}/api/saved/simulation/list`, 1, { params: { instage_id } });
		// console.log(result.data);

		// order the data where global is set to true first
		result.data.sort((a, b) => (a.global > b.global ? -1 : 1));
		setSessionList(result.data);
	};

	const deleteSession = async (simulation_id) => {
		try {
			setSessionList();
			await axiosLimitedDelete(`${url}/api/saved/simulation`, 1, { params: { simulation_id } });
			fetchSessionList();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<Helmet>
				<title>InStage | Dashboard</title>
				<meta name="description" content="Develop skills at scale with AI-powered Conversational Simulations." />
				<meta name="robots" content="noindex" />
			</Helmet>

			{showDeleteSessionModal && (
				<ModalWrapper closeModal={() => setShowDeleteSessionModal(false)}>
					<div className={styles2.modal}>
						<img src={circles} alt="Circles" className={styles2.modalCircles} />
						<div className={`${styles2.modalHeaderIconContainer} ${styles2.modalWarningIconContainer}`}>
							<FiTrash2 className={styles2.modalHeaderIcon} />
						</div>
						<IoClose className={styles2.modalCloseIcon} title="Close icon" onClick={() => setShowDeleteSessionModal(false)} />
						<div className={styles2.modalTextContainer}>
							<h2 className={styles2.modalTitle}>{pageText.DeleteSession}</h2>
							<p className={styles2.modalText}>{pageText.AreYouSureYouWantToDeleteThisSession}</p>
						</div>
						<div className={styles2.modalBtnContainer}>
							<button type="button" className={styles2.modalCancelBtn} onClick={() => setShowDeleteSessionModal(false)}>
								{pageText.Cancel}
							</button>
							<button
								type="button"
								className={styles2.modalWarningBtn}
								disabled={false}
								onClick={() => {
									deleteSession(selectedSessionId);
									setShowDeleteSessionModal(false);
								}}
							>
								{pageText.Delete}
							</button>
						</div>
					</div>
				</ModalWrapper>
			)}

			<div className={!admin ? a.adminPage : ""}>
				<div className={a.adminPageContainer}>
					<h1 className={s.dashboardHeading}>
						<PiSparkleBold />
						{pageText.GenerateNewSession}
						<span className={styles.infoIconContainer2}>
							<span
								className={styles.infoIconHeading}
								onClick={(event) => {
									event.stopPropagation();
									setShowInfo_instructions(true);
									setShowPTModal(true);
								}}
							>
								<RxQuestionMarkCircled />
							</span>
							{showInfo_instructions && (
								<span
									ref={refClickOffModal}
									onClick={(event) => {
										event.stopPropagation();
									}}
								>
									{showPTModal && (
										<ProductTourModal
											setShowPTModal={setShowPTModal}
											content={[
												{
													videoLink: "ProductTour_MainPage_Intro.mp4",
													title: pageText.WelcomeToInStage,
													description:
														pageText.ThisIsTheMainPageWhereYouCanCreateCustomSimulationsAndShareThemForOthersToTry,
													adminOrNot: "(Admin/Member)"
												},
												{
													videoLink: "ProductTour_MainPage_SimTypes.mp4",
													title: pageText.PickASimulation,
													description: pageText.ChooseFromOneOfOurSimulationTypesToGetStartedDevelopingYourInterview,
													adminOrNot: "(Admin/Member)"
												},
												{
													videoLink: "ProductTour_MainPage_MoreHelp.mp4",
													title: pageText.NeedMoreHelp,
													description:
														pageText.CheckOutOurHelpCenterAtTheBottomRightCornerOfTheScreenToViewOurHelpDocsOrSpeakWithOneOfOurTeamMembersDirectly,
													adminOrNot: "(Admin/Member)"
												}
											]}
										/>
									)}
									{/* <span>
										<span
											className={styles.learnMore}
											onClick={() => {
												window.Intercom("showArticle", dynamicData[setupData.setup_type].intercomArticle);
												setShowInfo_instructions(false);
											}}
										>
											Learn more
										</span>{" "}
										about this simulation.
									</span> */}
								</span>
							)}
						</span>
					</h1>

					<div className={s.dashboardCardContainer}>
						{isLoading && (
							<div className={s.spinnerContainerStart}>
								<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
							</div>
						)}
						{!isLoading && (
							<>
								{[...sortedSetups, ...(reflectionAppAccess ? [{ setup_type: "reflection", dashboard: true }] : [])]
									.filter((sim) => cardCopy[sim?.setup_type]?.access === true)
									.map((sim, index) => {
										const access = accessibleSetups.includes(sim) || sim.setup_type === "reflection";
										// console.log("cardCopy[sim.setup_type]", cardCopy[sim.setup_type]);
										if (sim.dashboard) {
											return (
												<div
													key={index}
													className={s.dashboardCard}
													style={{
														color: !access ? "#bfbfbf" : "inherit"
														// backgroundColor: !access ? "#49506F" : "#455084",
													}}
												>
													<img
														src={sim.setup_type === "reflection" ? reflectionBackground : sim.setup_background}
														className={s.dashboardCardImg}
														alt=""
													/>
													<div className={s.dashboardTitleContainer}>
														<p className={s.dashboardCardTitle}>{cardCopy[sim.setup_type].title}</p>
													</div>
													<p className={s.dashboardCardText} style={{ height: resumeAssistAccess ? "2.9rem" : "initial" }}>
														{cardCopy[sim.setup_type].text}
													</p>
													{cardCopy[sim.setup_type].list && (
														<ul className={s.dashboardCardList}>
															{cardCopy[sim.setup_type].list.map((item, listIndex) => (
																<li key={listIndex} className={s.dashboardCardListItem}>
																	{item}
																</li>
															))}
														</ul>
													)}
													<div className={s.dashboardCardBtnContainer}>
														{!access && (
															<button
																type="button"
																className={`${s.dashboardTableBtnStart} ${s.locked}`}
																onClick={() => {
																	if (window.Intercom) {
																		window.Intercom("showNewMessage");
																	}
																}}
															>
																Get Access
																<FaLock className={s.dashboardTableBtnStartIcon} />
															</button>
														)}
														{access && (
															<button
																type="button"
																className={s.dashboardTableBtnStart}
																onClick={() =>
																	sim.setup_type === "reflection"
																		? (window.location.href = process.env.REACT_APP_REFLECTION_APP_URL)
																		: handleStart(sim.setup_type)
																}
															>
																{pageText.Start}
															</button>
														)}
													</div>
												</div>
											);
										}
										return null;
									})}
							</>
						)}
					</div>

					{!sessionList && (
						<div className={s.spinnerContainerStart} style={{ marginTop: "3rem" }}>
							<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
						</div>
					)}
					{sessionList && sessionList.length > 0 && (
						<>
							<h1 className={s.dashboardHeading} style={{ marginTop: "3rem" }}>
								<RiListCheck />
								{pageText.YourSessions}
							</h1>
							<div className={s.dashboardCardContainer}>
								{dynamicPageText.slice(0, sessionDisplayLimit).map((session, index) => {
									let typeValue = session.setup_type;
									if (session.setup_type === "interview" && resumeAssistAccess) {
										typeValue = "Job Prep";
									}
									const sessionUrl = `/setup/${sessionList[index].setup_type}`;
									return (
										<button
											key={index}
											className={s.dashboardSessionCard}
											onClick={() => {
												navigate(`${sessionUrl}/${session.simulation_id}`);
											}}
											onKeyDown={(event) => {
												if (event.key === "Enter" || event.key === " ") {
													navigate(`${sessionUrl}/${session.simulation_id}`);
												}
											}}
											type="button"
											tabIndex={0}
										>
											<div className={s.dashboardSessionCardTextContainer}>
												<p className={s.dashboardSessionCardTitle}>{session.setup_name}</p>
												<p className={s.dashboardSessionCardText} style={{ color: "#00a9af" }}>
													{session.assignment_name}
												</p>
												<p className={s.dashboardSessionCardText}>{typeValue.charAt(0).toUpperCase() + typeValue.slice(1)}</p>
												<p className={s.dashboardSessionCardText}>
													{pageText.Created}{" "}
													{new Date(session.created_date).toLocaleDateString(`${userLanguage}-US`, {
														year: "numeric",
														month: "long",
														day: "numeric"
													})}
												</p>
											</div>
											<div
												className={s.dashboardSessionCardIconContainer}
												onClick={(e) => {
													e.stopPropagation();
													setSelectedSessionId(session.simulation_id);
													setShowDeleteSessionModal(true);
												}}
												onKeyDown={(event) => {
													if (event.key === "Enter" || event.key === " ") {
														event.stopPropagation();
														setSelectedSessionId(session.simulation_id);
														setShowDeleteSessionModal(true);
													}
												}}
												role="button"
												tabIndex={0}
												aria-label="Delete"
											>
												<FiTrash2 />
											</div>
										</button>
									);
								})}
							</div>
							<button
								type="button"
								className={s.showMoreButton}
								onClick={() => setSessionDisplayLimit(sessionDisplayLimit === 4 ? 12 : 4)}
							>
								{sessionDisplayLimit === 4 ? `${pageText.ShowMore}` : `${pageText.ShowLess}`}
								<FiChevronRight className={sessionDisplayLimit === 4 ? s.chevronRight : s.chevronUp} />
							</button>
						</>
					)}
				</div>
			</div>
			<OverLimitBanner />
		</>
	);
}
