export const learning_points_array = [
	{
		short: "Pain Points",
		checklist: [
			{
				item: "Current Challenges",
				details: "Learn about the challenges or problems they are currently facing that your solution could address.",
			},
			{
				item: "Desired Improvements",
				details: "Discover what improvements they are seeking in their current process or solution.",
			},
		],
		feedback: "",
		status: "",
	},
	{
		short: "Decision-Maker",
		checklist: [
			{
				item: "Decision-Making Authority",
				details: "Identify who has the final say in the purchase decision.",
			},
			{
				item: "Influencers",
				details: "Determine who else has influence over the decision, even if they don't make the final call.",
			},
		],
		feedback: "",
		status: "",
	},
	{
		short: "Solution Requirements",
		checklist: [
			{
				item: "Key Features",
				details: "Identify what features they consider most critical in the potential solution.",
			},
			{
				item: "Non-negotiables",
				details: "Find out any deal-breakers or must-haves that the solution should adhere to.",
			},
		],
		feedback: "",
		status: "",
	},
	{
		short: "Budget",
		checklist: [
			{
				item: "Budget Range",
				details: "Find out the financial range they are willing to invest in the solution.",
			},
			{
				item: "Budget Approval Process",
				details: "Understand the process of how the budget for the project gets approved.",
			},
		],
		feedback: "",
		status: "",
	},
];
