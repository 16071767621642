import React, { useState, useContext, useEffect } from "react";
import { IoClose } from "react-icons/io5/index.esm.js";
import styles from "../../../styleModules/modalStyles.module.css";
import ModalWrapper from "../../SecondaryComponents/ModalWrapper.js";
import { assignIndex } from "./assignIndex";
import { AppContext } from "../../UtilityFunctions/AppContext.js";

const Dropdown = React.memo(({ startingValue, options, onChange }) => {
	const [selectedValue, setSelectedValue] = useState(startingValue);

	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	const LocalLanguageDict = {
		Select: "Select...",
		Candel: "Cancel",
		Save: "Save"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	const handleChange = (event) => {
		setSelectedValue(event.target.value);

		// Call the onChange prop if it's provided
		if (onChange) {
			onChange(event.target.value);
		}
	};

	return (
		<div style={{ display: "flex", justifyContent: "flex-start" }}>
			<select className={styles.modalQuestionSelect} value={selectedValue} onChange={handleChange}>
				<option value="">{pageText.Select}</option>
				{options.map((option, index) => (
					<option key={index} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
});

export default function QuestionModal({ adminAccess, data, setData, index, setModalOpen, options, fixedOpenClose, evaluationFramework }) {
	const [dataTemp, setDataTemp] = useState([...data]);
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;
	const [localIndex, setLocalIndex] = useState(index);
	const [edited, setEdited] = useState(false);
	const [isQuestionValid, setIsQuestionValid] = useState(false);

	const { userLanguage, translateDictText, performTranslation, translateSingleText } = useContext(AppContext);

	const LocalLanguageDict = {
		QuestionList: "Question List",
		Add: "Add",
		Edit: "Edit",
		Question: "Question",
		QuestionType: "Question Type",
		QuestionText: "Question Text",
		QuestionTextDescription: "The question that will be asked",
		EvaluationFramework: "Evaluation Framework",
		EvaluationFrameworkDescription: "The criteria that will be used to evaluate the candidate's answer",
		Cancel: "Cancel",
		Save: "Save"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	const [translatedOptions, setTranslatedOptions] = useState(options);

	useEffect(() => {
		async function translateText() {
			const optionsTranslated = await Promise.all(
				options.map(async (option) => {
					const translatedText = await translateSingleText(option.framework, userLanguage);
					return { ...option, framework: translatedText };
				})
			);
			// console.log("options: ", options);
			// console.log("optionsTranslated: ", optionsTranslated);
			setTranslatedOptions(optionsTranslated);
		}

		if (!isTranslating) {
			translateText();
		}
	}, [userLanguage, pageText, options]);

	let question = dataTemp[localIndex];
	if (localIndex === -1) {
		question = { text: "" };
	}

	function saveToDb() {
		// Check if the question is valid before saving
		if (isQuestionValid) {
			const dataWithIndex = assignIndex(dataTemp);
			setData(dataWithIndex);
			setEdited(false);
		} else {
			// You can add an alert or a notification here to inform the user that the question is invalid
			alert("Please make sure to add a question type and question text before saving.");
		}
	}

	function handleModalChange(e, t_index) {
		setEdited(true);
		const tempArray = [...dataTemp];
		if (t_index === -1) {
			if (tempArray.length === data.length) {
				if (fixedOpenClose) {
					tempArray.splice(tempArray.length - 1, 0, {
						text: e.target.value
						// key: "#" + (tempArray.length + 1)
					});
					setDataTemp(tempArray);
					setLocalIndex(tempArray.length - 2);
				} else if (!fixedOpenClose) {
					tempArray.push({
						text: e.target.value
						// key: "#" + (tempArray.length + 1)
					});
					setDataTemp(tempArray);
					setLocalIndex(tempArray.length - 1);
				}
			}

			return;
		}
		tempArray[t_index] = { ...tempArray[t_index], text: e.target.value };
		setDataTemp(tempArray);

		// Check if the question type and question text are valid
		setIsQuestionValid(tempArray[t_index].type && tempArray[t_index].text);
	}

	function handleDropdownChange(value) {
		// console.log(value);
		// console.log(localIndex);

		setEdited(true);

		const tempArray = [...dataTemp];
		if (localIndex === -1) {
			if (tempArray.length === data.length) {
				if (fixedOpenClose) {
					tempArray.splice(tempArray.length - 1, 0, {
						text: "",
						type: value
						// key: tempArray.length + 1 + "."
					});
					setDataTemp(tempArray);
					setLocalIndex(tempArray.length - 2);
				} else if (!fixedOpenClose) {
					tempArray.push({
						text: "",
						type: value
						// key: tempArray.length + 1 + "."
					});
					setDataTemp(tempArray);
					setLocalIndex(tempArray.length - 1);
				}
			}
			// return;
		}
		tempArray[localIndex] = { ...tempArray[localIndex], type: value };
		setDataTemp(tempArray);
		console.log(tempArray[localIndex].type);
		console.log(tempArray[localIndex].type !== null && tempArray[localIndex].type !== undefined);
		// Check if the question type and question text are valid
		setIsQuestionValid(tempArray[localIndex].type !== null && tempArray[localIndex].type !== undefined); // && tempArray[localIndex].text.trim() !== ""
	}

	// if options does not contain a value === question.type, then add it to the options
	if (question.type && !options.find((option) => option.value === question.type)) {
		options.push({
			value: question.type,
			label: question.type,
			text: ""
		});
	}

	let title = `${index === -1 ? pageText.Add : pageText.Edit} ${pageText.Question}`;
	return (
		<ModalWrapper closeModal={() => setModalOpen(false)}>
			<div className={`${styles.modal} ${styles.modalMinWidth}`}>
				<IoClose className={styles.modalCloseIcon} title="Close icon" onClick={() => setModalOpen(false)} />
				<div className={styles.modalContent}>
					<h1 className={styles.modalTitle}>{title}</h1>

					<div>
						<p className={styles.modalInputLabel}>{pageText.QuestionType}</p>
						<Dropdown startingValue={question.type ? question.type : ""} options={options} onChange={handleDropdownChange} />
						{adminAccess && (
							<>
								<p className={styles.modalInputLabel}>{pageText.QuestionText}</p>
								<p className={styles.modalInputDescription}>{pageText.QuestionTextDescription}</p>
								<textarea
									className={styles.modalTextarea}
									type="text"
									rows={2}
									value={question.text}
									onChange={(e) => {
										handleModalChange(e, localIndex);
									}}
								/>
								{evaluationFramework && (
									<>
										<p className={styles.modalInputLabel}>{pageText.EvaluationFramework}</p>
										<p className={styles.modalInputDescription}>{pageText.EvaluationFrameworkDescription}</p>
										<textarea
											className={`${styles.modalTextarea} ${styles.modalTextareaDisabled}`}
											type="text"
											rows={4}
											value={
												userLanguage === "en"
													? options.find((option) => option.value === question.type)?.framework.join("\n")
													: translatedOptions.find((option) => option.value === question.type)?.framework
											} // .join("\n")
											readOnly
										/>
									</>
								)}
							</>
						)}
					</div>
				</div>

				<div className={styles.modalBtnContainer}>
					<button className={styles.modalCancelBtn} onClick={() => setModalOpen(false)}>
						{pageText.Cancel}
					</button>
					<button
						className={edited && isQuestionValid ? styles.modalConfirmBtn : styles.modalDisabledBtn}
						disabled={!edited || !isQuestionValid}
						onClick={() => {
							saveToDb();
							setModalOpen(false);
						}}
					>
						{pageText.Save}
					</button>
				</div>
			</div>
		</ModalWrapper>
	);
}
