export const process_preliminaries = {
	short: "Preliminaries",
	checklist: [
		{
			item: "Greeting - Name and Role",
			criteria: "Introduce oneself, stating name and role",
			examples: [
				"Hello, I'm “Sales Rep” and I'll be leading our meeting today.",
				"Good morning, I'm “Sales Rep”, I'm a business development manager at “Company”.",
				"Hi there, my name's “Sales Rep”, I'm your account Manager.",
			],
		},
		{
			item: "Rapport Building",
			criteria: "Make a personal connection or establish a positive tone",
			examples: [
				"I hope you're having a good day. How has your week been?",
				"It's great to see you again. How's everything with your team?",
				"Before we dive in, how was your weekend? I hope you had a chance to relax.",
			],
		},
		{
			item: "What Preparation",
			criteria: "Tell the customer how you prepared for the meeting",
			examples: [
				"I spent some time you reviewing your website",
				"I had did a bunch of research on your company and looking forward to digging in today",
				"In preparation for today's meeting I reviewed some of your recent projects and was most impressed",
			],
		},
		{
			item: "Time Check",
			criteria: "Ask if the time available to meet today is still okay",
			examples: [
				"I believe we had initially set aside about an hour for this meeting. Can you confirm that we're still on track or should we adjust?",
				"We had initially agreed to an hour for this meeting. Are we still aligned with that timeframe, or do you think we need to revisit our agenda?",
				"I'd like to ensure we're on the same page with respect to our time commitment. We've set aside an hour for this conversation. Is that still suitable, or do we need to make any adjustments?",
			],
		},
	],
	feedback: "",
	status: "",
};

export const process_opening = {
	short: "Opening",
	checklist: [
		{
			item: "State Agenda",
			criteria: "State the intended plan for the meeting",
			examples: [
				"Today, our goal is to understand your specific needs and challenges, so that we can develop a tailored proposal that best addresses them.",
				"In this meeting, I would like to learn more about your requirements, any existing issues you're facing, and your goals. This will allow us to create a proposal that is specifically suited to your needs.",
				"Our agenda for today is to gather as much information as possible about your needs and challenges. With this information, we'll be able to craft a personalized proposal that aims to meet your objectives.",
			],
		},
		{
			item: "Value Proposition",
			criteria: "Explain the benefits of the proposed plan or action",
			examples: [
				"I believe that by focusing on these areas, we can significantly improve our team's performance and productivity.",
				"If we follow through on these actions, I'm confident we'll see improvements in efficiency and customer satisfaction.",
				"By addressing these issues, we're not only solving immediate problems, but also positioning ourselves for success in future projects.",
			],
		},
		{
			item: "Check For Acceptance",
			criteria: "Confirm if the buyer is okay with the agenda",
			examples: [
				"Does that sound good to you?",
				"Do you think this approach will help us get a good grasp of your needs?",
				"Does that agenda sound okay?",
			],
		},
		{
			item: "Check For Customer's Agenda",
			criteria: "Ask if the customer or other parties have their own agenda or points to discuss",
			examples: [
				"Before we dive into our agenda, do you have any topics you'd like to discuss or points you want to ensure we cover?",
				"Do you have any specific issues or topics you'd like us to address during our meeting today?",
				"I want to ensure we cover any points important to you. Do you have your own items to add to our agenda?",
			],
		},
	],
	feedback: "",
	status: "",
};

export const process_closing = {
	short: "Closing",
	checklist: [
		{
			item: "Check For Any Other Issues or Questions",
			criteria: "Ask if there are any other issues or concerns to be addressed",
			examples: [
				"Before we wrap up, are there any other issues or concerns that you'd like to discuss?",
				"Is there anything else you'd like to address before we conclude our meeting?",
				"Do you have any questions?",
			],
		},
		{
			item: "Review Accepted Benefits",
			criteria: "Reiterate the benefits or positive outcomes that were agreed upon during the meeting",
			examples: [
				"To recap, we agreed that these actions will enhance our team's performance and productivity.",
				"Let's review the benefits we've discussed today: by following through on these actions, we'll see improvements in efficiency and customer satisfaction.",
				"As a summary, we're not just solving our immediate problems but also positioning ourselves for success in future projects.",
			],
		},
		{
			item: "Next Steps",
			criteria: "Discuss or propose the next steps",
			examples: [
				"What would you suggest as our next steps, or would you like me to propose some?",
				"So I guess the next steps from here are to take this information you've given me and put together a tailored proposal for you. How does that sound?",
				"So from here we can take these steps?",
			],
		},
		{
			item: "Check For Acceptance",
			criteria: "Confirm if the buyer agrees with the proposed next steps",
			examples: [
				"Do you agree with these next steps? Any concerns or suggestions?",
				"Are you comfortable with this plan for moving forward? Any objections or thoughts?",
				"How does all this sound? Are we good?",
			],
		},
		{
			item: "Thank the Buyer",
			criteria: "Thank the buyer",
			examples: [
				"Thank you for your time today. I look forward to our continued collaboration.",
				" Thanks for making time for this meeting.",
				"Okay thanks for your time, we'll be in touch.",
			],
		},
	],
	feedback: "",
	status: "",
};

export const process_SPIN = [
	{
		short: "Situation",
		checklist: [
			{
				item: "Background Information Gathering",
				criteria: "The sales rep should ask one or more open ended question about the buyer's current situation",
				examples: [
					"Could you please describe your current process for managing [relevant aspect, e.g., inventory, customer relationships, production]?",
					"What are some of the key objectives or goals for your department/team this year?",
					"How does your organization typically handle (relevant issue, e.g., product development, marketing campaigns, customer service)?",
				],
			},
			{
				item: "Relevance of Questions",
				criteria: "The sales rep should ask relevant situation based questions that should revolve around the context of this meeting",
				examples: [
					"Could you please describe your current process for managing [relevant aspect, e.g., inventory, customer relationships, production]?",
					"What are some of the key objectives or goals for your department/team this year?",
					"How does your organization typically handle [relevant issue, e.g., product development, marketing campaigns, customer service",
				],
			},
		],
		feedback: "",
		status: "",
	},
	{
		short: "Problem",
		checklist: [
			{
				item: "Identification of Problems",
				criteria: "The sales rep should identify potential problems, difficulties, or dissatisfactions in the buyer's current situation",
				examples: [
					"Have you encountered any bottlenecks in your current process?",
					"Are there any challenges your team is facing in managing projects?",
					"Do you face any issues with task allocation or tracking?",
				],
			},
			{
				item: "Tactfulness in Problem Questions",
				criteria: "The sales rep should ask problem questions in a tactful, non-confrontational manner",
				examples: [
					"How does your team handle any roadblocks that come up?",
					"What strategies are in place to manage any potential problems?",
					"Are there any areas you believe could use improvement?",
				],
			},
			{
				item: "Framing of Problem Questions",
				criteria: "The sales rep should build on the responses to situation questions to frame their problem questions",
				examples: [
					"Based on what you've shared about your workflow, where do you think the main challenges lie?",
					"Considering your team's size and the number of projects, how do you manage task allocation?",
					"From your description of the communication process, have you experienced any difficulties?",
				],
			},
		],
		feedback: "",
		status: "",
	},
	{
		short: "Implication",
		checklist: [
			{
				item: "Consequences and Implications",
				criteria: "The sales rep should help the buyer understand the consequences or implications of their identified problems",
				examples: [
					"If these bottlenecks continue, how might they impact your project timelines?",
					"What are the implications of these challenges on team productivity?",
					"If issues with task tracking persist, how could they affect project outcomes?",
				],
			},
			{
				item: "Framing of Implication Questions",
				criteria: "The sales rep should use the responses to problem questions to frame their implication questions",
				examples: [
					"Given the challenges you've mentioned, what effect do they have on your ability to deliver on time?",
					"Considering these problems, how might they affect your overall project management efficiency?",
					"Given these issues, what impact do they have on your team's productivity?",
				],
			},
			{
				item: "Urgency or Importance",
				criteria: "The sales rep should help the buyer understand the urgency or importance of addressing the problem",
				examples: [
					"How urgent is it for you to address these bottlenecks?",
					"What is the importance of resolving these challenges for your team's productivity?",
					"How crucial is it to improve task tracking for your project outcomes?",
				],
			},
		],
		feedback: "",
		status: "",
	},
	{
		short: "Need-Payoff",
		checklist: [
			{
				item: "Articulation of Benefits",
				criteria: "The sales rep should guide the buyer to articulate the benefits of solving their problems (i.e., the payoff)",
				examples: [
					"How would resolving these bottlenecks improve your project delivery?",
					"What would be the benefit of overcoming these challenges for your team's productivity?",
					"How would improving task tracking enhance your project outcomes?",
				],
			},
			{
				item: "Framing of Need-Payoff Questions",
				criteria: "The sales rep should use the responses to implication questions to frame their need-payoff questions",
				examples: [
					"If these bottlenecks were resolved, what improvements do you anticipate in your project delivery?",
					"By overcoming these challenges, how do you think your team's productivity would be affected?",
					"If task tracking were to be improved, how do you envision this impacting your project outcomes?",
				],
			},
			{
				item: "Value of Proposed Solution",
				criteria: "The sales rep should help the buyer see the value of the proposed solution",
				examples: [
					"Our solution could potentially address these bottlenecks, enhancing your project delivery. What are your thoughts?",
					"Our product is designed to tackle such challenges, potentially boosting your team's productivity. Would you find that valuable?",
					"The features of our tool could help improve task tracking, positively affecting your project outcomes. Does this align with your needs?",
				],
			},
		],
		feedback: "",
		status: "",
	},
];

export const process_BANT = [
	{
		short: "Budget",
		checklist: [
			{
				item: "Understanding Financial Capacity",
				criteria:
					"The sales rep should understand if the buyer has the necessary financial capacity or if they can secure the budget for the purchase",
				examples: [
					"Have you allocated a budget for this kind of purchase?",
					"Is there a specific price range you're considering for this solution?",
					"Can you give me an idea of your budget for this project?",
				],
			},
			{
				item: "Discussing Price Early",
				criteria: "The sales rep should discuss pricing early in the conversation to ensure alignment",
				examples: [
					"Our product typically falls within this price range, does that fit within your budget?",
					"To make sure we're on the same page, does our pricing align with your budget expectations?",
					"Based on your budget, we can recommend the best options for you. Can you share more about your budget considerations?",
				],
			},
		],
		feedback: "",
		status: "",
	},
	{
		short: "Authority",
		checklist: [
			{
				item: "Identifying Decision Makers",
				criteria:
					"The sales rep should identify if the buyer has the authority to make the purchase or if they can influence the decision-making process",
				examples: [
					"Are you the main decision-maker for this purchase?",
					"Can you tell me about the decision-making process in your organization?",
					"Who else would be involved in this decision-making process?",
				],
			},
			{
				item: "Understanding Influence on Decision",
				criteria: "The sales rep should understand the buyer's influence on the final purchasing decision",
				examples: [
					"Do you have a say in the final decision?",
					"How does your role fit into the decision-making process?",
					"Can you walk me through your process for evaluating and selecting a solution?",
				],
			},
		],
		feedback: "",
		status: "",
	},
	{
		short: "Need",
		checklist: [
			{
				item: "Identifying Needs or Pain Points",
				criteria: "The sales rep must determine if the buyer has a real need or pain point that the product or service can solve",
				examples: [
					"What challenges are you looking to address with our solution?",
					"Can you share more about the specific needs that led you to consider our product?",
					"What problem are you trying to solve with this purchase?",
				],
			},
			{
				item: "Aligning Product with Need",
				criteria: "The sales rep should illustrate how the product or service aligns with the buyer's needs",
				examples: [
					"Based on what you've shared, our product can help address these needs. What are your thoughts?",
					"Our solution has been effective in solving similar challenges for other clients. Would you like to know more?",
					"Considering your needs, I believe our product would be a good fit. Can we discuss this in more detail?",
				],
			},
		],
		feedback: "",
		status: "",
	},
	{
		short: "Timeline",
		checklist: [
			{
				item: "Understanding Purchase Timeline",
				criteria: "The sales rep should understand the buyer's timeline for making the purchase",
				examples: [
					"What is your timeline for implementing a new solution?",
					"Do you have a specific timeframe for this purchase?",
					"By when do you need to have this solution in place?",
				],
			},
			{
				item: "Discussing Follow-up Timing",
				criteria: "The sales rep should agree on a suitable follow-up timing based on the buyer's purchase timeline",
				examples: [
					"Given your timeline, I suggest we schedule a follow-up call. Does that work for you?",
					"Based on your timeframe, it might be best to continue this conversation at a later date. How does that sound?",
					"Considering your implementation date, let's discuss the next steps. Can we set a time for another call?",
				],
			},
		],
		feedback: "",
		status: "",
	},
];
