import { useContext } from "react";
import axios from "axios";
import { AppContext } from "./AppContext.js";

const dummyToken =
	btoa(JSON.stringify({ alg: "none", typ: "JWT" })) +
	"." +
	btoa(JSON.stringify({ user: "unauthenticated", exp: Date.now() + 10000 })) +
	"." +
	"dummySignature";

export const axiosRetryGet = async (url, retries = 2, config = {}) => {
	let attempts = 0;
	let key;
	if (window.location.href.indexOf("localhost") !== -1) {
		key = process.env.REACT_APP_BACKEND_KEY;
	}
	// console.log("axios.get", url);

	// config.headers = { Authorization: `Bearer ${key}` };
	while (attempts <= retries) {
		try {
			const response = await axios.get(url, config);
			return response;
		} catch (error) {
			attempts++;
			if (attempts > retries) {
				throw error;
			}
			console.log(`Error occurred, retrying (${attempts}/${retries})...`);
			console.error(error);
		}
	}
};

export const axiosRetryPost = async (url, data, retries = 2, config = {}, timeout = 10000) => {
	let attempts = 0;
	let key;
	if (window.location.href.indexOf("localhost") !== -1) {
		key = process.env.REACT_APP_BACKEND_KEY;
	}
	// console.post("axios.get", url);

	// config.headers = { Authorization: `Bearer ${key}` };
	while (attempts <= retries) {
		try {
			const response = await axios.post(url, data, { ...config, timeout });
			return response;
		} catch (error) {
			attempts++;
			if (attempts > retries) {
				throw error;
			}
			console.log(`Error occurred, retrying (${attempts}/${retries})...`);
			console.error(error);
		}
	}
};

export const axiosRetryPut = async (url, data, retries = 2, config = {}, timeout = 10000) => {
	let attempts = 0;
	let key;
	if (window.location.href.indexOf("localhost") !== -1) {
		key = process.env.REACT_APP_BACKEND_KEY;
	}

	config.headers = { Authorization: `Bearer ${key}` };
	while (attempts <= retries) {
		try {
			const response = await axios.put(url, data, { ...config, timeout });
			return response;
		} catch (error) {
			attempts++;
			if (attempts > retries) {
				throw error;
			}
			console.log(`Error occurred, retrying (${attempts}/${retries})...`);
			console.error(error);
		}
	}
};

export const axiosRetryPatch = async (url, data, retries = 2, config = {}, timeout = 10000) => {
	let attempts = 0;
	let key;
	if (window.location.href.indexOf("localhost") !== -1) {
		key = process.env.REACT_APP_BACKEND_KEY;
	}

	config.headers = { Authorization: `Bearer ${key}` };
	while (attempts <= retries) {
		try {
			const response = await axios.patch(url, data, { ...config, timeout });
			return response;
		} catch (error) {
			attempts++;
			if (attempts > retries) {
				throw error;
			}
			console.log(`Error occurred, retrying (${attempts}/${retries})...`);
			console.error(error);
		}
	}
};

export const axiosRetryDelete = async (url, retries = 2, config = {}) => {
	let attempts = 0;
	let key;
	if (window.location.href.indexOf("localhost") !== -1) {
		key = process.env.REACT_APP_BACKEND_KEY;
	}

	config.headers = { Authorization: `Bearer ${key}` };
	while (attempts <= retries) {
		try {
			const response = await axios.delete(url, config);
			return response;
		} catch (error) {
			attempts++;
			if (attempts > retries) {
				throw error;
			}
			console.log(`Error occurred, retrying (${attempts}/${retries})...`);
			console.error(error);
		}
	}
};

export const useAxiosLimitedGet = () => {
	const { user, localUser, encryptionKey } = useContext(AppContext);

	const axiosLimitedGet = async (url, retries = 2, config = {}) => {
		let attempts = 0;
		const currentUser = user || localUser;
		// console.log("currentUser", user, localUser);
		let token = currentUser?.accessToken || dummyToken;

		// console.log("axios.get", url);

		// Use public URL if Frontegg user is undefined (except for uploads)
		let updatedUrl = url;
		if (!url.includes("api/upload")) {
			// 	updatedUrl = !user ? url.replace("api/", "api/public/") : url;
		}

		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token}`,
			"X-User-Id": currentUser?.id || "unauthenticatedUserId"
		};

		while (attempts <= retries) {
			try {
				const response = await axios.get(updatedUrl, config);
				return response;
			} catch (error) {
				attempts++;
				if (attempts > retries) {
					throw error.response;
				}
				console.log(`Error occurred, retrying (${attempts}/${retries})...`);
				if (error.message) {
					console.error(error.message);
				} else {
					console.error(error);
				}
				if (error.response.data) {
					console.error(error.response.data);
					// window.location.href = "/login"
				}
			}
		}
	};

	return axiosLimitedGet;
};

export const useAxiosLimitedPost = () => {
	const { user, localUser } = useContext(AppContext);

	const axiosLimitedPost = async (url, data, retries = 2, config = {}, timeout = 10000, retryDelay = 500) => {
		let attempts = 0;
		const currentUser = user || localUser;
		const token = currentUser?.accessToken || dummyToken;

		// Use public URL if Frontegg user is undefined
		let updatedUrl = url;
		if (!url.includes("api/upload")) {
			// 	updatedUrl = !user ? url.replace("api/", "api/public/") : url;
		}

		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token}`,
			"X-User-Id": currentUser?.id || "unauthenticatedUserId"
		};

		while (attempts <= retries) {
			try {
				const response = await axios.post(updatedUrl, data, { ...config, timeout });
				return response;
			} catch (error) {
				attempts++;
				if (attempts > retries) {
					throw error;
				}
				console.log(`Error occurred, retrying (${attempts}/${retries})...`);
				console.error(error);
				if (attempts <= retries) {
					await new Promise((resolve) => setTimeout(resolve, retryDelay));
				}
			}
		}
	};

	return axiosLimitedPost;
};
export const useAxiosLimitedPut = () => {
	const { user, localUser } = useContext(AppContext);

	const axiosLimitedPut = async (url, data, retries = 2, config = {}, timeout = 10000) => {
		let attempts = 0;
		const currentUser = user || localUser;
		let token = currentUser?.accessToken || dummyToken;

		// Use public URL if Frontegg user is undefined
		let updatedUrl = url;
		if (!url.includes("api/upload")) {
			// updatedUrl = !user ? url.replace("api/", "api/public/") : url;
		}

		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token}`,
			"X-User-Id": currentUser?.id || "unauthenticatedUserId"
		};

		while (attempts <= retries) {
			try {
				const response = await axios.put(updatedUrl, data, { ...config, timeout });
				return response;
			} catch (error) {
				attempts++;
				if (attempts > retries) {
					throw error;
				}
				console.log(`Error occurred, retrying (${attempts}/${retries})...`);
				console.error(error);
			}
		}
	};

	return axiosLimitedPut;
};

export const useAxiosLimitedPatch = () => {
	const { user, localUser } = useContext(AppContext);

	const axiosLimitedPatch = async (url, data, retries = 2, config = {}, timeout = 10000) => {
		let attempts = 0;
		const currentUser = user || localUser;
		let token = currentUser?.accessToken || dummyToken;

		// Use public URL if Frontegg user is undefined
		let updatedUrl = url;
		if (!url.includes("api/upload")) {
			// updatedUrl = !user ? url.replace("api/", "api/public/") : url;
		}

		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token}`,
			"X-User-Id": currentUser?.id || "unauthenticatedUserId"
		};

		while (attempts <= retries) {
			try {
				const response = await axios.patch(updatedUrl, data, { ...config, timeout });
				return response;
			} catch (error) {
				attempts++;
				if (attempts > retries) {
					throw error;
				}
				console.log(`Error occurred, retrying (${attempts}/${retries})...`);
				console.error(error);
			}
		}
	};

	return axiosLimitedPatch;
};

export const useAxiosLimitedDelete = () => {
	const { user, localUser } = useContext(AppContext);

	const axiosLimitedDelete = async (url, retries = 2, config = {}) => {
		let attempts = 0;
		const currentUser = user || localUser;
		let token = currentUser?.accessToken || dummyToken;

		// Use public URL if Frontegg user is undefined
		let updatedUrl = url;
		if (!url.includes("api/upload")) {
			//	updatedUrl = !user ? url.replace("api/", "api/public/") : url;
		}

		config.headers = {
			...config.headers,
			Authorization: `Bearer ${token}`,
			"X-User-Id": currentUser?.id || "unauthenticatedUserId"
		};

		while (attempts <= retries) {
			try {
				const response = await axios.delete(updatedUrl, config);
				return response;
			} catch (error) {
				attempts++;
				if (attempts > retries) {
					throw error;
				}
				console.log(`Error occurred, retrying (${attempts}/${retries})...`);
				console.error(error);
			}
		}
	};

	return axiosLimitedDelete;
};

export const useAxiosLimited = () => {
	const axiosLimitedGet = useAxiosLimitedGet();
	const axiosLimitedPost = useAxiosLimitedPost();
	const axiosLimitedPut = useAxiosLimitedPut();
	const axiosLimitedPatch = useAxiosLimitedPatch();
	const axiosLimitedDelete = useAxiosLimitedDelete();

	return { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete };
};
