// useSpeechSynthesizer.js
import { useRef, useState, useEffect } from "react";
import { sleep } from "../UtilityFunctions/sleep.js";
import usePollingIntervals from "../UtilityFunctions/usePollingIntervals";
import { useDeviceSettings } from "../UtilityFunctions/useDeviceSettings.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { usePostHog } from "posthog-js/react";

const sdk = require("microsoft-cognitiveservices-speech-sdk");

const timeoutDuration = 12400; // 5 seconds, adjust as needed

const safeGuardDuration = 12400; // 5 seconds, adjust as needed
export const url = process.env.REACT_APP_BACKEND_STATIC_URL;

const maxAttempts = 2;
const VoiceToVoice = {
	Nina: "en-ZA-LeahNeural",
	John: "en-US-AndrewMultilingualNeural", // "en-US-BrianNeural"
	David: "en-US-RyanMultilingualNeural", // "en-US-RyanMultilingualNeural",
	Myra: "en-US-AshleyNeural",

	Default: "fr-CA-AntoineNeural",
	Edward: "en-NZ-MitchellNeural",
	Ian: "en-US-TonyNeural",
	Colleen: "en-HK-YanNeural",

	Nina_en: "en-ZA-LeahNeural",
	John_en: "en-US-AndrewMultilingualNeural", // "en-US-BrianNeural"
	David_en: "en-US-RyanMultilingualNeural",
	Myra_en: "en-US-AshleyNeural",

	Nina_fr: "fr-FR-YvetteNeural",
	Myra_fr: "fr-CA-SylvieNeural",
	John_fr: "fr-CA-ThierryNeural",
	David_fr: "fr-CA-AntoineNeural"
};

const voiceToFrVoice = {
	Default: "fr-CA-AntoineNeural"
};
// eslint-disable-next-line
export function useSpeechSynthesizer({
	setShowSocketErrorModal = () => {},
	pauseSim = () => {},
	getTimeStamp = () => Date.now(),
	addAudioSourceToMixer,
	audioContext,
	onVisemeReceived = () => {},
	onWordReceived = () => {},
	onSynthesisStarted = () => {},
	onSynthesisCompleted = () => {},
	onSynthesisCanceled = () => {},
	onAudioStart = () => {},
	onAudioPlaying = () => {},
	onAudioEnd = () => {},
	isTalkingRef = { current: false },
	simPausedRef = { current: false },
	isAvatarTalkingRef
}) {
	const { selectedDevices, mediaInitialized, devices } = useDeviceSettings();
	const selectedDevicesRef = useRef(selectedDevices);
	const { axiosLimitedGet } = useAxiosLimited();
	const posthog = usePostHog();

	const synthesizerRef = useRef(null);
	const [status, setStatus] = useState("stopped");
	const statusRef = useRef("stopped");
	const timeoutIntervalRef = useRef(null);

	const saveGuardIntervalRef = useRef(null);

	const currentAttemptRef = useRef(null);
	const currentAttemptTextRef = useRef("");

	const speechIdRef = useRef(null);
	const intervalRef = useRef(null);
	const audioSourceRef = useRef(null);
	const gainNodeRef = useRef(null);
	const startTime = useRef(0);
	const pauseTime = useRef(0);
	const { setPollingInterval, clearPollingInterval } = usePollingIntervals();
	const SpeechSDK = sdk;
	const playerRef = useRef(null);
	const tokenRef = useRef(null);
	const tokenRef2 = useRef(null);
	const region1 = "eastus";
	const region2 = "eastus2";
	const serviceLabel = "instagepier2";
	const serviceLabel2 = "instagepier3";
	useEffect(() => {
		// on mount
		selectedDevicesRef.current = selectedDevices;
	}, [selectedDevices, mediaInitialized, devices]);

	useEffect(() => {
		// on mount
		const test = 1;

		try {
			axiosLimitedGet(`${url}/azure/token`, 1, { params: { keyName: serviceLabel } }).then((response) => {
				// console.log("azure token", response);
				tokenRef.current = {
					token: response.data,
					region: region1
				};
			});

			axiosLimitedGet(`${url}/azure/token`, 1, { params: { keyName: serviceLabel2 } }).then((response) => {
				// console.log("azure token", response);
				tokenRef2.current = {
					token: response.data,
					region: region2
				};
			});
		} catch (error) {
			console.error(error);
		}

		return () => {
			// Code to execute during component unmount
			if (playerRef.current) {
				playerRef.current.pause();
			}
		};
	}, []);

	function checkIfAllowedToTalk() {
		console.log("checkIfAllowedToTalk");

		console.log("isTalkingRef.current", isTalkingRef.current);
		console.log("simPausedRef.current", simPausedRef.current);

		return isTalkingRef.current === false && simPausedRef.current === false; // && isAvatarTalkingRef.current === false
	}

	// const audioContext = useRef(null);

	// use effect
	// useEffect(() => {
	// 	// console.log("useEffect audioContextProp speech synth");
	// 	if (audioContextProp) {
	// 		audioContext.current = audioContextProp;
	// 	}
	// }, [audioContextProp]);

	// Other event handlers and functions...

	function OnSynthesisStarted(sender, e) {
		console.log("synthesis-started", getTimeStamp());
		onSynthesisStarted({ speechId: speechIdRef.current });
	}

	function OnWordBoundary(sender, e) {
		const word = {
			text: e.text,
			textOffset: e.textOffset,
			wordLength: e.wordLength,
			audioOffset: e.audioOffset,
			duration: e.duration,
			boundaryType: e.boundaryType
		};
		// 	console.log("word", word);
		onWordReceived({ speechId: speechIdRef.current, word });
	}

	function OnVisemeReceived(sender, e) {
		const visime = {
			visemeId: e.visemeId,
			audioOffset: e.audioOffset,
			animation: e.animation
		};
		// Emit 'visemes' event to the client with the viseme details
		// 	console.log("viseme", visime);
		onVisemeReceived({ speechId: speechIdRef.current, visime });
	}

	function OnSynthesisCompleted(sender, e) {
		// console.log("synthesis-completed", getTimeStamp());
		onSynthesisCompleted({ speechId: speechIdRef.current });
	}

	const PLAYBACK_INTERVAL = 100;

	function setIntervalTimer() {
		clearIntervalTimer();
		let tempId = setPollingInterval(() => {
			// console.log("audio playing")
			try {
				intervalRef.current.duration += PLAYBACK_INTERVAL / 1000;
				OnAudioPlaying(intervalRef.current.duration);
			} catch (error) {
				console.error(error);
			}
		}, PLAYBACK_INTERVAL);
		// 	console.log("start setIntervalTimer", tempId);

		intervalRef.current.interval = tempId;
	}

	function clearIntervalTimer() {
		// console.log("clearIntervalTimer");
		if (intervalRef.current && intervalRef.current.interval) {
			// console.log("clearIntervalTimer done");

			clearPollingInterval(intervalRef.current.interval);
		}
	}

	function OnAudioStart(attempt) {
		// console.log(`audio started attempt ${attempt}`, getTimeStamp());
		setStatus("playing");
		statusRef.current = "playing";

		try {
			if (!intervalRef.current) {
				intervalRef.current = {};
			}
			intervalRef.current.duration = 0;
			// console.log("OnAudioStart", intervalRef.current);
			setIntervalTimer();
			onAudioStart({ speechId: speechIdRef.current });
		} catch (error) {
			console.error(error);
		}
		const player = playerRef.current;
		// console.log("player", player);
		const audioElement = player.internalAudio;
		// console.log("audioElement", audioElement);
		console.log(selectedDevicesRef.current);

		const outputDeviceId = selectedDevicesRef.current?.speaker?.value;

		console.log(`Audio is being played on device ${outputDeviceId}`);

		if (outputDeviceId && outputDeviceId !== "default") {
			audioContext.current.setSinkId(outputDeviceId);
		}
		// Create a media element source from the HTMLAudioElement
		// Create a media element source from the HTMLAudioElement
		const sourceNode = audioContext.current.createMediaElementSource(audioElement);
		// console.log("sourceNode", sourceNode);
		// Create a gain node
		const gainNode = audioContext.current.createGain();

		// Connect the source node to the gain node
		sourceNode.connect(gainNode);

		if (addAudioSourceToMixer) {
			addAudioSourceToMixer(gainNode);
		}

		gainNode.connect(audioContext.current.destination);
		//	gainNode.gain.value = 1; // Set gain level

		// Set the onended event handler of the source to the OnAudioEnd function
		// source.onended = OnAudioEnd;
		startTime.current = audioContext.current.currentTime;

		// Start the source
		//	sourceNode.start();
	}

	function OnAudioPlaying(duration) {
		try {
			// console.log("speechId", speechIdRef.current, duration);
			onAudioPlaying({ speechId: speechIdRef.current, duration });
		} catch (error) {
			console.error(error);
		}
	}

	function OnAudioEnd() {
		console.log("audio finished", getTimeStamp());
		clearIntervalTimer();

		if (statusRef.current === "ended") {
			return;
		}

		setStatus("ended");
		statusRef.current = "ended";
		if (saveGuardIntervalRef.current) {
			clearTimeout(saveGuardIntervalRef.current);
			saveGuardIntervalRef.current = null;
		}
		try {
			//  sender.close();

			onAudioEnd({ speechId: speechIdRef.current });
			// clearIntervalTimer();
		} catch (error) {
			console.error(error);
		}
		// audioContext.current.suspend();
	}

	// take the first attempt that
	function speak(speechId, originalInputText, voice = "Default", attempt = 0, SpeechSynthesisOutputFormat = "***") {
		if (!checkIfAllowedToTalk()) {
			console.warn("Not allowed to talk");
			return;
		}
		console.log("speechSynth Speak", originalInputText, getTimeStamp());
		let inputText = originalInputText.replace(/undefined/g, "");
		inputText = inputText.trim();
		interrupt();
		if (inputText.length <= 2) {
			console.warn("text too short to synth");
			return;
		}
		if (attempt === 0) {
			currentAttemptRef.current = null;
			currentAttemptTextRef.current = inputText;
		}

		try {
			const player = new SpeechSDK.SpeakerAudioDestination();

			player.onAudioEnd = () => {
				console.log("audio finished", getTimeStamp());
				OnAudioEnd();
			};

			player.onAudioStart = (test) => {
				// console.log(`audio started TEST `, getTimeStamp());
				// console.log("test", test);

				// currentAttemptRef.current = attempt;
				if (timeoutIntervalRef.current) {
					clearTimeout(timeoutIntervalRef.current);
					timeoutIntervalRef.current = null;
				}
				// Call the OnAudioStart function
				OnAudioStart(currentAttemptRef.current);
			};
			// Access the internal HTMLAudioElement

			playerRef.current = player;
			const tokenToUse = attempt === 0 ? tokenRef.current : tokenRef2.current;

			speechIdRef.current = speechId;
			let synthesizer = synthesizerRef.current;

			if (!synthesizer) {
				const audioConfig = SpeechSDK.AudioConfig.fromSpeakerOutput(player);

				const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(tokenToUse.token, tokenToUse.region);

				// const audioConfig = SpeechSDK.AudioConfig.fromStreamOutput(pullStream);
				// const speechConfig = SpeechSDK.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);
				// speechConfig.speechSynthesisOutputFormat = "***";

				speechConfig.speechSynthesisOutputFormat = SpeechSynthesisOutputFormat;
				speechConfig.speechSynthesisVoiceName = VoiceToVoice[voice];
				synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
				synthesizer.synthesisStarted = OnSynthesisStarted;
				synthesizer.wordBoundary = OnWordBoundary;
				synthesizer.visemeReceived = OnVisemeReceived;
				synthesizer.SynthesisCanceled = (sender, e) => {
					OnSynthesisCanceled(sender, e, { speechId, originalInputText, voice, attempt });
				};
				synthesizer.synthesisCompleted = OnSynthesisCompleted;
				synthesizer.synthesizing = (sender, e) => {};

				synthesizerRef.current = synthesizer;
			}

			if (timeoutIntervalRef.current) {
				clearTimeout(timeoutIntervalRef.current);
				timeoutIntervalRef.current = null;
			}

			// Start a timer
			const timeoutId = setTimeout(() => {
				console.log("Azure service slow to respond, triggering retry...");
				// Trigger a retry here
				if (maxAttempts > attempt) {
					speak(speechId, originalInputText, voice, attempt + 1);
				}
			}, timeoutDuration);
			timeoutIntervalRef.current = timeoutId;

			if (saveGuardIntervalRef.current) {
				clearTimeout(saveGuardIntervalRef.current);
				saveGuardIntervalRef.current = null;
			}
			const words = inputText.split(/\s+/).length; // Count the number of words in the input text
			const averageWordDurationMs = 650; // Average duration in milliseconds for speaking one word
			const dynamicSafeGuardDuration = words * averageWordDurationMs; // Calculate dynamic duration based on word count
			// console.log("dynamicSafeGuardDuration", dynamicSafeGuardDuration);
			const safeGuardId = setTimeout(() => {
				// console.log("force audio end");
				OnAudioEnd();
			}, dynamicSafeGuardDuration);
			saveGuardIntervalRef.current = safeGuardId;

			synthesizer.speakTextAsync(
				inputText,
				(result) => {
					// console.log("test2", getTimeStamp());
					synthesizer.close();
					synthesizerRef.current = undefined;

					// Speech synthesis completed successfully
					// handleSynthesisResult(synthesizer, result, inputText, attempt);
				},
				(err) => {
					// Error occurred during synthesis
					handleSynthesisError(synthesizer, err, { speechId, originalInputText, voice, attempt });
				}
			);
		} catch (error) {
			console.error(error);
		}
	}
	function speakV2(speechId, originalInputText, voice = "Default", attempt = 0, SpeechSynthesisOutputFormat = 0) {
		if (!checkIfAllowedToTalk()) {
			console.warn("Not allowed to talk");
			return;
		}
		console.log("speechSynth SpeakV2", originalInputText, getTimeStamp());
		let inputText = originalInputText.replace(/undefined/g, "");
		inputText = inputText.trim();
		interrupt2();

		if (inputText.length <= 2) {
			console.warn("text too short to synth");
			return;
		}
		if (attempt === 0) {
			currentAttemptRef.current = null;
			currentAttemptTextRef.current = inputText;
		}

		try {
			const tokenToUse = attempt === 0 ? tokenRef2.current : tokenRef2.current;

			speechIdRef.current = speechId;
			const audioStream = sdk.PullAudioOutputStream.create();
			let synthesizer = synthesizerRef.current;
			const sourceNode = audioContext.current.createBufferSource();

			if (!synthesizer) {
				const audioConfig = sdk.AudioConfig.fromStreamOutput(audioStream);

				// const audioConfig = SpeechSDK.AudioConfig.fromStreamOutput(pullStream);
				const speechConfig = SpeechSDK.SpeechConfig.fromAuthorizationToken(tokenToUse.token, tokenToUse.region);

				// speechConfig.speechSynthesisOutputFormat = "***";

				// speechConfig.speechSynthesisOutputFormat = SpeechSynthesisOutputFormat;
				speechConfig.speechSynthesisVoiceName = VoiceToVoice[voice];
				synthesizer = new SpeechSDK.SpeechSynthesizer(speechConfig, audioConfig);
				synthesizer.synthesisStarted = OnSynthesisStarted;
				synthesizer.wordBoundary = OnWordBoundary;
				synthesizer.visemeReceived = OnVisemeReceived;
				synthesizer.SynthesisCanceled = (sender, e) => {
					OnSynthesisCanceled(sender, e, { speechId, originalInputText, voice, attempt });
				};
				synthesizer.synthesisCompleted = OnSynthesisCompleted;
				synthesizer.synthesizing = (sender, e) => {};

				synthesizerRef.current = synthesizer;
			}

			if (timeoutIntervalRef.current) {
				clearTimeout(timeoutIntervalRef.current);
				timeoutIntervalRef.current = null;
			}
			// Start a timer
			const timeoutId = setTimeout(() => {
				console.log("Azure service slow to respond, triggering retry...");
				// Trigger a retry here
				if (maxAttempts > attempt) {
					speak(speechId, originalInputText, voice, attempt + 1);
				}
			}, timeoutDuration);
			timeoutIntervalRef.current = timeoutId;

			if (saveGuardIntervalRef.current) {
				clearTimeout(saveGuardIntervalRef.current);
				saveGuardIntervalRef.current = null;
			}
			const words = inputText.split(/\s+/).length; // Count the number of words in the input text
			const averageWordDurationMs = 650; // Average duration in milliseconds for speaking one word
			const dynamicSafeGuardDuration = words * averageWordDurationMs; // Calculate dynamic duration based on word count
			// console.log("dynamicSafeGuardDuration", dynamicSafeGuardDuration);
			const safeGuardId = setTimeout(() => {
				// console.log("force audio end");
				OnAudioEnd();
			}, dynamicSafeGuardDuration);
			saveGuardIntervalRef.current = safeGuardId;
			synthesizer.speakTextAsync(
				inputText,
				(result) => {
					audioContext.current.decodeAudioData(result.audioData, (buffer) => {
						sourceNode.buffer = buffer;
						const gainNode = audioContext.current.createGain();
						sourceNode.connect(gainNode);

						if (addAudioSourceToMixer) {
							addAudioSourceToMixer(gainNode);
						}
						const outputDeviceId = selectedDevicesRef.current?.speaker?.value;

						if (outputDeviceId && outputDeviceId !== "default") {
							audioContext.current.setSinkId(outputDeviceId);
						}
						gainNode.connect(audioContext.current.destination);

						// sourceNode.connect(audioContext.current.destination);
						sourceNode.onended = OnAudioEnd; // Set the event handler for when the audio ends

						sourceNode.start(0);
						// currentAttemptRef.current = attempt;
						if (timeoutIntervalRef.current) {
							clearTimeout(timeoutIntervalRef.current);
							timeoutIntervalRef.current = null;
						}
						// Call the OnAudioStart function
						setStatus("playing");
						statusRef.current = "playing";

						try {
							intervalRef.current = {};
							intervalRef.current.duration = 0;
							// setIntervalTimer();
							onAudioStart({ speechId: speechIdRef.current });
						} catch (error) {
							console.error(error);
						}

						// Create a media element source from the HTMLAudioElement
						// Create a media element source from the HTMLAudioElement
						// console.log("sourceNode", sourceNode);
						// Create a gain node

						// Connect the source node to the gain node

						//	gainNode.gain.value = 1; // Set gain level

						// Set the onended event handler of the source to the OnAudioEnd function
						// source.onended = OnAudioEnd;
						startTime.current = audioContext.current.currentTime;
						// Start the source
					});
					// Speech synthesis completed successfully
					// handleSynthesisResult(synthesizer, result, inputText, attempt);
				},
				(err) => {
					// Error occurred during synthesis
					handleSynthesisError(synthesizer, err, { speechId, originalInputText, voice, attempt });
				}
			);
		} catch (error) {
			console.error(error);
		}
	}
	function handleSynthesisResult(synthesizer, result, inputText, attempt) {
		// if (currentAttemptTextRef.current !== inputText) {
		// 	console.error("Synth result for old text, ignoring", inputText, currentAttemptTextRef.current);
		// 	return;
		// }
		// if (currentAttemptRef.current !== null) {
		// 	console.error("Synth result for old attempt, ignoring", currentAttemptRef.current, attempt);
		// 	return;
		// }
		// if (!checkIfAllowedToTalk()) {
		// 	console.warn("Not allowed to talk after");
		// 	return;
		// }
		currentAttemptRef.current = attempt;
		if (timeoutIntervalRef.current) {
			clearTimeout(timeoutIntervalRef.current);
			timeoutIntervalRef.current = null;
		}

		// Get the audio data from the result
		const { audioData } = result;

		// Convert the audio data to a 16-bit integer array
		const int16Array = new Int16Array(audioData);
		// Create a new 32-bit floating point array with the same length as the integer array
		const float32Array = new Float32Array(int16Array.length);
		// Convert the 16-bit integer values to 32-bit floating point values
		for (let i = 0; i < int16Array.length; i++) {
			float32Array[i] = int16Array[i] / 32768;
		}
		// Create an audio buffer with the audio context, using the length and sample rate of the floating point array
		const buffer = audioContext.current.createBuffer(1, float32Array.length, 48000);
		// Copy the floating point array to the audio buffer
		buffer.copyToChannel(float32Array, 0);
		// Create a new buffer source with the audio context
		const source = audioContext.current.createBufferSource();

		audioSourceRef.current = source;

		// Set the buffer of the source to the audio buffer
		source.buffer = buffer;

		gainNodeRef.current = audioContext.current.createGain();
		// Connect the source to the audio context's destination
		source.connect(gainNodeRef.current);
		if (addAudioSourceToMixer) {
			addAudioSourceToMixer(gainNodeRef.current);
		}

		gainNodeRef.current.connect(audioContext.current.destination);
		// Set the onended event handler of the source to the OnAudioEnd function
		source.onended = OnAudioEnd;
		startTime.current = audioContext.current.currentTime;
		// Start the source
		source.start();

		// Call the OnAudioStart function
		OnAudioStart(attempt);
		// If the reason for the result is that the audio synthesis was completed
		if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
			// Log that the synthesis has finished
			console.log(`synthesis finished for [${inputText}].\n`, getTimeStamp());
			// If the reason for the result is that the audio synthesis was canceled
		} else if (result.reason === SpeechSDK.ResultReason.Canceled) {
			// Log that the synthesis failed and provide the error details
			console.log(`synthesis failed. Error detail: ${result.errorDetails}\n`);
		}
		// Close the synthesizer
		synthesizer.close();
		// Set the current synthesizer to undefined
		synthesizerRef.current = undefined;
	}

	async function handleSynthesisError(synthesizer, err, data) {
		if (timeoutIntervalRef.current) {
			clearTimeout(timeoutIntervalRef.current);
			timeoutIntervalRef.current = null;
		}
		if (saveGuardIntervalRef.current) {
			clearTimeout(saveGuardIntervalRef.current);
			saveGuardIntervalRef.current = null;
		}
		const { speechId, originalInputText, voice, attempt } = data;
		console.log("Error: ");
		console.error(err);
		try {
			posthog.capture("error-speech-synthesis-", {
				speechId,
				originalInputText,
				voice,
				attempt,
				errorDetails: err
			});
		} catch (error) {
			console.error(error);
		}
		if (attempt <= maxAttempts) {
			synthesizer.close();
			synthesizerRef.current = undefined;
			await sleep(200);
			speak(speechId, originalInputText, voice, attempt + 1);
		} else {
			synthesizer.close();
			synthesizerRef.current = undefined;
			//	pauseSim();
			posthog.capture("special-error-speech-synthesis", {
				speechId,
				originalInputText,
				voice,
				attempt,
				errorDetails: err
			});
			await sleep(200);
			//	setShowSocketErrorModal(true);
		}
	}

	async function OnSynthesisCanceled(sender, e, data) {
		console.error("synthesiser Cancelled", e.result.errorDetails);

		const { speechId, originalInputText, voice, attempt } = data;
		if (attempt <= maxAttempts) {
			synthesizerRef.current.close();
			synthesizerRef.current = undefined;
			await sleep(200);
			speak(speechId, originalInputText, voice, attempt + 1);
		} else {
			// pauseSim();
			// setShowSocketErrorModal(true);
			try {
				posthog.capture("error-speech-synthesis-cancelled", {
					speechId,
					originalInputText,
					voice,
					attempt,
					errorDetails: e.result.errorDetails
				});
			} catch (error) {
				console.error(error);
			}
			onSynthesisCanceled({ speechId: speechIdRef.current, errorDetails: e.result.errorDetails });
			if (saveGuardIntervalRef.current) {
				clearTimeout(saveGuardIntervalRef.current);
				saveGuardIntervalRef.current = null;
			}
			posthog.capture("special-error-speech-synthesis-cancelled", {
				speechId,
				originalInputText,
				voice,
				attempt
			});
		}
	}

	function getVolume() {
		if (gainNodeRef.current) {
			return gainNodeRef.current.gain.value;
		}
		return 0;
	}

	function setVolume(vol) {
		if (gainNodeRef.current) {
			gainNodeRef.current.gain.value = vol;
		}
	}

	function mute() {
		if (gainNodeRef.current) {
			gainNodeRef.current.gain.value = 0;
		}
	}

	function unmute() {
		if (gainNodeRef.current) {
			gainNodeRef.current.gain.value = 1;
		}
	}

	async function interrupt() {
		if (audioSourceRef.current) {
			audioSourceRef.current.stop();

			if (synthesizerRef.current) {
				synthesizerRef.current.close();
				synthesizerRef.current = undefined;
			}

			clearIntervalTimer();

			setStatus("interrupted");
			statusRef.current = "interrupted";
		}
	}
	async function interrupt2() {
		console.log("interrupt2");
	}
	function pause() {
		if (audioSourceRef.current) {
			audioSourceRef.current.onended = undefined;
			pauseTime.current = audioContext.current.currentTime - startTime.current;
			audioSourceRef.current.stop();

			setStatus("paused");
			statusRef.current = "paused";
		}
		clearIntervalTimer();
	}

	function resume() {
		if (audioSourceRef.current && status === "paused") {
			// Create a new buffer source for the audio context
			const source = audioContext.current.createBufferSource();
			source.buffer = audioSourceRef.current.buffer;

			gainNodeRef.current = audioContext.current.createGain();
			// Connect the source to the audio context's destination
			source.connect(gainNodeRef.current);
			if (addAudioSourceToMixer) {
				addAudioSourceToMixer(gainNodeRef.current);
			}
			gainNodeRef.current.connect(audioContext.current.destination);
			source.onended = OnAudioEnd;
			audioSourceRef.current = source;
			OnAudioStart();
			// Start the source from where it was paused
			source.start(0, pauseTime.current);
			startTime.current = audioContext.current.currentTime;

			setIntervalTimer();
			setStatus("resumed");
			statusRef.current = "resumed";
		}
	}

	return {
		speakV2,
		speak,
		pause,
		resume,
		getVolume,
		setVolume,
		mute,
		unmute,
		interrupt,
		status
		// ... other controls and data you need to expose
	};
}
