import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import a from "../styleModules/signupStyles.module.css";
import { AppContext } from "./UtilityFunctions/AppContext.js";
import { useAxiosLimited } from "./UtilityFunctions/axiosRetry.js";
import logo from "../images/logo.png";

function UnsubscribePage() {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { instage_id } = useParams();
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;

	const { userLanguage, translateDictText, performTranslation, localUser } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		YouHaveBeenRemovedFromOurMonthlyEmailList: "You Have Been Removed From Our Monthly Email List"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	const setUserMonthlyEmailPref = async () => {
		axiosLimitedGet(`${url}/api/users/unsubscribe/${instage_id}`)
			.then((response) => {
				console.log(response.data);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useEffect(() => {
		setUserMonthlyEmailPref();
	}, []);

	return (
		<>
			<div
				style={{
					position: "fixed",
					top: "50%", // Center vertically
					left: "50%", // Center horizontally
					transform: "translate(-50%, -50%)", // Adjust for the div's own size
					zIndex: 1000,
					display: "flex",
					width: "100%",
					maxWidth: "500px",
					height: "auto", // Adjust height as needed
					justifyContent: "center",
					flexDirection: "column"
				}}
			>
				<div className={a.adminBoxHeader}>
					<img className={a.adminBoxLogo} style={{ width: "85%", height: "auto", alignSelf: "flex-start" }} src={logo} alt="logo" />
				</div>

				<h1 className={a.adminBoxTitle} style={{ color: "black", textAlign: "center" }}>
					{pageText.YouHaveBeenRemovedFromOurMonthlyEmailList}
				</h1>
			</div>
		</>
	);
}

export default UnsubscribePage;
