/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 personal_office.glb --transform
Author: Janis Zeps (https://sketchfab.com/zeps9001)
License: SKETCHFAB Standard (https://sketchfab.com/licenses)
Source: https://sketchfab.com/3d-models/personal-office-fb2319942a6d49fa9adf1bc7f7d3aa84
Title: Personal Office
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model(props) {
	const { nodes, materials } = useGLTF("https://pixelbackendstaging.instage.io/getmodel?file=personal_office-transformed");
	return (
		<group {...props} dispose={null}>
			<group scale={0.01}>
				<mesh
					geometry={nodes.Cube002_furniture_0.geometry}
					material={materials.furniture}
					position={[-16.84, 164.45, -0.97]}
					rotation={[-Math.PI / 2, 0, -Math.PI]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Plane_furniture_0.geometry}
					material={materials.furniture}
					position={[227.73, 2.56, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cylinder_furniture_0.geometry}
					material={materials.furniture}
					position={[192.33, 60.02, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cylinder002_furniture_0.geometry}
					material={materials.furniture}
					position={[192.33, 78.15, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={131}
				/>
				<mesh
					geometry={nodes.Back_low003_furniture_0.geometry}
					material={materials.furniture}
					position={[293.45, 2.74, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube053_furniture_0.geometry}
					material={materials.furniture}
					position={[-210.07, -1.12, -32.29]}
					rotation={[-Math.PI / 2, 0, 1.15]}
					scale={108.24}
				/>
				<mesh
					geometry={nodes.chair_furniture_0.geometry}
					material={materials.furniture}
					position={[-206.17, 8.55, -31.41]}
					rotation={[-Math.PI / 2, 0, 1.15]}
					scale={108.24}
				/>
				<mesh
					geometry={nodes.Cube036_furniture_0.geometry}
					material={materials.furniture}
					position={[-16.84, 164.45, -0.97]}
					rotation={[-Math.PI / 2, 0, -Math.PI]}
					scale={100}
				/>
				<mesh
					geometry={nodes.paintings_bookcase_0.geometry}
					material={materials.bookcase}
					position={[-208.54, 327.23, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube019_bookcase_0.geometry}
					material={materials.bookcase}
					position={[-336.08, -1.98, -0.44]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.pachira_aquatica_01_bark_c002_bookcase_0.geometry}
					material={materials.bookcase}
					position={[-251.98, 31.93, -0.29]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Plane006_bookcase_0.geometry}
					material={materials.bookcase}
					position={[-68.34, 94.55, 4.69]}
					rotation={[-Math.PI / 2, 0, -0.15]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Plane007_bookcase_0.geometry}
					material={materials.bookcase}
					position={[-69.01, 93.29, -82.66]}
					rotation={[-Math.PI / 2, 0, -0.48]}
					scale={127.59}
				/>
				<mesh
					geometry={nodes.Cylinder004_bookcase_0.geometry}
					material={materials.bookcase}
					position={[-25.68, 90.66, 99.32]}
					rotation={[-Math.PI / 2, 0, -2.11]}
					scale={148.77}
				/>
				<mesh
					geometry={nodes.Plane005_bookcase_0.geometry}
					material={materials.bookcase}
					position={[212, 61.29, -40.76]}
					rotation={[-Math.PI / 2, 0, -2.26]}
					scale={85.69}
				/>
				<mesh
					geometry={nodes.Plane003_bookcase_0.geometry}
					material={materials.bookcase}
					position={[223.01, 61.29, 34.77]}
					rotation={[-Math.PI / 2, 0, 1.74]}
					scale={85.69}
				/>
				<mesh
					geometry={nodes.Cube004_details_0.geometry}
					material={materials.details}
					position={[-275.98, 135.42, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube006_details_0.geometry}
					material={materials.details}
					position={[55.07, 145.53, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube007_details_0.geometry}
					material={materials.details}
					position={[0, 0, 1.71]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cylinder001_details_0.geometry}
					material={materials.details}
					position={[463.36, 121.07, 117.44]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube008_details_0.geometry}
					material={materials.details}
					position={[-21.75, 121.1, -102.8]}
					rotation={[-Math.PI / 2, 0, -0.54]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube009_details_0.geometry}
					material={materials.details}
					position={[-33.48, 138.4, -83.35]}
					rotation={[-Math.PI / 2, 0, -0.54]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube010_details_0.geometry}
					material={materials.details}
					position={[61.99, 0, 1.27]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube012_details_0.geometry}
					material={materials.details}
					position={[62.29, 0, 0]}
					rotation={[-Math.PI / 2, 0, -Math.PI]}
					scale={100}
				/>
				<mesh
					geometry={nodes.lamps001_details_0.geometry}
					material={materials.details}
					position={[-2.57, 491.3, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube014_details_0.geometry}
					material={materials.details}
					position={[61.99, 0, 1.38]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube_details_0.geometry}
					material={materials.details}
					position={[461.24, 122.28, 123.98]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cylinder005_details_0.geometry}
					material={materials.details}
					position={[56.39, 307.83, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={72.37}
				/>
				<mesh
					geometry={nodes.Cube018_details_0.geometry}
					material={materials.details}
					position={[61.99, 0, 19.92]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Icosphere_details_0.geometry}
					material={materials.details}
					position={[-326.11, 124.6, -191.95]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={75.6}
				/>
				<mesh
					geometry={nodes.Cube016_details_0.geometry}
					material={materials.details}
					position={[-336.08, -1.98, 0]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cylinder007_details_0.geometry}
					material={materials.details}
					position={[-333.27, 261.08, -215.11]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cylinder008_details_0.geometry}
					material={materials.details}
					position={[-25.68, 90.66, 99.32]}
					rotation={[-Math.PI / 2, 0, -2.11]}
					scale={148.77}
				/>
				<mesh
					geometry={nodes.Cylinder009_details_0.geometry}
					material={materials.details}
					position={[-69.34, 95.14, -15.15]}
					rotation={[-3.13, 1.17, -2.66]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube003_details_0.geometry}
					material={materials.details}
					position={[62.29, -0.19, 1.27]}
					rotation={[-Math.PI / 2, 0, -Math.PI]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube020_details_0.geometry}
					material={materials.details}
					position={[61.99, 0, 19.92]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.lamps002_details_0.geometry}
					material={materials.details}
					position={[-358.31, 402.76, -362.15]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cylinder010_details_0.geometry}
					material={materials.details}
					position={[-333.27, 214.15, 153.45]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cylinder006_details_0.geometry}
					material={materials.details}
					position={[458.86, 112.39, -0.54]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={130.62}
				/>
				<mesh
					geometry={nodes.Icosphere003_details_0.geometry}
					material={materials.details}
					position={[-326.11, 263.95, 215.57]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={75.6}
				/>
				<mesh
					geometry={nodes.Cylinder019_details_0.geometry}
					material={materials.details}
					position={[219.76, 62.1, 29.17]}
					rotation={[-3.13, 1.17, -2.66]}
					scale={100}
				/>
				<mesh
					geometry={nodes.grain_details_0.geometry}
					material={materials.details}
					position={[463.91, 144.71, -0.16]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cube021_details_0.geometry}
					material={materials.details}
					position={[33.75, 150.54, 316.23]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.walls_wall_0.geometry}
					material={materials.wall}
					position={[61.99, 0, 1.63]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
				<mesh
					geometry={nodes.Cylinder016_bookcase_0.geometry}
					material={materials.bookcase}
					position={[464.24, 111.45, -108.98]}
					rotation={[-Math.PI / 2, 0, -1.23]}
					scale={297.36}
				/>
				<mesh
					geometry={nodes.Cylinder017_details_0.geometry}
					material={materials.details}
					position={[464.24, 111.45, -108.98]}
					rotation={[-Math.PI / 2, 0, -1.23]}
					scale={297.36}
				/>
				<mesh
					geometry={nodes.walls001_wall_0.geometry}
					material={materials.wall}
					position={[61.99, 0, 1.63]}
					rotation={[-Math.PI / 2, 0, 0]}
					scale={100}
				/>
			</group>
		</group>
	);
}

//useGLTF.preload('/personal_office-transformed.glb')
