import React, { useState, useEffect, useRef, useContext } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai/index.esm.js";
import { IoClose } from "react-icons/io5/index.esm.js";
import r from "../../styleModules/reportStyles.module.css";
import m from "../../styleModules/modalStyles.module.css";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import ModalWrapper from "../SecondaryComponents/ModalWrapper.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import { CommentIcon } from "../SecondaryComponents/CommentModal.js";
import { Metrics } from "./Metrics.js";

export default function Communication({ sessionData, speechMetrics, setSpeechMetrics }) {
	// Tips Popup
	const [activeMetric, setActiveMetric] = useState("");
	const [popupContent, setPopupContent] = useState("");
	const [showPopup, setShowPopup] = useState(false);

	const { runTranslation, adminAccess, tenantId, userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	const [tempMetrics, setTempMetrics] = useState(Metrics);
	const [dynamicPageText, setDynamicPageText] = useState({});

	// Static Translation
	const LocalLanguageDict = {
		Quiet: "Quiet",
		Normal: "Normal",
		Loud: "Loud",
		TargetRange: "Target Range",
		Between: "Between",
		And: "and",
		Between5570dB: "Between 55-70dB",
		Volume: "Volume",
		Pace: "Pace",
		SessionDuration: "Session Duration",
		PresentationDuration: "Presentation Duration",
		LongestMonologue: "Longest Monologue",
		FillerWords: "Filler Words"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	const [fillerWordsTranslated, setFillerWordsTranslated] = useState([speechMetrics.filler_words]);
	const [isTranslating, setIsTranslating] = useState(0);
	// Static Usage
	useEffect(() => {
		// console.log(speechMetrics.filler_words);
		const translatedFillerWords = Object.keys(speechMetrics.filler_words).map((word) => {
			if (!speechMetrics.filler_words) {
				return [];
			}
			return translateDictText([word], userLanguage).then(([translatedWord]) => ({
				key: translatedWord,
				value: speechMetrics.filler_words[word]
			}));
		});

		Promise.all(translatedFillerWords).then((results) => {
			setFillerWordsTranslated(results);
		});

		async function translateText() {
			await performTranslation(pageText, setPageText);
			setIsTranslating((prev) => prev + 1);
		}

		if (isTranslating <= 5) {
			// console.log("Translating Communication");
			translateText();
		}
	}, [userLanguage, pageText]);

	// Helper function to deeply translate React components
	const deepTranslateReactNodes = async (node, translateFunc, language) => {
		if (typeof node === "string") {
			const [translated] = await translateFunc([node], language);
			return translated;
			// eslint-disable-next-line no-else-return
		} else if (React.isValidElement(node)) {
			const children = React.Children.toArray(node.props.children);
			const translatedChildren = await Promise.all(children.map((child) => deepTranslateReactNodes(child, translateFunc, language)));
			return React.cloneElement(node, {}, translatedChildren);
		} else if (Array.isArray(node)) {
			return Promise.all(node.map((child) => deepTranslateReactNodes(child, translateFunc, language)));
		}
		return node;
	};

	// Dynamic Usage
	useEffect(() => {
		const translateMetricTitlesAndContents = async () => {
			try {
				const translatedMetrics = await Promise.all(
					Object.entries(Metrics).map(async ([key, metric]) => {
						const [title] = await translateDictText([metric.tips.title], userLanguage).catch(() => [metric.tips.title]);
						const content = await deepTranslateReactNodes(metric.tips.content, translateDictText, userLanguage).catch(
							() => metric.tips.content
						);
						return [key, { ...metric, tips: { ...metric.tips, title, content } }];
					})
				);
				setTempMetrics(Object.fromEntries(translatedMetrics));

				if (speechMetrics.filler_words) {
					const translatedFillerWords = await translateDictText(Object.keys(speechMetrics.filler_words), userLanguage).catch(() =>
						Object.keys(speechMetrics.filler_words)
					);
					const fillerWordsMap = Object.fromEntries(
						translatedFillerWords.map((translated, index) => [
							translated,
							speechMetrics.filler_words[Object.keys(speechMetrics.filler_words)[index]]
						])
					);
					setSpeechMetrics((prevMetrics) => ({
						...prevMetrics,
						filler_words: fillerWordsMap
					}));
				}
			} catch (error) {
				console.error("Error translating metrics:", error);
			}
		};

		if (Metrics) {
			setTimeout(() => {
				translateMetricTitlesAndContents();
			}, 500);
		}
	}, [userLanguage]);

	// Effect to log tempMetrics when it updates
	useEffect(() => {
		// console.log("Updated tempMetrics: ", tempMetrics);
	}, [tempMetrics]);

	const convertTime = (seconds) => {
		// convert seconds to mm:ss format
		const minutes = Math.floor(seconds / 60);
		let secondsLeft = seconds % 60;
		if (secondsLeft < 10) {
			secondsLeft = `0${secondsLeft}`;
		}
		return `${minutes}:${secondsLeft}`;
	};

	const convertVolume = (volume) => {
		// Quiet: Lower than -25 dBFS
		// Normal: Between -25 dBFS and -14 dBFS
		// Loud: Greater than -14 dBFS

		if (volume < -25) {
			return pageText.Quiet;
		}
		if (volume < -14) {
			return pageText.Normal;
		}
		return pageText.Loud;
	};

	const talkSpeedSummary = Math.round(speechMetrics.wpm_avg);
	const loudnessSummary = Math.round(speechMetrics.db_avg);
	const fillerSummary = parseFloat((speechMetrics.filler_words_perc * 100).toFixed(1));
	// useEffect(() => {
	// 	runTranslation();
	// });
	return (
		<>
			{showPopup && (
				<ModalWrapper closeModal={() => setShowPopup(false)}>
					<div className={m.modal} style={{ textAlign: "left", maxWidth: "50%" }}>
						<span className={m.modalCloseIcon}>
							<IoClose title="Close icon" onClick={() => setShowPopup(false)} />
						</span>
						<h2 className={m.modalTitle}>{tempMetrics[activeMetric].tips.title}</h2>
						<div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
							<p className={m.modalText} style={{ fontFamily: "BoldFont" }}>
								{pageText.TargetRange}:
							</p>
							<p className={m.modalText}>
								{activeMetric === "loudness"
									? pageText.Between5570dB
									: `${pageText.Between} ${tempMetrics[activeMetric].normalStart} ${tempMetrics[activeMetric].unit} ${pageText.And} ${tempMetrics[activeMetric].normalEnd} ${tempMetrics[activeMetric].unit}`}
							</p>
						</div>
						<div style={{ margin: "1rem 0 0" }}>{popupContent}</div>
					</div>
				</ModalWrapper>
			)}

			<div>
				{speechMetrics === null && (
					<div className={r.spinnerContainer}>
						<LoadingSpinner height={50} width={50} thickness={5} color="#00a9af" />
					</div>
				)}

				{Object.keys(speechMetrics).length !== 0 && (
					<div>
						<div className={r.communicationRow}>
							<div className={r.communicationSection}>
								<div className={r.communicationMetric}>
									<span>{pageText.Volume}</span>
									<AiOutlineInfoCircle
										className={r.communicationMetricIcon}
										onClick={() => {
											setActiveMetric("loudness");
											setPopupContent(tempMetrics.loudness.tips.content);
											setShowPopup(true);
										}}
									/>
								</div>
								<BulletChart
									summary={loudnessSummary}
									lowerBound={Metrics.loudness.lowerBound}
									upperBound={Metrics.loudness.upperBound}
									normalStart={Metrics.loudness.normalStart}
									normalEnd={Metrics.loudness.normalEnd}
								/>
								<div
									className={r.communicationValue}
									style={{
										color: loudnessSummary < -30 || loudnessSummary > -10 ? "#ee4188" : "#00a9af"
									}}
								>
									{convertVolume(loudnessSummary)}
								</div>
							</div>
							<div className={r.communicationSection}>
								<div className={r.communicationMetric}>
									<span>{pageText.Pace}</span>

									<AiOutlineInfoCircle
										className={r.communicationMetricIcon}
										onClick={() => {
											setActiveMetric("pace");
											setPopupContent(tempMetrics.pace.tips.content);
											setShowPopup(true);
										}}
									/>
								</div>
								<BulletChart
									summary={talkSpeedSummary}
									lowerBound={Metrics.pace.lowerBound}
									upperBound={Metrics.pace.upperBound}
									normalStart={Metrics.pace.normalStart}
									normalEnd={Metrics.pace.normalEnd}
								/>
								<div
									className={r.communicationValue}
									style={{
										color: talkSpeedSummary < 110 || talkSpeedSummary > 199 ? "#ee4188" : "#00a9af"
									}}
								>
									<span>{talkSpeedSummary} wpm</span>
								</div>
							</div>
							<div className={r.communicationSection} style={{ alignItems: "flex-start" }}>
								<div>
									<div className={r.communicationMetric}>
										<span>{pageText.SessionDuration}</span>
									</div>
									<div className={r.communicationValue}>
										{convertTime(Math.min(Math.round(sessionData.duration), 2400))}
										{Math.round(sessionData.duration) > 2400 ? " (max)" : ""}
									</div>
								</div>
								<div>
									<div className={r.communicationMetric}>
										<span>
											{sessionData.setup_type === "presentation" ? pageText.PresentationDuration : pageText.LongestMonologue}{" "}
										</span>
									</div>
									<div className={r.communicationValue}>
										{sessionData.setup_type === "presentation"
											? convertTime(Math.round(speechMetrics.first_response))
											: convertTime(Math.round(speechMetrics.longest_response))}
									</div>
								</div>
							</div>
						</div>
						<div className={r.communicationRow}>
							<div className={r.communicationSectionFull}>
								<div className={r.communicationMetricSection}>
									<div className={r.communicationMetric}>
										<span>{pageText.FillerWords}</span>
										<AiOutlineInfoCircle
											className={r.communicationMetricIcon}
											onClick={() => {
												setActiveMetric("fillers");
												setPopupContent(tempMetrics.fillers.tips.content);
												setShowPopup(true);
											}}
										/>
									</div>
									<BulletChart
										summary={fillerSummary}
										lowerBound={Metrics.fillers.lowerBound}
										upperBound={Metrics.fillers.upperBound}
										normalStart={Metrics.fillers.normalStart}
										normalEnd={Metrics.fillers.normalEnd}
										roundedCorners
									/>
									<div
										className={r.communicationValue}
										style={{ color: speechMetrics.filler_words_perc > 0.06 ? "#ee4188" : "#00a9af" }}
									>
										{fillerSummary}%
									</div>
								</div>
								<div className={r.communicationFillers}>
									{fillerWordsTranslated &&
										fillerWordsTranslated
											.filter(({ key }) => key) // Filter out empty or undefined keys
											.map(({ key, value }) => (
												<div key={key}>
													<div className={r.communicationMetric}>
														<span>{key}</span>
													</div>
													<div className={r.communicationValue}>{value}</div>
												</div>
											))}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

function BulletChart({ lowerBound, upperBound, summary, normalStart, normalEnd, roundedCorners = false }) {
	const fullScale = upperBound - lowerBound;
	const targetZoneWidth = ((normalEnd - normalStart) / fullScale) * 100;
	const marginToLeftOfTarget = ((normalStart - lowerBound) / fullScale) * 100;
	const summaryBulletMarker = Math.min(Math.max(((summary - lowerBound) / fullScale) * 100, 0), 100);
	const isOutsideTarget = summary < normalStart || summary > normalEnd;
	const markerStyle = isOutsideTarget
		? {
				left: `${summaryBulletMarker}%`
				// backgroundColor: '#ee4188'
		  }
		: { left: `${summaryBulletMarker}%` };
	const cornerStyle = roundedCorners ? { borderTopLeftRadius: "0.5rem", borderBottomLeftRadius: "0.5rem" } : {};

	return (
		<div className={r.bulletChart}>
			<div className={r.bulletChartWrapper}>
				<div
					className={r.bulletTarget}
					style={{
						marginLeft: `${marginToLeftOfTarget}%`,
						width: `${targetZoneWidth}%`,
						...cornerStyle
					}}
				/>
			</div>
			<div className={r.bulletMarker} style={markerStyle} />
		</div>
	);
}
