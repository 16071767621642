import React, { createContext, useRef, useState } from "react";

export const AppContext = createContext();
export const CommentContext = createContext();
export const VideoContext = createContext();

// a new wrapper component to provide the values
export function VideoProvider({ children }) {
	const VideoRef = useRef(null);
	const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
	const [videoDownloadLink, setVideoDownloadLink] = useState("");

	// values that we want to share across components
	const value = {
		VideoRef,
		playingVideoIndex,
		setPlayingVideoIndex,
		videoDownloadLink,
		setVideoDownloadLink
	};

	return <VideoContext.Provider value={value}>{children}</VideoContext.Provider>;
}
