import { validateString } from "../Simulation/validateString.js";

/**
 * Class representing the data related to User's transcripts.
 * @property {string} transcript - The transcript text.
 * @property {string} interimTranscript - The interim transcript text.
 * @property {boolean} isFinal - Flag to check if the transcript is final.
 * @property {boolean} speechFinal - Flag to check if the speech is final.
 * @property {number} duration - The duration of the transcript.
 * @property {number} transcriptId - The ID of the transcript.
 * @property {Date} startTime - The time when the transcript is first set to a non-empty value.
 * @property {number} wordsPerMinute - The words per minute rate of the transcript.
 * @property {number} fillerCount - The count of filler words in the transcript.
 */
class UserTranscriptData {
	/**
	 * Create a new UserTranscriptData object.
	 */
	constructor(transcript, isFinal, speechFinal, duration) {
		this.deepgram_data = null;
		/**
		 * The transcript text.
		 * @type {string}
		 */
		this.transcript = transcript;
		/**
		 * The interim transcript text.
		 */
		this.interimTranscript = "";
		/**
		 * Flag to check if the transcript is final.
		 * @type {boolean}
		 */
		this.isFinal = isFinal;

		/**
		 * Flag to check if the speech is final.
		 * @type {boolean}
		 */
		this.speechFinal = speechFinal;

		/**
		 * The duration of the transcript.
		 * @type {number}
		 */
		this.duration = duration;

		/**
		 * The ID of the transcript.
		 * @type {number}
		 */
		this.transcriptId = 0;

		/**
		 * The time when the transcript is first set to a non-empty value.
		 * @type {Date}
		 */
		this.startTime = null;

		this.timeStamp = null;
		/**
		 * The time when the transcript is finished.
		 * @type {Date}
		 */
		this.endTime = null;
		/**
		 * The words per minute rate of the transcript.
		 * @type {number}
		 */
		this.wordsPerMinute = 0;

		/**
		 * The count of filler words in the transcript.
		 * @type {number}
		 */
		this.fillerCount = 0;

		this.pauses = 0;

		this.db = 0;

		this.rms = 0;
		this.sampleRate = -1;
		this.words = 0;
		this.audioFileStarted = false;
		this.audioFileName = "";
	}

	updateTranscript(transcript, isFinal, speechFinal, duration) {
		this.isFinal = isFinal;
		this.speechFinal = speechFinal;

		if (this.isFinal) {
			this.pauses += 1;
			if (this.transcript !== "" && transcript !== "") {
				this.transcript += ` ${transcript}`;
			} else {
				this.transcript += transcript;
			}
			// Append the transcript to the existing transcript
			if (!validateString(this.interimTranscript)) {
				this.interimTranscript = transcript;
			} else {
				// Clear the interim transcript
				this.interimTranscript = "";
			}
		} else {
			// Update the interim transcript
			this.interimTranscript = transcript;
		}
		// Set the start time if the transcript is not empty and start time is not set
		if (validateString(this.interimTranscript) && this.startTime === null) {
			this.startTime = new Date();
		}
		// if (this.speechFinal) {
		//     this.finishEvent()
		// }
	}

	finishEvent() {
		this.speechFinal = true;
		this.isFinal = true;
		// Calculate the duration from the start time to now
		if (this.startTime !== null) {
			this.endTime = new Date();
			this.duration = (this.endTime - this.startTime) / 1000;
			// Check if transcript is not null or undefined
			if (this.transcript) {
				// Remove leading and trailing spaces and split by one or more spaces
				const words = this.transcript.trim().split(/\s+/);
				this.words = words.length;
				this.wordsPerMinute = words.length / (this.duration / 60);
				this.wordsPerMinute = Math.min(this.wordsPerMinute, 250);
				this.fillerCount = this.countFillerWords(this.transcript);
			}
		}
	}

	countFillerWords(text) {
		const singleFillers = [
			"nuh-uh",
			"uh-huh",
			"uh-uh",
			"mm-mm",
			"mmhm",
			"eh",
			"oh",
			"ah",
			"uh",
			"um",
			"like",
			"so",
			"actually",
			"basically",
			"seriously",
			"literally"
		];
		const doubleFillers = ["you know", "i mean", "sort of", "kind of"];

		let count = 0;

		if (text === null || text === undefined) {
			return count;
		}
		// Normalize text to lowercase
		const normalizedText = text.toLowerCase();

		// count single word fillers
		for (let i = 0; i < singleFillers.length; i++) {
			const pattern = `\\b${singleFillers[i]}\\b`; // word boundaries
			const regExp = new RegExp(pattern, "g");
			const matches = normalizedText.match(regExp);
			count += matches ? matches.length : 0;
		}

		// count double word fillers
		for (let i = 0; i < doubleFillers.length; i++) {
			const pattern = `\\b${doubleFillers[i]}\\b`; // word boundaries
			const regExp = new RegExp(pattern, "g");
			const matches = normalizedText.match(regExp);
			count += matches ? matches.length : 0;
		}

		return count;
	}

	/**
	 * Static function to convert an object to an instance of UserTranscriptData.
	 * @param {Object} data - The data object.
	 * @return {UserTranscriptData} The instance of UserTranscriptData.
	 */
	static fromObject(data) {
		const userTranscriptData = new UserTranscriptData(data.transcript, data.isFinal, data.speechFinal, data.duration);
		Object.assign(userTranscriptData, data);

		if (data.startTime) userTranscriptData.startTime = new Date(data.startTime);
		if (data.endTime) userTranscriptData.endTime = new Date(data.endTime);

		return userTranscriptData;
	}
}

export default UserTranscriptData;
