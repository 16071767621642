import React, { useState, useContext, useEffect } from "react";
import styles from "../../styleModules/modalStyles.module.css";
import { IoClose } from "react-icons/io5/index.esm.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { axiosRetryGet } from "../UtilityFunctions/axiosRetry";
import { useFeatureFlagEnabled } from "posthog-js/react";

function OverLimitBanner() {
	const { userLanguage, translateDictText, performTranslation, localUser } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		YouHaveExceededYourAccountLimit: "You have exceeded your account limit.",
		ThisIsImportantAsYouMayBeRequiredToPurchaseMoreLicensesPleaseContactSupportIfYouHaveAnyQuestions:
			"This is important as you may be required to purchase more licenses. Please contact support if you have any questions."
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
		}
	}, [userLanguage, pageText]);

	const url = process.env.REACT_APP_BACKEND_STATIC_URL;

	const [isVisible, setIsVisible] = useState(true);
	const [exceededLimit, setExceededLimit] = useState(false);
	const [numOfUsers, setNumOfUsers] = useState(0);
	const [numOfLicenses, setNumOfLicenses] = useState(0);
	const licenseCheckFlag = useFeatureFlagEnabled("license-check");
	const [metadata, setMetadata] = useState({});

	const CompareUniqueUsersToLicenses = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/users/CompareUniqueUsersToLicenses`, 1, {
				params: {
					start_date: metadata.licenseStartDate,
					end_date: new Date().toISOString().split("T")[0]
				},
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});
			if (!response.data.maxLicenses) {
				return;
			}
			const { hasAvailableLicenses, maxLicenses, currentUsers } = response.data;
			setNumOfUsers(currentUsers);
			setNumOfLicenses(maxLicenses);
			if (!hasAvailableLicenses) {
				setExceededLimit(true);
			}
			return hasAvailableLicenses; // This can be used to decide further actions in the UI
		} catch (err) {
			console.error("Error fetching license data:", err);
		}
	};

	const getMetadata = async () => {
		try {
			const response = await axiosRetryGet(`${url}/api/users/GetClientMetaData`, 1, {
				headers: { Authorization: `Bearer ${localUser.accessToken}` }
			});
			setMetadata(response.data.clientMetadata[0].metadata);
			console.log("Metadata", response.data.clientMetadata[0].metadata);
		} catch (err) {
			console.error("Error fetching metadata:", err);
		}
	};

	useEffect(() => {
		getMetadata();
	}, []);

	useEffect(() => {
		if (licenseCheckFlag && metadata && metadata.licenseStartDate) {
			CompareUniqueUsersToLicenses();
			// console.log("Roles ", localUser.roles);
		}
	}, [licenseCheckFlag, metadata]);

	return (
		<>
			{isVisible && exceededLimit && localUser.roles[0].name !== "Member" && (
				<span className={styles.overLimitBanner}>
					<div className={styles.topLimitBannerBar}>
						<p className={styles.overLimitBannerTitle}>{pageText.YouHaveExceededYourAccountLimit}</p>
						<IoClose title="Close icon" className={styles.overLimitCloseBtn} onClick={() => setIsVisible(false)} />
					</div>
					<p className={styles.userCount}>
						{numOfUsers}/{numOfLicenses}
					</p>
					<p className={styles.overLimitSmallText}>
						{pageText.ThisIsImportantAsYouMayBeRequiredToPurchaseMoreLicensesPleaseContactSupportIfYouHaveAnyQuestions}
					</p>
				</span>
			)}
		</>
	);
}

export default OverLimitBanner;
