export async function GetMedia(userMediaStreamRef, videoOn, setVideoOn) {
	console.log("getMEDIA OLD", videoOn);

	try {
		const mediaStream = await navigator.mediaDevices.getUserMedia({
			audio: {
				// ...additionalAudioConstraints,
				channelCount: 1,
				echoCancellation: true,
				autoGainControl: true,
				noiseSuppression: true
			},
			video: videoOn
		});
		userMediaStreamRef.current = mediaStream;
		// setStream(mediaStream);
	} catch (error) {
		if (videoOn) {
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				audio: {
					// ...additionalAudioConstraints,
					channelCount: 1,
					echoCancellation: true,
					autoGainControl: true,
					noiseSuppression: true
				},
				video: false
			});
			setVideoOn(false);
			userMediaStreamRef.current = mediaStream;
		}
		console.error("Error accessing media devices.", error);
	}
}

export async function GetScreen() {
	try {
		const mediaStream = await navigator.mediaDevices.getDisplayMedia({
			video: true,
			audio: true,
			preferCurrentTab: true,
			systemAudio: "include"
		});
		return mediaStream;
	} catch (error) {
		console.error("Error accessing media devices.", error);
	}
}
const requestMicAccess = async () => {
	try {
		const constraints = { audio: true };
		const stream = await navigator.mediaDevices.getUserMedia(constraints);
		// Stop the stream after checking permission
		stream.getTracks().forEach((track) => track.stop());
		return true;
	} catch (err) {
		console.log("Access denied");
		return false;
	}
};
const requestVideoAccess = async () => {
	try {
		const constraints = { video: true };
		const stream = await navigator.mediaDevices.getUserMedia(constraints);
		// Stop the stream after checking permission
		stream.getTracks().forEach((track) => track.stop());
		return true;
	} catch (err) {
		console.log("Access to camera denied");
		return false;
	}
};
export async function CheckPermissions(askForVideo = false) {
	try {
		let micAccess = false;
		let videoAccess = false;
		try {
			if (/Firefox/i.test(navigator.userAgent)) {
				// Firefox-specific permission checking
				try {
					const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
					micAccess = true; // Permission granted
					audioStream.getTracks().forEach((track) => track.stop());
				} catch (err) {
					alert("Microphone access is required. Please update your browser settings to allow microphone access and refresh the page.");
				}

				if (askForVideo) {
					try {
						const videoStream = await navigator.mediaDevices.getUserMedia({ video: true });
						videoAccess = true; // Permission granted
						videoStream.getTracks().forEach((track) => track.stop());
					} catch (err) {
						alert(
							"Camera access is required for video. Please update your browser settings to allow camera access and refresh the page."
						);
					}
				}
			} else {
				// Chrome and other browser's permission checking
				const micPermissionStatus = await navigator.permissions.query({ name: "microphone" });
				if (micPermissionStatus.state === "granted") {
					micAccess = true;
				} else if (micPermissionStatus.state === "prompt") {
					micAccess = await requestMicAccess();
				} else if (micPermissionStatus.state === "denied") {
					alert("Microphone access is required. Please update your browser settings to allow microphone access and refresh the page.");
				}

				if (askForVideo) {
					const videoPermissionStatus = await navigator.permissions.query({ name: "camera" });
					if (videoPermissionStatus.state === "granted") {
						videoAccess = true;
					} else if (videoPermissionStatus.state === "prompt") {
						videoAccess = await requestVideoAccess();
					} else if (videoPermissionStatus.state === "denied") {
						alert(
							"Camera access is required for video. Please update your browser settings to allow camera access and refresh the page."
						);
					}
				}
			}
		} catch (error) {
			console.error(true);
		}

		return { micAccess, videoAccess };
	} catch (err) {
		console.error("Error checking permissions:", err);
		return { micAccess: false, videoAccess: false };
	}
}
