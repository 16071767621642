import { useState, useCallback, useEffect } from "react";
import Vapi from "@vapi-ai/web";

export const useVapiCall = () => {
	const [vapi, setVapi] = useState(null);
	const [message, setMessage] = useState("");
	const [isCallActive, setIsCallActive] = useState(false);

	const startCall = useCallback(() => {
		const vapiInstance = new Vapi(process.env.REACT_APP_VAPI_KEY);
		vapiInstance.start("2e804640-f50a-49ec-a3d5-dca053cc3509");

		// Event listeners
		vapiInstance.on("speech-start", (data) => {
			console.log("Speech has started", data);
		});

		vapiInstance.on("speech-end", (data) => {
			console.log("Speech has ended", data);
		});

		vapiInstance.on("call-start", (data) => {
			console.log("Call has started", data);
			setIsCallActive(true);
		});

		vapiInstance.on("call-end", (data) => {
			console.log("Call has stopped", data);
			setIsCallActive(false);
		});

		vapiInstance.on("volume-level", (volume) => {
			//	console.log(`Assistant volume level: ${volume}`);
		});

		vapiInstance.on("message", (m_message) => {
			if (m_message.type === "transcript") {
				setMessage(m_message.transcript);
			} else {
				console.log(m_message);
			}
		});

		vapiInstance.on("error", (e) => {
			console.error(e);
		});

		setVapi(vapiInstance);
	}, []);

	const sendMessage = useCallback(
		(newMessage) => {
			if (vapi) {
				vapi.send({
					type: "add-message",
					message: newMessage
				});
			}
		},
		[vapi]
	);

	const stopCall = useCallback(() => {
		if (vapi) {
			vapi.stop();
		}
	}, [vapi]);

	const toggleMute = useCallback(
		(muted) => {
			if (vapi) {
				vapi.setMuted(muted);
			}
		},
		[vapi]
	);

	const isMuted = useCallback(() => vapi?.isMuted() ?? false, [vapi]);

	// Cleanup event listeners on unmount
	useEffect(() => {
		return () => {
			if (vapi) {
				vapi.removeAllListeners();
			}
		};
	}, [vapi]);

	return {
		startCall,
		sendMessage,
		message,
		stopCall,
		toggleMute,
		isMuted,
		isCallActive
	};
};
