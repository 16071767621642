import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Canvas, useFrame } from "@react-three/fiber";
import { useProgress, PerspectiveCamera, View, OrbitControls, CameraControls, TransformControls } from "@react-three/drei";
import styles from "../../styleModules/adminSystemStyles.module.css";

import s from "../../styleModules/setupStyles.module.css";
import Office from "./Personal_office.jsx";
import { Character } from "./Character.js";
import ToneMapping from "./ToneMapping.js";
import { characterDict } from "./characterDict.js";
import TypingText from "../UtilityFunctions/TypingText.js";
import HDR_SETUP from "./HDR_Setup.js";
import { useSpeechSynthesizer } from "../SecondaryComponents/useSpeechSynthesizer";
import { sleep } from "../UtilityFunctions/sleep.js";

const visemeObject = {
	0: { sounds: ["Silence"], description: "The mouth position when viseme ID is 0" },
	1: { sounds: ["æ", "ə", "ʌ"], description: "The mouth position when viseme ID is 1" },
	2: { sounds: ["ɑ"], description: "The mouth position when viseme ID is 2" },
	3: { sounds: ["ɔ"], description: "The mouth position when viseme ID is 3" },
	4: { sounds: ["ɛ", "ʊ"], description: "The mouth position when viseme ID is 4" },
	5: { sounds: ["ɝ"], description: "The mouth position when viseme ID is 5" },
	6: { sounds: ["j", "i", "ɪ"], description: "The mouth position when viseme ID is 6" },
	7: { sounds: ["w", "u"], description: "The mouth position when viseme ID is 7" },
	8: { sounds: ["o"], description: "The mouth position when viseme ID is 8" },
	9: { sounds: ["aʊ"], description: "The mouth position when viseme ID is 9" },
	10: { sounds: ["ɔɪ"], description: "The mouth position when viseme ID is 10" },
	11: { sounds: ["aɪ"], description: "The mouth position when viseme ID is 11" },
	12: { sounds: ["h"], description: "The mouth position when viseme ID is 12" },
	13: { sounds: ["ɹ"], description: "The mouth position when viseme ID is 13" },
	14: { sounds: ["l"], description: "The mouth position when viseme ID is 14" },
	15: { sounds: ["s", "z"], description: "The mouth position when viseme ID is 15" },
	16: { sounds: ["ʃ", "tʃ", "dʒ", "ʒ"], description: "The mouth position when viseme ID is 16" },
	17: { sounds: ["ð"], description: "The mouth position when viseme ID is 17" },
	18: { sounds: ["f", "v"], description: "The mouth position when viseme ID is 18" },
	19: { sounds: ["d", "t", "n", "θ"], description: "The mouth position when viseme ID is 19" },
	20: { sounds: ["k", "g", "ŋ"], description: "The mouth position when viseme ID is 20" },
	21: { sounds: ["p", "b", "m"], description: "The mouth position when viseme ID is 21" }
};
const soundToVisemeMapping = {
	Silence: "SIL",
	æ: "A_E",
	ə: "A_E",
	ʌ: "A_E",
	ɑ: "Ah",
	ɔ: "Oh",
	ɛ: "EE",
	ʊ: "W_OO",
	ɝ: "Er",
	j: "EE",
	i: "EE",
	ɪ: "Ih",
	w: "W_OO",
	u: "W_OO",
	o: "Oh",
	aʊ: "W_OO",
	ɔɪ: "Oh",
	aɪ: "A_E",
	h: "SIL",
	ɹ: "R",
	l: "T_L_D",
	s: "S_Z",
	z: "S_Z",
	ʃ: "S_Z",
	tʃ: "CH_J",
	dʒ: "CH_J",
	ʒ: "S_Z",
	ð: "Th",
	f: "F_V",
	v: "F_V",
	d: "T_L_D",
	t: "T_L_D",
	n: "T_L_D",
	θ: "Th",
	k: "K_G",
	g: "K_G",
	ŋ: "K_G",
	p: "B_M_P",
	b: "B_M_P",
	m: "B_M_P"
};
const sampleData = [
	{
		file_name: "output0.wav",
		produced_audioOffset: 0,
		sample_audioOffset: 50,
		produced_visemeId: 20,
		sample_visemeId: 0,
		correct_visemeId: "False",
		produced_visemeMapping: "K_G",
		sample_visemeMapping: "SIL",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 160,
		sample_audioOffset: 100,
		produced_visemeId: 13,
		sample_visemeId: 20,
		correct_visemeId: "False",
		produced_visemeMapping: "R",
		sample_visemeMapping: "K_G",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 180,
		sample_audioOffset: 187.5,
		produced_visemeId: 1,
		sample_visemeId: 13,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "R",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 220,
		sample_audioOffset: 250,
		produced_visemeId: 19,
		sample_visemeId: 4,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 360,
		sample_audioOffset: 293.75,
		produced_visemeId: 19,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 420,
		sample_audioOffset: 337.5,
		produced_visemeId: 1,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 500,
		sample_audioOffset: 387.5,
		produced_visemeId: 21,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "B_M_P",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 560,
		sample_audioOffset: 462.5,
		produced_visemeId: 6,
		sample_visemeId: 4,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "True"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 660,
		sample_audioOffset: 512.5,
		produced_visemeId: 19,
		sample_visemeId: 21,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "B_M_P",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 720,
		sample_audioOffset: 587.5,
		produced_visemeId: 6,
		sample_visemeId: 6,
		correct_visemeId: "True",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "True"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 800,
		sample_audioOffset: 700,
		produced_visemeId: 7,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "W_OO",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 1940,
		sample_audioOffset: 737.5,
		produced_visemeId: 19,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2020,
		sample_audioOffset: 825,
		produced_visemeId: 4,
		sample_visemeId: 7,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "W_OO",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2080,
		sample_audioOffset: 1075,
		produced_visemeId: 14,
		sample_visemeId: 0,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "SIL",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2160,
		sample_audioOffset: 1837.5,
		produced_visemeId: 1,
		sample_visemeId: 0,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "SIL",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2220,
		sample_audioOffset: 1887.5,
		produced_visemeId: 15,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2300,
		sample_audioOffset: 2000,
		produced_visemeId: 7,
		sample_visemeId: 4,
		correct_visemeId: "False",
		produced_visemeMapping: "W_OO",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2360,
		sample_audioOffset: 2075,
		produced_visemeId: 1,
		sample_visemeId: 14,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2400,
		sample_audioOffset: 2137.5,
		produced_visemeId: 19,
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2460,
		sample_audioOffset: 2200,
		produced_visemeId: 6,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2520,
		sample_audioOffset: 2287.5,
		produced_visemeId: 20,
		sample_visemeId: 7,
		correct_visemeId: "False",
		produced_visemeMapping: "K_G",
		sample_visemeMapping: "W_OO",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2620,
		sample_audioOffset: 2337.5,
		produced_visemeId: 15,
		sample_visemeId: 2,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "Ah",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2640,
		sample_audioOffset: 2387.5,
		produced_visemeId: 11,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2700,
		sample_audioOffset: 2437.5,
		produced_visemeId: 19,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2860,
		sample_audioOffset: 2500,
		produced_visemeId: 16,
		sample_visemeId: 20,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "K_G",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2880,
		sample_audioOffset: 2550,
		produced_visemeId: 6,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 2940,
		sample_audioOffset: 2637.5,
		produced_visemeId: 7,
		sample_visemeId: 11,
		correct_visemeId: "False",
		produced_visemeMapping: "W_OO",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3020,
		sample_audioOffset: 2750,
		produced_visemeId: 1,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3080,
		sample_audioOffset: 2825,
		produced_visemeId: 21,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "B_M_P",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3120,
		sample_audioOffset: 2875,
		produced_visemeId: 9,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "W_OO",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3160,
		sample_audioOffset: 2937.5,
		produced_visemeId: 19,
		sample_visemeId: 7,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "W_OO",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3260,
		sample_audioOffset: 2987.5,
		produced_visemeId: 17,
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "Th",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3300,
		sample_audioOffset: 3050,
		produced_visemeId: 6,
		sample_visemeId: 21,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "B_M_P",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3380,
		sample_audioOffset: 3100,
		produced_visemeId: 15,
		sample_visemeId: 9,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "W_OO",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3440,
		sample_audioOffset: 3175,
		produced_visemeId: 2,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "Ah",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3520,
		sample_audioOffset: 3237.5,
		produced_visemeId: 21,
		sample_visemeId: 17,
		correct_visemeId: "False",
		produced_visemeMapping: "B_M_P",
		sample_visemeMapping: "Th",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3600,
		sample_audioOffset: 3287.5,
		produced_visemeId: 5,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "Er",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3660,
		sample_audioOffset: 3337.5,
		produced_visemeId: 19,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3740,
		sample_audioOffset: 3425,
		produced_visemeId: 7,
		sample_visemeId: 2,
		correct_visemeId: "False",
		produced_visemeMapping: "W_OO",
		sample_visemeMapping: "Ah",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3800,
		sample_audioOffset: 3500,
		produced_visemeId: 19,
		sample_visemeId: 21,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "B_M_P",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3860,
		sample_audioOffset: 3550,
		produced_visemeId: 1,
		sample_visemeId: 1,
		correct_visemeId: "True",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "True"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3920,
		sample_audioOffset: 3587.5,
		produced_visemeId: 19,
		sample_visemeId: 13,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "R",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: 3980,
		sample_audioOffset: 3637.5,
		produced_visemeId: 6,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: "",
		sample_audioOffset: 3725,
		produced_visemeId: "",
		sample_visemeId: 7,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "W_OO",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: "",
		sample_audioOffset: 3800,
		produced_visemeId: "",
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: "",
		sample_audioOffset: 3850,
		produced_visemeId: "",
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: "",
		sample_audioOffset: 3912.5,
		produced_visemeId: "",
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: "",
		sample_audioOffset: 3950,
		produced_visemeId: "",
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output0.wav",
		produced_audioOffset: "",
		sample_audioOffset: 4187.5,
		produced_visemeId: "",
		sample_visemeId: 0,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "SIL",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 0,
		sample_audioOffset: 50,
		produced_visemeId: 6,
		sample_visemeId: 0,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "SIL",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 160,
		sample_audioOffset: 100,
		produced_visemeId: 4,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "W_OO",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 220,
		sample_audioOffset: 212.5,
		produced_visemeId: 13,
		sample_visemeId: 3,
		correct_visemeId: "False",
		produced_visemeMapping: "R",
		sample_visemeMapping: "Oh",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 280,
		sample_audioOffset: 262.5,
		produced_visemeId: 6,
		sample_visemeId: 13,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "R",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 320,
		sample_audioOffset: 300,
		produced_visemeId: 20,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "K_G",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 380,
		sample_audioOffset: 362.5,
		produced_visemeId: 15,
		sample_visemeId: 20,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "K_G",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 460,
		sample_audioOffset: 412.5,
		produced_visemeId: 21,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "B_M_P",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 520,
		sample_audioOffset: 487.5,
		produced_visemeId: 6,
		sample_visemeId: 21,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "B_M_P",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 580,
		sample_audioOffset: 550,
		produced_visemeId: 13,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "R",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 640,
		sample_audioOffset: 625,
		produced_visemeId: 6,
		sample_visemeId: 13,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "R",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 700,
		sample_audioOffset: 675,
		produced_visemeId: 1,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 740,
		sample_audioOffset: 750,
		produced_visemeId: 19,
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 800,
		sample_audioOffset: 800,
		produced_visemeId: 15,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 860,
		sample_audioOffset: 850,
		produced_visemeId: 7,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "W_OO",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 900,
		sample_audioOffset: 900,
		produced_visemeId: 6,
		sample_visemeId: 7,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "W_OO",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 960,
		sample_audioOffset: 950,
		produced_visemeId: 19,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "Th",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1020,
		sample_audioOffset: 987.5,
		produced_visemeId: 1,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1080,
		sample_audioOffset: 1037.5,
		produced_visemeId: 15,
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1160,
		sample_audioOffset: 1100,
		produced_visemeId: 21,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "B_M_P",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1220,
		sample_audioOffset: 1175,
		produced_visemeId: 6,
		sample_visemeId: 21,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "B_M_P",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1280,
		sample_audioOffset: 1225,
		produced_visemeId: 15,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1360,
		sample_audioOffset: 1287.5,
		produced_visemeId: 6,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1420,
		sample_audioOffset: 1387.5,
		produced_visemeId: 18,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "F_V",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1480,
		sample_audioOffset: 1450,
		produced_visemeId: 6,
		sample_visemeId: 18,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "F_V",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1560,
		sample_audioOffset: 1512.5,
		produced_visemeId: 20,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "K_G",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1640,
		sample_audioOffset: 1587.5,
		produced_visemeId: 15,
		sample_visemeId: 20,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "K_G",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1740,
		sample_audioOffset: 1650,
		produced_visemeId: 20,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "K_G",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1820,
		sample_audioOffset: 1750,
		produced_visemeId: 6,
		sample_visemeId: 20,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "K_G",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 1960,
		sample_audioOffset: 1812.5,
		produced_visemeId: 14,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2160,
		sample_audioOffset: 1987.5,
		produced_visemeId: 6,
		sample_visemeId: 14,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2240,
		sample_audioOffset: 2112,
		produced_visemeId: 15,
		sample_visemeId: 0,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "SIL",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2320,
		sample_audioOffset: 2200,
		produced_visemeId: 6,
		sample_visemeId: 6,
		correct_visemeId: "True",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2360,
		sample_audioOffset: 2275,
		produced_visemeId: 21,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "B_M_P",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2400,
		sample_audioOffset: 2337.5,
		produced_visemeId: 21,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "B_M_P",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2460,
		sample_audioOffset: 2387.5,
		produced_visemeId: 13,
		sample_visemeId: 21,
		correct_visemeId: "False",
		produced_visemeMapping: "R",
		sample_visemeMapping: "B_M_P",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2540,
		sample_audioOffset: 2437.5,
		produced_visemeId: 4,
		sample_visemeId: 21,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "B_M_P",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2620,
		sample_audioOffset: 2500,
		produced_visemeId: 15,
		sample_visemeId: 13,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "R",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2720,
		sample_audioOffset: 2562.5,
		produced_visemeId: 6,
		sample_visemeId: 4,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 2860,
		sample_audioOffset: 2625,
		produced_visemeId: 18,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "F_V",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 3900,
		sample_audioOffset: 2725,
		produced_visemeId: 20,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "K_G",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 3960,
		sample_audioOffset: 2875,
		produced_visemeId: 1,
		sample_visemeId: 18,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "F_V",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4020,
		sample_audioOffset: 3050,
		produced_visemeId: 19,
		sample_visemeId: 0,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "SIL",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4080,
		sample_audioOffset: 3812.5,
		produced_visemeId: 6,
		sample_visemeId: 0,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "SIL",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4140,
		sample_audioOffset: 3862.5,
		produced_visemeId: 7,
		sample_visemeId: 20,
		correct_visemeId: "False",
		produced_visemeMapping: "W_OO",
		sample_visemeMapping: "K_G",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4220,
		sample_audioOffset: 3950,
		produced_visemeId: 16,
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4300,
		sample_audioOffset: 4000,
		produced_visemeId: 4,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4360,
		sample_audioOffset: 4050,
		produced_visemeId: 13,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "R",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4500,
		sample_audioOffset: 4100,
		produced_visemeId: 15,
		sample_visemeId: 7,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "W_OO",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4580,
		sample_audioOffset: 4162.5,
		produced_visemeId: 21,
		sample_visemeId: 16,
		correct_visemeId: "False",
		produced_visemeMapping: "B_M_P",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4640,
		sample_audioOffset: 4287.5,
		produced_visemeId: 6,
		sample_visemeId: 4,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4700,
		sample_audioOffset: 4362.5,
		produced_visemeId: 15,
		sample_visemeId: 13,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "R",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4780,
		sample_audioOffset: 4412.5,
		produced_visemeId: 6,
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4840,
		sample_audioOffset: 4475,
		produced_visemeId: 18,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "F_V",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4880,
		sample_audioOffset: 4550,
		produced_visemeId: 6,
		sample_visemeId: 21,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "B_M_P",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 4960,
		sample_audioOffset: 4600,
		produced_visemeId: 20,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "K_G",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5020,
		sample_audioOffset: 4662.5,
		produced_visemeId: 4,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5060,
		sample_audioOffset: 4750,
		produced_visemeId: 19,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5140,
		sample_audioOffset: 4812.5,
		produced_visemeId: 15,
		sample_visemeId: 18,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "F_V",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5200,
		sample_audioOffset: 4862.5,
		produced_visemeId: 19,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5260,
		sample_audioOffset: 4925,
		produced_visemeId: 4,
		sample_visemeId: 20,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "K_G",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5320,
		sample_audioOffset: 4987.5,
		produced_visemeId: 19,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5400,
		sample_audioOffset: 5062.5,
		produced_visemeId: 15,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5480,
		sample_audioOffset: 5137.5,
		produced_visemeId: 7,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "W_OO",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5520,
		sample_audioOffset: 5187.5,
		produced_visemeId: 4,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "EE",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5580,
		sample_audioOffset: 5237.5,
		produced_visemeId: 13,
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "R",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5620,
		sample_audioOffset: 5312.5,
		produced_visemeId: 6,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5660,
		sample_audioOffset: 5387.5,
		produced_visemeId: 19,
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5740,
		sample_audioOffset: 5450,
		produced_visemeId: 21,
		sample_visemeId: 7,
		correct_visemeId: "False",
		produced_visemeMapping: "B_M_P",
		sample_visemeMapping: "W_OO",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5760,
		sample_audioOffset: 5512.5,
		produced_visemeId: 1,
		sample_visemeId: 4,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5780,
		sample_audioOffset: 5562.5,
		produced_visemeId: 19,
		sample_visemeId: 13,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "R",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 5920,
		sample_audioOffset: 5612.5,
		produced_visemeId: 1,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 6000,
		sample_audioOffset: 5662.5,
		produced_visemeId: 19,
		sample_visemeId: 19,
		correct_visemeId: "True",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "True"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 6060,
		sample_audioOffset: 5700,
		produced_visemeId: 6,
		sample_visemeId: 21,
		correct_visemeId: "False",
		produced_visemeMapping: "Ih",
		sample_visemeMapping: "B_M_P",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 6120,
		sample_audioOffset: 5762.5,
		produced_visemeId: 18,
		sample_visemeId: 4,
		correct_visemeId: "False",
		produced_visemeMapping: "F_V",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 6180,
		sample_audioOffset: 5806.25,
		produced_visemeId: 13,
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "R",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 6240,
		sample_audioOffset: 5850,
		produced_visemeId: 1,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "A_E",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 6300,
		sample_audioOffset: 5900,
		produced_visemeId: 19,
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "T_L_D",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: 6440,
		sample_audioOffset: 5975,
		produced_visemeId: 15,
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "S_Z",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: "",
		sample_audioOffset: 6037.5,
		produced_visemeId: "",
		sample_visemeId: 6,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "EE",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: "",
		sample_audioOffset: 6100,
		produced_visemeId: "",
		sample_visemeId: 18,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "F_V",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: "",
		sample_audioOffset: 6162.5,
		produced_visemeId: "",
		sample_visemeId: 13,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "R",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: "",
		sample_audioOffset: 6212.5,
		produced_visemeId: "",
		sample_visemeId: 1,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "A_E",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: "",
		sample_audioOffset: 6300,
		produced_visemeId: "",
		sample_visemeId: 19,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "T_L_D",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: "",
		sample_audioOffset: 6400,
		produced_visemeId: "",
		sample_visemeId: 15,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "S_Z",
		correct_visemeMapping: "False"
	},
	{
		file_name: "output1.wav",
		produced_audioOffset: "",
		sample_audioOffset: 6599.5,
		produced_visemeId: "",
		sample_visemeId: 0,
		correct_visemeId: "False",
		produced_visemeMapping: "",
		sample_visemeMapping: "SIL",
		correct_visemeMapping: "False"
	}
];

const sampleData2 = [
	{
		audioOffset: 120,
		visemeId: 12
	},
	{
		audioOffset: 193,
		visemeId: 1
	},
	{
		audioOffset: 266,
		visemeId: 6
	},
	{
		audioOffset: 340,
		visemeId: 17
	},
	{
		audioOffset: 453,
		visemeId: 4
	},
	{
		audioOffset: 566,
		visemeId: 13
	},
	{
		audioOffset: 1080,
		visemeId: 11
	},
	{
		audioOffset: 1129,
		visemeId: 21
	},
	{
		audioOffset: 1180,
		visemeId: 19
	},
	{
		audioOffset: 1233,
		visemeId: 1
	},
	{
		audioOffset: 1286,
		visemeId: 6
	},
	{
		audioOffset: 1339,
		visemeId: 18
	},
	{
		audioOffset: 1392,
		visemeId: 6
	},
	{
		audioOffset: 1445,
		visemeId: 19
	},
	{
		audioOffset: 1720,
		visemeId: 6
	},
	{
		audioOffset: 1733,
		visemeId: 4
	},
	{
		audioOffset: 1746,
		visemeId: 13
	},
	{
		audioOffset: 1760,
		visemeId: 18
	},
	{
		audioOffset: 1808,
		visemeId: 13
	},
	{
		audioOffset: 1856,
		visemeId: 4
	},
	{
		audioOffset: 1904,
		visemeId: 19
	},
	{
		audioOffset: 1952,
		visemeId: 19
	},
	{
		audioOffset: 2000,
		visemeId: 14
	},
	{
		audioOffset: 2048,
		visemeId: 6
	},
	{
		audioOffset: 2100,
		visemeId: 1
	},
	{
		audioOffset: 2159,
		visemeId: 6
	},
	{
		audioOffset: 2218,
		visemeId: 19
	},
	{
		audioOffset: 2277,
		visemeId: 16
	},
	{
		audioOffset: 2336,
		visemeId: 2
	},
	{
		audioOffset: 2395,
		visemeId: 13
	},
	{
		audioOffset: 2460,
		visemeId: 13
	},
	{
		audioOffset: 2513,
		visemeId: 4
	},
	{
		audioOffset: 2566,
		visemeId: 21
	},
	{
		audioOffset: 2619,
		visemeId: 13
	},
	{
		audioOffset: 2672,
		visemeId: 15
	},
	{
		audioOffset: 2725,
		visemeId: 4
	},
	{
		audioOffset: 2778,
		visemeId: 19
	},
	{
		audioOffset: 2831,
		visemeId: 19
	},
	{
		audioOffset: 2884,
		visemeId: 1
	},
	{
		audioOffset: 2937,
		visemeId: 19
	},
	{
		audioOffset: 2990,
		visemeId: 6
	},
	{
		audioOffset: 3043,
		visemeId: 18
	},
	{
		audioOffset: 3100,
		visemeId: 1
	},
	{
		audioOffset: 3319,
		visemeId: 19
	},
	{
		audioOffset: 3538,
		visemeId: 19
	},
	{
		audioOffset: 3760,
		visemeId: 11
	},
	{
		audioOffset: 3889,
		visemeId: 21
	},
	{
		audioOffset: 4019.9999999999995,
		visemeId: 12
	},
	{
		audioOffset: 4052.9999999999995,
		visemeId: 6
	},
	{
		audioOffset: 4085.9999999999995,
		visemeId: 13
	},
	{
		audioOffset: 4120,
		visemeId: 19
	},
	{
		audioOffset: 4169,
		visemeId: 7
	},
	{
		audioOffset: 4220,
		visemeId: 21
	},
	{
		audioOffset: 4275,
		visemeId: 1
	},
	{
		audioOffset: 4330,
		visemeId: 6
	},
	{
		audioOffset: 4385,
		visemeId: 20
	},
	{
		audioOffset: 4440,
		visemeId: 16
	},
	{
		audioOffset: 4506,
		visemeId: 4
	},
	{
		audioOffset: 4572,
		visemeId: 13
	},
	{
		audioOffset: 4640,
		visemeId: 6
	},
	{
		audioOffset: 4693,
		visemeId: 4
	},
	{
		audioOffset: 4746,
		visemeId: 13
	},
	{
		audioOffset: 4800,
		visemeId: 18
	},
	{
		audioOffset: 4913,
		visemeId: 15
	},
	{
		audioOffset: 5026,
		visemeId: 19
	},
	{
		audioOffset: 5140,
		visemeId: 19
	},
	{
		audioOffset: 5206,
		visemeId: 1
	},
	{
		audioOffset: 5272,
		visemeId: 6
	},
	{
		audioOffset: 5340,
		visemeId: 6
	},
	{
		audioOffset: 5440,
		visemeId: 15
	},
	{
		audioOffset: 5540,
		visemeId: 1
	},
	{
		audioOffset: 5609,
		visemeId: 15
	},
	{
		audioOffset: 5680,
		visemeId: 15
	},
	{
		audioOffset: 5730,
		visemeId: 21
	},
	{
		audioOffset: 5780,
		visemeId: 7
	},
	{
		audioOffset: 5830,
		visemeId: 17
	},
	{
		audioOffset: 5880,
		visemeId: 1
	},
	{
		audioOffset: 6029,
		visemeId: 15
	},
	{
		audioOffset: 6180,
		visemeId: 1
	},
	{
		audioOffset: 6240,
		visemeId: 6
	},
	{
		audioOffset: 6300,
		visemeId: 7
	},
	{
		audioOffset: 6367,
		visemeId: 6
	},
	{
		audioOffset: 6434,
		visemeId: 15
	},
	{
		audioOffset: 6501,
		visemeId: 1
	},
	{
		audioOffset: 6568,
		visemeId: 14
	},
	{
		audioOffset: 6700,
		visemeId: 11
	},
	{
		audioOffset: 6979,
		visemeId: 21
	},
	{
		audioOffset: 7260,
		visemeId: 13
	},
	{
		audioOffset: 7304,
		visemeId: 6
	},
	{
		audioOffset: 7348,
		visemeId: 1
	},
	{
		audioOffset: 7392,
		visemeId: 14
	},
	{
		audioOffset: 7436,
		visemeId: 6
	},
	{
		audioOffset: 7480,
		visemeId: 4
	},
	{
		audioOffset: 7563,
		visemeId: 20
	},
	{
		audioOffset: 7646,
		visemeId: 15
	},
	{
		audioOffset: 7729,
		visemeId: 11
	},
	{
		audioOffset: 7812,
		visemeId: 19
	},
	{
		audioOffset: 7900,
		visemeId: 19
	},
	{
		audioOffset: 7999,
		visemeId: 7
	},
	{
		audioOffset: 8100,
		visemeId: 21
	},
	{
		audioOffset: 8159,
		visemeId: 6
	},
	{
		audioOffset: 8218,
		visemeId: 19
	},
	{
		audioOffset: 8280,
		visemeId: 6
	},
	{
		audioOffset: 8380,
		visemeId: 7
	},
	{
		audioOffset: 8480,
		visemeId: 1
	},
	{
		audioOffset: 8526,
		visemeId: 19
	},
	{
		audioOffset: 8572,
		visemeId: 19
	},
	{
		audioOffset: 8620,
		visemeId: 20
	},
	{
		audioOffset: 8680,
		visemeId: 1
	},
	{
		audioOffset: 8740,
		visemeId: 19
	},
	{
		audioOffset: 8800,
		visemeId: 19
	},
	{
		audioOffset: 8860,
		visemeId: 7
	},
	{
		audioOffset: 8904,
		visemeId: 1
	},
	{
		audioOffset: 8948,
		visemeId: 6
	},
	{
		audioOffset: 8992,
		visemeId: 19
	},
	{
		audioOffset: 9040,
		visemeId: 19
	},
	{
		audioOffset: 9120,
		visemeId: 7
	},
	{
		audioOffset: 9200,
		visemeId: 12
	},
	{
		audioOffset: 9253,
		visemeId: 6
	},
	{
		audioOffset: 9306,
		visemeId: 13
	},
	{
		audioOffset: 9360,
		visemeId: 3
	},
	{
		audioOffset: 9449,
		visemeId: 14
	},
	{
		audioOffset: 9540,
		visemeId: 21
	},
	{
		audioOffset: 9600,
		visemeId: 9
	},
	{
		audioOffset: 9660,
		visemeId: 19
	},
	{
		audioOffset: 9720,
		visemeId: 6
	},
	{
		audioOffset: 9776,
		visemeId: 4
	},
	{
		audioOffset: 9832,
		visemeId: 13
	},
	{
		audioOffset: 9890,
		visemeId: 13
	},
	{
		audioOffset: 9952,
		visemeId: 8
	},
	{
		audioOffset: 10014,
		visemeId: 4
	},
	{
		audioOffset: 10076,
		visemeId: 14
	},
	{
		audioOffset: 10140,
		visemeId: 1
	},
	{
		audioOffset: 10209,
		visemeId: 15
	},
	{
		audioOffset: 10280,
		visemeId: 1
	},
	{
		audioOffset: 10390,
		visemeId: 6
	},
	{
		audioOffset: 10500,
		visemeId: 21
	},
	{
		audioOffset: 10534,
		visemeId: 2
	},
	{
		audioOffset: 10568,
		visemeId: 13
	},
	{
		audioOffset: 10602,
		visemeId: 20
	},
	{
		audioOffset: 10636,
		visemeId: 6
	},
	{
		audioOffset: 10670,
		visemeId: 6
	},
	{
		audioOffset: 10704,
		visemeId: 20
	},
	{
		audioOffset: 10740,
		visemeId: 20
	},
	{
		audioOffset: 10806,
		visemeId: 8
	},
	{
		audioOffset: 10872,
		visemeId: 4
	},
	{
		audioOffset: 10938,
		visemeId: 3
	},
	{
		audioOffset: 11004,
		visemeId: 13
	},
	{
		audioOffset: 11070,
		visemeId: 19
	},
	{
		audioOffset: 11136,
		visemeId: 19
	},
	{
		audioOffset: 11202,
		visemeId: 1
	},
	{
		audioOffset: 11268,
		visemeId: 6
	},
	{
		audioOffset: 11340,
		visemeId: 15
	},
	{
		audioOffset: 11690,
		visemeId: 1
	},
	{
		audioOffset: 12040,
		visemeId: 19
	},
	{
		audioOffset: 12390,
		visemeId: 16
	},
	{
		audioOffset: 12740,
		visemeId: 1
	},
	{
		audioOffset: 12850,
		visemeId: 6
	},
	{
		audioOffset: 12960,
		visemeId: 20
	},
	{
		audioOffset: 12999,
		visemeId: 13
	},
	{
		audioOffset: 13038,
		visemeId: 6
	},
	{
		audioOffset: 13077,
		visemeId: 1
	},
	{
		audioOffset: 13116,
		visemeId: 6
	},
	{
		audioOffset: 13155,
		visemeId: 6
	},
	{
		audioOffset: 13194,
		visemeId: 18
	},
	{
		audioOffset: 13240,
		visemeId: 21
	},
	{
		audioOffset: 13322,
		visemeId: 1
	},
	{
		audioOffset: 13404,
		visemeId: 15
	},
	{
		audioOffset: 13486,
		visemeId: 6
	},
	{
		audioOffset: 13568,
		visemeId: 16
	},
	{
		audioOffset: 13650,
		visemeId: 1
	},
	{
		audioOffset: 13732,
		visemeId: 19
	},
	{
		audioOffset: 14780,
		visemeId: 15
	},
	{
		audioOffset: 14786,
		visemeId: 8
	},
	{
		audioOffset: 14792,
		visemeId: 4
	},
	{
		audioOffset: 14800,
		visemeId: 12
	},
	{
		audioOffset: 14929,
		visemeId: 9
	},
	{
		audioOffset: 15060,
		visemeId: 21
	},
	{
		audioOffset: 15119,
		visemeId: 9
	},
	{
		audioOffset: 15178,
		visemeId: 19
	},
	{
		audioOffset: 15240,
		visemeId: 6
	},
	{
		audioOffset: 15320,
		visemeId: 7
	},
	{
		audioOffset: 15400,
		visemeId: 19
	},
	{
		audioOffset: 15446,
		visemeId: 4
	},
	{
		audioOffset: 15492,
		visemeId: 14
	},
	{
		audioOffset: 15540,
		visemeId: 21
	},
	{
		audioOffset: 15630,
		visemeId: 6
	},
	{
		audioOffset: 15720,
		visemeId: 1
	},
	{
		audioOffset: 15749,
		visemeId: 6
	},
	{
		audioOffset: 15780,
		visemeId: 14
	},
	{
		audioOffset: 15825,
		visemeId: 6
	},
	{
		audioOffset: 15870,
		visemeId: 1
	},
	{
		audioOffset: 15915,
		visemeId: 14
	},
	{
		audioOffset: 15960,
		visemeId: 21
	},
	{
		audioOffset: 16006,
		visemeId: 6
	},
	{
		audioOffset: 16052,
		visemeId: 19
	},
	{
		audioOffset: 16100.000000000002,
		visemeId: 21
	},
	{
		audioOffset: 16126.000000000002,
		visemeId: 9
	},
	{
		audioOffset: 16152.000000000002,
		visemeId: 19
	},
	{
		audioOffset: 16180,
		visemeId: 6
	},
	{
		audioOffset: 16257,
		visemeId: 8
	},
	{
		audioOffset: 16334,
		visemeId: 13
	},
	{
		audioOffset: 16411,
		visemeId: 15
	},
	{
		audioOffset: 16488,
		visemeId: 4
	},
	{
		audioOffset: 16565,
		visemeId: 14
	},
	{
		audioOffset: 16642,
		visemeId: 18
	}
];
const animationTestData = sampleData.map((item) => {
	return {
		audioOffset: item.sample_audioOffset,
		visemeId: item.produced_visemeId
	};
});

const ModelViewer = forwardRef((props, ref) => {
	const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
	const SpeechGrammarList = window.SpeechGrammarList || window.webkitSpeechGrammarList;
	const SpeechRecognitionEvent = window.SpeechRecognitionEvent || window.webkitSpeechRecognitionEvent;
	const recognition = new SpeechRecognition();
	recognition.continuous = true;
	recognition.lang = "en-US";
	recognition.interimResults = true;
	recognition.maxAlternatives = 1;
	const { param1 } = useParams();
	const characterName = param1 || "Nina";
	const character = characterDict[characterName];
	const character2 = characterDict[characterName === "Nina" ? "John" : "Nina"];
	const characterRef = useRef();
	const background = useRef();
	const [changeDebug, setChangeDebug] = useState(Date.now);
	const [optionOpen, setOptionOpen] = useState(false);
	const { active, progress, errors, item, loaded, total } = useProgress();
	const [talking, SetTalking] = useState(false);
	const [transcript, SetTranscript] = useState(false);

	const [anim, setAnim] = useState("");
	const animationRef = useRef([]);
	const talkingAnimRef = useRef("Talk_Happy_F");
	const CanvasRef = useRef(null);
	const audioContext = useRef(null);
	const blockAzureViseme = useRef(false);
	const blockAzureViseme2 = useRef(false);

	const {
		speak: startSpeaking,
		pause: pauseAudio,
		resume: resumeAudio,
		interrupt: interruptAudio,
		status
	} = useSpeechSynthesizer({
		onVisemeReceived: handleVisemeReceived,
		onWordReceived: () => {},
		onSynthesisStarted: () => {},
		onSynthesisCompleted: () => {},
		onSynthesisCanceled: () => {},
		onAudioPlaying: () => {},

		onAudioStart: ({ speechId }) => {
			// let avatarSpeechData = getAvatarSpeechData(speechId);
			// avatarSpeechData.onAudioStart()

			// isAvatarTalkingRef.current = true; // Set the global playing status to true
			// setAudioPlaying(true); // Update the state of audio playing to true

			if (characterRef.current) {
				// If the character reference is set
				// console.log("UpdateAnimations", animationRef.current);
				if (blockAzureViseme.current) {
					const keyFrames = animationTestData.map((item) => {
						console.log("item", item, visemeObject[item.visemeId]);
						const visemeId = item.visemeId !== "" ? item.visemeId : 0;
						return { time: item.audioOffset / 1000, value: soundToVisemeMapping[visemeObject[visemeId].sounds[0]] };
					});
					console.log("keyFrames", keyFrames);

					animationRef.current = keyFrames;
				}

				characterRef.current.UpdateAnimations("test QUOTE", animationRef.current); // Update the animations of the character with the current speech data's animation
				characterRef.current.StartTalking(talkingAnimRef.current); // Start the talking animation of the character
			}
		},
		onAudioEnd: ({ speechId }) => {
			blockAzureViseme.current = false;
			// let speechData = getAvatarSpeechData(speechId);
			// // Get the old playback time
			// speechData.onAudioEnd();
			// let nextSpeechId = speechId + 1;
			// let nextData = getAvatarSpeechData(nextSpeechId);
			// if (nextData != undefined && nextData.gptId == speechData.gptId) {
			//     // characterRef.current.UpdateAnimations("test QUOTE", nextData.animation);
			//     emitSynthesizeSpeech(nextData)
			// }
			// else {
			//     isAvatarTalkingRef.current = false;
			//     // Set the audio started status
			//     setAudioPlaying(false);
			//     if (vadRefs.current && vadRefs.current.OnCharacterFinishCb) {
			//         vadRefs.current.OnCharacterFinishCb();
			//         vadRefs.current.OnCharacterFinishCb = undefined;
			//     }
			// }
		},
		audioContext
	});
	function playTestAnimation2() {
		console.log("playTestAnimation2");
		console.log(sampleData2);
		blockAzureViseme2.current = true;

		const audioUrl = "https://avatarportraits.s3.ca-central-1.amazonaws.com/testAudio.wav";
		const audio = new Audio(audioUrl);
		audio.addEventListener("canplaythrough", () => {
			console.log("Audio started playing1");
			if (blockAzureViseme2.current) {
				const keyFrames = sampleData2.map((item) => {
					const visemeId = item.visemeId !== "" ? item.visemeId : 0;
					console.log("item", item, visemeObject[visemeId], visemeId);

					return { time: item.audioOffset / 1000, value: soundToVisemeMapping[visemeObject[visemeId].sounds[0]] };
				});
				console.log("keyFrames", keyFrames);
				animationRef.current = keyFrames;
			}
			characterRef.current.UpdateAnimations("test QUOTE", animationRef.current); // Update the animations of the character with the current speech data's animation
			characterRef.current.StartTalking(talkingAnimRef.current); // Start the talking animation of the character
			// Your callback logic hereas
		});
		audio.addEventListener("play", () => {
			//		console.log("Audio started playing2");
			// Your callback logic here
		});
		audio.play().catch((error) => console.error("Error playing audio:", error));
	}
	function playTestAnimation() {
		console.log("playTestAnimation");
		console.log(animationTestData);
		blockAzureViseme.current = true;
		startSpeaking(0, "Great to meet you! Tell us what excites you about this opportunity.", "John");
	}
	function handleVisemeReceived({ speechId, visime }) {
		try {
			const keyFrame = { time: visime.audioOffset / 1000 / 10000, value: soundToVisemeMapping[visemeObject[visime.visemeId].sounds[0]] };
			console.log("viseme received ", keyFrame);
			animationRef.current.push(keyFrame);
		} catch (error) {
			console.error(error);
		}
	}
	useEffect(() => {
		audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
	}, []);

	useEffect(() => {
		let interval;
		interval = setInterval(() => {
			// console.log(characterRef.current?.getAnim())
			setAnim(characterRef.current?.getAnim());
		}, 200);
		return () => clearInterval(interval);
	}, []);
	const CameraControlsRef = useRef();

	function setLightSettings(setting, field, value) {
		const oldSetting = characterRef.current.lightSettings;

		setting[field] = value;
		oldSetting[setting.name] = setting;
		// console.log(oldSetting)
		characterRef.current.setLightSettings(oldSetting);
		setChangeDebug(Date.now);
	}
	function OnMouseEnter() {
		//   CameraControlsRef.current.enabled = false;
	}
	function OnMouseLeave() {
		//  CameraControlsRef.current.enabled = true;
	}

	const [camPosition, set_camPosition] = useState(character.cameraPosition);
	const [camRotation, set_camRotation] = useState(character.cameraRotation);
	//  console.log(character.animations.ReactionAnimsName)
	const typingRefs = useRef(null);

	const currentAudioSource = useRef(null);
	return (
		<>
			<Helmet>
				<title>InStage ModelViewer</title>

				<meta name="robots" content="noindex" />
			</Helmet>
			<div className={styles.adminPage}>
				<div>
					<h1>
						<TypingText refHolder={typingRefs}>Model Viewer</TypingText>
					</h1>
					<div style={{ display: "flex", fontSize: "0.8rem" }}>
						<div style={{ display: "flex", flexDirection: "column" }}>
							{/* <button
								type="button"
								onClick={() => {
									recognition.start();
									recognition.onspeechstart = function () {
										SetTalking(true);
										console.log("Speech has been detected");
									};
									recognition.onspeechend = function () {
										SetTalking(false);
										console.log("Speech has stopped being detected");
										//
									};
									recognition.onresult = function (event) {
										const lastEvent = event.results[event.results.length - 1];
										const text = lastEvent[0].transcript;
										// get last event.results

										console.log(lastEvent);
										SetTranscript(text);
									};

									recognition.onend = function () {
										console.log("Speech recognition service disconnected");
										//  recognition.start();
									};
								}}
							>
								start recognition
							</button>
							{talking ? "talking" : "silent"}

							{transcript} */}

							<TypingText refHolder={typingRefs}>States</TypingText>

							{/* <button onClick={() => { characterRef.current.startStoryTime("Brian") }}>
                        <TypingText refHolder={typingRefs}>
                            start Story Time
                        </TypingText>

                    </button> */}
							{/* <button onClick={() => { characterRef.current.makeEyeLookAt() }}>look</button> */}
							{/* <button onClick={() => { characterRef.current.realTimeLipsync("Hi Instage, a long time ago I briefly looked into, how you might configure an app created by create-react-app to work with the vad, but I concluded early on that it was more trouble than it was worth for me at the time. I can eventually try to provide an example, but I won't have time to look into it in the near future. However, one thing that I expect would work is to create a build script that looks something like", "Ayanda") }}>
                        <TypingText refHolder={typingRefs}>
                            Start Talking
                        </TypingText>

                    </button> */}

							<button
								type="button"
								onClick={() => {
									// characterRef.current.StartTalking()

									try {
										animationRef.current = [];

										playTestAnimation();
									} catch (error) {
										console.error(error);
									}
								}}
							>
								<TypingText refHolder={typingRefs}>playTestAnimation Sharmitha</TypingText>
							</button>

							<button
								type="button"
								onClick={() => {
									// characterRef.current.StartTalking()

									try {
										animationRef.current = [];

										playTestAnimation2();
									} catch (error) {
										console.error(error);
									}
								}}
							>
								<TypingText refHolder={typingRefs}>playTestAnimation Zack</TypingText>
							</button>
							<button
								type="button"
								onClick={() => {
									// characterRef.current.StartTalking()
									talkingAnimRef.current = "Talk_Happy_F";
									try {
										animationRef.current = [];
										blockAzureViseme.current = false;
										blockAzureViseme2.current = true;
										startSpeaking(0, "Great to meet you! Tell us what excites you about this opportunity.", "John");
									} catch (error) {
										console.error(error);
									}
								}}
							>
								<TypingText refHolder={typingRefs}>Start Speaking Happy</TypingText>
							</button>
							{/* <button
								type="button"
								onClick={async () => {
									// characterRef.current.StartTalking()
									talkingAnimRef.current = "Talk_Happy_F";
									try {
										// animationRef.current = []

										startSpeaking(
											0,
											"If you click on the advanced tab, you can go through each area of the simulation and see how you answered each question and see feedback on how you could improve.",
											"John"
										);
									} catch (error) {
										console.error(error);
									}

									await sleep(4);
									console.log("sleep done");
									characterRef.current.StartActiveListening();
								}}
							>
								<TypingText refHolder={typingRefs}>Start Speaking Async</TypingText>
							</button>
							<button
								type="button"
								onClick={() => {
									// characterRef.current.StartTalking()
									talkingAnimRef.current = "Assertive";
									try {
										// animationRef.current = []

										startSpeaking(
											0,
											"Hello this is a test confident. I am currently speaking confidently so that you can see my animation play"
										);
									} catch (error) {
										console.error(error);
									}
								}}
							>
								<TypingText refHolder={typingRefs}>Start Speaking Confident</TypingText>
							</button>
							<button
								type="button"
								onClick={() => {
									characterRef.current.StartActiveListening();
								}}
							>
								<TypingText refHolder={typingRefs}>Start Active Listening</TypingText>
							</button>
							<button
								type="button"
								onClick={() => {
									characterRef.current.StartThinking();
								}}
							>
								<TypingText refHolder={typingRefs}>Start Thinking</TypingText>
							</button>
							<button
								type="button"
								onClick={() => {
									characterRef.current.StartListeningToOther();
								}}
							>
								<TypingText refHolder={typingRefs}>Start Listening To Other</TypingText>
							</button>
							<button
								type="button"
								onClick={() => {
									characterRef.current.StartPassiveListening();
								}}
							>
								<TypingText refHolder={typingRefs}>Start Passive Listening</TypingText>
							</button> */}
						</div>
						{/* <div style={{ display: "flex", flexDirection: "column" }}>
							<button
								type="button"
								onClick={() => {
									characterRef.current.StartGreeting();
								}}
							>
								<TypingText refHolder={typingRefs}>Start Greeting</TypingText>
							</button>

							<button
								type="button"
								onClick={() => {
									characterRef.current.StopSpeech();
								}}
							>
								<TypingText refHolder={typingRefs}>Stop Speech</TypingText>
							</button>

							<TypingText refHolder={typingRefs}>additive anims</TypingText>

							{character.animations.AdditiveAnimsNames.map((name, index) => (
								<button
									type="button"
									onClick={() => {
										characterRef.current.StartAdditiveAnimation(name);
									}}
								>
									<TypingText refHolder={typingRefs}>{name}</TypingText>
								</button>
							))}
						</div>

						<div style={{ display: "flex", flexDirection: "column" }}>
							<TypingText refHolder={typingRefs}>Reactions</TypingText>

							<button
								type="button"
								onClick={() => {
									console.log(CanvasRef.current);

									console.log(CanvasRef.current.captureStream());
								}}
							>
								debug canvas
							</button>
							{character.animations.ReactionAnimsName.map((name, index) => (
								<button
									type="button"
									onClick={() => {
										characterRef.current.StartReaction(name);
									}}
								>
									<TypingText refHolder={typingRefs}>{name}</TypingText>
								</button>
							))}
						</div> */}

						<div style={{ display: "flex", flexDirection: "column" }}>
							<div
								style={{
									padding: "10px",
									backgroundColor: "black",
									top: "20px",
									right: "20px"
								}}
							>
								{/* <p
									onClick={(e) => {
										setOptionOpen(!optionOpen);
									}}
									onKeyPress={(e) => {
										if (e.key === "Enter") setOptionOpen(!optionOpen);
									}}
									style={{ cursor: "pointer", color: "white" }}
								>
									<input
										type="checkbox"
										checked={optionOpen}
										onChange={(e) => {
											setOptionOpen(e.target.checked);
										}}
									/>{" "}
									open debug
								</p> */}
								{/* {changeDebug.toString().slice(0, 1)} */}
								{optionOpen && (
									<>
										<div style={{ color: "white" }}>
											LIGHTS
											{characterRef.current &&
												Object.keys(characterRef.current?.lightSettings).map((key, index) => {
													const setting = characterRef.current?.lightSettings[key];
													return (
														<div onMouseEnter={OnMouseEnter} onMouseLeave={OnMouseLeave} key={setting.name}>
															{setting.name}_ Intensity:
															{setting.intensity}
															<input
																type="range"
																min="0"
																max="1"
																step="0.01"
																value={setting.intensity}
																onChange={(e) => {
																	setLightSettings(setting, "intensity", e.target.value);
																}}
															/>
															<p>
																Color:
																{setting.color}
															</p>
															<input
																type="color"
																value={setting.color}
																onChange={(e) => {
																	setLightSettings(setting, "color", e.target.value);
																}}
															/>
														</div>
													);
												})}
										</div>

										<p style={{ color: "white" }}>{character.url}</p>
										<p style={{ color: "white" }}>{character.urlIdle}</p>
										<p style={{ color: "white" }}>{character.urlReactions}</p>
									</>
								)}
							</div>
							{/* <button onClick={() => { characterRef.current.makeEyeLookAt() }}>look</button> */}
							{/* debug camera controls */}
							{/* <div style={{ margin: "25px 0 0" }}>
								x
								<input
									type="number"
									step={0.01}
									value={camPosition[0]}
									onChange={(e) => {
										const val = parseFloat(e.target.value);
										set_camPosition([val, camPosition[1], camPosition[2]]);
									}}
								/>
								y
								<input
									type="number"
									step={0.01}
									value={camPosition[1]}
									onChange={(e) => {
										const val = parseFloat(e.target.value);
										set_camPosition([camPosition[0], val, camPosition[2]]);
									}}
								/>
								z
								<input
									type="number"
									step={0.01}
									value={camPosition[2]}
									onChange={(e) => {
										const val = parseFloat(e.target.value);
										set_camPosition([camPosition[0], camPosition[1], val]);
									}}
								/>
								<br />
								x
								<input
									type="number"
									step={0.1}
									value={camRotation[0]}
									onChange={(e) => {
										const val = parseFloat(e.target.value);
										set_camRotation([val, camRotation[1], camRotation[2]]);
									}}
								/>
								y
								<input
									type="number"
									step={0.1}
									value={camRotation[1]}
									onChange={(e) => {
										const val = parseFloat(e.target.value);
										set_camRotation([camRotation[0], val, camRotation[2]]);
									}}
								/>
								z
								<input
									type="number"
									step={0.1}
									value={camRotation[2]}
									onChange={(e) => {
										const val = parseFloat(e.target.value);
										set_camRotation([camRotation[0], camRotation[1], val]);
									}}
								/>
							</div> */}
							<p>
								{loaded}/{total}
							</p>
						</div>
					</div>
				</div>
				<p>{anim}</p>
				<Canvas ref={CanvasRef} style={{ position: "absolute" }}>
					<HDR_SETUP hdrMapFilePath={character.hdrmap} />
					<ToneMapping toneMapExp={character.exposureLvl} />

					<PerspectiveCamera makeDefault fov={55} near={0.1} far={1000} position={camPosition} rotation={camRotation} />
					{/* <EyeTracking /> */}
					<Character
						ref={characterRef}
						name={character.name}
						url={character.url}
						currentAudioSource={currentAudioSource}
						lightSettings={character.lightSettings}
						background={character.background}
						urlAnimations={character.urlAnimations}
						animations={character.animations}
						position={[0, 0, 0]}
						rotation={character.characterRotation}
						characterDict={character}
					/>

					{/* <TransformControls object={background}  mode="translate"    />  */}

					{/* <Suspense >
                                <Office></Office>
                            </Suspense>  */}

					{/* <CameraControls position={character.cameraPosition} rotation={character.cameraRotation} ref={CameraControlsRef} makeDefault /> */}
				</Canvas>
			</div>
		</>
	);
});

export default ModelViewer;
