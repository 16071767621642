import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import s from "../../styleModules/simulationStyles.module.css";
import { obfuscate } from "../UtilityFunctions/DataUtility.js";
import { useUploadManager } from "../UtilityFunctions/useUploadManager.js";
import r from "../../styleModules/reportStyles.module.css";
import { ConfirmGenerateReportModal } from "./SetupElements/SetupModals.js";

export function SimFinished({ uploadProgress, setUploadProgressState, currentSession, setCurrentSession, showSidebar }) {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const { simId } = useParams();
	const posthog = usePostHog();

	const { userLanguage, translateDictText, performTranslation, localUser, handleNavigate, uploadProgressReal, setUploadProgressReal } =
		useContext(AppContext);

	const [TextRenderState, setTextRenderState] = useState(false);
	const [showConfirmGenerateReportModal, setShowConfirmGenerateReportModal] = useState(false);

	// useEffect(() => {
	// 	console.log("uploadprogress SimFinished: ", uploadProgressReal);
	// }, [uploadProgressReal]);

	// Static Translation
	const LocalLanguageDict = {
		GenerateReport: "Generate Report",
		TryAgain: "Try Again",
		ThisWillTakeAFewMinutes: "Please do not leave/refresh this page. Video recordings may take over 10 minutes to upload.",
		Submit: "Submit",
		SimulationFinished: "Simulation Finished",
		SavingProgress: "Saving progress",
		Generating: "Generating",
		UploadingInProgress: "Uploading: ",
		GenerateAnyways: "Generate Anyways"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	const [hasSavedValues, setHasSavedValues] = useState(false);

	useEffect(() => {
		// Clear any existing stored values when component mounts
		localStorage.removeItem("uploadProgress");
		localStorage.removeItem("currentSession");

		// Only save new values if they are valid
		if (uploadProgress !== null && currentSession !== null) {
			localStorage.setItem("uploadProgress", uploadProgress);
			localStorage.setItem("currentSession", JSON.stringify(currentSession));
		}

		setHasSavedValues(true);

		return () => {
			localStorage.removeItem("uploadProgress");
			localStorage.removeItem("currentSession");
		};
	}, [uploadProgress, currentSession]);

	useEffect(() => {
		// Retrieve values from local storage on mount

		const savedUploadProgress = localStorage.getItem("uploadProgress");

		const savedCurrentSession = localStorage.getItem("currentSession");

		if (savedUploadProgress !== null) {
			setUploadProgressState(parseInt(savedUploadProgress, 10));
		}

		if (savedCurrentSession && savedCurrentSession !== "undefined") {
			setCurrentSession(JSON.parse(savedCurrentSession));
		}

		// console.log("2 Saved Upload Progress... ", savedUploadProgress);
		// console.log("2 Saved Current Session... ", savedCurrentSession);
		return () => {
			localStorage.removeItem("uploadProgress");
			localStorage.removeItem("currentSession");
			console.log("2 Removed Upload Progress... ", localStorage.getItem("uploadProgress"));
			console.log("2 Removed Current Session... ", localStorage.getItem("currentSession"));
		};
	}, [hasSavedValues]);

	useEffect(() => {
		console.log("uploadProgress: ", uploadProgress);
	}, [uploadProgress]);

	// Add this useEffect near the other useEffect hooks
	// useEffect(() => {
	// 	const handleBeforeUnload = (e) => {
	// 		e.preventDefault();
	// 		// This message might not show in some browsers as they use their own standard messages
	// 		const message = "Are you sure you want to leave? Your progress might be lost.";
	// 		e.returnValue = message;
	// 		return message;
	// 	};

	// 	window.addEventListener("beforeunload", handleBeforeUnload);

	// 	// Cleanup
	// 	return () => {
	// 		window.removeEventListener("beforeunload", handleBeforeUnload);
	// 	};
	// }, []);

	const handleGenerateReport = () => {
		// const paramsString = searchParams.toString();

		if (!currentSession?.session_id) {
			console.error("No valid session found! (Possibly refreshed before clicking Generate Report)");
			return;
		}

		// Clear local storage before generating a new report
		localStorage.removeItem("uploadProgress");
		localStorage.removeItem("currentSession");

		if (window.location.pathname.includes("preview")) {
			try {
				posthog?.capture("instage_preview_generate_report", {
					instage_id: localUser.id
				});
			} catch (error) {
				console.error("Posthog error:", error);
			}
			handleNavigate(`/preview-report/${obfuscate(currentSession.session_id.toString())}`, true);
		} else {
			handleNavigate(`/report/${obfuscate(currentSession.session_id.toString())}`, true);
		}
	};
	// useEffect(() => {
	// 	runTranslation();
	// });

	return (
		<div
			className={s.simPage}
			style={{
				// padding:
				// 	window.innerWidth > 768 ? (!showSidebar ? "1.25rem 1.25rem 0" : "1.25rem calc(1.25rem + 25%) 0 1.25rem") : "inherit",
				padding: window.innerWidth > 1000 ? "1.25rem 27% 0 2%" : "1.25rem 2% 0",
				zIndex: 1
			}}
		>
			{showConfirmGenerateReportModal && (
				<ConfirmGenerateReportModal
					setShowConfirmModal={setShowConfirmGenerateReportModal}
					handleModalCancel={() => setShowConfirmGenerateReportModal(false)}
					handleModalConfirm={handleGenerateReport}
				/>
			)}
			<div className={s.simEndedContainer}>
				{/* {process.env.REACT_APP_ENVIRONMENT_TYPE === "staging" ||
					(process.env.REACT_APP_ENVIRONMENT_TYPE === "development" && (
						<div>
							{uploadProgressReal < 100 && (
								<div className={r.loadingBar}>
									<div className={r.loadingFill} style={{ width: `${uploadProgressReal}%` }}>
										<p className={r.loadingText}>{pageText.Generating}</p>
									</div>
								</div>
							)}
							Upload Progress: {uploadProgressReal.toString()}%
						</div>
					))} */}
				<div className={s.simEndedContent}>
					<h1 className={s.simEndedHeading}>{pageText.SimulationFinished}</h1>
					{uploadProgress > 0 && uploadProgress < 100 && (
						<div className={s.loadingBarContainer}>
							{/* <div className="progress-bar" style={{ width: `${uploadProgress}%`, background: "blue" }}>
									{uploadProgress}%
								</div> */}
							<div className={s.loadingBar}>
								<div className={s.loadingFill} style={{ width: `${uploadProgress}%` }}>
									<p className={s.loadingText}>{pageText.SavingProgress}...</p>
								</div>
							</div>
						</div>
					)}
					<div className={s.simEndedBtnContainer}>
						{uploadProgress >= 100 ||
							(uploadProgress === 0 && (
								<>
									{!window.location.pathname.includes("preview") && (
										<button
											type="button"
											className={s.simEndedCancelBtn}
											onClick={() => {
												let newPathname = location.pathname.replace(`/${simId}`, "");
												newPathname = window.location.pathname.includes("simulation")
													? newPathname.replace("simulation", "setup")
													: newPathname;

												handleNavigate(window.location.origin + newPathname, true);
											}}
										>
											{pageText.TryAgain}
										</button>
									)}
									<div className={s.simEndedBtnGroup}>
										{uploadProgressReal < 99 ? (
											<>
												<div className={r.loadingBar2}>
													<div className={r.loadingFill} style={{ width: `${uploadProgressReal}%` }}>
														<p className={r.loadingText}>
															{pageText.UploadingInProgress} {uploadProgressReal}%
														</p>
													</div>
												</div>
											</>
										) : (
											<button
												data-attr="modals_generate_report"
												type="button"
												className={uploadProgressReal >= 99 ? s.simEndedConfirmBtn : s.simEndedConfirmBtnDisabled}
												onClick={() => (uploadProgressReal >= 99 ? handleGenerateReport() : null)}
											>
												{pageText.GenerateReport}
											</button>
										)}
									</div>{" "}
									{uploadProgressReal < 99 && (
										<div className={s.simEndedBtnGroup}>
											<button
												data-attr="modals_generate_report"
												type="button"
												className={s.simEndedConfirmBtn}
												onClick={() => setShowConfirmGenerateReportModal(true)}
											>
												{pageText.GenerateAnyways}
											</button>
										</div>
									)}
								</>
							))}
					</div>
				</div>
				<p className={s.simEndedBtnNote}>{pageText.ThisWillTakeAFewMinutes}</p>
				{/* {uploadProgressReal < 100 && (
				<div
					style={{
						justifyContent: "center",
						alignItems: "center",
						maxWidth: "100%",
						margin: "1rem auto",
						marginTop: "3rem"
					}}
				>
					<div className={r.loadingBar}>
						<div className={r.loadingFill} style={{ width: `${uploadProgressReal}%` }}>
							<p className={r.loadingText}>{pageText.UploadingInProgress}...</p>
						</div>
					</div>
				</div>
				)} */}
			</div>
		</div>
	);
}
