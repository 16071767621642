import React, { useState, useEffect, useRef, useContext, createContext } from "react";
import r from "../../styleModules/reportStyles.module.css";
import { FiInfo } from "react-icons/fi/index.esm.js";

const useOutsideClick = (ref, callback) => {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};

export default function InfoToolTip({ item_index, useTooltip, children }) {
	const { activeTooltip, setActiveTooltip } = useTooltip();
	const infoRef_details = useRef();
	const [showInfo_details, setShowInfo_details] = useState(false);

	useOutsideClick(infoRef_details, () => {
		if (showInfo_details) {
			setShowInfo_details(false);
		}
	});

	// Effect to close tooltip if another one is opened
	useEffect(() => {
		if (activeTooltip !== item_index) {
			setShowInfo_details(false);
		}
	}, [activeTooltip, item_index]);

	return (
		<div className={r.infoIconContainer}>
			<button
				type="button"
				aria-label="Information"
				tabIndex={0}
				className={r.info}
				onClick={(event) => {
					event.stopPropagation();
					setShowInfo_details(!showInfo_details);
					if (!showInfo_details) {
						// If we're about to show the tooltip
						setActiveTooltip(item_index); // Use title or any unique identifier
					} else {
						setActiveTooltip(null);
					}
				}}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						setShowInfo_details(!showInfo_details);
						if (!showInfo_details) {
							// If we're about to show the tooltip
							setActiveTooltip(item_index); // Use title or any unique identifier
						} else {
							setActiveTooltip(null);
						}
					}
				}}
			>
				<FiInfo />
			</button>
			{showInfo_details && (
				<div
					className={r.infoTooltip}
					ref={infoRef_details}
					onClick={(event) => event.stopPropagation()}
					onKeyDown={(event) => {
						if (event.key === "Enter") {
							event.stopPropagation();
						}
					}}
					tabIndex={0}
					role="button"
				>
					<span>{children}</span>
				</div>
			)}
		</div>
	);
}
