import React from "react";
import { Link } from "react-router-dom";
import logo from "../../images/logoLarge.png";

const NotFound = () => {
	return (
		<div className="not-found">
			<div>
				<img src={logo} alt="Logo" className="not-found-logo" />
				<h1>Uh oh! Looks like this page doesn't exist.</h1>
				<p>Don't worry, we won't tell anyone you got lost.</p>
				<p>In the meantime, why don't you check out some of our other pages?</p>
				<Link to="/" className="not-found-button">
					Let's Go
				</Link>
			</div>
		</div>
	);
};

export default NotFound;
