import React from "react";
import templateSelectorStyles from "../../styleModules/TemplateSelectorMini.module.css";
import { templates, coverLetterTemplates } from "./templates";

const TemplateSelectorMini = ({ setTemplateIndex, type }) => {
	return (
		<div className={templateSelectorStyles.templateSelectorMini}>
			{type === "resume"
				? templates.map((template) => (
						<button
							key={template.id}
							className={templateSelectorStyles.templateItem}
							onClick={() => setTemplateIndex(template.id - 1)}
							onKeyDown={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									setTemplateIndex(template.id - 1);
								}
							}}
						>
							<img src={template.imageUrl} alt={template.name} className={templateSelectorStyles.templateImage} />
						</button>
				  ))
				: coverLetterTemplates.map((template) => (
						<button
							key={template.id}
							className={templateSelectorStyles.templateItem}
							onClick={() => setTemplateIndex(template.id - 1)}
							onKeyDown={(e) => {
								if (e.key === "Enter" || e.key === " ") {
									setTemplateIndex(template.id - 1);
								}
							}}
						>
							<img src={template.imageUrl} alt={template.name} className={templateSelectorStyles.templateImage} />
						</button>
				  ))}
		</div>
	);
};

export default TemplateSelectorMini;
