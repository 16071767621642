import React, { useEffect, useState, useRef, useContext } from "react";
import { BsStars } from "react-icons/bs";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FiArrowLeft, FiBriefcase, FiCopy, FiEdit2, FiRotateCcw, FiThumbsUp, FiThumbsDown } from "react-icons/fi/index.esm.js";
import { IoCheckmark } from "react-icons/io5";
import { MdOutlineFormatListBulleted, MdOutlineUnfoldLess } from "react-icons/md";
import { TbClipboardText, TbLineHeight } from "react-icons/tb";
import styles from "../../styleModules/generateStyles.module.css";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import { useSendScore, GptAssistFeedbackModal } from "./SetupElements/SetupModals.js";
import ThumbButton from "./ThumbButton.js";
import { set } from "date-fns";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { Cancel } from "@mui/icons-material";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { FaArrowRight } from "react-icons/fa6";
import { IoIosCheckmark } from "react-icons/io";
import ResumeCreatorForm from "../SecondaryComponents/ResumeCreatorForm.js";
import ResumeCreatorPreview from "../SecondaryComponents/ResumeCreatorPreview.js";
import templateSelectorStyles from "../../styleModules/TemplateSelectorMini.module.css";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

const useOutsideClick = (ref, callback) => {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};

export default function ResumeSection({
	docType,
	section,
	resumeSectionsText,
	coverLetterSectionsText,
	resumeSections,
	editMode,
	toggleEdit,
	handleCopy,
	handleTempUpdate,
	handleSave,
	data,
	tempData,
	tempDataSetter,
	dataSetter,
	dataStatusSetter,
	gptAssistData,
	setGptAssistData,
	allFeedbackLoading,
	sectionFeedbackLoading,
	sectionRewriteLoading,
	resumeFeedbackSection,
	resumeRewriteSection,
	detailsArray,
	setShowEditModal,
	setTemplateResumeData,
	templateResumeData,
	setupData,
	editOrSave,
	setEditOrSave
}) {
	const sendScore = useSendScore();

	const [sectionTitleText, setSectionTitleText] = useState("Summary");
	const [sectionFeedbackText, setSectionFeedbackText] = useState(
		`No Data Entered For This Section: \n \n Please input details for this section and then click the "Regenerate Feedback" button below.`
	);
	const [isFormSelected, setIsFormSelected] = useState(false);
	// const [editOrSave, setEditOrSave] = useState("edit");
	const {
		userLanguage,
		performTranslation,
		translateSingleText,
		resumeTemplateData,
		setResumeTemplateData,
		coverLetterTemplateData,
		setCoverLetterTemplateData
	} = useContext(AppContext);

	const resumeCreatorFlag = useFeatureFlagEnabled("resume-creator");
	const { axiosLimitedPost } = useAxiosLimited();

	// Static Translation
	const LocalLanguageDict = {
		MissingSection: "Missing Section",
		Feedback: "Feedback",
		Rewrite: "Rewrite",
		JobPost: "Job Post",
		Edit: "Edit",
		Copy: "Copy",
		Your: "Your",
		Formalize: "Formalize",
		Elaborate: "Elaborate",
		Shorten: "Shorten",
		Save: "Save",
		Cancel: "Cancel",
		Generate: "Generate",
		Insert: "Insert",
		ClickGenerate: "Click 'Generate' to get a suggested version",
		ApplyToTemplate: "Add To Template"
		// Section: section,
		// GPTAssistFeedback: gptAssistData?.[section]?.feedback
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Update ScoreText when gptAssistScore changes
	useEffect(() => {
		if (gptAssistData && gptAssistData[section]) {
			setSectionFeedbackText(gptAssistData[section].feedback);
		}
	}, [allFeedbackLoading, sectionFeedbackLoading, sectionFeedbackText, section]);

	useEffect(() => {
		console.log("Section Feedback & Title: ", sectionFeedbackText, sectionTitleText);
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
			try {
				if (section && gptAssistData?.[section]?.feedback) {
					const translatedSectionTitle = await translateSingleText(section, userLanguage);
					const translatedSectionFeedback = await translateSingleText(gptAssistData[section].feedback, userLanguage);
					setSectionTitleText(translatedSectionTitle);
					setSectionFeedbackText(translatedSectionFeedback);
				}
			} catch (err) {
				console.log("Translation failed: ", err);
			}
		};

		const retryTranslateText = async (retries = 3, delay = 1000) => {
			for (let i = 0; i < retries; i++) {
				try {
					await translateText();
					break; // Exit loop if successful
				} catch (err) {
					if (i < retries - 1) {
						console.log(`Retrying translation... (${i + 1}/${retries})`);
						await new Promise((resolve) => setTimeout(resolve, delay));
					} else {
						console.log("Translation failed after retries: ", err);
					}
				}
			}
		};

		if (section && gptAssistData?.[section]?.feedback && userLanguage !== "en") {
			setTimeout(() => {
				retryTranslateText();
			}, 1000);
		}
	}, [userLanguage, gptAssistData?.[section]?.feedback, section]); // Depend on userLanguage to trigger re-translation

	const sections = [
		{ key: 0, title: `${pageText.Feedback}`, icon: <TbClipboardText /> },
		{ key: 1, title: `${pageText.Rewrite}`, icon: <BsStars /> },
		{ key: 2, title: `${pageText.JobPost}`, icon: <MdOutlineFormatListBulleted /> }
	];
	const refineOptions = [
		{ key: 0, title: `${pageText.Formalize}`, icon: <FiBriefcase className={styles.gptAssistBtnIcon} /> },
		{ key: 1, title: `${pageText.Elaborate}`, icon: <TbLineHeight className={styles.gptAssistBtnIcon} /> },
		{ key: 2, title: `${pageText.Shorten}`, icon: <MdOutlineUnfoldLess className={styles.gptAssistBtnIcon} /> }
	];
	const [copySuccess, setCopySuccess] = useState(false);
	const [activeSection, setActiveSection] = useState(sections[0].key);
	const [sectionSaved, setSectionSaved] = useState(false);
	const [showRefinePopup, setShowRefinePopup] = useState(false);
	const [selectedRefineOption, setSelectedRefineOption] = useState("");

	// Feedback
	const [isThumbClicked, setIsThumbClicked] = useState({
		feedback: null,
		rewrite: null
	});
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [feedbackName, setFeedbackName] = useState(null);
	const [feedbackStatus, setFeedbackStatus] = useState(null);
	const [selectedPromptId, setSelectedPromptId] = useState(null);

	const textareaRef = useRef(null);
	const refinePopupRef = useRef(null);
	const tabsRef = useRef(sections.map(() => React.createRef()));

	const handleThumbClickSection = async (category, status, request_id, currentSection) => {
		setIsThumbClicked((prevState) => ({
			...prevState,
			[category]: status
		}));
		setFeedbackStatus(status);
		setShowFeedbackModal(true);

		const prefix = resumeSections.includes(currentSection) ? "resume_" : "cover_letter_";
		const scoreName = `${prefix}${category}_${currentSection}`;
		setFeedbackName(scoreName);

		// Reset Prompt request metadata
		const feedbackData = {
			pl_request_id: request_id,
			metadata: {
				date: "",
				[scoreName]: ""
			}
		};

		try {
			await axiosLimitedPost(`${url}/api/promptlayer/feedback`, feedbackData, 1, {}, 30000);
		} catch (err) {
			console.log(err);
		}

		if (request_id) {
			setSelectedPromptId(request_id);
			sendScore(request_id, status, scoreName);
		}
	};

	const handleTabClick = (sectionKey, index) => {
		const tab = tabsRef.current[index].current;
		if (tab) {
			const { offsetLeft: left } = tab;
		}
		setActiveSection(sectionKey);
	};

	const adjustTextareaHeight = () => {
		if (textareaRef.current) {
			textareaRef.current.style.height = "auto";
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	};

	const togglePopup = () => setShowRefinePopup(!showRefinePopup);

	const refineClick = (title) => {
		setSelectedRefineOption(title);

		if (selectedRefineOption !== title) {
			resumeRewriteSection(section, title);
		}
		if (selectedRefineOption !== "") {
			togglePopup();
		}
	};

	// Set the indicator's initial position and width
	useEffect(() => {
		const tab = tabsRef.current[0].current;
		if (tab) {
			const { offsetLeft: left } = tab;
		}
	}, []);

	useEffect(() => {
		// Check if the textarea should be present
		if (editMode[section] && textareaRef.current) {
			adjustTextareaHeight();
		}
	}, [editMode[section]]);

	useEffect(() => {
		if (section === "contact" && gptAssistData && gptAssistData[section]) {
			// Check if contact exists
			setGptAssistData((prev) => ({
				...prev,
				[section]: {
					...prev[section],
					rewrite: ""
				}
			}));
		}
		return () => {
			if (section === "contact" && gptAssistData && gptAssistData[section]) {
				// Check if contact exists
				setGptAssistData((prev) => ({
					...prev,
					[section]: {
						...prev[section],
						rewrite: ""
					}
				}));
			}
		};
	}, []); // Add section and gptAssistData as dependencies

	useOutsideClick(refinePopupRef, () => setShowRefinePopup(false));

	const [isAddingToTemplate, setIsAddingToTemplate] = useState(false);

	const applySectionToTemplate = (templateSection, sectionInput, index = null, subIndex = null, type) => {
		const sendToPromptLayer = async () => {
			const TemplateData = {
				sectionTitle: templateSection,
				sectionData: sectionInput,
				type: type
			};

			// if (templateSection === "summary") {
			// 	setTemplateResumeData((prevData) => ({
			// 		...prevData,
			// 		[templateSection]: sectionInput
			// 	}));
			// 	return;
			// }

			try {
				// Make the API call
				const resp = await axiosLimitedPost(`${url}/api/resume-assist/resumeCreatorInsertSection`, TemplateData, 1, {}, 30000);

				setTemplateResumeData((prevData) => {
					if (templateSection === "volunteer") {
						// For volunteer, we need to update the extraCurricular array
						const updatedExtraCurricular = [
							...(prevData.extraCurricular || []).filter((item) => !item.isVolunteer || item.type !== "experience"),
							...resp.data[templateSection].map((item) => ({ ...item, isVolunteer: true, type: "experience" }))
						];
						return {
							...prevData,
							extraCurricular: updatedExtraCurricular
						};
					} else if (templateSection === "contact") {
						return {
							...prevData,
							contact: resp.data
						};
					} else {
						// For all other sections, update directly
						return {
							...prevData,
							[templateSection]: resp.data[templateSection]
						};
					}
				});

				console.log(`Updated ${templateSection} in templateResumeData:`, resp.data);
			} catch (err) {
				console.error("Error in applySectionToTemplate:", err);
			}
		};

		return sendToPromptLayer();
	};

	const handleContainerClick = () => {
		setIsFormSelected(false);
	};

	return (
		<>
			{showFeedbackModal && (
				<GptAssistFeedbackModal
					setShowFeedbackModal={setShowFeedbackModal}
					feedbackName={feedbackName}
					feedbackStatus={feedbackStatus}
					selectedPromptId={selectedPromptId}
				/>
			)}
			<div className={styles.resumeSection}>
				{docType === "resume" && (
					<ResumeCreatorForm
						wide={false}
						isFormSelected={isFormSelected}
						setIsFormSelected={setIsFormSelected}
						type="resume"
						resumeData={resumeTemplateData}
						setResumeData={setResumeTemplateData}
						selectedSection={section}
						editOrSave={editOrSave}
						setEditOrSave={setEditOrSave}
						setupData={setupData}
						setResumeDataChanged={dataStatusSetter}
					/>
				)}
				{docType === "cover_letter" && (
					<ResumeCreatorForm
						wide={false}
						isFormSelected={isFormSelected}
						setIsFormSelected={setIsFormSelected}
						type="cover_letter"
						resumeData={coverLetterTemplateData}
						setResumeData={setCoverLetterTemplateData}
						selectedSection={section}
						editOrSave={editOrSave}
						setEditOrSave={setEditOrSave}
						setupData={setupData}
						setResumeDataChanged={dataStatusSetter}
					/>
				)}
				{/* {console.log("setupData", setupData)} */}
				{/* {!resumeCreatorFlag && (
					<>
						<div className={styles.resumeDetails}>
							<p className={styles.resumeDetailsHeading}>
								{pageText.Your}{" "}
								{userLanguage !== "en"
									? typeof sectionTitleText === "string"
										? sectionTitleText.charAt(0).toUpperCase() + sectionTitleText.slice(1)
										: ""
									: section
									? section.charAt(0).toUpperCase() + section.slice(1)
									: ""}
							</p>
							<div className={styles.resumeDetailsHeadingBtnContainer}>
								<button
									type="button"
									className={styles.resumeDetailsHeadingBtn}
									onClick={() => {
										toggleEdit(section);
										adjustTextareaHeight();
									}}
								>
									<FiEdit2 className={styles.resumeDetailsIcon} />
									{pageText.Edit}
								</button>

								{data && data[section] && (
									<button
										type="button"
										className={styles.resumeDetailsHeadingBtn}
										onClick={() => {
											const dataToCopy = editMode[section] ? tempData[section] : data[section];
											navigator.clipboard.writeText(dataToCopy);
											setCopySuccess(true);
											setTimeout(() => setCopySuccess(false), 1000);
										}}
									>
										{copySuccess ? (
											<IoCheckmark className={styles.resumeDetailsIcon} />
										) : (
											<FiCopy className={styles.resumeDetailsIcon} />
										)}
										{pageText.Copy}
									</button>
								)} */}
				{/*  */}
				{/* {resumeCreatorFlag && data[section] && (
								<button
									type="button"
									className={styles.resumeDetailsHeadingBtn}
									onClick={async () => {
										setIsAddingToTemplate(true);
										await applySectionToTemplate(section, data[section], null, null);
										if (section === "summary") {
											await new Promise((resolve) => setTimeout(resolve, 1000));
										}
										setIsAddingToTemplate(false);
										setTimeout(() => setIsAddingToTemplate("checkmark"), 0);
										setTimeout(() => setIsAddingToTemplate(false), 1000);
									}}
									disabled={isAddingToTemplate === true}
								>
									{isAddingToTemplate === true ? (
										<LoadingSpinner height={16} width={16} thickness={2} color="#00a9af" />
									) : isAddingToTemplate === "checkmark" ? (
										<IoIosCheckmark className={styles.resumeDetailsIcon} style={{ fontSize: "1.5rem", color: "#00a9af" }} />
									) : (
										<FaArrowRight className={styles.resumeDetailsIcon} />
									)}
									{pageText.ApplyToTemplate}
								</button>
							)} */}
				{/*  */}
				{/* </div>
							{editMode[section] ? (
								<>
									<textarea
										ref={textareaRef}
										className={styles.resumeDetailsTextarea}
										value={(() => {
											if (typeof tempData[section] === 'object') {
											  if (Array.isArray(tempData[section])) {
												return tempData[section].map((item, index) => {
												  return Object.entries(item)
													.filter(([key]) => key !== 'id')
													.map(([key, value]) => {
													  const formattedKey = key.replace(/([A-Z])/g, ' $1').toLowerCase();
													  const capitalizedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
													  if (Array.isArray(value)) {
														return `${capitalizedKey}:\n${value.join('\n')}`;
													  }
													  return `${capitalizedKey}: ${value}`;
													})
													.join('\n');
												}).join('\n\n');
											  } else {
												return Object.entries(tempData[section])
												  .filter(([key]) => key !== 'id')
												  .map(([key, value]) => {
													const formattedKey = key.replace(/([A-Z])/g, ' $1').toLowerCase();
													const capitalizedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
													if (Array.isArray(value)) {
													  return `${capitalizedKey}:\n${value.join('\n')}`;
													}
													return `${capitalizedKey}: ${value}`;
												  })
												  .join('\n\n');
											  }
											} else if (typeof tempData[section] === 'string') {
											  return tempData[section];
											}
											return '';
										  })()}
										onChange={(e) => {
											handleTempUpdate(section, e.target.value, tempDataSetter);
											adjustTextareaHeight();
										}}
									/>
									<div className={styles.resumeDetailsBtnContainer}>
										<button
											type="button"
											className={`${styles.resumeDetailsBtn} ${styles.cancel}`}
											onClick={() => toggleEdit(section)}
										>
											{pageText.Cancel}
										</button>
										<button
											type="button"
											className={`${styles.resumeDetailsBtn} ${
												tempData[section] !== data[section] ? styles.confirm : styles.disabled
											}`}
											disabled={tempData[section] === data[section]}
											onClick={() => {
												handleSave(section, dataSetter, dataStatusSetter);
												setSectionSaved(true);
												console.log(data);
											}}
										>
											{pageText.Save}
										</button>
									</div>
								</>
							) : (
								<div className={styles.resumeDetailsText}>
									{data && data[section] && (
										<div className={styles.resumeDetailsText}>
											{(() => {
												if (typeof data[section] === "object") {
													if (Array.isArray(data[section])) {
														return data[section].map((item, index) => (
															<div key={index} className={styles.resumeDataItem}>
																{Object.entries(item).map(([key, value]) => {
																	if (key === "id") return null;
																	const formattedKey = key.replace(/([A-Z])/g, " $1").toLowerCase();
																	const capitalizedKey =
																		formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
																	return (
																		<p key={key} className={styles.resumeDataText}>
																			<strong>{capitalizedKey}:</strong>{" "}
																			{Array.isArray(value) ? value.join(", ") : value}
																		</p>
																	);
																})}
															</div>
														));
													} else {
														return Object.entries(data[section]).map(([key, value]) => {
															if (key === "id") return null;
															const formattedKey = key.replace(/([A-Z])/g, " $1").toLowerCase();
															const capitalizedKey = formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
															return (
																<p key={key} className={styles.resumeDataText}>
																	<strong>{capitalizedKey}:</strong>{" "}
																	{Array.isArray(value)
																		? value.map((item, index) => (
																				<span key={index}>
																					{item}
																					{index < value.length - 1 && <br />}
																				</span>
																		  ))
																		: value}
																</p>
															);
														});
													}
												} else if (typeof data[section] === "string") {
													return data[section].split("\n").map((item, index) => (
														<p key={index} className={styles.resumeDataText}>
															{item}
															<br />
														</p>
													));
												}
												return null;
											})()}
										</div>
									)}
									{data && !data[section] && <p className={styles.gptAssistTextLoading}>[{pageText.MissingSection}]</p>}
								</div>
							)}
						</div>
					</>
				)} */}

				<div className={`${styles[`gptAssistContainer_${!isFormSelected}`]}`} onClick={handleContainerClick}>
					<p className={styles.resumeDetailsHeading}>
						InStage{" "}
						{userLanguage !== "en"
							? typeof sectionTitleText === "string"
								? sectionTitleText.charAt(0).toUpperCase() + sectionTitleText.slice(1)
								: ""
							: section
							? section.charAt(0).toUpperCase() + section.slice(1)
							: ""}
					</p>
					<div className={styles.gptAssistTabContainer}>
						{sections.map((section, index) => (
							<button
								type="button"
								ref={tabsRef.current[index]}
								key={section.key}
								className={`${styles.gptAssistTab} ${activeSection === section.key ? styles.activeGptAssistTab : ""}`}
								onClick={() => handleTabClick(section.key, index)}
							>
								{window.innerWidth > 1000 ? section.icon : ""}
								{section.title}
							</button>
						))}
					</div>
					{activeSection === 0 && (
						<div className={styles.gptAssist}>
							<p
								className={`${styles.gptAssistText} ${
									allFeedbackLoading || sectionFeedbackLoading[section] ? styles.gptAssistTextLoading : ""
								}`}
							>
								{sectionFeedbackText?.split("\n").map((item, index) => (
									<React.Fragment key={index}>
										{item}
										<br />
									</React.Fragment>
								))}
							</p>
							<div className={styles.gptAssistTextBtnContainer}>
								{allFeedbackLoading || sectionFeedbackLoading[section] ? (
									<div className={styles.spinnerContainerGptAssist}>
										<LoadingSpinner height={46} width={46} thickness={5} color="#00a9af" />
									</div>
								) : (
									<>
										<div className={styles.tooltipContainer}>
											<button
												type="button"
												className={`${styles.gptAssistTextBtn} ${
													editOrSave === "save" ? styles.gptAssistTextBtnDisabled : ""
												}`}
												onClick={() => resumeFeedbackSection(section)}
												disabled={editOrSave === "save"}
											>
												<FiRotateCcw className={styles.gptAssistBtnIcon} />
												{/* Regenerate */}
											</button>
											<span className={styles.tooltipTextLeft} style={{ marginLeft: "4rem" }}>
												{editOrSave === "save" ? "Save changes to regenerate" : "Regenerate feedback"}
											</span>
										</div>
										<ThumbButton
											type="up"
											docType={docType}
											isClicked={isThumbClicked.feedback === true}
											onClick={(e) => {
												e.stopPropagation();
												handleThumbClickSection("feedback", true, gptAssistData?.[section]?.feedback_request_id, section);
											}}
										/>
										<ThumbButton
											type="down"
											docType={docType}
											isClicked={isThumbClicked.feedback === false}
											onClick={(e) => {
												e.stopPropagation();
												handleThumbClickSection("feedback", false, gptAssistData?.[section]?.feedback_request_id, section);
											}}
										/>
									</>
								)}
							</div>
						</div>
					)}
					{activeSection === 1 && (
						<>
							<div className={styles.gptAssist}>
								<p className={`${styles.gptAssistText} ${sectionRewriteLoading[section] ? styles.gptAssistTextLoading : ""}`}>
									{gptAssistData?.[section]?.rewrite?.split("\n").map((item, index) => (
										<React.Fragment key={index}>
											{item}
											<br />
										</React.Fragment>
									))}
									{!gptAssistData?.[section]?.rewrite && (
										<span className={styles.gptAssistTextLoading}>{pageText.ClickGenerate}</span>
									)}
								</p>
								<div className={styles.gptAssistTextBtnContainer}>
									{sectionRewriteLoading[section] && (
										<div className={styles.spinnerContainerGptAssist}>
											<LoadingSpinner height={46} width={46} thickness={5} color="#00a9af" />
										</div>
									)}
									{!sectionRewriteLoading[section] && gptAssistData?.[section]?.rewrite && (
										<>
											<button
												type="button"
												className={styles.gptAssistTextBtn}
												onClick={async () => {
													setIsAddingToTemplate(true);
													if (docType === "resume") {
														// if (section === "summary") {
														// 	setResumeTemplateData((prevData) => ({
														// 		...prevData,
														// 		summary: { summary: gptAssistData?.[section]?.rewrite }
														// 	}));
														// 	setEditOrSave("save");
														// 	setIsAddingToTemplate(false);
														// 	return;
														// }
														await applySectionToTemplate(section, gptAssistData?.[section]?.rewrite, null, null, docType);
													} else if (docType === "cover_letter") {
														if (section === "contact") {
															await applySectionToTemplate(
																section,
																gptAssistData?.[section]?.rewrite,
																null,
																null,
																docType
															);
															setEditOrSave("save");
															setIsAddingToTemplate(false);
															setIsFormSelected(true);
															return;
														}
														setCoverLetterTemplateData((prevData) => ({
															...prevData,
															[section]: {
																[section]: gptAssistData?.[section]?.rewrite
															}
														}));
													}

													setIsAddingToTemplate(false);
													setEditOrSave("save");
													setIsFormSelected(true);
												}}
												disabled={isAddingToTemplate}
											>
												{isAddingToTemplate ? (
													<LoadingSpinner height={16} width={16} thickness={2} color="#00a9af" />
												) : (
													<>
														<FiArrowLeft className={styles.gptAssistBtnIcon} />
														{pageText.Insert}
													</>
												)}
											</button>
											<ThumbButton
												type="up"
												docType={docType}
												isClicked={isThumbClicked.rewrite === true}
												onClick={(e) => {
													e.stopPropagation();
													handleThumbClickSection("rewrite", true, gptAssistData?.[section]?.rewrite_request_id, section);
												}}
											/>
											<ThumbButton
												type="down"
												docType={docType}
												isClicked={isThumbClicked.rewrite === false}
												onClick={(e) => {
													e.stopPropagation();
													handleThumbClickSection("rewrite", false, gptAssistData?.[section]?.rewrite_request_id, section);
												}}
											/>
										</>
									)}
								</div>
							</div>
							{!gptAssistData?.[section]?.rewrite && (
								<button
									type="button"
									className={`${styles.gptAssistBtn} ${
										editMode[section] || sectionRewriteLoading[section] ? styles.gptAssistBtnDisabled : ""
									}`}
									style={{ marginTop: "1rem" }}
									disabled={editMode[section] || sectionRewriteLoading[section]}
									onClick={() => resumeRewriteSection(section)}
								>
									<BsStars className={styles.gptAssistBtnIcon} />
									{pageText.Generate}
								</button>
							)}
							{gptAssistData?.[section]?.rewrite && (
								<>
									<p className={styles.gptAssistWarningText}>Note: Rewrites are generated by AI and require human review.</p>
									<div className={styles.refineOptions} ref={refinePopupRef}>
										{refineOptions.map((option, index) => {
											if (selectedRefineOption === "" || selectedRefineOption === option.title) {
												return (
													<button
														key={option.key}
														type="button"
														className={`${styles.refineOptionBtn} ${
															editMode[section] || sectionRewriteLoading[section] ? styles.gptAssistBtnDisabled : ""
														}`}
														disabled={editMode[section] || sectionRewriteLoading[section]}
														onClick={(event) => {
															event.stopPropagation();
															refineClick(option.title);
														}}
														onKeyDown={(event) => {
															if (event.key === "Enter") {
																event.stopPropagation();
																refineClick(option.title);
															}
														}}
													>
														{option.icon}
														{option.title.charAt(0).toUpperCase() + option.title.slice(1)}
														{selectedRefineOption === option.title && (showRefinePopup ? <FaCaretUp /> : <FaCaretDown />)}
													</button>
												);
											}
											return null;
										})}
										{showRefinePopup && (
											<div className={styles.refinePopup} ref={refinePopupRef}>
												{refineOptions.map((option, index) => (
													<button
														key={option.key}
														type="button"
														onClick={(event) => {
															refineClick(option.title);
														}}
														onKeyDown={(event) => {
															if (event.key === "Enter") {
																event.stopPropagation();
																refineClick(option.title);
															}
														}}
													>
														{option.icon}
														{option.title.charAt(0).toUpperCase() + option.title.slice(1)}
														{selectedRefineOption === option.title && <IoCheckmark />}
													</button>
												))}
											</div>
										)}
									</div>
								</>
							)}
						</>
					)}
					{activeSection === 2 && (
						<div className={styles.jobDetailsContainer}>
							<div className={styles.jobDetailsBtnContainer}>
								<FiEdit2
									className={styles.resumeDetailsIcon}
									onClick={() => {
										setShowEditModal(true);
									}}
								/>
							</div>
							{detailsArray.map((item, index) => (
								<div key={index} className={styles.jobDetails}>
									<p className={styles.inputHeading} style={{ height: "2rem" }}>
										{item.heading}
									</p>
									{(() => {
										if (Array.isArray(item.text)) {
											return item.text.map((line, index) => (
												<p key={index} className={styles.inputText}>
													• {line}
												</p>
											));
										}
										if (typeof item.text === "string") {
											return item.text.split("\n").map((line, index) => (
												<p key={index} className={styles.inputText}>
													{line.startsWith("- ") ? `• ${line.slice(2)}` : line}
												</p>
											));
										}
										return item.text;
									})()}
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		</>
	);
}
