import React, { useState, useEffect, useContext } from "react";
import { PiSparkleBold, PiPencil, PiSave } from "react-icons/pi";
import { FaRegTrashAlt } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import styles from "../../styleModules/resumeCreatorForm.module.css";
import { LoadingSpinner } from "./LoadingSpinner.jsx";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { FiEdit, FiSave, FiX } from "react-icons/fi";
import { FaLessThanEqual } from "react-icons/fa/index.esm.js";

// eslint-disable-next-line
const ResumeCreatorForm = ({
	wide = false,
	isFormSelected,
	setIsFormSelected,
	type,
	resumeData: templateResumeData, // used for both resume and cover letter
	setResumeData: setTemplateResumeData, // used for both resume and cover letter
	selectedSection,
	editOrSave,
	setEditOrSave,
	setupData,
	setResumeDataChanged
}) => {
	const [activeSection, setActiveSection] = useState(selectedSection);
	const [isSaving, setIsSaving] = useState(false);
	const [tempData, setTempData] = useState(templateResumeData);

	const { resumeData, setResumeData, coverLetterData, setCoverLetterData, localUser } = useContext(AppContext);

	const url = process.env.REACT_APP_BACKEND_STATIC_URL;

	// useEffect(() => {
	// 	console.log("selectedSection", selectedSection);
	// }, [selectedSection]);

	// useEffect(() => {
	// 	console.log("resumeData test", resumeData);
	// }, [resumeData]);

	// useEffect(() => {
	// 	console.log("setupData", setupData);
	// }, [setupData]);

	// useEffect(() => {
	// 	console.log("templateResumeData", templateResumeData);
	// }, [templateResumeData]);

	// useEffect(() => {
	// 	setTemplateResumeData(templateResumeData);
	// }, [templateResumeData]);

	const [resumeCreatorRewriteSections, setResumeCreatorRewriteSections] = useState({
		summary: "",
		experience: {},
		extraCurricular: {},
		volunteer: {}
	});
	const { axiosLimitedPost } = useAxiosLimited();

	const handleInputChange = (section, field, value, index = 0, subIndex = null) => {
		// console.log("section", section);
		// console.log("field", field);
		// console.log("value", value);
		// console.log("type", type);
		if (type === "resume") {
			// resume
			setTemplateResumeData((prevData) => {
				if (section === "summary") {
					return { ...prevData, summary: { summary: value } };
				}
				if (section === "extraCurricular") {
					const newArray = [...prevData.extraCurricular];
					if (subIndex !== null) {
						newArray[index] = {
							...newArray[index],
							[field]: [...newArray[index][field].slice(0, subIndex), value, ...newArray[index][field].slice(subIndex + 1)]
						};
					} else {
						newArray[index] = { ...newArray[index], [field]: value };
					}
					return { ...prevData, extraCurricular: newArray };
				}
				if (section === "volunteer") {
					const newArray = [...prevData.volunteer];
					if (subIndex !== null) {
						newArray[index] = {
							...newArray[index],
							[field]: [...newArray[index][field].slice(0, subIndex), value, ...newArray[index][field].slice(subIndex + 1)]
						};
					} else {
						newArray[index] = { ...newArray[index], [field]: value };
					}
					return { ...prevData, volunteer: newArray };
				}
				if (Array.isArray(prevData[section])) {
					const newArray = [...prevData[section]];
					if (subIndex !== null) {
						newArray[index] = {
							...newArray[index],
							[field]: [...newArray[index][field].slice(0, subIndex), value, ...newArray[index][field].slice(subIndex + 1)]
						};
					} else {
						newArray[index] = { ...newArray[index], [field]: value };
					}
					return { ...prevData, [section]: newArray };
				} else if (section === "skills") {
					return {
						...prevData,
						skills: {
							...prevData.skills,
							[field]: prevData.skills[field].map((item, i) => (i === index ? value : item))
						}
					};
				} else if (typeof prevData[section] === "object") {
					return { ...prevData, [section]: { ...prevData[section], [field]: value } };
				}
				return prevData;
			});
		} else {
			// cover letter
			setTemplateResumeData((prevData) => {
				if (section === "contact") {
					return {
						...prevData,
						contact: {
							...prevData.contact,
							[field]: value
						}
					};
				} else {
					return {
						...prevData,
						[section]: {
							...prevData[section],
							[field]: value
						}
					};
				}
			});

			console.log("templateCoverLetterData", templateResumeData);
			console.log("section", section);
			console.log("field", field);
			console.log("value", value);
		}
		// console.log("Setting resumeData to templateResumeData", templateResumeData);
		// setResumeData(templateResumeData);
	};

	const addItem = (section) => {
		setTemplateResumeData((prevData) => {
			if (section === "extraCurricular") {
				const newItem = {
					id: Date.now(),
					activity: "",
					orgName: "",
					startMonth: "",
					startYear: "",
					endMonth: "",
					endYear: "",
					tasks: [],
					isVolunteer: section === "volunteer"
				};
				return {
					...prevData,
					extraCurricular: [...prevData.extraCurricular, newItem]
				};
			} else if (section === "volunteer") {
				const newItem = {
					id: Date.now(),
					activity: "",
					orgName: "",
					startMonth: "",
					startYear: "",
					endMonth: "",
					endYear: "",
					tasks: [],
					isVolunteer: section === "volunteer"
				};
				return {
					...prevData,
					volunteer: [...prevData.volunteer, newItem]
				};
			} else {
				return {
					...prevData,
					[section]: [
						...prevData[section],
						section === "experience"
							? {
									id: Date.now(),
									jobTitle: "",
									companyName: "",
									city: "",
									stateOrProv: "",
									startMonth: "",
									startYear: "",
									endMonth: "",
									endYear: "",
									roles: []
							  }
							: section === "education"
							? {
									id: Date.now(),
									school: "",
									degree: "",
									program: "",
									graduatingYear: ""
							  }
							: ""
					]
				};
			}
		});
	};

	const addRole = (experienceIndex) => {
		setTemplateResumeData((prevData) => {
			const newExperience = [...prevData.experience];
			newExperience[experienceIndex].roles.push("");
			return { ...prevData, experience: newExperience };
		});
	};

	const addTask = (activityIndex, isVolunteer = false) => {
		if (isVolunteer) {
			setTemplateResumeData((prevData) => {
				const newVolunteer = [...prevData.volunteer];
				newVolunteer[activityIndex].tasks.push("");
				return { ...prevData, volunteer: newVolunteer };
			});
		} else {
			setTemplateResumeData((prevData) => {
				const newExtraCurricular = [...prevData.extraCurricular];
				const targetIndex = isVolunteer
					? newExtraCurricular.findIndex((activity) => activity.isVolunteer && activity.id === newExtraCurricular[activityIndex].id)
					: activityIndex;

				if (targetIndex !== -1) {
					newExtraCurricular[targetIndex] = {
						...newExtraCurricular[targetIndex],
						tasks: [...newExtraCurricular[targetIndex].tasks, ""]
					};
				}

				return { ...prevData, extraCurricular: newExtraCurricular };
			});
		}
	};

	const addSkill = (skillType) => {
		setTemplateResumeData((prevData) => ({
			...prevData,
			skills: {
				...prevData.skills,
				[skillType]: [...prevData.skills[skillType], ""]
			}
		}));
	};

	const [loadingRewrite, setLoadingRewrite] = useState({});

	const rewriteSection = async (section, sectionInput, index = null, subIndex = null) => {
		setLoadingRewrite({ [section]: { [index]: { [subIndex]: true } } });

		const sendToPromptLayer = async () => {
			const data = {
				sectionTitle: section,
				sectionData: sectionInput
			};

			try {
				const resp = await axiosLimitedPost(
					`${process.env.REACT_APP_BACKEND_STATIC_URL}/api/resume-assist/resumeCreatorRewriteSection`,
					data,
					1,
					{},
					30000
				);
				setResumeCreatorRewriteSections((prevSections) => {
					if (section === "experience" || section === "extraCurricular" || section === "volunteer") {
						return {
							...prevSections,
							[section]: {
								...prevSections[section],
								[index]: {
									...prevSections[section][index],
									[subIndex]: resp.data
								}
							}
						};
					}
					return {
						...prevSections,
						[section]: resp.data
					};
				});
			} catch (err) {
				console.log(err);
			} finally {
				setLoadingRewrite({});
			}
		};

		return sendToPromptLayer();
	};

	const removeRole = (experienceIndex, roleIndex) => {
		setTemplateResumeData((prevData) => {
			const newExperience = [...prevData.experience];
			newExperience[experienceIndex].roles = newExperience[experienceIndex].roles.filter((_, index) => index !== roleIndex);
			return { ...prevData, experience: newExperience };
		});
	};

	const removeTask = (activityIndex, taskIndex, isVolunteer) => {
		if (isVolunteer) {
			setTemplateResumeData((prevData) => {
				const newVolunteer = [...prevData.volunteer];
				newVolunteer[activityIndex].tasks = newVolunteer[activityIndex].tasks.filter((_, index) => index !== taskIndex);
				return { ...prevData, volunteer: newVolunteer };
			});
		} else {
			setTemplateResumeData((prevData) => {
				const newExtraCurricular = [...prevData.extraCurricular];
				const targetIndex = newExtraCurricular.findIndex(
					(activity) => activity.id === newExtraCurricular[activityIndex].id && activity.isVolunteer === isVolunteer
				);
				if (targetIndex !== -1) {
					newExtraCurricular[targetIndex].tasks = newExtraCurricular[targetIndex].tasks.filter((_, index) => index !== taskIndex);
				}
				return { ...prevData, extraCurricular: newExtraCurricular };
			});
		}
	};

	const removeTaskExtracurricular = (activityIndex, taskIndex) => {
		setTemplateResumeData((prevData) => {
			const newExtraCurricular = [...prevData.extraCurricular];
			newExtraCurricular[activityIndex].tasks = newExtraCurricular[activityIndex].tasks.filter((_, index) => index !== taskIndex);
			return { ...prevData, extraCurricular: newExtraCurricular };
		});
	};

	const removeSkill = (skillType, index) => {
		setTemplateResumeData((prevData) => ({
			...prevData,
			skills: {
				...prevData.skills,
				[skillType]: prevData.skills[skillType].filter((_, i) => i !== index)
			}
		}));
	};

	// eslint-disable-next-line
	const renderContactForm = () =>
		type === "resume" ? (
			<div className={styles.formGroup}>
				<h4 className={styles.formLabel}>First Name:</h4>
				<input
					className={styles.input}
					placeholder="First Name"
					value={templateResumeData.contact.firstName || ""}
					onChange={(e) => handleInputChange("contact", "firstName", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Last Name:</h4>
				<input
					className={styles.input}
					placeholder="Last Name"
					value={templateResumeData.contact.lastName || ""}
					onChange={(e) => handleInputChange("contact", "lastName", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Email Address:</h4>
				<input
					className={styles.input}
					placeholder="Email Address"
					type="email"
					value={templateResumeData.contact.email || ""}
					onChange={(e) => handleInputChange("contact", "email", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Address:</h4>
				<input
					className={styles.input}
					placeholder="Address"
					value={templateResumeData.contact.address || ""}
					onChange={(e) => handleInputChange("contact", "address", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Phone Number:</h4>
				<input
					className={styles.input}
					placeholder="Phone Number"
					type="tel"
					value={templateResumeData.contact.phone || ""}
					onChange={(e) => handleInputChange("contact", "phone", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>LinkedIn Profile:</h4>
				<input
					className={styles.input}
					placeholder="LinkedIn Profile"
					value={templateResumeData.contact.linkedinURL || ""}
					onChange={(e) => handleInputChange("contact", "linkedinURL", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
			</div>
		) : (
			<div className={styles.formGroup}>
				<h4 className={styles.formLabel}>First Name:</h4>
				<input
					className={styles.input}
					placeholder="First Name"
					value={templateResumeData.contact?.firstName || ""}
					onChange={(e) => handleInputChange("contact", "firstName", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Last Name:</h4>
				<input
					className={styles.input}
					placeholder="Last Name"
					value={templateResumeData.contact?.lastName || ""}
					onChange={(e) => handleInputChange("contact", "lastName", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Email Address:</h4>
				<input
					className={styles.input}
					placeholder="Email Address"
					type="email"
					value={templateResumeData.contact?.email || ""}
					onChange={(e) => handleInputChange("contact", "email", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Address:</h4>
				<input
					className={styles.input}
					placeholder="Address"
					value={templateResumeData.contact?.address || ""}
					onChange={(e) => handleInputChange("contact", "address", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Phone Number:</h4>
				<input
					className={styles.input}
					placeholder="Phone Number"
					type="tel"
					value={templateResumeData.contact?.phone || ""}
					onChange={(e) => handleInputChange("contact", "phone", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Hiring Manager Name:</h4>
				<input
					className={styles.input}
					placeholder="Hiring Manager Name"
					value={templateResumeData.contact?.hiring_manager_name || ""}
					onChange={(e) => handleInputChange("contact", "hiring_manager_name", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Company Name:</h4>
				<input
					className={styles.input}
					placeholder="Company Name"
					value={templateResumeData.contact?.company || ""}
					onChange={(e) => handleInputChange("contact", "company", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
				<h4 className={styles.formLabel}>Company Address:</h4>
				<input
					className={styles.input}
					placeholder="Company Address"
					value={templateResumeData.contact?.company_address || ""}
					onChange={(e) => handleInputChange("contact", "company_address", e.target.value)}
					readOnly={editOrSave === "edit"}
				/>
			</div>
		);

	const renderIntroForm = () => (
		<>
			<h4 className={styles.formLabel}>Introduction:</h4>
			<textarea
				className={styles.textarea}
				placeholder="Introduction"
				value={templateResumeData.intro?.intro || ""}
				onChange={(e) => handleInputChange("intro", "intro", e.target.value)}
				readOnly={editOrSave === "edit"}
			/>
		</>
	);
	const renderBodyForm = () => (
		<>
			<h4 className={styles.formLabel}>Body:</h4>
			<textarea
				className={styles.textarea}
				placeholder="Body"
				value={templateResumeData.body?.body || ""}
				onChange={(e) => handleInputChange("body", "body", e.target.value)}
				readOnly={editOrSave === "edit"}
			/>
		</>
	);
	const renderSummaryForm = () => (
		<>
			<h4 className={styles.formLabel}>Summary:</h4>
			<textarea
				className={styles.textarea}
				placeholder="Professional Summary"
				value={typeof templateResumeData.summary === "object" ? templateResumeData.summary.summary : templateResumeData.summary}
				onChange={(e) => handleInputChange("summary", null, e.target.value)}
				readOnly={editOrSave === "edit"}
			/>
			<div>
				{/* <button
					type="button"
					className={styles.button}
					onClick={() => rewriteSection("summary", templateResumeData.summary.summary)}
					disabled={editOrSave === "edit"}
				>
					<PiSparkleBold style={{ marginRight: "10px" }} />
					Rewrite
				</button> */}
				{resumeCreatorRewriteSections.summary && (
					<textarea
						className={styles.rewriteText}
						value={resumeCreatorRewriteSections.summary}
						onChange={(e) =>
							setResumeCreatorRewriteSections({
								...resumeCreatorRewriteSections,
								summary: e.target.value
							})
						}
						readOnly={editOrSave === "edit"}
					/>
				)}
			</div>
		</>
	);

	const renderExperienceForm = () => (
		<div>
			{/* {console.log("resumeData.experience: ", templateResumeData.experience)} */}
			{templateResumeData.experience.map((exp, index) => (
				<>
					<h5>Work Experience {index + 1}</h5>
					<div key={exp.id || index} className={styles.experienceItem}>
						<h4 className={styles.formLabel}>Job Title:</h4>
						<input
							className={styles.input}
							placeholder="Job Title"
							value={exp.jobTitle || ""}
							onChange={(e) => handleInputChange("experience", "jobTitle", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>Company Name:</h4>
						<input
							className={styles.input}
							placeholder="Company Name"
							value={exp.companyName || ""}
							onChange={(e) => handleInputChange("experience", "companyName", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>City:</h4>
						<input
							className={styles.input}
							placeholder="City"
							value={exp.city || ""}
							onChange={(e) => handleInputChange("experience", "city", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>State/Province:</h4>
						<input
							className={styles.input}
							placeholder="State/Province"
							value={exp.stateOrProv || ""}
							onChange={(e) => handleInputChange("experience", "stateOrProv", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>Start Month:</h4>
						<input
							className={styles.input}
							placeholder="Start Month"
							value={exp.startMonth || ""}
							onChange={(e) => handleInputChange("experience", "startMonth", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>Start Year:</h4>
						<input
							className={styles.input}
							placeholder="Start Year"
							value={exp.startYear || ""}
							onChange={(e) => handleInputChange("experience", "startYear", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>End Month:</h4>
						<input
							className={styles.input}
							placeholder="End Month"
							value={exp.endMonth || ""}
							onChange={(e) => handleInputChange("experience", "endMonth", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>End Year:</h4>
						<input
							className={styles.input}
							placeholder="End Year"
							value={exp.endYear || ""}
							onChange={(e) => handleInputChange("experience", "endYear", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<div className={styles.indentedField}>
							<h4 className={styles.formLabel}>Roles:</h4>
							{(exp.roles || []).map((role, roleIndex) => (
								<>
									<div key={`role-${exp.id}-${roleIndex}`}>
										<div className={styles.roleItem}>
											<input
												className={styles.input}
												placeholder={`Role ${roleIndex + 1}`}
												value={role}
												onChange={(e) => handleInputChange("experience", "roles", e.target.value, index, roleIndex)}
												readOnly={editOrSave === "edit"}
											/>
											<div className={styles.buttonGroup}>
												<button
													type="button"
													className={styles.removeButtonSmall}
													onClick={() => removeRole(index, roleIndex)}
													title="Remove Role"
													disabled={editOrSave === "edit"}
												>
													<FaRegTrashAlt />
												</button>
												{/* <button
													type="button"
													className={styles.rewriteButtonSmall}
													onClick={() => rewriteSection("experience", role, index, roleIndex)}
													title="AI-Rewrite"
													disabled={loadingRewrite?.experience?.[index]?.[roleIndex] || editOrSave === "edit"}
												>
													{loadingRewrite?.experience?.[index]?.[roleIndex] ? (
														<LoadingSpinner height={16} width={16} thickness={2} color="white" />
													) : (
														<PiSparkleBold />
													)}
												</button> */}
											</div>
										</div>
										{resumeCreatorRewriteSections.experience?.[index]?.[roleIndex] && (
											<textarea
												className={styles.rewriteText}
												value={resumeCreatorRewriteSections.experience[index][roleIndex]}
												onChange={(e) => {
													setResumeCreatorRewriteSections((prev) => ({
														...prev,
														experience: {
															...prev.experience,
															[index]: {
																...prev.experience[index],
																[roleIndex]: e.target.value
															}
														}
													}));
												}}
												readOnly={editOrSave === "edit"}
											/>
										)}
									</div>
								</>
							))}
							<button type="button" className={styles.addButton} onClick={() => addRole(index)} disabled={editOrSave === "edit"}>
								Add Role
							</button>
						</div>
						<button
							type="button"
							className={styles.removeButton}
							onClick={() => removeItem("experience", index)}
							disabled={editOrSave === "edit"}
						>
							Remove Experience
						</button>
						<hr />
					</div>
				</>
			))}
			<button type="button" className={styles.button} onClick={() => addItem("experience")} disabled={editOrSave === "edit"}>
				Add Experience
			</button>
		</div>
	);

	const renderSkillsForm = () => (
		<div>
			<h4 className={styles.formLabel} style={{ marginLeft: "20px" }}>
				Technical Skills:
			</h4>
			<div className={styles.indentedField}>
				{templateResumeData.skills.technical.map((skill, index) => (
					<div key={`technical-${index}`} className={styles.skillItem}>
						<input
							className={styles.input}
							placeholder={`Technical Skill ${index + 1}`}
							value={skill}
							onChange={(e) => handleInputChange("skills", "technical", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<button
							type="button"
							className={styles.removeButtonSmall}
							onClick={() => removeSkill("technical", index)}
							disabled={editOrSave === "edit"}
						>
							<FaRegTrashAlt />
						</button>
					</div>
				))}
				<button type="button" className={styles.button} onClick={() => addSkill("technical")} disabled={editOrSave === "edit"}>
					Add Technical Skill
				</button>
			</div>
			<br />
			<h4 className={styles.formLabel} style={{ marginLeft: "20px" }}>
				Core Competencies:
			</h4>
			<div className={styles.indentedField}>
				{templateResumeData.skills.coreCompetencies.map((skill, index) => (
					<div key={`competency-${index}`} className={styles.skillItem}>
						<input
							className={styles.input}
							placeholder={`Core Competency ${index + 1}`}
							value={skill}
							onChange={(e) => handleInputChange("skills", "coreCompetencies", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<button
							type="button"
							className={styles.removeButtonSmall}
							onClick={() => removeSkill("coreCompetencies", index)}
							disabled={editOrSave === "edit"}
						>
							<FaRegTrashAlt />
						</button>
					</div>
				))}
				<button type="button" className={styles.button} onClick={() => addSkill("coreCompetencies")} disabled={editOrSave === "edit"}>
					Add Core Competency
				</button>
			</div>
		</div>
	);

	const renderEducationForm = () => (
		<div>
			{templateResumeData.education.map((edu, index) => (
				<>
					<h5>Education {index + 1}</h5>
					<div key={edu.id || index} className={styles.educationItem}>
						<h4 className={styles.formLabel}>School:</h4>
						<input
							className={styles.input}
							placeholder="School"
							value={edu.school || ""}
							onChange={(e) => handleInputChange("education", "school", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>Degree:</h4>
						<input
							className={styles.input}
							placeholder="Degree"
							value={edu.degree || ""}
							onChange={(e) => handleInputChange("education", "degree", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>Program:</h4>
						<input
							className={styles.input}
							placeholder="Program"
							value={edu.program || ""}
							onChange={(e) => handleInputChange("education", "program", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>Graduating Year:</h4>
						<input
							className={styles.input}
							placeholder="Graduating Year"
							value={edu.graduatingYear || ""}
							onChange={(e) => handleInputChange("education", "graduatingYear", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<button
							type="button"
							className={styles.removeButton}
							onClick={() => removeItem("education", index)}
							disabled={editOrSave === "edit"}
						>
							Remove Education
						</button>
						<hr />
					</div>
				</>
			))}
			<button type="button" className={styles.button} onClick={() => addItem("education")} disabled={editOrSave === "edit"}>
				Add Education
			</button>
		</div>
	);

	const renderExtraCurricularForm = () => (
		<div>
			{templateResumeData.extraCurricular.map((activity, index) => (
				<>
					<h5>Extra Curricular Activity {index + 1}</h5>
					<div key={activity.id} className={styles.extraCurricularItem}>
						<h4 className={styles.formLabel}>Activity:</h4>
						<input
							className={styles.input}
							placeholder="Activity"
							value={activity.activity}
							onChange={(e) => handleInputChange("extraCurricular", "activity", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>Organization Name:</h4>
						<input
							className={styles.input}
							placeholder="Organization Name"
							value={activity.orgName}
							onChange={(e) => handleInputChange("extraCurricular", "orgName", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>Start Month:</h4>
						<input
							className={styles.input}
							placeholder="Start Month"
							value={activity.startMonth}
							onChange={(e) => handleInputChange("extraCurricular", "startMonth", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>Start Year:</h4>
						<input
							className={styles.input}
							placeholder="Start Year"
							value={activity.startYear}
							onChange={(e) => handleInputChange("extraCurricular", "startYear", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>End Month:</h4>
						<input
							className={styles.input}
							placeholder="End Month"
							value={activity.endMonth}
							onChange={(e) => handleInputChange("extraCurricular", "endMonth", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<h4 className={styles.formLabel}>End Year:</h4>
						<input
							className={styles.input}
							placeholder="End Year"
							value={activity.endYear}
							onChange={(e) => handleInputChange("extraCurricular", "endYear", e.target.value, index)}
							readOnly={editOrSave === "edit"}
						/>
						<div className={styles.indentedField}>
							<h4 className={styles.formLabel}>Tasks:</h4>
							{activity.tasks.map((task, taskIndex) => (
								<div key={`task-${activity.id}-${taskIndex}`}>
									<div className={styles.taskItem}>
										<input
											className={styles.input}
											placeholder={`Task ${taskIndex + 1}`}
											value={task}
											onChange={(e) => handleInputChange("extraCurricular", "tasks", e.target.value, index, taskIndex)}
											readOnly={editOrSave === "edit"}
										/>
										<div className={styles.buttonGroup}>
											<button
												type="button"
												className={styles.removeButtonSmall}
												onClick={() => removeTaskExtracurricular(index, taskIndex)}
												title="Remove Task"
												disabled={editOrSave === "edit"}
											>
												<FaRegTrashAlt />
											</button>
											{/* <button
												type="button"
												className={styles.rewriteButtonSmall}
												onClick={() => rewriteSection("extraCurricular", task, index, taskIndex)}
												title="AI-Rewrite"
												disabled={loadingRewrite?.extraCurricular?.[index]?.[taskIndex] || editOrSave === "edit"}
											>
												{loadingRewrite?.extraCurricular?.[index]?.[taskIndex] ? (
													<LoadingSpinner height={16} width={16} thickness={2} color="white" />
												) : (
													<PiSparkleBold />
												)}
											</button> */}
										</div>
									</div>
									{resumeCreatorRewriteSections.extraCurricular?.[index]?.[taskIndex] && (
										<textarea
											className={styles.rewriteText}
											value={resumeCreatorRewriteSections.extraCurricular[index][taskIndex]}
											onChange={(e) => {
												setResumeCreatorRewriteSections((prev) => ({
													...prev,
													extraCurricular: {
														...prev.extraCurricular,
														[index]: {
															...prev.extraCurricular[index],
															[taskIndex]: e.target.value
														}
													}
												}));
											}}
											readOnly={editOrSave === "edit"}
										/>
									)}
								</div>
							))}
							<button type="button" className={styles.addButton} onClick={() => addTask(index, false)} disabled={editOrSave === "edit"}>
								Add Task
							</button>
						</div>
						<button
							type="button"
							className={styles.removeButton}
							onClick={() => removeItem("extraCurricular", index)}
							disabled={editOrSave === "edit"}
						>
							Remove Extra-Curricular
						</button>
						<hr />
					</div>
				</>
			))}
			<button type="button" className={styles.button} onClick={() => addItem("extraCurricular")} disabled={editOrSave === "edit"}>
				Add Extra-Curricular Activity
			</button>
		</div>
	);

	const renderVolunteerForm = () => (
		<div>
			{templateResumeData.volunteer.map((activity, filteredIndex) => {
				const originalIndex = templateResumeData.volunteer.findIndex((item) => item.id === activity.id);
				return (
					<>
						<h5>Volunteer Experience {filteredIndex + 1}</h5>
						<div key={activity.id} className={styles.volunteerItem}>
							<h4 className={styles.formLabel}>Activity:</h4>
							<input
								className={styles.input}
								placeholder="Activity"
								value={activity.activity}
								onChange={(e) => handleInputChange("volunteer", "activity", e.target.value, originalIndex)}
								readOnly={editOrSave === "edit"}
							/>
							<h4 className={styles.formLabel}>Organization Name:</h4>
							<input
								className={styles.input}
								placeholder="Organization Name"
								value={activity.orgName}
								onChange={(e) => handleInputChange("volunteer", "orgName", e.target.value, originalIndex)}
								readOnly={editOrSave === "edit"}
							/>
							<h4 className={styles.formLabel}>Start Month:</h4>
							<input
								className={styles.input}
								placeholder="Start Month"
								value={activity.startMonth}
								onChange={(e) => handleInputChange("volunteer", "startMonth", e.target.value, originalIndex)}
								readOnly={editOrSave === "edit"}
							/>
							<h4 className={styles.formLabel}>Start Year:</h4>
							<input
								className={styles.input}
								placeholder="Start Year"
								value={activity.startYear}
								onChange={(e) => handleInputChange("volunteer", "startYear", e.target.value, originalIndex)}
								readOnly={editOrSave === "edit"}
							/>
							<h4 className={styles.formLabel}>End Month:</h4>
							<input
								className={styles.input}
								placeholder="End Month"
								value={activity.endMonth}
								onChange={(e) => handleInputChange("volunteer", "endMonth", e.target.value, originalIndex)}
								readOnly={editOrSave === "edit"}
							/>
							<h4 className={styles.formLabel}>End Year:</h4>
							<input
								className={styles.input}
								placeholder="End Year"
								value={activity.endYear}
								onChange={(e) => handleInputChange("volunteer", "endYear", e.target.value, originalIndex)}
								readOnly={editOrSave === "edit"}
							/>
							<div className={styles.indentedField}>
								<h4 className={styles.formLabel}>Tasks:</h4>
								{activity.tasks.map((task, taskIndex) => (
									<div key={`task-${activity.id}-${taskIndex}`}>
										<div className={styles.taskItem}>
											<input
												className={styles.input}
												placeholder={`Task ${taskIndex + 1}`}
												value={task}
												onChange={(e) => handleInputChange("volunteer", "tasks", e.target.value, originalIndex, taskIndex)}
												readOnly={editOrSave === "edit"}
											/>
											<div className={styles.buttonGroup}>
												<button
													type="button"
													className={styles.removeButtonSmall}
													onClick={() => removeTask(originalIndex, taskIndex, true)}
													title="Remove Task"
													disabled={editOrSave === "edit"}
												>
													<FaRegTrashAlt />
												</button>
												{/* <button
													type="button"
													className={styles.rewriteButtonSmall}
													onClick={() => rewriteSection("volunteer", task, originalIndex, taskIndex)}
													title="AI-Rewrite"
													disabled={loadingRewrite?.volunteer?.[originalIndex]?.[taskIndex] || editOrSave === "edit"}
												>
													{loadingRewrite?.volunteer?.[originalIndex]?.[taskIndex] ? (
														<LoadingSpinner height={16} width={16} thickness={2} color="white" />
													) : (
														<PiSparkleBold />
													)}
												</button> */}
											</div>
										</div>
										{resumeCreatorRewriteSections.volunteer?.[originalIndex]?.[taskIndex] && (
											<textarea
												className={styles.rewriteText}
												value={resumeCreatorRewriteSections.volunteer[originalIndex][taskIndex]}
												onChange={(e) => {
													setResumeCreatorRewriteSections((prev) => ({
														...prev,
														volunteer: {
															...prev.volunteer,
															[originalIndex]: {
																...prev.volunteer[originalIndex],
																[taskIndex]: e.target.value
															}
														}
													}));
												}}
												readOnly={editOrSave === "edit"}
											/>
										)}
									</div>
								))}
								<button
									type="button"
									className={styles.addButton}
									onClick={() => addTask(originalIndex, true)}
									disabled={editOrSave === "edit"}
								>
									Add Task
								</button>
							</div>
							<button
								type="button"
								className={styles.removeButton}
								onClick={() => removeItem("volunteer", filteredIndex)}
								disabled={editOrSave === "edit"}
							>
								Remove Volunteer Activity
							</button>
							<hr />
						</div>
					</>
				);
			})}
			<button type="button" className={styles.button} onClick={() => addItem("volunteer")} disabled={editOrSave === "edit"}>
				Add Volunteer Activity
			</button>
		</div>
	);

	const removeItem = (section, index) => {
		setTemplateResumeData((prevData) => {
			if (section === "extraCurricular") {
				const newArray = prevData.extraCurricular.filter((_, i) => i !== index);
				return { ...prevData, extraCurricular: newArray };
			}
			if (section === "volunteer") {
				const newArray = prevData.volunteer.filter((_, i) => i !== index);
				return { ...prevData, volunteer: newArray };
			}
			return {
				...prevData,
				[section]: prevData[section].filter((_, i) => i !== index)
			};
		});
	};

	const updateDetailsInDB = async (data) => {
		console.log("setupData", setupData);
		let updateData = {};
		if (type === "resume") {
			updateData = {
				setup_id: setupData.simulation_id,
				instage_id: localUser.instage_id,
				resume_details: data
			};
		} else {
			updateData = {
				setup_id: setupData.simulation_id,
				instage_id: localUser.instage_id,
				cover_letter_details: data,
				isCoverLetterSection: true
			};
		}

		try {
			const response = await axiosLimitedPost(`${url}/api/resume-assist/update`, updateData, 1);
			console.log("response", response);

			// posthog?.capture("instage_resume_update", { setup_id: setupData.setup_id, instage_id: localUser.instage_id });
			if (type === "resume") {
				setResumeData(updateData.resume_details);
			} else {
				setCoverLetterData(updateData.cover_letter_details);
			}
		} catch (error) {
			console.error("Error updating details:", error);
		}
	};

	const saveDetailsToDB = async (data) => {
		const saveData = {
			instage_id: localUser.instage_id,
			setup_id: setupData.simulation_id
		};

		if (type === "resume") {
			saveData.resume_details = data;
		} else {
			saveData.cover_letter_details = data;
		}

		console.log("saveData", saveData.setup_id, saveData.instage_id);

		try {
			const response = await axiosLimitedPost(`${url}/api/resume-assist/create`, saveData, 1);
			console.log("response", response);

			// if (type === "resume") {
			// 	posthog?.capture("instage_resume_save", { setup_id, instage_id: resume_user });
			// } else {
			// 	posthog?.capture("instage_cover_letter_save", { setup_id, instage_id: resume_user });
			// }

			// // setResumeData(response.data.resume_details);
			setResumeData(saveData.resume_details);
			setCoverLetterData(response.data.cover_letter_details);
		} catch (error) {
			console.error("Error saving resume details:", error);
		}
	};

	const handleSave = async () => {
		setIsSaving(true);
		if (!wide) {
			// if !wide means a resume has been uploaded so it needs to be updates and saved to the db
			if (type === "resume") {
				try {
					await updateDetailsInDB(templateResumeData);
					setEditOrSave("edit");
					setResumeData(templateResumeData);
					setResumeDataChanged(true);
				} catch (error) {
					console.error("Error saving resume data:", error);
					// Optionally, show an error message to the user
				} finally {
					setIsSaving(false);
				}
			} else {
				try {
					await updateDetailsInDB(templateResumeData);
					setEditOrSave("edit");
					setCoverLetterData(templateResumeData);
					setResumeDataChanged(true);
				} catch (error) {
					console.error("Error saving resume data:", error);
					// Optionally, show an error message to the user
				} finally {
					setIsSaving(false);
				}
			}
		} else {
			// create a new resume in the db
			if (type === "resume") {
				try {
					await saveDetailsToDB(templateResumeData);
					setResumeData(templateResumeData);
				} catch (error) {
					console.error("Error saving resume data:", error);
					// Optionally, show an error message to the user
				} finally {
					setIsSaving(false);
				}
			} else {
				try {
					await saveDetailsToDB(templateResumeData);
					setCoverLetterData(templateResumeData);
				} catch (error) {
					console.error("Error saving resume data:", error);
					// Optionally, show an error message to the user
				} finally {
					setIsSaving(false);
				}
			}
		}
		console.log("templateResumeData", templateResumeData);
	};

	const handleContainerClick = () => {
		if (wide) {
			return;
		}
		if (!isFormSelected) {
			setIsFormSelected(true);
		}
	};

	return (
		<div className={wide ? styles.mainContainerWide : styles[`mainContainer_${isFormSelected}`]} onClick={handleContainerClick}>
			<div className={styles.container}>
				{/* <div className={styles.sideNav}>
					{sections.map((section, index) => (
						<button
							type="button"
							key={section}
							className={`${styles.navButton} ${activeSection === section ? styles.activeButton : ""} ${
								isSectionFilled(section) ? styles.filledButton : styles.emptyButton
							}`}
							onClick={() => setActiveSection(section)}
						>
							<span className={styles.stepNumber}>{index + 1}</span>
							{section.charAt(0).toUpperCase() + section.slice(1)}
						</button>
					))}
				</div> */}
				<div className={styles.formContainer}>
					<div className={styles.resumeCreatorFormHeader}>
						<h1 className={styles.title}>Resume Creator</h1>
						<div className={styles.resumeCreatorFormHeaderButtons}>
							{editOrSave === "edit" ? (
								<button
									type="button"
									className={styles.editButton}
									onClick={() => {
										setEditOrSave("save");
										setTempData(templateResumeData);
									}}
								>
									<FiEdit />
									Edit
								</button>
							) : (
								<>
									<button
										type="button"
										className={`${styles.cancelButton}`}
										onClick={() => {
											setEditOrSave("edit");
											setTemplateResumeData(tempData);
										}}
									>
										<FiX />
										Cancel
									</button>
									<button type="button" className={styles.saveButton} onClick={handleSave} disabled={isSaving}>
										{isSaving ? (
											<LoadingSpinner height={16} width={16} thickness={2} color="white" />
										) : (
											<>
												<FiSave />
												Save
											</>
										)}
									</button>
								</>
							)}
						</div>
					</div>

					<div className={styles.formContent}>
						{selectedSection === "contact" && renderContactForm()}
						{selectedSection === "summary" && renderSummaryForm()}
						{selectedSection === "experience" && renderExperienceForm()}
						{selectedSection === "skills" && renderSkillsForm()}
						{selectedSection === "education" && renderEducationForm()}
						{selectedSection === "extraCurricular" && renderExtraCurricularForm()}
						{selectedSection === "volunteer" && renderVolunteerForm()}
						{selectedSection === "intro" && renderIntroForm()}
						{selectedSection === "body" && renderBodyForm()}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResumeCreatorForm;
