import React, { useState } from "react";
import { obfuscate } from "./UtilityFunctions/DataUtility";

function ObfuscationPage() {
	const [inputText, setInputText] = useState("");
	const [obfuscatedText, setObfuscatedText] = useState("");

	const handleInputChange = (event) => {
		const text = event.target.value;
		setInputText(text);
		setObfuscatedText(obfuscate(text));
	};

	return (
		<div
			className="obfuscation-page"
			style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh" }}
		>
			<h1>Text Obfuscation</h1>
			<div>
				<label htmlFor="text-input">Enter text to obfuscate:</label>
				<input type="text" id="text-input" value={inputText} onChange={handleInputChange} placeholder="Type something..." />
			</div>
			<div>
				<h2>Obfuscated Result:</h2>
				<p>{obfuscatedText}</p>
			</div>
		</div>
	);
}

export default ObfuscationPage;
