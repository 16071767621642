import React, { useRef, useState, useEffect } from "react";
import { FiArrowRight, FiChevronRight, FiCircle } from "react-icons/fi/index.esm.js";
import TypingText from "../UtilityFunctions/TypingText.js";
import s from "../../styleModules/secondaryStyles.module.css";

function SidebarAccordion({ title, content, contentObject, description, initialActive, refHolder, urlContent, job_summary }) {
    const sectionRef = useRef(null);
    const [sectionStatus, setSectionStatus] = useState(initialActive ? "active" : "");
    const [sectionHeight, setSectionHeight] = useState(initialActive ? "3000px" : "0px");
    const [jobSummaryArray, setJobSummaryArray] = useState([]);

    function toggleSection() {
        const newStatus = sectionStatus === "active" ? "" : "active";
        setSectionStatus(newStatus);
        setSectionHeight(newStatus === "active" ? "3000px" : "0px");
    }

    const handleLinkClick = (url) => (e) => {
        e.preventDefault();
        window.open(url, "_blank");
    };

    const createMarkup = (text) => {
        // Split the text by line breaks
        const lines = text.split("\n");

        // Check if the text contains a URL link
        const urlRegex = /(http[s]?:\/\/[^\s]+)/g;
        const url = text.match(urlRegex);

        // Iterate over the lines and format them
        const formattedLines = lines.map((line, index) => {
            // If the line ends with a colon, make it bold
            if (line.endsWith(":")) {
                return (
                    <span key={index}>
                        <span style={{ fontFamily: "BoldFont" }}>{line}</span>
                        <br />
                    </span>
                );
            }
            if (url && line.includes(url[0])) {
                // If a URL exists in the line, replace it
                const parts = line.split(url[0]); // Split the line around the URL
                return (
                    <span key={index}>
                        {parts[0]}
                        <a href={url[0]} style={{ color: "#41c1ca" }} onClick={handleLinkClick(url[0])} target="_blank" rel="noopener noreferrer">
                            Click here
                        </a>
                        {parts[1]}
                        <br />
                    </span>
                );
            }
            return (
                <span key={index}>
                    {line}
                    <br />
                </span>
            );
        });

        return formattedLines;
    };

    useEffect(() => {
        if (job_summary === undefined || job_summary === null) {
            return;
        }
        const tempLines = job_summary.split("\n");
        let currentHeading = "";
        let currentIndex = -1;
        const temp = {};
        const tempArray = [];
        tempLines.forEach((line, index) => {
            // Check if the line contains a colon (has a heading)
            const lineParts = line.split(":");

            if (lineParts.length > 1) {
                currentHeading = lineParts[0].toUpperCase();
                // This line contains a heading and content
                temp[currentHeading] = lineParts.slice(1).join(":").trim();
                tempArray.push({ heading: currentHeading, content: lineParts.slice(1).join(":").trim() });
                currentIndex++;
            } else {
                // This is normal text, just return it as is
                if (temp[currentHeading] === "") {
                    temp[currentHeading] = line;
                    tempArray[currentIndex].content = line;
                } else {
                    temp[currentHeading] += `\n${line}`;
                    if (tempArray[currentIndex]) {
                        tempArray[currentIndex].content += `\n${line}`;
                    }
                }
            }
        });

        setJobSummaryArray(tempArray);
    }, [job_summary]);

    return (
        <div className={s.resourceContainer}>
            <div className={s.resourceSubheader} onClick={toggleSection}>
                <p className={sectionStatus ? s.resourceSubheadingActive : s.resourceSubheading}>
                    <span className={s.resourceSubheadingText}>{title}</span>
                    <FiChevronRight
                        className={s.resourceSubheadingIcon}
                        style={{
                            transform: `${sectionStatus === "active" ? "rotate(90deg)" : "rotate(0deg)"}`,
                        }}
                    />
                </p>
            </div>

            <div ref={sectionRef} style={{ maxHeight: sectionHeight }} className={s.resourceBreakdownContainer}>
                <div className={s.resourceBreakdownDataContainer}>
                    {jobSummaryArray.length > 0 &&
                        jobSummaryArray.map((item, index) => (
                            <div key={index}>
                                <div className={s.resourceText}>{item.heading}</div>
                                <div className={s.resourceDescription}>
                                    {!item.content.includes("- ") && <p>{item.content}</p>}
                                    {item.content.includes("- ") &&
                                        item.content.split("- ").map((bullet, index) => bullet.trim() !== "" && <p key={index}>•{bullet.trim()}</p>)}
                                </div>
                            </div>
                        ))}
                    {content && urlContent && <>{createMarkup(content)}</>}
                    {content && !urlContent && (
                        <>
                            {typeof content === "string" ? (
                                <p className={s.resourceText}>{content}</p>
                            ) : (
                                content.map((item, index) => {
                                    if (description === true) {
                                        return (
                                            <div key={index}>
                                                <p className={s.resourceText} style={{ fontFamily: "BoldFont" }}>
                                                    {!item.price && <span>{item.short}</span>}
                                                    {item.price && (
                                                        <span>
                                                            {item.short} ($
                                                            {item.price})
                                                        </span>
                                                    )}
                                                </p>
                                                <p className={s.resourceDescription}>
                                                    {/* <span style={{ fontFamily: "BoldFont" }}>Description: </span> */}
                                                    {item.description}
                                                </p>
                                                {item.price_list && (
                                                    <table className={s.resourceTable}>
                                                        <thead>
                                                            <tr className={s.resourceTableRow}>
                                                                <th className={s.resourceTableQuantity}>Minimum Quantity</th>
                                                                <th className={s.resourceTablePrice}>Price Per Pen</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item.price_list.map((price, index) => (
                                                                <tr key={index} className={s.resourceTableRow}>
                                                                    <td className={s.resourceTableQuantity}>{price.quantity.toLocaleString()}</td>
                                                                    <td className={s.resourceTablePrice}>${price.price}</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                )}

                                                {item.details &&
                                                    typeof item.details !== "object" &&
                                                    item.details.map((detail, index) => (
                                                        <p key={index} className={s.resourceDescription}>
                                                            {/* Split detail by colon, then bold everything before the colon */}
                                                            <span style={{ fontFamily: "BoldFont" }}>{detail.split(":")[0]}: </span>
                                                            {detail.split(":")[1]}
                                                        </p>
                                                    ))}

                                                {item.details &&
                                                    typeof item.details === "object" &&
                                                    !Array.isArray(item.details) &&
                                                    Object.keys(item.details).map((key) => (
                                                        <div key={key}>
                                                            <p className={s.resourceDetailTitle}>{key}</p>
                                                            {Array.isArray(item.details[key]) &&
                                                                item.details[key].map((detail, index) => (
                                                                    <p key={index} className={s.resourceDetail}>
                                                                        <span style={{ fontFamily: "BoldFont" }}>{detail.split(":")[0]}: </span>
                                                                        {detail.split(":")[1]}
                                                                    </p>
                                                                ))}
                                                        </div>
                                                    ))}

                                                {item.details &&
                                                    Array.isArray(item.details) &&
                                                    item.details.map((detail, index) => (
                                                        <p key={index} className={s.resourceDescription}>
                                                            <span style={{ fontFamily: "BoldFont" }}>{detail.split(":")[0]}: </span>
                                                            {detail.split(":")[1]}
                                                        </p>
                                                    ))}
                                            </div>
                                        );
                                    }
                                    return (
                                        <p key={index} className={s.resourceText}>
                                            {item}
                                        </p>
                                    );
                                })
                            )}
                        </>
                    )}
                    {contentObject && (
                        <>
                            {/* Check if any of the keys in the contentObject contain an object with a description? */}
                            {contentObject[Object.keys(contentObject)[0]].description &&
                                Object.keys(contentObject).map((key, index) => (
                                    <div key={index}>
                                        <p className={s.resourceText}>
                                            <span style={{ fontFamily: "BoldFont" }}>{key}: </span>
                                        </p>
                                        <p className={s.resourceDescription}>
                                            <span style={{ fontFamily: "BoldFont" }}>Description: </span>
                                            {contentObject[key].description}
                                        </p>
                                        <p className={s.resourceDescription}>
                                            <span style={{ fontFamily: "BoldFont" }}>Differentiation: </span>
                                            {contentObject[key].differentiation}
                                        </p>
                                    </div>
                                ))}

                            {/* Check if any of the objects in the contentObject contain a checklist. Then map through the objects and render the short and long strings for each */}
                            {contentObject[Object.keys(contentObject)[0]].checklist &&
                                Object.keys(contentObject).map((key, index) => (
                                    <div key={index}>
                                        <p className={s.resourceText} style={{ fontFamily: "BoldFont" }}>
                                            {contentObject[key].short}
                                        </p>
                                        <p className={s.resourceDescription}>{contentObject[key].long}</p>
                                    </div>
                                ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SidebarAccordion;
