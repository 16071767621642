import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef, Suspense } from "react";
import * as THREE from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";

export default function ToneMapping({ toneMapExp }) {
	const { gl, scene } = useThree(({ gl, scene }) => ({ gl, scene }));
	useEffect(() => {
		gl.toneMapping = THREE.ACESFilmicToneMapping;
		gl.toneMappingExposure = toneMapExp;
		scene.traverse((object) => {
			if (object.material) {
				object.material.needsUpdate = true;
			}
		});
	}, [gl, scene]);
	return <></>;
}
