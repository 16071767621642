import React, { useContext } from "react";

import { AppContext } from "../../UtilityFunctions/AppContext.js";
import { useAxiosLimited } from "../../UtilityFunctions/axiosRetry.js";
import { systemMessageCougar } from "../../CustomMessages.js";
import { usePostHog } from "posthog-js/react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";

const getAudience = (data) => {
	let tempAudience;
	if (data.audience === undefined) {
		// Default to David
		tempAudience = ["David"];
	} else {
		tempAudience = data.audience;
	}
	return tempAudience;
};

const getPersonality = (data, personalities) => {
	let tempPersonality;
	if (data.personality === undefined) {
		// Default to David's randomized personality
		tempPersonality = personalities.David;
	} else {
		tempPersonality = data.personality;
	}
	return tempPersonality;
};

export function handleGeneration({
	assignmentData,
	setGenerateStarted,
	setup_type,
	localUser,
	url,
	savedId,
	getRandomArrayElement,
	audienceOptions,
	personalityOptions,
	personalities,
	setShowStartSimBtn,
	setCallNotes,
	setError1,
	setError3
}) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { setupData, setSetupData, setSessionList, resumeData, coverLetterData, handleNavigate } = useContext(AppContext);
	const instage_id = localUser ? localUser.id : null;
	const posthog = usePostHog();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const randomizeAudience = () => {
		const randomAudience = getRandomArrayElement(audienceOptions);
		const randomPersonality = getRandomArrayElement(personalityOptions).value;
		return [randomAudience, randomPersonality];
	};

	const fetchSessionList = async (checkLocalUser = false) => {
		if (checkLocalUser && !localUser) {
			return;
		}

		try {
			const result = await axiosLimitedGet(`${url}/api/saved/simulation/list`, 1, { params: { instage_id } });

			// order the data where global is set to true first
			result.data.sort((a, b) => (a.global > b.global ? -1 : 1));
			setSessionList(result.data);
		} catch (e) {
			console.log(e.status);
			console.log(e);
		}
	};

	const generateSim = async () => {
		if (localUser) {
			try {
				posthog?.capture("instage_generate_sim", { setup_id: setup_type });
			} catch (error) {
				console.error(error);
			}

			setGenerateStarted(true);
			if (setup_type === "interview") {
				generateJobPost();
			} else if (setup_type === "discovery") {
				generateMeeting();
			} else if (setup_type === "discovery_spin") {
				generateMeetingSPIN();
			} else if (setup_type === "closing") {
				generateMeetingClose();
			} else if (setup_type === "retail") {
				generateRetail();
			} else if (setup_type === "presentation") {
				generatePresentation();
			} else if (setup_type === "intro") {
				generateIntro();
			} else if (setup_type === "freestyle") {
				generateFreestyle();
			} else if (setup_type === "patient") {
				generatePatient();
			} else if (setup_type === "pitch") {
				generatePitch();
			} else if (setup_type === "business_pitch") {
				generateBusinessPitch();
			} else if (setup_type === "reflection") {
				generateReflection();
			}
		}

		await fetchSessionList();
	};

	const titleLimit = 100;
	const detailsLimit = 15000;

	const generateReflection = async () => {
		const { experience_type, experience_type_description, experience_details, competency_list } = setupData;

		if (experience_type === "" || experience_type.length > titleLimit || experience_details.length > detailsLimit || competency_list.length > 5) {
			if (experience_type === "" || experience_type.length > titleLimit) {
				setError1(true);
			}
			if (competency_list.length > 5) {
				setError3(true);
			}
			setGenerateStarted(false);
			return;
		}

		// Forced delay
		await new Promise((resolve) => setTimeout(resolve, 500));

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);
		const phase = "after";

		try {
			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				setup_type: "reflection",
				setup_name: experience_type,
				setup_input: experience_details,
				setup_output: {
					phase,
					experience_type_description,
					competency_list
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);

				handleNavigate(newSimPath);
			} else {
				const simulation_id = await UpdateSimDetails(experience_type, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					experience_type_description: savedData.setup_output.experience_type_description,
					phase: savedData.setup_output.phase,
					competency_list: savedData.setup_output.competency_list,
					audience: tempAudience,
					personality: tempPersonality
				}));
				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generateBusinessPitch = async () => {
		const { pitch_topic, pitch_details } = setupData;

		if (pitch_topic === "" || pitch_topic.length > titleLimit) {
			setError1(true);
			setGenerateStarted(false);
			return;
		}

		if (pitch_details.length > detailsLimit) {
			setGenerateStarted(false);
			return;
		}

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			pitch_topic,
			pitch_details
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GenerateBusinessPitchDetails`, data, 1, {}, 30000);
			// console.log(resp.data);

			const question_list = [
				{
					index: 0,
					type: "Clarification",
					text: ""
				},
				{
					index: 1,
					type: "Critical",
					text: ""
				},
				{
					index: 2,
					type: "Application",
					text: ""
				}
			];

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				setup_type: "business_pitch",
				setup_name: pitch_topic,
				setup_input: pitch_details,

				setup_output: {
					target_time: 120,
					audience_role: resp.data.generated_details.audience_role,
					user_role: resp.data.generated_details.user_role,
					question_list
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				const simulation_id = await UpdateSimDetails(pitch_topic, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					target_time: savedData.setup_output.target_time,
					audience_role: savedData.setup_output.audience_role,
					user_role: savedData.setup_output.user_role,
					audience: tempAudience,
					personality: tempPersonality,
					question_list
				}));
				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generatePitch = async () => {
		const { pitch_topic, pitch_details } = setupData;

		if (pitch_topic === "" || pitch_topic.length > titleLimit) {
			setError1(true);
		}

		if (pitch_topic === "" || pitch_topic.length > titleLimit || pitch_details.length > detailsLimit) {
			setGenerateStarted(false);
			return;
		}

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			pitch_topic,
			pitch_details
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GeneratePitchDetails`, data, 1, {}, 30000);
			// console.log(resp.data);

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				setup_type: "pitch",
				setup_name: pitch_topic,
				setup_input: pitch_details,

				setup_output: {
					target_time: 30,
					audience_role: resp.data.generated_details.audience_role,
					user_role: resp.data.generated_details.user_role
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				const simulation_id = await UpdateSimDetails(pitch_topic, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					target_time: savedData.setup_output.target_time,
					audience_role: savedData.setup_output.audience_role,
					user_role: savedData.setup_output.user_role,
					audience: tempAudience,
					personality: tempPersonality
				}));
				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generatePatient = async () => {
		const { user_role, visit_type, patient_bio, patient_medical } = setupData;

		if (user_role === "" || visit_type === "" || patient_bio.length > detailsLimit || patient_medical.length > detailsLimit) {
			setGenerateStarted(false);
			return;
		}

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			instage_id: localUser.id,
			audience: tempAudience,
			user_role,
			visit_type,
			patient_bio,
			patient_medical
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GeneratePatientDetails`, data, 1, {}, 30000);
			// console.log(resp.data);

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "patient",
				setup_name: visit_type,
				setup_input: patient_bio,

				setup_output: {
					user_role,
					patient_summary: resp.data.generated_details.patient_summary,
					patient_bio: resp.data.generated_details.patient_bio,
					patient_medical: resp.data.generated_details.patient_medical
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				const simulation_id = await UpdateSimDetails(visit_type, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					audience: tempAudience,
					personality: tempPersonality,
					patient_summary: savedData.setup_output.patient_summary,
					patient_bio: savedData.setup_output.patient_bio,
					patient_medical: savedData.setup_output.patient_medical
				}));
				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generateFreestyle = async () => {
		const { convo_type, user_details, audience_details } = setupData;

		if (convo_type === "" || convo_type.length > titleLimit) {
			setError1(true);
			setGenerateStarted(false);
			return;
		}

		if (user_details.length > detailsLimit || audience_details.length > detailsLimit) {
			setGenerateStarted(false);
			return;
		}

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			instage_id: localUser.id,
			audience: tempAudience,
			convo_type,
			user_details,
			audience_details
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GenerateFreestyleDetails`, data, 1, {}, 30000);
			// console.log(resp.data);

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "freestyle",
				setup_name: convo_type,
				setup_input: user_details,

				setup_output: {
					user_role: resp.data.generated_details.user_role,
					audience_role: resp.data.generated_details.audience_role,
					user_details: resp.data.generated_details.user_details,
					audience_details: resp.data.generated_details.audience_details
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				const simulation_id = await UpdateSimDetails(convo_type, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					audience: tempAudience,
					personality: tempPersonality,
					user_role: savedData.setup_output.user_role,
					audience_role: savedData.setup_output.audience_role,
					user_details: savedData.setup_output.user_details,
					audience_details: savedData.setup_output.audience_details
				}));
				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generateIntro = async () => {
		const { intro_event, intro_details } = setupData;

		if (intro_event === "" || intro_event.length > titleLimit) {
			setError1(true);
			setGenerateStarted(false);
			return;
		}

		if (intro_details.length > detailsLimit) {
			setGenerateStarted(false);
			return;
		}

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			instage_id: localUser.id,
			intro_event,
			intro_details
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GenerateIntroDetails`, data, 1, {}, 30000);
			// console.log(resp.data);

			const question_list = [
				{
					index: 0,
					type: "General",
					text: ""
				},
				{
					index: 1,
					type: "General",
					text: ""
				}
			];

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "intro",
				setup_name: intro_event,
				setup_input: intro_details,

				setup_output: {
					audience_role: resp.data.generated_details.audience_role,
					user_role: resp.data.generated_details.user_role,
					question_list
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				const simulation_id = await UpdateSimDetails(intro_event, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					audience_role: savedData.setup_output.audience_role,
					user_role: savedData.setup_output.user_role,
					audience: tempAudience,
					personality: tempPersonality,
					question_list
				}));
				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generatePresentation = async () => {
		const { topic, presentation_details } = setupData;

		if (topic === "" || topic.length > titleLimit) {
			setError1(true);
			setGenerateStarted(false);
			return;
		}

		if (presentation_details.length > detailsLimit) {
			setGenerateStarted(false);
			return;
		}

		// console.log("Getting Presentation Details...");

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			instage_id: localUser.id,
			topic,
			presentation_details
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GeneratePresentation`, data, 1, {}, 30000);
			// console.log(resp.data);

			const question_list = [
				{
					index: 0,
					type: "Clarification",
					text: ""
				},
				{
					index: 1,
					type: "Critical",
					text: ""
				},
				{
					index: 2,
					type: "Application",
					text: ""
				}
			];

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "presentation",
				setup_name: topic,
				setup_input: presentation_details,

				setup_output: {
					target_time: 300,
					audience_role: resp.data.generated_details.audience_role,
					objective: resp.data.generated_details.objective,
					question_list
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				const simulation_id = await UpdateSimDetails(topic, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					target_time: savedData.setup_output.target_time,
					audience_role: savedData.setup_output.audience_role,
					objective: savedData.setup_output.objective,
					audience: tempAudience,
					personality: tempPersonality,
					question_list
				}));
				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generateRetail = async () => {
		const { product, additional_details } = setupData;

		if (product === "" || product.length > titleLimit) {
			setError1(true);
		}

		if (product === "" || product.length > titleLimit || additional_details.length > detailsLimit) {
			setGenerateStarted(false);
			return;
		}

		// console.log("Getting Meeting Details...");

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			instage_id: localUser.id,
			product,
			additional_details,
			audience: tempAudience,
			personality: tempPersonality
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GenerateMeetingDetails`, data, 1, {}, 30000);
			// console.log(resp.data);

			const question_list = [];

			// insert buyer_questions from resp.data.generated_details
			if (resp.data.generated_details.question_list) {
				resp.data.generated_details.question_list.forEach((item, index) => {
					question_list.push({
						index,
						type: item.type,
						text: item.text
					});
				});
			}

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "retail",
				setup_name: product,
				setup_input: additional_details,

				setup_output: {
					sales_rep_company_name: resp.data.generated_details.sales_rep_company_name,
					sales_rep_company_description: resp.data.generated_details.sales_rep_company_description,
					buyer_title: resp.data.generated_details.buyer_title,
					question_list
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			// console.log(savedData);
			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				const simulation_id = await UpdateSimDetails(product, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					sales_rep_company_name: resp.data.generated_details.sales_rep_company_name,
					sales_rep_company_description: resp.data.generated_details.sales_rep_company_description,
					buyer_title: resp.data.generated_details.buyer_title,
					audience: tempAudience,
					personality: tempPersonality,
					question_list
				}));
				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generateMeeting = async () => {
		const { product, meeting_details } = setupData;

		if (product === "" || product.length > titleLimit) {
			setError1(true);
		}

		if (product === "" || product.length > titleLimit || meeting_details.length > detailsLimit) {
			setGenerateStarted(false);
			return;
		}

		// console.log("Getting Meeting Details...");

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			instage_id: localUser.id,
			product,
			meeting_details
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GenerateMeetingDetails`, data, 1, {}, 30000);
			// console.log(resp.data);

			setCallNotes(resp.data.call_notes ? resp.data.call_notes : "");

			// Add call notes to setupData if available
			if (resp.data.call_notes) {
				// Add call notes to setupData state
				setSetupData((prevSetupData) => ({
					...prevSetupData,
					call_notes: resp.data.call_notes
				}));
			}

			const question_list = [];

			// insert prospect_questions from resp.data.generated_details
			if (resp.data.generated_details.prospect_questions) {
				resp.data.generated_details.prospect_questions.forEach((item) => {
					question_list.push({
						index: item.index,
						type: item.short,
						text: item.question
					});
				});
			}

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "discovery",
				setup_name: product,
				setup_input: meeting_details,

				setup_output: {
					sales_rep_company_name: resp.data.generated_details.sales_rep_company_name,
					sales_rep_company_description: resp.data.generated_details.sales_rep_company_description,
					prospect_company_name: resp.data.generated_details.prospect_company_name,
					prospect_company_description: resp.data.generated_details.prospect_company_description,
					buyer_title: resp.data.generated_details.buyer_title,
					question_list,
					call_notes: resp.data.call_notes ? resp.data.call_notes : ""
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			// console.log(savedData);
			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				const simulation_id = await UpdateSimDetails(product, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					sales_rep_company_name: resp.data.generated_details.sales_rep_company_name,
					sales_rep_company_description: resp.data.generated_details.sales_rep_company_description,
					prospect_company_name: resp.data.generated_details.prospect_company_name,
					prospect_company_description: resp.data.generated_details.prospect_company_description,
					buyer_title: resp.data.generated_details.buyer_title,
					audience: tempAudience,
					personality: tempPersonality,
					question_list
				}));

				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generateMeetingSPIN = async () => {
		const { product, meeting_details, buyerSituation1, buyerSituation2 } = setupData;

		const isProductInvalid = product === "" || product.length > titleLimit;
		const isBuyerSituation1Invalid = buyerSituation1 === "" || buyerSituation1 === undefined;
		const isBuyerSituation2Invalid = buyerSituation2 === "" || buyerSituation2 === undefined;
		const isMeetingDetailsInvalid = meeting_details.length > detailsLimit;

		if (isProductInvalid) {
			setError1(true);
		}

		if (isBuyerSituation1Invalid || isBuyerSituation2Invalid) {
			setError3(true);
		}

		if (isProductInvalid || isMeetingDetailsInvalid || isBuyerSituation1Invalid || isBuyerSituation2Invalid) {
			setGenerateStarted(false);
			return;
		}

		console.log("Getting Meeting Details...");

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			instage_id: localUser.id,
			product,
			meeting_details,
			buyerSituation1,
			buyerSituation2
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GenerateMeetingDetails`, data, 1, {}, 30000);
			// console.log(resp.data);

			setCallNotes(resp.data.generated_details.call_notes ? resp.data.generated_details.call_notes : "");

			// Add call notes to setupData if available
			if (resp.data.generated_details.call_notes) {
				// Add call notes to setupData state
				setSetupData((prevSetupData) => ({
					...prevSetupData,
					call_notes: resp.data.generated_details.call_notes
				}));
			}

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "discovery_spin",
				setup_name: product,
				setup_input: meeting_details,

				setup_output: {
					sales_rep_company_name: resp.data.generated_details.sales_rep_company_name,
					sales_rep_company_description: resp.data.generated_details.sales_rep_company_description,
					prospect_company_name: resp.data.generated_details.prospect_company_name,
					prospect_company_description: resp.data.generated_details.prospect_company_description,
					buyer_title: resp.data.generated_details.buyer_title,
					call_notes: resp.data.generated_details.call_notes ? resp.data.generated_details.call_notes : "",
					buyer_situation_1: resp.data.generated_details.buyer_situation_1,
					buyer_situation_2: resp.data.generated_details.buyer_situation_2
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			// console.log(savedData);

			let simulation_id;
			const [newAudience, newPersonality] = tempAudience[0] === "Random" ? randomizeAudience() : [tempAudience, tempPersonality];

			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				simulation_id = await UpdateSimDetails(product, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					sales_rep_company_name: resp.data.generated_details.sales_rep_company_name,
					sales_rep_company_description: resp.data.generated_details.sales_rep_company_description,
					prospect_company_name: resp.data.generated_details.prospect_company_name,
					prospect_company_description: resp.data.generated_details.prospect_company_description,
					buyer_title: resp.data.generated_details.buyer_title,
					audience: newAudience,
					personality: newPersonality,
					buyer_situation_1: resp.data.generated_details.buyer_situation_1,
					buyer_situation_2: resp.data.generated_details.buyer_situation_2
				}));

				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generateMeetingClose = async () => {
		const { product, meeting_details, buyerNeed1, buyerNeed2, buyerObjection1, buyerObjection2 } = setupData;

		const isProductInvalid = product === "" || product.length > titleLimit;
		const isBuyerSituation1Invalid = buyerNeed1 === "" || buyerNeed1 === undefined;
		const isBuyerSituation2Invalid = buyerNeed2 === "" || buyerNeed2 === undefined;
		const isBuyerObjection1Invalid = buyerObjection1 === "" || buyerObjection1 === undefined;
		const isBuyerObjection2Invalid = buyerObjection2 === "" || buyerObjection2 === undefined;
		const isMeetingDetailsInvalid = meeting_details.length > detailsLimit;

		if (isProductInvalid) {
			setError1(true);
		}

		if (isBuyerSituation1Invalid || isBuyerSituation2Invalid || isBuyerObjection1Invalid || isBuyerObjection2Invalid) {
			setError3(true);
		}

		if (
			isProductInvalid ||
			isMeetingDetailsInvalid ||
			isBuyerSituation1Invalid ||
			isBuyerSituation2Invalid ||
			isBuyerObjection1Invalid ||
			isBuyerObjection2Invalid
		) {
			setGenerateStarted(false);
			return;
		}

		console.log("Getting Meeting Details...");

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			instage_id: localUser.id,
			product,
			meeting_details,
			buyerNeed1,
			buyerNeed2,
			buyerObjection1,
			buyerObjection2
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GenerateMeetingDetails`, data, 1, {}, 30000);
			// console.log(resp.data);

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "closing",
				setup_name: product,
				setup_input: meeting_details,

				setup_output: {
					sales_rep_company_name: resp.data.generated_details.sales_rep_company_name,
					sales_rep_company_description: resp.data.generated_details.sales_rep_company_description,
					prospect_company_name: resp.data.generated_details.prospect_company_name,
					prospect_company_description: resp.data.generated_details.prospect_company_description,
					buyer_title: resp.data.generated_details.buyer_title,
					buyer_need_1: resp.data.generated_details.buyer_need_1,
					buyer_need_2: resp.data.generated_details.buyer_need_2,
					buyer_objection_1: resp.data.generated_details.buyer_objection_1,
					buyer_objection_2: resp.data.generated_details.buyer_objection_2
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};
			// console.log(savedData);

			let simulation_id;
			const [newAudience, newPersonality] = tempAudience[0] === "Random" ? randomizeAudience() : [tempAudience, tempPersonality];

			if (!savedId) {
				const { newSimData, newSimPath } = await SaveNewSim(savedData);
				handleNavigate(newSimPath);
			} else {
				simulation_id = await UpdateSimDetails(product, savedData);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					sales_rep_company_name: resp.data.generated_details.sales_rep_company_name,
					sales_rep_company_description: resp.data.generated_details.sales_rep_company_description,
					prospect_company_name: resp.data.generated_details.prospect_company_name,
					prospect_company_description: resp.data.generated_details.prospect_company_description,
					buyer_title: resp.data.generated_details.buyer_title,
					audience: newAudience,
					personality: newPersonality,
					buyer_need_1: resp.data.generated_details.buyer_need_1,
					buyer_need_2: resp.data.generated_details.buyer_need_2,
					buyer_objection_1: resp.data.generated_details.buyer_objection_1,
					buyer_objection_2: resp.data.generated_details.buyer_objection_2
				}));

				setShowStartSimBtn(true);
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	const generateJobPost = async () => {
		console.log("generateJobPost", setupData);
		const { job_title, monsterUrl } = setupData;
		let { job_description } = setupData;

		// if job_description is undefined, set it to an empty string
		if (!job_description) {
			job_description = "";
		}

		if (job_title === "" || job_title.length > titleLimit) {
			setError1(true);
		}

		if (job_title === "" || job_title.length > titleLimit || job_description.length > detailsLimit) {
			setGenerateStarted(false);
			return;
		}

		const baseQuestionList = [
			{
				type: "Opening",
				text: ""
			},
			{
				type: "Behavioral",
				text: ""
			},
			{
				type: "Situational",
				text: ""
			},
			{
				type: "Cultural Fit",
				text: ""
			},
			{
				type: "Technical",
				text: ""
			},
			{
				type: "Closing",
				text: ""
			}
		];

		const tempAudience = getAudience(setupData);
		const tempPersonality = getPersonality(setupData, personalities);

		const data = {
			instage_id: localUser.id,
			job_description,
			job_title
		};

		try {
			console.log("generateJobPost", data);
			const resp = await axiosLimitedPost(`${url}/api/${setupData.setup_type}/GenerateJobPost`, data, 1, {}, 60000);

			if (localUser.resume_details) {
				baseQuestionList.splice(1, 0, {
					type: "Resume",
					text: ""
				});
			}

			const question_list = baseQuestionList.map((item, index) => ({
				...item,
				index
			}));

			const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");
			const savedData = {
				instage_id: localUser.id,
				setup_type: "interview",
				setup_name: job_title,
				setup_input: job_description,

				setup_output: {
					job_title,
					job_description,
					short_summary: resp.data.generated_details.short_summary,
					job_function: resp.data.generated_details.job_function,
					experience_level: resp.data.generated_details.experience_level,
					industry: resp.data.generated_details.industry,
					main_responsibilities: resp.data.generated_details.main_responsibilities,
					skills_required: resp.data.generated_details.skills_required,
					question_list: resp.data.generated_details.job_question_list,
					job_question_list: question_list,
					keywords: resp.data.generated_details.keywords,
					...(monsterUrl && monsterUrl !== "" ? { monsterUrl } : {})
				},
				created_date: start_time,
				last_modified: start_time,
				audience: tempAudience,
				personality: tempPersonality,
				shared: false
			};

			const [newAudience, newPersonality] = tempAudience[0] === "Random" ? randomizeAudience() : [tempAudience, tempPersonality];

			if (!savedId) {
				if (resumeData !== null || coverLetterData !== null) {
					console.log("generateJobPost", savedData);
					const saveNewSimData = await axiosLimitedPost(`${url}/api/saved/simulation`, savedData, 1, {}, 30000);
					let path = window.location.pathname;
					// check if path end with /
					if (path[path.length - 1] === "/") {
						path += saveNewSimData.data.simulation_id;
					} else {
						path = `${path}/${saveNewSimData.data.simulation_id}`;
					}

					const createData = {
						instage_id: localUser.id,
						setup_id: saveNewSimData.data.simulation_id,
						resume_details: resumeData,
						cover_letter_details: coverLetterData
					};

					await axiosLimitedPost(`${url}/api/resume-assist/create`, createData, 1);

					handleNavigate(path);
				} else {
					const { newSimData, newSimPath } = await SaveNewSim(savedData);
					handleNavigate(newSimPath);
				}
			} else {
				const simulation_id = await UpdateSimDetails(job_title, savedData);
				setShowStartSimBtn(true);

				setSetupData((prevSetupData) => ({
					...prevSetupData,
					simulation_id,
					audience: newAudience,
					personality: newPersonality,
					short_summary: resp.data.generated_details.short_summary,
					job_function: resp.data.generated_details.job_function,
					experience_level: resp.data.generated_details.experience_level,
					industry: resp.data.generated_details.industry,
					main_responsibilities: resp.data.generated_details.main_responsibilities,
					skills_required: resp.data.generated_details.skills_required,
					question_list: resp.data.generated_details.job_question_list,
					keywords: resp.data.generated_details.keywords
				}));
			}
		} catch (error) {
			console.error(error);
			setGenerateStarted(false);
		}
	};

	return { generateSim };

	async function UpdateSimDetails(setup_name, savedData) {
		let simulation_id = savedId;
		let data;
		if (assignmentData.assignment_type === "dynamic") {
			// savedID as an int
			const assignment_simulation_id = parseInt(savedId);
			savedData.setup_output = { ...savedData.setup_output, ...assignmentData, assignment_simulation_id };

			console.log("UpdateSimDetails savedData", savedData);
			data = await SaveNewAssignmentSim(savedData);

			simulation_id = data.simulation_id;
		}

		const data1 = {
			simulation_id,
			value: setup_name,
			field: "setup_name"
		};
		await axiosLimitedPatch(`${url}/api/saved/simulation`, data1, 1, {}, 30000);

		const data2 = {
			simulation_id,
			value: savedData.setup_output,
			field: "setup_output"
		};
		await axiosLimitedPatch(`${url}/api/saved/simulation`, data2, 1, {}, 30000);

		const data3 = {
			simulation_id,
			value: savedData.audience,
			field: "audience"
		};
		await axiosLimitedPatch(`${url}/api/saved/simulation`, data3, 1, {}, 30000);
		// setut input
		const data4 = {
			simulation_id,
			value: savedData.setup_input,
			field: "setup_input"
		};
		await axiosLimitedPatch(`${url}/api/saved/simulation`, data4, 1, {}, 30000);
		if (assignmentData.assignment_type === "dynamic" && data) {
			handleNavigate(data.path);
		}

		return simulation_id;
	}
	async function SaveNewAssignmentSim(savedData) {
		let path = window.location.pathname;
		let pathWithoutID = "";
		if (path[path.length - 1] === "/") {
			const pathWithoutTrailingSlash = path.substring(0, path.length - 1);
			pathWithoutID = path.substring(0, pathWithoutTrailingSlash.lastIndexOf("/"));
		} else {
			pathWithoutID = path.substring(0, path.lastIndexOf("/"));
		}

		// console.log(pathWithoutID)

		const resp = await axiosLimitedPost(`${url}/api/saved/simulation`, savedData, 1, {}, 30000);
		path = `${pathWithoutID}/${resp.data.simulation_id}`;

		return { simulation_id: resp.data.simulation_id, path };
	}

	async function SaveNewSim(savedData) {
		const resp = await axiosLimitedPost(`${url}/api/saved/simulation`, savedData, 1, {}, 30000);

		let path = window.location.pathname;

		// check if path end with /
		if (path[path.length - 1] === "/") {
			path += resp.data.simulation_id;
		} else {
			path = `${path}/${resp.data.simulation_id}`;
		}

		return { newSimData: resp, newSimPath: path };
	}
}
