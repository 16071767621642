// For placeholder text that the translate API cannot directly access
const LanguageDict = {
	InterviewJobTitlePlaceholder: "Enter job title here",
	InterviewJobDetailsPlaceholder: "Enter a job description",
	SubscribeLabel: "Yes, I would like to receive update emails from InStage.",
	SignUpEnterEmailPlaceholder: "Enter email address",
	SignUpEnterNamePlaceholder: "Enter name",
	StartWithVideo: "Start with video",
	RecordVideo: "Record video",
	InterviewJobTitleDropDownPlaceholder: "Start typing to search job titles or create your own",
	SalesTitlePlaceholder: "Product / service name",
	SalesDescriptionPlaceholder: `Buyer: Sales Director at MarketMind Enterprises
- Struggling with tracking customer interactions and needs a unified system to improve sales performance.
		
Our Company: NexTech Solutions
- We specialize in providing innovative software solutions, including CRM systems, to help businesses enhance their customer engagement.
		
Our Solution: NexTech CRM Suite, integrates with existing tools, centralizes customer information, and automates key tasks, enabling your sales team to focus on building meaningful relationships.`,
	PresentationTitle: "Enter presentation topic",
	PresentationDescription: `Purpose: The goal is to analyze and discuss how AI is transforming healthcare, including its applications, benefits, challenges, and ethical considerations.

Audience:
- Undergraduate Students in Health Sciences
		
Possible questions:
- How is AI being utilized in healthcare diagnostics and treatment?
- What are the ethical considerations in implementing AI in healthcare?
- What challenges do healthcare providers face in adopting AI technologies?`,
	SelfIntroTitle: "Where are you introducing yourself? e.g. Networking Event",
	SelfIntroDescription: `e.g. I'm introducing myself to a prospective employer.

- I'm looking for a job in the tech industry
- I'm hoping to connect with a HR Coordinator at Google who can help me find a job.`,
	ElevatorPitchTitle: "e.g. Innovative Mobile Health App for Diabetes Management",
	ElevatorPitchDescription: `This is a networking event for healthcare and technology sectors. There's an opportunity to pitch your health app to potential investors and stakeholders.

Audience:
- These individuals are particularly interested in mobile health applications that can revolutionize patient care and management.`,
	BusinessPitchTitle: "e.g. E-commerce Platform for Sustainable Products",
	BusinessPitchDescription: `This is a business conference for sustainability and e-commerce sectors. There's an opportunity to pitch your e-commerce platform to potential investors and stakeholders.

Audience:
- These individuals are particularly interested in e-commerce platforms that can promote and facilitate the sale of sustainable products.`,
	SignUpEnterNamePlaceholder: "Enter name",
	SignUpEnterEmailPlaceholder: "Enter email address"
};

export default LanguageDict;
