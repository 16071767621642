import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../UtilityFunctions/AppContext.js";
import { BsMagic } from "react-icons/bs/index.esm.js";
import { BiUpvote, BiDownvote } from "react-icons/bi/index.esm.js";
import { FaRedo } from "react-icons/fa/index.esm.js";
import { FiEdit2, FiPlus, FiTrash2 } from "react-icons/fi";
import { GoArrowUp, GoArrowDown } from "react-icons/go";
import { PiSparkleBold } from "react-icons/pi";
import { RiDeleteBin5Line } from "react-icons/ri/index.esm.js";
import QuestionModal from "./QuestionModal.js";
import { LoadingSpinner } from "../../SecondaryComponents/LoadingSpinner.jsx";
import { BaseTable, Column } from "../../SecondaryComponents/BaseTable.jsx";
import { useAxiosLimited } from "../../UtilityFunctions/axiosRetry.js";
import styles from "../../../styleModules/generateStyles.module.css";
import { assignIndex } from "./assignIndex";

export default function QuestionTable({
	editAccess,
	questionListViewAccess,
	setupType,
	simulation_id,
	field,
	questionTypeOptions,
	generateQuestions,
	resetQuestions,
	isGenerating,
	cellRenderer,
	fixedOpenClose,
	evaluationFramework
}) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { setupData, setSetupData, adminAccess, userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	const LocalLanguageDict = {
		QuestionList: "Question List",
		AutoGeneratedQuestion: "Auto-generated question",
		Generate: "Generate",
		Regenerate: "Regenerate",
		Reset: "Reset",
		AddNewQuestion: "Add New Question"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage, setupData]);
	const [TextRenderState, setTextRenderState] = useState(false);
	useEffect(() => {
		setTextRenderState((prev) => !prev);
	}, [pageText]);

	const url = process.env.REACT_APP_BACKEND_STATIC_URL;

	const [modalOpen, setModalOpen] = useState(false);
	const [selectedQuestion, setSelectedQuestion] = useState(-1);

	const openModal = () => setModalOpen(true);
	const questionList = setupData?.question_list;

	const [translatedQuestionTypeOptions, setTranslatedQuestionTypeOptions] = useState([]);

	// Dynamic Translation
	const [dynamicQuestionListText, setDynamicQuestionListText] = useState([]);

	// Usage
	useEffect(() => {
		// console.log("Question List: ", questionList);
		async function performTranslationsDynamic() {
			if (!questionList || questionList.length === 0) {
				// console.error("questionList is undefined or empty");
				return;
			}

			const propertiesToTranslate = ["text", "type"];
			let updatedQuestionList = [...questionList];

			try {
				for (const property of propertiesToTranslate) {
					const texts = updatedQuestionList.map((item) => item[property]);

					// Validate texts and userLanguage
					if (!Array.isArray(texts) || texts.length === 0 || typeof userLanguage !== "string" || userLanguage.trim() === "") {
						console.error("Invalid data for translation", { texts, userLanguage });
						return;
					}

					const translatedTexts = await translateDictText(texts, userLanguage);

					updatedQuestionList = updatedQuestionList.map((item, index) => ({
						...item,
						[property]: translatedTexts[index] || item[property] // Use translated text if available, otherwise use original
					}));
				}
				setDynamicQuestionListText(updatedQuestionList);
			} catch (error) {
				console.error("Error translating properties:", error);
			}
		}
		if (questionListViewAccess) {
			performTranslationsDynamic();
		}
	}, [setupData.question_list, userLanguage, translateDictText, generateQuestions]); // Removed setupData from dependency array

	// Additional useEffect to force re-render when question_list changes
	useEffect(() => {
		//	console.log("questionList", typeof questionList, questionList, setupData.question_list);
		if (questionList) {
			setDynamicQuestionListText([...questionList]);
		}
	}, [setupData.question_list, isGenerating]);

	if (!setupType.includes("interview")) {
		questionTypeOptions.length = 0;
		questionTypeOptions.push({
			value: "Custom",
			label: "Custom",
			text: ""
		});
	}

	useEffect(() => {
		async function translateOptions() {
			if (!questionTypeOptions || questionTypeOptions.length === 0) {
				console.error("questionTypeOptions is undefined or empty");
				return;
			}

			const labelsToTranslate = questionTypeOptions.map((option) => option.label);
			try {
				const translatedLabels = await translateDictText(labelsToTranslate, userLanguage);
				const translatedQuestionTypeOptions2 = questionTypeOptions.map((option, index) => ({
					...option,
					label: translatedLabels[index] || option.label
				}));

				setTranslatedQuestionTypeOptions(translatedQuestionTypeOptions2);
				// console.log("Translated Question Type Options: ", translatedQuestionTypeOptions2);
			} catch (error) {
				console.error("Error translating question type options:", error);
			}
		}
		if (questionListViewAccess) {
			translateOptions();
		}
	}, [questionTypeOptions, translateDictText, userLanguage]);

	useEffect(() => {
		const payload = {
			simulation_id,
			value: setupData.question_list,
			field
		};
		if (simulation_id && setupData.question_list?.length > 0) {
			axiosLimitedPatch(`${url}/api/saved/simulation/output`, payload, 1, {}, 30000).then((resp3) => {
				// console.log(resp3.data);
				if (resp3.data.length === 0) {
					return;
				}
				const newQuestionList = resp3.data[field];
				if (newQuestionList && JSON.stringify(newQuestionList) !== JSON.stringify(questionList)) {
					setSetupData((prevSetupData) => ({
						...prevSetupData,
						[field]: newQuestionList
					}));
				}
			});
		}
	}, [questionList]);

	const moveDownQuestion = (index) => {
		let currentData = [...setupData.question_list];
		const removed = currentData.splice(index, 1)[0];
		let newIndex = index + 1;
		if (newIndex === currentData.length && fixedOpenClose) {
			newIndex = 0;
		}
		currentData.splice(newIndex, 0, removed);
		currentData = assignIndex(currentData);
		setSetupData((prevSetupData) => ({ ...prevSetupData, question_list: currentData }));
	};

	const moveUpQuestion = (index) => {
		let currentData = [...setupData.question_list];
		const removed = currentData.splice(index, 1)[0];
		let newIndex = index - 1;
		if (newIndex === -1) {
			newIndex = fixedOpenClose ? currentData.length - 1 : currentData.length;
		}
		currentData.splice(newIndex, 0, removed);
		currentData = assignIndex(currentData);
		setSetupData((prevSetupData) => ({ ...prevSetupData, question_list: currentData }));
	};

	const removeQuestion = (index) => {
		let currentData = [...setupData.question_list];
		currentData.splice(index, 1);
		currentData = assignIndex(currentData);
		setSetupData((prevSetupData) => ({ ...prevSetupData, question_list: currentData }));
	};

	const addQuestion = () => {
		setModalOpen(true);
		setSelectedQuestion(-1);
	};

	const isOperationAllowed = (index, operation) => {
		const isInterviewSetup = setupType.includes("interview");
		const isFirstOrLast = index === 0 || index === questionList.length - 1;
		const isSecond = index === 1;
		const isSecondToLast = index === questionList.length - 2;

		if (!isInterviewSetup) return true; // All operations allowed if not an interview setup

		switch (operation) {
			case "edit":
				return editAccess && !isFirstOrLast; // Edit allowed for all questions
			case "delete":
				return !isFirstOrLast; // Edit and delete not allowed for first and last questions
			case "moveUp":
				return !isFirstOrLast && !isSecond; // Move up not allowed for first and second questions
			case "moveDown":
				return !isFirstOrLast && !isSecondToLast; // Move down not allowed for last and second-to-last questions
			default:
				return false;
		}
	};

	return (
		<>
			{modalOpen && (
				<QuestionModal
					adminAccess={adminAccess}
					index={selectedQuestion}
					data={setupData.question_list}
					setData={(newList) => setSetupData((prevSetupData) => ({ ...prevSetupData, question_list: newList }))}
					setModalOpen={setModalOpen}
					options={translatedQuestionTypeOptions}
					fixedOpenClose={fixedOpenClose}
					evaluationFramework={evaluationFramework}
				/>
			)}
			{questionList && questionList.length > 0 && questionListViewAccess && (
				<>
					<p className={styles.inputHeading}>
						{setupType.includes("discovery") ? "Buyer " : ""}
						{pageText.QuestionList}:
					</p>
					<div className={styles.questionTableContainer}>
						{editAccess && (
							<div className={styles.questionTableBtnContainer}>
								<button
									type="button"
									className={`${styles.generateBtn} ${isGenerating ? styles.disabled : ""}`}
									onClick={generateQuestions}
									disabled={isGenerating}
								>
									<PiSparkleBold className={styles.generateBtnIcon} />
									{["interview", "presentation", "intro", "business_pitch"].some((type) => setupType.includes(type))
										? `${pageText.Generate}`
										: `${pageText.Regenerate}`}
								</button>
								{["interview", "presentation", "intro", "business_pitch"].some((type) => setupType.includes(type)) && (
									<button
										type="button"
										className={`${styles.generateBtn} ${isGenerating ? styles.disabled : ""}`}
										onClick={resetQuestions}
										disabled={isGenerating}
									>
										<FaRedo className={styles.generateBtnIcon} />
										{pageText.Reset}
									</button>
								)}
							</div>
						)}
						{dynamicQuestionListText && dynamicQuestionListText.length > 0 && (
							<div className={styles.questionTableContainer}>
								{dynamicQuestionListText &&
									dynamicQuestionListText.map((question, index) => (
										<div key={index} className={styles.questionTableRow}>
											<div className={styles.questionTextContainer}>
												{isGenerating ? (
													<div className={styles.spinnerContainerQuestion}>
														<LoadingSpinner height={35} width={35} thickness={5} color="#00a9af" />
													</div>
												) : question.text === "" ? (
													<p className={styles.openQuestion}>[{pageText.AutoGeneratedQuestion}]</p>
												) : (
													<p className={styles.questionText}>"{question.text}"</p>
												)}
											</div>
											<div className={styles.editQuestionBtnContainer}>
												<p className={styles.questionType}>{question.type}</p>

												{editAccess && (
													<>
														{adminAccess && (
															<FiEdit2
																className={
																	isOperationAllowed(index, "edit")
																		? styles.editQuestionBtn
																		: styles.editQuestionBtnDisabled
																}
																onClick={() => {
																	if (isOperationAllowed(index, "edit")) {
																		setSelectedQuestion(index);
																		openModal();
																	}
																}}
															/>
														)}

														<GoArrowUp
															className={
																isOperationAllowed(index, "moveUp")
																	? styles.editQuestionBtn
																	: styles.editQuestionBtnDisabled
															}
															onClick={() => {
																if (isOperationAllowed(index, "moveUp")) {
																	moveUpQuestion(index);
																}
															}}
														/>
														<GoArrowDown
															className={
																isOperationAllowed(index, "moveDown")
																	? styles.editQuestionBtn
																	: styles.editQuestionBtnDisabled
															}
															onClick={() => {
																if (isOperationAllowed(index, "moveDown")) {
																	moveDownQuestion(index);
																}
															}}
														/>
														<FiTrash2
															className={
																isOperationAllowed(index, "delete")
																	? `${styles.editQuestionBtn} ${styles.deleteBtn}`
																	: styles.editQuestionBtnDisabled
															}
															onClick={() => {
																if (isOperationAllowed(index, "delete")) {
																	removeQuestion(index);
																}
															}}
														/>
													</>
												)}
											</div>
										</div>
									))}
							</div>
						)}
					</div>
					{editAccess && (
						<div>
							<button
								type="button"
								className={`${styles.addQuestionBtn} ${setupData?.question_list?.length >= 15 ? styles.disabledAddQuestionBtn : ""}`}
								disabled={setupData?.question_list?.length >= 15}
								onClick={addQuestion}
							>
								<FiPlus />
								<span>{setupData?.question_list?.length >= 15 ? "Max Questions Reached" : pageText.AddNewQuestion}</span>
							</button>
						</div>
					)}
				</>
			)}
		</>
	);
}
