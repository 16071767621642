import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef, Suspense } from "react";
import * as THREE from "three";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

export default function HDR_SETUP({ hdrMapFilePath }) {
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;
	const { gl, scene } = useThree(({ gl, scene }) => ({ gl, scene }));
	useEffect(() => {
		// const pmremGenerator = new THREE.PMREMGenerator(gl);

		gl.outputEncoding = THREE.sRGBEncoding;
		// gl.toneMapping = THREE.ACESFilmicToneMapping;
		// gl.toneMappingExposure = 0.8;

		gl.ColorManagement = THREE.ColorManagement.enabled;
		const loader = new RGBELoader();
		loader.load(`${url}/getFile?file=${hdrMapFilePath}`, (texture) => {
			// was: "/getFile?file=emptyroom_hdr.hdr"
			texture.mapping = THREE.EquirectangularReflectionMapping;
			scene.background = texture;
			scene.environment = texture;
		});

		loader.load;
	}, [scene, gl]);
	return <></>;
}
