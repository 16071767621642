import { useRef, useState, useEffect } from "react";

import { sleep } from "../UtilityFunctions/sleep.js";
import usePollingIntervals from "../UtilityFunctions/usePollingIntervals";
import { useDeviceSettings } from "../UtilityFunctions/useDeviceSettings.js";

const speechsdk = require("microsoft-cognitiveservices-speech-sdk");

export function useSpeechRecognizer() {
	const [isListening, setIsListening] = useState(false);
	const [transcript, setTranscript] = useState("");
	const [interimTranscript, setInterimTranscript] = useState("");

	const [message, setMessage] = useState(null);
	const recognizerRef = useRef(null);

	const startListening = () => {
		setIsListening(true);
		const attempt = 0;
		// Initialize speech recognition logic here
		const subscriptionKey = attempt === 0 ? "979d2669321146bcb56dff424a0dff1a" : "3f03e1e189924d1185d5d5b03827691e";
		const serviceRegion = attempt === 0 ? "eastus" : "canadacentral";
		const speechConfig = speechsdk.SpeechConfig.fromSubscription(subscriptionKey, serviceRegion);

		speechConfig.setProperty(speechsdk.PropertyId.SpeechServiceResponse_PostProcessingOption, "default");
		// TrueText;
		speechConfig.outputFormat = speechsdk.OutputFormat.Detailed;

		const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
		recognizerRef.current = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);

		const phraseList = speechsdk.PhraseListGrammar.fromRecognizer(recognizerRef.current);
		phraseList.addPhrase("um");
		phraseList.addPhrase("ah");
		phraseList.addPhrase("eh");
		phraseList.addPhrase("mmm");
		phraseList.addPhrase("uh");
		phraseList.addPhrase("InStage");

		console.log("Listening...");
		setMessage("Listening... speak into your microphone...");

		recognizerRef.current.startContinuousRecognitionAsync();

		recognizerRef.current.recognizing = (s, e) => {
			console.log(`RECOGNIZING: Text=${e.result.text}`, e.result);
			setMessage(`RECOGNIZING: Text=${e.result.text}`);
			setInterimTranscript(e.result.text);
		};

		recognizerRef.current.recognized = (s, e) => {
			if (e.result.reason === speechsdk.ResultReason.RecognizedSpeech) {
				console.log(`RECOGNIZED: Text=${e.result.text}`, e.result);
				setTranscript(e.result.text);
				setMessage(`RECOGNIZED: Text=${e.result.text}`);
			}
		};

		recognizerRef.current.canceled = (s, e) => {
			console.log(`CANCELED: Reason=${e.reason}`);
			if (e.reason === speechsdk.CancellationReason.Error) {
				console.log(`CANCELED: ErrorCode=${e.errorCode}`);
				console.log(`CANCELED: ErrorDetails=${e.errorDetails}`);
				console.log("CANCELED: Did you update the subscription info?");
			}
			setMessage("ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.");
		};
	};

	const stopListening = () => {
		if (recognizerRef.current) {
			recognizerRef.current.stopContinuousRecognitionAsync(
				() => {
					// console.log("Recognition stopped.");
					setIsListening(false);
					recognizerRef.current.close();
					recognizerRef.current = null;
				},
				(err) => {
					console.error(err);
				}
			);
		}
	};

	// Export the states and control functions
	return {
		isListening,
		transcript,
		interimTranscript,
		message,
		startListening,
		stopListening
	};
}
