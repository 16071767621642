import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef, Suspense } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import { Canvas, useFrame } from "@react-three/fiber";
import { useProgress, PerspectiveCamera, View, OrbitControls, CameraControls, TransformControls } from "@react-three/drei";
import s from "../../styleModules/setupStyles.module.css";
import Office from "./Personal_office.jsx";
import { Character } from "./Character.js";
import ToneMapping from "./ToneMapping.js";
import { characterDict } from "./characterDict.js";
import TypingText from "../UtilityFunctions/TypingText.js";

const ModelViewer = forwardRef((props, ref) => {
	const { param1 } = useParams();
	const characterName = param1 || "Nina";
	const character = characterDict[characterName];
	const character2 = characterDict[characterName === "Nina" ? "John" : "Nina"];
	const characterRef = useRef();
	const background = useRef();
	const [changeDebug, setChangeDebug] = useState(Date.now);
	const [optionOpen, setOptionOpen] = useState(false);
	const { active, progress, errors, item, loaded, total } = useProgress();
	const [talking, SetTalking] = useState(false);
	const [transcript, SetTranscript] = useState(false);

	const [anim, setAnim] = useState("");
	const [camPosition, set_camPosition] = useState(character.cameraPosition);
	const [camRotation, set_camRotation] = useState(character.cameraRotation);

	const [countDown, set_countDown] = useState("");
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		let interval;
		interval = setInterval(() => {
			// console.log(characterRef.current?.getAnim())
			setAnim(characterRef.current?.getAnim());
		}, 200);
		return () => clearInterval(interval);
	}, []);

	//  console.log(character.animations.ReactionAnimsName)
	const typingRefs = useRef(null);

	const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	const currentAudioSource = useRef(null);
	const AudioRef = useRef(null);
	const playAudio = () => {
		const bookingURL = process.env.REACT_APP_BOOKING_BACKEND_URL;

		if (AudioRef.current) {
			console.log(AudioRef.current.src);
			if (AudioRef.current.src === "") {
				AudioRef.current.src = `${bookingURL}/getFile?file=Correct_chime_with_volume.wav`;
			}
			if (AudioRef.current.paused) {
				setAudioPlaying(true);
				AudioRef.current.play();
			} else {
				setAudioPlaying(false);
				AudioRef.current.pause();
			}

			AudioRef.current.addEventListener("ended", () => {
				setAudioPlaying(false);
			});
		}
	};

	async function test1() {
		playAudio();
		set_countDown("5");
		await sleep(1000);
		set_countDown("4");
		await sleep(1000);
		set_countDown("3");
		await sleep(1000);
		set_countDown("2");
		await sleep(1000);
		set_countDown("1");
		await sleep(1000);
		set_countDown("0");
		await sleep(1000);
		set_countDown("");
		characterRef.current.realTimeLipsync(
			"Hi Instage, a long time ago I briefly looked into, how you might configure an app created by create-react-app to work with the vad, but I concluded early on that it was more trouble than it was worth for me at the time. I can eventually try to provide an example, but I won't have time to look into it in the near future. However, one thing that I expect would work is to create a build script that looks something like",
			"Brian"
		);
	}
	return (
		<>
			<Helmet>
				<title>InStage ModelViewer</title>

				<meta name="robots" content="noindex" />
			</Helmet>
			<div className="page">
				<div className="pageContainer">
					{/* {talking ? "talking":"silent"}
                <br/>
                {transcript} */}
					<h1>
						<TypingText refHolder={typingRefs}>Model Viewer</TypingText>
					</h1>

					<audio ref={AudioRef}>
						<source type="audio/wav" />
						Your browser does not support the audio element.
					</audio>

					<br />

					{/* <button onClick={() => { test1() }}>
                <TypingText refHolder= {typingRefs}>
                start Test1
                    </TypingText>

                </button>  <br /> countDown {countDown}

                <button onClick={ playAudio }>
                <TypingText refHolder= {typingRefs}>
                play chime
                    </TypingText>

                </button> */}
					<button
						onClick={() => {
							characterRef.current.startStoryTime("Brian");
						}}
					>
						<TypingText refHolder={typingRefs}>start Story Time</TypingText>
					</button>

					<p>{anim}</p>
					<Canvas style={{ position: "absolute" }}>
						<ToneMapping />

						<PerspectiveCamera makeDefault fov={75} near={0.1} far={1000} position={camPosition} rotation={camRotation} />

						<Character
							ref={characterRef}
							hideMesh
							name={character.name}
							url={character.url}
							currentAudioSource={currentAudioSource}
							lightSettings={character.lightSettings}
							background={character.background}
							urlAnimations={character.urlAnimations}
							animations={character.animations}
							position={[0, 0, 0]}
							rotation={character.characterRotation}
						/>

						{/* <TransformControls object={background}  mode="translate"    />  */}

						{/* <Suspense >
                                <Office></Office>
                            </Suspense>  */}

						{/* <CameraControls position={character.cameraPosition} rotation={character.cameraRotation} ref={CameraControlsRef} makeDefault /> */}
					</Canvas>
				</div>
			</div>
		</>
	);
});

export default ModelViewer;
