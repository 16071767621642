import React from "react";
import styles from "../styleModules/adminSystemStyles.module.css";
import { useNavigate } from "react-router-dom";

function NoAccessPage() {
	const navigate = useNavigate();
	return (
		<div className={styles.noAccessContainer}>
			<h2 className={styles.noAccessTitle}>License Expired</h2>
			<br />
			<p>We're sorry, but your license has expired.</p>
			<p>Please renew your subscription to regain access to our services.</p>

			<p>
				Contact{" "}
				<a href="mailto:team@instage.io" style={{ color: "#0077be" }}>
					team@instage.io
				</a>{" "}
				for more information.
			</p>
			<button className={styles.tryAgainBtn} type="button" onClick={() => navigate("/account/logout", {})}>
				Try Again
			</button>
		</div>
	);
}

export default NoAccessPage;

// ... existing code ...
