import { useRef, useEffect, useCallback } from "react";

const usePollingIntervals = () => {
	const pollingIntervals = useRef([]);

	const setPollingInterval = useCallback((callback, delay) => {
		const intervalId = setInterval(callback, delay);
		pollingIntervals.current.push(intervalId);
		return intervalId;
	}, []);

	const clearPollingInterval = useCallback((intervalId) => {
		// 	console.log("clearPollingInterval", intervalId, pollingIntervals.current);
		clearInterval(intervalId);
		pollingIntervals.current = pollingIntervals.current.filter((id) => id !== intervalId);
	}, []);

	useEffect(
		() =>
			// This will be called when the component that uses the hook unmounts
			() => {
				pollingIntervals.current.forEach(clearInterval);
				pollingIntervals.current = [];
			},
		[]
	); // Empty dependency array ensures this effect runs only on mount and unmount

	return { setPollingInterval, clearPollingInterval };
};

export default usePollingIntervals;
