export const cleanResponse = (inputString) => {
	// Remove any text between asterisks, curly braces, and parentheses, including the asterisks, curly braces, and parentheses
	let cleanedString = inputString.replace(/\*[^*]*\*|\{[^}]*\}|\([^)]*\)/g, "");

	// Reformat separated quote strings into one
	cleanedString = cleanedString.replace(/"([^"]*)"/g, (match, capture) => capture || match);

	// Add double quotes around the concatenated quote sections
	cleanedString = cleanedString.replace(/^([^:]*:\s*)(.*)$/, '$1"$2"');

	// Replace double spaces with single spaces
	cleanedString = cleanedString.replace(/  +/g, " ");

	return cleanedString;
};
