import React, { useState, useRef, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { IoClose } from "react-icons/io5/index.esm.js";
import { FiChevronLeft, FiChevronRight, FiMessageSquare, FiUser } from "react-icons/fi/index.esm.js";
import s from "../../styleModules/modalStyles.module.css";
import styles from "../../styleModules/reportStyles.module.css";
import ModalWrapper from "./ModalWrapper.js";
import { PLANS } from "../../plans.config.js";
import { AppContext, CommentContext } from "../UtilityFunctions/AppContext.js";

function isIterable(variable) {
	// Check if the variable is an object and not null
	if (typeof variable !== "object" || variable === null) {
		return false;
	}

	// Check if the object has a Symbol.iterator property
	return Symbol.iterator in variable;
}

export function CommentIcon({ data_id }) {
	const { tenantPlan, customFeatures, showLockedModal } = useContext(AppContext);
	const { showComment, comments, showEmptyCommentIcons } = useContext(CommentContext);

	const defaultAccess = PLANS[tenantPlan]?.features?.proReport;
	const proAccess = customFeatures?.proReport ?? defaultAccess;

	let show = showEmptyCommentIcons;
	let commentsExist = false;
	if (comments && comments[data_id] && comments[data_id].length > 0) {
		show = true;
		commentsExist = true;
	} else if (showEmptyCommentIcons) {
		show = true;
	} else {
		show = false;
	}
	if (show) {
		return (
			<div
				className={styles.commentIconContainer}
				style={{
					color: !proAccess ? "#7d7d7d" : commentsExist ? "teal" : "unset"
				}}
				onClick={(event) => {
					event.stopPropagation(); // Consume the click event, preventing it from propagating to parent elements
					if (!proAccess) {
						showLockedModal("Admin Comments");
					} else {
						showComment(data_id);
					}
				}}
			>
				{/* <p className={styles.commentIconText}>Comment</p> */}
				<FiMessageSquare className={styles.commentIcon} />
			</div>
		);
	}
	return <></>;
}

export function CommentModal({ localUser, manager, comment, data_id, show, onHide, onSave, userId }) {
	// console.log(localUser)
	const [local_comments, setLocal_comments] = useState(comment);
	const { gpt_session_id } = useParams();

	const [feedbackText, setFeedbackText] = useState("");
	let tempIndex = 0;
	if (local_comments && local_comments.length > 0) {
		tempIndex = local_comments.length;
	}
	const [index, setIndex] = useState(tempIndex);

	const modalRef = useRef();

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (modalRef.current && !modalRef.current.contains(event.target)) {
				close();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [onHide]);
	useEffect(() => {
		function createEmptyComment() {
			return {
				Text: "",
				id: data_id,
				Timestamp: new Date().toISOString(),
				created_by: localUser.name,
				created_by_id: userId
			};
		}
		console.log(local_comments);
		// let current_comment = local_comments[index];
		if (local_comments === undefined) {
			const newComment = createEmptyComment();
			const temp_comments = [newComment];

			setLocal_comments(temp_comments);

			setFeedbackText("");
		} else if (manager) {
			if (local_comments[index] === undefined) {
				const newComment = createEmptyComment();
				// check if local_comments is iterable
				if (isIterable(local_comments)) {
					const temp_comments = [...local_comments, newComment];

					setLocal_comments(temp_comments);
				} else {
					const temp_comments = [local_comments, newComment];

					setLocal_comments(temp_comments);
				}

				setFeedbackText("");
			} else {
				setFeedbackText(local_comments[index].Text);
			}
		}
		// setFeedbackText(temp_comment[0].Text)

		return () => {};
	}, [comment, index]);

	function close() {
		onHide();
	}
	function save() {
		const temp_comments = [...local_comments];
		temp_comments[index].Text = feedbackText;
		setLocal_comments(temp_comments);

		onSave(temp_comments[index], data_id);
		// onSave(feedbackText)
	}
	const show_style = { height: "unset", bottom: "unset" };
	const hide_style = {};
	// && local_comments[index]?.Text?.length === 0

	const canSave = local_comments && local_comments[index] && local_comments[index]?.Text != feedbackText;
	const canCancel = true;
	let canNext = false;
	if (manager) {
		canNext = local_comments && local_comments[index] && local_comments[index]?.Text?.length > 0;
	} else {
		canNext = local_comments && local_comments[index + 1];
	}

	const canPrev = local_comments && index > 0;
	let createdBy = "";
	if (localUser && localUser.email) {
	}
	if (local_comments && local_comments[index]) {
		createdBy = local_comments[index].created_by;
	} else {
		createdBy = localUser.email;
	}
	return (
		<>
			{show && (
				<ModalWrapper closeModal={() => onHide()}>
					<div className={s.modal}>
						<span className={s.modalCloseIcon}>
							<IoClose title="Close icon" onClick={() => close()} />
						</span>
						{local_comments &&
							local_comments.map((comment, i) => {
								// if not last comment

								if (manager && i == index) {
									return (
										<>
											<p className={s.npsTitle}>{createdBy}</p>
											<textarea
												style={{ height: "2lh" }}
												className={s.modalTextarea}
												id="feedbackText"
												name="feedbackText"
												rows="3"
												placeholder="Add a comment"
												value={feedbackText}
												onChange={(e) => {
													setFeedbackText(e.target.value);
												}}
											/>
										</>
									);
								}
								return (
									<div>
										<p className={s.npsTitle}>{comment.created_by}</p>
										<p>{comment.Text}</p>
										<hr />
									</div>
								);
							})}

						<>
							<div className={s.modalBtnContainer}>
								{manager && (
									<>
										<button
											className={s.modalConfirmBtn}
											disabled={!canSave}
											onClick={() => {
												save();
												if (index == local_comments.length - 1) {
													setIndex(index + 1);
												}
											}}
										>
											Comment
										</button>
										<button
											className={s.modalCancelBtn}
											disabled={!canCancel}
											onClick={() => {
												setFeedbackText("");
											}}
										>
											Cancel
										</button>
									</>
								)}
								{manager && (
									<>
										{(canPrev || canNext) && (
											<>
												<button
													className={`${s.modalNavBtn} ${canPrev ? "" : s.disabledNavBtn}`}
													disabled={!canPrev}
													onClick={() => {
														setIndex(index - 1);
													}}
												>
													<FiChevronLeft />
												</button>
												{index + 1}/{local_comments?.length}
												<button
													className={`${s.modalNavBtn} ${canNext ? "" : s.disabledNavBtn}`}
													disabled={!canNext}
													onClick={() => {
														setIndex(index + 1);
													}}
												>
													<FiChevronRight />
												</button>
											</>
										)}
									</>
								)}
							</div>
							{/* <div className={s.formBtnContainer}>
								<button
									className={feedbackText.length > 0 ? s.feedbackBtn : s.feedbackBtnDisabled}
									disabled={feedbackText.length === 0}
									onClick={(e) => {

										onSave(feedbackText)
										onHide();
									}}
								>
									Submit
								</button>
								{/* <button
									className={`${s.feedbackBtn} ${s.skipBtn}`}
									onClick={(e) => onHide()}
								>
									Skip
								</button>
							</div>
							*/}
						</>
					</div>
				</ModalWrapper>
			)}
		</>
	);
}

// export default CommentModal;
