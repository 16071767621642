import React, { useEffect, useState, useContext, useRef } from "react";
import { FiDownload, FiMaximize, FiPlay, FiPause, FiVolume2, FiVolumeX, FiX } from "react-icons/fi";
import { MdPauseCircleOutline, MdReplay } from "react-icons/md";
import { VideoContext } from "../../UtilityFunctions/AppContext";
import r from "../../../styleModules/reportStyles.module.css";
import p from "../../../styleModules/popupVideoStyles.module.css";

function PopupVideoPlayer({ videoLink }) {
	const videoRef = useRef(null);

	const [isMuted, setIsMuted] = useState(true);
	const [showReplay, setShowReplay] = useState(false);
	const [isPlaying, setIsPlaying] = useState(true);

	const handleVideoEnd = () => {
		setShowReplay(true);
	};

	const togglePlay = () => {
		if (videoRef.current) {
			if (isPlaying) {
				videoRef.current.pause();
			} else {
				videoRef.current.play();
			}
			setIsPlaying(!isPlaying);
		}
	};

	const handleReplay = () => {
		if (videoRef.current) {
			videoRef.current.currentTime = 0;
			videoRef.current.play();
			// setShowReplay(false);
		}
	};

	const toggleMute = () => {
		setIsMuted(!isMuted);
	};

	useEffect(() => {
		setShowReplay(false);
	}, [videoLink]);

	return (
		<div className={p.video}>
			<video
				ref={videoRef}
				key={videoLink}
				autoPlay
				muted={isMuted}
				style={{ width: "100%", borderRadius: "8px", cursor: "default" }}
				onClick={togglePlay}
				onMouseEnter={() => {
					videoRef.current.style.cursor = "pointer";
				}}
				onMouseLeave={() => {
					videoRef.current.style.cursor = "default";
				}}
				onEnded={() => setShowReplay(true)}
			>
				<source src={`https://supportvideos.s3.ca-central-1.amazonaws.com/${videoLink}`} type="video/mp4" />
				Your browser does not support the audio element.
			</video>
			{/* {showReplay && <MdReplay onClick={handleReplay} className={p.replayIcon} />} */}
			<div className={p.videoToggles}>
				{isMuted ? (
					<FiVolumeX className={p.toggleButtons} onClick={toggleMute} />
				) : (
					<FiVolume2 className={p.toggleButtons} onClick={toggleMute} />
				)}
				{isPlaying ? (
					<MdPauseCircleOutline className={p.toggleButtons} onClick={togglePlay} />
				) : (
					<FiPlay className={p.toggleButtons} onClick={togglePlay} />
				)}
				<MdReplay onClick={handleReplay} className={p.toggleButtons} />
			</div>
		</div>
	);
}

export default PopupVideoPlayer;
