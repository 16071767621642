import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLoginWithRedirect } from "@frontegg/react";

import { AppContext } from "../UtilityFunctions/AppContext.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import a from "../../styleModules/signupStyles.module.css";

export default function LandingPage() {
	const { handleNavigate } = useContext(AppContext);
	const location = useLocation();

	const navigate = useNavigate();
	const loginWithRedirect = () => {
		handleNavigate("/account/login", true, { redirectUrl: location.pathname + location.search });
	}; // useLoginWithRedirect();

	const { tenantId, adminAccess } = useContext(AppContext);

	const tenantOverrideList = [
		"001-75289-47765-62687", // Lakefield College School
		"001-39655-87935-58738", // Mohawk College
		"001-83655-59532-26989" // BCIT Student Association
	];

	useEffect(() => {
		// if (tenantId && tenantOverrideList.includes(tenantId)) {
		// 	if (adminAccess !== null && adminAccess) {
		// 		navigate(`/admin`);
		// 	} else {
		// 		navigate(`/practice`);
		// 	}
		// } else if (tenantId && tenantId.startsWith("001")) {
		// 	navigate(`/edu`);
		// } else if (tenantId && tenantId !== "") {
		// 	if (adminAccess) {
		// 		navigate(`/admin`);
		// 	} else {
		// 		navigate(`/practice`);
		// 	}
		// }
		// console.log(tenantId);
		const uoftIDs = ["001-54758-75833-75688", "001-39362-32479-47456"];
		navigate("/dashboard");
		if (tenantId && uoftIDs.includes(tenantId)) {
			navigate("/dashboard");
		} else if (adminAccess !== null && tenantId && tenantId !== "") {
			if (adminAccess) {
				navigate("/admin");
			} else {
				navigate("/dashboard");
			}
		} else {
			console.log("loginWithRedirect");
			loginWithRedirect();
		}
	}, [tenantId, adminAccess]);

	return (
		<div>
			<Helmet>
				<title>InStage</title>
				<meta name="description" content="Home Page" />
				<meta name="robots" content="noindex,nofollow" />
			</Helmet>
			<div className={a.confirmedPage}>
				<div className={a.confirmedWrapper}>
					<div className={a.confirmedContainer}>
						<p style={{ fontSize: "2rem" }}>You are being redirected, please wait...</p>
						<div className={a.spinnerContainer}>
							<LoadingSpinner height={150} width={150} thickness={15} color="#00a9af" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
