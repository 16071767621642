import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { cleanResponse } from "../UtilityFunctions/cleanResponse.js";

const audioServerUrl = process.env.REACT_APP_AUDIO_BACKEND_URL;

const visemes = {
	A_E: {
		V_Open: 0.369,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0.861,
		V_Affricate: 0,
		V_Lip_Open: 0.4
	},
	Ah: {
		V_Open: 0.9,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0.3
	},
	B_M_P: {
		V_Open: 0,
		V_Explosive: 1,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0
	},
	CH_J: {
		V_Open: 0,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0.9,
		V_Lip_Open: 0
	},
	EE: {
		V_Open: 0,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0.859,
		V_Affricate: 0,
		V_Lip_Open: 0.78
	},
	Er: {
		V_Open: 0.22,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0.274,
		V_Lip_Open: 0
	},
	F_V: {
		V_Open: 0.14,
		V_Explosive: 0,
		V_Dental_Lip: 0.9,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0
	},
	Ih: {
		V_Open: 0.137,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0.609
	},
	K_G: {
		V_Open: 0.059,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0.703
	},
	Oh: {
		V_Open: 0.545,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0.686,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0
	},
	R: {
		V_Open: 0.094,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0.78,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0
	},
	S_Z: {
		V_Open: 0,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0.171,
		V_Affricate: 0,
		V_Lip_Open: 0.853
	},
	T_L_D: {
		V_Open: 0.064,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0.4,
		V_Affricate: 0,
		V_Lip_Open: 0.545
	},
	Th: {
		V_Open: 0.184,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0.609
	},
	W_OO: {
		V_Open: 0,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0.824,
		V_Tight: 0.2,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0
	},
	SIL: {
		V_Open: 0,
		V_Explosive: 0,
		V_Dental_Lip: 0,
		V_Tight_O: 0,
		V_Tight: 0,
		V_Wide: 0,
		V_Affricate: 0,
		V_Lip_Open: 0
	}
};
// https://docs.aws.amazon.com/polly/latest/dg/ph-table-english-us.html
const awsVisemeMap = {
	"@": "Ah",
	r: "Er",
	i: "CH_J",
	t: "T_L_D",
	a: "A_E",
	p: "B_M_P",
	s: "S_Z",
	T: "Th",
	f: "F_V",
	e: "Er",
	E: "EE",
	k: "K_G",
	O: "Oh",
	S: "CH_J",
	u: "W_OO",
	sil: "SIL"
};
export const visemeObject = {
	0: { sounds: ["Silence"], description: "The mouth position when viseme ID is 0" },
	1: { sounds: ["æ", "ə", "ʌ"], description: "The mouth position when viseme ID is 1" },
	2: { sounds: ["ɑ"], description: "The mouth position when viseme ID is 2" },
	3: { sounds: ["ɔ"], description: "The mouth position when viseme ID is 3" },
	4: { sounds: ["ɛ", "ʊ"], description: "The mouth position when viseme ID is 4" },
	5: { sounds: ["ɝ"], description: "The mouth position when viseme ID is 5" },
	6: { sounds: ["j", "i", "ɪ"], description: "The mouth position when viseme ID is 6" },
	7: { sounds: ["w", "u"], description: "The mouth position when viseme ID is 7" },
	8: { sounds: ["o"], description: "The mouth position when viseme ID is 8" },
	9: { sounds: ["aʊ"], description: "The mouth position when viseme ID is 9" },
	10: { sounds: ["ɔɪ"], description: "The mouth position when viseme ID is 10" },
	11: { sounds: ["aɪ"], description: "The mouth position when viseme ID is 11" },
	12: { sounds: ["h"], description: "The mouth position when viseme ID is 12" },
	13: { sounds: ["ɹ"], description: "The mouth position when viseme ID is 13" },
	14: { sounds: ["l"], description: "The mouth position when viseme ID is 14" },
	15: { sounds: ["s", "z"], description: "The mouth position when viseme ID is 15" },
	16: { sounds: ["ʃ", "tʃ", "dʒ", "ʒ"], description: "The mouth position when viseme ID is 16" },
	17: { sounds: ["ð"], description: "The mouth position when viseme ID is 17" },
	18: { sounds: ["f", "v"], description: "The mouth position when viseme ID is 18" },
	19: { sounds: ["d", "t", "n", "θ"], description: "The mouth position when viseme ID is 19" },
	20: { sounds: ["k", "g", "ŋ"], description: "The mouth position when viseme ID is 20" },
	21: { sounds: ["p", "b", "m"], description: "The mouth position when viseme ID is 21" }
};
export const soundToVisemeMapping = {
	Silence: "SIL",
	æ: "A_E",
	ə: "A_E",
	ʌ: "A_E",
	ɑ: "Ah",
	ɔ: "Oh",
	ɛ: "EE",
	ʊ: "W_OO",
	ɝ: "Er",
	j: "EE",
	i: "EE",
	ɪ: "Ih",
	w: "W_OO",
	u: "W_OO",
	o: "Oh",
	aʊ: "W_OO",
	ɔɪ: "Oh",
	aɪ: "A_E",
	h: "SIL",
	ɹ: "R",
	l: "T_L_D",
	s: "S_Z",
	z: "S_Z",
	ʃ: "S_Z",
	tʃ: "CH_J",
	dʒ: "CH_J",
	ʒ: "S_Z",
	ð: "Th",
	f: "F_V",
	v: "F_V",
	d: "T_L_D",
	t: "T_L_D",
	n: "T_L_D",
	θ: "Th",
	k: "K_G",
	g: "K_G",
	ŋ: "K_G",
	p: "B_M_P",
	b: "B_M_P",
	m: "B_M_P"
};
// converts from animation viseme name to morph target values
export function AnimationValueToVisemesMorphs(value) {
	return visemes[value];
}
export async function getAnimation(text) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const cleanText = cleanResponse(text);
	// console.log("getAnimation");
	/// console.log(text);
	const response = await axiosLimitedGet(`${audioServerUrl}/Visemes`, 1, { params: { text: cleanText } });
	// Handle successful response
	const viseme = convertRawPollyVisemeToObject(response.data);
	// console.log(viseme);
	// loop through viseme array and convert viseme with awsVisemeMap
	const animation = [];
	animation.push({ time: 0, value: "SIL" });
	for (let index = 0; index < viseme.length; index++) {
		const element = viseme[index];
		element.value = clamp01(awsVisemeMap[element.value]);
		element.time /= 1000;
		// remove type property
		delete element.type;
		animation.push(element);
	}
	// console.log(animation);
	return animation;
}
export async function getAudio(text, voice) {
	const cleanText = cleanResponse(text);

	// console.log("getAudio");
	// create url for audio witha query url encoded string

	// console.log("getAudio",text)
	let trimedText = cleanText.trim();

	if (trimedText.includes(":")) {
		trimedText = trimedText.split(":").slice(1).join(" ");
	}
	// eslint-disable-next-line quotes
	if (trimedText[0] === "." || trimedText[0] === "," || trimedText[0] === "!" || trimedText[0] === "?" || trimedText[0] === '"') {
		trimedText = trimedText.substring(1);
	}
	if (trimedText === "") {
		// return "";
		trimedText += " ";
	}
	// console.log("getAudio",trimedText)
	const url = `${audioServerUrl}/TestPolly?text=${encodeURIComponent(trimedText)}&voice=${voice}`;
	// TestPolly

	// let response = await  axios.get(audioServerUrl+'/TestPolly',{params:{text:text}})
	// Handle successful response
	return url;
}
function convertRawPollyVisemeToObject(visemeData) {
	// console.log("convertRawPollyVisemeToObject");
	// console.log(visemeData);
	const modifiedString = visemeData.replace(/\n/g, ",");
	const jsonArrayString = `[${modifiedString.slice(0, -1)}]`;
	// console.log(jsonArrayString)
	const jsonObject = JSON.parse(jsonArrayString);

	// for (let index = 0; index < jsonObject.length; index++) {
	//     const element = jsonObject[index];
	//     // console.log(element)
	//     pushUnique(uniqueVisemeArray,element.value)
	// }
	// console.log(uniqueVisemeArray)
	return jsonObject;
}
export function clamp01(num) {
	return Math.max(0, Math.min(num, 1));
}
export function lerp(a, b, t) {
	return a + (b - a) * t;
}
