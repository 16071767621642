import React from "react";
import s from "../../styleModules/modalStyles.module.css";

export const Metrics = {
	pace: {
		lowerBound: 0,
		upperBound: 250,
		normalStart: 110,
		normalEnd: 199,
		unit: "wpm",
		tips: {
			title: "Tips For Pace",
			content: (
				<>
					<h4 className={s.modalSubtitle}>Why not talk fast?</h4>
					<p>
						Are you in a hurry? Talking too fast can make it tough for your audience to keep up and understand what you're saying. Not
						only that, but it can also make you appear nervous or unprepared. In a formal setting, like a speech or presentation, speaking
						too quickly can detract from your professionalism. Slow down and take a breath. Pausing and speaking at a slower pace can help
						you connect with your audience and appear confident and in control.
					</p>
					<h4 className={s.modalSubtitle}>10 tips for speaking more slowly:</h4>
					<ol>
						<li>
							To master the art of speaking slowly, try incorporating a pre-speech practice routine where you speak at a slower pace.
							This will make it feel more natural when you're giving a presentation or speech.
						</li>
						<li>
							Before beginning a sentence, take a moment to count to three in your head. This can help you pause, take a breath, and
							slow down your speaking pace.
						</li>
						<li>Using a metronome, a tool that helps you keep time, set it to a slow tempo, and practice speaking along with it.</li>
						<li>
							Record yourself speaking and listen to the recording. This will help you identify when you're speaking too fast and make
							adjustments accordingly.
						</li>
						<li>
							Ask for feedback from someone you trust. They can help you identify when you're speaking too fast and give you tips on how
							to slow down.
						</li>
						<li>
							Take deep breaths before speaking, and try to breathe from your diaphragm. This will help you speak more slowly and
							calmly.
						</li>
						<li>
							Pausing between sentences and thoughts can help you speak more slowly and give your audience time to process what you're
							saying.
						</li>
						<li>
							Speak with intention and focus on communicating your message clearly. Think about what you want to say before you say it.
						</li>
						<li>Break down your speech into smaller chunks, this will help you focus on speaking at a steady pace.</li>
						<li>
							Incorporate humor in your speech. It can help you relax and slow down your pace, making it easier for the audience to
							follow along and understand.
						</li>
					</ol>
				</>
			)
		}
	},
	loudness: {
		lowerBound: -40, // Lower bound to include +/- 15dBFS around quiet zone
		upperBound: 1, // Upper bound to include +/- 15dBFS around loud zone
		normalStart: -30,
		normalEnd: -10,
		unit: "dB",
		tips: {
			title: "Tips For Volume",
			content: (
				<>
					<h4 className={s.modalSubtitle}>Why pay attention to your volume?</h4>
					<p>
						If you talk too quiet, one tactic is to speak with intention and focus on projecting your voice. Try speaking in a slightly
						louder tone than you're used to and practice speaking in different environments, such as in front of a mirror or with a group
						of friends. Additionally, you can try using a microphone or other amplifying device to help your voice carry.
					</p>
					<p>
						On the other hand, if you talk too loud, try speaking in a slightly lower tone than you're used to and focus on modulating
						your volume. It's also helpful to pay attention to the volume of your surroundings, and adjust accordingly. Try speaking in
						different environments, such as in front of a mirror or with a group of friends, it will help you get a sense of how you
						sound.
					</p>
					<p>
						Another tip is to practice breathing exercises, it will help you relax, and modulate your volume. Also, incorporating humor in
						your speech, it can help you relax and control your volume.
					</p>
					<p>
						The key to speaking at the right volume is to be aware of your surroundings and to practice speaking in different
						environments. The more you practice, the more control you'll have over your volume.
					</p>
				</>
			)
		}
	},
	fillers: {
		lowerBound: 0,
		upperBound: 25,
		normalStart: 0,
		normalEnd: 6,
		unit: "%",
		tips: {
			title: "Tips For Fillers",
			content: (
				<>
					<h4 className={s.modalSubtitle}>Why not use a lot of filler words?</h4>
					<p>
						Picture this: you're sitting in a presentation, eagerly awaiting the speaker to share their insights and knowledge. But
						instead of focusing on the content, you find yourself counting the number of times they say "um" or "ah." It's a frustrating
						experience, isn't it?
					</p>
					<p>
						Well, just like that, filler words can be a major distraction for your audience, making it difficult for them to fully grasp
						and remember the information you're sharing. Not only that, but it can also make you appear less confident and in control of
						your message. Think of filler words as the white noise in a symphony- they may not necessarily ruin the performance, but they
						certainly detract from the beauty of it.
					</p>
					<p>
						But don't despair! Instead of relying on filler words, try speaking with intention and focus on your message. Use rhetorical
						questions and storytelling to make your points relatable and memorable. And remember, the audience is here to hear what you
						have to say, not to count how many filler words you use. So, take a deep breath, and let your message shine through.
					</p>
					<h4 className={s.modalSubtitle}>10 tips for using less filler words:</h4>
					<ol>
						<li>
							Identify the filler words you commonly use, such as "um," "ah," "like," "you know," etc. This will help you become aware
							of them and avoid using them as much.
						</li>
						<li>
							Record yourself speaking and listen to the recording. This can help you identify when you are using filler words and make
							adjustments accordingly.
						</li>
						<li>
							Instead of using filler words, try to pause briefly before speaking. This can help you gather your thoughts and speak more
							clearly.
						</li>
						<li>Speak more slowly. This will help you avoid filler words and prevent you from feeling the need to fill silence.</li>
						<li>Practice speaking without filler words by reading aloud or giving a speech in front of a mirror or a friend.</li>
						<li>
							Seek feedback from someone you trust. They can help you identify when you are using filler words and give you tips on how
							to reduce them.
						</li>
						<li>
							Focus on your breathing by taking deep breaths before speaking and trying to breathe from your diaphragm. This can help
							you speak more slowly and calmly, reducing the use of filler words.
						</li>
						<li>
							Speak with intention by thinking about what you want to say before you say it and focusing on communicating your message
							clearly.
						</li>
						<li>
							Break down your speech into smaller chunks. This will help you focus on speaking without filler words, rather than trying
							to rush through the entire speech.
						</li>
						<li>Incorporate humor in your speech. This can help you relax and reduce the use of filler words.</li>
					</ol>
				</>
			)
		}
	}
};
