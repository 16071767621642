import React, { useState, useRef, useContext } from "react";
import { usePostHog } from "posthog-js/react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import styles from "../../styleModules/modalStyles.module.css";
import { LoadingSpinner } from "./LoadingSpinner";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export default function ContactForm({ setStep }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { localUser } = useContext(AppContext);
	const posthog = usePostHog();
	const [formData, setFormData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		phoneNumber: "",
		institutionType: "Career Centre - Higher Ed",
		company: "",
		position: "",
		environment: "production",
		emailOptIn: ""
	});
	const [loading, setLoading] = useState(false);
	const [formError, setFormError] = useState(false);
	const [errorText, setErrorText] = useState("");
	const emailInputRef = useRef(null);

	// const blockedDomains = [
	// 	"gmail.com",
	// 	"hotmail.com",
	// 	"hotmail.ca",
	// 	"yahoo.com",
	// 	"yahoo.ca",
	// 	"aol.com",
	// 	"outlook.com",
	// 	"live.com",
	// 	"msn.com",
	// 	"msn.ca",
	// 	"icloud.com",
	// 	"zoho.com",
	// 	"protonmail.com",
	// 	"ymail.com",
	// 	"comcast.net",
	// 	"verizon.net",
	// 	"sbcglobal.net",
	// 	"att.net",
	// 	"cox.net",
	// 	"me.com",
	// 	"mac.com",
	// 	"gmx.com",
	// 	"gmx.net",
	// 	"web.de",
	// 	"mail.com",
	// 	"rocketmail.com",
	// 	"inbox.com",
	// 	"fastmail.com",
	// 	"hushmail.com",
	// 	"tutanota.com",
	// 	"yandex.com",
	// 	"yahoo.co.uk",
	// 	"hotmail.co.uk",
	// 	"aol.co.uk",
	// 	"mail.ru",
	// 	"rediffmail.com",
	// 	"163.com",
	// 	"126.com",
	// 	"juno.com",
	// 	"lycos.com",
	// 	"excite.com",
	// 	"naver.com",

	// 	// Client domains
	// 	"myseneca.ca",
	// 	"mail.utoronto.ca",
	// 	"holbertonstudents.com",
	// 	"ycdsb.ca",
	// 	"ycdsbk12.ca",
	// 	"fanshaweonline.ca"
	// ];

	const handleChange = (e) => {
		const { name, value } = e.target;

		// If the user edits the email field, clear the previous error related to the email
		if (name === "email") {
			setFormError(false);
			setErrorText("");
		}

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// Email validation to require at least two characters in the top-level domain
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;

		// Check if the email is in a valid format
		if (!emailRegex.test(formData.email)) {
			setFormError(true);
			setErrorText("Please enter a valid email address.");
			emailInputRef.current?.focus();
			return; // Prevent form submission if the email format is invalid
		}

		// // Extract the domain from the email and check if it's a blocked domain
		// const emailParts = formData.email.split("@");
		// if (emailParts.length === 2) {
		// 	const domain = emailParts[1].toLowerCase();
		// 	if (blockedDomains.includes(domain)) {
		// 		setFormError(true);
		// 		setErrorText(`Email addresses from ${domain} are not allowed.`);
		// 		emailInputRef.current?.focus();
		// 		return; // Prevent form submission if the email domain is blocked
		// 	}
		// }

		// Clear the error state before submitting
		setFormError(false);
		setErrorText("");

		// Proceed with form submission if no error
		console.log("Form Data:", JSON.stringify(formData));
		setLoading(true);

		try {
			const response = await axiosLimitedPost(`${url}/api/preview/FreePreviewForm`, formData, 1);
			// console.log("Success:", response.data);

			posthog?.identify(localUser.id, {
				firstName: formData.firstName,
				lastName: formData.lastName,
				email: formData.email,
				isPreview: true
			});

			posthog?.capture("instage_preview_form_submit", {
				instage_id: localUser.id
			});

			setStep(2);
		} catch (error) {
			console.error("Error:", error);
			setFormError(true);
			setErrorText("Failed to submit, try again!");
			setTimeout(() => {
				setFormError(false);
			}, 3000);
		} finally {
			setLoading(false);
		}
	};

	return (
		<form
			onSubmit={handleSubmit}
			style={{
				display: "flex",
				flexDirection: "column",
				fontFamily: "Plus Jakarta Sans, sans-serif",
				fontSize: "14px",
				color: "#344054",
				fontWeight: 500,
				width: "100%",
				borderRadius: "8px",
				boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.0)"
			}}
		>
			<input type="hidden" name="_redirect" value="" />
			<div className={styles.modalInputContainerSplit}>
				<div className={styles.modalInputGroup}>
					<label htmlFor="firstName">
						First name
						<span style={{ color: "red" }}>*</span>
						<input
							type="text"
							id="firstName"
							name="firstName"
							placeholder="First name"
							value={formData.firstName}
							onChange={handleChange}
							required
							className={styles.modalInput}
						/>
					</label>
				</div>
				<div className={styles.modalInputGroup}>
					<label htmlFor="lastName">
						Last name
						<span style={{ color: "red" }}>*</span>
						<input
							type="text"
							id="lastName"
							name="lastName"
							placeholder="Last name"
							value={formData.lastName}
							onChange={handleChange}
							required
							className={styles.modalInput}
						/>
					</label>
				</div>
			</div>
			<div className={styles.modalInputContainerSplit}>
				<div className={styles.modalInputGroup}>
					<label htmlFor="email">
						Email
						<span style={{ color: "red" }}>*</span>
						<input
							type="text"
							id="email"
							name="email"
							value={formData.email}
							onChange={handleChange}
							placeholder="you@company.com"
							required
							ref={emailInputRef}
							className={styles.modalInput}
						/>
					</label>
				</div>
				<div className={styles.modalInputGroup}>
					<label htmlFor="phoneNumber">
						Phone number
						<span style={{ color: "red" }}>*</span>
						<input
							type="text"
							id="phoneNumber"
							name="phoneNumber"
							placeholder="+1 (555) 000-0000"
							value={formData.phoneNumber}
							onChange={handleChange}
							required
							className={styles.modalInput}
						/>
					</label>
				</div>
			</div>
			<div className={styles.modalInputContainerSplit}>
				<div className={styles.modalInputGroup}>
					<label htmlFor="institutionType">
						Institution Type
						<span style={{ color: "red" }}>*</span>
						<select
							id="institutionType"
							name="institutionType"
							placeholder="Select"
							value={formData.institutionType}
							onChange={handleChange}
							required
							className={styles.modalInput}
						>
							<option>Career Centre - Higher Ed</option>
							<option>Employment Centre</option>
							<option>Higher Education</option>
							<option>High School</option>
							<option>Government</option>
							<option>Other</option>
						</select>
					</label>
				</div>
				<div className={styles.modalInputGroup}>
					<label htmlFor="company">
						Name of the Institution
						<span style={{ color: "red" }}>*</span>
						<input
							type="text"
							id="company"
							name="company"
							placeholder="College/Company"
							value={formData.company}
							onChange={handleChange}
							required
							className={styles.modalInput}
						/>
					</label>
				</div>
			</div>
			<div className={styles.modalInputContainerSplit}>
				<div className={styles.modalInputGroup}>
					<label htmlFor="position">
						Position
						<span style={{ color: "red" }}>*</span>
						<input
							type="text"
							id="position"
							name="position"
							value={formData.position}
							onChange={handleChange}
							placeholder="Your position"
							required
							className={styles.modalInput}
						/>
					</label>
				</div>
			</div>
			<p className={styles.modalPreviewNote}>
				By proceeding, you consent to InStage&apos;s processing of your information provided above in accordance with its{" "}
				<a target="_blank" href="https://instage.io/privacy-policy" rel="noreferrer">
					Privacy Policy
				</a>
				, subject to its website{" "}
				<a target="_blank" href="https://instage.io/terms-service" rel="noreferrer">
					Terms of Service
				</a>
				. You can unsubscribe or revoke your consent at any time.
			</p>
			<div className={styles.modalBtnContainer} style={{ marginTop: "1rem" }}>
				{loading ? (
					<div className={styles.modalSpinnerContainer}>
						<LoadingSpinner height={54} width={54} thickness={5} color="#00a9af" />
					</div>
				) : (
					<button type="submit" value="Submit" className={styles.modalConfirmBtn}>
						Submit
					</button>
				)}
			</div>
			{formError && <p style={{ color: "red", margin: "1rem auto 0" }}>{errorText}</p>}
		</form>
	);
}
