import React, { useState, useEffect, useRef, useContext } from "react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { FaCheck, FaTimes } from "react-icons/fa";
import { FiChevronRight } from "react-icons/fi/index.esm.js";
import r from "../../styleModules/reportStyles.module.css";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";

export default function ReflectionSummary({ sessionData, summary, summaryLoading, recap, takeaways }) {
	const [expandedRows, setExpandedRows] = useState([]);
	const [hoveredRow, setHoveredRow] = useState(null);
	const { runTranslation, adminAccess, tenantId, userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Exceptional: "Exceptional",
		Good: "Good",
		Satisfactory: "Satisfactory",
		Overall: "Overall",
		Unsatisfactory: "Unsatisfactory",
		ReflectionSummary: "Reflection Summary",
		KeyTakeaways: "Key Takeaways",
		Recap: recap,
		Takeaways: takeaways,
		Summary: "Summary",
		Feedback: "Feedback"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [dynamicPageText, setDynamicPageText] = useState([takeaways]);

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	// Static Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage, sessionData, recap]);

	useEffect(() => {
		async function translateDict() {
			const translatedTakeaways = await translateDictText(takeaways, userLanguage);
			setDynamicPageText(translatedTakeaways);
		}
		if (!hasTranslatedText && takeaways) {
			translateDict();
			setHasTranslatedText(true);
		}
	}, [userLanguage, takeaways]);

	const [summaryPageText, setSummaryPageText] = useState({});
	// Dynamic Usage
	useEffect(() => {
		async function performTranslations() {
			if (!summary || Object.keys(summary).length === 0) {
				// console.error("summary is undefined or empty");
				return;
			}

			const propertiesToTranslate = ["feedback", "summary"];
			let updatedSummary = { ...summary };

			try {
				for (const property of propertiesToTranslate) {
					const texts = Object.keys(updatedSummary).map((key) => updatedSummary[key][property]);
					const translatedTexts = await translateDictText(texts, userLanguage);
					Object.keys(updatedSummary).forEach((key, index) => {
						updatedSummary[key][property] = translatedTexts[index] || updatedSummary[key][property]; // Use translated text if available, otherwise use original
					});
				}
				setSummaryPageText(updatedSummary);
			} catch (error) {
				console.error("Error translating properties:", error);
			}
		}

		performTranslations();
	}, [summary, userLanguage, translateDictText]);

	const statusOptions = ["No", "Partial", "Yes"];
	const statusDict = {
		No: {
			text: pageText.Unsatisfactory,
			darkColor: "#7d7d7d",
			lightColor: "#AFAFAF",
			score: 1
		},
		Novice: {
			text: pageText.Unsatisfactory,
			darkColor: "#7d7d7d",
			lightColor: "#AFAFAF"
		},
		Partial: {
			text: pageText.Satisfactory,
			darkColor: "#87BAC0",
			lightColor: "#4BA6AD",
			score: 2
		},
		Intermediate: {
			text: pageText.Satisfactory,
			darkColor: "#87BAC0",
			lightColor: "#4BA6AD"
		},
		Yes: {
			text: pageText.Exceptional,
			darkColor: "#4BA6AD",
			lightColor: "#87BAC0",
			score: 3
		},
		Competent: {
			text: pageText.Exceptional,
			darkColor: "#4BA6AD",
			lightColor: "#87BAC0"
		}
	};

	const toggleRow = (index) => {
		const newExpandedRows = [...expandedRows];
		newExpandedRows[index] = !newExpandedRows[index];
		setExpandedRows(newExpandedRows);
	};

	useEffect(() => {
		// Initialize expandedRows state with false values
		if (summary) {
			// console.log("summary", summary);
			setExpandedRows(Object.keys(summary).map(() => false));
		}
	}, [summary]);

	return (
		<div className={r.reportSectionContainer}>
			{summaryLoading && (
				<div className={r.spinnerContainer}>
					<LoadingSpinner height={50} width={50} thickness={5} color="#00a9af" />
				</div>
			)}
			{!summaryLoading && (
				<div className={r.reportTable}>
					{recap && (
						<>
							<h2 className={r.reportSectionHeading}>{pageText.ReflectionSummary}</h2>
							<p className={r.reportText}>{pageText.Recap}</p>
						</>
					)}
					{takeaways && takeaways.length > 0 && (
						<>
							<h2 className={r.reportSectionHeading}>{pageText.KeyTakeaways}</h2>
							<ol className={r.reportOrderedList}>
								{dynamicPageText.map((takeaway, index) => (
									<li key={index} className={r.reportListItem}>
										{takeaway}
									</li>
								))}
							</ol>
						</>
					)}
					{Object.keys(summary).map((key, index) => (
						<React.Fragment key={key}>
							<div className={`${r.reportTableRow} ${expandedRows[index] ? r.reportTableToggledRow : ""}`}>
								<div
									role="button"
									tabIndex={0}
									className={`${r.reportTableHeader} ${expandedRows[index] ? r.reportTableToggledHeader : ""}`}
									onMouseEnter={() => setHoveredRow(index)}
									onMouseLeave={() => setHoveredRow(null)}
									onClick={() => toggleRow(index)}
									onKeyDown={(event) => {
										if (event.key === "Enter" || event.key === " ") {
											toggleRow(index);
										}
									}}
								>
									<div
										className={r.questionColumn}
										style={{ width: expandedRows[index] ? "95%" : window.innerWidth > 1000 ? "65%" : "50%" }}
									>
										{key
											.split("_")
											.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
											.join(" ")}
									</div>
									<div
										className={r.scoreColumn}
										style={{ width: expandedRows[index] ? "0%" : window.innerWidth > 1000 ? "30%" : "45%" }}
									>
										{!expandedRows[index] && (
											<>
												<div
													className={r.scoreContainer}
													style={{
														backgroundColor: statusDict[summary[key].status]?.darkColor
													}}
												>
													<div
														className={r.scoreIconCircle}
														style={{ backgroundColor: statusDict[summary[key].status]?.lightColor }}
													>
														<FaCheck className={r.scoreIcon} />
													</div>
													{statusDict[summary[key].status]?.text}
												</div>
											</>
										)}
									</div>
									<div className={r.chevronColumn}>
										<FiChevronRight className={expandedRows[index] || hoveredRow === index ? r.chevronDown : r.chevronRight} />
									</div>
								</div>
								{expandedRows[index] && (
									<div className={r.reportTableDropdown}>
										<div className={r.reportTableDropdownTop}>
											<div className={r.reportTableTranscript}>
												<p className={r.reportTableDropdownTitle}>{pageText.Summary}:</p>
												<p className={r.reportTableTranscriptText}>{summaryPageText[key].summary}</p>
											</div>
											<div className={r.scoreBreakdown}>
												{[...statusOptions].reverse().map((status, index) => (
													<div
														key={index}
														className={r.scoreContainer}
														style={{ backgroundColor: statusDict[status]?.darkColor }}
													>
														<div
															className={r.scoreIconCircle}
															style={{
																backgroundColor:
																	summary[key].status === status ? statusDict[status]?.lightColor : "transparent"
															}}
														>
															{summary[key].status === status &&
																(summary[key].status === "No" || summary[key].status === "Novice" ? (
																	<FaTimes className={r.scoreIcon} />
																) : (
																	<FaCheck className={r.scoreIcon} />
																))}
														</div>
														{statusDict[status]?.text}
													</div>
												))}
											</div>
										</div>
										<div className={r.reportTableFeedback}>
											<div className={r.statusGradeFeedbackContainer}>
												<p className={r.reportTableDropdownTitle}>{pageText.Feedback}:</p>
												<p className={r.statusGradeFeedbackText}>{summaryPageText[key].feedback}</p>
											</div>
										</div>
									</div>
								)}
							</div>
						</React.Fragment>
					))}
				</div>
			)}
		</div>
	);
}
