import React from "react";
import { IoClose } from "react-icons/io5";
import { MdOutlineTour } from "react-icons/md";
import styles from "../../styleModules/modalStyles.module.css";
import circles from "../../images/circles.png";

export default function JoyrideModal({ step, tooltipProps, primaryProps, backProps, skipProps, closeProps, continuous, index }) {
	const nextButtonLabel = step.final ? "Finish" : `Next (${step.progress})`;

	return (
		<div className={styles.modal} {...tooltipProps}>
			<img src={circles} alt="Circles" className={styles.modalCircles} />
			<div className={styles.modalHeaderIconContainer}>
				<MdOutlineTour className={styles.modalHeaderIcon} />
			</div>
			<IoClose className={styles.modalCloseIcon} style={{ color: "#475467" }} title="Close icon" {...skipProps} />
			<div className={styles.modalTextContainer}>
				<h4 className={styles.modalTitle}>{step.title}</h4>
				{step.content.map((content, index) => (
					<p key={index} className={styles.modalText}>
						{content}
					</p>
				))}
			</div>
			<div className={styles.tourModalBtnContainer}>
				<button type="button" className={styles.tourModalSkipBtn} {...skipProps}>
					Skip
				</button>
				<div className={styles.tourModalNavContainer}>
					<>
						{index > 0 && (
							<button type="button" className={styles.tourModalBackBtn} {...backProps}>
								Back
							</button>
						)}
						{continuous && (
							<button type="button" className={styles.tourModalNextBtn} {...primaryProps}>
								{nextButtonLabel}
							</button>
						)}
					</>
				</div>
			</div>
		</div>
	);
}
