import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

function Modal({ children }) {
	const elRef = useRef(null);

	if (!elRef.current) {
		const div = document.createElement("div");
		elRef.current = div;
	}

	useEffect(() => {
		const modalRoot = document.getElementById("modal");

		modalRoot.appendChild(elRef.current);
		return () => {
			if (modalRoot.contains(elRef.current)) {
				modalRoot.removeChild(elRef.current);
			}
		};
	}, []);

	return createPortal(
		<div
			style={{
				position: "fixed",
				top: "0",
				left: "0%",
				display: "flex",
				alignItems: "center",
				height: "100%",
				width: "100%",
				overflow: "auto",
				background: "rgba(0, 0, 0, 0.8)",
				cursor: "pointer",
				zIndex: "1005"
			}}
		>
			{children}
		</div>,
		elRef.current
	);
}

export default Modal;
