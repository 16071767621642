import React, { useState, useRef, useEffect } from "react";
import styles from "../../styleModules/generateStyles.module.css";
import { useDeviceSettings } from "../UtilityFunctions/useDeviceSettings.js";
import Select from "react-select";
import { IoSettingsSharp } from "react-icons/io5";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import { FaMicrophone, FaPlay, FaStop } from "react-icons/fa";

const StatusIcon = ({ isWorking }) => (
	<div className={styles.statusIcon}>
		{isWorking ? <IoIosCheckmarkCircle className={styles.checkIcon} /> : <TiDelete className={styles.errorIcon} />}
	</div>
);

export default function DeviceSelector() {
	const { selectedDevices, saveDeviceSetting, devices } = useDeviceSettings();
	const [isExpanded, setIsExpanded] = useState(false);
	const [deviceStatus, setDeviceStatus] = useState({
		video: false,
		audio: false,
		speaker: false
	});
	const [isRecording, setIsRecording] = useState(false);
	const [audioBlob, setAudioBlob] = useState(null);
	const [isPlaying, setIsPlaying] = useState(false);

	const videoRef = useRef(null);
	const audioContextRef = useRef(null);
	const micStreamRef = useRef(null);
	const mediaRecorderRef = useRef(null);
	const audioChunksRef = useRef([]);

	const handleDeviceChange = (selectedOption, { name }) => {
		saveDeviceSetting(name, selectedOption);
		testDevice(name, selectedOption);
	};

	const testDevice = async (deviceType, device) => {
		if (!device) return;

		switch (deviceType) {
			case "video":
				try {
					const stream = await navigator.mediaDevices.getUserMedia({
						video: { deviceId: device.value }
					});
					if (videoRef.current) {
						videoRef.current.srcObject = stream;
						setDeviceStatus((prev) => ({ ...prev, video: true }));
					}
				} catch (error) {
					console.error("Video test failed:", error);
					setDeviceStatus((prev) => ({ ...prev, video: false }));
				}
				break;

			case "audio":
				try {
					const stream = await navigator.mediaDevices.getUserMedia({
						audio: { deviceId: device.value }
					});
					micStreamRef.current = stream;
					setDeviceStatus((prev) => ({ ...prev, audio: true }));
				} catch (error) {
					console.error("Audio test failed:", error);
					setDeviceStatus((prev) => ({ ...prev, audio: false }));
				}
				break;

			case "speaker":
				try {
					const audio = new Audio("https://www.w3schools.com/html/horse.mp3");
					if (typeof audio.sinkId !== "undefined") {
						await audio.setSinkId(device.value);
						setDeviceStatus((prev) => ({ ...prev, speaker: true }));
					}
				} catch (error) {
					console.error("Speaker test failed:", error);
					setDeviceStatus((prev) => ({ ...prev, speaker: false }));
				}
				break;

			default:
				break;
		}
	};

	const startRecording = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				audio: { deviceId: selectedDevices.audio?.value }
			});

			mediaRecorderRef.current = new MediaRecorder(stream);
			audioChunksRef.current = [];

			mediaRecorderRef.current.ondataavailable = (event) => {
				if (event.data.size > 0) {
					audioChunksRef.current.push(event.data);
				}
			};

			mediaRecorderRef.current.onstop = () => {
				const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
				setAudioBlob(audioBlob);
			};

			mediaRecorderRef.current.start();
			setIsRecording(true);
		} catch (error) {
			console.error("Error starting recording:", error);
		}
	};

	const stopRecording = () => {
		if (mediaRecorderRef.current && isRecording) {
			mediaRecorderRef.current.stop();
			setIsRecording(false);
		}
	};

	const playRecording = async () => {
		if (audioBlob && selectedDevices.speaker) {
			const audio = new Audio(URL.createObjectURL(audioBlob));

			try {
				if (typeof audio.sinkId !== "undefined") {
					await audio.setSinkId(selectedDevices.speaker.value);
				}

				setIsPlaying(true);
				audio.play();
				audio.onended = () => setIsPlaying(false);
			} catch (error) {
				console.error("Error playing recording:", error);
				setIsPlaying(false);
			}
		}
	};

	const testSpeaker = () => {
		if (selectedDevices.speaker) {
			const audio = new Audio("https://www.w3schools.com/html/horse.mp3");
			if (typeof audio.sinkId !== "undefined") {
				audio
					.setSinkId(selectedDevices.speaker.value)
					.then(() => {
						audio.play();
					})
					.catch((error) => {
						console.error("Error testing speaker:", error);
					});
			} else {
				audio.play();
			}
		}
	};

	useEffect(
		() => () => {
			if (micStreamRef.current) {
				micStreamRef.current.getTracks().forEach((track) => track.stop());
			}
			if (videoRef.current?.srcObject) {
				videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
			}
		},
		[]
	);

	return (
		<div className={styles.deviceSelectorWrapper}>
			<button type="button" className={styles.deviceSelectorToggle} onClick={() => setIsExpanded(!isExpanded)} aria-label="Device Settings">
				<IoSettingsSharp />
			</button>

			<div className={`${styles.deviceSelectorPanel} ${isExpanded ? styles.expanded : ""}`}>
				<div className={styles.deviceSelectorContent}>
					<div className={styles.inputSection2} style={{ paddingTop: "0", marginTop: "0" }}>
						<h3>Input Devices</h3>

						<div className={styles.deviceControl}>
							<div className={styles.deviceControlHeader}>
								<label>Camera</label>
								<StatusIcon isWorking={deviceStatus.video} />
							</div>
							<Select
								name="video"
								options={devices.video}
								value={selectedDevices.video}
								onChange={handleDeviceChange}
								placeholder="Select Camera"
							/>
							<video ref={videoRef} className={styles.videoPreview} autoPlay muted playsInline />
						</div>

						<div className={styles.deviceControl}>
							<div className={styles.deviceControlHeader}>
								<label>Microphone</label>
								<StatusIcon isWorking={deviceStatus.audio} />
							</div>
							<Select
								name="audio"
								options={devices.audio}
								value={selectedDevices.audio}
								onChange={handleDeviceChange}
								placeholder="Select Microphone"
							/>
							<div className={styles.recordingControls}>
								<button
									type="button"
									onClick={isRecording ? stopRecording : startRecording}
									className={`${styles.recordButton} ${isRecording ? styles.recording : ""}`}
									disabled={!selectedDevices.audio}
								>
									{isRecording ? <FaStop /> : <FaMicrophone />}
									{isRecording ? "Stop" : "Record"}
								</button>
								{audioBlob && (
									<button
										type="button"
										onClick={playRecording}
										className={`${styles.playButton} ${isPlaying ? styles.playing : ""}`}
										disabled={isRecording || !selectedDevices.speaker}
									>
										<FaPlay />
										Play
									</button>
								)}
							</div>
						</div>
					</div>

					<div className={styles.outputSection2}>
						<h3>Output Device</h3>
						<div className={styles.deviceControl}>
							<div className={styles.deviceControlHeader}>
								<label>Speaker</label>
								<StatusIcon isWorking={deviceStatus.speaker} />
							</div>
							<Select
								name="speaker"
								options={devices.speaker}
								value={selectedDevices.speaker}
								onChange={handleDeviceChange}
								placeholder="Select Speaker"
							/>
							<div className={styles.speakerControls}>
								<button type="button" onClick={testSpeaker} className={styles.testButton} disabled={!selectedDevices.speaker}>
									Test Speaker
								</button>
							</div>
						</div>
					</div>
					<button
						type="button"
						onClick={() => window.location.reload()} // Refresh the page
						className={styles.applyRefreshButton}
					>
						Apply & Refresh
					</button>
					<p className={styles.deviceControlHeader} style={{ fontSize: "0.8rem", marginTop: "10px" }}>
						Note: Changing devices mid-session may result in loss of audio and video. Please set before starting.
					</p>
				</div>
			</div>
		</div>
	);
}
