import React, { useState, useContext, useEffect } from "react";
import a from "../styleModules/signupStyles.module.css";
import { AppContext } from "./UtilityFunctions/AppContext";
import { useAxiosLimited } from "./UtilityFunctions/axiosRetry.js";
import logo from "../images/logo.png";

function EmailSubscriptionPage() {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const [user, setUser] = useState({});
	const [userEmail, setUserEmail] = useState("");
	const [theEmail, setTheEmail] = useState("");
	const [clickedButton, setClickedButton] = useState(false);
	const [isSubscribed, setIsSubscribed] = useState(false);
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;

	const { userLanguage, translateDictText, performTranslation, localUser } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		YouHaveBeenRemovedFromOurMonthlyEmailList: "You Have Been Removed From Our Monthly Email List",
		ClickedButton: "Clicked Button",
		UnsubscribeFromOurNewsletter: "Unsubscribe From Our Newsletter",
		SubscribeToOurNewsletter: "Subscribe To Our Newsletter",
		IfYouNoLongerWantToReceiveClickUnsubscribe:
			"If you no longer want to receive monthly emails from us, please enter your email address below and click unsubscribe.",
		Unsubscribe: "Unsubscribe",
		Subscribe: "Subscribe",
		EnterYourEmail: "Enter Your Email"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	useEffect(() => {
		axiosLimitedGet(
			`${url}/api/users/getUser`,
			1,
			{
				params: {
					instage_id: localUser.id
				}
			},
			30000
		)
			.then((response) => {
				console.log("User Data ", response.data);
				setUser(response.data);
				const { preferences, email } = response.data;
				const { emailSubPref } = preferences;
				setTheEmail(email);
				setIsSubscribed(emailSubPref);
			})
			.catch((error) => {
				console.log(error);
			});
	}, [clickedButton]);

	const setUserMonthlyEmailPref = async () => {
		const body = {
			instage_id: localUser.id
		};
		await axiosLimitedPost(`${url}/api/users/editSubPref`, body, 1, {}, 30000);
	};

	const handleUnsubscribe = async (event) => {
		event.preventDefault();
		if (userEmail === theEmail) {
			setClickedButton(!clickedButton); // Disable the button
			await setUserMonthlyEmailPref();
			setIsSubscribed(!isSubscribed); // Toggle the isSubscribed state
			console.log(`${pageText.YouHaveBeenRemovedFromOurMonthlyEmailList}: ${userEmail}`);
			console.log(pageText.ClickedButton, clickedButton);
		}
	};

	return (
		<div
			style={{
				position: "fixed",
				top: "50%", // Center vertically
				left: "50%", // Center horizontally
				transform: "translate(-50%, -50%)", // Adjust for the div's own size
				zIndex: 1000,
				display: "flex",
				width: "100%",
				maxWidth: "500px",
				height: "auto", // Adjust height as needed
				justifyContent: "center",
				flexDirection: "column"
			}}
		>
			<div className={a.adminBoxHeader}>
				<img className={a.adminBoxLogo} style={{ width: "85%", height: "auto", alignSelf: "flex-start" }} src={logo} alt="logo" />
			</div>
			<h1 className={a.adminBoxTitle} style={{ color: "black", paddingLeft: "35px" }}>
				{isSubscribed ? pageText.UnsubscribeFromOurNewsletter : pageText.SubscribeToOurNewsletter}
			</h1>
			<p style={{ color: "black", width: "96%", paddingLeft: "35px", marginBottom: "30px" }}>
				{pageText.IfYouNoLongerWantToReceiveClickUnsubscribe}
			</p>
			<form onSubmit={handleUnsubscribe} style={{ boxSizing: "border-box", width: "96%", paddingLeft: "35px" }}>
				<div>
					<p style={{ marginBottom: "-1px", color: "black" }}>Email:</p>
					<input
						type="email"
						placeholder={pageText.EnterYourEmail}
						value={userEmail}
						onChange={(e) => setUserEmail(e.target.value)}
						required
						className={a.adminBoxInput}
						style={{
							marginBottom: "30px",
							width: "96%"
						}}
					/>
				</div>
				{userEmail !== theEmail && userEmail !== "" && <p style={{ color: "red" }}>Email does not match</p>}
				<button
					type="submit"
					className={a.adminBoxConfirm}
					style={{
						width: "96%",
						padding: "10px",
						margin: "10px 0",
						boxSizing: "border-box",
						paddingLeft: "35px",
						backgroundColor: isSubscribed ? "#ee4188" : "#00a9af"
					}} // Add padding and margin if needed
				>
					{isSubscribed ? pageText.Unsubscribe : pageText.Subscribe}
				</button>
			</form>

			{/* <h1 className={a.adminBoxTitle} style={{ color: "black", textAlign: "center" }}>
				You Have Been Removed From Our Monthly Email List
			</h1> */}
		</div>
	);
}

export default EmailSubscriptionPage;
