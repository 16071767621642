import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { usePostHog } from "posthog-js/react";
import { openDB } from "idb";
import { AppContext } from "./UtilityFunctions/AppContext.js";
import styles from "../styleModules/setupStyles.module.css";
import { useAxiosLimited } from "./UtilityFunctions/axiosRetry.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export default function TestPage() {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();

	// GLOBAL VARIABLES
	const { user, localUser, setupData, setSetupData, setActiveSessionData } = useContext(AppContext);

	const testAPI = async () => {
		try {
			const resp = await axiosLimitedGet(`${url}/api/test/GET`, 1, {}, 10000);
			console.log("Test API response:", resp.data);
		} catch (error) {
			if (error.response && error.response.status === 429) {
				console.error("Test API error:", error.response.data.message);
			} else {
				console.error("Test API error:", error);
			}
		}
	};

	return (
		<>
			<Helmet>
				<title>TEST PAGE</title>
				<meta name="description" content="InStage Test Page" />
				<meta name="robots" content="noindex" />
			</Helmet>

			<div className={styles.previewPage}>
				<div className={styles.previewPageContainer}>
					<h1>Test Page</h1>
					<button type="button" onClick={testAPI}>
						Run Test
					</button>
				</div>
			</div>
		</>
	);
}
