import React, { useState, useContext } from "react";
import { IoClose } from "react-icons/io5";
import { FaRegCalendarPlus, FaUserFriends } from "react-icons/fa";
import { FiRepeat } from "react-icons/fi";
import { MdOutlineMessage } from "react-icons/md";
import styles from "../../styleModules/modalStyles.module.css";
import ModalWrapper from "./ModalWrapper.js";
import circles from "../../images/circles.png";
import simScreenExample from "../../images/simScreenExample.png";
import { LoadingSpinner } from "./LoadingSpinner";
import ContactForm from "./ContactForm";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { usePostHog } from "posthog-js/react";
import { AppContext } from "../UtilityFunctions/AppContext.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export default function PostPreviewModal({ onClose }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { localUser } = useContext(AppContext);
	const posthog = usePostHog();
	const [step, setStep] = useState(0);

	// const handleChangeEmailOptIn = (e) => {
	// 	setFormData({
	// 		...formData,
	// 		emailOptIn: e.target.value
	// 	});
	// };

	const handleLearnMore = () => {
		try {
			posthog?.capture("instage_preview_report_popup_learn_more", {
				instage_id: localUser.id
			});
		} catch (error) {
			console.error("Posthog error:", error);
		}
		setStep(1);
	};

	return (
		<ModalWrapper closeModal={() => onClose()}>
			<div className={step === 0 ? styles.modalFullScreen : styles.modal}>
				{step > 0 && (
					<>
						<img src={circles} alt="Circles" className={styles.modalCircles} />
						<div className={styles.modalHeaderIconContainer}>
							<MdOutlineMessage className={styles.modalHeaderIcon} />
						</div>
					</>
				)}
				<IoClose className={styles.modalCloseIcon} title="Close icon" onClick={() => onClose()} />
				{step === 0 && (
					<>
						<div className={styles.modalPreviewContent}>
							<div className={styles.modalPreviewHeader}>
								<h3 className={styles.modalPreviewTitle}>
									Personalize Learning at scale with AI-powered <br className={styles.modalPreviewTitleBreak} />
									<span className={styles.modalHighlight}>Conversational Simulations.</span>
								</h3>
								<p className={styles.modalPreviewSubtitle}>
									<span style={{ fontFamily: "BoldFont" }}>Save staff time</span> & introduce next generation A.I. by deploying
									conversational simulations as Course Assignments.
								</p>
							</div>
							<div className={styles.modalSplitContainer}>
								<div className={styles.modalSplitLeft}>
									<img src={simScreenExample} alt="Simulated screen example" className={styles.modalPreviewImage} />
								</div>
								<div className={styles.modalSplitRight}>
									<div>
										<p className={styles.modalPreviewListTitle}>
											Do you need a scalable way to
											<br />
											<span className={styles.modalHighlight}>provide personalized learning?</span>
										</p>
										<div className={styles.modalPreviewBulletList}>
											<div className={styles.modalPreviewBullet}>
												<FiRepeat className={styles.modalPreviewBulletIcon} />
												<p className={styles.modalPreviewBulletText}>Your students need to practice conversational skills</p>
											</div>
											<div className={styles.modalPreviewBullet}>
												<FaRegCalendarPlus className={styles.modalPreviewBulletIcon} />
												<p className={styles.modalPreviewBulletText}>
													Your team struggles to schedule, conduct and review every session.
												</p>
											</div>
											<div className={styles.modalPreviewBullet}>
												<FaUserFriends className={styles.modalPreviewBulletIcon} />
												<p className={styles.modalPreviewBulletText}>
													Tag team with digital humans to save instructor time and give every student a personalized
													experience.
												</p>
											</div>
										</div>
									</div>
									<div>
										<p className={styles.modalPreviewListTitle}>Online simulations include:</p>
										<div className={styles.modalPreviewSimList}>
											<ul className={styles.modalPreviewSimListLeft}>
												<li>Interviews</li>
												<li>Presentations</li>
												<li>Business Pitches</li>
											</ul>
											<ul className={styles.modalPreviewSimListRight}>
												<li>Elevator Pitches</li>
												<li>B2B Sales</li>
												<li>and more...</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
							<div className={styles.modalPreviewBtnContainer}>
								<button type="button" className={styles.modalCancelBtn} onClick={() => onClose()}>
									Cancel
								</button>
								<button type="button" className={styles.modalConfirmBtn} onClick={() => handleLearnMore()}>
									Learn More
								</button>
							</div>
						</div>
					</>
				)}
				{step === 1 && (
					<>
						<div className={styles.modalTextContainer}>
							<h3 className={styles.modalTitle}>Talk to our team</h3>
						</div>
						<ContactForm setStep={setStep} />
					</>
				)}
				{step === 2 && (
					<>
						<div className={styles.modalTextContainer}>
							<h3 className={styles.modalTitle}>Thank you for your interest!</h3>
							<p className={styles.modalText}>Someone from our team will be in touch with you shortly.</p>
						</div>
						<div className={styles.modalBtnContainer}>
							<button type="button" className={styles.modalConfirmBtn} onClick={() => onClose()}>
								Close
							</button>
						</div>
					</>
				)}
			</div>
		</ModalWrapper>
	);
}
