export function isMobile() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	const isiOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
	const isAndroid = /android/i.test(userAgent);

	return isiOS || isAndroid;
}

export function isSupportedBrowser() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	const isBrave = navigator.brave !== undefined;
	const isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(" OPR/") >= 0;

	const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor) && !/Edg\//.test(userAgent) && !isBrave && !isOpera;
	const isFirefox = /Firefox/.test(userAgent);
	const isSafari = /Safari/.test(userAgent) && /Apple Computer/.test(navigator.vendor);
	const isEdge = /Edg/.test(userAgent);
	// console.log("isChrome", isChrome, "isFirefox", isFirefox, "isSafari", isSafari, "isEdge", isEdge, "isBrave", isBrave, "isOpera", isOpera);
	return isChrome || isSafari || isEdge;
}
