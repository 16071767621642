export const templates = [
	{
		id: 1,
		name: "Chronological Resume",
		url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/BasicChronologicalResumePlaceholders18.docx",
		imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/BasicChronologicalResumeImage2.JPG"
	}
	// {
	// 	id: 2,
	// 	name: "Professional Highlights Resume",
	// 	url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ProfessionalHighlightsResumePlaceholders3.docx",
	// 	imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ProfessionalHighlightsImage.JPG"
	// },
	// {
	// 	id: 3,
	// 	name: "Two Column Resume",
	// 	url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/TwoColumnResumeTemplate12.docx",
	// 	imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/TwoColumnResumeImage.JPG"
	// },
	// {
	// 	id: 4,
	// 	name: "SideLine Resume",
	// 	url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/SideLineResumePlaceholders6.docx",
	// 	imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/SideLineTemplateIMG.JPG"
	// },
	// {
	// 	id: 5,
	// 	name: "Classic Clear Resume",
	// 	url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ClassicClearResumePlaceholders3.docx",
	// 	imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ClassicClearResumeImage.JPG"
	// }

	// Add more templates here in the future
];

export const coverLetterTemplates = [
	{
		id: 1,
		name: "Basic Cover Letter",
		url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/Basic+Cover+Letter3.docx",
		imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/BasicCoverLetterIMG.JPG"
	}
	// },
	// {
	// 	id: 2,
	// 	name: "Style 1 Cover Letter",
	// 	url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/Style1_Cover_Letter2.docx",
	// 	imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/Style1CoverLetterIMG.JPG"
	// },
	// {
	// 	id: 3,
	// 	name: "Simple Cover Letter",
	// 	url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/Simple+Cover+Letter8.docx",
	// 	imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/SimpleCoverLetter2IMG.JPG"
	// },
	// {
	// 	id: 4,
	// 	name: "Shape Cover Letter",
	// 	url: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ShapeCoverLetter3.docx",
	// 	imageUrl: "https://resumedocumentsbucket.s3.ca-central-1.amazonaws.com/ShapeCoverLetterIMG.JPG"
	// }
];
