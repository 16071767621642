import { useState, useCallback } from "react";
import axios from "axios";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { TrySharp } from "@mui/icons-material";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export const useAuraSpeechSynthesizer = () => {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const [audio, setAudio] = useState(new Audio());
	const [isPlaying, setIsPlaying] = useState(false);

	const fetchAndPlayAudio = useCallback(
		async (text, voice, transcribe) => {
			try {
				const audioCreationUrl = `${url}/aura_speak`;
				console.log("fetchAndPlayAudio", audioCreationUrl, transcribe);
				const response = await axios.get(audioCreationUrl, {
					params: {
						text, // Ensure this is the actual text you want to synthesize
						model: voice,
						transcribe
						// encoding: "encoding format",
						// container: "container format"
					},
					responseType: "json" // Set responseType to 'blob' for binary data
				});
				// const response = await axiosLimitedGet(audioCreationUrl, 1, {
				// 	params: {
				// 		text: "text to synthesize"
				// 		// model: "model identifier",
				// 		// encoding: "encoding format",
				// 		// container: "container format"
				// 	}
				// });
				console.log("response", response);
				try {
					const audioBase64 = response.data.audio;
					console.log("additionalData ", response.data.additionalData);
					const audioBlob = new Blob([Uint8Array.from(atob(audioBase64), (c) => c.charCodeAt(0))], { type: "audio/wav" });

					//	const audioBlob = new Blob([response.data], { type: "audio/wav" });
					const audioUrl = URL.createObjectURL(audioBlob);
					audio.src = audioUrl;
					audio.play();
					setIsPlaying(true);
				} catch (error) {
					console.error("Error playing audio:", error);
				}
			} catch (error) {
				console.error("Error fetching audio:", error);
			}
		},
		[audio]
	);

	const speak = useCallback(
		(text, voice, transcribe) => {
			if (isPlaying) {
				audio.pause();
				audio.currentTime = 0;
			}
			fetchAndPlayAudio(text, voice, transcribe);
		},
		[audio, isPlaying, fetchAndPlayAudio]
	);

	const pause = useCallback(() => {
		if (isPlaying) {
			audio.pause();
			setIsPlaying(false);
		}
	}, [audio, isPlaying]);

	const resume = useCallback(() => {
		if (!isPlaying) {
			audio.play();
			setIsPlaying(true);
		}
	}, [audio, isPlaying]);

	return { speak, pause, resume };
};
