import React, { useEffect, useCallback, useState, useRef } from "react";
import { saveAs } from "file-saver";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import { renderAsync } from "docx-preview";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import { FiDownload } from "react-icons/fi";
import { FiAlertTriangle } from "react-icons/fi/index.esm.js";
import styles from "../../styleModules/resumeCreatorPreview.module.css";
import TemplateSelectorMini from "./TemplateSelectorMini";

// eslint-disable-next-line
const ResumeCreatorPreview = ({ type, resumeData, selectedTemplate, setTemplateIndex }) => {
	const [templateContent, setTemplateContent] = useState(null);
	const [error, setError] = useState(null);
	const [isFullscreen, setIsFullscreen] = useState(false);
	const previewContainerRef = useRef(null);

	const handleTemplateSelect = useCallback(async (template) => {
		if (!template || !template.url) return;
		try {
			const response = await fetch(template.url);
			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`);
			}
			const arrayBuffer = await response.arrayBuffer();
			setTemplateContent(arrayBuffer);
		} catch (err) {
			console.error("Error fetching template:", err.message);
			setError(`Failed to fetch template: ${err.message}`);
		}
	}, []);

	const data =
		type === "resume"
			? {
					firstName: resumeData.contact.firstName,
					lastName: resumeData.contact.lastName,
					email: resumeData.contact.email,
					phone: resumeData.contact.phone,
					address: resumeData.contact.address,
					linkedinURL: resumeData.contact.linkedinURL,
					summary: typeof resumeData.summary === "object" ? resumeData.summary.summary : resumeData.summary,
					technical1: resumeData.skills.technical.join(", "),
					Competencies1: resumeData.skills.coreCompetencies.join(", "),
					educations: resumeData.education.map((edu) => ({
						school: edu.school,
						degree: edu.degree,
						program: edu.program,
						graduatingYear: edu.graduatingYear
					})),
					experiences: resumeData.experience.map((exp) => ({
						JobTitle: exp.jobTitle,
						companyName: exp.companyName,
						city: exp.city,
						stateOrProv: exp.stateOrProv,
						startMonth: exp.startMonth,
						startYear: exp.startYear,
						endMonth: exp.endMonth,
						endYear: exp.endYear,
						roles: exp.roles
					})),
					extraCurriculars: [
						...resumeData.extraCurricular.map((activity) => ({
							activity: activity.activity,
							OrgName: activity.orgName,
							startMonth: activity.startMonth,
							startYear: activity.startYear,
							endMonth: activity.endMonth,
							endYear: activity.endYear,
							tasks: activity.tasks,
							type: "Extra-Curricular" // Add this field to differentiate
						})),
						...resumeData.volunteer.map((activity) => ({
							activity: activity.activity,
							OrgName: activity.orgName,
							startMonth: activity.startMonth,
							startYear: activity.startYear,
							endMonth: activity.endMonth,
							endYear: activity.endYear,
							tasks: activity.tasks,
							type: "Volunteer" // Add this field to differentiate
						}))
					]
			  }
			: {
					firstName: resumeData.contact.firstName,
					lastName: resumeData.contact.lastName,
					email: resumeData.contact.email,
					address: resumeData.contact.address,
					phone: resumeData.contact.phone,
					hiring_manager_name: resumeData.contact.hiring_manager_name,
					company: resumeData.contact.company,
					company_address: resumeData.contact.company_address,

					intro: resumeData.intro.intro,
					body: resumeData.body.body
			  };
	const generatePreview = useCallback(async () => {
		if (!templateContent) return;

		try {
			const zip = new PizZip(templateContent);
			const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });

			doc.setData(data);
			doc.render();

			const blob = doc.getZip().generate({
				type: "blob",
				mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			});

			const arrayBuffer = await blob.arrayBuffer();

			const previewContainer = document.getElementById("docxPreview");
			if (previewContainer) {
				previewContainer.innerHTML = "";
				await renderAsync(arrayBuffer, previewContainer, previewContainer, {
					className: "docx-preview",
					inWrapper: false,
					ignoreWidth: true,
					ignoreHeight: true,
					renderHeaders: false,
					renderFooters: false,
					useBase64URL: true,
					scale: 0.7,
					renderBackground: true // Ensure background images are rendered
				});
				setTimeout(() => {
					const images = previewContainer.querySelectorAll(".docx-preview img");
					images.forEach((img) => {
						img.style.position = "relative";
						img.style.zIndex = "-1";
						if (type === "cover_letter" && selectedTemplate && selectedTemplate.id === 3) {
							img.style.width = "100vw";
						}
					});
				}, 100);
			}
		} catch (err) {
			console.error("Error generating preview:", err);
			setError(`Error generating preview: ${err.message}`);
		}
	}, [templateContent, resumeData]);

	useEffect(() => {
		if (selectedTemplate) {
			handleTemplateSelect(selectedTemplate);
		}
	}, [selectedTemplate, handleTemplateSelect]);

	useEffect(() => {
		if (templateContent) {
			generatePreview();
		}
	}, [templateContent, generatePreview]);

	const downloadDocx = async () => {
		if (!templateContent) return;

		try {
			const zip = new PizZip(templateContent);
			const doc = new Docxtemplater(zip, { paragraphLoop: true, linebreaks: true });

			doc.setData(data);
			doc.render();

			const blob = doc.getZip().generate({
				type: "blob",
				mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			});

			const firstName = resumeData.contact?.firstName || "";
			const lastName = resumeData.contact?.lastName || "";
			const documentType = type || "";

			const fileName = firstName && lastName && documentType ? `${firstName}_${lastName}_${documentType}.docx` : "document.docx";

			saveAs(blob, fileName);
		} catch (err) {
			console.error("Error generating DOCX:", err);
		}
	};

	const toggleFullscreen = () => {
		setIsFullscreen(!isFullscreen);
	};

	return (
		<div className={`${styles.mainContainer} ${isFullscreen ? styles.fullscreen : ""}`}>
			<div className={`${styles.previewContainer} ${isFullscreen ? styles.fullscreenPreview : ""}`}>
				<div className={styles.previewHeader}>
					<div className={styles.previewTitle}>
						<h4 className={styles.subtitle}>Preview</h4>
						<div className={styles.calloutContainer}>
							<FiAlertTriangle className={styles.calloutIcon} />
							<h4 className={styles.headingMessage}>Preview may not look 100% accurate until exported.</h4>
						</div>
					</div>
					<div className={styles.previewActions}>
						<button label="Download DOCX" type="button" className={styles.button} onClick={downloadDocx}>
							<FiDownload />
						</button>
						<button type="button" className={styles.iconButton} onClick={toggleFullscreen}>
							{isFullscreen ? <MdFullscreenExit /> : <MdFullscreen />}
						</button>
					</div>
				</div>
				<div className={styles.sideBySideContainer}>
					<TemplateSelectorMini setTemplateIndex={setTemplateIndex} type={type} />
					<div className={styles.resumePreview}>
						<div ref={previewContainerRef} id="docxPreview" className={styles.docxPreview} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResumeCreatorPreview;
