// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TemplateSelectorMini-module-templateSelectorMini {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 10px;
	overflow-x: auto;
	padding-right: 20px;
}

.TemplateSelectorMini-module-templateItem {
	background: none;
	border: none;
	cursor: pointer;
	transition: transform 0.2s ease-in-out;
}

.TemplateSelectorMini-module-templateItem:hover {
	transform: scale(1.05);
}

.TemplateSelectorMini-module-templateImage {
	width: 125px;
	height: auto;
	object-fit: cover;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/styleModules/TemplateSelectorMini.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,2BAA2B;CAC3B,uBAAuB;CACvB,SAAS;CACT,gBAAgB;CAChB,mBAAmB;AACpB;;AAEA;CACC,gBAAgB;CAChB,YAAY;CACZ,eAAe;CACf,sCAAsC;AACvC;;AAEA;CACC,sBAAsB;AACvB;;AAEA;CACC,YAAY;CACZ,YAAY;CACZ,iBAAiB;CACjB,kBAAkB;CAClB,wCAAwC;AACzC","sourcesContent":[".templateSelectorMini {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: flex-start;\n\talign-items: flex-start;\n\tgap: 10px;\n\toverflow-x: auto;\n\tpadding-right: 20px;\n}\n\n.templateItem {\n\tbackground: none;\n\tborder: none;\n\tcursor: pointer;\n\ttransition: transform 0.2s ease-in-out;\n}\n\n.templateItem:hover {\n\ttransform: scale(1.05);\n}\n\n.templateImage {\n\twidth: 125px;\n\theight: auto;\n\tobject-fit: cover;\n\tborder-radius: 4px;\n\tbox-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"templateSelectorMini": `TemplateSelectorMini-module-templateSelectorMini`,
	"templateItem": `TemplateSelectorMini-module-templateItem`,
	"templateImage": `TemplateSelectorMini-module-templateImage`
};
export default ___CSS_LOADER_EXPORT___;
