import { useCallback } from "react";
import { openDB } from "idb";

const usePageVisitTracker = () => {
	const checkPageVisit = useCallback(async (page) => {
		const db = await openDB("visitDB", 3, {
			// increment this number to make it show for users again.
			upgrade(db) {
				if (!db.objectStoreNames.contains("pageVisits")) {
					db.createObjectStore("pageVisits", { keyPath: "page" });
				}
			}
		});

		try {
			const tx = db.transaction("pageVisits", "readwrite");
			const store = tx.objectStore("pageVisits");
			const visitStatus = await store.get(page);

			// Inside your usePageVisitTracker hook
			if (visitStatus === undefined) {
				await store.put({ page, visited: true }); // If any issues, could be this
				// console.log("Recorded first visit to the page in the database.");
				return false; // First visit
			}
			// console.log("Visit to the page already recorded in the database.");
			return true; // Repeat visit
		} catch (error) {
			console.error("Error accessing IndexedDB:", error);
		}
	}, []);

	return { checkPageVisit };
};

export default usePageVisitTracker;
