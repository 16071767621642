import PropTypes from "prop-types";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useLocation, useSearchParams, parsePath } from "react-router-dom";
import { usePostHog, useFeatureFlagEnabled } from "posthog-js/react";
import { z } from "zod";

import { FiLogOut, FiInfo, FiEdit, FiEdit2, FiPlay } from "react-icons/fi/index.esm.js";
import { RxQuestionMarkCircled } from "react-icons/rx";

import { IoClose } from "react-icons/io5/index.esm.js";
import { GoDotFill } from "react-icons/go";
import { MdOutlineCheck, MdOutlineAssignment, MdStart, MdWarning } from "react-icons/md/index.esm.js";
import DOMPurify from "dompurify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { openDB } from "idb";
import { VscFeedback } from "react-icons/vsc";
import { IoMdRefresh } from "react-icons/io";
import { useAxiosLimited } from "../../UtilityFunctions/axiosRetry.js";
import { AppContext, VideoContext } from "../../UtilityFunctions/AppContext.js";
import { isMobile, isSupportedBrowser } from "../../UtilityFunctions/isMobile.js";
import circles from "../../../images/circles.png";
import Trustbox from "../../SecondaryComponents/Trustbox.js";
import ModalWrapper from "../../SecondaryComponents/ModalWrapper.js";
import { SelectWithAddOption, ListWithAddOption, ListWithInputOptions } from "../../SecondaryComponents/SelectWithAddOption.js";
import s2 from "../../../styleModules/modalStyles.module.css";
import s from "../../../styleModules/setupStyles.module.css";
import s3 from "../../../styleModules/generateStyles.module.css";
import { connectionStatusOptions } from "../../Simulation/UseSocket.js";
import { obfuscate } from "../../UtilityFunctions/DataUtility.js";
import PopupVideoPlayer from "./PopupVideoPlayer.js";
import { AssignmentInd } from "@mui/icons-material";
import posthog from "posthog-js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export function AudioErrorModal({ setShowAudioErrorModal, audioErrorTimer }) {
	return (
		<ModalWrapper closeModal={() => setShowAudioErrorModal(false)}>
			<div className={s2.modal} style={{ textAlign: "center" }}>
				<span className={s2.modalCloseIcon}>
					<IoClose title="Close icon" onClick={() => setShowAudioErrorModal(false)} />
				</span>
				<h1 className={s2.modalTitle}>Warning</h1>
				<p className={s2.modalText}>Our system is having some trouble processing your audio.</p>
				<p className={s2.modalText}>This can be caused by low speaking volume, internet latency, or background sound</p>
				<p className={s2.modalText} style={{ margin: "50px 0 0" }}>
					Automatically closing in
					<span style={{ fontFamily: "BoldFont", color: "#ee4188" }}>{audioErrorTimer}</span>
					...
				</p>
				<div className={s.endSimBtnContainer}>
					<button type="button" className={s2.modalCancelBtn} style={{ width: "175px" }} onClick={() => setShowAudioErrorModal(false)}>
						Okay
					</button>
				</div>
			</div>
		</ModalWrapper>
	);
}

AudioErrorModal.propTypes = {
	setShowAudioErrorModal: PropTypes.func.isRequired,
	audioErrorTimer: PropTypes.number.isRequired
};

function refreshPage() {
	window.location.reload();
}

export function SimEndedModal({ localUser, tenantName, setShowSimEndedModal, generateReport, currentSession }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const { simId } = useParams();

	const { userLanguage, translateDictText, performTranslation, handleNavigate } = useContext(AppContext);

	const [TextRenderState, setTextRenderState] = useState(false);
	// Static Translation
	const LocalLanguageDict = {
		GenerateReport: "Generate Report",
		TryAgain: "Try Again",
		ThisWillTakeAFewMinutes: "This will take a few minutes",
		Submit: "Submit",
		SimulationFinished: "Simulation Finished",
		HowLikelyToRecommend: "How likely are you to recommend InStage to a friend?",
		HowCanInStageDoBetter: "Thanks! How can InStage do better in the future?",
		ThanksForReview: "Thanks! Would you mind leaving us a review on Trustpilot?"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	const [surveyText, setSurveyText] = useState(pageText.HowLikelyToRecommend);
	const [feedbackText, setFeedbackText] = useState("");
	const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
	const [rating, setRating] = useState();
	const npsOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	const rateExperience = async (event, value) => {
		event.preventDefault();
		if (value < 9) {
			setSurveyText(pageText.HowCanInStageDoBetter);
		} else if (value >= 9) {
			setSurveyText(pageText.ThanksForReview);
		}
		setRating(value);
		await sendToAirtable(value);
	};

	const submitFeedback = async (event) => {
		event.preventDefault();
		setSurveyText("Thank you for your feedback!");
		setFeedbackSubmitted(true);
		await sendToAirtable(rating, feedbackText);
	};

	// Create Airtable record in UserFeedback table
	const sendToAirtable = async (rating, feedbackText) => {
		try {
			// console.log("Sending to Airtable");
			const data = {
				records: [
					{
						fields: {
							Environment:
								window.location.href.indexOf("localhost") !== -1
									? "Development"
									: window.location.href.indexOf("staging") !== -1
									? "Staging"
									: "Production",
							InStageID: localUser.id,
							Client: tenantName,
							SimID: simId,
							Rating: rating,
							FeedbackText: feedbackText
						}
					}
				]
			};

			const response = await axiosLimitedPost(
				`${url}/AirtableSimSurvey?host=https://api.airtable.com&path=/v0/appLohFHY5J1k7yUp/UserFeedback`,
				data,
				1
			);
			// console.log(response);
		} catch (e) {
			console.error(e);
			if (e.message) {
				console.error(e.message);
			}
		}
	};

	return (
		<ModalWrapper closeModal={() => setShowSimEndedModal(false)}>
			<div className={`${s2.modalAnimated} ${s2.modalMinWidth}`}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={s2.modalHeaderIconContainer}>
					<MdOutlineCheck className={s2.modalHeaderIcon} />
				</div>
				<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowSimEndedModal(false)} />
				{!generateReport && (
					<>
						<div className={s2.modalTextContainer}>
							<h1 className={s2.modalTitle}>{pageText.SimulationFinished}</h1>
						</div>

						<div className={s2.modalBtnContainer}>
							{!window.location.pathname.includes("preview") && (
								<button
									type="button"
									className={s2.modalCancelBtn}
									onClick={() => {
										let newPathname = location.pathname.replace(`/${simId}`, "");
										newPathname = window.location.pathname.includes("simulation")
											? newPathname.replace("simulation", "setup")
											: newPathname;

										handleNavigate(`${window.location.origin}${newPathname}`, true);
									}}
								>
									{pageText.TryAgain}
								</button>
							)}
							<div style={{ flex: "1 0 0" }}>
								<button
									data-attr="modals_generate_report"
									type="button"
									className={s2.modalConfirmBtn}
									style={{ width: "100%" }}
									onClick={() => {
										const paramsString = searchParams.toString();
										if (window.location.pathname.includes("preview")) {
											const obfuscated_gpt_session_id = obfuscate(currentSession.session_id.toString());
											handleNavigate(`/preview-report/${obfuscated_gpt_session_id}`, true);
										} else {
											const obfuscated_gpt_session_id = obfuscate(currentSession.session_id.toString());
											handleNavigate(`/report/${obfuscated_gpt_session_id}`, true);
										}
									}}
								>
									{pageText.GenerateReport}
								</button>
								<p className={s2.modalBtnNote} style={{ textAlign: "center" }}>
									{pageText.ThisWillTakeAFewMinutes}
								</p>
							</div>
						</div>

						{!window.location.pathname.includes("preview") && (
							<div className={s2.surveyContainer}>
								<p
									className={s2.surveyTitle}
									style={{
										margin: "0",
										textAlign: rating < 9 && !feedbackSubmitted ? "left" : "inherit"
									}}
								>
									{surveyText}
								</p>

								{rating < 9 && feedbackSubmitted === false && (
									<>
										<form className={s2.feedbackForm}>
											<textarea
												className={s2.feedbackBox}
												id="feedbackText"
												name="feedbackText"
												rows="3"
												placeholder="We read every answer!"
												onChange={(e) => setFeedbackText(e.target.value)}
											/>
											<div className={s2.formBtnContainer}>
												<button
													className={feedbackText.length > 0 ? s2.feedbackBtn : s2.feedbackBtnDisabled}
													disabled={feedbackText.length === 0}
													onClick={(e) => submitFeedback(e)}
												>
													{pageText.Submit}
												</button>
											</div>
										</form>
									</>
								)}

								{rating >= 9 && (
									<>
										<Trustbox />
									</>
								)}

								{rating === undefined && (
									<>
										{window.innerWidth > 900 && (
											<div className={s2.surveyBtnContainer}>
												{npsOptions.map((option, index) => (
													<button key={index} className={s2.surveyBtn} onClick={(e) => rateExperience(e, option)}>
														{option}
													</button>
												))}
											</div>
										)}
										{window.innerWidth <= 900 && (
											<div className={s2.surveyBtnContainer}>
												<div className={s2.surveyBtnRow}>
													{npsOptions.slice(0, 6).map((option, index) => (
														<button key={index} className={s2.surveyBtn} onClick={(e) => rateExperience(e, option)}>
															{option}
														</button>
													))}
												</div>
												<div className={s2.surveyBtnRow}>
													{npsOptions.slice(6).map((option, index) => (
														<button key={index} className={s2.surveyBtn} onClick={(e) => rateExperience(e, option)}>
															{option}
														</button>
													))}
												</div>
											</div>
										)}

										<div className={s2.surveyLabelContainer}>
											<p className={s2.npsLabel}>0 - Not likely</p>
											<p className={s2.npsLabel}>10 - Very likely</p>
										</div>
									</>
								)}
							</div>
						)}
					</>
				)}
			</div>
		</ModalWrapper>
	);
}

export function EndingModal({
	setShowEndingModal,
	keepGoing,
	endingTimer,
	setKeepGoing,
	endSim,
	togglePauseSim,
	overwriteAssistantMesage,
	lastMessage,
	lastMessageName
}) {
	// Calculate the stroke-dasharray for the countdown circle
	const radius = 15.9155; // Radius of the SVG circle path
	const circleCircumference = 2 * Math.PI * radius;

	// Calculate the stroke dash offset based on the endingTimer
	const strokeDashoffset = ((15 - endingTimer) / 15) * circleCircumference;

	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		SoundsLikeThisConversationIsNearingTheEnd: "Sounds like this conversation is nearing the end.",
		NoKeepGoing: "No, keep going",
		YesEndNow: "Yes, end now",
		ClickWhenYoureFinished: "Click when you're finished.",
		Okay: "Okay"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	const [TextRenderState, setTextRenderState] = useState(false);
	useEffect(() => {
		setTextRenderState((prev) => !prev);
	}, [pageText]);

	return (
		<div className={s2.modalFillContainer}>
			<div className={s2.new_modal}>
				<IoClose className={s2.new_modalCloseIcon} title="Close icon" onClick={() => setShowEndingModal(false)} />
				{!keepGoing && (
					<>
						<div className={s2.new_modalTimerWrapper}>
							<div className={s2.new_modalTimerContainer}>
								<svg className={s2.new_modalTimerRing} viewBox="0 0 36 36">
									<path
										className={s2.new_modalTimerRingCircleBg}
										d="M18 2.0845
				a 15.9155 15.9155 0 0 1 0 31.831
				a 15.9155 15.9155 0 0 1 0 -31.831"
									/>
									<path
										className={s2.new_modalTimerRingCircle}
										strokeDasharray={`${circleCircumference} ${circleCircumference}`}
										strokeDashoffset={-strokeDashoffset}
										d="M18 2.0845
				a 15.9155 15.9155 0 0 1 0 31.831
				a 15.9155 15.9155 0 0 1 0 -31.831"
									/>
								</svg>
								<p className={s2.new_modalTimer}>{endingTimer}</p>
							</div>
						</div>
						<div className={s2.modalTextContainer}>
							<h1 className={s2.new_modalTitle}>{pageText.SoundsLikeThisConversationIsNearingTheEnd}</h1>
						</div>
						<div className={s2.new_modalBtnContainer}>
							<button type="button" className={s2.new_modalCancelBtn} onClick={() => setKeepGoing(true)}>
								{pageText.NoKeepGoing}
							</button>
							<button
								type="button"
								className={s2.new_modalConfirmBtn}
								onClick={() => {
									setShowEndingModal(false);
									endSim("manual");
								}}
							>
								{pageText.YesEndNow}
							</button>
						</div>
					</>
				)}
				{keepGoing && (
					<>
						<div className={s2.modalTextContainer}>
							<h1 className={`${s2.new_modalTitle} ${s2.new_modalTitleWithBtn}`}>
								Click{" "}
								<span className={s.endSimBtnMini}>
									<FiLogOut className={s.endSimBtnMiniIcon} />
								</span>{" "}
								{pageText.ClickWhenYoureFinished}
							</h1>
						</div>
						<div className={s2.new_modalBtnContainer}>
							<button
								type="button"
								className={s2.new_modalConfirmBtn}
								onClick={() => {
									setShowEndingModal(false);
									togglePauseSim(false);
									if (setShowEndingModal && togglePauseSim && overwriteAssistantMesage) {
										overwriteAssistantMesage(lastMessage, lastMessageName);
									}
								}}
							>
								{pageText.Okay}
							</button>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export function AssignmentModal({
	editAccess,
	navigate,
	localUser,
	setup_type,
	data,
	setData,
	setShowAssignmentModal,
	newAssignmentType,
	setCaptureOn,
	sessionData,
	updateEduData,
	isReport
}) {
	const posthog = usePostHog();

	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();

	const assignmentOptionsFlag = useFeatureFlagEnabled("assignment-options-flag");

	const { adminAccess, setupData, clientType, runTranslation, translateDictText, userLanguage, performTranslation } = useContext(AppContext);

	const [hasTranslatedText, setHasTranslatedText] = useState(false);

	const LocalLanguageDict = {
		AssignmentInstructions: "Assignment Instructions",
		AssignmentName: "ASSIGNMENT NAME",
		PracticeAssignment: "Practice Assignment",
		DueDate: "DUE DATE",
		SampleDueDate: "Submit by the due date specified by your instructor",
		AssignmentInstructionsCaps: "ASSIGNMENT INSTRUCTIONS",
		SampleInstructions:
			'1. Copy/Paste simulation information into the required sections. <br /> 2. Click "Generate". <br /> 3. Select Your Avatar. <br /> 4. Scroll down and make sure "Start with video on" and "Record video" are checked. <br /> 5. Click "Start Meeting". <br /> 6. Complete the simulation. <br /> 7. After the simulation is complete, click "Yes, end now". <br /> 8. Finally, click "Generate Report".',
		SubmissionInstructionsCaps: "SUBMISSION INSTRUCTIONS",
		SampleSubmissionInstructions:
			'1. Click "Share" in the top right hand corner of the report screen and copy the link to your report. <br /> 2. Paste your report to your instructors assignment submission folder.',
		QuestionGradingRubricCaps: "QUESTION GRADING RUBRIC",
		ContentLabel: "Content:",
		DeliveryLabel: "Delivery:",
		CompletionLabel: "Completion:",
		OtherLabel: "Other:",
		ContentLabelText: " The quality, relevance, and structure of your answer",
		DeliveryLabelText: " Your pace, volume, and use of filler words",
		CompletionLabelText: " The time spent answering each question",
		OtherLabelText: " Your camera setup, background, attire, speech pattern (rhythm, flow, and intonation), clarity, grammar, etc.",
		DontShowAgain: "Don't show this again.",
		Edit: "Edit",
		Okay: "Okay",
		CanStudentsSeeGrade: "Allow students to see their report grade",
		StartWithVideoOn: "Start with video recording on by default",
		DisableMidSessionRetry: "Disable mid-session retry by default",
		StudentMessageForInstructions: "The student will see these instructions when they first open the link.",
		StudentMessageForReport: "The student will see these instructions when they load their report.",
		Save: "Save",
		Cancel: "Cancel",
		SelectCourse: "SELECT COURSE",
		COURSE: "COURSE",
		SelectACourse: "Select a course",
		SectionCodeOrName: "Section code or name",
		SectionOptions: "Section Options",
		EnterAllAvailableSectionCodesOrNames: "Enter all available section codes or names",
		SelectTerm: "SELECT TERM",
		TERM: "TERM",
		SelectATerm: "Select a term",
		SelectSection: "SELECT SECTION",
		SelectASection: "Select a section",
		Required: "required",
		Percentage: "Percentage",
		PassingGradeCAPS: "PASSING GRADE",
		CanStudentsSeeGradeLabel: "CAN USERS SEE THEIR GRADE?",
		PassingGradeLabel:
			"This will determine the passing grade for the assignment. i.e. 50%, students must score 50% or higher to get a successful outcome.",
		Yes: "Yes",
		No: "No"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// useEffect(() => {
	// 	console.log(pageText.InterviewJobTitlePlaceholder);
	// 	setPageText({ ...pageText, InterviewJobTitlePlaceholder: "test" }); // Set individual key
	// }, []);

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
		}
	}, [userLanguage, pageText]);

	const [editModeOn, setEditModeOn] = useState(false);

	const cleanHTML = (value) => DOMPurify.sanitize(value);

	const { savedId } = useParams();
	const [edited, setEdited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [dataTemp, setDataTemp] = useState({ ...data });

	const originalData = useRef({ ...data });

	const [showSaveButton, setShowSaveButton] = useState(false);
	const [localContentWeight, setLocalContentWeight] = useState(
		dataTemp?.content_weight !== undefined ? dataTemp.content_weight : originalData.current.content_weight
	);
	const [localDeliveryWeight, setLocalDeliveryWeight] = useState(
		dataTemp?.delivery_weight !== undefined ? dataTemp.delivery_weight : originalData.current.delivery_weight
	);
	const [localCompletionWeight, setLocalCompletionWeight] = useState(
		dataTemp?.completion_weight !== undefined ? dataTemp.completion_weight : originalData.current.completion_weight
	);
	const [localOtherWeight, setLocalOtherWeight] = useState(
		dataTemp?.other_weight !== undefined ? dataTemp.other_weight : originalData.current.other_weight
	);
	const [weightError, setWeightError] = useState(false);
	const [ShowInfo_assignment_instructions, setShowInfo_assignment_instructions] = useState(false);
	const [ShowInfo_submission_instructions, setShowInfo_submission_instructions] = useState(false);
	const [dontShowAgainState, setDontShowAgainState] = useState(false);
	const [requireVideoRecording, setrequireVideoRecording] = useState(dataTemp?.require_video_recording || false);
	const [canStudentsSeeGrade, setCanStudentsSeeGrade] = useState(dataTemp?.can_students_see_grade || false);
	const [disabledRetry, setDisabledRetry] = useState(dataTemp?.disable_retry || true);

	const [optionsCourse, setOptionsCourse] = useState(dataTemp?.course_options || originalData.current.course_options);
	const [optionsTerm, setOptionsTerm] = useState(dataTemp?.term_options || originalData.current.term_options);
	const [optionsSection, setOptionsSection] = useState(dataTemp?.section_options || originalData.current.section_options);
	const [assignmentName, setAssignmentName] = useState(dataTemp?.assignment_name || originalData.current.assignment_name);

	const [selectedCourse, setSelectedCourse] = useState();
	const [selectedTerm, setSelectedTerm] = useState();
	const [selectedSection, setSelectedSection] = useState();
	const [localAssignmentDetails, setLocalAssignmentDetails] = useState({});
	// const [passingGrade, setPassingGrade] = useState(dataTemp?.passing_grade || originalData.current.passing_grade);
	const [passingGrade, setPassingGrade] = useState(50);

	const currentSimId = savedId || sessionData?.simulation_fk || "";

	const pageLocation = useLocation();

	// useEffect(() => {
	// 	runTranslation();
	// });

	useEffect(() => {
		// console.log("dataTemp", dataTemp);
		// console.log("data", data);
		setSelectedCourse(dataTemp.course_options);
		setSelectedTerm(dataTemp.term_options[0]);
		// setCanStudentsSeeGrade(dataTemp.can_students_see_grade);
	}, [dataTemp]);

	useEffect(() => {
		// console.log("setup_type", setup_type);
		// console.log("creator_instage_id", data.creator_instage_id);
		// console.log("data", data);
		// console.log("localUser", localUser);
		// localUser.id += "5";
		// Set local edit access if the user has admin access and the creator of the data is either the local user or undefined
		setSelectedCourse(dataTemp.course_options);
		setSelectedTerm(dataTemp.term_options);
		const payload = {};
		if (data?.passing_grade === null || data?.passing_grade === undefined) payload.passing_grade = passingGrade;
		if (data?.can_students_see_grade === null || data?.can_students_see_grade === undefined) payload.can_students_see_grade = canStudentsSeeGrade;

		if (dataTemp?.course_options) {
			payload.selectedCourse = Array.isArray(dataTemp.course_options) ? dataTemp.course_options[0] : dataTemp.course_options;
		}
		if (dataTemp?.term_options) payload.selectedTerm = Array.isArray(dataTemp.term_options) ? dataTemp.term_options[0] : dataTemp.term_options;
		// if (data?.course_options?.length > 0) payload.selectedCourse = selectedCourse;
		// if (data?.term_options?.length > 0) payload.selectedTerm = selectedTerm;
		if (data?.section_options?.length > 0) payload.selectedSection = selectedSection;
		// console.log("payload", payload);
		setLocalAssignmentDetails(payload);
		if (updateEduData) {
			updateEduData(payload);
		}
	}, [data]);

	useEffect(() => {
		originalData.current = { ...data };
	}, [data]);

	useEffect(() => {
		const handleClick = () => {
			setShowInfo_assignment_instructions(false);
			setShowInfo_submission_instructions(false);
		};
		document.addEventListener("click", handleClick);
		return () => {
			document.removeEventListener("click", handleClick);
		};
	}, []);

	useEffect(() => {
		const fetchAssignmentState = async () => {
			const db = await openDB("simulation", 1);
			const hiddenInstructions = (await db.get("simulation_db", "hiddenInstructions")) || {};
			const assignmentState = hiddenInstructions[currentSimId];

			// Default to true (checked) if no match is found
			setDontShowAgainState(assignmentState === false);
		};

		fetchAssignmentState().catch((err) => console.error("Error fetching assignment state:", err));
	}, [currentSimId]);

	async function saveToDb() {
		setIsLoading(true);
		const isNewAssignment = data.assignment_type === "" && data.assignment_name === "";
		// console.log("before save data", data, isNewAssignment);

		const totalWeight = localContentWeight + localDeliveryWeight + localCompletionWeight + localOtherWeight;
		if (totalWeight !== 100) {
			setWeightError(true);
			setIsLoading(false);
			return;
		}

		try {
			if (isNewAssignment) {
				posthog?.capture("instage_new_assignment", {
					instage_id: localUser.id,
					...originalData
				});
			} else {
				posthog?.capture("instage_edit_assignment", {
					instage_id: localUser.id,
					...originalData
				});
			}
		} catch (e) {
			console.error(e);
		}
		try {
			dataTemp.course_options = [optionsCourse];
			dataTemp.term_options = optionsTerm;
			dataTemp.can_students_see_grade = canStudentsSeeGrade;
			dataTemp.require_video_recording = requireVideoRecording;
			if (currentSimId) {
				[
					"assignment_name",
					"due_date",
					"submission_instructions",
					"assignment_instructions",
					"content_weight",
					"delivery_weight",
					"completion_weight",
					"other_weight",
					"course_options",
					"term_options",
					"section_options",
					"passing_grade",
					"can_students_see_grade",
					"require_video_recording"
				].forEach((field) => {
					// Always save content_weight, delivery_weight, completion_weight, and other_weight
					if (
						["content_weight", "delivery_weight", "completion_weight", "other_weight"].includes(field) ||
						originalData.current[field] !== dataTemp[field]
					) {
						const data = {
							simulation_id: currentSimId,
							value: dataTemp[field],
							field
						};
						// console.log("data", data);

						axiosLimitedPatch(`${url}/api/saved/simulation/output`, data, 1, {}, 30000).then((resp3) => {
							// console.log(resp3.data);
							// update this field in data using the response

							setData((prevData) => ({ ...prevData, [field]: dataTemp[field] }));
							// console.log("Data: ", dataTemp[field]);
						});
					}
				});

				if (originalData.current.assignment_type === "" && newAssignmentType !== "") {
					axiosLimitedPatch(
						`${url}/api/saved/simulation/output`,
						{ simulation_id: currentSimId, value: newAssignmentType, field: "assignment_type" },
						1,
						{},
						30000
					).then((resp3) => {
						// console.log(resp3.data);
						// update this field in data using the response
						setData((prevData) => ({ ...prevData, assignment_type: resp3.data.assignment_type }));
					});
				}
			} else {
				// console.log(setupData)
				// console.log(setup_type)
				console.log("setupData", setupData);
				const { path } = await createNewDynamicAssingnment(
					setup_type,
					setupData,
					localUser.id,
					dataTemp,
					newAssignmentType,
					axiosLimitedPost,
					setData,
					navigate
				);
				if (parsePath) {
					navigate(path);
				}
			}

			setEdited(false);
		} catch (error) {
			console.error("Failed to save:", error);
		} finally {
			setIsLoading(false);
			setShowAssignmentModal(false);
		}
	}

	function handleModalChange(e, property) {
		// console.log(e, property);
		setWeightError(false);
		setIsLoading(true);
		setEdited(true);
		const temp = { ...dataTemp };

		// Check if e has a target property (standard event object)
		// If not, assume it's the direct value (e.g., HTML content from ReactQuill)
		let value = e.target ? e.target.value : e;
		//	console.log(value, property);
		// If one of the weight fields is being edited
		if (["content_weight", "delivery_weight", "completion_weight", "other_weight"].includes(property)) {
			value = value === "" ? "" : parseInt(value, 10);

			// Update the local state variable corresponding to the edited field
			if (property === "content_weight") {
				setLocalContentWeight(value);
			} else if (property === "delivery_weight") {
				setLocalDeliveryWeight(value);
			} else if (property === "completion_weight") {
				setLocalCompletionWeight(value);
			} else if (property === "other_weight") {
				setLocalOtherWeight(value);
			}
		}
		if (property === "passing_grade") {
			if (!isNaN(value)) {
				setPassingGrade(parseInt(value, 10));
			} else {
				setPassingGrade(50);
			}
			console.log("Passing Grade: ", passingGrade);
		}
		if (property === "course_options") {
			setOptionsCourse(value);
		}
		if (property === "term_options") {
			setOptionsTerm(value);
		}
		if (property === "section_options") {
			setOptionsSection(value);
		}
		if (property === "assignment_name") {
			setAssignmentName(value);
		}
		temp[property] = value;

		setDataTemp(temp);
		setIsLoading(false);
	}

	const handleRequireVideoRecordingToggle = async () => {
		setrequireVideoRecording(!requireVideoRecording);
		if (setCaptureOn) {
			setCaptureOn(!requireVideoRecording);
		}

		const payload = {
			simulation_id: currentSimId,
			value: !requireVideoRecording,
			field: "require_video_recording"
		};

		await axiosLimitedPatch(`${url}/api/saved/simulation/output`, payload, 1, {}, 30000).then((resp) => {
			// console.log(resp.data);
			// update this field in data using the response
			setData((prevData) => ({ ...prevData, require_video_recording: resp.data.require_video_recording }));
		});
	};

	const handleCanStudentsSeeGradeToggle = async () => {
		setCanStudentsSeeGrade(!canStudentsSeeGrade);

		const payload = {
			simulation_id: currentSimId,
			value: !canStudentsSeeGrade,
			field: "can_students_see_grade"
		};

		await axiosLimitedPatch(`${url}/api/saved/simulation/output`, payload, 1, {}, 30000).then((resp) => {
			// console.log(resp.data);
			// update this field in data using the response
			setData((prevData) => ({ ...prevData, can_students_see_grade: resp.data?.can_students_see_grade }));
		});
	};

	const handleDisabledRetrygToggle = async () => {
		setDisabledRetry(!disabledRetry);

		const payload = {
			simulation_id: currentSimId,
			value: !disabledRetry,
			field: "disable_retry"
		};

		await axiosLimitedPatch(`${url}/api/saved/simulation/output`, payload, 1, {}, 30000).then((resp) => {
			// console.log(resp.data);
			// update this field in data using the response
			setData((prevData) => ({ ...prevData, disable_retry: resp.data.disable_retry }));
		});
	};

	const handleDontShowAgainToggle = async () => {
		const newDontShowAgainState = !dontShowAgainState;
		setDontShowAgainState(newDontShowAgainState);

		const db = await openDB("simulation", 1);
		const tx = db.transaction("simulation_db", "readwrite");
		const store = tx.objectStore("simulation_db");

		// Retrieve the hiddenInstructions object, or create it if it doesn't exist
		const hiddenInstructions = (await store.get("hiddenInstructions")) || {};

		// Add or toggle the currentSimId
		hiddenInstructions[currentSimId] = hiddenInstructions[currentSimId] === undefined ? true : !hiddenInstructions[currentSimId];

		// Save the updated hiddenInstructions object back to the store
		await store.put(hiddenInstructions, "hiddenInstructions");

		// Close the transaction
		await tx.done;
	};

	function areAllKeysDefined(obj) {
		return Object.keys(obj).every((key) => obj[key] !== undefined);
	}
	const allowedToClose = clientType === "edu" && !editModeOn && !isReport && !adminAccess ? areAllKeysDefined(localAssignmentDetails) : true;
	return (
		<ModalWrapper
			closeModal={() => {
				if (allowedToClose) {
					setShowAssignmentModal(false);
				}
			}}
			maxWidth="100%"
		>
			<div className={`${s2.modalAnimated} ${s2.modalMinWidth} ${!adminAccess ? s2.instructionsModal : ""}`}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={s2.modalHeaderIconContainer}>
					<MdOutlineAssignment className={s2.modalHeaderIcon} />
				</div>
				{allowedToClose && <IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowAssignmentModal(false)} />}

				<div className={s2.modalTextContainer}>
					<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
						<h1 className={s2.modalTitle}>
							{editModeOn ? "Create " : ""}
							{pageText.AssignmentInstructions}
						</h1>
						{editAccess && editModeOn === false && (
							<button
								type="button"
								className={s3.adminBtn}
								style={{ alignSelf: "flex-end" }}
								onClick={() => {
									setEditModeOn(true);
								}}
							>
								<FiEdit className={s3.adminBtnIcon} />
								<span>{pageText.Edit}</span>
							</button>
						)}
					</div>
				</div>
				<div>
					<div style={{ display: "flex", alignItems: "center", marginBottom: "0" }}>
						<p className={s2.modalInputLabel}>{pageText.AssignmentName}</p>
						<h6 style={{ color: "red", fontSize: "1.2rem", marginLeft: "5px", lineHeight: "5px", marginTop: "5px" }}>*</h6>
					</div>

					{editModeOn ? (
						<input
							data={pageText.PracticeAssignment}
							placeholder={pageText.PracticeAssignment}
							className={s2.modalInput}
							type="text"
							value={dataTemp.assignment_name}
							disabled={!editModeOn}
							onChange={(e) => {
								handleModalChange(e, "assignment_name");
							}}
						/>
					) : (
						<p className={s2.modalInstructionsText}>
							{dataTemp.assignment_name ? dataTemp.assignment_name : `${pageText.PracticeAssignment}`}
						</p>
					)}
				</div>
				<div>
					<p className={s2.modalInputLabel}>{pageText.DueDate}</p>
					{editModeOn ? (
						<input
							data={pageText.SampleDueDate}
							placeholder={pageText.SampleDueDate}
							className={s2.modalInput}
							type="text"
							value={dataTemp.due_date}
							disabled={!editModeOn}
							onChange={(e) => {
								handleModalChange(e, "due_date");
							}}
						/>
					) : (
						<p className={s2.modalInstructionsText}>{dataTemp.due_date ? dataTemp.due_date : `${pageText.SampleDueDate}`}</p>
					)}
				</div>
				<div>
					<div style={{ display: "flex", alignItems: "center" }}>
						{(editModeOn || pageLocation.pathname.includes("setup")) && (
							<>
								<p className={s2.modalInputLabel}>{pageText.AssignmentInstructionsCaps}</p>
								{editModeOn && (
									<FiInfo
										style={{ marginLeft: "10px", fontSize: "15px", cursor: "pointer" }}
										onMouseEnter={(event) => {
											// eslint-disable-next-line no-param-reassign
											event.target.style.color = "#009399";
										}}
										onMouseLeave={(event) => {
											// eslint-disable-next-line no-param-reassign
											event.target.style.color = "#808080";
										}}
										onClick={(event) => {
											event.stopPropagation();
											setShowInfo_assignment_instructions(true);
											// eslint-disable-next-line no-param-reassign
											event.target.style.color = "#808080";
										}}
									/>
								)}
							</>
						)}
					</div>
					{ShowInfo_assignment_instructions && (
						<button
							type="button"
							style={{
								backgroundColor: "beige",
								borderRadius: "4px",
								position: "relative",
								display: "flex",
								alignItems: "center",
								margin: "0.5rem",
								justifyContent: "center",
								border: "none",
								cursor: "pointer"
							}}
							onClick={(event) => event.stopPropagation()}
						>
							{pageText.StudentMessageForInstructions}
						</button>
					)}
					{editModeOn ? (
						<>
							<CKEditor
								editor={ClassicEditor}
								data={
									// prettier-ignore
									dataTemp?.assignment_instructions
										? dataTemp.assignment_instructions
										: `${pageText.SampleInstructions}`
								}
								config={{
									toolbar: {
										items: [
											"undo",
											"redo",
											"|",
											"heading",
											"|",
											"bold",
											"italic",
											"link",
											"|",
											"bulletedList",
											"numberedList",
											"outdent",
											"indent"
										]
									}
								}}
								onChange={(event, editor) => {
									const editorData = editor.getData();
									handleModalChange(editorData, "assignment_instructions");
								}}
							/>
						</>
					) : (
						<>
							{pageLocation.pathname.includes("setup") && (
								<span
									className={s2.modalInstructionsText}
									// prettier-ignore
									dangerouslySetInnerHTML={{ __html: cleanHTML(dataTemp?.assignment_instructions) || `${pageText.SampleInstructions}` }}
								/>
							)}
						</>
					)}
				</div>
				<div>
					<div style={{ display: "flex", alignItems: "center" }}>
						{(editModeOn || pageLocation.pathname.includes("report")) && (
							<>
								<p className={s2.modalInputLabel}>{pageText.SubmissionInstructionsCaps}</p>
								{editModeOn && (
									<FiInfo
										style={{ marginLeft: "10px", fontSize: "15px", cursor: "pointer" }}
										onMouseEnter={(event) => {
											// eslint-disable-next-line no-param-reassign
											event.target.style.color = "#009399";
										}}
										onMouseLeave={(event) => {
											// eslint-disable-next-line no-param-reassign
											event.target.style.color = "#808080";
										}}
										onClick={(event) => {
											event.stopPropagation();
											setShowInfo_submission_instructions(true);
											// eslint-disable-next-line no-param-reassign
											event.target.style.color = "#808080";
										}}
									/>
								)}
							</>
						)}
					</div>
					{ShowInfo_submission_instructions && (
						<button
							type="button"
							style={{
								backgroundColor: "beige",
								borderRadius: "4px",
								position: "relative",
								display: "flex",
								alignItems: "center",
								margin: "0.5rem",
								justifyContent: "center",
								border: "none",
								cursor: "pointer"
							}}
							onClick={(event) => event.stopPropagation()}
						>
							{pageText.StudentMessageForReport}
						</button>
					)}

					{editModeOn ? (
						<>
							<CKEditor
								editor={ClassicEditor}
								// prettier-ignore
								data={dataTemp?.submission_instructions ? dataTemp.submission_instructions : `${pageText.SampleSubmissionInstructions}`}
								config={{
									toolbar: {
										items: [
											"undo",
											"redo",
											"|",
											"heading",
											"|",
											"bold",
											"italic",
											"link",
											"|",
											"bulletedList",
											"numberedList",
											"outdent",
											"indent"
										]
									}
								}}
								onChange={(event, editor) => {
									const editorData = editor.getData();
									handleModalChange(editorData, "submission_instructions");
								}}
							/>
						</>
					) : (
						<>
							{pageLocation.pathname.includes("report") && (
								<div
									className={s2.modalInstructionsText}
									// prettier-ignore
									dangerouslySetInnerHTML={{ __html: cleanHTML(dataTemp?.submission_instructions) || "1. Click \"Share\" in the top right hand corner of the report screen and copy the link to your report. <br /> 2. Paste your report to your instructors assignment submission folder." }}
								/>
							)}
						</>
					)}
				</div>

				{setup_type.includes("interview") && (
					<div className={s2.modalRubricContainer}>
						<p className={s2.modalInputLabel}>{pageText.QuestionGradingRubricCaps}</p>
						<div className={s2.modalGradeContainer}>
							{editModeOn ? (
								<input
									type="number"
									value={localContentWeight}
									className={s2.modalInputSmall}
									disabled={!editModeOn}
									onChange={(e) => {
										handleModalChange(e, "content_weight");
									}}
								/>
							) : (
								<p className={s2.modalInstructionsText} style={{ margin: "0", width: "2rem" }}>
									{dataTemp?.content_weight ? dataTemp.content_weight : originalData.current.content_weight}
								</p>
							)}
							<span className={s2.modalGradePercent}>%</span>
							<p className={s2.modalInputLabelSmall}>{pageText.ContentLabel}</p>
							<p className={s2.modalInputLabelDescription}>{pageText.ContentLabelText}</p>
						</div>
						<div className={s2.modalGradeContainer}>
							{editModeOn ? (
								<input
									type="number"
									value={localDeliveryWeight}
									className={s2.modalInputSmall}
									disabled={!editModeOn}
									onChange={(e) => {
										handleModalChange(e, "delivery_weight");
									}}
								/>
							) : (
								<p className={s2.modalInstructionsText} style={{ margin: "0", width: "2rem" }}>
									{dataTemp?.delivery_weight ? dataTemp.delivery_weight : originalData.current.delivery_weight}
								</p>
							)}
							<span className={s2.modalGradePercent}>%</span>
							<p className={s2.modalInputLabelSmall}>{pageText.DeliveryLabel}</p>
							<p className={s2.modalInputLabelDescription}>{pageText.DeliveryLabelText}</p>
						</div>
						<div className={s2.modalGradeContainer}>
							{editModeOn ? (
								<input
									type="number"
									value={localCompletionWeight}
									className={s2.modalInputSmall}
									disabled={!editModeOn}
									onChange={(e) => {
										handleModalChange(e, "completion_weight");
									}}
								/>
							) : (
								<p className={s2.modalInstructionsText} style={{ margin: "0", width: "2rem" }}>
									{dataTemp?.completion_weight ? dataTemp.completion_weight : originalData.current.completion_weight}
								</p>
							)}
							<span className={s2.modalGradePercent}>%</span>
							<p className={s2.modalInputLabelSmall}>{pageText.CompletionLabel}</p>
							<p className={s2.modalInputLabelDescription}>{pageText.CompletionLabelText}</p>
						</div>

						<div className={s2.modalGradeContainer}>
							{editModeOn ? (
								<input
									type="number"
									value={localOtherWeight}
									className={s2.modalInputSmall}
									disabled={!editModeOn}
									onChange={(e) => {
										handleModalChange(e, "other_weight");
									}}
								/>
							) : (
								<p className={s2.modalInstructionsText} style={{ margin: "0", width: "2rem" }}>
									{dataTemp?.other_weight ? dataTemp.other_weight : originalData.current.other_weight}
								</p>
							)}
							<span className={s2.modalGradePercent}>%</span>
							<p className={s2.modalInputLabelSmall}>{pageText.OtherLabel}</p>
							<p className={s2.modalInputLabelDescription}>{pageText.OtherLabelText}</p>
						</div>
						{weightError && <p className={s2.modalRubricError}>The sum of the weights must be 100.</p>}

						{adminAccess && (
							<div>
								<hr />
								<p className={s2.modalInputLabel}>{pageText.PassingGradeCAPS}</p>
								<div className={s2.modalGradeContainer}>
									{editModeOn ? (
										<input
											type="number"
											value={dataTemp.passing_grade}
											className={s2.modalInputSmall}
											disabled={!editModeOn}
											onChange={({ target: { value } }) => {
												handleModalChange(value === "" ? 50 : parseInt(value, 10), "passing_grade");
											}}
											onBlur={(e) => {
												if (e.target.value === "") {
													handleModalChange(50, "passing_grade");
												}
											}}
											defaultValue={50}
										/>
									) : (
										<p className={s2.modalInstructionsText} style={{ margin: "0", width: "2rem" }}>
											{/* {console.log("Data: ", data)}
									{console.log("OriginalData: ", originalData.current)}
									{console.log("TempData: ", dataTemp)} */}
											{dataTemp?.passing_grade !== undefined
												? dataTemp.passing_grade
												: originalData.current.passing_grade || 50}
											{/* {passingGrade} */}
										</p>
									)}
									<span className={s2.modalGradePercent}>%</span>
									<p className={s2.modalInputLabelSmall}>{pageText.Percentage}:</p>
									<p className={s2.modalInputLabelDescription}>{pageText.PassingGradeLabel}</p>
								</div>
								<hr />
							</div>
						)}
					</div>
				)}
				{editModeOn ? (
					<div style={{ marginTop: "20px" }}>
						<input
							type="checkbox"
							id="canStudentsSeeGrade_Toggle"
							style={{ transform: "scale(0.4)" }}
							checked={canStudentsSeeGrade}
							onChange={handleCanStudentsSeeGradeToggle}
						/>
						<label htmlFor="canStudentsSeeGrade_Toggle">{pageText.CanStudentsSeeGrade}</label>
					</div>
				) : (
					<div style={{ marginTop: "0px" }}>
						<p className={s2.modalInputLabel}>{pageText.CanStudentsSeeGradeLabel}</p>
						<p className={s2.modalInstructionsText}>{dataTemp?.can_students_see_grade ? pageText.Yes : pageText.No}</p>
						<hr />
					</div>
				)}
				{editModeOn && (
					<div style={{ marginTop: "20px" }}>
						<input
							type="checkbox"
							id="requireWebCam_Toggle"
							style={{ transform: "scale(0.4)" }}
							checked={requireVideoRecording}
							onChange={handleRequireVideoRecordingToggle}
						/>
						<label htmlFor="requireWebCam_Toggle">{pageText.StartWithVideoOn}</label>
					</div>
				)}
				{editModeOn && (
					<div style={{ marginTop: "20px" }}>
						<input
							type="checkbox"
							id="disableRetry_Toggle"
							style={{ transform: "scale(0.4)" }}
							checked={disabledRetry}
							onChange={handleDisabledRetrygToggle}
						/>
						<label htmlFor="disableRetry_Toggle">{pageText.DisableMidSessionRetry}</label>
					</div>
				)}

				{clientType === "edu" && assignmentOptionsFlag && (
					<>
						{editModeOn && (
							<>
								<div style={{ marginTop: "20px" }}>
									<div style={{ display: "flex", flexDirection: "row" }}>
										<p className={s2.modalInputLabel}>Course Code: </p>

										<p style={{ marginLeft: "5px" }}> Enter your course code or name</p>
										<p style={{ color: "red", fontSize: "1.2rem", marginLeft: "5px", marginTop: "0px" }}>*</p>
									</div>
									<input
										type="text"
										value={optionsCourse}
										className={s2.modalInputMedium}
										disabled={!editModeOn}
										onChange={({ target: { value } }) => {
											handleModalChange(value, "course_options");
										}}
										defaultValue={dataTemp.course_options[0]}
									/>
									{/* <ListWithInputOptions
										initialData={optionsCourse}
										onOptionsChange={(options) => {
											handleModalChange(options, "course_options");
											// setOptionsCourse(options);
											// setEdited(true);
										}}
										placeholder="Course code or name"
									/> */}
								</div>
								{/* <hr /> */}
								<div style={{ marginTop: "20px" }}>
									<div style={{ display: "flex", flexDirection: "row" }}>
										<p className={s2.modalInputLabel}>Term: </p>
										<p style={{ marginLeft: "5px" }}>Enter the course term or leave as the automatically chosen term</p>
										<p style={{ color: "red", fontSize: "1.2rem", marginLeft: "5px", marginTop: "0px" }}>*</p>
									</div>
									<input
										type="text"
										value={optionsTerm}
										className={s2.modalInputMedium}
										disabled={!editModeOn}
										onChange={({ target: { value } }) => {
											handleModalChange(value, "term_options");
										}}
										defaultValue={dataTemp.term_options[0]}
									/>
									{/* <ListWithInputOptions
										initialData={optionsTerm}
										onOptionsChange={(options) => {
											handleModalChange(options, "term_options");
											// setOptionsTerm(options);
											// setEdited(true);
										}}
										placeholder="Term code or name"
									/> */}
								</div>
								{/* <hr />
								<div style={{ marginTop: "20px" }}>
									<p className={s2.modalInputLabel}>{pageText.SectionOptions}</p>
									{pageText.EnterAllAvailableSectionCodesOrNames}
									<ListWithInputOptions
										initialData={optionsSection}
										onOptionsChange={(options) => {
											handleModalChange(options, "section_options");
											// setOptionsSection(options);
											// setEdited(true);
										}}
										placeholder={pageText.SectionCodeOrName}
									/>
								</div> */}
							</>
						)}

						<br />
						{!isReport && !editModeOn && optionsCourse && optionsCourse.length > 0 && (
							<>
								<p className={s2.modalInputLabel}>
									{pageText.COURSE}
									<span style={{ color: "red" }}>*</span>
								</p>
								<p>{optionsCourse}</p>
								{/* <select
									value={selectedCourse}
									defaultValue={""}
									onChange={(e) => {
										setSelectedCourse(e.target.value);
										const payload = {};
										if (optionsCourse.length > 0) payload.selectedCourse = e.target.value;
										if (optionsTerm.length > 0) payload.selectedTerm = selectedTerm;
										if (optionsSection.length > 0) payload.selectedSection = selectedSection;
										setLocalAssignmentDetails(payload);

										updateEduData(payload);
									}}
								>
									<option value="" disabled>
										{pageText.SelectACourse}
									</option>
									{optionsCourse.map((option, index) => (
										<option key={index} value={option}>
											{option}
										</option>
									))}
								</select> */}
							</>
						)}
						{/* <br /> */}
						{!isReport && !editModeOn && optionsTerm && optionsTerm.length > 0 && (
							<>
								<p className={s2.modalInputLabel}>
									{pageText.TERM}
									<span style={{ color: "red" }}>*</span>
								</p>
								<p>{optionsTerm}</p>
								{/* <select
									value={selectedTerm}
									defaultValue={""}
									onChange={(e) => {
										setSelectedTerm(e.target.value);
										const payload = {};
										if (optionsCourse.length > 0) payload.selectedCourse = selectedCourse;
										if (optionsTerm.length > 0) payload.selectedTerm = e.target.value;
										if (optionsSection.length > 0) payload.selectedSection = selectedSection;
										setLocalAssignmentDetails(payload);
										updateEduData(payload);
									}}
								>
									<option value="" disabled>
										{pageText.SelectATerm}
									</option>
									{optionsTerm.map((option, index) => (
										<option key={index} value={option}>
											{option}
										</option>
									))}
								</select> */}
							</>
						)}
						<br />
						{/* {!isReport && !editModeOn && optionsSection && optionsSection.length > 0 && (
							<>
								<p className={s2.modalInputLabel}>
									{pageText.SelectSection}
									<span style={{ color: "red" }}>*</span>
								</p>
								<select
									value={selectedSection}
									defaultValue={""}
									onChange={(e) => {
										setSelectedSection(e.target.value);
										const payload = {};
										if (optionsCourse.length > 0) payload.selectedCourse = selectedCourse;
										if (optionsTerm.length > 0) payload.selectedTerm = selectedTerm;
										if (optionsSection.length > 0) payload.selectedSection = e.target.value;
										setLocalAssignmentDetails(payload);

										updateEduData(payload);
									}}
								>
									<option value="" disabled>
										{pageText.SelectASection}
									</option>
									{optionsSection.map((option, index) => (
										<option key={index} value={option}>
											{option}
										</option>
									))}
								</select>
							</>
						)} */}
						{/* {!isReport &&
							!editModeOn &&
							((optionsSection && optionsSection.length > 0) ||
								(optionsTerm && optionsTerm.length > 0) ||
								(optionsCourse && optionsCourse.length > 0)) && <span style={{ color: "red" }}>* {pageText.Required}</span>} */}
					</>
				)}

				{currentSimId && currentSimId !== "" && (
					<>
						{!editModeOn && (
							<div style={{ marginTop: "20px" }}>
								<input
									type="checkbox"
									id="showInstructions_Toggle"
									style={{ transform: "scale(0.4)" }}
									checked={dontShowAgainState}
									onChange={handleDontShowAgainToggle}
								/>
								<label htmlFor="showInstructions_Toggle" style={{ marginTop: "0px" }}>
									<p>{pageText.DontShowAgain}</p>
								</label>
							</div>
						)}
					</>
				)}
				<div className={s2.modalBtnContainer}>
					{editModeOn ? (
						<>
							<button type="button" className={s2.modalCancelBtn} onClick={() => setShowAssignmentModal(false)}>
								{pageText.Cancel}
							</button>
							<button
								type="button"
								className={
									edited &&
									(clientType !== "edu" ||
										(assignmentName?.trim() && optionsCourse?.toString().trim() !== "" && optionsTerm?.toString().trim() !== ""))
										? s2.modalConfirmBtn
										: s2.modalDisabledBtn
								}
								disabled={
									!edited ||
									isLoading ||
									(clientType === "edu" &&
										(!assignmentName?.trim() || !optionsCourse?.toString().trim() || !optionsTerm?.toString().trim()))
								}
								onClick={() => saveToDb()}
							>
								{pageText.Save}
							</button>
						</>
					) : (
						<button type="button" className={s2.modalConfirmBtn} onClick={() => setShowAssignmentModal(false)}>
							{pageText.Okay}
						</button>
					)}
				</div>
			</div>
		</ModalWrapper>
	);
}
const dataTempSchema = z.object({
	assignment_name: z.string(),
	due_date: z.string(),
	submission_instructions: z.string(),
	assignment_instructions: z.string(),
	content_weight: z.number().optional(),
	delivery_weight: z.number().optional(),
	completion_weight: z.number().optional(),
	other_weight: z.number().optional(),
	require_video_recording: z.boolean().optional(),
	can_students_see_grade: z.boolean().optional(),
	disable_retry: z.boolean().optional(),
	course_options: z.array(z.string()).optional(),
	term_options: z.array(z.string()).optional(),
	section_options: z.array(z.string()).optional(),
	passing_grade: z.number().optional()
	// Add other fields as necessary
});

/**
 * Creates a new dynamic assignment.
 *
 * @param {string} setup_type - The type of setup (e.g., "interview", "discovery", "retail", "presentation").
 * @param {Object} setupData - The data related to the setup.
 * @param {string} instage_id - The ID of the instage.
 * @param {dataTempSchema} inputData - Data for the assignment.
 * @param {string} newAssignmentType - The type of the new assignment.
 * @param {Function} axiosLimitedPost - The axios post function with limited retries.
 * @param {Function} [setAssingmentData=() => {}] - Function to set the assignment data.
 * @returns {Promise<{path: string, payload: Object}>} - A promise that resolves when the assignment is created.
 */
export async function createNewDynamicAssingnment(
	setup_type,
	setupData,
	instage_id,
	inputData,
	newAssignmentType,
	axiosLimitedPost,
	setAssingmentData = () => {}
) {
	let setup_input = "";
	let setup_name = "";

	const dataTemp = dataTempSchema.parse(inputData);

	switch (setup_type) {
		case "interview":
			setup_input = setupData.job_description;
			setup_name = setupData.job_title;
			break;
		case "discovery":
			setup_input = setupData.meeting_details;
			setup_name = setupData.product;
			break;
		case "retail":
			setup_input = setupData.additional_details;
			setup_name = setupData.product;
			break;
		case "presentation":
			setup_input = setupData.presentation_details;
			setup_name = setupData.topic;
			break;
		default:
			break;
	}
	const start_time = new Date().toISOString().replace("T", " ").replace("Z", "+00");

	const savedData = {
		instage_id,
		setup_type,
		setup_name: dataTemp.assignment_name,
		setup_input,

		setup_output: {
			...setupData,
			...dataTemp,
			assignment_type: newAssignmentType
		},
		created_date: start_time,
		last_modified: start_time,
		audience: ["John"],
		personality: "Playful",
		shared: false
	};

	// console.log(savedData);
	const resp2 = await axiosLimitedPost(`${url}/api/saved/simulation`, savedData, 1, {}, 30000);
	// console.log(JSON.stringify(resp2.data));
	// console.log(resp2.data);
	setAssingmentData((prevData) => ({
		...prevData,
		assignment_name: dataTemp.assignment_name,
		due_date: dataTemp.due_date,
		submission_instructions: dataTemp.submission_instructions,
		assignment_type: newAssignmentType,
		assignment_instructions: dataTemp.assignment_instructions
	}));
	let path = window.location.pathname;
	// check if path end with /
	if (path[path.length - 1] === "/") {
		path += resp2.data.simulation_id;
	} else {
		path = `${path}/${resp2.data.simulation_id}`;
	}
	return { path, payload: resp2.data };
}

export function OngoingModal({ setShowOngoingModal, handleModalCancel, handleModalConfirm }) {
	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Cancel: "Cancel",
		Confirm: "Confirm"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		performTranslation(pageText, setPageText);
	}, [userLanguage]); // Depend on pageText to trigger re-translation

	const [TextRenderState, setTextRenderState] = useState(false);
	useEffect(() => {
		setTextRenderState((prev) => !prev);
	}, [pageText]);
	return (
		<ModalWrapper closeModal={() => setShowOngoingModal(false)}>
			<div className={s2.modalAnimated}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={s2.modalHeaderIconContainer}>
					<MdStart className={s2.modalHeaderIcon} />
				</div>
				<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowOngoingModal(false)} />
				<div className={s2.modalTextContainer}>
					<h1 className={s2.modalTitle}>Are you sure?</h1>
					<p className={s2.modalText}>This will end your ongoing simulation.</p>
				</div>
				<div className={s2.modalBtnContainer}>
					<button type="button" className={s2.modalCancelBtn} onClick={() => handleModalCancel()}>
						{pageText.Cancel}
					</button>
					<button type="button" className={s2.modalConfirmBtn} onClick={() => handleModalConfirm()}>
						{pageText.Confirm}
					</button>
				</div>
			</div>
		</ModalWrapper>
	);
}
export function SocketErrorModal({ posthog, connectionStatus, deepgramStatus, setShowSocketErrorModal }) {
	const showAudioError = deepgramStatus === connectionStatusOptions.Disconnected || deepgramStatus === connectionStatusOptions.Error;
	const showDataError = connectionStatus === connectionStatusOptions.Disconnected || connectionStatus === connectionStatusOptions.Error;

	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		AudioConnectionError: "Audio connection error",
		PleaseRefreshThePage: "Please refresh the page",
		DataConnectionError: "Data connection error"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		performTranslation(pageText, setPageText);
	}, [userLanguage]); // Depend on pageText to trigger re-translation
	useEffect(() => {
		posthog.capture("instage_socketErrorModal_Shown", {
			connectionStatus,
			deepgramStatus
		});
	}, []); // This empty dependency array ensures the effect runs only once when the component mounts

	useEffect(() => {
		if (connectionStatus === connectionStatusOptions.Connected && deepgramStatus === connectionStatusOptions.Connected) {
			setShowSocketErrorModal(false);
		}
	}, [connectionStatus, deepgramStatus]);
	return (
		<ModalWrapper closeModal={() => setShowSocketErrorModal(true)}>
			<div className={s2.modalAnimated}>
				{/* <span className={s2.modalCloseIcon}>
					<IoClose title="Close icon" onClick={() => setShowSocketErrorModal(false)} />
				</span> */}
				<span className={s2.modalCloseIcon}>
					<IoMdRefresh title="Close icon" onClick={() => refreshPage()} />
				</span>
				<div className={s2.modalTextContainer}>
					{showAudioError && !showDataError && (
						<h1 className={s2.modalSubtitle} style={{ margin: "0" }}>
							{pageText.AudioConnectionError} - {pageText.PleaseRefreshThePage}
						</h1>
					)}
					{showDataError && (
						<h1 className={s2.modalSubtitle} style={{ margin: "0" }}>
							{pageText.DataConnectionError} - {pageText.PleaseRefreshThePage}
						</h1>
					)}

					{/* <p>socket {connectionStatus}</p>
				<p>deepgram {deepgramStatus}</p> */}
				</div>
			</div>
		</ModalWrapper>
	);
}
export function ResumeSimModal({ audioContext, initializeAudioContext, onLoadingDone, setShowResumeSimModal }) {
	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		AreYouReady: "Are you ready?",
		Yes: "Yes"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		performTranslation(pageText, setPageText);
	}, [userLanguage]); // Depend on pageText to trigger re-translation
	async function resume() {
		console.log("Resuming simulation");
		onLoadingDone(true);
		setShowResumeSimModal(false);
	}
	return (
		<div className={s2.modalFillContainer}>
			<div className={s2.new_modal}>
				<IoClose className={s2.new_modalCloseIcon} title="Close icon" onClick={() => resume()} />
				<div className={s2.new_modalIconContainer}>
					<FiPlay className={s2.new_modalIcon} />
				</div>
				<div className={s2.modalTextContainer}>
					<h1 className={s2.new_modalTitle} style={{}}>
						{pageText.AreYouReady}?
					</h1>
				</div>
				<div className={s2.new_modalBtnContainer} style={{ width: "50%", margin: "0 auto" }}>
					<button
						type="button"
						className={s2.new_modalConfirmBtn}
						onClick={() => {
							if (audioContext.current == null) {
								initializeAudioContext();
							}
							// console.log(`audioContext State ${audioContext.current.state}`);
							if (audioContext.current.state === "suspended") {
								audioContext.current
									.resume()
									.then(() => {
										resume();
									})
									.catch((err) => {
										console.error(err);
										resume();
									});
							} else {
								resume();
							}
						}}
					>
						{pageText.Yes}
					</button>
				</div>
			</div>
		</div>
	);
}

export function ProductTourModal({ setShowPTModal, content }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { adminAccess } = useContext(AppContext);

	const [theTitle, setTheTitle] = useState(content[0]?.title || "");
	const [theDescription, setTheDescription] = useState(content[0]?.description || "");
	const [theVideoLink, setTheVideoLink] = useState(content[0]?.videoLink || "");
	const [selectedDot, setSelectedDot] = useState(0);
	const [adminOrNot, setAdminOrNot] = useState(content[0]?.adminOrNot || false);

	const [updateText, setUpdateText] = useState(false);

	const { setPlayingVideoIndex, VideoRef } = useContext(VideoContext);

	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Continue: "Continue",
		Title: content[0]?.title || "",
		Description: content[0]?.description || "",
		AdminOrNot: content[0]?.adminOrNot || ""
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [dynamicPageTextTitle, setDynamicPageTextTitle] = useState({});
	const [dynamicPageTextDescription, setDynamicPageTextDescription] = useState({});
	const [dynamicPageTextAdminOrNot, setDynamicPageTextAdminOrNot] = useState({});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	// Static Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage, selectedDot, content]);

	useEffect(() => {
		setHasTranslatedText(false);
	}, [selectedDot]);

	useEffect(() => {
		// console.log("TranslatedModal");
	}, [updateText]);

	useEffect(() => {
		// console.log(content);
		if (content.length === 0) {
			setShowPTModal(false);
		}
	}, [content]);

	useEffect(() => {
		async function translateDynamic() {
			const translationTitleArray = [content[0]?.title, content[1]?.title, content[2]?.title];
			const translationDescriptionArray = [content[0]?.description, content[1]?.description, content[2]?.description];
			const translationAdminOrNotArray = [content[0]?.adminOrNot, content[1]?.adminOrNot, content[2]?.adminOrNot];
			if (userLanguage !== "en") {
				setDynamicPageTextTitle(await translateDictText(translationTitleArray, userLanguage));
				setDynamicPageTextDescription(await translateDictText(translationDescriptionArray, userLanguage));
				setDynamicPageTextAdminOrNot(await translateDictText(translationAdminOrNotArray, userLanguage));
			} else {
				setDynamicPageTextTitle(translationTitleArray);
				setDynamicPageTextDescription(translationDescriptionArray);
				setDynamicPageTextAdminOrNot(translationAdminOrNotArray);
			}
		}
		translateDynamic();
	}, [content, selectedDot]);

	const playVideo = (videoUrl, index) => {
		VideoRef.current.src = videoUrl;
		setPlayingVideoIndex(index);
	};

	const updateContent = (item, index) => {
		setTheVideoLink(item.videoLink);
		setTheTitle(item.title);
		setTheDescription(item.description);
		setSelectedDot(index);
		setAdminOrNot(item.adminOrNot);

		const updatedLanguageDict = {
			Continue: "Continue",
			Title: item.title || "",
			Description: item.description || "",
			AdminOrNot: item.adminOrNot || ""
		};

		setUpdateText((prevUpdateText) => !prevUpdateText);

		// Update pageText state to trigger re-translation
		setPageText((prevPageText) => ({
			...prevPageText,
			...updatedLanguageDict
		}));
	};

	// Inline styles
	const modalStyle = {
		position: "relative",
		backgroundColor: "#fff",
		borderRadius: "8px",
		overflow: "hidden",
		width: "55vw",
		maxWidth: "40rem"
	};

	return (
		<ModalWrapper closeModal={() => setShowPTModal(false)}>
			<div className={s2.modalAnimated} style={modalStyle}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={s2.modalHeaderIconContainer}>
					<RxQuestionMarkCircled className={s2.modalHeaderIcon} />
				</div>
				<IoClose className={s2.modalCloseIcon} style={{ color: "#D92D20" }} title="Close icon" onClick={() => setShowPTModal(false)} />

				<div className={s2.modalTextContainer}>
					<div style={{ marginBottom: "9rem" }}>
						{/* <PopupVideoPlayer/> */}
						<PopupVideoPlayer videoLink={theVideoLink} />
					</div>

					<h2 className={s2.modalTitle}>{dynamicPageTextTitle[selectedDot]}</h2>
					<h2 style={{ fontSize: "12px", color: "#00a9af" }}>{dynamicPageTextAdminOrNot[selectedDot]}</h2>
					{/* {content[selectedDot].adminOrNot ?
							<h2 style={{fontSize: '12px', color: '#00a9af'}}>(Admin)</h2> :
							<h2 style={{fontSize: '12px', color: '#00a9af'}}>(Admin/Member)</h2>
						} */}

					<p className={s2.modalText}>{dynamicPageTextDescription[selectedDot]}</p>
					<div className={s2.modalDotsContainer}>
						{content.map((item, index) => (
							<span
								key={index}
								className={`${s2.modalDot} ${selectedDot === index ? s2.selectedModalDot : ""}`}
								onClick={() => updateContent(item, index)}
							>
								<GoDotFill />
							</span>
						))}
					</div>
				</div>
				<div className={s2.modalBtnContainer} style={{ marginTop: "1rem" }}>
					<button
						type="button"
						className={s2.modalConfirmBtn}
						onClick={() => {
							if (selectedDot < content.length - 1) {
								updateContent(content[selectedDot + 1], selectedDot + 1);
							} else {
								setShowPTModal(false);
							}
						}}
					>
						{pageText.Continue}
					</button>
				</div>
			</div>
		</ModalWrapper>
	);
}

function getWeek() {
	const now = new Date();
	const dayOfWeek = now.getDay(); // 0 (Sunday) to 6 (Saturday)
	const diff = now.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // if it's Sunday, we count back 6 days to get to last Monday
	const lastMonday = new Date(now.setDate(diff));
	const dayOfMonthForLastMonday = lastMonday.getDate(); // get the day of the month

	return dayOfMonthForLastMonday;
}

export const useSendScore = () => {
	const { axiosLimitedPost } = useAxiosLimited();

	const sendScore = async (selectedPromptId, status, name) => {
		const data = {
			pl_request_id: selectedPromptId,
			score: status ? 100 : 0,
			metadata: {
				date: new Date().toISOString().split("T")[0]
			},
			...(name && { name })
		};

		try {
			await axiosLimitedPost(`${url}/api/promptlayer/score`, data, 1, {}, 30000).then((resp) => {
				// console.log(resp.data);
			});
		} catch (err) {
			console.log(err);
		}
	};

	return sendScore;
};

export const useSendFeedback = () => {
	const { axiosLimitedPost } = useAxiosLimited();

	const sendFeedback = async (selectedPromptId, feedbackText) => {
		const data = {
			pl_request_id: selectedPromptId,
			metadata: {
				date: new Date().toISOString().split("T")[0],
				[`${feedbackText.feedbackTextKey}`]: feedbackText.feedbackTextValue
			}
		};

		try {
			await axiosLimitedPost(`${url}/api/promptlayer/feedback`, data, 1, {}, 30000).then((resp) => {
				// console.log(resp.data);
			});
		} catch (err) {
			console.log(err);
		}
	};

	return sendFeedback;
};

export function DialogueFeedbackModal({ index, session_id, instage_id, metadata, isAdmin, setShowFeedbackModal, feedbackStatus, selectedPromptId }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const [noSenseToggle, setNoSenseToggle] = useState(false);
	const [unFinishedSpeechToggle, setUnFinishedSpeechToggle] = useState(false);
	const [feedbackText, setFeedbackText] = useState("");

	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		WhatDidYouLikeAboutThisResponse: "What did you like about this response?",
		WhatWouldBeABetterAIResponseHerePleaseProvideAnIdealResponse: "What would be a better AI response here? Please provide an ideal response.",
		UnfinishedSpeech: "Unfinished Speech",
		ThisDidntMakeSense: "This Didn't Make Sense",
		Submit: "Submit"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	// function to send feedback to the backend
	const sendDialogueFeedback = async () => {
		const now = new Date();

		const data = {
			pl_request_id: selectedPromptId,
			metadata: {
				instage_id,
				date: `${now.getFullYear()}-${getWeek()}`,
				isAdmin,
				feedbackMessage: feedbackText,
				noSense: noSenseToggle,
				unFinishedSpeech: unFinishedSpeechToggle,
				questionType: metadata.question?.type,
				responseType: metadata.responseType,
				personality: metadata.personality,
				characterName: metadata.audience,
				session_id,
				index
			}
		};

		// console.log("fake send", data);
		await axiosLimitedPost(`${url}/api/promptlayer/feedback`, data, 1, {}, 30000).then((resp) => {
			console.log(resp.data);
		});

		setShowFeedbackModal(false);
	};

	// console.log("feedback modal render")
	return (
		<ModalWrapper closeModal={() => setShowFeedbackModal(false)}>
			<div style={{ width: "800px" }}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={s2.modalHeaderIconContainer}>
					<VscFeedback className={s2.modalHeaderIcon} />
				</div>
				<IoClose className={s2.modalCloseIcon} style={{ color: "#D92D20" }} title="Close icon" onClick={() => setShowFeedbackModal(false)} />
				<div className={s2.modal}>
					<h2 className={s2.modalTitle}>Please Provide Your Feedback Here</h2>
					<textarea
						className={s2.modalTextarea}
						placeholder={
							feedbackStatus
								? pageText.WhatDidYouLikeAboutThisResponse
								: pageText.WhatWouldBeABetterAIResponseHerePleaseProvideAnIdealResponse
						}
						onChange={(e) => {
							setFeedbackText(e.target.value);
							// handleModalChange(e, "due_date");
						}}
					/>
					{!feedbackStatus && (
						<>
							<div style={{ marginTop: "20px" }}>
								<input
									style={{ marginTop: "20px" }}
									type="checkbox"
									id="unfinishedSpeech_toggle"
									checked={unFinishedSpeechToggle}
									onChange={() => setUnFinishedSpeechToggle(!unFinishedSpeechToggle)}
								/>
								<label htmlFor="unfinishedSpeech_toggle">{pageText.UnfinishedSpeech}</label>
							</div>
							<div style={{ marginTop: "20px" }}>
								<input
									type="checkbox"
									id="noSense_toggle"
									checked={noSenseToggle}
									onChange={() => setNoSenseToggle(!noSenseToggle)}
								/>
								<label htmlFor="noSense_toggle">{pageText.ThisDidntMakeSense}</label>
							</div>
						</>
					)}
					<div className={s2.modalBtnContainer} style={{ marginTop: "1rem" }}>
						<button
							type="button"
							className={s2.modalConfirmBtn}
							onClick={() => {
								// Save the information
								sendDialogueFeedback();
							}}
						>
							{pageText.Submit}
						</button>
					</div>
				</div>
			</div>
		</ModalWrapper>
	);
}

export function GptAssistFeedbackModal({ setShowFeedbackModal, feedbackName, feedbackStatus, selectedPromptId }) {
	const sendFeedback = useSendFeedback();
	const [feedbackText, setFeedbackText] = useState("");

	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		PleaseProvideYourFeedbackHere: "Please provide your feedback here",
		Submit: "Submit",
		WhatDidYouLikeAboutThisResponse: "What did you like about this response?",
		WhatWouldBeABetterAIResponseHerePleaseProvideAnIdealResponse: "What would be a better AI response here? Please provide an ideal response."
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	return (
		<ModalWrapper closeModal={() => setShowFeedbackModal(false)}>
			<div style={{ width: "800px" }}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={s2.modalHeaderIconContainer}>
					<VscFeedback className={s2.modalHeaderIcon} />
				</div>
				<IoClose className={s2.modalCloseIcon} style={{ color: "#D92D20" }} title="Close icon" onClick={() => setShowFeedbackModal(false)} />
				<div className={s2.modal}>
					<h2 className={s2.modalTitle}>{pageText.PleaseProvideYourFeedbackHere}</h2>
					<textarea
						className={s2.modalTextarea}
						placeholder={
							feedbackStatus
								? pageText.WhatDidYouLikeAboutThisResponse
								: pageText.WhatWouldBeABetterAIResponseHerePleaseProvideAnIdealResponse
						}
						onChange={(e) => {
							setFeedbackText(e.target.value);
						}}
					/>
					<div className={s2.modalBtnContainer} style={{ marginTop: "1rem" }}>
						<button
							type="button"
							className={s2.modalConfirmBtn}
							onClick={() => {
								// Save the information
								sendFeedback(selectedPromptId, { feedbackTextKey: feedbackName, feedbackTextValue: feedbackText });
								setShowFeedbackModal(false);
							}}
						>
							{pageText.Submit}
						</button>
					</div>
				</div>
			</div>
		</ModalWrapper>
	);
}
export function ReplayConfirmModal({
	title = "Go back to this point ",
	subTitle = "Your progress after this point will be lost",
	setShowConfirmModal,
	handleModalCancel,
	handleModalConfirm
}) {
	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Note: "Note: Audio & Video Recording will restart at the retry point",
		Cancel: "Cancel",
		Confirm: "Confirm"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);
	return (
		<ModalWrapper closeModal={() => setShowConfirmModal(false)}>
			<div className={s2.modal}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={`${s2.modalHeaderIconContainer} ${s2.modalWarningIconContainer}`}>
					<FiLogOut className={s2.modalHeaderIcon} />
				</div>
				<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowConfirmModal(false)} />
				<div className={s2.modalTextContainer}>
					<h1 className={s2.modalTitle}>{title}</h1>
					<h2 className={s2.modalText}>{subTitle}</h2>
					<p className={s2.modalNote}>{pageText.Note}</p>
				</div>
				<div className={s2.modalBtnContainer}>
					<button type="button" className={s2.modalCancelBtn} onClick={() => handleModalCancel()}>
						{pageText.Cancel}
					</button>
					<button type="button" className={s2.modalWarningBtn} onClick={() => handleModalConfirm()}>
						{pageText.Confirm}
					</button>
				</div>
			</div>
		</ModalWrapper>
	);
}
export function ConfirmModal({ setShowConfirmModal, handleModalCancel, handleModalConfirm }) {
	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		AreYouSure: "Are you sure you want to end the simulation?",
		Yes: "Yes",
		Cancel: "Cancel",
		Confirm: "Confirm"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	return (
		<ModalWrapper closeModal={() => setShowConfirmModal(false)}>
			<div className={s2.modal}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={`${s2.modalHeaderIconContainer} ${s2.modalWarningIconContainer}`}>
					<FiLogOut className={s2.modalHeaderIcon} />
				</div>
				<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowConfirmModal(false)} />
				<div className={s2.modalTextContainer}>
					<h1 className={s2.modalTitle}>{pageText.AreYouSure}</h1>
				</div>
				<div className={s2.modalBtnContainer}>
					<button type="button" className={s2.modalCancelBtn} onClick={() => handleModalCancel()}>
						{pageText.Cancel}
					</button>
					<button type="button" className={s2.modalWarningBtn} onClick={() => handleModalConfirm()}>
						{pageText.Confirm}
					</button>
				</div>
			</div>
		</ModalWrapper>
	);
}

export function ConfirmGenerateReportModal({ setShowConfirmModal, handleModalCancel, handleModalConfirm }) {
	const { userLanguage, translateDictText, performTranslation, localUser } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Warning: "Continuing now may result in loss of video and audio.",
		Yes: "Continue",
		Cancel: "Cancel",
		Confirm: "Confirm",
		PleaseWait: "Please wait for the upload process to complete."
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	return (
		<ModalWrapper closeModal={() => setShowConfirmModal(false)}>
			<div className={s2.modal}>
				<img src={circles} alt="Circles" className={s2.modalCircles} />
				<div className={`${s2.modalHeaderIconContainer} ${s2.modalWarningIconContainer}`}>
					<FiLogOut className={s2.modalHeaderIcon} />
				</div>
				<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowConfirmModal(false)} />
				<div className={s2.modalTextContainer}>
					<h1 className={s2.modalTitle}>{pageText.PleaseWait}</h1>
				</div>
				<p className={s2.modalNote}>{pageText.Warning}</p>
				<div className={s2.modalBtnContainer}>
					<button type="button" className={s2.modalCancelBtn} onClick={() => handleModalCancel()}>
						{pageText.Cancel}
					</button>
					<button
						type="button"
						className={s2.modalWarningBtn}
						onClick={() => {
							handleModalConfirm();
							posthog?.capture("instage_generate_report_anyways_modal_confirm", {
								instage_id: localUser?.id || ""
							});
						}}
					>
						{pageText.Confirm}
					</button>
				</div>
			</div>
		</ModalWrapper>
	);
}

export function MobileModal() {
	const [showModal_isMobile, setShowModal_isMobile] = useState(false);
	// On first render, check for mobile device and show modal if true
	useEffect(() => {
		if (isMobile()) {
			// setTimeout(() =>
			// {
			setShowModal_isMobile(true);
			// }, 2000);
		}
	}, []);

	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		TryMobile: "Try Mobile"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		performTranslation(pageText, setPageText);
	}, [userLanguage]); // Depend on pageText to trigger re-translation

	return (
		<>
			{showModal_isMobile && (
				<ModalWrapper closeModal={() => setShowModal_isMobile(false)}>
					<div className={s2.modal}>
						<img src={circles} alt="Circles" className={s2.modalCircles} />
						<div className={`${s2.modalHeaderIconContainer} ${s2.modalWarningIconContainer}`}>
							<MdWarning className={s2.modalHeaderIcon} />
						</div>
						<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowModal_isMobile(false)} />
						<div className={s2.modalTextContainer}>
							<p className={s2.modalTitle}>Desktop browser recommended for better experience.</p>
						</div>
						<div className={s2.modalBtnContainer} style={{ padding: "0 25%" }}>
							<button type="button" className={s2.modalWarningBtn} onClick={() => setShowModal_isMobile(false)}>
								{pageText.TryMobile}
							</button>
						</div>
						<p className={s2.modalNote}>Headphones required on mobile</p>
					</div>
				</ModalWrapper>
			)}
		</>
	);
}
export function BrowserSupportModal() {
	const [showModal_isSupported, setShowModal_isSupported] = useState(false);

	const { userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		YourBrowserIsNotSupported: "Your browser is not supported.",
		ProceedAnyway: "Proceed Anyway",
		YouMayExperienceSomeTechnicalGlitches: "You may experience some technical glitches.",
		ForTheBestExperiencePleaseUseChromeSafariOrEdge: "For the best experience please use Chrome, Safari or Edge"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	// On first render, check for mobile device and show modal if true
	useEffect(() => {
		if (!isSupportedBrowser() && !isMobile()) {
			// setTimeout(() =>
			// {
			setShowModal_isSupported(true);
			// }, 2000);
		}
		// setShowModal_isSupported(true);
	}, []);

	return (
		<>
			{showModal_isSupported && (
				<ModalWrapper closeModal={() => setShowModal_isSupported(false)}>
					<div className={s2.modal}>
						<img src={circles} alt="Circles" className={s2.modalCircles} />
						<div className={`${s2.modalHeaderIconContainer} ${s2.modalWarningIconContainer}`}>
							<MdWarning className={s2.modalHeaderIcon} />
						</div>
						<IoClose className={s2.modalCloseIcon} title="Close icon" onClick={() => setShowModal_isSupported(false)} />
						<div className={s2.modalTextContainer}>
							<p className={s2.modalTitle}>{pageText.YourBrowserIsNotSupported} </p>
						</div>
						<div className={s2.modalBtnContainer} style={{ padding: "0 25%" }}>
							<button type="button" className={s2.modalWarningBtn} onClick={() => setShowModal_isSupported(false)}>
								{pageText.ProceedAnyway}
							</button>
						</div>
						<p className={s2.modalNote}>{pageText.YouMayExperienceSomeTechnicalGlitches}</p>
						<p className={s2.modalNote}>{pageText.ForTheBestExperiencePleaseUseChromeSafariOrEdge}</p>
					</div>
				</ModalWrapper>
			)}
		</>
	);
}
