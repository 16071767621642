import React, { useEffect, useRef, useCallback, useContext } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";

function ModalWrapper({ closeModal, children, maxWidth }) {
	const { runTranslation } = useContext(AppContext);

	const modalRef = useRef(null);
	const closeOuter = useCallback(
		(e) => {
			if (modalRef.current && e.target === modalRef.current.parentNode) {
				closeModal();
			}
		},
		[closeModal]
	);

	useEffect(() => {
		window.addEventListener("mousedown", closeOuter);

		return () => window.removeEventListener("mousedown", closeOuter);
	}, [closeOuter]);
	// useEffect(() => {
	// 	runTranslation();
	// });
	return (
		<Modal>
			<div
				ref={modalRef}
				style={{
					position: "relative",
					margin: "0 auto",
					borderRadius: "5px",
					cursor: "auto",
					background: "transparent",
					// width: "100%",
					minWidth: "17rem",
					// maxWidth: maxWidth || (window.innerWidth > 1900 ? "40%" : window.innerWidth < 1024 ? "96%" : "60%"),
					overflow: "hidden"
				}}
			>
				{children}
			</div>
		</Modal>
	);
}

ModalWrapper.propTypes = {
	children: PropTypes.node.isRequired,
	closeModal: PropTypes.func.isRequired,
	maxWidth: PropTypes.string
};

export default ModalWrapper;
