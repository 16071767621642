import React from "react";
import { Helmet } from "react-helmet";
import a from "../../styleModules/signupStyles.module.css";
import logo from "../../images/logoLarge.png";

export default function SignUpPage() {
	return (
		<div>
			<Helmet>
				<title>Sign Up Confirmed - InStage</title>
				<meta name="description" content="Sign up confirmed page" />
				<meta name="robots" content="noindex,nofollow" />
			</Helmet>
			<div className={a.confirmedPage}>
				<div className={a.confirmedWrapper}>
					<div className={a.confirmedContainer}>
						<img className={a.confirmedLogo} src={logo} alt="logo" />
						<h1 className={a.confirmedTitle}>Thanks for signing up!</h1>
						<p className={a.confirmedText} style={{ marginBottom: "0" }}>
							Please check your inbox to activate your account.
						</p>
						<p className={a.confirmedText} style={{ marginTop: "0" }}>
							If you don't receive the activation email within a few minutes, please check your spam or junk folder.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
