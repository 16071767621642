import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import ReactDOM from "react-dom/client";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { FiChevronRight, FiX, FiDownload, FiMessageSquare, FiUser, FiEdit, FiEdit2, FiInfo, FiRotateCcw } from "react-icons/fi/index.esm.js";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { MdOndemandVideo } from "react-icons/md/index.esm.js";
import { usePostHog } from "posthog-js/react";
import { RiDownload2Fill, RiDeleteBin5Line, RiVolumeUpFill, RiVolumeMuteFill } from "react-icons/ri/index.esm.js";
import { openDB } from "idb";
import { AppContext, CommentContext, VideoContext } from "../UtilityFunctions/AppContext.js";
import { PLANS } from "../../plans.config.js";
import { deobfuscate } from "../UtilityFunctions/DataUtility.js";
import r from "../../styleModules/reportStyles.module.css";
import { CommentModal, CommentIcon } from "../SecondaryComponents/CommentModal.js";
import { PostSessionModal } from "../SecondaryComponents/PostSessionModal.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import TypingText from "../UtilityFunctions/TypingText.js";
import { getAudio } from "../SimulationOld/lipsyncUtility.js";
import { characterDict } from "../SimulationOld/characterDict.js";
import { useAxiosLimited, axiosRetryGet, axiosRetryPost, axiosRetryPut, axiosRetryPatch, axiosRetryDelete } from "../UtilityFunctions/axiosRetry.js";
import ReportSection from "./ReportSection.js";
import { AssignmentModal, ProductTourModal } from "../Setup/SetupElements/SetupModals.js";
import { cleanResponse } from "../UtilityFunctions/cleanResponse.js";
import { Metrics } from "./Metrics.js";
import { useSpeechSynthesizer } from "../SecondaryComponents/useSpeechSynthesizer";
import AvatarSpeechData from "../Classes/AvatarSpeechData";
import AvatarGptData from "../Classes/AvatarGptData";
import UserTranscriptData from "../Classes/UserTranscriptData";
import { sleep } from "../UtilityFunctions/sleep.js";
import { CreateEventHistory, CreateTranscriptV3, CreateChatHistoryV3 } from "../UtilityFunctions/CreateEventHistory.js";
import { similarityPercentageLevenshtein, compareStringsUsingCosine, compareStringsUsingNGrams } from "../UtilityFunctions/textCompare.js";
import usePollingIntervals from "../UtilityFunctions/usePollingIntervals.js";
import { getReportTourContent } from "../UtilityFunctions/getProductTourContent.js";
import styles from "../../styleModules/generateStyles.module.css";
import usePageVisitTracker from "../SecondaryComponents/usePageVisitTracker";
import { getEmptySims } from "../Admin/SimulationList.js";
import PopupVideoPlayer from "../Setup/SetupElements/PopupVideoPlayer.js";
import { LuAlertTriangle } from "react-icons/lu";

async function initDB() {
	return openDB("AccountDB", 1, {
		upgrade(db) {
			db.createObjectStore("account");
		}
	});
}

async function initSimulationDB() {
	return openDB("simulation", 1, {
		upgrade(db) {
			if (!db.objectStoreNames.contains("simulation_db")) {
				db.createObjectStore("simulation_db");
			}
		}
	});
}

function stripSpacesAndLowercase(str) {
	if (str === undefined || str === null) {
		return null;
	}
	// Remove spaces from the string
	const stripped = str.replace(/\s/g, "");

	// Convert the string to lowercase
	const lowercase = stripped.toLowerCase();

	return lowercase;
}

function Report({ setActiveReport, activeReportTab, setActiveReportTab, obfuscated }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const {
		user,
		localUser,
		adminAccess,
		setRunTour,
		showSidebar,
		setShowSidebar,
		setSidebarContent,
		tenantPlan,
		customFeatures,
		showLockedModal,
		runTranslation,
		userLanguage,
		translateDictText,
		performTranslation,
		handleNavigate
	} = useContext(AppContext);
	const { VideoRef, playingVideoIndex, setPlayingVideoIndex, setVideoDownloadLink } = useContext(VideoContext);

	// Data states
	const [sessionData, setSessionData] = useState();
	const [incomplete, setIncomplete] = useState(false);

	const [RenderedText, setRenderedText] = useState(false);

	// Static Translation
	const LocalLanguageDict = {
		ReportInterviewFor: "Report: Interview for",
		ReportDiscoveryMeeting: "Report: Discovery Meeting",
		ReportClosingMeeting: "Report: Closing Meeting",
		ReportRetailSalesMeeting: "Report: Retail Sales Meeting",
		ReportPresentationOn: "Report: Presentation on",
		ReportSelfIntroductionTo: "Report: Self-Introduction to",
		ReportFreestyleConversationWith: "Report: Freestyle Conversation with",
		ReportPatientInterview: "Report: Patient Interview",
		ReportElevatorPitch: "Report: Elevator Pitch",
		ReportBusinessPitch: "Report: Business Pitch",
		ReportReflectionOn: "Report: Reflection on",
		Report: "Report",
		Overall: "Overall",
		QuestionSummary: "Question Summary",
		Checklist: "Checklist",
		Process: "Process",
		Questions: "Questions",
		Summary: "Summary",
		Competencies: "Competencies",
		Communication: "Communication",
		Incomplete: "Incomplete",
		TheConversationWasInterrupted: "The conversation was interrupted.",
		ForAssignmentInstructions: "For assignment instructions, ",
		ClickHere: "click here",
		SlowInternetMayAffectAudioAndVideoUploads: "Slow internet detected: Audio & Video may be affected."
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	// const sessionDataChatHistory = sessionData?.chat_history;
	// useEffect(() => {
	// 	setHasTranslatedText(false);
	// }, [sessionDataChatHistory]);

	const { setPollingInterval, clearPollingInterval } = usePollingIntervals();

	const isSuperAdmin = window.location.href.includes("super-admin");
	const isPreview = window.location.href.includes("preview");

	// PRODUCT TOUR
	const [showInfo_instructions, setShowInfo_instructions] = useState(false);
	const [showPTModal, setShowPTModal] = useState(false);

	// GLOBAL VARIABLES
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;
	const { obfuscated_gpt_session_id, gpt_session_id } = useParams();
	let real_session_id;
	let manager;
	if (obfuscated_gpt_session_id) {
		real_session_id = deobfuscate(obfuscated_gpt_session_id);
		manager = false;
	} else {
		real_session_id = gpt_session_id;
		manager = true;
	}
	// if real_session_id contains any non-integer values, remove them so that it can be parsed as an integer
	real_session_id = real_session_id.replace(/\D/g, "");
	const typingRefs = useRef(null);

	// Tab states
	const [tabList, setTabList] = useState([]);

	// Modal states
	const [showEmailModal, setShowEmailModal] = useState(false);
	const [showAssignmentModal, setShowAssignmentModal] = useState(false);

	const defaultAssignmentData = {
		assignment_type: "",
		assignment_name: "",
		due_date: "",
		submission_instructions: "",
		assignment_instructions: "",
		passing_grade: 50,
		can_students_see_grade: false
	};
	const [assignmentData, setAssignmentData] = useState(defaultAssignmentData);

	// Toggle states

	const [speechMetrics, setSpeechMetrics] = useState(null);

	const [reportDeleted, setReportDeleted] = useState(false);
	const [reportError, setReportError] = useState(false);

	const [reportForbidden, setReportForbidden] = useState(false);
	const [reportNotFound, setReportNotFound] = useState(false);

	const { checkPageVisit } = usePageVisitTracker();

	const posthog = usePostHog();

	useEffect(() => {
		if (isPreview && sessionData && sessionData.chat_history?.filter((message) => message.role === "user").length >= 3) {
			setRunTour(true);
		}
	}, [sessionData, isPreview]);

	useEffect(() => {
		runTranslation();
		// console.log("Run Translation");
	}, [userLanguage, activeReportTab]);

	useEffect(() => {
		const checkVisit = async () => {
			const isFirstVisit = await checkPageVisit("report");

			if (isFirstVisit) {
				// console.log("Welcome back to this page");
			} else {
				// console.log("This is your first visit to this page.");
				if (!isPreview) {
					setShowPTModal(true);
					setShowInfo_instructions(true);
				}
			}
		};

		checkVisit();
	}, [checkPageVisit, isPreview]);

	useEffect(() => {
		setIncomplete(false);

		if (real_session_id) {
			// Reset all data states
			setSessionData();

			// Fetch session data for this session
			fetchSessionData();
		} else {
			console.error("Invalid session ID provided.");
			setReportNotFound(true);
		}

		setSidebarContent("ReportsView");
		setShowSidebar(window.innerWidth > 1000);

		return () => {
			setActiveReport();
			setReportForbidden(false);
		};
	}, [real_session_id]);

	// Add useEffect to update tabs after pageText is updated
	useEffect(() => {
		if (!sessionData) {
			return;
		}
		// console.log("pageText Translated", pageText);
		let reportTabs = [{ id: "overall", title: pageText.Overall }];
		switch (sessionData.setup_type) {
			case "interview":
				reportTabs.push({ id: "question_summary", title: pageText.QuestionSummary });
				break;
			case "intro":
				reportTabs.push({ id: "process", title: pageText.Checklist });
				break;
			case "discovery":
			case "presentation":
				reportTabs.push({ id: "process", title: pageText.Process });
				reportTabs.push({ id: "question_responses", title: pageText.Questions });
				break;
			case "retail":
				reportTabs.push({ id: "question_responses", title: pageText.Questions });
				break;
			case "discovery_spin":
			case "closing":
			case "patient":
				reportTabs.push({ id: "process", title: pageText.Process });
				break;
			case "reflection":
				reportTabs = reportTabs.filter((tab) => tab.id !== "overall");
				reportTabs.push({ id: "summary", title: pageText.Summary });
				if (sessionData.competency_list?.length > 0) {
					reportTabs.push({ id: "competencies", title: pageText.Competencies });
				}
				break;
			default:
				break;
		}
		reportTabs.push({ id: "communication", title: pageText.Communication });
		setTabList(reportTabs);
		setActiveReportTab(reportTabs[0]);
	}, [pageText, sessionData]);

	const fetchSessionData = async () => {
		setAssignmentData(defaultAssignmentData);
		try {
			let l_sessionData;

			try {
				l_sessionData = await getSessionData();
				// console.log("sessionData", l_sessionData);
			} catch (error) {
				if (error.status) {
					console.error(error.status);
					// if (error.status === 404) {
					// 	setReportDeleted(true);
					// 	return;
					// }
					if (error.status === 500) {
						console.error("Backend error.  ");
						setReportError(true);
					}
					if (error.status === 403) {
						setReportForbidden(true);
						// send post hog
						try {
							posthog?.capture("instage_forbidden_report", {
								reportURL: window.location.href
							});
							// console.log(posthog, "Forbidden_report event");
						} catch (e) {
							console.error(e);
						}
						console.error("You do not have access to this report.");
					}
				} else {
					console.error(error);
				}

				return;
			}
			console.log("sessionData", l_sessionData);
			if (
				l_sessionData &&
				!l_sessionData.transcript_data &&
				!l_sessionData.gpt_data &&
				!l_sessionData.avatar_speech_data &&
				!l_sessionData.chat_history
			) {
				setReportDeleted(true);
				setSessionData(l_sessionData);
				return;
			}

			axiosLimitedGet(`${url}/api/saved/simulation`, 1, { params: { simulation_id: l_sessionData.simulation_fk } })
				.then((response) => {
					// console.log("saved simulation", response.data)
					setAssignmentData({
						creator_instage_id: response.data.instage_id || "",

						assignment_type: response.data.assignment_type || "",
						assignment_name: response.data.assignment_name || "",
						due_date: response.data.due_date || "",
						submission_instructions: response.data.submission_instructions || "",
						assignment_instructions: response.data.assignment_instructions || "",
						content_weight:
							response.data.content_weight !== undefined ? response.data.content_weight : defaultAssignmentData.content_weight,
						delivery_weight:
							response.data.delivery_weight !== undefined ? response.data.delivery_weight : defaultAssignmentData.delivery_weight,
						completion_weight:
							response.data.completion_weight !== undefined ? response.data.completion_weight : defaultAssignmentData.completion_weight,
						other_weight: response.data.other_weight !== undefined ? response.data.other_weight : defaultAssignmentData.other_weight,
						require_video_recording: response.data.require_video_recording || false,
						course_options: response.data.course_options || [],
						term_options: response.data.term_options || [],
						section_options: response.data.section_options || [],
						passing_grade: response.data.passing_grade || 50,
						can_students_see_grade: response.data.can_students_see_grade || false
					});

					if (response.data.assignment_type !== undefined) {
						initSimulationDB().then(async (db) => {
							// Retrieve the "hiddenInstructions" object
							const hiddenInstructions = (await db.get("simulation_db", "hiddenInstructions")) || {};
							const shouldShowModal = hiddenInstructions[response.data.simulation_id] !== false;
							setShowAssignmentModal(shouldShowModal);
						});
					}
				})
				.catch((error) => {
					if (error.status) {
						//	console.error(error.status);
					}
				});

			// create audioFile map
			// const audioFileMap = {};
			// const audioFileArray = [];
			// const videoFileArray = [];

			// if (sessionData.speech_data) {
			// 	sessionData.speech_data.forEach((speech) => {
			// 		const key = stripSpacesAndLowercase(speech.transcript);
			// 		audioFileMap[key] = speech.file_name;
			// 		audioFileArray.push(speech.file_name);
			// 		videoFileArray.push(speech.video_file_name);
			// 	});
			// }
			if (l_sessionData.audience[0] == "Azilea") {
				l_sessionData.audience[0] = "Nina";
			}

			// console.log(audioFileMap)
			setSessionData(l_sessionData);
			if (l_sessionData.transcript_data) {
				handleAudioProcessing(real_session_id);
			}
			if (
				l_sessionData.transcript_data === null ||
				l_sessionData.transcript_data === undefined ||
				(l_sessionData.transcript_data.length < 3 &&
					l_sessionData.setup_type !== "presentation" &&
					l_sessionData.setup_type !== "business_pitch")
			) {
				setIncomplete(true);
				return;
			}

			// Load Chat History section
			const currentUserAudioIndex = 0;
			let temp_TranscriptData;
			if (l_sessionData.transcript_data) {
				temp_TranscriptData = l_sessionData.transcript_data.filter(
					(event) => event.startTime !== 0 && event.startTime !== null && event.startTime >= 10000
				);
			}

			await getSpeechMetrics();
		} catch (error) {
			console.log(error);
			console.error(error);
			throw error;
		}
	};
	const startSessionAudioProcessing = async (session_id) => {
		// console.log("Starting session audio processing for session_id: ", session_id);
		const response = await axiosLimitedPost(`${url}/api/sessionData/session/processaudio`, { session_id }, 1);

		if (response.data.message) {
			console.log("Error message from startSessionAudioProcessing: ", response.data.message);
			return false;
		}
		console.log("Response from startSessionAudioProcessing: ", response.data.deepgram_data);
		return response.data.deepgram_data;
	};

	const checkAudioProcessingStatus = async (session_id) => {
		// console.log("Checking audio processing status for session_id: ", session_id);
		const response = await axiosLimitedGet(`${url}/api/sessionData/session/checkAudioStatus`, 2, { params: { session_id } });

		if (response.data.message) {
			//	console.log("Message from checkAudioProcessingStatus: ", response.data.message);
			return false;
		}
		console.log("Response from checkAudioProcessingStatus: ", response.data.deepgram_data);
		return response.data.deepgram_data;
	};

	const handleAudioProcessing = async (session_id) => {
		// console.log("Handling audio processing for session_id: ", session_id);
		const result = await startSessionAudioProcessing(session_id);

		if (result) {
			console.log("Result from startSessionAudioProcessing: ", result);
			setActiveReport((prevState) => ({ ...prevState, deepgram_data: result }));
			setSessionData((prevState) => ({ ...prevState, deepgram_data: result }));

			//	return;
		}

		const maxAttempts = 20;
		let attempts = 0;

		const intervalId = setPollingInterval(async () => {
			if (attempts >= maxAttempts) {
				clearPollingInterval(intervalId);
				// console.log("Max attempts reached. Stopping audio processing check.");
				return;
			}

			const status = await checkAudioProcessingStatus(session_id);
			attempts++;

			if (status) {
				clearPollingInterval(intervalId);
				setSessionData((prevState) => ({ ...prevState, deepgram_data: status }));
				setActiveReport((prevState) => ({ ...prevState, deepgram_data: status }));
				console.log("Audio processing completed.", status);
				// Now you can retrieve the final data if needed
			} else {
				// console.log("Waiting for audio processing to complete...");
			}
		}, 5000);
	};

	const getSessionData = async () => {
		const router = "/api/sessionData/session";

		const params = { session_id: real_session_id, complete: true };
		// console.log("Get session data");
		const resp = await axiosLimitedGet(url + router, 1, { params });
		// console.log("Get session data", resp);
		let { avatar_speech_data } = resp.data;
		if (avatar_speech_data) {
			avatar_speech_data = Object.keys(avatar_speech_data).map((key) => {
				const newData = AvatarSpeechData.fromObject(avatar_speech_data[key]);
				//	console.log(typeof(newData.startTime))
				return newData;
			});
		}

		resp.data.avatar_speech_data = avatar_speech_data;
		let { transcript_data } = resp.data;
		if (transcript_data) {
			transcript_data = Object.keys(transcript_data).map((key) => UserTranscriptData.fromObject(transcript_data[key]));
			resp.data.transcript_data = transcript_data;
		}

		let { gpt_data } = resp.data;
		if (gpt_data) {
			gpt_data = Object.keys(gpt_data).map((key) => AvatarGptData.fromObject(gpt_data[key]));
			resp.data.gpt_data = gpt_data;
		}

		if (transcript_data && gpt_data && avatar_speech_data) {
			const eventHistory = CreateEventHistory(transcript_data, gpt_data, avatar_speech_data);
			// TODO modify for non interview question list
			resp.data.transcriptv3 = CreateTranscriptV3(eventHistory, resp.data.audience, resp.data.job_question_list, resp.data.personality);
			resp.data.chat_history = CreateChatHistoryV3(resp.data.transcriptv3, resp.data.setup_type);
			// console.log({ ...resp.data, eventHistory });
		} else {
			// console.log({ ...resp.data });
		}

		// Load avatar GPT data from the provided object, converting each item to an instance of AvatarGptData
		// avatarGptDataRef.current = Object.keys(avatarGptData).map(key => AvatarGptData.fromObject(avatarGptData[key])) || {};
		// Load user transcript data from the provided object, converting each item to an instance of UserTranscriptData
		// userTranscriptDataRef.current = Object.keys(userTranscriptData).map(key => UserTranscriptData.fromObject(userTranscriptData[key])) || {};
		// setActiveReportId(real_session_id);
		// console.log(resp.data);
		setActiveReport(resp.data);
		// console.log("PageText Used in tabs", pageText);
		// let reportTabs = [{ id: "overall", title: pageText.Overall }];
		// switch (resp.data.setup_type) {
		// 	case "interview":
		// 		reportTabs.push({ id: "question_summary", title: pageText.QuestionSummary });
		// 		break;
		// 	case "intro":
		// 		reportTabs.push({ id: "process", title: pageText.Checklist });
		// 		break;
		// 	case "discovery":
		// 	case "presentation":
		// 		reportTabs.push({ id: "process", title: pageText.Process });
		// 		reportTabs.push({ id: "question_responses", title: pageText.Questions });
		// 		break;
		// 	case "retail":
		// 		reportTabs.push({ id: "question_responses", title: pageText.Questions });
		// 		break;
		// 	case "discovery_spin":
		// 	case "closing":
		// 	case "patient":
		// 		reportTabs.push({ id: "process", title: pageText.Process });
		// 		break;
		// 	case "reflection":
		// 		reportTabs = reportTabs.filter((tab) => tab.id !== "overall");
		// 		reportTabs.push({ id: "summary", title: pageText.Summary });
		// 		if (resp.data.competency_list?.length > 0) {
		// 			reportTabs.push({ id: "competencies", title: pageText.Competencies });
		// 		}
		// 		break;
		// 	default:
		// 		break;
		// }

		// reportTabs.push({ id: "communication", title: pageText.Communication });

		// setTabList(reportTabs);
		// setActiveReportTab(reportTabs[0]);

		return resp.data;
	};

	const getSpeechMetrics = async () => {
		const params = {
			session_id: real_session_id
		};

		const router = "/api/sessionData/session/speech-data";

		try {
			const resp = await axiosLimitedGet(url + router, 1, { params }, 20000);
			console.log(resp.data);
			if (resp.data) {
				setSpeechMetrics(resp.data);
			}
			return resp.data;
		} catch (error) {
			console.error("Error fetching speech metrics:", error);
			// Handle error appropriately, e.g., set an error state or show a notification
		}
	};

	const saveEmail = async (email) => {
		const response = await axiosLimitedPost(`${url}/api/users/updateUser`, { instage_id: localUser.id, field: "email", value: email }, 1);
		// console.log(response.data)
		const db = await initDB();
		await db.put("account", email, "email");
	};

	return (
		<>
			<Helmet>
				<title>InStage | Report</title>
				<meta name="description" content="InStage Report" />
				<meta name="robots" content="noindex" />
			</Helmet>
			<div
				className={r.reportPage}
				style={{
					// padding:
					// 	window.innerWidth > 768 ? (!showSidebar ? "3rem 7rem" : "3rem calc(8rem + 25%) 3rem 7rem") : "inherit"
					padding: window.innerWidth > 1000 ? "3rem calc(8rem + 25%) 3rem 7rem" : "3rem 7rem",
					zIndex: 1
				}}
			>
				<PostSessionModal show={showEmailModal} onHide={() => setShowEmailModal(false)} onSave={(value) => saveEmail(value)} />

				{showAssignmentModal && (
					<AssignmentModal
						isReport
						navigate={handleNavigate}
						localUser={localUser}
						data={assignmentData}
						setData={setAssignmentData}
						setup_type={`${sessionData.setup_type}`}
						// dynamicInputData = {dynamicData}
						setShowAssignmentModal={setShowAssignmentModal}
						// newAssignmentType={newAssignmentType}
						sessionData={sessionData}
					/>
				)}

				<div className={r.reportPageContainer}>
					{!sessionData && !incomplete && !reportDeleted && !reportForbidden && !reportNotFound && !reportError && (
						<div className={r.spinnerContainer}>
							<LoadingSpinner height={50} width={50} thickness={5} color="#00a9af" />
						</div>
					)}

					<div className={r.reportHeadingContainer}>
						{sessionData && (
							<>
								<h1 className={r.reportHeading}>
									<TypingText refHolder={typingRefs}>
										{sessionData.job_title
											? `${pageText.ReportInterviewFor} ${sessionData.job_title}`
											: sessionData.setup_type?.includes("discovery")
											? `${pageText.ReportDiscoveryMeeting} (${sessionData.setup_name})`
											: sessionData.setup_type === "closing"
											? `${pageText.ReportClosingMeeting} (${sessionData.setup_name})`
											: sessionData.setup_type === "retail"
											? `${pageText.ReportRetailSalesMeeting} (${sessionData.setup_name})`
											: sessionData.setup_type === "presentation"
											? `${pageText.ReportPresentationOn} ${sessionData.setup_name}`
											: sessionData.setup_type === "intro"
											? `${pageText.ReportSelfIntroductionTo} ${sessionData.audience_role}`
											: sessionData.setup_type === "freestyle"
											? `${pageText.ReportFreestyleConversationWith} ${sessionData.audience_role}`
											: sessionData.setup_type === "patient"
											? `${pageText.ReportPatientInterview} (${sessionData.setup_name})`
											: sessionData.setup_type === "pitch"
											? `${pageText.ReportElevatorPitch} (${sessionData.setup_name})`
											: sessionData.setup_type === "business_pitch"
											? `${pageText.ReportBusinessPitch} (${sessionData.setup_name})`
											: sessionData.setup_type === "reflection"
											? `${pageText.ReportReflectionOn} ${sessionData.experience_type}`
											: pageText.Report}
									</TypingText>

									<span className={styles.infoIconContainer2}>
										<TypingText refHolder={typingRefs}>
											<span
												className={styles.infoIconHeading}
												onClick={(event) => {
													event.stopPropagation();
													setShowInfo_instructions(true);
													setShowPTModal(true);
												}}
												onKeyDown={(event) => {
													// 'Enter' key or 'Space' key
													if (event.key === "Enter" || event.key === " ") {
														event.preventDefault();
														event.stopPropagation();
														setShowInfo_instructions(true);
														setShowPTModal(true);
													}
												}}
												tabIndex={0} // Make the element focusable
												aria-label="Show Product Tour"
												role="button"
											>
												{!getEmptySims().includes(sessionData.setup_type) && <RxQuestionMarkCircled />}
											</span>
											{showPTModal && !getEmptySims().includes(sessionData.setup_type) && (
												<ProductTourModal
													setShowPTModal={setShowPTModal}
													content={getReportTourContent(sessionData.setup_type)}
												/>
											)}
										</TypingText>
									</span>
								</h1>

								<div className={r.reportSubheading}>
									<div style={{ display: "flex", gap: "1rem" }}>
										<div className={r.reportTypeContainer}>
											<span className={r.reportTypeText}>
												{sessionData.setup_output?.assignment_type ? "ASSIGNMENT REPORT" : "OPEN MODE REPORT"}
											</span>
										</div>
										{sessionData.upload_stuck_events_called &&
											sessionData.stopwatch_duration !== null &&
											sessionData.stopwatch_duration !== undefined &&
											sessionData.stopwatch_duration !== "00:00" &&
											(() => {
												// Convert "MM:SS" format to minutes
												const [minutes, seconds] = (sessionData.stopwatch_duration || "0:00").split(":").map(Number);
												const durationInMinutes = minutes + seconds / 60;

												// Calculate events per minute (2 events/minute is the threshold)
												const eventsPerMinute = sessionData.upload_stuck_events_called / durationInMinutes;
												const EVENTS_PER_MINUTE_THRESHOLD = 20;

												return (
													eventsPerMinute >= EVENTS_PER_MINUTE_THRESHOLD && (
														<div className={r.reportTypeContainerWarning}>
															<span className={r.reportTypeText}>
																<LuAlertTriangle
																	style={{ fontSize: "1.25rem", display: "inline-flex", alignItems: "center" }}
																/>
																{pageText.SlowInternetMayAffectAudioAndVideoUploads}
															</span>
														</div>
													)
												);
											})()}
									</div>
									{sessionData.setup_output?.assignment_type && (
										<div className={r.headingDescription} style={{ margin: "0 0 0" }}>
											<p className={r.headingDescriptionText}>
												<TypingText refHolder={typingRefs}>
													<span>{pageText.ForAssignmentInstructions} </span>
													<span
														className={r.headingDescriptionLink}
														onClick={() => {
															setShowAssignmentModal(true);
														}}
													>
														{pageText.ClickHere}
													</span>
												</TypingText>
											</p>
										</div>
									)}
								</div>

								{sessionData.start_time && (
									<p className={r.reportSubheading}>
										<TypingText refHolder={typingRefs}>
											<span>
												{new Date(sessionData.start_time).toLocaleDateString(`${userLanguage}-US`, {
													year: "numeric",
													month: "long",
													day: "numeric"
												})}{" "}
												-{" "}
												{new Date(sessionData.start_time).toLocaleTimeString(`${userLanguage}-US`, {
													hour: "numeric",
													minute: "numeric",
													second: "numeric",
													hour12: true
												})}
											</span>
											{sessionData.simulation_session_count && <span> | Rep {sessionData.simulation_session_count} </span>}
										</TypingText>
									</p>
								)}
								{sessionData.email && (
									<p className={r.reportSubheading}>
										<TypingText refHolder={typingRefs}>
											{sessionData.email}
											{sessionData?.assignment_details && Object.keys(sessionData.assignment_details).length !== 0 && (
												<>
													<span> | {sessionData.assignment_details.selectedCourse}</span>
													<span> | {sessionData.assignment_details.selectedTerm}</span>
													<span> | {sessionData.assignment_details.selectedSection}</span>
												</>
											)}
										</TypingText>
									</p>
								)}

								<TypingText refHolder={typingRefs}>
									<div className={r.tabContainer}>
										{tabList.map((tab) => (
											<button
												key={tab.id}
												id={tab.id}
												type="button"
												className={`${r.tab} ${activeReportTab.id === tab.id ? r.activeReportTab : ""}`}
												onClick={() => setActiveReportTab(tab)}
												tabIndex={0}
											>
												{tab.title}
											</button>
										))}
									</div>
								</TypingText>
							</>
						)}
					</div>

					{reportError && !reportDeleted && (
						<>
							<div className={r.reportSection}>
								<p className={r.overallText}>
									<span className={r.outcomeText} style={{ color: "#898989" }}>
										<TypingText refHolder={typingRefs}>Error.</TypingText>
									</span>
									<TypingText refHolder={typingRefs}>error.</TypingText>
								</p>
							</div>
						</>
					)}
					{reportForbidden && !reportDeleted && (
						<>
							<div className={r.reportSection}>
								<p className={r.overallText}>
									<span className={r.outcomeText} style={{ color: "#898989" }}>
										<TypingText refHolder={typingRefs}>No Access.</TypingText>
									</span>
									<TypingText refHolder={typingRefs}>
										You do not have access to this report. Log in or ask user to share it.
									</TypingText>
								</p>
								<div
									// className={r.reportSection}
									style={{ maxWidth: "75%", display: "flex", justifyContent: "center", alignItems: "center" }}
								>
									<PopupVideoPlayer videoLink="ReportVideoAccess_Comp.mp4" />
								</div>
							</div>
						</>
					)}
					{reportNotFound && !reportDeleted && (
						<>
							<div className={r.reportSection}>
								<p className={r.overallText}>
									<span className={r.outcomeText} style={{ color: "#898989" }}>
										<TypingText refHolder={typingRefs}>Invalid Link.</TypingText>
									</span>
									<TypingText refHolder={typingRefs}>This url is invalid. Please check the link and try again.</TypingText>
								</p>
							</div>
						</>
					)}
					{sessionData && incomplete && !reportDeleted && (
						<>
							<div className={r.reportSection}>
								<p className={r.overallText}>
									<span className={r.outcomeText} style={{ color: "#898989" }}>
										<TypingText refHolder={typingRefs}>{pageText.Incomplete}:</TypingText>
									</span>
									<TypingText refHolder={typingRefs}>{pageText.TheConversationWasInterrupted}</TypingText>
								</p>
							</div>
						</>
					)}
					{sessionData && reportDeleted && (
						<>
							<div className={r.reportSection}>
								<p className={r.overallText}>
									<span style={{ color: "#898989" }}>
										<TypingText refHolder={typingRefs}>Deleted.</TypingText>
									</span>
									<TypingText refHolder={typingRefs}>This report was deleted.</TypingText>
								</p>
							</div>
						</>
					)}

					{sessionData && !incomplete && !reportDeleted && (
						<ReportSection
							typingRefs={typingRefs}
							activeReportTab={activeReportTab}
							sessionData={sessionData}
							assignmentData={assignmentData}
							speechMetrics={speechMetrics}
							setSpeechMetrics={setSpeechMetrics}
						/>
					)}
				</div>
			</div>
		</>
	);
}

export default Report;

function Action({ isSuperAdmin, playingVideoIndex, setPlayingVideoIndex, VideoRef, index, item, typingRefs, onComplete, setVideoDownloadLink }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const [audioPlaying, setAudioPlaying] = useState(false);
	const [showButton, setShowButton] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	// console.log("actions props",  index, item)
	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	const handleMouseLeave = () => {
		setIsHovered(false);
	};
	const { speak, pause, resume } = useSpeechSynthesizer({ onAudioStart, onAudioEnd });
	function onAudioStart() {
		setAudioPlaying(true);
	}

	function onAudioEnd() {
		setAudioPlaying(false);
	}
	const AudioRef = useRef(null);
	const downloadLink = item?.audio?.replace("Audio2", "Audio");
	const isUser =
		item.content.split(":")[0] === "Sales Rep" ||
		item.content.split(":")[0] === "Interviewee" ||
		item.content.split(":")[0] === "Presenter" ||
		item.content.split(":")[0] === "User";
	const audioValid = item.audio && item.audio !== "" && item.audio !== "error" && item.audio !== undefined && item.audio !== null;
	const videoValid = item.video && item.video !== "" && item.video !== "error" && item.video !== undefined && item.video !== null;
	const [itemContent, setItemContent] = useState(cleanResponse(item.content));
	const [contentLoading, setContentLoading] = useState(false);

	const playAudio = () => {
		if (isUser) {
			if (AudioRef.current) {
				if (AudioRef.current.src === "") {
					AudioRef.current.src = item.audio;
				}
				if (AudioRef.current.paused) {
					setAudioPlaying(true);
					AudioRef.current.play();
				} else {
					setAudioPlaying(false);
					AudioRef.current.pause();
				}

				AudioRef.current.addEventListener("ended", () => {
					setAudioPlaying(false);
				});
			}
		} else {
			console.log(item);
			setAudioPlaying(true);
			const split = itemContent.split(":");
			speak(0, split[1], split[0]);
		}
	};

	const playVideo = (downloadLink) => {
		if (VideoRef.current) {
			setVideoDownloadLink(downloadLink);
			if (VideoRef.current.src !== item.video) {
				VideoRef.current.src = item.video;
			}
			if (VideoRef.current.paused) {
				setPlayingVideoIndex(index);
				VideoRef.current.play();
			} else {
				setPlayingVideoIndex(null);
				VideoRef.current.pause();
			}

			VideoRef.current.addEventListener("ended", () => {
				setPlayingVideoIndex(null);
			});
		}
	};
	function runWhisper() {
		console.log("runWhisper", item);
		setContentLoading(true);
		console.log("file loaded", item.audio);

		const formData = new FormData();
		// formData.append("audio", wavBlob, "audio.wav");
		formData.append("audioUrl", item.audio);

		formData.append("instage_id", "instage_id");
		formData.append("session_id", 0);
		formData.append("responseCount", 0);
		formData.append("responseAudioCount", 0);
		const url = process.env.REACT_APP_BACKEND_STATIC_URL;

		const serverUrl = `${url}/api/transcribe-audio`;

		axiosLimitedPost(
			serverUrl,
			formData,
			1,
			{
				headers: {
					"Content-Type": "multipart/form-data"
				}
			},
			50000
		)
			.then((response) => {
				console.log("rawResponseS", response);
				return response.data;
			})
			.then((data) => {
				const parts = itemContent.split(":");
				const prefix = parts[0];
				const { text } = data;
				const temp_itemContent = `${prefix}: ${text}`;
				setItemContent(temp_itemContent);
				console.log(temp_itemContent);
				setContentLoading(false);
				const params = { session_id: item.simulation_id };
				// console.log("Get session data");

				axiosLimitedGet(`${url}/api/sessionData/session`, 1, { params }).then((session) => {
					console.log(session.data);
					const temp_chat_history = session.data.chat_history;
					temp_chat_history[item.messageIndex].content = temp_itemContent;

					const temp_speech_data = session.data.speech_data;
					temp_speech_data[item.index].transcript = text;
					console.log(temp_speech_data);
					console.log(temp_chat_history);
					axiosLimitedPatch(
						`${url}/api/sessionData/session`,
						{ session_id: item.simulation_id, field: "chat_history", value: JSON.stringify(temp_chat_history) },
						1
					);
					axiosLimitedPatch(
						`${url}/api/sessionData/session`,
						{ session_id: item.simulation_id, field: "speech_data", value: JSON.stringify(temp_speech_data) },
						1
					);
					axiosLimitedPatch(`${url}/api/sessionData/session`, { session_id: item.simulation_id, field: "outcome", value: null }, 1);
					axiosLimitedPatch(`${url}/api/sessionData/session`, { session_id: item.simulation_id, field: "summary", value: null }, 1);
					axiosLimitedPatch(
						`${url}/api/sessionData/session`,
						{ session_id: item.simulation_id, field: "question_responses", value: null },
						1
					);
					axiosLimitedPatch(
						`${url}/api/sessionData/session`,
						{ session_id: item.simulation_id, field: "question_summary", value: null },
						1
					);
				});
			})
			.catch((error) => {
				console.error(`/api/upload-audio${error}`);
				return { text: "...???..." };
				// TODO: Handle error
			});
	}

	let avatarName = item.content.split(":")[0];
	if (avatarName == "Azilea") {
		avatarName = "Nina";
	}
	return (
		<div key={index} className={r.transcriptActionContainer}>
			{isUser && isSuperAdmin && (
				<>
					<button onClick={runWhisper}>ReRun</button>
				</>
			)}
			<audio ref={AudioRef}>
				<source type="audio/wav" />
				Your browser does not support the audio element.
			</audio>

			<div className={r.transcriptProfile}>
				{isUser && (
					<TypingText refHolder={typingRefs}>
						<FiUser className={r.transcriptUserIcon} />
					</TypingText>
				)}
				{!isUser && (
					<TypingText refHolder={typingRefs}>
						<img
							className={r.transcriptPortrait}
							src={`https://avatarportraits.s3.ca-central-1.amazonaws.com/${avatarName}Portrait.png`}
							alt="portrait"
						/>
					</TypingText>
				)}
				<p className={r.transcriptName} style={{ color: !isUser ? "#aaaaaa" : "inherit", fontStyle: !isUser ? "italic" : "" }}>
					<TypingText refHolder={typingRefs}>{`${item.content.split(":")[0]}:`}</TypingText>
				</p>
			</div>

			<div className={r.transcriptControls}>
				{showButton && audioValid && (
					<>
						{AudioRef.current && !audioPlaying && (
							<RiVolumeUpFill
								title="Audio playback icon"
								className={r.transcriptPlayBtn}
								onClick={playAudio}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
							/>
						)}
						{AudioRef.current && audioPlaying && isHovered && (
							<RiVolumeMuteFill
								title="Pause audio playback icon"
								className={r.transcriptPlayBtn}
								style={{ color: "#ee4188" }}
								onClick={playAudio}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
							/>
						)}
						{AudioRef.current && audioPlaying && !isHovered && (
							<RiVolumeUpFill
								title="Playing audio playback icon"
								className={r.transcriptPlayBtn}
								style={{ color: "#ee4188" }}
								onClick={playAudio}
								onMouseEnter={handleMouseEnter}
								onMouseLeave={handleMouseLeave}
							/>
						)}
						{/* {isUser && (
							<a
								className={r.downloadAudioBtn+ " AudioDownload"}
								style={{ marginTop: "10px" }}
								href={downloadLink}>
								<RiDownload2Fill title="Download icon"/>
							</a>
						)} */}
					</>
				)}
				{showButton && VideoRef.current && videoValid && (
					<MdOndemandVideo
						title="Video playback icon"
						className={r.transcriptPlayBtn}
						style={{ color: playingVideoIndex !== index ? "#00a9af" : "#ee4188" }}
						onClick={() => playVideo(item.videoDownload)}
					/>
				)}
				{/* {showButton && VideoRef.current && videoValid && (
					<a className={r.transcriptPlayBtn} href={item.videoDownload}><FiDownload /></a>
				)} */}
			</div>
			{!contentLoading && (
				<p
					className={r.transcriptText}
					style={{ color: !isUser ? "#aaaaaa" : "inherit", fontStyle: !isUser ? "italic" : "" }}
					data-id={`id-action-${index}`}
				>
					<TypingText
						refHolder={typingRefs}
						typingSpeed={15}
						onComplete={() => {
							setShowButton(true), onComplete();
						}}
					>
						{itemContent.split(":")[1]}
					</TypingText>
				</p>
			)}

			{isUser && (
				<TypingText refHolder={typingRefs}>
					<div className={r.transcriptCommentIcon}>
						<CommentIcon data_id={`user-transcript-${index}`} />
					</div>
				</TypingText>
			)}
		</div>
	);
}
