import React, { useState, useEffect, useContext, useRef } from "react";
import { Routes, Route, useLocation, useNavigate, useParams, useSearchParams, Navigate } from "react-router-dom";
import axios from "axios";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import s from "../../styleModules/setupStyles.module.css";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export default function LmsLaunch(props) {
	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const { handleNavigate } = useContext(AppContext);
	const [token, setToken] = useState(null);
	const getLtik = () => {
		// console.log("getLtik");
		const ltik = searchParams.get("ltik");
		// console.log(ltik);
		if (!ltik) return false;
		return ltik;
	};
	const getResourceId = () => {
		// console.log("getResourceId");
		const resourceId = searchParams.get("resource");
		// console.log(resourceId);
		if (!resourceId) return false;
		return resourceId;
	};
	const resourceId = searchParams.get("resource");
	const ltik = searchParams.get("ltik");
	const navToSim = (setup_type, local_token) => {
		handleNavigate(`/setup/${setup_type}/${resourceId}`, false, {
			token: local_token
		});
	};
	useEffect(() => {
		axios
			.post(`${url}/lti/login`, {
				ltik,
				resourceId
			})
			.then((res) => {
				console.log(res.data);
				setToken(res.data.token);

				navToSim(res.data.simulation.setup_type, res.data.token);
			});
	}, [resourceId, ltik]);

	return (
		<div style={{ display: "flex", flexDirection: "row" }} className={s.dashboardCardContainer}>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "800px", margin: "0 auto" }}>
				<h1>LMS Launch</h1>

				{/* <h2>LTI Resource ID: {getResourceId()}</h2>

				<p style={{ wordWrap: "break-word" }}>LTIK: {getLtik()}</p> */}

				<button type="button" onClick={() => navToSim("interview", token)}>
					Go to Interview
				</button>
			</div>
		</div>
	);
}
