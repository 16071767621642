import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { FiChevronRight, FiEdit, FiEdit2, FiInfo, FiRotateCcw, FiStar } from "react-icons/fi/index.esm.js";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { BiUpvote, BiDownvote } from "react-icons/bi/index.esm.js";
import { RiDeleteBin5Line } from "react-icons/ri/index.esm.js";
import { Description } from "@mui/icons-material";
import { MdOutlineAssignment } from "react-icons/md";
import styles from "../../styleModules/generateStyles.module.css";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import { AudienceContainer } from "./AudienceContainer.js";
import QuestionTable from "./SetupElements/QuestionTable.js";
import { StartButtonSection } from "./SetupElements/StartButtonSection.js";
import { GenerateButton } from "./GenerateButton.js";
import SetupDetails from "./SetupDetails.js";
import JobDetails from "./JobDetails.js";
import DetailsModal from "./SetupElements/DetailsModal.js";
import { ResumeModal } from "../SecondaryComponents/ResumeModal.js";
import { ProductTourModal } from "./SetupElements/SetupModals.js";
import usePageVisitTracker from "../SecondaryComponents/usePageVisitTracker";
import { getSetupTourContent, getAssistTourContent } from "../UtilityFunctions/getProductTourContent.js";
import ResumeUpload from "./ResumeUpload.js";
import { getEmptySims } from "../Admin/SimulationList.js";
import MultiSelect from "./MultiSelect.js";
import Competencies from "./Competencies.js";
import WIL_Types from "./WIL_Types.js";
import LanguageDict from "./LanguageDict.js";
import JobTitleSelect from "./JobTitleSelect.js";
import JobOptions from "./JobOptions.js";
import ResumeCreatorPreview from "../SecondaryComponents/ResumeCreatorPreview.js";
import templateSelectorStyles from "../../styleModules/TemplateSelectorMini.module.css";
import { useFeatureFlagEnabled } from "posthog-js/react";
import ShareModal from "../SecondaryComponents/ShareModal.js";
import s from "../../styleModules/secondaryStyles.module.css";
import { FiShare } from "react-icons/fi";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

function QuestionGradingRubric() {
	const [percentages, setPercentages] = useState({
		content: 40,
		delivery: 40,
		completion: 20,
		other: 0
	});
	const [isValid, setIsValid] = useState(true);
	const [videoRecording, setVideoRecording] = useState(false);
	const [disableRetry, setDisableRetry] = useState(true);

	const handlePercentageChange = (category) => (e) => {
		const value = parseInt(e.target.value, 10) || 0;
		setPercentages((prev) => ({ ...prev, [category]: value }));
	};

	const verifyTotal = () => {
		const total = Object.values(percentages).reduce((sum, value) => sum + value, 0);
		setIsValid(total === 100);
	};

	useEffect(() => {
		verifyTotal();
	}, [percentages]);

	return (
		<div>
			<h2 className="text-xl font-bold mb-4">QUESTION GRADING RUBRIC</h2>
			<div className="space-y-4">
				{Object.entries(percentages).map(([category, value]) => (
					<div key={category} className="flex items-center space-x-2">
						<input
							style={{ width: "3.8rem" }}
							type="number"
							value={value}
							onChange={handlePercentageChange(category)}
							className="border rounded px-2 py-1 w-16 text-right"
						/>
						<span className="w-4" style={{ marginRight: "1rem" }}>
							%
						</span>

						<label className="flex-grow">
							<span className="font-semibold capitalize">
								<b>{category}: </b>
							</span>
							{category === "content" && <span>The quality, relevance, and structure of your answer</span>}
							{category === "delivery" && <span>Your pace, volume, and use of filler words</span>}
							{category === "completion" && <span>Completion:</span>}
							{category === "other" && <span>Your camera setup, background, attire, speech pattern, clarity, grammar, etc.</span>}
						</label>
					</div>
				))}
			</div>
			{!isValid && (
				<div style={{ color: "red" }}>
					<p>Percentages do not add up to 100%.</p>
				</div>
			)}
			<div className="mt-4 space-y-2">
				<div className="flex items-center space-x-2">
					<input type="checkbox" id="videoRecording" checked={videoRecording} onChange={(e) => setVideoRecording(e.target.checked)} />
					<label htmlFor="videoRecording">Start with video recording on by default</label>
				</div>
				<div className="flex items-center space-x-2">
					<input type="checkbox" id="disableRetry" checked={disableRetry} onChange={(e) => setDisableRetry(e.target.checked)} />
					<label htmlFor="disableRetry">Disable mid-session retry by default</label>
				</div>
			</div>
			<br />
		</div>
	);
}

const useOutsideClick = (ref, callback) => {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};

export default function SetupPage({
	summitDeeplink,
	editAccess,
	questionListViewAccess,
	allowedToStart,
	localUser,
	typingRefs1,
	adminAccess,
	generateStarted,
	error1,
	setError1,
	error2,
	setError2,
	error3,
	setError3,
	questionTypeOptions,
	generateQuestions,
	translatedQuestionTypeOptions,
	resetQuestions,
	isGenerating,
	editSimDetails,
	assignmentData,
	setNewAssignmentType,
	setShowAssignmentModal,
	generateSim,
	simDeleted,
	showStartSimBtn,
	videoOn,
	setVideoOn,
	saveAudio,
	setSaveAudio,
	captureOn,
	setCaptureOn,
	startSimHandler,
	audienceOptions,
	personalityOptions,
	setPersonalityOptions,
	personalities,
	handlePersonalityChange,
	changeAvatar,
	activeTab,
	setActiveTab,
	simulationForbidden,
	clientType
}) {
	// HOOKS

	const {
		runTranslation,
		user,
		resumeAssistAccess,
		setResumeData,
		setupData,
		setSetupData,
		resumeData,
		userLanguage,
		translateSingleText,
		translateDictText,
		performTranslation,
		handleNavigate,
		resumeTemplateData,
		setResumeTemplateData
	} = useContext(AppContext);
	const { checkPageVisit } = usePageVisitTracker();
	const { simId } = useParams();
	const navigate = useNavigate();
	const mlReflectionEnabled = useFeatureFlagEnabled("ml-reflection-option");
	const resumeCreatorFlag = useFeatureFlagEnabled("resume-creator");
	const [searchParams, setSearchParams] = useSearchParams();
	const [showShareModal, setShowShareModal] = useState(false);
	const [showShareButton, setShowShareButton] = useState(false);
	const getLmsAssignmentType = () => {
		// console.log("getLtik");
		const ltik = searchParams.get("assignmentType");
		// console.log(ltik);
		if (!ltik) return false;
		return ltik;
	};
	const getLtik = () => {
		// console.log("getLtik");
		const ltik = searchParams.get("ltik");
		// console.log(ltik);
		if (!ltik) return false;
		return ltik;
	};

	const getLmsToken = () => {
		// console.log("getLmsToken");
		const lmsToken = searchParams.get("token");
		// console.log(lmsToken);
		if (!lmsToken) return false;
		return lmsToken;
	};

	useEffect(() => {
		console.log("Assignment Data", assignmentData);
		if (assignmentData.assignment_name !== "" && assignmentData.assignment_name !== null && assignmentData.assignment_name !== undefined) {
			setShowShareButton(true);
		} else {
			setShowShareButton(false);
		}
	}, [assignmentData]);

	// useEffect(() => {
	// 	const uploadResume = async () => {
	// 		const response = await axiosLimitedPost(
	// 			`${url}/api/resume-assist/get`,
	// 			{ setup_id: simId },
	// 			1,
	// 			{
	// 				headers: { "Content-Type": "multipart/form-data" }
	// 			},
	// 			60000
	// 		);
	// 		setResumeData(response.data);
	// 		setResumeTemplateData(response.data);
	// 	};
	// 	uploadResume();
	// });

	// console.log(window.location); // Log the entire location object
	// const searchParams = new URLSearchParams(window.location.search);
	// console.log(window.location.search); // Log the entire query string
	// const testUrlParam = searchParams.get("test");
	// console.log(testUrlParam);
	// useEffect(() => {
	// 	console.log("setupData", setupData);
	// }, [setupData]);

	// console.log(pageText);
	// Dictionary Ends

	const {
		// Common
		simulation_id,
		audience,
		personality,

		// Interviews
		job_title, // to translate
		short_summary, // to translate
		job_function, // to translate
		experience_level, // to translate
		industry,
		main_responsibilities,
		skills_required,
		monsterUrl,

		// Discovery
		product,
		meeting_details,
		prospect_company_name,
		prospect_company_description,
		call_notes,

		// Closing
		buyer_need_1,
		buyer_need_2,
		buyer_objection_1,
		buyer_objection_2,

		// Presentation
		topic,
		presentation_details,
		target_time,
		objective,
		question_list,

		// Retail
		additional_details,
		sales_rep_company_name,
		sales_rep_company_description,

		// Intro
		intro_event,
		intro_details,
		user_role,
		audience_role,

		// Freestyle
		convo_type,
		user_details,
		audience_details,

		// Patient
		visit_type,
		patient_summary,
		patient_bio,
		patient_medical,

		// Pitch
		pitch_topic,
		pitch_details,

		// Reflection
		experience_type,
		experience_type_description,
		experience_details,
		phase,
		competency_list
	} = setupData;

	const [hasTranslatedText, setHasTranslatedText] = useState(false);

	const LocalLanguageDict = {
		interview: {
			InterviewJobTitlePlaceholder: "Enter job title here",
			InterviewJobDetailsPlaceholder: "Enter a job description",
			InterviewJobTitleDropDownPlaceholder: "Start typing to search job titles or create your own",
			GenerateJobSimTitle: "Generate Job",
			InterviewInputHeadingTitle: "Job Title / Role",
			InterviewInputHeadingDescription: "Job Description",
			InterviewInputHeadingAvatarSelect: "Choose Your Avatar",
			InterviewInputHeadingYourResume: "Your Resume",
			InterviewInputLabelTitle: "Choose the job you are preparing for",
			InterviewInputLabelDescription: "Optional - Copy details from a job listing or describe the job in detail",
			InterviewUploadResumeInputLabelEnabled: "Click to upload or advance to next screen to create one",
			InterviewUploadResumeInputLabelDisabled: "Optional - Copy and paste your resume here",
			EarlyAccess: "Early Access",
			Instructions: "Instructions",
			DescriptionTip: `Entering in these extra details is recommended but not mandatory as this will
			add more personal touch to your simulation by allowing the avatars to pull
			specific information that is put here and use it while speaking with you.`,
			ResumeUploadTip: `Entering in your resume and/or cover letter will help tailor the question flow
			during the simulation. You can also use the resume/ cover letter assist
			features to easily check how well they align to any specific job posting and
			use AI to enhance them on the fly!`,
			SkillsRequired: "Skills Required",
			JobFunction: "Job Function",
			ShortSummary: "Short Summary",
			ExperienceLevel: "Experience Level",
			Industry: "Industry",
			MainResponsibilities: "Main Responsibilities",
			Job: "Job",
			JobInterviewFor: "Job Interview For",
			MockInterview: "Mock Interview",
			ResumeAssist: "Resume Assist",
			CoverLetterAssist: "Cover Letter Assist",
			Apply: "Apply",
			Interviewer: "Interviewer",
			Description: "Description",
			InterviewWarningCalloutText: "This interview simulation is not a true representation of any mentioned company's process.",
			Resume: "Resume",
			Edit: "Edit",
			Initial: "Initial",
			Current: "Current",
			JobTitle: "Job Title",
			Details: "Details",
			AudienceMember: "Audience Member",
			ResumeFeedback: "Resume feedback and rewrites are generated by AI and require human review."
		},
		presentation: {
			PresentationTitlePlaceholder: "Enter presentation topic",
			PresentationDescriptionPlaceholder: `Purpose: The goal is to analyze and discuss how AI is transforming healthcare, including its applications, benefits, challenges, and ethical considerations.

Audience:
- Undergraduate Students in Health Sciences

Possible questions:
- How is AI being utilized in healthcare diagnostics and treatment?
- What are the ethical considerations in implementing AI in healthcare?
- What challenges do healthcare providers face in adopting AI technologies?`,
			EarlyAccess: "Early Access",
			Instructions: "Instructions",
			GeneratePresentation: "Generate Presentation",
			ChooseYourPresentationType: "Choose your presentation type",
			WhatAreYouSelling: "What are you selling?",
			MeetingDetails: "Meeting Details",
			GeneralPresentation: "General Presentation",
			SelfIntro: "Self-Introduction",
			ElevatorPitch: "Elevator Pitch",
			BusinessPitch: "Business Pitch",
			WhatIsYourPresentationAbout: "What is your presentation about?",
			PresentationDetails: "Presentation Details",
			ChoosePracticePartner: "Choose You Practice Partner",
			EnterPresentationTopic: "Enter the topic of your presentation",
			PresentationOptionalEnterAdditionalDetails: "Optional - Enter any additional details about the presentation",
			ShortSummary: "Short Summary",
			ExperienceLevel: "Experience Level",
			Industry: "Industry",
			MainResponsibilities: "Main Responsibilities",
			SkillsRequired: "Skills Required",
			JobFunction: "Job Function",
			Job: "Job",
			JobInterviewFor: "Job Interview For",
			Apply: "Apply",
			Interviewer: "Interviewer",
			BuyerCompany: "Buyer Company",
			Description: "Description",
			IntelAboutBuyer: "Intel About Buyer",
			Buyer: "Buyer",
			InterviewWarningCalloutText: "This interview simulation is not a true representation of any mentioned company's process.",
			Resume: "Resume",
			Edit: "Edit",
			Initial: "Initial",
			Current: "Current",
			PresentationTopic: "Presentation Topic",
			TargetTime: "Target Time",
			Objective: "Objective",
			Minutes: "minutes",
			Persuade: "Persuade",
			Educate: "Educate",
			Entertain: "Entertain",
			Inspire: "Inspire",
			Event: "Event",
			Details: "Details",
			SecondsUnit: "seconds",
			MinutesUnit: "minutes",
			ProductPlaceholder: "e.g. Hiking boots",
			AdditionalDetailsPlaceholder: "Optional - Enter any additional details about the meeting",
			OptionalEnterAnyAdditionalDetailsAboutTheMeeting: "Optional - Enter any additional details about the meeting"
		},
		selfIntro: {
			SelfIntroTitlePlaceholder: "Where are you introducing yourself? e.g. Networking Event",
			SelfIntroDescriptionPlaceholder: `e.g. I'm introducing myself to a prospective employer.

- I'm looking for a job in the tech industry
- I'm hoping to connect with a HR Coordinator at Google who can help me find a job.`,
			EarlyAccess: "Early Access",
			Instructions: "Instructions",
			GenerateIntro: "Generate Self-Introduction",
			MeetingDetails: "Meeting Details",
			GeneralPresentation: "General Presentation",
			SelfIntro: "Self-Introduction",
			ElevatorPitch: "Elevator Pitch",
			BusinessPitch: "Business Pitch",
			ChooseYourPresentationType: "Choose your presentation type",
			ChoosePracticePartner: "Choose You Practice Partner",
			IntroWhereAreYouIntroducingYourself: "Where are you introducing yourself?",
			IntroIntroductionDetails: "Introduction Details",
			IntroWhoAreYouIntroducingYourselfTo: "Who are you introducing yourself to?",
			IntroEnterEvent: "Enter the event where you are introducing yourself",
			IntroOptionalEnterAdditionalDetails: "Optional - Enter any additional details about the introduction",
			ShortSummary: "Short Summary",
			ExperienceLevel: "Experience Level",
			Industry: "Industry",
			MainResponsibilities: "Main Responsibilities",
			SkillsRequired: "Skills Required",
			JobFunction: "Job Function",
			Job: "Job",
			Apply: "Apply",
			Resume: "Resume",
			Edit: "Edit",
			Initial: "Initial",
			Current: "Current",
			TargetTime: "Target Time",
			Objective: "Objective",
			Minutes: "minutes",
			Persuade: "Persuade",
			Educate: "Educate",
			Entertain: "Entertain",
			Inspire: "Inspire",
			Event: "Event",
			Details: "Details",
			SelfIntroWarningCalloutText: "This self-introduction simulation is not a true representation of any mentioned company.",
			SecondsUnit: "seconds",
			AudienceMember: "Audience Member",
			MinutesUnit: "minutes",
			AdditionalDetailsPlaceholder: "Optional - Enter any additional details about the meeting",
			ChooseYourBuyerPlaceholder: "Choose the buyer you are preparing for",
			EnterTheNameOfTheProductOrServiceYouAreSelling: "Enter the name of the product or service you are selling",
			OptionalEnterAnyAdditionalDetailsAboutTheMeeting: "Optional - Enter any additional details about the meeting"
		},
		elevatorPitch: {
			ElevatorPitchTitlePlaceholder: "e.g. Innovative Mobile Health App for Diabetes Management",
			ElevatorPitchDescriptionPlaceholder: `This is a networking event for healthcare and technology sectors. There's an opportunity to pitch your health app to potential investors and stakeholders.

Audience:
- These individuals are particularly interested in mobile health applications that can revolutionize patient care and management.`,
			EarlyAccess: "Early Access",
			Instructions: "Instructions",
			GenerateElevatorPitch: "Generate Elevator Pitch",
			WhatAreYouSelling: "What are you selling?",
			MeetingDetails: "Meeting Details",
			ChooseYourBuyer: "Choose Your Buyer",
			GeneralPresentation: "General Presentation",
			SelfIntro: "Self-Introduction",
			ElevatorPitch: "Elevator Pitch",
			BusinessPitch: "Business Pitch",
			ChooseYourPresentationType: "Choose your presentation type",
			WhatIsYourPresentationAbout: "What is your presentation about?",
			PresentationDetails: "Presentation Details",
			ChoosePracticePartner: "Choose You Practice Partner",
			EnterPresentationTopic: "Enter the topic of your presentation",
			ElevatorPitchWhatIsYourElevatorPitchAbout: "What is your elevator pitch about?",
			ElevatorPitchPitchDetails: "Pitch Details",
			ElevatorPitchChooseYourPracticeAudience: "Choose Your Practice Audience",
			ElevatorPitchEnterMainTopic: "Enter the main topic of your elevator pitch",
			ElevatorPitchOptionalEnterAdditionalDetails: "Optional - Enter any additional details about your pitch",
			ShortSummary: "Short Summary",
			ExperienceLevel: "Experience Level",
			Industry: "Industry",
			MainResponsibilities: "Main Responsibilities",
			SkillsRequired: "Skills Required",
			JobFunction: "Job Function",
			Job: "Job",
			JobInterviewFor: "Job Interview For",
			Apply: "Apply",
			Interviewer: "Interviewer",
			BuyerCompany: "Buyer Company",
			Description: "Description",
			IntelAboutBuyer: "Intel About Buyer",
			Buyer: "Buyer",
			InterviewWarningCalloutText: "This interview simulation is not a true representation of any mentioned company's process.",
			Resume: "Resume",
			Edit: "Edit",
			Initial: "Initial",
			Current: "Current",
			JobTitle: "Job Title",
			PresentationTopic: "Presentation Topic",
			ElevatorPitchFor: "Elevator Pitch for",
			TargetTime: "Target Time",
			Objective: "Objective",
			Minutes: "minutes",
			Persuade: "Persuade",
			Educate: "Educate",
			Entertain: "Entertain",
			Inspire: "Inspire",
			Event: "Event",
			Details: "Details",
			PitchTopic: "Pitch Topic",
			PitchDetails: "Pitch Details",
			PitchLength: "Pitch Length",
			SecondsUnit: "seconds",
			AudienceMember: "Audience Member",
			MinutesUnit: "minutes",
			ProductPlaceholder: "e.g. Hiking boots",
			AdditionalDetailsPlaceholder: "Optional - Enter any additional details about the meeting",
			ChooseYourBuyerPlaceholder: "Choose the buyer you are preparing for",
			EnterTheNameOfTheProductOrServiceYouAreSelling: "Enter the name of the product or service you are selling",
			OptionalEnterAnyAdditionalDetailsAboutTheMeeting: "Optional - Enter any additional details about the meeting",
			AdditionalBuyerDetailsPlaceholder: `Buyer: Sales Director at MarketMind Enterprises
- Struggling with tracking customer interactions and needs a unified system to improve sales performance.

Our Company: NexTech Solutions
- We specialize in providing innovative software solutions, including CRM systems, to help businesses enhance their customer engagement.

Our Solution: NexTech CRM Suite, integrates with existing tools, centralizes customer information, and automates key tasks, enabling your sales team to focus on building meaningful relationships.`
		},
		businessPitch: {
			BusinessPitchTitlePlaceholder: "e.g. E-commerce Platform for Sustainable Products",
			BusinessPitchDescriptionPlaceholder: `This is a business conference for sustainability and e-commerce sectors. There's an opportunity to pitch your e-commerce platform to potential investors and stakeholders.

Audience:
- These individuals are particularly interested in e-commerce platforms that can promote and facilitate the sale of sustainable products.`,
			EarlyAccess: "Early Access",
			Instructions: "Instructions",
			GenerateBusinessPitch: "Generate Business Pitch",
			WhatAreYouSelling: "What are you selling?",
			MeetingDetails: "Meeting Details",
			ChooseYourBuyer: "Choose Your Buyer",
			BusinessPitch: "Business Pitch",
			BusinessPitchWhatIsYourBusinessPitchAbout: "What is your business pitch about?",
			BusinessPitchPitchDetails: "Pitch Details",
			BusinessPitchChooseYourPracticeAudience: "Choose Your Practice Audience",
			BusinessPitchEnterMainTopic: "Enter the main topic of your business pitch",
			BusinessPitchOptionalEnterAdditionalDetails: "Optional - Enter any additional details about your pitch",
			BusinessPitchFor: "Business Pitch for",
			ShortSummary: "Short Summary",
			ExperienceLevel: "Experience Level",
			Industry: "Industry",
			MainResponsibilities: "Main Responsibilities",
			SkillsRequired: "Skills Required",
			Apply: "Apply",
			BuyerCompany: "Buyer Company",
			Description: "Description",
			IntelAboutBuyer: "Intel About Buyer",
			Buyer: "Buyer",
			Edit: "Edit",
			Initial: "Initial",
			Current: "Current",
			JobTitle: "Job Title",
			TargetTime: "Target Time",
			Objective: "Objective",
			Minutes: "minutes",
			Persuade: "Persuade",
			Educate: "Educate",
			Entertain: "Entertain",
			Inspire: "Inspire",
			Event: "Event",
			Details: "Details",
			PitchTopic: "Pitch Topic",
			PitchDetails: "Pitch Details",
			PitchLength: "Pitch Length",
			SecondsUnit: "seconds",
			AudienceMember: "Audience Member",
			BusinessPitchWarningCalloutText: "This business pitch simulation is hypothetical and does not guarantee actual business outcomes.",
			MinutesUnit: "minutes",
			ProductPlaceholder: "e.g. Hiking boots",
			AdditionalDetailsPlaceholder: "Optional - Enter any additional details about the meeting",
			ChooseYourBuyerPlaceholder: "Choose the buyer you are preparing for",
			EnterTheNameOfTheProductOrServiceYouAreSelling: "Enter the name of the product or service you are selling",
			OptionalEnterAnyAdditionalDetailsAboutTheMeeting: "Optional - Enter any additional details about the meeting",
			AdditionalBuyerDetailsPlaceholder: `Buyer: Sales Director at MarketMind Enterprises
- Struggling with tracking customer interactions and needs a unified system to improve sales performance.

Our Company: NexTech Solutions
- We specialize in providing innovative software solutions, including CRM systems, to help businesses enhance their customer engagement.

Our Solution: NexTech CRM Suite, integrates with existing tools, centralizes customer information, and automates key tasks, enabling your sales team to focus on building meaningful relationships.`
		},
		discovery: {
			GenerateDiscoveryDryRun: "Generate Discovery Dry Run",
			GenerateDiscovery: "Generate Discovery",
			WhatAreYouSelling: "What are you selling?",
			MeetingDetails: "Meeting Details",
			ChooseYourBuyer: "Choose Your Buyer",
			DiscoveryInputLabelProductName: "Enter the name of the product or service you are selling",
			DiscoveryInputLabelAdditionalDetails: "Optional - Enter any additional details about the meeting",
			ShortSummary: "Short Summary",
			ExperienceLevel: "Experience Level",
			Industry: "Industry",
			MainResponsibilities: "Main Responsibilities",
			SkillsRequired: "Skills Required",
			Apply: "Apply",
			BuyerCompany: "Buyer Company",
			Description: "Description",
			IntelAboutBuyer: "Intel About Buyer",
			SalesTitlePlaceholder: "Product / service name",
			SalesDescriptionPlaceholder: `Buyer: Sales Director at MarketMind Enterprises`,
			Instructions: "Instructions",
			Buyer: "Buyer",
			Resume: "Resume",
			Edit: "Edit",
			Initial: "Initial",
			Current: "Current",
			DiscoveryWarningCalloutText: "This discovery meeting simulation is not a true representation of any mentioned company.",
			DiscoveryDryRun: "Discovery Dry Run",
			TargetTime: "Target Time",
			Objective: "Objective",
			Minutes: "minutes",
			Persuade: "Persuade",
			Educate: "Educate",
			Entertain: "Entertain",
			Inspire: "Inspire",
			Event: "Event",
			Details: "Details",
			SecondsUnit: "seconds",
			AudienceMember: "Audience Member",
			MinutesUnit: "minutes",
			ProductPlaceholder: "e.g. Hiking boots",
			AdditionalDetailsPlaceholder: "Optional - Enter any additional details about the meeting",
			ChooseYourBuyerPlaceholder: "Choose the buyer you are preparing for",
			EnterTheNameOfTheProductOrServiceYouAreSelling: "Enter the name of the product or service you are selling",
			OptionalEnterAnyAdditionalDetailsAboutTheMeeting: "Optional - Enter any additional details about the meeting",
			AdditionalBuyerDetailsPlaceholder: `Buyer: Sales Director at MarketMind Enterprises
- Struggling with tracking customer interactions and needs a unified system to improve sales performance.

Our Company: NexTech Solutions
- We specialize in providing innovative software solutions, including CRM systems, to help businesses enhance their customer engagement.

Our Solution: NexTech CRM Suite, integrates with existing tools, centralizes customer information, and automates key tasks, enabling your sales team to focus on building meaningful relationships.`
		},
		reflection: {
			GenerateReflection: "Generate Reflection",
			WhereDidItTakePlace: "Where did it take place?",
			WhenDidYouParticipateInThis: "When did you participate in this?",
			HowLongWasYourExperience: "How long was your experience?",
			WhatDidTheExperienceEntail: "What did the experience entail?",
			WhatTypeOfExperience: "What type of experience was it?",
			ExperienceDetails: "Experience Details",
			SelectExperienceToReflectOn: "Select the type of experience you are reflecting on",
			ExampleCoop: "e.g. Co-op",
			OptionalEnterAnyAdditionalDetailsReflection: "Optional - Enter any additional details about the experience",
			ExampleCoopDetails: `- The co-op took place at FutureTech, headquartered in Toronto, in their software development department.\n- I participated in the co-op during the Fall semester.\n- It lasted six months.\n- The co-op entailed working as a junior software developer, where I contributed to project teams developing new applications, engaged in code reviews, and participated in daily stand-ups and agile sprint meetings.`,
			Competencies: "Competencies",
			ExperienceType: "Experience Type",
			ExperienceDescription: "Experience Description",
			SelectExperienceType: "Select Experience Type",
			OptionalSelect5Competencies: "Optional - Select up to 5 competencies you would like to reflect on."
		},
		closing: {
			GenerateClosing: "Generate Closing Meeting",
			BuyerNeed1Placeholder: "e.g. The buyer needs a unified system to improve sales performance.",
			BuyerNeed2Placeholder: "e.g. The buyer needs a solution to streamline their inventory management process",
			BuyerObjection1Placeholder: "e.g. The buyer is concerned about the cost of the product and is looking for a discount",
			BuyerObjection2Placeholder: "e.g. The buyer is concerned about the products compatibiltiy with their existing systems",
			BuyersNeed1: "Buyer's Need #1",
			BuyersNeed2: "Buyer's Need #2",
			BuyersObjection1: "Buyer's Objection #1",
			BuyersObjection2: "Buyer's Objection #2",
			WhatAreYouSelling: "What are you selling?",
			MeetingDetails: "Meeting Details",
			BuyerNeedLabel: "Enter a brief description of the buyer's current need.",
			BuyerObjectionLabel: "Enter a brief description of the buyer's current need.",
			EnterTheNameOfTheProductOrServiceYouAreSelling: "Enter the name of the product or service you are selling",
			OptionalEnterAnyAdditionalDetailsAboutTheMeeting: "Optional - Enter any additional details about the meeting",
			ProductPlaceholder: "e.g. Customer Relationship Management Software",
			AdditionalBuyerDetailsPlaceholder: `Buyer: Sales Director at MarketMind Enterprises
- Struggling with tracking customer interactions and needs a unified system to improve sales performance.

Our Company: NexTech Solutions
- We specialize in providing innovative software solutions, including CRM systems, to help businesses enhance their customer engagement.

Our Solution: NexTech CRM Suite, integrates with existing tools, centralizes customer information, and automates key tasks, enabling your sales team to focus on building meaningful relationships.`,
			GenerateClosingMeetingDryRun: "Generate Closing Meeting",
			ClosingMeetingDryRun: "Closing Meeting",
			Buyer: "Buyer",
			Resume: "Resume",
			Edit: "Edit",
			Initial: "Initial",
			Current: "Current",
			DescriptionTip: `Entering in these extra details is recommended but not mandatory as this will
			add more personal touch to your simulation by allowing the avatars to pull
			specific information that is put here and use it while speaking with you.`,
			BuyerCompany: "Buyer Company",
			Description: "Description",
			Needs: "Needs",
			Objection: "Objection",
			Solution: "Solution",
			ClosingMeetingWarningCalloutText: "This closing meeting simulation is not a true representation of any mentioned company.",
			Instructions: "Instructions"
		},
		discovery_spin: {
			GenerateDiscoverySpin: "Generate Discovery SPIN",
			DiscoverySpin: "Discovery SPIN",
			WhatAreYouSelling: "What are you selling?",
			MeetingDetails: "Meeting Details",
			ProductPlaceholder: "e.g. Customer Relationship Management Software",
			DiscoverySpinDetailsPlaceholder: `Buyer: Sales Director at MarketMind Enterprises
- Struggling with tracking customer interactions and needs a unified system to improve sales performance.

Our Company: NexTech Solutions
- We specialize in providing innovative software solutions, including CRM systems, to help businesses enhance their customer engagement.

Our Solution: NexTech CRM Suite, integrates with existing tools, centralizes customer information, and automates key tasks, enabling your sales team to focus on building meaningful relationships.`,
			ChooseYourBuyer: "Choose Your Buyer",
			ChooseYourBuyerPlaceholder: "Choose the buyer you are preparing for",
			DiscoverySpinInputLabelProductName: "Enter the name of the product or service you are selling",
			DiscoverySpinInputLabelAdditionalDetails: "Optional - Enter any additional details about the meeting",
			Buyer: "Buyer",
			BuyerCompany: "Buyer Company",
			Description: "Description",
			Needs: "Needs",
			Objection: "Objection",
			Solution: "Solution",
			Resume: "Resume",
			Edit: "Edit",
			Initial: "Initial",
			Current: "Current",
			IntelAboutBuyer: "Intel About Buyer",
			DiscoverySpinTitlePlaceholder: "e.g. Customer Relationship Management Software",
			BuyerCurrentSituation: "Buyer's Current Situation",
			BuyerNeed: "Buyer's Need",
			BuyerObjection: "Buyer's Objection",
			BuyerSolution: "Buyer's Solution",
			BuyerCurrentSituationLabel: "Enter a brief description of the buyer's current situation.",
			BuyerNeedLabel: "Enter a brief description of the buyer's current need.",
			BuyerObjectionLabel: "Enter a brief description of the buyer's current need.",
			BuyerSolutionLabel: "Enter a brief description of the buyer's solution.",
			BuyerCurrentSituationPlaceholder:
				"e.g. The buyer is struggling with tracking customer interactions and needs a unified system to improve sales performance.",
			BuyerCurrentSituation2Placeholder:
				"e.g. The buyer is currently facing challenges in managing their inventory and is looking for a solution to streamline the process.",
			DescriptionTip: `Entering in these extra details is recommended but not mandatory as this will
			add more personal touch to your simulation by allowing the avatars to pull
			specific information that is put here and use it while speaking with you.`,
			DiscoveryWarningCalloutText: "This discovery meeting simulation is not a true representation of any mentioned company.",
			Instructions: "Instructions"
		}
	};

	const getPageText = (setupType) => {
		switch (setupType) {
			case "interview":
				return LocalLanguageDict.interview;
			case "presentation":
				return LocalLanguageDict.presentation;
			case "intro":
				return LocalLanguageDict.selfIntro;
			case "pitch":
				return LocalLanguageDict.elevatorPitch;
			case "business_pitch":
				return LocalLanguageDict.businessPitch;
			case "discovery":
				return LocalLanguageDict.discovery;
			case "reflection":
				return LocalLanguageDict.reflection;
			case "closing":
				return LocalLanguageDict.closing;
			case "discovery_spin":
				return LocalLanguageDict.discovery_spin;
			default:
				return {};
		}
	};

	const [showInputSections, setShowInputSections] = useState({
		inputDetails: false,
		inputResume: false
	});

	// console.log("assignmentData", assignmentData);
	const [selectedPresentationOption, setSelectedPresentationOption] = useState(setupData.setup_type);
	const [setupDescriptionHeader, setSetupDescriptionHeader] = useState(setupData.setup_description);
	const [pageText, setPageText] = useState({
		...getPageText(setupData.setup_type),
		setupDescription: setupDescriptionHeader,
		StartWithVideo: "Start with video",
		RecordVideo: "Record video",
		EarlyAccessFeatureText: "This feature is under development and may require additional fees in the future.",
		Share: "Share",
		ShareAssignment: "Share Assignment",
		EditInstructionsBeforeSharing: "Edit instructions before sharing"
		// detailsModalFields: setupData.details_modal_fields,
	});
	const [SetupDataSetupType, setSetupDataSetupType] = useState(setupData.setup_type);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		async function translateText() {
			setIsLoading(true);
			await performTranslation(pageText, setPageText);
			setIsLoading(false);
		}
		translateText();
	}, [selectedPresentationOption]);

	// In your render method:

	useEffect(() => {
		// console.log("Translating");
		async function updateAndTranslateText() {
			setIsLoading(true);
			setSetupDataSetupType(setupData.setup_type);
			const newPageText = {
				...pageText,
				...getPageText(setupData.setup_type),
				setupDescription: setupData.setup_description
			};
			setPageText(newPageText);
			await performTranslation(newPageText, setPageText);
			setIsLoading(false);
		}
		updateAndTranslateText();
	}, [setupData.setup_type, selectedPresentationOption]);

	// Dynamic Translation
	const [dynamicPageText, setDynamicPageText] = useState([]);
	// Dynamic Usage
	useEffect(() => {
		async function performTranslations() {
			if (!WIL_Types || WIL_Types.length === 0) {
				// console.error("sessionList is undefined or empty");
				return;
			}

			const propertiesToTranslate = ["label"];
			let updatedSessionList = [...WIL_Types];
			if (mlReflectionEnabled) {
				updatedSessionList.push({
					label: "Intern Weekly Reflection",
					value: "intern_weekly_reflection",
					description: "The intern weekly reflection is a reflection on the intern's week of work and how they can improve."
				});
			}

			try {
				// eslint-disable-next-line no-restricted-syntax
				for (const property of propertiesToTranslate) {
					const texts = updatedSessionList.map((item) => item[property]);
					const translatedTexts = await translateDictText(texts, userLanguage);
					updatedSessionList = updatedSessionList.map((item, index) => ({
						...item,
						[property]: translatedTexts[index] || item[property] // Use translated text if available, otherwise use original
					}));
				}
				setDynamicPageText(updatedSessionList);
			} catch (error) {
				console.error("Error translating properties:", error);
			}
		}

		performTranslations();
	}, [WIL_Types, userLanguage, translateDictText]);

	// CONSTANTS
	const tabList = [pageText.MockInterview, pageText.ResumeAssist, pageText.CoverLetterAssist, monsterUrl ? pageText.Apply : ""].filter(Boolean);
	const titleLimit = 100;
	const detailsLimit = 15000;
	const error1Text = `Input required (Max: ${titleLimit} characters)`;
	const error2Text = `Please limit details to ${detailsLimit} characters`;
	const error3Text = "Missing required input(s)";
	const patientSimUserRoles = [
		"Clinical Psychologist/Psychiatrist",
		"Dental Assistant",
		"Dental Office Receptionist",
		"Dietitian/Nutritionist",
		"Health Care Provider",
		"Medical Receptionist",
		"Mental Health Clinic Receptionist",
		"Nutrition Clinic Receptionist",
		"Occupational Therapist",
		"Occupational Therapy Receptionist",
		"Optometrist",
		"Optometry Clinic Receptionist",
		"Pharmacist",
		"Pharmacy Technician",
		"Physical Therapist",
		"Physical Therapy Receptionist",
		"Speech-Language Pathologist",
		"Speech Therapy Clinic Receptionist"
	];
	const visitTypes = [
		"General Admission"
		// "Primary Care",
		// "Acute Care"
	];
	const presentationOptions = [
		{ value: "presentation", label: pageText.GeneralPresentation },
		{ value: "intro", label: pageText.SelfIntro },
		{ value: "pitch", label: pageText.ElevatorPitch },
		{ value: "business_pitch", label: pageText.BusinessPitch }
	];

	// REFS
	const inputRef = useRef(null);
	const selectRef = useRef(null);
	const textareaRef = useRef(null);
	const dropdownRef1 = useRef(null);
	const dropdownRef2 = useRef(null);
	const infoRef_instructions = useRef();
	const infoRef_details = useRef();
	const infoRef_details2 = useRef();
	const infoRef_resume = useRef();
	const infoRef_audience = useRef();
	const dropdownRef = useRef();

	// STATES

	const [showEarlyAccess, setShowEarlyAccess] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showPTModal, setShowPTModal] = useState(false);
	const [hoveredAvatar, setHoveredAvatar] = useState(null);
	const [infoTab, setInfoTab] = useState("resume");
	const [showInfo_instructions, setShowInfo_instructions] = useState(false);
	const [showInfo_details, setShowInfo_details] = useState(false);
	const [showInfo_details2, setShowInfo_details2] = useState(false);
	const [showInfo_resume, setShowInfo_resume] = useState(false);
	const [showInfo_audience, setShowInfo_audience] = useState(false);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [showResumeModal, setShowResumeModal] = useState(false);
	const [generateButtonClickedState, setGenerateButtonClickedState] = useState(false);
	const [wasFirstVisit, setWasFirstVisit] = useState(false);
	const [selectedPresentationType, setSelectedPresentationType] = useState(
		presentationOptions.find((option) => option.value === setupData?.setup_type) || {
			value: "presentation",
			label: "General Presentation"
		}
	);
	const [jobOptions, setJobOptions] = useState([]);
	const [selectedJobTitle, setSelectedJobTitle] = useState(job_title ? { label: job_title, value: job_title } : null);

	useEffect(() => {
		if (job_title && job_title !== "") {
			setShowInputSections({
				inputDetails: true,
				inputResume: true
			});
		}
	}, [job_title]);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	useEffect(() => {
		// Set early access if the user has resume assist access and is not in the interview tab
		setShowEarlyAccess(!(setupData?.setup_type === "interview" && resumeAssistAccess && (activeTab === 1 || activeTab === 2)));
	}, [resumeAssistAccess, setupData, activeTab]);

	const customCellRenderer = (props, openModal, moveUpQuestion, moveDownQuestion, removeQuestion, setSelectedQuestion) => {
		const isInterviewCustom = setupType === "interview";

		// Common conditions used for both setup types
		const isOpeningQuestion = props.rowIndex === 0;
		const isClosingQuestion = props.rowIndex === props.container._setupData.length - 1;
		const isSecondLastQuestion = props.rowIndex === props.container._setupData.length - 2;
		const isSecondQuestion = props.rowIndex === 1;

		// Determine the class names based on the setup type and question position
		const editButtonClass =
			isInterviewCustom && (isClosingQuestion || isOpeningQuestion) ? styles.editQuestionBtnDisabled : styles.editQuestionBtn;
		const upvoteButtonClass =
			isInterviewCustom && (isClosingQuestion || isOpeningQuestion || isSecondQuestion)
				? styles.editQuestionBtnDisabled
				: styles.editQuestionBtn;
		const downvoteButtonClass =
			isInterviewCustom && (isClosingQuestion || isOpeningQuestion || isSecondLastQuestion)
				? styles.editQuestionBtnDisabled
				: styles.editQuestionBtn;
		const deleteButtonClass =
			isInterviewCustom && (isClosingQuestion || isOpeningQuestion)
				? styles.editQuestionBtnDisabled
				: `${styles.editQuestionBtn} ${styles.deleteBtn}`;

		const handleEditClick = () => {
			if (!isInterviewCustom || (!isClosingQuestion && !isOpeningQuestion)) {
				setSelectedQuestion(props.rowIndex);
				openModal();
			}
		};
		const handleUpvoteClick = () => {
			if (!isInterviewCustom || (!isClosingQuestion && !isOpeningQuestion && !isSecondQuestion)) {
				moveUpQuestion(props.rowIndex, props.container._setupData);
			}
		};
		const handleDownvoteClick = () => {
			if (!isInterviewCustom || (!isClosingQuestion && !isOpeningQuestion && !isSecondLastQuestion)) {
				moveDownQuestion(props.rowIndex, props.container._setupData);
			}
		};
		const handleDeleteClick = () => {
			if (!isInterviewCustom || (!isClosingQuestion && !isOpeningQuestion)) {
				removeQuestion(props.rowIndex, props.container._setupData);
			}
		};

		return (
			<div className={styles.editQuestionBtnContainer}>
				<FiEdit className={editButtonClass} onClick={handleEditClick} />
				<BiUpvote className={upvoteButtonClass} onClick={handleUpvoteClick} />
				<BiDownvote className={downvoteButtonClass} onClick={handleDownvoteClick} />
				<RiDeleteBin5Line className={deleteButtonClass} onClick={handleDeleteClick} />
			</div>
		);
	};

	const setupConfig = {
		interview: {
			generateTitle: resumeAssistAccess ? pageText.GenerateJobSimTitle : "Generate Interview",
			generatedTitle:
				assignmentData && assignmentData.assignment_name
					? assignmentData.assignment_name
					: resumeData
					? `${pageText.Job}: ${job_title}`
					: `${pageText.JobInterviewFor} ${job_title}`,
			titleProperty: "job_title",
			detailsProperty: "job_description",
			inputHeadings: [
				`${pageText.InterviewInputHeadingTitle}`,
				`${pageText.InterviewInputHeadingDescription}`,
				`${pageText.InterviewInputHeadingAvatarSelect}`
			],
			inputLabels: [`${pageText.InterviewInputLabelTitle}`, `${pageText.InterviewInputLabelDescription}`],
			titlePlaceholder: pageText.InterviewJobTitlePlaceholder,
			detailsPlaceholder: pageText.InterviewJobDetailsPlaceholder,
			generatedProperty: short_summary,
			audienceRole: pageText.Interviewer,
			detailsArray: [
				{ heading: pageText.ShortSummary, text: short_summary },
				{ heading: pageText.JobFunction, text: job_function },
				{ heading: pageText.ExperienceLevel, text: experience_level },
				{ heading: pageText.Industry, text: industry },
				{ heading: pageText.MainResponsibilities, text: main_responsibilities },
				{ heading: pageText.SkillsRequired, text: skills_required }
			],
			detailsModalFields: [
				{
					name: "job_title",
					label: pageText.JobTitle,
					type: "input",
					title: true
				},
				{
					name: "short_summary",
					label: pageText.ShortSummary,
					type: "textarea",
					rows: 5
				},
				{
					name: "job_function",
					label: pageText.JobFunction,
					type: "textarea",
					rows: 5
				},
				{
					name: "experience_level",
					label: pageText.ExperienceLevel,
					type: "textarea",
					rows: 5
				},
				{ name: "industry", label: pageText.Industry, type: "input" },
				{ name: "main_responsibilities", label: pageText.MainResponsibilities, type: "array" },
				{ name: "skills_required", label: pageText.SkillsRequired, type: "array" }
			],
			intercomArticle: 8257398,
			warningCalloutText: pageText.InterviewWarningCalloutText
		},
		discovery: {
			generateTitle: pageText.GenerateDiscoveryDryRun,
			generatedTitle:
				assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : `${pageText.DiscoveryDryRun}: ${product}`,
			titleProperty: "product",
			detailsProperty: "meeting_details",
			inputHeadings: [pageText.WhatAreYouSelling, pageText.MeetingDetails, pageText.ChooseYourBuyer],
			inputLabels: [pageText.DiscoveryInputLabelProductName, pageText.DiscoveryInputLabelAdditionalDetails],
			titlePlaceholder: pageText.SalesTitlePlaceholder,
			detailsPlaceholder: pageText.SalesDescriptionPlaceholder,
			generatedProperty: prospect_company_name,
			audienceRole: pageText.Buyer,
			detailsArray: [
				{ heading: pageText.BuyerCompany, text: prospect_company_name },
				{ heading: pageText.Description, text: prospect_company_description },
				{ heading: pageText.IntelAboutBuyer, text: call_notes }
			],
			detailsModalFields: [
				{ name: "prospect_company_name", label: pageText.BuyerCompany, type: "input" },
				{
					name: "prospect_company_description",
					label: pageText.Description,
					type: "textarea",
					rows: 5
				},
				{
					name: "call_notes",
					label: pageText.IntelAboutBuyer,
					type: "textarea",
					rows: 5
				}
			],
			intercomArticle: 6252956,
			warningCalloutText: pageText.DiscoveryWarningCalloutText
		},
		discovery_spin: {
			generateTitle: pageText.GenerateDiscoverySpin,
			generatedTitle:
				assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : `${pageText.DiscoverySpin}: ${product}`,
			titleProperty: "product",
			detailsProperty: "meeting_details",
			inputHeadings: [pageText.WhatAreYouSelling, pageText.MeetingDetails, pageText.ChooseYourBuyer],
			inputLabels: [pageText.DiscoverySpinInputLabelProductName, pageText.DiscoverySpinInputLabelAdditionalDetails],
			titlePlaceholder: pageText.DiscoverySpinTitlePlaceholder,
			detailsPlaceholder: pageText.DiscoverySpinDetailsPlaceholder,
			generatedProperty: prospect_company_name,
			audienceRole: pageText.Buyer,
			detailsArray: [
				{ heading: pageText.BuyerCompany, text: prospect_company_name },
				{ heading: pageText.Description, text: prospect_company_description },
				{ heading: pageText.IntelAboutBuyer, text: call_notes }
			],
			detailsModalFields: [
				{ name: "prospect_company_name", label: "Buyer Company", type: "input" },
				{
					name: "prospect_company_description",
					label: "Description",
					type: "textarea",
					rows: 5
				},
				{
					name: "call_notes",
					label: "Intel About Buyer",
					type: "textarea",
					rows: 5
				}
			],
			intercomArticle: 6252956,
			warningCalloutText: pageText.DiscoveryWarningCalloutText
		},
		closing: {
			generateTitle: pageText.GenerateClosingMeetingDryRun,
			generatedTitle:
				assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : `${pageText.ClosingMeetingDryRun}: ${product}`,
			titleProperty: "product",
			detailsProperty: "meeting_details",
			inputHeadings: [pageText.WhatAreYouSelling, pageText.MeetingDetails, pageText.ChooseYourBuyer],
			inputLabels: [pageText.EnterTheNameOfTheProductOrServiceYouAreSelling, pageText.OptionalEnterAnyAdditionalDetailsAboutTheMeeting],
			titlePlaceholder: pageText.ProductPlaceholder,
			detailsPlaceholder: pageText.AdditionalBuyerDetailsPlaceholder,
			generatedProperty: prospect_company_name,
			audienceRole: pageText.Buyer,
			detailsArray: [
				{ heading: pageText.BuyerCompany, text: prospect_company_name },
				{ heading: pageText.Description, text: prospect_company_description },
				{
					heading: pageText.Needs,
					text: `- ${buyer_need_1}\n- ${buyer_need_2}`
				},
				{
					heading: pageText.Objection,
					text: `- ${buyer_objection_1}\n- ${buyer_objection_2}`
				}
			],
			detailsModalFields: [
				{ name: "prospect_company_name", label: "Buyer Company", type: "input" },
				{
					name: "prospect_company_description",
					label: "Description",
					type: "textarea",
					rows: 5
				},
				{
					name: "buyer_need_1",
					label: "Needs",
					type: "input"
				},
				{
					name: "buyer_need_2",
					label: "Needs",
					type: "input"
				},
				{
					name: "buyer_objection_1",
					label: "Objections",
					type: "input"
				},
				{
					name: "buyer_objection_2",
					label: "Objections",
					type: "input"
				}
			],
			intercomArticle: 6252956,
			warningCalloutText: pageText.ClosingMeetingWarningCalloutText
		},
		presentation: {
			generateTitle: pageText.GeneratePresentation,
			generatedTitle: assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : `Presentation: ${topic}`,
			titleProperty: "topic",
			detailsProperty: "presentation_details",
			inputHeadings: [pageText.WhatIsYourPresentationAbout, pageText.PresentationDetails, pageText.ChoosePracticePartner],
			inputLabels: [pageText.EnterPresentationTopic, pageText.PresentationOptionalEnterAdditionalDetails],
			titlePlaceholder: pageText.PresentationTitlePlaceholder,
			detailsPlaceholder: pageText.PresentationDescriptionPlaceholder,
			generatedProperty: target_time,
			audienceRole: "Partner",
			detailsArray: [
				{ heading: pageText.PresentationTopic, text: topic },
				{ heading: pageText.Objective, text: objective },
				{ heading: pageText.TargetTime, text: `${target_time / 60} ${pageText.Minutes}` }
			],
			detailsModalFields: [
				{
					name: "topic",
					label: pageText.PresentationTopic,
					type: "input",
					title: true
				},
				{
					name: "objective",
					label: pageText.Objective,
					type: "dropdown",
					options: [
						{ value: "Persuade", label: pageText.Persuade },
						{ value: "Educate", label: pageText.Educate },
						{ value: "Entertain", label: pageText.Entertain },
						{ value: "Inspire", label: pageText.Inspire }
					]
				},
				{ name: "target_time", label: pageText.TargetTime, type: "number", unit: pageText.MinutesUnit }
			],
			intercomArticle: 6252956
		},
		retail: {
			generateTitle: "Generate Retail Sales Dry Run",
			generatedTitle: assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : `Retail Sale Dry Run: ${product}`,
			titleProperty: "product",
			detailsProperty: "additional_details",
			inputHeadings: ["What are you selling?", "Additional Details", "Choose Your Buyer"],
			inputLabels: ["Enter the name of the product or service you are selling", "Optional - Enter any additional details about the meeting"],
			titlePlaceholder: "e.g. Hiking boots",
			detailsPlaceholder: `Buyer: Avid Hiker looking for durable gear

- Needs high-quality hiking boots that provide comfort and support for long trails.

Our Company: TrailBlazers Outfitters
- We provide top-tier outdoor equipment, specializing in hiking, camping, and adventure sports, tailored to individual needs.

Our Solution: The Summit Series Hiking Boots, engineered for comfort and endurance. They feature advanced cushioning, waterproof materials, and a unique traction system, ensuring a perfect fit and superior performance on any terrain.`,
			generatedProperty: sales_rep_company_name,
			audienceRole: "Buyer",
			detailsArray: [
				{ heading: "Objective", text: "Get the sale" },
				{ heading: "Your Company", text: sales_rep_company_name },
				{ heading: "Company Description", text: sales_rep_company_description }
			],
			detailsModalFields: [
				{ name: "sales_rep_company_name", label: "Your Company", type: "input" },
				{
					name: "sales_rep_company_description",
					label: "Company Description",
					type: "textarea",
					rows: 5
				}
			],
			intercomArticle: 6252956,
			warningCalloutText: "This sales simulation is not a true representation of any mentioned company."
		},
		intro: {
			generateTitle: pageText.GenerateIntro,
			generatedTitle: assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : pageText.SelfIntro,
			titleProperty: "intro_event",
			detailsProperty: "intro_details",
			inputHeadings: [
				pageText.IntroWhereAreYouIntroducingYourself,
				pageText.IntroIntroductionDetails,
				pageText.IntroWhoAreYouIntroducingYourselfTo
			],
			inputLabels: [pageText.IntroEnterEvent, pageText.IntroOptionalEnterAdditionalDetails],
			titlePlaceholder: pageText.SelfIntroTitlePlaceholder,
			detailsPlaceholder: pageText.SelfIntroDescriptionPlaceholder,
			generatedProperty: audience_role,
			audienceRole: "",
			detailsArray: [
				{ heading: pageText.Event, text: intro_event },
				{ heading: pageText.Details, text: intro_details }
			],
			detailsModalFields: [
				{ name: "intro_event", label: pageText.Event, type: "input" },
				{
					name: "intro_details",
					label: pageText.Details,
					type: "textarea",
					rows: 5
				}
			],
			intercomArticle: 6252956,
			warningCalloutText: pageText.SelfIntroWarningCalloutText
		},
		freestyle: {
			generateTitle: "Generate Freestyle Simulation",
			generatedTitle: assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : "Freestyle Simulation",
			titleProperty: "convo_type",
			detailsProperty: "user_details",
			detailsProperty2: "audience_details",
			inputHeadings: [
				"What type of conversation are you having?",
				"What background information is relevant about yourself?",
				"What background information does the digital human know?",
				"Choose Your Practice Partner"
			],
			inputLabels: [
				"Enter the type of conversation you are having",
				"Enter any background information about yourself that is relevant to the conversation",
				"Enter any background information the digital human knows that is relevant to the conversation"
			],
			titlePlaceholder: "e.g. Friendly chat about owning a dog",
			detailsPlaceholder: `e.g. I'm a dog owner who loves to talk about my dog.

- I own a husky named Luna
- I'm a dog trainer`,
			detailsPlaceholder2: `e.g. The digital human does not own a dog but is interested in getting one.

- The digital human has never owned a dog before and is looking for advice on how to train one.`,
			generatedProperty: audience_role,
			audienceRole: "Partner",
			detailsArray: [
				{ heading: "Conversation Type", text: convo_type },
				{ heading: "Details About You", text: user_details },
				{ heading: "Details About Them", text: audience_details }
			],
			detailsModalFields: [
				{ name: "convo_type", label: "Conversation Type", type: "input" },
				{
					name: "user_details",
					label: "User Details",
					type: "textarea",
					rows: 5
				},
				{
					name: "audience_details",
					label: "Digital Human Details",
					type: "textarea",
					rows: 5
				}
			],
			intercomArticle: 6252956,
			warningCalloutText: "This freestyle simulation is not a true representation of any mentioned company."
		},
		patient: {
			generateTitle: "Generate Patient Simulation",
			generatedTitle: assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : "Patient Simulation",
			titleProperty: "visit_type",
			detailsProperty: "patient_bio",
			detailsProperty2: "patient_medical",
			inputHeadings: ["Field & Role", "Patient Biographical Information", "Patient Medical Information", "Choose Your Patient"],
			inputLabels: [
				"Required - select from dropdown",
				"Optional - paste or write in any relevant details",
				"Optional - paste or write in any relevant details"
			],
			detailsPlaceholder: "Name, Age, Weight, Gender, Occupation, Other",
			detailsPlaceholder2: "Current Wounds/Pain/Illness (if active, medical history, family medical history, current medication, etc.)",
			generatedProperty: patient_summary,
			audienceRole: "Patient",
			detailsArray: [
				{ heading: "Field & Role", text: user_role },
				{ heading: "Purpose of Visit", text: visit_type },
				{ heading: "Patient Summary", text: patient_summary },
				{ heading: "Patient Biographical Information", text: patient_bio },
				{ heading: "Patient Medical Information", text: patient_medical }
			],
			detailsModalFields: [
				{
					name: "user_role",
					label: "Field & Role",
					type: "dropdown",
					// options: [...patientSimUserRoles.map((role) => ({ value: role, label: role }))]
					options: [{ value: "Health Care Provider", label: "Health Care Provider" }]
				},
				{
					name: "visit_type",
					label: "Purpose of Visit",
					type: "dropdown",
					options: [...visitTypes.map((type) => ({ value: type, label: type }))]
				},
				{ name: "patient_summary", label: "Patient Summary", type: "textarea", rows: 5 },
				{ name: "patient_bio", label: "Biographical Information", type: "object" },
				{ name: "patient_medical", label: "Medical Information", type: "object" }
			],
			intercomArticle: 6252956
		},
		pitch: {
			generateTitle: pageText.GenerateElevatorPitch,
			generatedTitle:
				assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : `${pageText.ElevatorPitchFor} ${pitch_topic}`,
			titleProperty: "pitch_topic",
			detailsProperty: "pitch_details",
			inputHeadings: [
				pageText.ElevatorPitchWhatIsYourElevatorPitchAbout,
				pageText.ElevatorPitchPitchDetails,
				pageText.ElevatorPitchChooseYourPracticeAudience
			],
			inputLabels: [pageText.ElevatorPitchEnterMainTopic, pageText.ElevatorPitchOptionalEnterAdditionalDetails],
			titlePlaceholder: pageText.ElevatorPitchTitlePlaceholder,
			detailsPlaceholder: pageText.ElevatorPitchDescriptionPlaceholder,
			generatedProperty: audience_role,
			audienceRole: pageText.AudienceMember,
			detailsArray: [
				{ heading: pageText.PitchTopic, text: pitch_topic },
				{ heading: pageText.PitchDetails, text: pitch_details },
				{ heading: pageText.PitchLength, text: `${target_time} seconds` }
			],
			detailsModalFields: [
				{
					name: "pitch_topic",
					label: pageText.PitchTopic,
					type: "input",
					title: true
				},
				{
					name: "pitch_details",
					label: pageText.PitchDetails,
					type: "textarea",
					rows: 5
				},
				{ name: "target_time", label: pageText.TargetTime, type: "number", unit: pageText.SecondsUnit }
			],
			intercomArticle: 6252956,
			warningCalloutText: pageText.ElevatorPitchWarningCalloutText
		},
		business_pitch: {
			generateTitle: pageText.GenerateBusinessPitch,
			generatedTitle:
				assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : `${pageText.BusinessPitchFor} ${pitch_topic}`,
			titleProperty: "pitch_topic",
			detailsProperty: "pitch_details",
			inputHeadings: [
				pageText.BusinessPitchWhatIsYourBusinessPitchAbout,
				pageText.BusinessPitchPitchDetails,
				pageText.BusinessPitchChooseYourPracticeAudience
			],
			inputLabels: [pageText.BusinessPitchEnterMainTopic, pageText.BusinessPitchOptionalEnterAdditionalDetails],
			titlePlaceholder: pageText.BusinessPitchTitlePlaceholder,
			detailsPlaceholder: pageText.BusinessPitchDescriptionPlaceholder,
			generatedProperty: audience_role,
			audienceRole: pageText.AudienceMember,
			detailsArray: [
				{ heading: pageText.PitchTopic, text: pitch_topic },
				{ heading: pageText.PitchDetails, text: pitch_details },
				{ heading: pageText.PitchLength, text: `${target_time / 60} ${pageText.Minutes}` }
			],
			detailsModalFields: [
				{
					name: "pitch_topic",
					label: pageText.PitchTopic,
					type: "input",
					title: true
				},
				{
					name: "pitch_details",
					label: pageText.PitchDetails,
					type: "textarea",
					rows: 5
				},
				{ name: "target_time", label: pageText.TargetTime, type: "number", unit: pageText.MinutesUnit }
			],
			intercomArticle: 6252956,
			warningCalloutText: pageText.BusinessPitchWarningCalloutText
		},
		reflection: {
			generateTitle: pageText.GenerateReflection,
			generatedTitle: assignmentData && assignmentData.assignment_name ? assignmentData.assignment_name : `Reflection for ${experience_type}`,
			titleProperty: "experience_type",
			detailsProperty: "experience_details",
			detailsHelpers: [
				pageText.WhereDidItTakePlace,
				pageText.WhenDidYouParticipateInThis,
				pageText.HowLongWasYourExperience,
				pageText.WhatDidTheExperienceEntail
			],
			inputHeadings: [pageText.WhatTypeOfExperience, pageText.ExperienceDetails],
			inputLabels: [pageText.SelectExperienceToReflectOn, pageText.OptionalEnterAnyAdditionalDetailsReflection],
			titlePlaceholder: pageText.ExampleCoop,
			detailsPlaceholder: pageText.ExampleCoopDetails,
			generatedProperty: phase,
			audienceRole: "Interviewer",
			detailsArray: [
				{ heading: pageText.ExperienceType, text: experience_type },
				{ heading: pageText.ExperienceDescription, text: experience_type_description },
				...(competency_list.length > 0 ? [{ heading: pageText.Competencies, text: competency_list }] : [])
			],
			detailsModalFields: [
				{
					name: "experience_type",
					label: "Experience Type",
					type: "dropdown",
					title: true,
					options: [...dynamicPageText.map((type) => ({ value: type.label, label: type.label }))]
				},
				{
					name: "competency_list",
					label: "Competencies",
					type: "multiselect"
				}
			],
			intercomArticle: 6252956
		}
	};

	function resetSetupData() {
		const tempData = { ...setupData };
		tempData[setupConfig[setupData.setup_type].generatedProperty] = "";
		setSetupData(tempData);
		setSelectedPresentationType("");
		setShowEditModal(false);
		setShowPTModal(false);
		editSimDetails();
	}

	function handleTitleChange(e) {
		if (e.target.value.length > titleLimit) {
			setError1(true);
		} else if (e.target.value.length <= titleLimit) {
			setError1(false);
		}
		const titleKey = setupConfig[setupData.setup_type].titleProperty;
		// console.log("titleKey", titleKey, e.target.value);
		setSetupData((prevSetupData) => ({
			...prevSetupData,
			[titleKey]: e.target.value
		}));
	}

	function handleDetailsChange(e) {
		if (e.target.value.length > detailsLimit) {
			setError2(true);
		} else if (e.target.value.length <= detailsLimit) {
			setError2(false);
		}
		const detailsKey = setupConfig[setupData.setup_type].detailsProperty;
		setSetupData((prevSetupData) => ({
			...prevSetupData,
			[detailsKey]: e.target.value
		}));
	}

	function handleDetailsChange2(e) {
		if (e.target.value.length > detailsLimit) {
			setError3(true);
		} else if (e.target.value.length <= detailsLimit) {
			setError3(false);
		}
		const detailsKey = setupConfig[setupData.setup_type].detailsProperty2;
		setSetupData((prevSetupData) => ({
			...prevSetupData,
			[detailsKey]: e.target.value
		}));
	}

	function handleSituationChange(e, situationKey) {
		setError3(false);
		setSetupData((prevSetupData) => ({
			...prevSetupData,
			[situationKey]: e.target.value
		}));
	}

	const handleCompetencyChange = (newCompetencyList) => {
		setError3(false);
		setSetupData((prevSetupData) => ({
			...prevSetupData,
			competency_list: newCompetencyList
		}));
	};

	const determineDisplayStyle = (stateToCheck) => ({
		display: stateToCheck || setupData?.setup_type !== "interview" ? "block" : "none"
	});

	useEffect(() => {
		const checkVisit = async () => {
			const isFirstVisit = await checkPageVisit("setup");
			// console.log(`Is first visit: ${isFirstVisit}`); // Debugging

			if (isFirstVisit) {
				// // console.log("Welcome back to this page");
				setWasFirstVisit(true);
			} else {
				// // console.log("This is your first visit to this page.");
				setShowPTModal(true);
				setShowInfo_instructions(true);
				// Logic for subsequent visits
			}
		};

		checkVisit();
	}, [checkPageVisit]);

	const handleButtonClick = () => {
		// console.log("FirstVisit?", wasFirstVisit);
		if (!wasFirstVisit) {
			setShowPTModal(true);
			setShowInfo_instructions(true);
			setGenerateButtonClickedState(true);
		}
		// Any additional logic to run when the button is clicked
	};

	useOutsideClick(infoRef_instructions, () => {
		if (showInfo_instructions) {
			setShowInfo_instructions(false);
		}
	});

	useOutsideClick(infoRef_details, () => {
		if (showInfo_details) {
			setShowInfo_details(false);
		}
	});

	useOutsideClick(infoRef_details2, () => {
		if (showInfo_details2) {
			setShowInfo_details2(false);
		}
	});

	useOutsideClick(infoRef_resume, () => {
		if (showInfo_resume) {
			setShowInfo_resume(false);
		}
	});

	useOutsideClick(infoRef_audience, () => {
		if (showInfo_audience) {
			setShowInfo_audience(false);
		}
	});

	useOutsideClick(dropdownRef, () => {
		if (dropdownOpen) {
			setDropdownOpen(false);
		}
	});
	// useEffect(() => {
	// 	runTranslation();
	// });

	return (
		<>
			{setupData?.setup_type === "interview" && <ResumeModal show={showResumeModal} onHide={() => setShowResumeModal(false)} />}
			{setupData && setupConfig[setupData.setup_type] && (
				<div className={styles.setupPageContainer}>
					{!simulationForbidden &&
						(generateStarted === undefined || (generateStarted && !setupConfig[setupData.setup_type].generatedProperty)) && (
							<div className={styles.spinnerContainer}>
								<LoadingSpinner height={80} width={80} thickness={10} color="#00a9af" />
							</div>
						)}
					{simulationForbidden && (
						<>
							<div className={styles.headingContainer}>
								<p className={styles.setupHeading} style={{ display: "unset" }}>
									<p style={{ color: "#898989" }}>No Access.</p>
									<p>You do not have access to this simulation. Log in or ask user to share it.</p>
								</p>
							</div>
						</>
					)}

					{generateStarted === false && (
						<>
							{assignmentData.assignment_type === "dynamic" && setupData?.setup_type === "interview" && getLtik() && !getLmsToken() && (
								<QuestionGradingRubric />
							)}

							<div className={styles.headingContainer}>
								<h1 className={styles.setupHeading}>
									<span>{setupConfig[setupData.setup_type].generateTitle}</span>
									<span className={styles.infoIconContainer}>
										<span
											role="button"
											tabIndex={0}
											className={styles.infoIconHeading}
											onClick={(event) => {
												event.stopPropagation();
												console.log("activeTab", activeTab);
												if (activeTab === 1) {
													setInfoTab("resume");
												} else if (activeTab === 2) {
													setInfoTab("Cover Letter");
												}
												setShowInfo_instructions(true);
												setShowPTModal(true);
											}}
											onKeyDown={(event) => {
												if (event.key === "Enter") {
													if (activeTab === 1) {
														setInfoTab("resume");
													} else if (activeTab === 2) {
														setInfoTab("Cover Letter");
													}
													setShowInfo_instructions(true);
													setShowPTModal(true);
												}
											}}
										>
											{!getEmptySims().includes(setupData.setup_type) && <RxQuestionMarkCircled />}
										</span>
										{showInfo_instructions && (
											<span onClick={(event) => event.stopPropagation()}>
												{showPTModal && !getEmptySims().includes(setupData.setup_type) && (
													<ProductTourModal
														setShowPTModal={setShowPTModal}
														content={
															activeTab === 0
																? getSetupTourContent(setupData.setup_type, generateStarted)
																: getAssistTourContent(infoTab, true)
														}
													/>
												)}
											</span>
										)}
									</span>
								</h1>

								<ShareModal
									show={showShareModal}
									onHide={() => setShowShareModal(false)}
									instage_id={localUser?.id}
									tenantId={localUser?.tenantId}
								/>

								{(adminAccess || (assignmentData.assignment_type !== null && assignmentData.assignment_type !== "")) &&
									!getLtik() && (
										<div className={styles.headingBtnContainer}>
											{/* if edit access and EITHER is edu and is marked as an assignment OR is not edu. Then show share button */}
											{editAccess &&
												// Show this button only if clientType is not "edu" OR showShareButton is true
												(clientType !== "edu" || showShareButton ? (
													<button type="button" className={s.headerBtnLarge} onClick={() => setShowShareModal(true)}>
														<FiShare className={s.headerBtnIcon} />
														{clientType === "edu" ? pageText.ShareAssignment : pageText.Share}
													</button>
												) : (
													// Show this button if the above conditions are not met
													<div className={s.tooltipWrapper}>
														<button type="button" className={s.headerBtnLargeDisabled}>
															<FiShare className={s.headerBtnIcon} />
															{clientType === "edu" ? pageText.ShareAssignment : pageText.Share}
														</button>
														<span className={s.tooltip}>{pageText.EditInstructionsBeforeSharing}</span>
													</div>
												))}
											<button
												type="button"
												className={styles.adminBtn}
												onClick={() => {
													setNewAssignmentType("dynamic");
													setShowAssignmentModal(true);
												}}
											>
												<MdOutlineAssignment className={styles.adminBtnIcon} />
												{pageText.Instructions}
											</button>
										</div>
									)}
							</div>

							<div className={styles.headingDescription}>
								<p className={styles.inputLabels}>{pageText.setupDescription}</p>
							</div>
							{getLmsAssignmentType() === "dynamic" && (
								<>
									<input
										ref={inputRef}
										className={styles.input}
										value={setupData[setupConfig[setupData.setup_type].titleProperty] || ""}
										onChange={(e) => {
											handleTitleChange(e);
											setSetupData((prevSetupData) => ({
												...prevSetupData,
												setup_name: e.target.value,
												setup_input: ""
											}));
										}}
										placeholder={setupConfig[setupData.setup_type].titlePlaceholder}
									/>
									<br />

									{!getLmsToken() && getLtik() && getLmsAssignmentType() !== "static" && (
										<button type="button" className={styles.startBtn} onClick={() => summitDeeplink()}>
											Submit
										</button>
									)}
								</>
							)}
							{getLmsAssignmentType() !== "dynamic" && (
								<>
									{(setupData?.setup_type === "presentation" ||
										((setupData?.setup_type === "intro" ||
											setupData?.setup_type === "pitch" ||
											setupData?.setup_type === "business_pitch") &&
											selectedPresentationType !== null)) &&
										selectedPresentationType !== "" && (
											<div className={styles.inputSection}>
												<div className={styles.inputTextSection}>
													<p className={styles.inputHeading}>
														<span>{pageText.ChooseYourPresentationType}</span>
														<span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>
													</p>
												</div>
												<div className={styles.inputUserEntrySection}>
													<select
														ref={dropdownRef1}
														className={styles.input}
														style={{ maxWidth: "20rem" }}
														value={selectedPresentationType.value}
														onChange={(event) => {
															setSelectedPresentationOption(event.target.value);
															const selectedValue = event.target.value;
															const selectedOption = presentationOptions.find(
																(option) => option.value === selectedValue
															);
															setSelectedPresentationType(selectedOption);
															setSetupData((prevSetupData) => ({
																...prevSetupData,
																setup_type: selectedValue,
																setup_id: selectedValue
															}));
															handleNavigate(`/setup/${selectedValue}${simId ? `/${simId}` : ""}`);
														}}
													>
														{presentationOptions.map((option) => (
															<option key={option.value} value={option.value}>
																{option.label}
															</option>
														))}
													</select>
												</div>
											</div>
										)}
									{(setupData?.setup_type !== "presentation" || selectedPresentationType !== null) && (
										<>
											<div
												className={styles.inputSection}
												style={{ minHeight: setupData?.setup_type === "interview" ? "8rem" : "inherit" }}
											>
												<div className={styles.inputTextSection}>
													<p className={styles.inputHeading}>
														<span>{setupConfig[setupData.setup_type].inputHeadings[0]}</span>
														<span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>
													</p>
													<p className={styles.inputLabel}>{setupConfig[setupData.setup_type].inputLabels[0]}</p>
												</div>
												<div className={styles.inputUserEntrySection}>
													{setupData?.setup_type === "patient" || setupData?.setup_type === "reflection" ? (
														<select
															ref={dropdownRef1}
															className={styles.input}
															style={{ maxWidth: "20rem" }}
															onChange={(event) => {
																if (setupData?.setup_type !== "patient") {
																	const selectedType = dynamicPageText.find(
																		(type) => type.label === event.target.value
																	);
																	setSetupData((prevSetupData) => ({
																		...prevSetupData,
																		experience_type: selectedType.label,
																		experience_type_description: selectedType.description
																	}));
																} else {
																	setSetupData((prevSetupData) => ({
																		...prevSetupData,
																		user_role: event.target.value
																	}));
																}
															}}
														>
															{setupData?.setup_type === "patient" && setupData?.user_role === "" && (
																<option value="">Select a Role</option>
															)}
															{setupData?.setup_type === "reflection" && setupData?.experience_type === "" && (
																<option value="">{pageText.SelectExperienceType}</option>
															)}
															{(setupData?.setup_type === "patient"
																? patientSimUserRoles
																: dynamicPageText.map((type) => type.label)
															).map((item) => (
																<option
																	key={item}
																	value={item}
																	disabled={setupData?.setup_type === "patient" && item !== "Health Care Provider"}
																>
																	{item}
																</option>
															))}
														</select>
													) : setupData?.setup_type === "interview" ? (
														<JobTitleSelect
															selectRef={selectRef}
															selectedJobTitle={selectedJobTitle}
															setSelectedJobTitle={setSelectedJobTitle}
															handleTitleChange={handleTitleChange}
															handleDetailsChange={handleDetailsChange}
															setJobOptions={setJobOptions}
														/>
													) : (
														<input
															ref={inputRef}
															className={styles.input}
															value={setupData[setupConfig[setupData.setup_type].titleProperty] || ""}
															onChange={handleTitleChange}
															placeholder={setupConfig[setupData.setup_type].titlePlaceholder}
														/>
													)}
													{setupData?.setup_type === "interview" && job_title !== "" && (
														<JobOptions
															job_title={job_title}
															options={jobOptions}
															setSelectedJobTitle={setSelectedJobTitle}
															handleDetailsChange={handleDetailsChange}
														/>
													)}
												</div>
											</div>
											{setupData?.setup_type === "patient" && (
												<div className={styles.inputSection}>
													<div className={styles.inputTextSection}>
														<p className={styles.inputHeading}>
															Purpose of Patient Visit
															<span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>
														</p>
														<p className={styles.inputLabel}>{setupConfig[setupData.setup_type].inputLabels[0]}</p>
													</div>
													<div className={styles.inputUserEntrySection}>
														<select
															ref={dropdownRef2}
															className={styles.input}
															style={{ maxWidth: "20rem" }}
															onChange={(event) => {
																setSetupData((prevSetupData) => ({
																	...prevSetupData,
																	visit_type: event.target.value
																}));
															}}
														>
															<option value="">Select a Purpose</option>
															{visitTypes.map((type) => (
																<option key={type} value={type}>
																	{type}
																</option>
															))}
														</select>
													</div>
												</div>
											)}
											<div
												className={`${styles.inputSection} ${
													setupData?.setup_type === "interview" ? styles.collapsibleInputSection : ""
												} ${showInputSections.inputDetails ? styles.expanded : styles.collapsed}`}
											>
												<InterviewWrapper
													condition={setupData?.setup_type === "interview"}
													wrapper={(children) => <div className={styles.interviewInputWrapper}>{children}</div>}
												>
													<>
														<div className={styles.inputTextSection}>
															<p className={styles.inputHeading}>
																{setupConfig[setupData.setup_type].inputHeadings[1]}
																<span className={styles.infoIconContainer}>
																	<span
																		className={styles.infoIconHeading}
																		onClick={(event) => {
																			event.stopPropagation();
																			setShowInfo_details(true);
																		}}
																	>
																		<FiInfo />
																	</span>
																	{showInfo_details && (
																		<span
																			className={styles.infoTooltipSubheading}
																			ref={infoRef_details}
																			onClick={(event) => event.stopPropagation()}
																		>
																			<span>{pageText.DescriptionTip}</span>
																		</span>
																	)}
																</span>
															</p>
															<p
																className={styles.inputLabel}
																style={determineDisplayStyle(showInputSections.inputDetails)}
															>
																{setupConfig[setupData.setup_type].inputLabels[1]}
															</p>
															{error2 && <p className={styles.error}>{error2Text}</p>}
															{setupConfig[setupData.setup_type].detailsHelpers && (
																<ol>
																	{setupConfig[setupData.setup_type].detailsHelpers?.map((helper, index) => (
																		<li key={index} className={styles.helperText}>
																			{helper}
																		</li>
																	))}
																</ol>
															)}
														</div>
														<div className={styles.inputUserEntrySection}>
															<textarea
																ref={textareaRef}
																className={styles.textarea}
																style={determineDisplayStyle(showInputSections.inputDetails)}
																value={setupData[setupConfig[setupData.setup_type].detailsProperty] || ""}
																onChange={handleDetailsChange}
																placeholder={setupConfig[setupData.setup_type].detailsPlaceholder}
															/>
														</div>
													</>
												</InterviewWrapper>
												{setupData?.setup_type === "interview" && (
													<div
														className={styles.inputChevronContainer}
														role="button"
														aria-label="Toggle input details"
														tabIndex={0}
														onClick={() => {
															setShowInputSections((prevState) => ({
																...prevState,
																inputDetails: !prevState.inputDetails
															}));
														}}
														onKeyDown={(event) => {
															if (event.key === "Enter" || event.key === " ") {
																setShowInputSections((prevState) => ({
																	...prevState,
																	inputDetails: !prevState.inputDetails
																}));
																event.preventDefault();
															}
														}}
													>
														<FiChevronRight
															className={`${styles.inputChevron} ${
																showInputSections.inputDetails ? styles.inputChevronDown : styles.inputChevronRight
															}`}
														/>
													</div>
												)}
											</div>
											{setupData?.setup_type === "interview" && !getLtik() && (
												<div
													className={`${styles.inputSection} ${styles.collapsibleInputSection} ${
														showInputSections.inputResume ? styles.expanded : styles.collapsed
													}`}
												>
													<div className={styles.interviewInputWrapper}>
														<div className={styles.inputTextSection}>
															<div className={styles.inputHeading} style={{ position: "relative" }}>
																<span>{pageText.InterviewInputHeadingYourResume}</span>
																<span className={styles.infoIconContainer}>
																	<span
																		className={styles.infoIconHeading}
																		onClick={(event) => {
																			event.stopPropagation();
																			setShowInfo_resume(true);
																		}}
																	>
																		<FiInfo />
																	</span>
																	{showInfo_resume && (
																		<span
																			className={styles.infoTooltipSubheading}
																			ref={infoRef_resume}
																			onClick={(event) => event.stopPropagation()}
																		>
																			<span>{pageText.ResumeUploadTip}</span>
																		</span>
																	)}
																</span>
																{/* {resumeAssistAccess && (
																	<div
																		className={styles.tooltipContainer}
																		style={{
																			position: "absolute",
																			right: "0",
																			...determineDisplayStyle(showInputSections.inputResume)
																		}}
																	>
																		<div
																			className={styles.earlyAccessTag}
																			style={{ height: "1.65rem", borderRadius: "0.825rem" }}
																		>
																			<span>{pageText.EarlyAccess}</span>
																			<FiStar />
																		</div>
																		<span className={styles.earlyAccessText} style={{ top: "2.25rem" }}>
																			{pageText.EarlyAccessFeatureText}
																		</span>
																	</div>
																)} */}
															</div>
															<p
																className={styles.inputLabel}
																style={determineDisplayStyle(showInputSections.inputResume)}
															>
																{resumeAssistAccess
																	? `${pageText.InterviewUploadResumeInputLabelEnabled}`
																	: `${pageText.InterviewUploadResumeInputLabelDisabled}`}
															</p>
														</div>
														<div className={styles.inputUserEntrySection}>
															{resumeAssistAccess ? (
																<div style={determineDisplayStyle(showInputSections.inputResume)}>
																	<ResumeUpload type="resume" />
																	<ResumeUpload type="cover" />
																</div>
															) : (
																<p
																	className={styles.resumeUploadText}
																	style={{
																		margin: "1rem 0",
																		...determineDisplayStyle(showInputSections.inputResume)
																	}}
																>
																	{localUser && localUser.resume_details
																		? "Resume saved to your account. To update your resume, "
																		: "To add your resume, "}
																	<span
																		className={styles.resumeUploadTextLink}
																		onClick={() => setShowResumeModal(true)}
																	>
																		click here
																	</span>
																	.
																</p>
															)}
														</div>
													</div>
													{setupData?.setup_type === "interview" && (
														<div
															className={styles.inputChevronContainer}
															role="button"
															aria-label="Toggle input resume"
															tabIndex={0}
															onClick={() => {
																setShowInputSections((prevState) => ({
																	...prevState,
																	inputResume: !prevState.inputResume
																}));
															}}
															onKeyDown={(event) => {
																if (event.key === "Enter" || event.key === " ") {
																	setShowInputSections((prevState) => ({
																		...prevState,
																		inputResume: !prevState.inputResume
																	}));
																	event.preventDefault();
																}
															}}
														>
															<FiChevronRight
																className={`${styles.inputChevron} ${
																	showInputSections.inputResume ? styles.inputChevronDown : styles.inputChevronRight
																}`}
															/>
														</div>
													)}
												</div>
											)}
											{setupData?.setup_type === "discovery_spin" && (
												<>
													<div className={styles.inputSection}>
														<div className={styles.inputTextSection}>
															<p className={styles.inputHeading}>
																{pageText.BuyerCurrentSituation} #1
																<span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>
															</p>
															<p className={styles.inputLabel}>{pageText.BuyerCurrentSituationLabel}</p>
														</div>
														<div className={styles.inputUserEntrySection}>
															<textarea
																className={styles.textarea}
																style={{ minHeight: "5rem" }}
																value={setupData.buyerSituation1}
																onChange={(e) => handleSituationChange(e, "buyerSituation1")}
																placeholder={pageText.BuyerCurrentSituationPlaceholder}
															/>
														</div>
													</div>

													<div className={styles.inputSection}>
														<div className={styles.inputTextSection}>
															<p className={styles.inputHeading}>
																{pageText.BuyerCurrentSituation} #2
																<span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>
															</p>
															<p className={styles.inputLabel}>{pageText.BuyerCurrentSituationLabel}</p>
														</div>
														<div className={styles.inputUserEntrySection}>
															<textarea
																className={styles.textarea}
																style={{ minHeight: "5rem" }}
																value={setupData.buyerSituation2}
																onChange={(e) => handleSituationChange(e, "buyerSituation2")}
																placeholder={pageText.BuyerCurrentSituation2Placeholder}
															/>
														</div>
													</div>
												</>
											)}
											{setupData?.setup_type === "closing" && (
												<>
													<div className={styles.inputSection}>
														<div className={styles.inputTextSection}>
															<p className={styles.inputHeading}>
																{pageText.BuyersNeed1}
																<span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>
															</p>
															<p className={styles.inputLabel}>{pageText.BuyerNeedLabel}</p>
														</div>
														<div className={styles.inputUserEntrySection}>
															<textarea
																className={styles.textarea}
																style={{ minHeight: "5rem" }}
																value={setupData.buyerNeed1}
																onChange={(e) => handleSituationChange(e, "buyerNeed1")}
																placeholder={pageText.BuyerNeed1Placeholder}
															/>
														</div>
													</div>
													<div className={styles.inputSection}>
														<div className={styles.inputTextSection}>
															<p className={styles.inputHeading}>
																{pageText.BuyersNeed2}
																<span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>
															</p>
															<p className={styles.inputLabel}>{pageText.BuyerNeedLabel}</p>
														</div>
														<div className={styles.inputUserEntrySection}>
															<textarea
																className={styles.textarea}
																style={{ minHeight: "5rem" }}
																value={setupData.buyerNeed2}
																onChange={(e) => handleSituationChange(e, "buyerNeed2")}
																placeholder={pageText.BuyerNeed2Placeholder}
															/>
														</div>
													</div>
													<div className={styles.inputSection}>
														<div className={styles.inputTextSection}>
															<p className={styles.inputHeading}>
																{pageText.BuyersObjection1}
																<span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>
															</p>
															<p className={styles.inputLabel}>{pageText.BuyerObjectionLabel}</p>
														</div>
														<div className={styles.inputUserEntrySection}>
															<textarea
																className={styles.textarea}
																style={{ minHeight: "5rem" }}
																value={setupData.buyerObjection1}
																onChange={(e) => handleSituationChange(e, "buyerObjection1")}
																placeholder={pageText.BuyerObjection1Placeholder}
															/>
														</div>
													</div>

													<div className={styles.inputSection}>
														<div className={styles.inputTextSection}>
															<p className={styles.inputHeading}>
																{pageText.BuyersObjection2}
																<span style={{ color: "#ee4188", marginLeft: "2px" }}>*</span>
															</p>
															<p className={styles.inputLabel}>{pageText.BuyerObjectionLabel}</p>
														</div>
														<div className={styles.inputUserEntrySection}>
															<textarea
																className={styles.textarea}
																style={{ minHeight: "5rem" }}
																value={setupData.buyerObjection2}
																onChange={(e) => handleSituationChange(e, "buyerObjection2")}
																placeholder={pageText.BuyerObjection2Placeholder}
															/>
														</div>
													</div>
												</>
											)}
											{["freestyle", "patient"].includes(setupData?.setup_type) && (
												<div className={styles.inputSection}>
													<div className={styles.inputTextSection}>
														<p className={styles.inputHeading}>
															{setupConfig[setupData.setup_type].inputHeadings[2]}
															<span className={styles.infoIconContainer}>
																<span
																	className={styles.infoIconHeading}
																	onClick={(event) => {
																		event.stopPropagation();
																		setShowInfo_details2(true);
																	}}
																>
																	<FiInfo />
																</span>
																{showInfo_details2 && (
																	<span
																		className={styles.infoTooltipSubheading}
																		ref={infoRef_details2}
																		onClick={(event) => event.stopPropagation()}
																	>
																		<span>
																			Entering in these extra details is recommended but not mandatory as this
																			will add more personal touch to your simulation by allowing the avatars to
																			pull specific information that is put here and use it while speaking with
																			you.
																		</span>
																	</span>
																)}
															</span>
														</p>
														<p className={styles.inputLabel}>{setupConfig[setupData.setup_type].inputLabels[2]}</p>
														{error3 && <p className={styles.error}>{error3Text}</p>}
													</div>
													<div className={styles.inputUserEntrySection}>
														<textarea
															className={styles.textarea}
															value={setupData[setupConfig[setupData.setup_type].detailsProperty2] || ""}
															onChange={handleDetailsChange2}
															placeholder={setupConfig[setupData.setup_type].detailsPlaceholder2}
														/>
													</div>
												</div>
											)}
											{setupData?.setup_type === "reflection" && (
												<div className={styles.inputSection}>
													<div className={styles.inputTextSection}>
														<p className={styles.inputHeading}>{pageText.Competencies}</p>
														<p className={styles.inputLabel}>{pageText.OptionalSelect5Competencies}</p>
													</div>
													<div className={styles.inputUserEntrySection}>
														<MultiSelect
															competencies={Competencies}
															selectedCompetencies={setupData.competency_list}
															onCompetencyChange={handleCompetencyChange}
														/>
													</div>
												</div>
											)}

											{!simDeleted && setupData !== undefined && (
												<GenerateButton
													inputRef={inputRef}
													selectRef={selectRef}
													textareaRef={textareaRef}
													dropdownRef1={dropdownRef1}
													dropdownRef2={dropdownRef2}
													generateStarted={generateStarted}
													generateSim={generateSim}
													setError1={setError1}
													error1={error1}
													error2={error2}
													error3={error3}
													error1Text={error1Text}
													error3Text={error3Text}
													handleModal={handleButtonClick}
												/>
											)}
										</>
									)}
								</>
							)}
						</>
					)}
					{generateStarted &&
						setupConfig[setupData.setup_type].generatedProperty !== undefined &&
						setupConfig[setupData.setup_type].generatedProperty !== "" && (
							<>
								{getLmsAssignmentType() === "static" && <QuestionGradingRubric />}
								{showEditModal && (
									<DetailsModal
										data={setupData}
										setData={setSetupData}
										simulation_id={simulation_id}
										modalOpen={showEditModal}
										setModalOpen={setShowEditModal}
										fields={setupConfig[setupData.setup_type].detailsModalFields}
									/>
								)}
								<div className={styles.headingContainer}>
									<h1 className={styles.setupHeading}>
										{setupConfig[setupData.setup_type].generatedTitle}

										<span className={styles.infoIconContainer}>
											<span
												role="button"
												tabIndex={0}
												className={styles.infoIconHeading}
												onClick={(event) => {
													event.stopPropagation();
													console.log("activeTab", activeTab);
													if (activeTab === 1) {
														setInfoTab("resume");
													} else if (activeTab === 2) {
														setInfoTab("Cover Letter");
													}
													setShowInfo_instructions(true);
													setShowPTModal(true);
												}}
												onKeyDown={(event) => {
													if (event.key === "Enter") {
														if (activeTab === 1) {
															setInfoTab("resume");
														} else if (activeTab === 2) {
															setInfoTab("Cover Letter");
														}
														setShowInfo_instructions(true);
														setShowPTModal(true);
													}
												}}
											>
												{!getEmptySims().includes(setupData.setup_type) && <RxQuestionMarkCircled />}
											</span>
											{(showInfo_instructions || generateButtonClickedState) && (
												<span onClick={(event) => event.stopPropagation()}>
													{showPTModal && !getEmptySims().includes(setupData.setup_type) && (
														<ProductTourModal
															setShowPTModal={setShowPTModal}
															content={
																activeTab === 0
																	? getSetupTourContent(setupData.setup_type, generateStarted)
																	: getAssistTourContent(infoTab, true)
															}
														/>
													)}
												</span>
											)}
										</span>
									</h1>
									<ShareModal
										show={showShareModal}
										onHide={() => setShowShareModal(false)}
										instage_id={localUser?.id}
										tenantId={localUser?.tenantId}
									/>

									{!getLmsAssignmentType() && (
										<div className={styles.headingBtnContainer}>
											{editAccess &&
												// Show this button only if clientType is not "edu" OR showShareButton is true
												(clientType !== "edu" || showShareButton ? (
													<button type="button" className={s.headerBtnLarge} onClick={() => setShowShareModal(true)}>
														<FiShare className={s.headerBtnIcon} />
														{clientType === "edu" ? pageText.ShareAssignment : pageText.Share}
													</button>
												) : (
													// Show this button if the above conditions are not met
													<div className={s.tooltipWrapper}>
														<button type="button" className={s.headerBtnLargeDisabled}>
															<FiShare className={s.headerBtnIcon} />
															{clientType === "edu" ? pageText.ShareAssignment : pageText.Share}
														</button>
														<span className={s.tooltip}>{pageText.EditInstructionsBeforeSharing}</span>
													</div>
												))}
											{(adminAccess || assignmentData.assignment_type !== "") && (
												<button
													type="button"
													className={styles.adminBtn}
													onClick={() => {
														setNewAssignmentType("static");
														setShowAssignmentModal(true);
													}}
												>
													<MdOutlineAssignment className={styles.adminBtnIcon} />
													{pageText.Instructions}
												</button>
											)}
											{/* {!showEarlyAccess &&
												setupConfig[setupData.setup_type].generatedProperty !== "" &&
												setupData?.setup_type === "interview" && (
													<div className={styles.tooltipContainer}>
														<div className={styles.earlyAccessTag}>
															{pageText.earlyAccess}
															<FiStar />
														</div>
														<span className={styles.earlyAccessText}>
															This feature is under development and may require additional fees in the future.
														</span>
													</div>
												)} */}
											{editAccess && setupConfig[setupData.setup_type].generatedProperty !== "" && (
												<div>
													<button
														type="button"
														className={styles.adminBtn}
														onClick={(event) => {
															event.stopPropagation();
															setDropdownOpen(!dropdownOpen);
														}}
													>
														<FiEdit className={styles.adminBtnIcon} />
														{pageText.Edit}
													</button>
													{dropdownOpen && (
														<div
															className={styles.dropdownContent}
															ref={dropdownRef}
															onClick={(event) => event.stopPropagation()}
														>
															<button
																type="button"
																className={styles.dropdownContentText}
																style={{ borderTopLeftRadius: "0.25rem", borderTopRightRadius: "0.25rem" }}
																onClick={() => {
																	setShowEditModal(true);
																	setDropdownOpen(false);
																}}
															>
																<FiEdit2 />
																{pageText.Current}
															</button>
															<button
																type="button"
																className={styles.dropdownContentText}
																style={{ borderBottomLeftRadius: "0.25rem", borderBottomRightRadius: "0.25rem" }}
																onClick={() => {
																	resetSetupData();
																	setDropdownOpen(false);
																}}
															>
																<FiRotateCcw />
																{pageText.Initial}
															</button>
														</div>
													)}
												</div>
											)}
											{!adminAccess && !resumeAssistAccess && setupData?.question_list?.some((q) => q.type === "Resume") && (
												<div style={{ position: "relative" }}>
													<button
														type="button"
														className={styles.resumeBtn}
														onClick={(event) => {
															setShowResumeModal(true);
														}}
													>
														<FiEdit className={styles.adminBtnIcon} />
														{pageText.Resume}
													</button>
												</div>
											)}
										</div>
									)}
								</div>
								<div className={styles.headingDescription}>
									<p className={styles.inputLabels}>{pageText.setupDescription}</p>
								</div>
								<div>
									{resumeAssistAccess && setupData?.setup_type === "interview" ? (
										<JobDetails
											getLmsAssignmentType={getLmsAssignmentType}
											setupData={setupData}
											typingRefs1={typingRefs1}
											audienceOptions={audienceOptions}
											personalityOptions={personalityOptions}
											personalities={personalities}
											audience={audience}
											personality={personality}
											audienceRole={setupConfig[setupData.setup_type].audienceRole}
											detailsArray={setupConfig[setupData.setup_type].detailsArray}
											tabList={tabList}
											activeTab={activeTab}
											setActiveTab={setActiveTab}
											setShowEditModal={setShowEditModal}
											hoveredAvatar={hoveredAvatar}
											setHoveredAvatar={setHoveredAvatar}
											handlePersonalityChange={handlePersonalityChange}
											changeAvatar={changeAvatar}
										/>
									) : (
										<SetupDetails
											setupData={setupData}
											typingRefs1={typingRefs1}
											audienceOptions={audienceOptions}
											personalityOptions={personalityOptions}
											personalities={personalities}
											audience={audience}
											personality={personality}
											audienceRole={setupConfig[setupData.setup_type].audienceRole}
											detailsArray={setupConfig[setupData.setup_type].detailsArray}
											hoveredAvatar={hoveredAvatar}
											setHoveredAvatar={setHoveredAvatar}
											handlePersonalityChange={handlePersonalityChange}
											changeAvatar={changeAvatar}
										/>
									)}

									{/* {resumeCreatorFlag && activeTab === 1 && (
										<>
											{console.log("resumeTemplateData", resumeTemplateData)}

											<ResumeCreatorPreview
												resumeData={resumeTemplateData}
												selectedTemplate={templates[templateIndex]}
												setTemplateIndex={setTemplateIndex}
											/>
										</>
									)} */}
									<div className={styles.warningCallout}>
										<p className={styles.warningCalloutText}>
											{activeTab === 0 ? setupConfig[setupData.setup_type].warningCalloutText : pageText.ResumeFeedback}
										</p>
									</div>
									<hr />
								</div>
								{setupData && activeTab === 0 && (
									<QuestionTable
										editAccess={editAccess}
										questionListViewAccess={questionListViewAccess}
										setupType={setupData.setup_type}
										simulation_id={simulation_id}
										data={setupData}
										setData={setSetupData}
										field={setupData?.setup_type === "interview" ? "job_question_list" : "question_list"}
										questionTypeOptions={questionTypeOptions}
										generateQuestions={generateQuestions}
										resetQuestions={resetQuestions}
										isGenerating={isGenerating}
										cellRenderer={customCellRenderer}
										fixedOpenClose={setupData?.setup_type === "interview"}
										evaluationFramework={setupData?.setup_type === "interview"}
									/>
								)}
								{!getLmsToken() && getLtik() && getLmsAssignmentType() !== "dynamic" && (
									<button className={styles.startBtn} onClick={() => summitDeeplink()}>
										Submit
									</button>
								)}
								{activeTab === 0 && (!getLtik() || getLmsAssignmentType() !== "static") && (
									<StartButtonSection
										setShowAssignmentModal={setShowAssignmentModal}
										allowedToStart={allowedToStart}
										showStartSimBtn={showStartSimBtn}
										videoOn={videoOn}
										setVideoOn={setVideoOn}
										saveAudio={saveAudio}
										setSaveAudio={setSaveAudio}
										captureOn={captureOn}
										setCaptureOn={setCaptureOn}
										audience={audience}
										startSimHandler={startSimHandler}
									/>
								)}
							</>
						)}
				</div>
			)}
		</>
	);
}

function InterviewWrapper({ condition, children }) {
	return condition ? <div className={styles.interviewInputWrapper}>{children}</div> : children;
}
