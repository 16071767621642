import React, { useContext, useState, useEffect } from "react";
import styles from "../../styleModules/generateStyles.module.css";
import ThumbButton from "./ThumbButton.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";

export default function ResumeScoreSection({ category, docType, gptScoreLoading, gptAssistScore, isThumbClicked, handleThumbClickScore }) {
	const { userLanguage, translateSingleText, translateDictText, performTranslation, localUser } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		// CategoryName: category,
		// Score: gptAssistScore?.[`${docType}_${category}`]
	};

	const [pageText, setPageText] = useState({
		// ...LocalLanguageDict
	});

	const [CategoryText, setCategoryText] = useState(category);
	const [ScoreText, setScoreText] = useState(gptAssistScore?.[`${docType}_${category}`]);

	const [hasTranslatedText, setHasTranslatedText] = useState(false);

	// Update ScoreText when gptAssistScore changes
	useEffect(() => {
		setScoreText(gptAssistScore?.[`${docType}_${category}`]);
	}, [gptAssistScore, docType, category]);

	// Usage
	useEffect(() => {
		async function translateText() {
			if (userLanguage !== "en") {
				try {
					if (category && gptAssistScore?.[`${docType}_${category}`]) {
						const translatedCategoryText = await translateSingleText(category, userLanguage);
						const translatedScoreText = await translateSingleText(gptAssistScore?.[`${docType}_${category}`], userLanguage);
						setCategoryText(translatedCategoryText);
						setScoreText(translatedScoreText);
						console.log("ScoreText", translatedScoreText);
					}
				} catch (err) {
					console.log("Failed to translate resume score section");
				}
			}
		}

		translateText();
		setHasTranslatedText(true);
	}, [userLanguage, gptAssistScore, category, ScoreText]);

	return (
		<div className={`${styles.resumeScoreSection} ${gptScoreLoading ? styles.gptAssistTextLoading : ""}`}>
			<div className={styles.resumeScoreSubsection}>
				<div className={styles.resumeDetailsTitleContainer}>
					<p className={styles.resumeDetailsTitle}>
						{typeof CategoryText === "string" ? CategoryText.charAt(0).toUpperCase() + CategoryText.slice(1) : ""}
					</p>

					{!gptScoreLoading && gptAssistScore && gptAssistScore[`${docType}_${category}`] && (
						<div className={styles.thumbsContainer}>
							<ThumbButton
								type="up"
								docType={docType}
								isClicked={isThumbClicked[`${docType}_${category}`]}
								onClick={(e) => {
									e.stopPropagation();
									handleThumbClickScore(category, true, docType);
								}}
							/>
							<ThumbButton
								type="down"
								docType={docType}
								isClicked={isThumbClicked[`${docType}_${category}`] === false}
								onClick={(e) => {
									e.stopPropagation();
									handleThumbClickScore(category, false, docType);
								}}
							/>
						</div>
					)}
				</div>
				<p className={styles.resumeDetailsText}>{ScoreText}</p>
			</div>
		</div>
	);
}
