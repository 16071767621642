import CryptoJS from "crypto-js";
import axios from "axios";

let passphrase = null;

export function setPassphrase(pass) {
	passphrase = pass;
	// Eject previous response interceptor if it exists
	if (window.myAppResponseInterceptor) {
		//	console.log("Ejecting response interceptor");
		axios.interceptors.response.eject(window.myAppResponseInterceptor);
	}
	window.myAppResponseInterceptor = axios.interceptors.response.use(
		(response) => {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			try {
				// console.log("interceptor",response.config.url,response.data)
				if (response.data.toUpperCase) {
					const parsedData = JSON.parse(AutoDecryptWithAES(response.data));

					response.data = parsedData;
				}
			} catch (error) {}

			return response;
		},
		(error) =>
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			Promise.reject(error)
	);
	// Eject previous request interceptor if it exists
	if (window.myAppRequestInterceptor) {
		axios.interceptors.request.eject(window.myAppRequestInterceptor);
	}

	if (process.env.REACT_APP_ENVIRONMENT_TYPE === "production") {
		window.myAppRequestInterceptor = axios.interceptors.request.use(
			(config) => {
				// Encrypt the data before sending it out
				// console.log("config", config.data, config.params);
				if (config.data && passphrase && !config.data.encryptedData && !(config.data instanceof FormData)) {
					try {
						const encryptedData = autoEncryptWithAES(JSON.stringify(config.data));
						config.data = { encryptedData };
					} catch (error) {
						console.error("Error encrypting request data", error);
					}
				}
				if (config.params && passphrase && !config.params.encryptedData) {
					try {
						//	console.log("config.params", config.params);
						const encryptedData = autoEncryptWithAES(JSON.stringify(config.params));
						config.params = { encryptedData };
					} catch (error) {
						console.error("Error encrypting request data", error);
					}
				}
				return config;
			},
			(error) =>
				// Do something with request error
				Promise.reject(error)
		);
	}
}
function autoEncryptWithAES(text) {
	let encryptedData = text;

	try {
		if (passphrase) {
			const encrypted = CryptoJS.AES.encrypt(text, passphrase).toString();
			encryptedData = encrypted;
		} else {
			console.error("Passphrase is not set.");
		}
	} catch (error) {
		console.error("Error encrypting data", error);
	}

	return encryptedData;
}

function AutoDecryptWithAES(ciphertext) {
	let result = ciphertext;

	try {
		const tmp = CryptoJS.enc.Base64.parse(ciphertext);
		// console.log(tmp)
		const decData = tmp.toString(CryptoJS.enc.Utf8);
		const bytes = CryptoJS.AES.decrypt(decData, passphrase).toString(CryptoJS.enc.Utf8);
		result = JSON.parse(bytes);
	} catch (error) {
		// console.error("44 ",error,ciphertext+"ciphertext" +ciphertext)
	}
	return result;
	// const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
	// console.log(bytes)
	// const originalText = bytes.toString(CryptoJS.enc.Utf8);
	// return originalText;
}
export const encryptWithAES = (text) => CryptoJS.AES.encrypt(text, passphrase).toString();

export const decryptWithAES = (ciphertext) => {
	let result = ciphertext;

	try {
		const tmp = CryptoJS.enc.Base64.parse(ciphertext);
		// console.log(tmp)
		const decData = tmp.toString(CryptoJS.enc.Utf8);
		const bytes = CryptoJS.AES.decrypt(decData, passphrase).toString(CryptoJS.enc.Utf8);
		result = JSON.parse(bytes);
	} catch (error) {
		// console.error("44 ",error,ciphertext+"ciphertext" +ciphertext)
	} finally {
		return JSON.stringify(result);
	}

	// const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
	// console.log(bytes)
	// const originalText = bytes.toString(CryptoJS.enc.Utf8);
	// return originalText;
};

export function log() {
	if (process.env.REACT_APP_SHOW_LOGS !== undefined) {
		console.log(...arguments);
	}
}

// OBFUSCATION FOR REPORTS
const iv = CryptoJS.lib.WordArray.create(new Array(16));

function toBase64Url(base64) {
	return base64.replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

function fromBase64Url(base64url) {
	base64url = base64url.replace(/-/g, "+").replace(/_/g, "/");
	while (base64url.length % 4) {
		base64url += "=";
	}
	return base64url;
}

export function obfuscate(text) {
	if (typeof text !== "string") {
		text = text.toString();
	}

	const key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_ENCRYPTION_KEY);

	const cipherText = CryptoJS.AES.encrypt(text, key, { iv }).ciphertext;
	const base64CipherText = CryptoJS.enc.Base64.stringify(cipherText);

	return toBase64Url(base64CipherText);
}

export function deobfuscate(text) {
	const cipherParams = CryptoJS.lib.CipherParams.create({
		ciphertext: CryptoJS.enc.Base64.parse(fromBase64Url(text))
	});

	const key = CryptoJS.enc.Base64.parse(process.env.REACT_APP_ENCRYPTION_KEY);
	const decryptedText = CryptoJS.AES.decrypt(cipherParams, key, { iv });

	return decryptedText.toString(CryptoJS.enc.Utf8);
}
