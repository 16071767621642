// useCachedModel.js
import { useEffect, useState } from "react";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module";

import { openDB } from "idb";

async function initDB() {
	return openDB("ModelCacheDB", 1, {
		upgrade(db) {
			db.createObjectStore("models");
		}
	});
}

// function fetch url and return glb as arraybuffer
async function fetchModel(url) {
	const response = await fetch(url);
	const arrayBuffer = await response.arrayBuffer();
	return arrayBuffer;
}

async function cachedLoader(loader, url) {
	console.log("Loading model ...");
	console.log(url);
	const db = await initDB();
	const cachedModelArrayBuffer = await db.get("models", url);
	let gltf = null;
	if (cachedModelArrayBuffer && cachedModelArrayBuffer.byteLength > 0) {
		console.log("Model loaded from cache");

		// console.log(cachedModelArrayBuffer);
		const modelLoaderPromise = new Promise((resolve, reject) => {
			loader.parse(cachedModelArrayBuffer, "", resolve, reject);
		});

		try {
			gltf = await modelLoaderPromise;
		} catch (err) {
			console.error(err);
		}
	}
	if (gltf) {
		return gltf;
	}
	return new Promise(async (resolve, reject) => {
		console.log("loading from url", url);

		const data = await fetchModel(url);
		// console.log(data)
		console.log("saving to cache");
		await db.put("models", data, url);
		loader.parse(data, "", resolve, reject);
		// loader.load(
		//   url,
		//   async (gltf) => {
		//     console.log("gltf loaded")
		//     console.log(gltf)
		//     const gltfExporter = new GLTFExporter();
		//     gltfExporter.parse(
		//       gltf.scene,
		//       async (result) => {
		//         //const arrayBuffer = BufferUtils.arrayBufferToBase64(result);

		//         const arrayBuffer = result instanceof ArrayBuffer ? result : new Uint8Array(result).buffer;
		//         console.log("saving to cache")
		//         console.log(result)

		//         console.log(arrayBuffer)
		//         await db.put('models', arrayBuffer, url);
		//         resolve(gltf);
		//       },
		//       { binary: true }
		//     );
		//   },
		//   undefined,
		//   (error) =>{
		//     console.error(error)
		//     reject(error)}
		// );
	});
}

export const useCachedModel = (url) => {
	const [model, setModel] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		const loader = new GLTFLoader();
		// const dracoLoader = new DRACOLoader();
		// dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.6/'); // Set the path to your Draco decoder files
		// loader.setDRACOLoader(dracoLoader);

		// const meshoptLoader = new MeshoptDecoder();
		// meshoptLoader.setDecoderPath(MeshoptDecoder); // For MeshOptDecoder Loading
		loader.setMeshoptDecoder(MeshoptDecoder);

		cachedLoader(loader, url)
			.then((gltf) => {
				//	console.log("gltf loaded");
				// console.log(gltf);
				setModel(gltf);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				console.log(url);
				setError(err);
				setLoading(false);
			});
	}, [url]);

	return { model, loading, error };
};
