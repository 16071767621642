import { useEffect, useState } from "react";
import { useThree } from "@react-three/fiber";
import { webgazer } from "webgazer/dist/webgazer";

export const useEyeTracking = () => {
	const [isLookingAtAvatar, setIsLookingAtAvatar] = useState(false);
	const { size } = useThree();

	useEffect(() => {
		const webgazerInit = async () => {
			const videoConstraints = {
				width: { ideal: 640 },
				height: { ideal: 480 }
			};
			webgazer.setCameraConstraints({ video: videoConstraints });
			webgazer.params.showVideo = false;
			webgazer.params.saveDataAcrossSessions = false;
			webgazer.params.dataTimestep = 200;

			// webgazer.videoElementCanvasId = "UserVideo";

			// // Assuming your existing video element has an ID of 'existingVideoElement'
			// const existingVideoElement = document.getElementById("3a1ac4d1cdbe5bc1ba91b58ef40fba308614bab33ca7110837cebff516349497");
			// if (existingVideoElement) {
			// 	webgazer.setVideoElement(existingVideoElement);
			// } else {
			// 	console.error("Existing video element not found");
			// }

			const webgazerObject = await webgazer;
			console.log("WebGazer created");

			let lastProcessedTime = 0;
			const throttleDelay = 200;

			await webgazerObject
				.setGazeListener((data, elapsedTime) => {
					const currentTime = new Date().getTime();
					if (data && currentTime - lastProcessedTime >= throttleDelay) {
						lastProcessedTime = currentTime;
						const { x } = data;
						const { width } = size;
						const isLookingLeftSide = x < width / 2; // True if gaze is on the left half of the screen

						// Only update isCentered if the new state differs
						if (isLookingAtAvatar !== isLookingLeftSide) {
							setIsLookingAtAvatar(isLookingLeftSide);
							console.log("Changed isCentered: ", isLookingLeftSide);
						}
					}
				})
				.begin();
		};

		const webgazerEnd = () => {
			if (webgazer) {
				webgazer.end();
				console.log("WebGazer ended");
			}
		};

		webgazerInit();

		return () => {
			webgazerEnd();
		};
	}, []);

	return isLookingAtAvatar;
}; // v1
