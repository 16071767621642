import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useMatch } from "react-router-dom";
import { openDB } from "idb";
import { Helmet } from "react-helmet";
import { FaLock } from "react-icons/fa/index.esm.js";
import { FiChevronRight, FiEdit, FiEdit2, FiInfo, FiRotateCcw, FiTrash2 } from "react-icons/fi/index.esm.js";
import { IoClose } from "react-icons/io5/index.esm.js";
import { PiSparkleBold } from "react-icons/pi";
import { RiListCheck } from "react-icons/ri/index.esm.js";
import { RxQuestionMarkCircled } from "react-icons/rx";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import s from "../../styleModules/setupStyles.module.css";
import styles2 from "../../styleModules/modalStyles.module.css";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import SimulationList from "../Admin/SimulationList.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import styles from "../../styleModules/generateStyles.module.css";
// import a from "../../styleModules/adminSystemStyles.module.css";
import { ProductTourModal } from "./SetupElements/SetupModals.js";
import splashBG from "../../images/Backgrounds/splashBG.png";
import discoveryBackground from "../../images/Backgrounds/discoveryBackground.webp";
import decisionBackground from "../../images/Backgrounds/decisionBackground.webp";
import presentationBackground from "../../images/Backgrounds/presentationBackground.webp";
import circles from "../../images/circles.png";
import usePageVisitTracker from "../SecondaryComponents/usePageVisitTracker";
import ModalWrapper from "../SecondaryComponents/ModalWrapper";
import OverLimitBanner from "../SecondaryComponents/OverLimitBanner.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;
function SimSelection({ sortedSetups, accessibleSetups, cardCopy, resumeAssistAccess, handleStart, pageText }) {
	return (
		<div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "800px", margin: "0 auto" }}>
			{sortedSetups.map((sim, index) => {
				const access = accessibleSetups.includes(sim);

				if (sim.dashboard) {
					return (
						<div
							key={sim.setup_type}
							style={{
								display: "flex",
								marginBottom: "20px",
								backgroundColor: "#ffffff",
								borderRadius: "10px",
								overflow: "hidden",
								boxShadow: "0 2px 4px rgba(0,0,0,0.1)"
							}}
						>
							<img alt="background" src={sim.setup_background} style={{ width: "200px", height: "150px", objectFit: "cover" }} />
							<div style={{ flex: "1", padding: "20px", display: "flex", flexDirection: "column" }}>
								<h3 style={{ margin: "0 0 10px 0", color: "#333", fontSize: "24px" }}>{cardCopy[sim.setup_type].title}</h3>
								<p style={{ margin: "0", color: "#666", fontSize: "14px" }}>{cardCopy[sim.setup_type].text}</p>
							</div>
							<div
								style={{
									width: "200px",
									padding: "20px",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center"
								}}
							>
								<div style={{ marginBottom: "10px", width: "100%" }}>
									<p style={{ margin: "0 0 5px 0", color: "#666", fontSize: "12px", textAlign: "center" }}>
										{pageText.emptyDetails}
									</p>
									<button
										type="button"
										style={{
											padding: "10px 20px",
											backgroundColor: "#00a9af",
											color: "white",
											border: "none",
											borderRadius: "5px",
											cursor: "pointer",
											width: "100%",
											fontWeight: "bold"
										}}
										onClick={() => handleStart(sim.setup_type, "dynamic")}
									>
										{pageText.empty}
									</button>
								</div>
								<div style={{ width: "100%" }}>
									<p style={{ margin: "0 0 5px 0", color: "#666", fontSize: "12px", textAlign: "center" }}>
										{pageText.preFilledDetails}
									</p>
									<button
										type="button"
										style={{
											padding: "10px 20px",
											backgroundColor: "#00a9af",
											color: "white",
											border: "none",
											borderRadius: "5px",
											cursor: "pointer",
											width: "100%",
											fontWeight: "bold"
										}}
										onClick={() => handleStart(sim.setup_type, "static")}
									>
										{pageText.preFilled}
									</button>
								</div>
							</div>
						</div>
					);
				}
				return null;
			})}
		</div>
	);
}
function AssignmentTypeSelection({ setSelectedAssignmentType, pageText }) {
	const options = [
		{
			value: "dynamic",
			title: "Dynamic",
			text: "Create a new simulation with a dynamic assignment type",
			setup_background: splashBG
		},
		{
			value: "static",
			title: "Generated",
			text: "Create a new simulation with a generated assignment type",
			setup_background: decisionBackground
		}
	];
	const handleStart = (option) => {
		setSelectedAssignmentType(option);
	};
	return (
		<>
			{options.map((option, index) => (
				<div
					key={option.title}
					className={s.dashboardCard}
					style={{
						margin: "unset",
						width: "15rem",
						color: "inherit"
						// backgroundColor: !access ? "#49506F" : "#455084",
					}}
				>
					<img alt="backgound" src={option.setup_background} className={s.dashboardCardImg} />
					<div className={s.dashboardTitleContainer}>
						<p className={s.dashboardCardTitle}>{option.title}</p>
					</div>
					<p className={s.dashboardCardText}>{option.text}</p>

					<div className={s.dashboardCardBtnContainer}>
						<button type="button" className={s.dashboardTableBtnStart} onClick={() => handleStart(option.value)}>
							{pageText.Start}
						</button>
					</div>
				</div>
			))}
		</>
	);
}
export default function DeepLink(props) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const {
		localUser,
		tenantMetaData,
		showSidebar,
		setShowSidebar,
		SetupList,
		setSetupData,
		resumeAssistAccess,
		sessionList,
		setSessionList,
		runTranslation,
		translateDictText,
		userLanguage,
		performTranslation,
		handleNavigate
	} = useContext(AppContext);

	const match = useMatch("/pen");
	const [selectedSetupType, setSelectedSetupType] = useState(null);
	const [selectedAssignmentType, setSelectedAssignmentType] = useState(null);

	useEffect(() => {
		console.log("localUser", localUser);
	}, [localUser]);

	const handleSetSelectedAssignmentType = (assignmentType) => {
		setSelectedAssignmentType(assignmentType);
		handleNavigate(`/setup/${selectedSetupType}`, false, { assignmentType });
	};
	const handleStart = (setupType, assignmentType) => {
		console.log("setupType", setupType);
		console.log("assignmentType", assignmentType);
		setSelectedSetupType(setupType);
		setSelectedAssignmentType(assignmentType);
		handleNavigate(`/setup/${setupType}`, false, { assignmentType });
	};
	const [isLoading, setIsLoading] = useState(false);
	const [ongoingSession, setOngoingSession] = useState(null);
	const [selectedSessionId, setSelectedSessionId] = useState(-1);
	const [sessionDisplayLimit, setSessionDisplayLimit] = useState(4);
	const [showDeleteSessionModal, setShowDeleteSessionModal] = useState(false);
	const [showInfo_instructions, setShowInfo_instructions] = useState(false);
	const [showPTModal, setShowPTModal] = useState(false);
	// const [isFirstLogin, hasShownFirstTimePopup] = useState(false);
	// const [hasClickedOffModal, setHasClickedOffModal] = useState(false);
	const refClickOffModal = useRef();
	const instage_id = localUser ? localUser.id : null;
	const accessibleSetups = SetupList.filter((sim) => {
		if (tenantMetaData?.setup_type_list) {
			return tenantMetaData.setup_type_list.includes(sim.setup_type.split("_")[0]);
		}
		return true;
	});

	const inaccessibleSetups = SetupList.filter((sim) => !accessibleSetups.includes(sim));
	const LocalLanguageDict = {
		empty: "Empty",
		preFilled: "Pre-filled",
		emptyDetails: "Students fill in their own details",
		preFilledDetails: "Teacher Pre-fills question and details",
		YourSessions: "Your Recent Sessions",
		Created: "Created",
		ShowMore: "Show More",
		ShowLess: "Show Less",
		GenerateNewSession: "Generate New Session",
		Start: "Start",
		JobPrep: "Job Prep",
		B2BSales: "B2B Sales",
		Presentation: "Presentations",
		CardTextInterviewResumeAssist: "Practice interviewing for any job or fine tune your resume & cover letter.",
		CardTextInterviewNoResumeAssist: "Practice interviewing for any job",
		CardTextDiscovery: "Conduct a discovery meeting",
		CardTextPresentation: "Practice with a partner",
		InterviewListOption1: "Mock Interviews",
		InterviewListOption2: "Resume Assist",
		InterviewListOption3: "Cover Letter Assist",
		PresentationListOption1: "General Presentation",
		PresentationListOption2: "Self-Introduction",
		PresentationListOption3: "Elevator/Business Pitch",
		DeleteSession: "Delete Session",
		AreYouSureYouWantToDeleteThisSession: "Are you sure you want to delete this session? This action cannot be undone.",
		Cancel: "Cancel",
		Delete: "Delete",
		ThisIsTheMainPageWhereYouCanCreateCustomSimulationsAndShareThemForOthersToTry:
			"This is the main page where you can create custom simulations and share them for others to try.",
		WelcomeToInStage: "Welcome To InStage",
		PickASimulation: "Pick A Simulation",
		ChooseFromOneOfOurSimulationTypesToGetStartedDevelopingYourInterview:
			"Choose from one of our simulation types to get started developing your interview, sales, and presentation skills.",
		NeedMoreHelp: "Need More Help?",
		CheckOutOurHelpCenterAtTheBottomRightCornerOfTheScreenToViewOurHelpDocsOrSpeakWithOneOfOurTeamMembersDirectly:
			"Check out our help center at the bottom right corner of the screen to view our help docs or speak with one of our team members directly!"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});
	const sortedSetups = [...accessibleSetups, ...inaccessibleSetups];
	const backgrounds = {
		interview: splashBG,
		discovery: discoveryBackground,
		discovery_spin: discoveryBackground,
		closing: decisionBackground,
		retail: discoveryBackground,
		presentation: presentationBackground,
		intro: splashBG,
		freestyle: splashBG,
		patient: splashBG
	};
	const ongoingTitles = {
		interview: "Job Interview",
		discovery: "Discovery Meeting",
		discovery_spin: "Discovery Meeting",
		closing: "Closing Meeting",
		retail: "Retail Meeting",
		presentation: "Presentation",
		intro: "Self-Introduction",
		freestyle: "Freestyle",
		patient: "Patient Interview"
	};

	const ongoingSetupId = {
		interview: "interview",
		discovery: "discovery",
		discovery_spin: "discovery_spin",
		closing: "closing",
		retail: "retail",
		presentation: "presentation",
		intro: "intro",
		freestyle: "freestyle",
		patient: "patient"
	};

	const cardCopy = {
		interview: {
			title: pageText.JobPrep,
			text: resumeAssistAccess ? `${pageText.CardTextInterviewResumeAssist}` : `${pageText.CardTextInterviewNoResumeAssist}`,
			list: resumeAssistAccess
				? [`${pageText.InterviewListOption1}`, `${pageText.InterviewListOption2}`, `${pageText.InterviewListOption3}`]
				: ""
		},
		discovery: {
			title: pageText.B2BSales,
			text: `${pageText.CardTextDiscovery}`
		},
		presentation: {
			title: pageText.Presentation,
			text: `${pageText.CardTextPresentation}`,
			list: [`${pageText.PresentationListOption1}`, `${pageText.PresentationListOption2}`, `${pageText.PresentationListOption3}`]
		}
	};

	const fetchSessionList = async () => {
		// console.log("fetchSessionList navHeader", localUser);
		const result = await axiosLimitedGet(`${url}/api/saved/simulation/list`, 1, { params: { instage_id } });
		// console.log(result.data);

		// order the data where global is set to true first
		result.data.sort((a, b) => (a.global > b.global ? -1 : 1));
		setSessionList(result.data);
	};

	return (
		<div>
			<div style={{ display: "flex", flexDirection: "row" }} className={s.dashboardCardContainer}>
				{isLoading && (
					<div className={s.spinnerContainerStart}>
						<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
					</div>
				)}
				{!isLoading && (
					<>
						{selectedSetupType === null && (
							<SimSelection
								sortedSetups={sortedSetups}
								accessibleSetups={accessibleSetups}
								cardCopy={cardCopy}
								resumeAssistAccess={resumeAssistAccess}
								handleStart={handleStart}
								pageText={pageText}
							/>
						)}
						{selectedSetupType !== null && (
							<AssignmentTypeSelection setSelectedAssignmentType={handleSetSelectedAssignmentType} pageText={pageText} />
						)}
					</>
				)}
			</div>
		</div>
	);
}
