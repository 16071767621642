import { useState, useEffect, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import { random } from "lodash";
import { useEyeTracking } from "./EyeTracking";
import { lerp } from "./lipsyncUtility";

import { sleep } from "../UtilityFunctions/sleep.js";

export function RealisticEye({ character, myMixer, myMesh, myScene, myTarget }) {
	// Blink Vars
	const [blinkOpen, setBlinkOpen] = useState(true); // Controls if the eyes are open or closed
	const blinkStartTimeRef = useRef(Date.now()); // Ref to store the start time of the current blink state
	const myCurrentMesh = myMesh.current;

	const leftEyeBoneRef = useRef();
	const rightEyeBoneRef = useRef();
	const headBoneRef = useRef();
	const bodyRef = useRef();
	const characterMeshRef = useRef();
	const eyeLashMeshRef = useRef();
	const blendFactorRef = useRef(1.0); // Default to a balanced blend

	useEffect(() => {
		if (myCurrentMesh) {
			leftEyeBoneRef.current = myCurrentMesh.getObjectByName("CC_Base_L_Eye");
			rightEyeBoneRef.current = myCurrentMesh.getObjectByName("CC_Base_R_Eye");
			headBoneRef.current = myCurrentMesh.getObjectByName("CC_Base_Head");
			bodyRef.current = myCurrentMesh.getObjectByName("CC_Base_Body_1");
			characterMeshRef.current = myCurrentMesh.getObjectByName("CC_Base_Body_1");
			eyeLashMeshRef.current = myCurrentMesh.getObjectByName("CC_Eyelashes");
		}
	}, [myCurrentMesh]);
	// Bone Definitions

	const targetPosLeft = new THREE.Vector3();
	const targetPosRight = new THREE.Vector3();

	// LookAt Positions Full
	const lookAtUsersRightEye = character.lookAtUsersRightEyeX;
	const lookAtUsersLeftEye = character.lookAtUsersLeftEyeX;
	const lookAtUsersMouth = character.lookAtUsersMouthX;
	const lookForward = character.lookForwardX;
	const lookUp = character.lookUpX;

	// LookAt Positions Micro
	const lookAtUsersRightEyeMicro = character.lookAtUsersRightEyeXMicro;
	const lookAtUsersLeftEyeMicro = character.lookAtUsersLeftEyeXMicro;
	const lookForwardMicro = character.lookForwardXMicro;
	const lookUpMicro = character.lookUpXMicro;

	// Max/Min Eye Clamps
	const eyeClamps = character.eyeMaxRots;

	// Look At States
	const lookStartPosRef = useRef(lookForward);
	const lookEndPosRef = useRef(lookUp);
	const canLookNewDirRef = useRef(true);
	const lookAtStartTimeRef = useRef(Date.now());

	const isStartPosSetRef = useRef(false);

	const lookAwayAmountRef = useRef(0);

	const isLookingForwardRef = useRef(false);

	const isMicrosaccadeActiveRef = useRef(false);

	const [trigger, setTrigger] = useState(isMicrosaccadeActiveRef);

	const positionsFull = [
		{ name: "lookAtUsersRightEye", value: lookAtUsersRightEye },
		{ name: "lookAtUsersLeftEye", value: lookAtUsersLeftEye },
		{ name: "lookForward", value: lookForward },
		{ name: "lookUp", value: lookUp }
	];

	const positionsMicro = [
		{ name: "lookAtUsersRightEyeX", value: lookAtUsersRightEyeMicro },
		{ name: "lookAtUsersLeftEyeX", value: lookAtUsersLeftEyeMicro },
		{ name: "lookForwardX", value: lookForwardMicro },
		{ name: "lookUpX", value: lookUpMicro }
	];

	// User Eye Tracking
	// const isLookingAtAvatar = useEyeTracking();

	useEffect(() => {
		const intervalId = setInterval(() => {
			isMicrosaccadeActiveRef.current = !isMicrosaccadeActiveRef.current;
			setTrigger((prev) => !prev); // Toggle trigger to force re-render
			// console.log("The Trigger Var: ", trigger);
		}, random(3000, 5000));

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		// console.log("Use effect called: ", isMicrosaccadeActiveRef.current);
		const handle = isMicrosaccadeActiveRef.current
			? lookAround(myTarget, leftEyeBoneRef.current, rightEyeBoneRef.current)
			: lookAround(myTarget, leftEyeBoneRef.current, rightEyeBoneRef.current);

		return () => {
			if (typeof handle === "function") {
				handle();
			}
		};
	}, [myTarget, trigger]); // Now depends on trigger state

	// useEffect(() => {
	// 	// print body mesh
	// 	if (bodyRef.current) {
	// 		console.log("Body: ", bodyRef.current);
	// 	}
	// }, [bodyRef.current]);

	// Blink Begins
	useEffect(() => {
		if (!character || !character.leftEyeBlinkIndex || !character.rightEyeBlinkIndex) {
			console.log("Character object or eye blink indexes are not properly defined.");
			return;
		}

		if (!myMixer) {
			console.log("No mixer", character.leftEyeBlinkIndex, character.rightEyeBlinkIndex, myMixer);
			return;
		}
		myMixer.addEventListener("update", (e) => {
			// eslint-disable-next-line
			let morphTargetInfluence = myMixer.getRoot().morphTargetInfluence;
			if (morphTargetInfluence && morphTargetInfluence.length > character.rightEyeBlinkIndex) {
				morphTargetInfluence[character.leftEyeBlinkIndex] = 0;
				morphTargetInfluence[character.rightEyeBlinkIndex] = 0;
			}
		});
	}, [myMixer, character.leftEyeBlinkIndex, character.rightEyeBlinkIndex]);

	// Initiate the blink (closing eyes) at random intervals
	useEffect(() => {
		if (blinkOpen) {
			// Only start a new blink if the eyes are currently open
			const min = 3;
			const max = 8;
			const timeBetweenBlinks = (Math.random() * (max - min) + min) * 1000;

			const timeoutId = setTimeout(() => {
				setBlinkOpen(false); // Start closing the eyes
				blinkStartTimeRef.current = Date.now(); // Mark the start time of closing
			}, timeBetweenBlinks);

			return () => clearTimeout(timeoutId);
		}
	}, [blinkOpen]); // Depend on the blinkOpen state to restart the cycle

	useEffect(() => {
		// toggle avatar target eye tracking
		const toggleTracking = () => {
			// setIsTracking((prev) => !prev); // Toggle the isTracking state
			// console.log("Toggled Tracking to: ", isCentered);
		};

		const intervalId = setInterval(() => {
			toggleTracking();
			// Randomly choose the next interval duration between 4 to 6 seconds
			const nextIntervalDuration = Math.random() * (6000 - 4000) + 4000;
			clearInterval(intervalId); // Clear the current interval
			setInterval(toggleTracking, nextIntervalDuration); // Set a new interval with the new duration
		}, Math.random() * (6000 - 4000) + 4000); // Initial interval duration

		return () => clearInterval(intervalId); // Cleanup on component unmount
	}, []); // Empty dependency array means this effect runs only once on mount

	useFrame(() => {
		if (headBoneRef.current) {
			// Assuming rotation.x is the relevant axis for clamping
			const headRotation = headBoneRef.current.rotation.x;
			const headRot = headBoneRef.current.rotation;
			const minRotation = character.minHeadRotation; // Adjust as needed
			const maxRotation = character.maxHeadRotation; // Adjust as needed
			// console.log(headRot.x);
			// Normalize the rotation to a value between 0 and 1
			let normalizedRotation = (headRotation - minRotation) / (maxRotation - minRotation);
			normalizedRotation = THREE.MathUtils.clamp(normalizedRotation, 0, 1);
			// console.log(blendFactor);
			// Map the normalized rotation to the blendFactor range
			const minBlend = 0.2;
			const maxBlend = 1;
			const newBlendFactor = normalizedRotation * (maxBlend - minBlend) + minBlend;
			blendFactorRef.current = newBlendFactor;
		}

		if (blendFactorRef.current > 0.3) {
			LerpLookPositions();
			eyesLookAtTarget(leftEyeBoneRef.current, rightEyeBoneRef.current, myTarget);

			if (myTarget) {
				TrackEyeLids(myTarget);
			}
			// console.log(myTarget.position);
			clampEyes(eyeClamps[0], eyeClamps[1], eyeClamps[2], eyeClamps[3]);
			// if (leftEyeBoneRef.current) {
			// 	console.log("X: ", leftEyeBoneRef.current.rotation.x);
			// 	console.log("Y: ", leftEyeBoneRef.current.rotation.y);
			// }
		}
		Blinking();
	});

	function clampEyes(minX, maxX, minY, maxY) {
		if (!leftEyeBoneRef.current || !rightEyeBoneRef.current) return;

		// Clamp rotation on the X axis (up and down movement)
		leftEyeBoneRef.current.rotation.x = Math.max(minX, Math.min(maxX, leftEyeBoneRef.current.rotation.x));

		// Clamp rotation on the Y axis (left and right movement)
		leftEyeBoneRef.current.rotation.y = Math.max(minY, Math.min(maxY, leftEyeBoneRef.current.rotation.y));

		rightEyeBoneRef.current.rotation.x = Math.max(minX, Math.min(maxX, rightEyeBoneRef.current.rotation.x));

		// Clamp rotation on the Y axis (left and right movement)
		rightEyeBoneRef.current.rotation.y = Math.max(minY, Math.min(maxY, rightEyeBoneRef.current.rotation.y));
	}

	function eyesLookAtTarget(_leftEyeBone, _rightEyeBone, _myTarget) {
		if (_leftEyeBone && _rightEyeBone) {
			const offset = 0.025;
			_leftEyeBone.up.set(0, -1, 0);
			_rightEyeBone.up.set(0, -1, 0);

			// Calculate target positions with offset
			const targetPosLeft = new THREE.Vector3(_myTarget.position.x + offset, _myTarget.position.y, _myTarget.position.z);
			const targetPosRight = new THREE.Vector3(_myTarget.position.x - offset, _myTarget.position.y, _myTarget.position.z);

			// Get current eye positions (assuming they are initially set by the base animation)
			const currentPosLeft = new THREE.Vector3().setFromMatrixPosition(_leftEyeBone.matrixWorld);
			const currentPosRight = new THREE.Vector3().setFromMatrixPosition(_rightEyeBone.matrixWorld);

			// Blend between current positions and target positions
			const blendedPosLeft = currentPosLeft.lerp(targetPosLeft, blendFactorRef.current);
			const blendedPosRight = currentPosRight.lerp(targetPosRight, blendFactorRef.current);

			// Use the blended positions for lookAt
			_leftEyeBone.lookAt(blendedPosLeft);
			_rightEyeBone.lookAt(blendedPosRight);

			// Adjust the eye rotations
			_leftEyeBone.rotation.y -= Math.PI / 2;
			_rightEyeBone.rotation.y -= Math.PI / 2;
		}
	}

	function lookAround(_myTarget, _leftEyeBone, _rightEyeBone) {
		// let lookStartPos = _myTarget.position.clone();
		// let lookEndPos = positions[Math.floor(Math.random() * positions.length)];
		// console.log("Position Array: ", positionArray);
		// console.log("isMicro? ", isMicrosaccadeActiveRef.current);
		/**
		 * recursive function to update eye target position
		 * @param {} positionArray
		 * @param {*} minTime
		 * @param {*} maxTime
		 * @param {*} extraLookForwardTime
		 * @returns
		 */
		async function updatePosition(positionArray, minTime, maxTime, extraLookForwardTime) {
			if (canLookNewDirRef.current && _leftEyeBone && _rightEyeBone) {
				// console.log("Update Positions Full? ", isMicrosaccadeActive);
				lookStartPosRef.current = lookEndPosRef.current; // Set the current end as new start
				const randomIndex = Math.floor(Math.random() * positionArray.length);
				// console.log(positionArray[randomIndex].name);
				if (lookAwayAmountRef.current >= 1) {
					lookAwayAmountRef.current = 0;
					lookEndPosRef.current = positionArray[2].value;
					isLookingForwardRef.current = true;
				} else {
					lookEndPosRef.current = positionArray[randomIndex].value;
					lookAwayAmountRef.current = (currentAmount) => currentAmount + 1;
					isLookingForwardRef.current = false;
				}

				lookAtStartTimeRef.current = Date.now(); // Reset the start time
				canLookNewDirRef.current = false; // Disallow new directions until this lerp completes

				let randomTimeBetweenLooks = Math.floor(Math.random() * (maxTime - minTime)) + minTime;
				if (randomIndex === 2) {
					randomTimeBetweenLooks += extraLookForwardTime;
				}

				await sleep(calculateLerpDuration() + randomTimeBetweenLooks);

				myTarget.position.copy(lookEndPosRef.current); // Ensure it sticks to the end position
				canLookNewDirRef.current = true; // Allow a new direction after the wait time
				if (isMicrosaccadeActiveRef.current) {
					updatePosition(positionsMicro, 500, 1000, 500); // Initialize the first position update
				} else {
					updatePosition(positionsFull, 1500, 4000, 3000); // Initialize the first position update
				}

				// setTimeout(() => {
				// 	console.log("Timeout ", timeoutId);
				// 	myTarget.position.copy(lookEndPosRef.current); // Ensure it sticks to the end position
				// 	canLookNewDirRef.current = true; // Allow a new direction after the wait time
				// 	if (isMicrosaccadeActiveRef.current) {
				// 		updatePosition(positionsMicro, 500, 1000, 500); // Initialize the first position update
				// 	} else {
				// 		updatePosition(positionsFull, 1500, 4000, 3000); // Initialize the first position update
				// 	}
				// }, calculateLerpDuration() + randomTimeBetweenLooks);
			}
		}
		if (isMicrosaccadeActiveRef.current) {
			updatePosition(positionsMicro, 500, 1000, 500); // Initialize the first position update
		} else {
			updatePosition(positionsFull, 1500, 4000, 3000); // Initialize the first position update
		}
	}

	function LerpLookPositions() {
		let theStartPos;
		if (!isStartPosSetRef.current) {
			theStartPos = myTarget.position.clone();
		}

		if (!canLookNewDirRef.current) {
			const elapsedTime = Date.now() - lookAtStartTimeRef.current;
			const duration = calculateLerpDuration();
			let currentAlpha = elapsedTime / duration;
			currentAlpha = THREE.MathUtils.clamp(currentAlpha, 0, 1);

			if (theStartPos.equals(lookEndPosRef.current)) {
				return;
			}

			// Ensure the interpolation is based on the actual time elapsed
			const interpolatedVector = new THREE.Vector3().lerpVectors(theStartPos, lookEndPosRef.current, currentAlpha);
			myTarget.position.copy(interpolatedVector);
		}
	}

	function calculateLerpDuration() {
		return 100; // Static duration for lerp, can be adjusted
	}

	function Blinking() {
		// const eyeControllerBone = myMesh.current?.getObjectByName("EyeControllerBone");

		if (!characterMeshRef.current || !eyeLashMeshRef.current) {
			// console.log("Not Found", characterMeshRef.current, eyeLashMeshRef.current);
			return;
		}

		// Calculate the current alpha based on time since blink state change
		const elapsedTime = Date.now() - blinkStartTimeRef.current;
		const duration = blinkOpen ? 150 : 100; // Duration for closing might be different from opening
		let currentAlpha = elapsedTime / duration;
		currentAlpha = THREE.MathUtils.clamp(currentAlpha, 0, 1); // Ensure alpha stays within [0, 1]

		// If opening the eyes and reached the end of the animation, reset state
		if (!blinkOpen && currentAlpha >= 1) {
			setBlinkOpen(true); // Reset to start closing eyes again
			blinkStartTimeRef.current = Date.now(); // Reset the start time
			currentAlpha = 0; // Reset alpha to start the transition again
		}

		// Apply the interpolated values to the morph targets
		const blinkValue = blinkOpen ? THREE.MathUtils.lerp(1, 0, currentAlpha) : THREE.MathUtils.lerp(0, 1, currentAlpha);
		characterMeshRef.current.morphTargetInfluences[character.leftEyeBlinkIndex] = blinkValue;
		characterMeshRef.current.morphTargetInfluences[character.rightEyeBlinkIndex] = blinkValue;
		eyeLashMeshRef.current.morphTargetInfluences[character.leftEyeBlinkIndex] = blinkValue;
		eyeLashMeshRef.current.morphTargetInfluences[character.rightEyeBlinkIndex] = blinkValue;
	}

	function TrackEyeLids(_myTarget) {
		if (!characterMeshRef.current || !eyeLashMeshRef.current) {
			console.log("Not Found", characterMeshRef.current, eyeLashMeshRef.current);
			return;
		}

		if (!_myTarget) {
			console.error("myTarget is undefined");
			return;
		}
		if (!_myTarget.position) {
			console.error("myTarget position is undefined");
			return;
		}

		const TrackDirection = (minDir, maxDir, AxisPosition, morphIndex1, morphIndex2, morphIndex3, morphIndex4) => {
			// Min and Max values for myTarget.position.y
			const minY = minDir;
			const maxY = maxDir;

			// Calculate the normalized position of myTarget.current.position.y within the [minY, maxY] range
			let normalized = (AxisPosition - minY) / (maxY - minY);

			// Clamp the normalized value between 0 and 1
			normalized = Math.max(0, Math.min(normalized, 1));

			const morphIntensity = normalized;
			// console.log("Blink Intensity: ", blinkIntensity);

			characterMeshRef.current.morphTargetInfluences[morphIndex1] = morphIntensity;
			characterMeshRef.current.morphTargetInfluences[morphIndex2] = morphIntensity;
			eyeLashMeshRef.current.morphTargetInfluences[morphIndex3] = morphIntensity;
			eyeLashMeshRef.current.morphTargetInfluences[morphIndex4] = morphIntensity;
		};

		// Track Up
		TrackDirection(
			character.trackUpMinY,
			character.trackUpMaxY,
			_myTarget.position.y,
			character.leftEyeLookUpIndex,
			character.rightEyeLookUpIndex,
			character.leftEyeLookUpIndex,
			character.rightEyeLookUpIndex
		);

		// Track Right
		TrackDirection(
			character.trackRightMinX,
			character.trackRightMaxX,
			_myTarget.position.x,
			character.leftEyeLookLeftIndex,
			character.leftEyeLookLeftIndex,
			character.rightEyeLookLeftIndex,
			character.rightEyeLookLeftIndex
		);

		// Track Left
		TrackDirection(
			character.trackLeftMinX,
			character.trackLeftMaxX,
			_myTarget.position.x,
			character.leftEyeLookRightIndex,
			character.leftEyeLookRightIndex,
			character.rightEyeLookRightIndex,
			character.rightEyeLookRightIndex
		);
	}
}
