import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "@frontegg/react";

function PrivateRoute({ localUser, children }) {
	const location = useLocation();
	const { user, isAuthenticated, isLoading } = useAuth();
	// console.log("localUser", localUser);
	// console.log("(!user && !localUser)", !user && !localUser);
	// console.log("isAuthenticated", isAuthenticated);
	if (isLoading) {
		return <p>...Loading</p>;
	}
	if (localUser) {
		return <>{children}</>;
	}
	// if (!isAuthenticated || !user) {
	// 	return (
	// 		<Navigate
	// 			to={{
	// 				pathname: "/oauth/account/login",
	// 				search: `?redirectUrl=${encodeURIComponent(location.pathname + location.search)}`,
	// 				state: { from: location.pathname }
	// 			}}
	// 		/>
	// 	);
	// }
	return children;
}

export default PrivateRoute;
