import { useRef, useEffect } from "react";

const useStopwatch = (targetTime) => {
	const isRecording = useRef(false);
	const startTime = useRef(null);
	const pausedTime = useRef(0);
	const targetTimeRef = useRef(parseInt(targetTime, 10) || 0);

	useEffect(() => {
		targetTimeRef.current = parseInt(targetTime, 10) || 0;
	}, [targetTime]);

	const startStopWatch = (offSet = null) => {
		if (offSet != null) {
			pausedTime.current = offSet;
		}
		startTime.current = Date.now() - pausedTime.current;
		isRecording.current = true;
	};

	const stopStopWatch = () => {
		isRecording.current = false;
		pausedTime.current = 0;
	};

	const pauseStopWatch = () => {
		if (isRecording.current) {
			pausedTime.current = Date.now() - startTime.current;
			isRecording.current = false;
		}
	};
	const getElapsedTimeFromTimestamp = (timestamp) => {
		if (isRecording.current) {
			return timestamp - startTime.current;
		}
		return pausedTime.current + (timestamp - Date.now());
	};
	const getElapsedTime = () => {
		if (isRecording.current) {
			return Date.now() - startTime.current;
		}
		return pausedTime.current;
	};

	const getRemainingTime = () => {
		const elapsedTime = getElapsedTime();
		const remainingTime = targetTimeRef.current * 1000 - elapsedTime;
		return remainingTime > 0 ? remainingTime : 0;
	};
	return {
		startStopWatch,
		stopStopWatch,
		pauseStopWatch,
		getElapsedTime,
		getElapsedTimeFromTimestamp,
		getRemainingTime
	};
};

export default useStopwatch;
