import * as THREE from "three";

// function to generate uuid
function uuidv4() {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
		const r = (Math.random() * 16) | 0;
		const v = c === "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
}
// animation class
function Animation(
	name,
	mixer,
	root,
	eyelashroot,
	animationController,
	loopType = THREE.LoopRepeat,
	blendMode = THREE.NormalAnimationBlendMode,
	clampWhenFinished = true
) {
	this.name = name;
	this.bodyAction = null;
	this.boneAction = null;
	this.bodyClip = null;
	this.boneClip = null;
	this.eyelashClip = null;
	this.root = root;
	this.eyelashroot = eyelashroot;
	this.childroot = root;
	this.mixer = mixer;
	this.loopType = loopType;
	this.log = false;
	this.playingAction = null;
	this.animationController = animationController;

	this.setBodyClip = function (bodyClip) {
		if (bodyClip == null) {
			return;
		}

		this.bodyClip = { ...bodyClip };
		this.bodyClip.name = `${this.name}_body`;
		// console.error("LOOK FOR THIS: " + this.name + "_body");
		if (blendMode === THREE.AdditiveAnimationBlendMode) {
			THREE.AnimationUtils.makeClipAdditive(this.bodyClip);
		} else {
			this.bodyClip.blendMode = blendMode;
		}

		// otherAnimations2.animations[3].tracks[0].name = "CC_Base_Body_1.morphTargetInfluences"
		this.bodyClip.tracks[0].name = "CC_Base_Body_1.morphTargetInfluences";
		this.bodyAction = this.mixer.clipAction(this.bodyClip, this.root);
		// console.log(this.mixer._actions)
		this.bodyAction.clampWhenFinished = clampWhenFinished;
		this.bodyAction.loop = this.loopType;
		this.bodyAction.setEffectiveTimeScale(1);
		this.bodyAction.setEffectiveWeight(1);
		this.bodyAction.enabled = false;
		this.bodyAction.play();
		if (this.loopType === THREE.LoopRepeat) {
			const extraClip = { ...this.bodyClip };
			extraClip.name = `${this.name}_body2`;
			extraClip.uuid = uuidv4();
		}
	};
	this.setBoneClip = function (boneClip) {
		if (boneClip == null) {
			return;
		}

		this.boneClip = { ...boneClip };
		this.boneClip.name = `${this.name}_bone`;

		if (blendMode === THREE.AdditiveAnimationBlendMode) {
			THREE.AnimationUtils.makeClipAdditive(this.boneClip);
		} else {
			this.boneClip.blendMode = blendMode;
		}
		// console.log(this.boneClip,this.root)
		// console.log(boneClip,this.root)

		this.boneAction = this.mixer.clipAction(this.boneClip, this.root);
		// console.log(this.mixer._actions)
		this.boneAction.clampWhenFinished = clampWhenFinished;
		this.boneAction.loop = this.loopType;
		this.boneAction.setEffectiveTimeScale(1);
		this.boneAction.setEffectiveWeight(1);
		this.boneAction.enabled = false;
		this.boneAction.play();
		if (this.loopType === THREE.LoopRepeat) {
			const extraClip = { ...this.boneClip };
			extraClip.name = `${this.name}_bone2`;
			extraClip.uuid = uuidv4();
		}
	};

	this.setEyelashClip = function (eyelashClip) {
		try {
			if (!eyelashClip) {
				return;
			}

			this.eyelashClip = { ...eyelashClip };
			this.eyelashClip.name = `${this.name}_eyelash`;
			// console.error("LOOK FOR THIS: " + this.eyelashClip);
			if (blendMode === THREE.AdditiveAnimationBlendMode) {
				THREE.AnimationUtils.makeClipAdditive(this.eyelashClip);
			} else {
				this.eyelashClip.blendMode = blendMode;
			}

			// otherAnimations2.animations[3].tracks[0].name = "CC_Base_Body_1.morphTargetInfluences"
			this.eyelashClip.tracks[0].name = "CC_Eyelashes.morphTargetInfluences";

			this.eyelashAction = this.mixer.clipAction(this.eyelashClip, this.eyelashroot);
			// console.error(GetEyelashObjectVar.Object.name);

			// console.log(this.mixer._actions)
			this.eyelashAction.clampWhenFinished = clampWhenFinished;
			this.eyelashAction.loop = this.loopType;
			this.eyelashAction.setEffectiveTimeScale(1);
			this.eyelashAction.setEffectiveWeight(1);
			this.eyelashAction.enabled = false;
			this.eyelashAction.play();
			if (this.loopType === THREE.LoopRepeat) {
				const extraClip = { ...this.eyelashClip };
				extraClip.name = `${this.name}_eyelash2`;
				extraClip.uuid = uuidv4();
			}
		} catch (err) {
			console.error(err);
		}
	};

	this.crossFadeFrom = function (otherAnimation, duration = 1, warp = false) {
		if (otherAnimation == null) {
			console.warn("otherAnimation is null");
			return;
		}
		if (otherAnimation.name === this.name) {
			console.error("other animation is this animation", otherAnimation);
			return;
		}
		if (otherAnimation.bodyAction == null) {
			console.warn("otherAnimation body action is null", otherAnimation);
			return;
		}
		if (otherAnimation.boneAction == null) {
			console.warn("otherAnimation bone action is null", otherAnimation);
			return;
		}
		if (otherAnimation.eyelashAction == null) {
			console.warn("otherAnimation eyelash action is null", otherAnimation);
			return;
		}
		if (this.bodyAction == null) {
			console.warn("body action is null", this);
			return;
		}
		if (this.boneAction == null) {
			console.warn("bone action is null", this);
			return;
		}
		if (this.eyelashAction == null) {
			console.warn("eyelash action is null", this);
			return;
		}
		function setWeight(action, weight) {
			action.enabled = true;
			action.setEffectiveTimeScale(1);
			action.setEffectiveWeight(weight);
		}
		if (this.log) {
			// console.warn("crossFadeFrom", otherAnimation.name, "to ", this.name, Date.now());
		}

		const clip = this.boneClip;
		const { duration: clipdiration } = clip;
		// console.warn("crossFadeFrom", otherAnimation.name, "to ", this.name, duration, clipdiration, Date.now());

		this.boneAction.paused = false;
		this.bodyAction.paused = false;
		if (eyelashroot) {
			this.eyelashAction.paused = false;
		}

		otherAnimation.boneAction.paused = false;
		otherAnimation.bodyAction.paused = false;
		if (eyelashroot) {
			otherAnimation.eyelashAction.paused = false;
		}

		setWeight(this.bodyAction, 1);
		setWeight(this.boneAction, 1);
		if (eyelashroot) {
			setWeight(this.eyelashAction, 1);
		}

		this.bodyAction.time = 0;
		this.boneAction.time = 0;
		if (eyelashroot) {
			this.eyelashAction.time = 0;
		}

		otherAnimation.bodyAction.crossFadeTo(this.bodyAction, duration, true);
		otherAnimation.boneAction.crossFadeTo(this.boneAction, duration, true);
		if (eyelashroot) {
			otherAnimation.eyelashAction.crossFadeTo(this.eyelashAction, duration, true);
		}

		this.playingAction = this.bodyAction;
	};
	// play function for the animation
	this.Play = function (fadeInDuration = 0.35) {
		// console.log(this);
		if (this.bodyAction == null) {
			// console.warn("body action is null");
			// return;
		}
		if (this.boneAction == null) {
			console.warn("bone action is null");
			return;
		}
		if (this.eyelashAction == null) {
			console.warn("eyelash action is null");
			// return;
		}
		// this.bodyAction.reset();
		// this.boneAction.reset();

		if (this.bodyAction != null) {
			this.bodyAction.time = 0;
		}

		this.boneAction.time = 0;

		if (this.bodyAction != null) {
			if (eyelashroot) {
				this.eyelashAction.time = 0;
			}
		}

		if (this.bodyAction != null) {
			this.boneAction.paused = false;
		}
		if (this.bodyAction != null) {
			if (eyelashroot) {
				this.eyelashAction.paused = false;
			}
		}

		if (this.bodyAction != null) {
			this.bodyAction.enabled = true;
		}

		this.boneAction.enabled = true;
		if (this.bodyAction != null) {
			if (eyelashroot) {
				this.eyelashAction.enabled = true;
			}
		}

		// setWeight( this.bodyAction, 1 );
		// setWeight( this.boneAction, 1 );
		const clip = this.boneClip;
		let { duration } = clip;
		const transitionDuration = getRandomizedDuration(fadeInDuration);
		if (duration - transitionDuration > 0) {
			duration -= transitionDuration;
		}

		if (this.bodyAction != null) {
			this.bodyAction.fadeIn(transitionDuration);
		}

		this.boneAction.fadeIn(transitionDuration);

		if (this.bodyAction != null) {
			if (eyelashroot) {
				this.eyelashAction.fadeIn(transitionDuration);
			}
		}
		if (this.bodyAction != null) {
			this.bodyAction.play();
		}

		this.boneAction.play();
		if (this.bodyAction != null) {
			if (eyelashroot) {
				this.eyelashAction.play();
			}
		}

		// console.log("startLoop")
		if (clampWhenFinished == true && this.loopType === THREE.LoopOnce) {
			// was false
			this.animationController.CreateClock(`${this.name}fadeOut`, duration, () => {
				this.boneAction.fadeOut(transitionDuration);
			});
		} else if (this.bodyAction != null) {
			this.playingAction = this.bodyAction;

			// if(this.loopType === THREE.LoopRepeat){
			//     this.startLoop();
			// }
			// else
			// {
			//     this.stopLoop();
			// }
		}
	};
	this.stop = function (fadeOutDuration = 0.25) {
		if (this.bodyAction == null) {
			console.warn("body action is null");
			return;
		}
		if (this.boneAction == null) {
			console.warn("bone action is null");
			return;
		}
		if (this.eyelashAction == null) {
			console.warn("eyelash action is null");
			return;
		}
		this.bodyAction.fadeOut(fadeOutDuration);
		this.boneAction.fadeOut(fadeOutDuration);
		if (eyelashroot) {
			this.eyelashAction.fadeOut(fadeOutDuration);
		}
	};
}

// get randomized duration from duration whitin max and min range
function getRandomizedDuration(duration, x = 0.35, y = 0.15) {
	const min = duration - y;
	const max = duration + x;
	const randomDuration = duration + (Math.random() * (max - min) + min);
	return randomDuration;
}
// eslint-disable-next-line import/prefer-default-export
export function AnimationController({
	mixer = null,
	root = null,
	eyelashroot = null,
	AdditiveAnimsNames = [],
	ActiveListeningAnimName = null,
	TalkingAnimName = [],
	PassiveListeningName = null,
	ThinkingAnimNames = [],
	GreetingAnimName = null,
	LisnetingtToOtherAnimName = null,
	ReactionAnimsName = [],
	NodAnimsName = [],
	animationFiles = null,
	GetChosenTalk,
	log = false
} = {}) {
	// console.log("AnimationController created", mixer);
	// Set the necessary properties of the object
	this.mixer = mixer;
	this.root = root;
	this.ActiveListeningAnimName = ActiveListeningAnimName;
	this.TalkingAnimName = TalkingAnimName; // Todo
	this.PassiveListeningName = PassiveListeningName;
	// this.ThinkingAnimNames = ThinkingAnimNames;
	this.GreetingAnimName = GreetingAnimName;
	this.LisnetingtToOtherAnimName = LisnetingtToOtherAnimName;
	this.ReactionAnimsName = ReactionAnimsName;
	this.NodAnimsName = NodAnimsName;
	this.AdditiveAnimsNames = AdditiveAnimsNames;
	this.GetChosenTalk = GetChosenTalk;
	this.eyelashroot = eyelashroot;
	this.animationFiles = animationFiles;

	this.ActiveListeningAnim = new Animation(ActiveListeningAnimName, mixer, root, eyelashroot, this, THREE.LoopRepeat, true);

	this.ActiveListeningAnim.log = this.log;

	// this.TalkingAnim = new Animation(TalkingAnimName, mixer, root ,this);
	// this.TalkingAnim.log =  this.log;
	this.PassiveListeningAnim = new Animation(PassiveListeningName, mixer, root, eyelashroot, this, THREE.LoopRepeat, true);
	this.PassiveListeningAnim.log = this.log;
	this.ThinkingAnims = {};
	this.GreetingAnim = new Animation(GreetingAnimName, mixer, root, eyelashroot, this, THREE.LoopOnce);
	this.GreetingAnim.log = this.log;
	this.LisnetingtToOtherAnim = new Animation(LisnetingtToOtherAnimName, mixer, root, eyelashroot, this);
	this.LisnetingtToOtherAnim.log = false;

	this.AdditiveAnims = {};

	this.TalkingAnims = {};

	this.ReactionAnims = {};
	this.NodAnims = {};
	this.ActiveAnimation = null;
	this.State = "";
	this.log = log;
	this.timers = {};

	this.StartGreeting = function (callback = null) {
		this.StateChange("Greeting");

		const clip = this.GreetingAnim.bodyClip;
		let { duration } = clip;
		const transitionDuration = getRandomizedDuration(0.5);
		if (duration - transitionDuration > 0) {
			duration -= transitionDuration;
		}

		this.CreateClock("Greeting", duration, () => {
			// console.log("Greeting call back")

			if (callback != null) {
				callback();
			}

			this.DeleteClock("Greeting");
		});
	};
	this.StartListeningToOther = function () {
		// console.log("StartListeningToOther");
		// console.log(mixer)
		this.StateChange("LisnetingtToOther", 0.45);
		// this.State = "LisnetingtToOther";
		// let newAnim;
		// if(this.ActiveAnimation === this.LisnetingtToOtherAnim)
		// {
		//     newAnim = this.LisnetingtToOtherAnim2;
		//     console.log("LisnetingtToOtherAnim2")
		// }
		// else
		// {
		//     newAnim = this.LisnetingtToOtherAnim;
		//     console.log("LisnetingtToOtherAnim")

		// }
		// let clip = newAnim.boneClip;
		// let duration = clip.duration;
		// console.log(clip.name, clip.uuid )
		// let transitionDuration = getRandomizedDuration(0.45);
		// if(duration - transitionDuration > 0){
		//     duration -= transitionDuration;
		// }
		// if (this.ActiveAnimation){

		//     newAnim.crossFadeFrom(this.ActiveAnimation,transitionDuration)
		//     console.log("crossFadeFrom")
		// }
		// else{
		//     newAnim.Play();
		//     console.log("Play")
		// }
		// this.ActiveAnimation = newAnim;
		// this.CreateClock("stateAnim",duration,()=>{
		//     console.log("stateAnim call back")
		//     this.StartListeningToOther();
		//     //this.DeleteClock("stateAnim");

		// });
	};

	this.onAnimationLoop = function (event) {
		if (event.action.getClip().name === this.ActiveAnimation.name) {
			// console.warn("The talking animation looped!");
		}
	};

	// 	this.StartThinking = function () {
	// //		this.StateChange("Thinking", 0.5);
	// 	};
	this.getStateAnim = function (stateToCheck) {
		try {
			switch (stateToCheck) {
				case "PassiveListening":
					return this.PassiveListeningAnim;

				case "ActiveListening":
					return this.ActiveListeningAnim;

				case "Talking":
					// this.TalkingAnims[Object.keys(this.TalkingAnims)[Math.floor(Math.random() * Object.keys(this.TalkingAnims).length)]];
					return this.TalkingAnims[GetChosenTalk()]; // Todo

				case "Greeting":
					return this.GreetingAnim;

				case "LisnetingtToOther":
					return this.LisnetingtToOtherAnim;

				// case "Thinking":
				// 	// return random thinking anim
				// 	return this.ThinkingAnims[Object.keys(this.ThinkingAnims)[Math.floor(Math.random() * Object.keys(this.ThinkingAnims).length)]];

				default:
					return this.ActiveListeningAnim;
			}
		} catch (err) {
			console.log(err);
		}
	};

	// this.stateMachine = {
	// 	"Idle": {
	// 		"onEnter": () => this.StartPassiveListening(),
	// 		"onExit": () => {},
	// 		"transitions": {
	// 			"StartTalking": "Talking",
	// 			"StartReaction": "Reaction"
	// 		}
	// 	},
	// 	"Talking": {
	// 		"onEnter": (talkingName) => this.StartTalking(talkingName),
	// 		"onExit": () => {},
	// 		"transitions": {
	// 			"StopTalking": "Idle",
	// 			"StartReaction": "Reaction"
	// 		}
	// 	},
	// 	"Reaction": {
	// 		"onEnter": (reactionName) => this.StartReaction(reactionName),
	// 		"onExit": () => {},
	// 		"transitions": {
	// 			"StopReaction": "Idle",
	// 			"StartTalking": "Talking"
	// 		}
	// 	}
	// };

	this.StateChange = function (newState, inputFadeInDuration = 0.25) {
		// console.log(inputFadeInDuration);

		if (this.State === newState) {
			return;
		}

		// console.warn("Changing from: ", this.State, "To: ", newState);
		const fadeInDuration = getRandomizedDuration(inputFadeInDuration);
		// delays the changing of state while reaction is happening , only talking anim can interumpt a reaction

		if (this.State === "Reaction") {
			// Like has exit time off (always play full if not talking)
			// console.log("UpdateClock(moodOneShot)")
			this.UpdateClock("moodOneShot", () => {
				// console.log("moodOneShot Ran")
				this.State = "ReactionTransition";
				this.StateChange(newState, inputFadeInDuration);
			});
			return;
		}

		// console.log("DeleteClock(moodOneShot)")
		this.DeleteClock("moodOneShot");
		this.DeleteClock("talkingOneShot1");
		this.DeleteClock("talkingOneShot2");
		this.DeleteClock("listeningOneShot1");
		this.DeleteClock("listeningOneShot2");
		this.DeleteClock("PlisteningOneShot1");
		this.DeleteClock("PlisteningOneShot2");
		let newAnim = this.getStateAnim(newState);

		if (this.log) {
			// console.log(newState, newAnim.name);
		}

		if (this.ActiveAnimation) {
			newAnim.crossFadeFrom(this.ActiveAnimation, fadeInDuration);
		} else {
			newAnim.Play();
		}

		this.ActiveAnimation = newAnim;
		this.State = newState;
		// console.error("New Animation is: ", newAnim);
	};

	// this.NodOnCoolDown = false;
	this.StartAdditiveAnimation = function (additiveName) {
		const boneAnimations = Object.keys(this.AdditiveAnims).filter((name) => !name.includes("|Body|"));
		const additiveAnim = this.AdditiveAnims[boneAnimations[Math.floor(Math.random() * boneAnimations.length)]];

		if (additiveAnim) {
			// console.error("Playing nod: ", additiveAnim)
			additiveAnim.Play();

			this.StateChange("ActiveListening", 0.3);
		}
	};

	this.StartPassiveListening = function () {
		this.DeleteClock("moodOneShot");
		// console.warn("Start Passive Listening", this.State);
		// console.log("StartPassiveListening");
		// this.StateChange("PassiveListening");
		this.State = "PassiveListening";
		const PlisteningAnim = this.getStateAnim("PassiveListening");

		const clip = PlisteningAnim.boneClip;
		let { duration } = clip;
		const transitionDuration = getRandomizedDuration(0.4);
		if (duration - transitionDuration > 0) {
			duration -= transitionDuration;
		}
		if (this.ActiveAnimation) {
			PlisteningAnim.crossFadeFrom(this.ActiveAnimation, transitionDuration);
		} else {
			PlisteningAnim.Play();
		}
		this.ActiveAnimation = PlisteningAnim;

		this.CreateClock("PlisteningOneShot1", duration, () => {
			this.DeleteClock("PlisteningOneShot1");

			//
			const listeningAnim2 = this.GreetingAnim;
			listeningAnim2.crossFadeFrom(this.ActiveAnimation, transitionDuration);
			// console.warn("Clock1_Listening ran", listeningAnim2);

			const clip2 = listeningAnim2.boneClip;
			let { duration: duration2 } = clip2;

			const transitionDuration2 = getRandomizedDuration(0.25, 0.1, 0.1);
			// console.warn(duration2, transitionDuration2);

			if (duration2 - transitionDuration2 > 0) {
				duration2 -= transitionDuration2;
			}
			this.ActiveAnimation = listeningAnim2;

			this.CreateClock("PlisteningOneShot2", duration2, () => {
				this.DeleteClock("PlisteningOneShot2");
				// console.log("randomAnim call back")
				// console.warn("Clock2_Listening ran");

				this.StartPassiveListening();
			});
		});
	};

	this.StartActiveListening = function () {
		// console.warn("Start Active Listening", this.State);
		this.StateChange("ActiveListening", 0.35);
		// if (this.State === "ActiveListening") {
		// 	return;
		// }
		// if (this.State === "Reaction") {
		// 	// Like has exit time off (always play full if not talking)
		// 	// console.log("UpdateClock(moodOneShot)")
		// 	this.UpdateClock("moodOneShot", () => {
		// 		// console.log("moodOneShot Ran")
		// 		this.State = "ReactionTransition";
		// 		this.StartActiveListening();
		// 	});
		// 	return;
		// }
		// this.DeleteClock("moodOneShot");
		// this.State = "ActiveListening";
		// const listeningAnim = this.getStateAnim("ActiveListening");

		// const clip = listeningAnim.boneClip;
		// let { duration } = clip;
		// const transitionDuration = getRandomizedDuration(0.4);
		// console.log("ActiveListeningDuration ", JSON.parse(JSON.stringify({ duration, transitionDuration })));
		// if (duration - transitionDuration > 0) {
		// 	duration -= transitionDuration;
		// }
		// if (this.ActiveAnimation) {
		// 	console.warn("Active Listening has active animation");
		// 	listeningAnim.crossFadeFrom(this.ActiveAnimation, transitionDuration);
		// } else {
		// 	console.warn("Active Listening DOESN'T has active animation");
		// 	listeningAnim.Play();
		// }
		// this.ActiveAnimation = listeningAnim;

		// this.CreateClock("listeningOneShot1", duration, () => {
		// 	this.DeleteClock("listeningOneShot1");

		// 	//
		// 	const listeningAnim2 = this.GreetingAnim;
		// 	listeningAnim2.crossFadeFrom(this.ActiveAnimation, transitionDuration);
		// 	console.warn("Clock1_Listening ran", listeningAnim2);

		// 	const clip2 = listeningAnim2.boneClip;
		// 	let { duration: duration2 } = clip2;

		// 	const transitionDuration2 = getRandomizedDuration(0.25, 0.1, 0.1);
		// 	console.warn(duration2, transitionDuration2);

		// 	if (duration2 - transitionDuration2 > 0) {
		// 		duration2 -= transitionDuration2;
		// 	}
		// 	this.ActiveAnimation = listeningAnim2;

		// 	this.CreateClock("listeningOneShot2", duration2, () => {
		// 		this.DeleteClock("listeningOneShot2");
		// 		// console.log("randomAnim call back")
		// 		console.warn("Clock2_Listening ran");

		// 		this.StartActiveListening();
		// 	});
		// });
	};

	this.StartTalking = function (talkingName) {
		// console.warn("Start Talking", this.State);
		this.DeleteClock("moodOneShot");
		// this.StateChange("Talking", 0.3);
		// this.mixer.addEventListener("loop", this.onAnimationLoop); // Todo (change to similar to startReaction)
		// this.TalkingAnims[GetChosenTalk()];
		const talkingName1 = talkingName;
		const talkingName2 = "Confident smile";

		this.State = "Talking";
		const talkingAnim = this.TalkingAnims[talkingName];
		// console.warn(talkingAnim);

		// console.warn("Animation changing", talkingName, talkingAnim);

		const clip = talkingAnim.boneClip;
		let { duration } = clip;
		const transitionDuration = getRandomizedDuration(0.4);
		if (duration - transitionDuration > 0) {
			duration -= transitionDuration;
		}
		if (this.ActiveAnimation) {
			talkingAnim.crossFadeFrom(this.ActiveAnimation, transitionDuration);
		} else {
			talkingAnim.Play();
		}
		this.ActiveAnimation = talkingAnim;

		this.CreateClock("talkingOneShot1", duration, () => {
			this.DeleteClock("talkingOneShot1");

			//
			const talkingAnim2 = this.GreetingAnim;
			talkingAnim2.crossFadeFrom(this.ActiveAnimation, transitionDuration);
			// console.warn("Clock1_Talking ran", talkingAnim2);

			const clip2 = talkingAnim2.boneClip;
			let { duration: duration2 } = clip2;

			const transitionDuration2 = getRandomizedDuration(0.25, 0.1, 0.1);
			// console.warn(duration2, transitionDuration2);

			if (duration2 - transitionDuration2 > 0) {
				duration2 -= transitionDuration2;
			}
			this.ActiveAnimation = talkingAnim2;

			this.CreateClock("talkingOneShot2", duration2, () => {
				this.DeleteClock("talkingOneShot2");
				// console.log("randomAnim call back")
				// console.warn("Clock2_Talking ran");

				this.StartTalking(talkingName);
			});
		});
	};

	this.StartReaction = function (reactionName) {
		// console.warn("Start Reaction", this.State);
		if (this.State === "Reaction") {
			return;
		}
		const oldState = this.State;
		this.State = "Reaction";
		const reactionAnim = this.ReactionAnims[reactionName];
		const clip = reactionAnim.boneClip;
		// console.log("reaction animation: ", reactionAnim);
		let { duration } = clip;
		const transitionDuration = getRandomizedDuration(0.5);
		if (duration - transitionDuration > 0) {
			duration -= transitionDuration;
		}
		if (this.ActiveAnimation) {
			reactionAnim.crossFadeFrom(this.ActiveAnimation, transitionDuration);
		} else {
			reactionAnim.Play();
		}
		// console.log(reactionAnim)
		this.ActiveAnimation = reactionAnim;

		this.CreateClock("moodOneShot", duration, () => {
			// console.log("randomAnim call back")
			this.State = "ReactionTransition";
			if (oldState === "ActiveListening") {
				this.StartActiveListening();
			} else {
				this.StateChange(oldState, transitionDuration);
			}
		});

		// console.log(1,"StartReaction", reactionName,duration);

		// console.log(reactionAnim)
	};
	this.GetReactions = function () {
		return Object.keys(this.ReactionAnims);
	};

	this.GetTalks = function () {
		return Object.keys(this.TalkingAnims);
	};
	this.Update = function (delta) {
		// console.log(this.State);
		if (this.mixer) {
			//     console.log("anim controller Update")
			this.mixer.update(delta);
			// console.log(delta)
		}
		this.evaluateClocks(delta);
	};

	this.onAnimationLoop = function (e) {
		// properties of e: type, action and loopDelta
		// console.log("animation loop",e)
	};
	this.onAnimationFinished = function (e) {
		// console.log("animation finished",e)
		// properties of e: type, action and direction
	};

	this.UpdateClock = function (name, callback) {
		if (!this.timers) return;
		if (this.timers[name]) {
			this.timers[name].callback = callback;
		}
	};
	this.CreateClock = function (name, interval, callback) {
		if (!this.timers) this.timers = {};
		// console.log("CreateClock",name,interval)
		// console.log(this)
		this.timers[name] = { interval, callback, time: 0 };
		// console.log(Object.keys(this.timers))
	};

	this.DeleteClock = function (name) {
		// console.log("DeleteClock",name)
		delete this.timers[name];
	};

	this.evaluateClocks = function (delta) {
		if (!this.timers) return;
		// console.log(Object.keys(this.timers))
		Object.keys(this.timers).forEach((key) => {
			// Check if this.timers[key] exists and has a property called time
			if (this.timers[key] && typeof this.timers[key].time !== "undefined") {
				this.timers[key].time += delta;
				if (this.timers[key].time >= this.timers[key].interval) {
					this.timers[key].time = 0;
					this.timers[key].callback();
				}
			}
		});
	};
	this.getAnim = function () {
		return `${this.State} | ${this.ActiveAnimation?.name}`;
	};
	this.init = function () {
		// console.log("AnimationController initiated");
		// console.log(this.mixer);
		if (!this.mixer) {
			console.error("No mixer");
			return;
		}
		// console.log(this.mixer._actions)
		this.mixer.addEventListener("loop", this.onAnimationLoop);
		this.mixer.addEventListener("finished", this.onAnimationFinished);

		// SET CLAMP TO FALSE IF LOOPREPEAT AND TRUE IF LOOPONCE
		// create instances of our custom animation class
		this.TalkingAnimName.forEach((talkingAnimName) => {
			this.TalkingAnims[talkingAnimName] = new Animation( // Todo
				talkingAnimName,
				mixer,
				root,
				eyelashroot,
				this,
				THREE.LoopOnce,
				THREE.NormalAnimationBlendMode,
				true
			);
			this.TalkingAnims[talkingAnimName].log = this.log;
		});
		this.ReactionAnimsName.forEach((reactionAnimName) => {
			this.ReactionAnims[reactionAnimName] = new Animation(
				reactionAnimName,
				mixer,
				root,
				eyelashroot,
				this,
				THREE.LoopOnce,
				THREE.NormalAnimationBlendMode,
				true
			);
			this.ReactionAnims[reactionAnimName].log = this.log;
		});
		// this.ThinkingAnimNames.forEach((thinkingAnimName) => {
		// 	this.ThinkingAnims[thinkingAnimName] = new Animation(
		// 		thinkingAnimName,
		// 		mixer,
		// 		root,
		// 		eyelashroot,
		// 		this,
		// 		THREE.LoopPingPong,
		// 		THREE.NormalAnimationBlendMode,
		// 		false
		// 	);
		// 	this.ThinkingAnims[thinkingAnimName].log = this.log;
		// });
		this.AdditiveAnimsNames.forEach((additiveAnimName) => {
			this.AdditiveAnims[additiveAnimName] = new Animation(
				additiveAnimName,
				mixer,
				root,
				eyelashroot,
				this,
				THREE.LoopOnce,
				THREE.AdditiveAnimationBlendMode,
				true
			);
			this.AdditiveAnims[additiveAnimName].log = this.log;
		});

		const setBoneAndBodyClip = (animName, animInstance, animation) => {
			// console.log(`|A|${animName}`, "*"+animation.name+"*")
			if (animation.name.includes("001")) {
				return;
			}

			if (animation.name.includes(`|A|${animName}`)) {
				// console.log("setBoneClip")
				// console.log("LOOK FOR THIS ", animation)
				animInstance.setBoneClip(animation);
			}
			if (animation.name.includes(`|Body|${animName}`)) {
				// console.log("setBodyClip")
				animInstance.setBodyClip(animation);
			}
			if (animation.name.includes(`|Lash|${animName}`) && eyelashroot) {
				animInstance.setEyelashClip(animation);
			}
		};

		const setAdditiveBoneClip = (animName, animInstance, animation) => {
			// console.log(`|A|${animName}`, "*"+animation.name+"*")
			if (animation.name.includes("001")) {
				return;
			}

			if (animation.name.includes(`|A|${animName}`)) {
				// console.log("setBoneClip")
				// console.log("LOOK FOR THIS ", animation)
				animInstance.setBoneClip(animation);
			}

			if (animation.name.includes(`|Lash|${animName}`) && eyelashroot) {
				animInstance.setEyelashClip(animation);
			}

			if (animation.name.includes(`|Body|${animName}`)) {
				return;
			}
		};
		// loop throu all the animations and check if they match the names of one of the categories m, if it does then is sets the bone and body clips
		this.animationFiles.forEach((animationFile) => {
			// console.log(animationFile )
			animationFile.animations.forEach((animation) => {
				if (!animation) {
					return;
				}
				setBoneAndBodyClip(ActiveListeningAnimName, this.ActiveListeningAnim, animation);

				setBoneAndBodyClip(PassiveListeningName, this.PassiveListeningAnim, animation);
				setBoneAndBodyClip(GreetingAnimName, this.GreetingAnim, animation);
				setBoneAndBodyClip(LisnetingtToOtherAnimName, this.LisnetingtToOtherAnim, animation);

				this.TalkingAnimName.forEach((talkingAnimName) => {
					// console.log("LOOK FOR THIS ", talkingAnimName, this.TalkingAnims[talkingAnimName], animation)
					setBoneAndBodyClip(talkingAnimName, this.TalkingAnims[talkingAnimName], animation);
				}); ///
				this.ReactionAnimsName.forEach((reactionAnimName) => {
					setBoneAndBodyClip(reactionAnimName, this.ReactionAnims[reactionAnimName], animation);
				}); ///
				this.AdditiveAnimsNames.forEach((additiveAnimName) => {
					setAdditiveBoneClip(additiveAnimName, this.AdditiveAnims[additiveAnimName], animation);
				});
			});
		});

		delete this.animationFiles;
	};

	this.init();
}
