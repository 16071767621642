import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef, Suspense } from "react";
import { useFrame, useLoader, useThree } from "@react-three/fiber";
import { PositionalAudio, Html, TransformControls, Image, PerspectiveCamera } from "@react-three/drei";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";

import useMeshoptGLTFLoader from "./useMeshoptGLTFLoader"; // path to your custom hook
import { LineBasicMaterial, BufferGeometry, Line } from "three";

import { useCachedModel } from "./useCachedModel.js";
import { clamp01, lerp, AnimationValueToVisemesMorphs, getAnimation, getAudio } from "./lipsyncUtility.js";
import { AnimationController } from "./AnimationController.js";
import { RealisticEye } from "./RealisticEye.js";
import EyeTracking from "./EyeTracking";

// eslint-disable-next-line import/prefer-default-export
export const Character = forwardRef((props, ref) => {
	const characterName = props.name;
	const propsAnimations = props.animations;
	const propLightSettings = props.lightSettings;
	const { characterDict } = props;
	const { hideMesh } = props;
	const { onCharacterLoadedCb } = props;

	// console.log("Character props", props);
	const ChosenTalkRef = useRef("Talk_Happy_F");

	const [paused, setPaused] = useState(false);

	const [lightSettings, setLightSettings] = useState(propLightSettings);

	const SubtitleTrigger = useRef();

	function setSubtitleTrigger(trigger) {
		SubtitleTrigger.current = trigger;
	}

	function MeshoptDecoder() {
		// constructor function body
	}

	// reference to the audio element
	// const sound = useRef();
	const audioRef = useRef();

	//	const [audioBuffers, setAudioBuffers] = useState({});

	// hook to load model from url or cache
	const { model, loading } = useCachedModel(props.url);

	// hook to load animation from url

	// const glbAnimations = useLoader(GLTFLoader, props.urlAnimations);
	const [glbAnimations, error2] = useMeshoptGLTFLoader(props.urlAnimations);

	// Create and set the MeshoptDecoder

	// Now load the GLTF animations
	// const glbAnimations = useLoader(GLTFLoader, props.urlAnimations);

	const animationControllerRef = useRef();

	// animation mixer. This is used to play animations and blend them
	const [mixer] = useState(new THREE.AnimationMixer());
	// reference to the animations in the model like idle, and talkingIdle
	const actions = useRef();
	const moods = useRef();

	// start time of the lipsync animation
	const [startTime, set_startTime] = useState(Date.now());
	const [pausedTime, set_pausedTime] = useState(Date.now());

	// used to start the lipsync animation
	const [startAnimation, set_startAnimation] = useState(false);
	// the lipsync animation
	const [animation, set_animation] = useState(null);
	const jawBone = useRef();

	// reference to the jaw bone start rotation. this used to lerp the Jaw Open and Closed
	const jawBoneStartRotation = useRef();

	// reference to the body
	const body = useRef();

	const eyelash = useRef();

	const skeleton = useRef();

	const leftEyeBone = useRef();

	const rightEyeBone = useRef();
	// Create a state to control whether the character should look at the viewPos
	const [shouldLook, setShouldLook] = useState(false);

	// referene to the model
	const myMesh = React.useRef();

	const headBoneRef = React.useRef();

	const camera = useRef();

	const currentAudioSourceStatus = useRef(null);

	const timers = useRef();

	const [blinkStartTime, set_blinkStartTime] = useState(Date.now());

	const textureLoader = new THREE.TextureLoader();

	const cubeGeometry = new THREE.BoxGeometry(0.03, 0.03, 0.03);
	const cubeMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
	cubeMaterial.opacity = 0.0; // 0.1 for debugging realistic eyes
	cubeMaterial.transparent = true;
	const cubeMesh = new THREE.Mesh(cubeGeometry, cubeMaterial);
	cubeMesh.position.set(0, 1.3, 0.2);

	useImperativeHandle(ref, () => ({
		makeEyeLookAt,
		StartActiveListening,
		StartGreetingV2,
		StartPassiveListening,
		StartListeningToOther,
		StartThinking,
		StartReaction,

		StartAdditiveAnimation,
		OnVadResult,
		OnGptReaction,
		OnGptTalking,
		lightSettings,
		setLightSettings,
		characterDict,
		StopSpeech,
		ResumeSpeech,
		getAnim,
		setPaused,
		setSubtitleTrigger,
		StartTalking,
		UpdateAnimations,
		animationControllerRef,
		GetChosenTalk,
		wipeAnimationData
	}));
	function wipeAnimationData() {
		set_animation(null);
		set_startAnimation(false);
	}

	// useEffect(() => {
	// 	console.log("Animations: ", glbAnimations);
	// }, [glbAnimations]);

	useEffect(() => {
		if (myMesh.current) {
			headBoneRef.current = myMesh.current?.getObjectByName("CC_Base_NeckTwist02");
			console.log(headBoneRef.current);
		}
	}, [myMesh.current]);

	// This useEffect hook is triggered once the loading is done.
	// It applies the scale and saves the model to myMesh.
	useEffect(() => {
		// Check if loading is done
		if (!loading) {
			// console.log("model", model);
			// If there is no model or scene in the model, return
			if (!model || !model.scene) return;

			// Save the model scene to myMesh
			myMesh.current = model.scene;
			// Disable frustum culling for the scene
			model.scene.frustumCulled = false;
			// Traverse through all nodes in the scene
			model.scene.traverse((node) => {
				// If the node is a Mesh
				if (node instanceof THREE.Mesh) {
					// Disable frustum culling for the node
					// eslint-disable-next-line no-param-reassign
					node.frustumCulled = false;
					// Enable shadow casting for the node
					// eslint-disable-next-line no-param-reassign
					node.castShadow = true;
					// console.log(node.name);

					// console.log(node.name);
					if (node.name === "Side_part_wavy_2") {
						// || node.name === "Side_part_wavy003_1") {
						// eslint-disable-next-line no-param-reassign
						node.castShadow = true;
						// eslint-disable-next-line no-param-reassign
						node.receiveShadow = true;
						// eslint-disable-next-line no-param-reassign
						node.frustumCulled = false;
						// eslint-disable-next-line no-param-reassign
						node.material.transparent = true;
						// eslint-disable-next-line no-param-reassign
						node.material.side = THREE.DoubleSide;
						// eslint-disable-next-line no-param-reassign
						node.material.alphaTest = 0;
						// eslint-disable-next-line no-param-reassign
						node.material.opacity = 1;
						// eslint-disable-next-line no-param-reassign
						node.material.alphaMode = "BLEND";
						// node.material.depthFunc = THREE.LessDepth;
					} else if (node.name === "Medium_Afro003") {
						// node.material = hairMaterial;
						// node.material.side = THREE.DoubleSide;
						// node.material.transparent = true;
						// node.material.alphaTest = 0.02; // Adjust this value as needed
						// node.material.depthWrite = false;
						// node.material.alphaToCoverage = true; // Enable alpha to coverage
					}
				}
			});

			// Initialize timers and actions
			timers.current = {};
			actions.current = {};
			// Uncomment the following lines to set the scale of myMesh
			//  myMesh.current.scale.set(.01, .01, .01);
			// myMesh.current.scale.set(.1, .1, .1);
		}
	}, [model, loading]);

	// once the model is loaded and the animation is loaded
	// create the actions for the animations
	// and set the body and jaw bone references
	useEffect(() => {
		if (loading) {
			return;
		}
		if (!myMesh.current) {
			// 	console.log("No mesh found", myMesh.current);
			return;
		}
		// myMesh.current.traverse((child) => {
		// 	console.log("Meshes", child.name);
		// });
		// console.log("myMesh", myMesh.current);
		if (myMesh.current.getObjectByName("CC_Base_Body_primitive0")) {
			body.current = myMesh.current.getObjectByName("CC_Base_Body_primitive0");
		} else {
			body.current = myMesh.current.getObjectByName("CC_Base_Body_1");
		}
		if (myMesh.current.getObjectByName("Classic_side_part001")) {
			// hide the hair
			myMesh.current.getObjectByName("Classic_side_part001").visible = false;
		}
		if (myMesh.current.getObjectByName("RL_HairMesh001")) {
			// hide the hair
			myMesh.current.getObjectByName("RL_HairMesh001").visible = true;
			myMesh.current.getObjectByName("RL_HairMesh001").alphaHashed = true;
		}
		if (myMesh.current.getObjectByName("RL_HairMesh002")) {
			// hide the hair
			myMesh.current.getObjectByName("RL_HairMesh002").visible = false;
		}
		if (myMesh.current.getObjectByName("RL_HairMesh003")) {
			// hide the hair
			myMesh.current.getObjectByName("RL_HairMesh003").visible = false;
		}
		// John
		if (myMesh.current.getObjectByName("Classic_short")) {
			// hide the hair
			// myMesh.current.getObjectByName("Classic_short").visible = true;

			myMesh.current.getObjectByName("Classic_short").material.frustumCulled = false;
			myMesh.current.getObjectByName("Classic_short").material.DoubleSide = true;
			myMesh.current.getObjectByName("Classic_short").alphaHashed = true;
		}
		if (myMesh.current.getObjectByName("Classic_short001")) {
			// hide the hair
			// myMesh.current.getObjectByName("Classic_short001").visible = true;
			myMesh.current.getObjectByName("Classic_short001").material.frustumCulled = false;
			myMesh.current.getObjectByName("Classic_short001").material.DoubleSide = true;
			myMesh.current.getObjectByName("Classic_short001").material.transparent = true;
			// myMesh.current.getObjectByName("Classic_short001").alphaHashed = true;
			// myMesh.current.getObjectByName("Classic_short001").alphaTest = 0.01; // Adjust this value as needed
			// myMesh.current.getObjectByName("Classic_short001").transparent = true;
		}
		if (myMesh.current.getObjectByName("Classic_short002")) {
			// hide the hair
			myMesh.current.getObjectByName("Classic_short002").visible = false;
			myMesh.current.getObjectByName("Classic_short002").material.frustumCulled = true;
			myMesh.current.getObjectByName("Classic_short002").material.transparent = true;
			// myMesh.current.getObjectByName("Classic_short002").material.depthWrite = false; // Add this line
			// myMesh.current.getObjectByName("Classic_short002").renderOrder = 2; // Add this line
		}
		// Myra
		if (myMesh.current.getObjectByName("Wavy_short_Bob")) {
			// hide the hair
			// myMesh.current.getObjectByName("Wavy_short_Bob").visible = false;

			myMesh.current.getObjectByName("Wavy_short_Bob").material.frustumCulled = false;
			myMesh.current.getObjectByName("Wavy_short_Bob").material.DoubleSide = true;
			myMesh.current.getObjectByName("Wavy_short_Bob").alphaHashed = true;
			myMesh.current.getObjectByName("Wavy_short_Bob").material.depthWrite = false;
		}
		if (myMesh.current.getObjectByName("Wavy_short_Bob001")) {
			// hide the hair
			myMesh.current.getObjectByName("Wavy_short_Bob001").visible = false;
			myMesh.current.getObjectByName("Wavy_short_Bob001").material.frustumCulled = false;
			myMesh.current.getObjectByName("Wavy_short_Bob001").material.DoubleSide = true;
			myMesh.current.getObjectByName("Wavy_short_Bob001").material.transparent = true;
			myMesh.frustumCulled = false;
			// myMesh.current.getObjectByName("Classic_short001").alphaHashed = true;
			// myMesh.current.getObjectByName("Classic_short001").alphaTest = 0.01; // Adjust this value as needed
			// myMesh.current.getObjectByName("Classic_short001").transparent = true;
		}

		if (myMesh.current.getObjectByName("Medium_Afro")) {
			// hide the hair
			myMesh.current.getObjectByName("Medium_Afro").material.frustumCulled = false;
			myMesh.current.getObjectByName("Medium_Afro").material.DoubleSide = true;
			myMesh.current.getObjectByName("Medium_Afro").material.alphaHashed = true;
			// myMesh.current.getObjectByName("Medium_Afro").material.visible = false;
			myMesh.frustumCulled = false;
		}
		if (myMesh.current.getObjectByName("Medium_Afro001")) {
			// hide the hair
			myMesh.current.getObjectByName("Medium_Afro001").material.frustumCulled = false;
			myMesh.current.getObjectByName("Medium_Afro001").material.DoubleSide = true;
			// myMesh.current.getObjectByName("Medium_Afro001").material.visible = false;

			myMesh.frustumCulled = false;
		}
		if (myMesh.current.getObjectByName("Afro_Magic_2001")) {
			// hide the hair
			myMesh.current.getObjectByName("Afro_Magic_2001").material.frustumCulled = false;
			myMesh.current.getObjectByName("Afro_Magic_2001").material.DoubleSide = true;
			myMesh.current.getObjectByName("Afro_Magic_2001").material.visible = false;
			// myMesh.current.getObjectByName("Medium_Afro.001").material.alphaHash = true;

			myMesh.frustumCulled = false;
		}
		if (myMesh.current.getObjectByName("CC_Eyelashes")) {
			eyelash.current = myMesh.current.getObjectByName("CC_Eyelashes");
			// console.log("Look for this: ", myMesh.current);
			// myMesh.current.getObjectByName("CC_Eyelashes").visible = false;
		}
		if (myMesh.current.getObjectByName("CC_Base_L_Eye")) {
			leftEyeBone.current = myMesh.current.getObjectByName("CC_Base_L_Eye");
		}
		if (myMesh.current.getObjectByName("CC_Base_R_Eye")) {
			rightEyeBone.current = myMesh.current.getObjectByName("CC_Base_R_Eye");
		}
		// set skeleton
		skeleton.current = myMesh.current.getObjectByName("CC_Base_BoneRoot");

		//   console.log(body.current);

		jawBone.current = myMesh.current.getObjectByName("CC_Base_JawRoot");
		jawBoneStartRotation.current = jawBone.current.quaternion.clone();
	}, [loading, myMesh]);

	// animation useEffect
	useEffect(() => {
		if (loading) {
			return;
		}
		if (!glbAnimations) {
			console.log(error2);
		}
		// console.log(loading, mixer, glbAnimations, characterName, propsAnimations);

		if (glbAnimations && glbAnimations.animations && glbAnimations.animations.length && body.current) {
			// console.log("glbAnimations", glbAnimations);
			//	console.log("body", body.current);
			const log = false;
			if (characterName === "John") {
				// log = true;
			}
			console.log("new animationController");

			const animationController = new AnimationController({
				mixer,
				root: body.current,
				eyelashroot: eyelash.current,
				...propsAnimations,
				animationFiles: [glbAnimations],
				log,
				GetChosenTalk
			});
			animationControllerRef.current = animationController;

			animationController.StartActiveListening(); // Turn off if starting animation needs to be static

			set_startTime(Date.now());
			// console.log("call onCharacterLoadedCb");
			if (onCharacterLoadedCb && typeof onCharacterLoadedCb === "function") {
				onCharacterLoadedCb();
			}
		}
	}, [loading, mixer, glbAnimations, characterName, propsAnimations]);

	// this is the main update loop called once per frame
	// it is used to update the animation mixer and the lipsync animation
	useFrame((state, delta) => {
		if (loading) {
			// console.log("still loading")
			return;
		}
		// const delta = clock.getDelta();
		if (animationControllerRef.current) {
			if (!paused) {
				animationControllerRef.current.Update(delta); // Updates the animations per frame (like update function)
			} else {
				//   console.log("paused")
			}

			// console.log(delta)
		}
		// evaluate clocks
		if (timers.current) {
			// evaluateClocks(delta)
		}
		if (myMesh.current) {
			const [x, y, z] = props.position;
			const [rx, ry, rz] = props.rotation;

			myMesh.current.position.x = x;
			myMesh.current.position.y = y;
			myMesh.current.position.z = z;

			myMesh.current.rotation.x = rx;
			myMesh.current.rotation.y = ry;
			myMesh.current.rotation.z = rz;
		}
		if (startAnimation && animation) {
			if (!paused) {
				// console.error("Unpaused. Animation: ", animation);
				const time = (Date.now() - startTime) / 1000;
				evaluateAnimation(time, animation);
			}
		}
	});

	function StartNod(nodName) {
		// console.log("StartNod")
		animationControllerRef.current.StartNod(nodName);
	}
	function StartActiveListening() {
		animationControllerRef.current.StartActiveListening();
	}
	function StartPassiveListening() {
		animationControllerRef.current.StartPassiveListening();
	}

	function StartListeningToOther() {
		animationControllerRef.current.StartListeningToOther();
	}
	function StartThinking() {
		animationControllerRef.current.StartThinking();
	}
	function GetReactions() {
		return animationControllerRef.current.GetReactions();
	}
	function GetTalks() {
		return animationControllerRef.current.GetTalks();
	}
	function getAnim() {
		// return"test";
		return animationControllerRef?.current?.getAnim();
	}

	function StartAdditiveAnimation(additiveName = "NodAdditive") {
		return animationControllerRef?.current?.StartAdditiveAnimation(additiveName);
	}
	function StartReaction(reactionName) {
		animationControllerRef.current.StartReaction(reactionName);
	}
	function OnGptReaction(reaction) {
		const options = {
			Bored: ["Fall asleep", "Phubbing"],
			Irritated: ["Annoyed_M", "Upset_M"],
			Confused: ["Confused_M", "Miffed_F"],
			Pleased: ["Pleased_F", "Optimistic_F"]
		};
		const choices = options[reaction];
		// console.log("choices", choices)
		if (choices) {
			const ChosenReaction = choices[Math.floor(Math.random() * choices.length)];
			// console.log("ChosenReaction", reaction, ChosenReaction);
			StartReaction(ChosenReaction);
		}
	}
	function StartTalking(talkingName) {
		try {
			animationControllerRef.current.StartTalking(talkingName);
		} catch (error) {
			console.error("This Is The Error: ", error);
		}
	}

	function ReturnTalkingAnimNames(inputCharacterName) {
		switch (
			inputCharacterName // To Do: add all animations
		) {
			case "Nina":
				return { Happy: ["Talk_Happy_F", "Talk_Nonchalant"], Assertive: ["Talk_Confident", "Talk_Defensive"] };
			case "Myra":
				return { Happy: ["Talk_Happy_F", "Talk_Nonchalant"], Assertive: ["Talk_Confident", "Talk_Defensive"] };
			case "John":
				return { Happy: ["Talk_Happy_F", "Talk_Nonchalant"], Assertive: ["Talk_Confident", "Talk_Defensive"] };
			case "David":
				return { Happy: ["Talk_Happy_F", "Talk_Nonchalant"], Assertive: ["Talk_Confident", "Talk_Defensive"] };

			default:
				break;
		}
	}

	function OnGptTalking(talking) {
		const options = ReturnTalkingAnimNames(characterName);

		// console.log(options);
		// let options = { Happy: ["Talk_Happy_F",], Confident: ["Talk_Confident"] }
		const choices = options[talking];
		// console.log("choices", choices)
		if (choices) {
			ChosenTalkRef.current = choices[Math.floor(Math.random() * choices.length)];
			// console.error("ChosenTalk", talking, ChosenTalkRef.current);
			animationControllerRef.current.StartTalking(ChosenTalkRef.current);
		}
	}

	function GetChosenTalk() {
		// console.error("ChosenTalk var is: ", ChosenTalk);
		return ChosenTalkRef.current;
	}

	function OnVadResult(text) {
		// console.log("OnVadResult")
		StartNod();
		const chanceInPercent = 25;
		const chance = Math.random() * 100;
		// if (chance < chanceInPercent) {
		// }
	}
	// converts from viseme value to jaw open value
	function V_openToJawOpen(value) {
		// the jaw open value is between 1.5 and 1.8 these can be tweaked to get the right look
		// Myra & Nina: Between 0.0 & 0.2
		// John & Ian: Between -1.5 & -1.8
		let jawStartRot;
		let jawEndRot;

		if (characterName === "Nina") {
			jawStartRot = 1.6; // was 0.0
			jawEndRot = 1.75; // was 0.2
		} else if (characterName === "Myra") {
			jawStartRot = 1.6; // was 1.5
			jawEndRot = 1.71; // was 1.8
		} else if (characterName === "David") {
			jawStartRot = 1.6; // was 1.5
			jawEndRot = 1.75; // was 1.8
		} else if (characterName === "John") {
			jawStartRot = 1.6; // was -1.6
			jawEndRot = 1.75; // was -1.7
		} else {
			jawStartRot = -1.6; // was -1.6
			jawEndRot = -1.7; // was -1.7
		}

		// if (value > 0)
		// {
		//     value += 0.2; //add 0.2 to the value to make it more open
		// }
		// clamp the value between 0 and 1
		const clampedValue = clamp01(value);
		return lerp(jawStartRot, jawEndRot, clampedValue);
	}

	// apply morph targets to the model
	function setMorphTargetInfluence(name, value) {
		// console.log("setMorphTargetInfluence")
		if (!body.current) {
			return;
		}
		if (body.current.morphTargetDictionary && body.current.morphTargetInfluences) {
			const index = body.current.morphTargetDictionary[name];
			body.current.morphTargetInfluences[index] = value; // check this clamp?
		} else {
			console.log("morphTargetDictionary or morphTargetInfluences not found");
			// console.log(body.current);
		}
	}

	function getMorphTargetInfluence(name) {
		if (!body.current) {
			return null;
		}
		const index = body.current.morphTargetDictionary[name];
		return body.current.morphTargetInfluences[index];
	}
	// pass in a value between 0 and 1
	// convers to a rotation value for how open the jaw should be
	function setJawOpen(value) {
		if (jawBoneStartRotation.current) {
			if (characterName === "Nina") {
				const jawRot = V_openToJawOpen(value);
				// console.error("Jaw Value is: " + jawRot);
				const euler = new THREE.Euler().setFromQuaternion(jawBoneStartRotation.current);

				const targetRotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(euler.x, euler.y, jawRot));
				jawBone.current.quaternion.copy(targetRotation);
			} else if (characterName === "Myra") {
				const jawRot = V_openToJawOpen(value);
				// console.error("Jaw Value is: " + jawRot);
				const euler = new THREE.Euler().setFromQuaternion(jawBoneStartRotation.current);

				const targetRotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(euler.x, euler.y, jawRot));
				jawBone.current.quaternion.copy(targetRotation);
			} else if (characterName === "David") {
				const jawRot = V_openToJawOpen(value);
				// console.error("Jaw Value is: " + jawRot);
				const euler = new THREE.Euler().setFromQuaternion(jawBoneStartRotation.current);

				const targetRotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(euler.x, euler.y, jawRot));
				jawBone.current.quaternion.copy(targetRotation);
			} else if (characterName === "John") {
				const jawRot = V_openToJawOpen(value);
				// console.error("Jaw Value is: " + jawRot);
				const euler = new THREE.Euler().setFromQuaternion(jawBoneStartRotation.current);

				const targetRotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(euler.x, euler.y, jawRot));
				jawBone.current.quaternion.copy(targetRotation);
			} else {
				const jawRot = V_openToJawOpen(value);
				// console.error("Jaw Value is: " + jawRot);
				const euler = new THREE.Euler().setFromQuaternion(jawBoneStartRotation.current);

				const targetRotation = new THREE.Quaternion().setFromEuler(new THREE.Euler(jawRot, jawRot, jawRot));
				jawBone.current.quaternion.copy(targetRotation);
			}
		}
	}

	// smoothly lerp between the viseme values to animate the mouth
	function evaluateAnimation(time, inputAnimation) {
		if (audioRef.current && audioRef.current.paused === true) {
			// console.log("evaluateAnimation paused")
			set_startAnimation(false);
			return null;
		}
		// Error handling: Check if 'time' is a valid number
		if (typeof time !== "number" || Number.isNaN(time)) {
			console.error("Invalid time value.");
			set_startAnimation(false);
			return null;
		}
		// Error handling: Check if 'animation' or 'animation.keyFrames' is not defined
		if (!inputAnimation) {
			console.error("Missing Animation");
			set_startAnimation(false);
			return null;
		}
		if (!Array.isArray(inputAnimation.keyFrames)) {
			console.error("Missing keyframes");
			set_startAnimation(false);
			return null;
		}
		if (inputAnimation.keyFrames.length === 0) {
			// let fallBackKF = {
			//     fallbackkeys: [
			//         { time: 1, value: 1 },
			//         { time: 0.5, value: 0.5 },
			//     ]
			// }
			// animation.keyFrames = fallBackKF.fallbackkeys;
			// console.error("Keyframe length is: ", animation.keyFrames.length);
			set_startAnimation(false);
			return null;
		}
		// current keyframe and next keyframe to interpolate between
		let keyFrame = inputAnimation.keyFrames[0];
		let nextKeyFrame = inputAnimation.keyFrames[1];

		// loop through the keyframes and find the current keyframe and the next keyframe
		for (let i = 0; i < inputAnimation.keyFrames.length; i++) {
			if (time < inputAnimation.keyFrames[i].time) {
				break;
			}
			keyFrame = inputAnimation.keyFrames[i];

			if (i + 1 < inputAnimation.keyFrames.length) {
				nextKeyFrame = inputAnimation.keyFrames[i + 1];
			} else {
				nextKeyFrame = inputAnimation.keyFrames[i];
			}
		}

		// if the time is past the last keyframe then stop the animation
		if (time > inputAnimation.keyFrames[inputAnimation.keyFrames.length - 1].time) {
			set_startAnimation(false);
			return null;
		}
		// get the morph targets from the keyframe values
		const a = AnimationValueToVisemesMorphs(keyFrame.value);
		const b = AnimationValueToVisemesMorphs(nextKeyFrame.value);
		// get the lerp value between the keyframes
		const t = (time - keyFrame.time) / (nextKeyFrame.time - keyFrame.time);
		// lerp every morph target between the keyframes
		// eslint-disable-next-line
		for (const key in a) {
			const val = lerp(a[key], b[key], t);
			// condition if val is not NaN
			if (val) {
				// if the key is the jaw open key then set the jaw open
				if (key === "V_Open") {
					setJawOpen(val);
				}
				// then set the morph target
				setMorphTargetInfluence(key, val);
			}
		}

		return keyFrame.value;
	}

	function makeEyeLookAt() {
		// console.log(skeleton.current)
		console.log(skeleton.current.rotation);
	}

	// eslint-disable-next-line
	const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

	// Stop the audio when stopAudio prop changes
	useEffect(
		() => () => {
			if (props.currentAudioSource && props.currentAudioSource.current) {
				props.currentAudioSource.current.stop();
			}
			set_startAnimation(false);
			currentAudioSourceStatus.current = true;
		},
		[]
	);

	function ResumeSpeech() {
		set_startAnimation(true);
		// let offset = Date.now() - pausedTime;
		// set_startTime(startTime + offset);
		set_startTime(Date.now());
		// console.log("pausedTime: ", pausedTime);
		// console.log("starttime: ", startTime + offset);
	}

	function StopSpeech() {
		if (props.currentAudioSource?.current) {
			props.currentAudioSource?.current?.stop();
			currentAudioSourceStatus.current = true;
		}
		set_startAnimation(false);

		set_pausedTime(Date.now());
		// StartPassiveListening();
	}

	const isIndexValid = (index, array) => {
		if (index < 0 || index >= array.length) {
			return false;
		}
		return true;
	};

	function userLog(message, data) {
		// You could also add conditions here based on environment (e.g., only log in development)
		console.log(`[User Log] ${message}`, data || "");
	}

	async function StartGreetingV2(callback = () => {}) {
		if (animationControllerRef.current) {
			animationControllerRef.current.StartGreeting(async () => {
				await sleep(500);
				try {
					callback();
				} catch (error) {
					console.error(error);
				}
			});
		}
	}

	/**
	 * This asynchronous function updates the animations for a given text and set of keyframes.
	 * It sets the animation state, logs the update, and starts the animation.
	 * It also sets the start time for the animation.
	 *
	 * @param {string} text - The text for which the animation is to be updated.
	 * @param {Array} keyFrames - The keyframes for the animation.
	 */
	async function UpdateAnimations(text, keyFrames) {
		set_animation({ name: text, keyFrames });
		// console.log("UpdateAnimations",text,keyFrames)
		set_startAnimation(true);
		set_startTime(Date.now());
		if (keyFrames.length === 0) {
			console.error("Keyframes are empty for text: ", text);
		}
	}

	if (loading || model === null) {
		return (
			<Html>
				<div style={{ color: "white" }}>Loading...</div>
			</Html>
		);
	}

	return (
		<Suspense>
			<ambientLight
				color={lightSettings.AmbientLight.color}
				// eslint-disable-next-line
				intensity={lightSettings.AmbientLight.intensity}
			/>
			<hemisphereLight
				color={lightSettings.HemisphereLight.color}
				// eslint-disable-next-line
				intensity={lightSettings.HemisphereLight.intensity}
			/>
			<directionalLight
				color={lightSettings.DirectionalLight.color}
				// eslint-disable-next-line
				intensity={lightSettings.DirectionalLight.intensity}
				// eslint-disable-next-line
				position={lightSettings.DirectionalLight.position}
			/>
			<primitive
				// eslint-disable-next-line
				object={model.scene}
			/>
			<primitive
				// eslint-disable-next-line
				object={cubeMesh}
			/>
			<primitive
				// eslint-disable-next-line
				object={headBoneRef}
			/>
			{/* // Uncomment below for debugging realistic eyes */}
			{/* {headBoneRef.current && <TransformControls object={headBoneRef} mode="rotate" />} */}
			{/* <TransformControls object={cubeMesh} /> */}
			document.body.appendChild( renderer.domElement );
			<RealisticEye character={characterDict} myMixer={mixer} myMesh={myMesh} myScene={model.scene} myTarget={cubeMesh} />
			<Image url={props.background} scale={[7.0, 7.0, 7.0]} rotation={[0, 0, 0]} position={[0, 1, -2.25]} />
			{/* <PositionalAudio url={`${process.env.REACT_APP_AUDIO_BACKEND_URL}/TestPolly?text=this`} ref={sound} /> */}
		</Suspense>
	);
});
