import React from "react";
import styles from "../../styleModules/adminSystemStyles.module.css";

export function Tab({ children, onTabClick, isActive, tabIndex }) {
	return (
		<div onClick={() => onTabClick(tabIndex)} className={`${styles.tab} ${isActive ? styles.activeTab : ""}`}>
			{children}
		</div>
	);
}
