import React, { useEffect, useState, useContext } from "react";
import Select from "react-select";
import { DateRangePicker, Stack, Pagination } from "rsuite";
import frFR from "rsuite/locales/fr_FR";
import subDays from "date-fns/subDays";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import s from "../../styleModules/setupStyles.module.css";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { BaseTable, Column } from "../SecondaryComponents/BaseTable.jsx";
import styles from "../../styleModules/adminSystemStyles.module.css";
import { Tab } from "./Tab.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import "rsuite/DateRangePicker/styles/index.css";
import "rsuite/Pagination/styles/index.css";

const { combine, afterToday } = DateRangePicker;
const predefinedBottomRanges = [
	{
		label: "Today",
		value: [new Date(), new Date()]
	},
	{
		label: "Yesterday",
		value: [addDays(new Date(), -1), addDays(new Date(), -1)]
	},

	{
		label: "Last 7 days",
		value: [subDays(new Date(), 6), new Date()]
	},
	{
		label: "Last 30 days",
		value: [subDays(new Date(), 29), new Date()]
	},

	{
		label: "Last month",
		value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))]
	},
	{
		label: "This year",
		value: [new Date(new Date().getFullYear(), 0, 1), new Date()]
	},
	{
		label: "All time",
		value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date()]
	}
];
const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export function AggregateStatsList() {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { tenantId, userLanguage, translateDictText, performTranslation, translateSingleText } = useContext(AppContext);

	const [data, setData] = useState([]);

	const PaginationLocale = {
		fr: {
			more: "Plus",
			prev: "Précédent",
			next: "Suivant",
			first: "Premier",
			last: "Dernier",
			limit: "{0} / page",
			total: "Total lignes: {0}",
			skip: "Aller à {0}"
		},
		en: {
			more: "More",
			prev: "Previous",
			next: "Next",
			first: "First",
			last: "Last",
			limit: "{0} / page",
			total: "Total Rows: {0}",
			skip: "Go to {0}"
		}
	};

	const LocalLanguageDict = {
		AggregateAnalytics: "Aggregate Analytics",
		MeetingType: "Meeting Type",
		Started: "Started",
		Completed: "Completed",
		Duration: "Duration",
		TopicsToCover: "Topics to Cover",
		TopicsToLearn: "Topics to Learn",
		FilterByType: "Filter By Type",
		FilterByDate: "Filter By Date",
		All: "All"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	// Static Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage, data]);

	const [translatedRanges, setTranslatedRanges] = useState([]);

	useEffect(() => {
		const translateLabels = async () => {
			const translatedRanges = await Promise.all(
				predefinedBottomRanges.map(async (range) => {
					const translatedLabel = await translateSingleText(range.label, userLanguage);
					return { ...range, label: translatedLabel };
				})
			);
			// Assuming you have a state to hold the translated ranges
			setTranslatedRanges(translatedRanges);
		};

		translateLabels();
	}, [userLanguage]); // Re-run the effect when userLanguage changes

	let clientID = "nTop";
	let isInstage = clientID === "inStage";
	let isDashboard = false;

	if (window.location.href.includes("admin/dashboard")) {
		isInstage = true;
		isDashboard = true;
	}
	if (isInstage) {
		clientID = "Quillworks";
	}

	const [lastPeriodData, setLastPeriodData] = useState([]);
	const [companyList, setCompanyList] = useState(["Quillworks"]);
	const [activeTab, setActiveTab] = useState("Quillworks");
	const [activeDateTab, setActiveDateTab] = useState(7);
	const [sortField, setSortField] = useState(null);
	const [sortDirection, setSortDirection] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const [isDownloadingCSV, setIsDownloadingCSV] = useState(false);
	const [emailOptions, setEmailOptions] = useState([]);
	const [setupNameOptions, setSetupNameOptions] = useState([]);
	const [dateFilter, setDateFilter] = React.useState([]);
	const [activePage, setActivePage] = React.useState(1);
	const [totalRecords, setTotalRecords] = React.useState(1);
	const limitPerPage = 10;
	const [emailFilter, setEmailFilter] = useState("");
	const [selectedSetupName, setSelectedSetupName] = useState("all");
	const [selectedSetupType, setSelectedTypeDisplay] = useState([{ value: "all", label: "All" }]);
	const [selectedSearchFilter, setSelectedSearchFilter] = useState([]);
	const [selectedSearchOptions, setSelectedSearchOptions] = useState([]);
	const [selectedSearchEmail, setSelectedSearchEmail] = useState([]);

	useEffect(() => {
		fetchEmails();
	}, [selectedSetupName]);

	async function fetchEmails() {
		const result = await axiosLimitedGet(`${url}/api/dashboard/filter-values`, 1, {
			params: {
				tenantId,
				...(selectedSetupName !== "all" ? { setup_type: selectedSetupName } : {})
			}
		});
		// console.log(result.data);

		setEmailOptions(result.data.emails.map((item) => ({ value: item.email, label: item.email })));

		const uniqueSetupNames = [...new Set(result.data.setup_names.map((item) => (item.setup_name ? item.setup_name.toLowerCase() : "")))];
		setSelectedSearchOptions(
			uniqueSetupNames.map((item) => ({
				value: item,
				label: item.charAt(0).toUpperCase() + item.slice(1)
			}))
		);

		const localSetupNameOptions = result.data.setup_types.map((item) => {
			if (typeof item.setup_type === "string") {
				return {
					value: item.setup_type,
					label: item.setup_type.charAt(0).toUpperCase() + item.setup_type.slice(1)
				};
			} else {
				// Handle the case where setup_type is not a string
				return {
					value: "",
					label: "Unknown" // You can customize this placeholder as needed
				};
			}
		});
		setSetupNameOptions([{ value: "all", label: "All" }, ...localSetupNameOptions]);
	}

	useEffect(() => {
		async function fetchSetupTypes() {
			// Extract labels from setupNameOptions for translation
			const labelsToTranslate = setupNameOptions.map((option) => option.label);

			try {
				const translatedTypes = await translateDictText(labelsToTranslate, userLanguage);
				// Map translated texts back to setupNameOptions structure
				const translatedSetupNameOptions = setupNameOptions.map((option, index) => ({
					value: option.value,
					label: translatedTypes[index] || option.label // Fallback to original label if translation fails
				}));

				// console.log("translatedSetupNameOptions: ", translatedSetupNameOptions);
				setSetupNameOptions([...translatedSetupNameOptions]);
			} catch (error) {
				console.error("Error translating setup name options:", error);
			}
		}

		if (setupNameOptions.length > 0) {
			fetchSetupTypes();
		}
	}, [userLanguage, emailOptions]);

	const selectedSearchFilterLength = selectedSearchFilter.length;

	async function exportToCSV() {
		// setIsDownloadingCSV(true);
		// console.log("Exporting to CSV");
		// const columnOrder = ["Email", "Name", "Simulation", "Date", "Duration"];
		// let csvData = [];
		// const totalPages = Math.ceil(totalRecords / 100);
		// for (let i = 1; i <= totalPages; i++) {
		// 	// eslint-disable-next-line no-await-in-loop
		// 	const result = await axiosLimitedGet(`${url}/api/dashboard/aggregate-data`, 1, {
		// 		params: {
		// 			tenantId,
		// 			start_days,
		// 			end_days,
		// 			page: i,
		// 			...(selectedSetupName !== "all" ? { setup_type: selectedSetupName } : {}),
		// 			setup_name: selectedSearchFilter[0]?.value
		// 		}
		// 	});
		// 	console.log(result.data);
		// 	// eslint-disable-next-line
		// 	await new Promise((resolve) => setTimeout(resolve, 300));
		// 	csvData.push(...result.data.data);
		// }
		// console.log(csvData);
		// csvData = csvData.map((item) => {
		// 	const setupname = item.setup_output?.assignment_name ? item.setup_output?.assignment_name : item.setup_name;
		// 	return {
		// 		Email: item.email,
		// 		Name: item.full_name,
		// 		Simulation: setupname,
		// 		Date: item.start_time,
		// 		Duration: convertTime(item.duration)
		// 	};
		// });
		// console.log(csvData);
		// const csvString = convertJsonToCsv(csvData, columnOrder);
		// const csvBlob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
		// const csvUrl = URL.createObjectURL(csvBlob);
		// const downloadLink = document.createElement("a");
		// // auto download
		// downloadLink.setAttribute("href", csvUrl);
		// downloadLink.setAttribute("download", "filename.csv"); // specify the download file name
		// document.body.appendChild(downloadLink);
		// downloadLink.click();
		// document.body.removeChild(downloadLink); // remove the link when done
		// setIsDownloadingCSV(false);
	}

	const convertTime = (seconds) => {
		// convert seconds to mm:ss format
		const minutes = Math.floor(seconds / 60);
		let secondsLeft = seconds % 60;
		if (secondsLeft < 10) {
			secondsLeft = `0${secondsLeft}`;
		}
		return `${minutes}:${secondsLeft}`;
	};

	const fetchData = async () => {
		const end_days = 0;
		const result = await axiosLimitedGet(`${url}/api/dashboard/aggregate-data`, 1, {
			params: {
				tenantId,
				startDate: dateFilter && dateFilter[0] ? dateFilter[0] : null, // startDate
				endDate: dateFilter && dateFilter[1] ? dateFilter[1] : null,
				page: activePage,
				setup_name: selectedSearchFilter[0]?.value,
				...(selectedSetupName !== "all" ? { setup_type: selectedSetupName } : {})
			}
		});

		// console.log(result.data);

		setData(result.data.rows);
		setTotalRecords(result.data.totalRecords);

		//	setLastPeriodData(lastPeriodResult.data.rows);
		// resetFilters();
	};

	/**
	 * Escapes a CSV field to ensure it is properly formatted.
	 * If the field contains a comma, double quote, or newline, it is enclosed in double quotes.
	 * Any double quotes in the field are escaped with two double quotes.
	 *
	 * @param {string} field - The field to escape.
	 * @returns {string} - The escaped field.
	 */
	function escapeCsvField(field) {
		if (field === null || field === undefined) {
			return "";
		}
		let stringField = String(field);
		if (stringField.includes(",") || stringField.includes('"') || stringField.includes("\n")) {
			stringField = `"${stringField.replace(/"/g, '""')}"`;
		}
		return stringField;
	}

	/**
	 * Converts an array of JSON objects to a CSV string.
	 *
	 * @param {Object[]} jsonData - The JSON data to convert.
	 * @param {string[]} columnOrder - The order of the columns in the CSV file.
	 * @returns {string} - The CSV string.
	 */
	function convertJsonToCsv(jsonData, columnOrder) {
		if (!Array.isArray(jsonData) || !Array.isArray(columnOrder)) {
			throw new Error("Invalid input: jsonData and columnOrder must be arrays");
		}

		const csvRows = jsonData.map((obj) => columnOrder.map((key) => escapeCsvField(obj[key])).join(","));

		return [columnOrder.join(",")].concat(csvRows).join("\n");
	}

	const sortData = (field) => {
		setSortDirection(sortDirection === "asc" || sortDirection === null ? "desc" : "asc");
		setSortField(field);
	};

	// useEffect(() => {
	// 	fetchCompanyList();
	// }, []);
	useEffect(() => {
		fetchData(activeTab, activeDateTab, tenantId);
	}, [emailFilter, selectedSearchFilterLength, selectedSearchFilter, selectedSetupName, dateFilter, activePage, tenantId]);

	useEffect(() => {
		if (sortField !== null) {
			const sortedData = [...data].sort((a, b) => {
				if (sortDirection === "asc") {
					return a[sortField] > b[sortField] ? 1 : -1;
				}
				return a[sortField] < b[sortField] ? 1 : -1;
			});
			const sortedData2 = [...lastPeriodData].sort((a, b) => {
				if (sortDirection === "asc") {
					return a[sortField] > b[sortField] ? 1 : -1;
				}
				return a[sortField] < b[sortField] ? 1 : -1;
			});
			setLastPeriodData(sortedData2);
			setData(sortedData);
		}
	}, [sortField, sortDirection]);

	const headers = [pageText.MeetingType, pageText.Started, pageText.Completed, pageText.Duration];

	const columnWidths = {
		"Meeting Type": "250px",
		Started: "100px",
		Completed: "100px",
		Duration: "100px",
		"Topics to Cover": "125px",
		"Topics to Learn": "125px",
		Responses: "125px"
	};

	const customStyles = {
		control: (provided, state) => ({
			...provided,
			cursor: "pointer",
			outline: state.isFocused ? "1px solid #00a9af" : "none",
			width: "16rem",
			backgroundColor: "#f1f1f1",
			color: "#303d7c"
		}),
		option: (provided, state) => ({
			...provided,
			cursor: "pointer",
			backgroundColor: state.isFocused ? (state.isSelected ? "#00a9af" : "#00a9af7e") : state.isSelected ? "#00a9af" : null,
			color: state.isSelected ? "#fff" : "#414042",
			whiteSpace: "nowrap",
			overflow: "hidden",
			textOverflow: "ellipsis"
		}),
		menu: (provided, state) => ({
			...provided,
			width: "16rem"
		})
	};

	return (
		<div className={styles.adminPageContainer}>
			{!isDashboard && (
				<h1 className={styles.dashboardHeading} style={{ position: "sticky", top: "0", left: "0" }}>
					{pageText.AggregateAnalytics}
				</h1>
			)}

			<div className={styles.filterContainer} style={{ position: "sticky", left: "0" }}>
				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterByType}: </p>
					<div className={styles.reactDropdownSelect}>
						<Select
							placeholder="Search type"
							styles={customStyles}
							isSearchable={false}
							options={setupNameOptions}
							value={selectedSetupType}
							onChange={(option) => {
								setSelectedTypeDisplay(option);
								setSelectedSetupName(option ? option.value : `${pageText.All}`);
								setSelectedSearchFilter([]);
								setActivePage(1);
							}}
						/>
					</div>
				</div>
				{/* <div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>Filter By Simulation:</p>
					<div className={styles.reactDropdownSelect}>
						<Select
							placeholder="Search simulation"
							styles={customStyles}
							isSearchable
							isClearable
							options={selectedSearchOptions}
							value={selectedSearchFilter}
							onChange={(option) => {
								setSelectedSearchFilter(option ? [option] : []);
								setActivePage(1);
							}}
						/>
					</div>
				</div> */}

				<div className={styles.filterHeadingContainer}>
					<p className={styles.filterHeading}>{pageText.FilterByDate}: </p>
					<div className={styles.reactDropdownSelect}>
						<DateRangePicker
							format="yyyy-MM-dd"
							onChange={(value) => {
								setDateFilter(value);
								setActivePage(1);
							}}
							ranges={translatedRanges}
							showOneCalendar
							shouldDisableDate={afterToday()}
							showHeader={false}
						/>
					</div>
				</div>
			</div>

			<BaseTable
				className={styles.tableContainer}
				textAlign="left"
				data={data}
				rowKey="key"
				fixed
				maxHeight={Infinity}
				headerHeight={0}
				headers={headers}
				classNameHeader={styles.tableHeaderRow}
				columnWidths={columnWidths}
			>
				<Column
					title="setup_name"
					key="setup_name"
					dataKey="setup_name"
					textAlign="left"
					cellRenderer={(props) => (
						// return <div className={styles.tableData}>{props.cellData}</div>;
						<div className={styles.tableData} style={{ width: columnWidths["Meeting Type"] }}>
							{props.cellData}
						</div>
					)}
				/>
				<Column
					title="started_simulations"
					key="started_simulations"
					dataKey="started_simulations"
					textAlign="center"
					cellRenderer={(props) => (
						<div className={styles.tableData} style={{ width: columnWidths.Started }}>
							{props.cellData}
						</div>
					)}
				/>
				<Column
					title="completed_simulations"
					key="completed_simulations"
					dataKey="completed_simulations"
					textAlign="center"
					cellRenderer={(props) => (
						<div className={styles.tableData} style={{ width: columnWidths.Completed }}>
							{props.cellData}
						</div>
					)}
				/>
				<Column
					title="simulation_time"
					key="simulation_time"
					dataKey="simulation_time"
					textAlign="center"
					cellRenderer={(props) => {
						let value = props.cellData;
						const minutes = Math.floor(value / 60);
						let seconds = value % 60;
						if (seconds < 10) {
							seconds = `0${seconds}`;
						}
						value = `${minutes}:${seconds}`;
						return (
							<div className={styles.tableData} style={{ width: columnWidths.Duration }}>
								{value}
							</div>
						);
					}}
				/>
				<Column
					title="Topics to Cover"
					key="talking_point"
					dataKey="talking_point"
					textAlign="center"
					cellRenderer={(props) => (
						<div className={styles.tableData} style={{ width: columnWidths["Topics to Cover"] }}>
							{props.cellData}
						</div>
					)}
				/>
				<Column
					title="Topics to learn"
					key="learning_points"
					dataKey="learning_points"
					textAlign="center"
					cellRenderer={(props) => (
						<div className={styles.tableData} style={{ width: columnWidths["Topics to Learn"] }}>
							{props.cellData}
						</div>
					)}
				/>
				<Column
					title="Responses"
					key="key_point"
					dataKey="key_point"
					textAlign="center"
					cellRenderer={(props) => (
						<div className={styles.tableData} style={{ width: columnWidths.Responses }}>
							{props.cellData}
						</div>
					)}
				/>
			</BaseTable>

			<Pagination
				ellipsis
				boundaryLinks
				prev
				last
				next
				first
				limit={limitPerPage}
				total={totalRecords}
				activePage={activePage}
				onChangePage={setActivePage}
				maxButtons={5}
				layout={["pager", "skip", "|", "total"]}
				locale={PaginationLocale[userLanguage]}
			/>

			{/* {isDownloadingCSV && <LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />}
			{!isDownloadingCSV && (
				<button style={{ width: "unset" }} className={s.dashboardTableBtnStart} type="button" onClick={exportToCSV}>
					{" "}
					Export to CSV
				</button>
			)} */}
		</div>
	);
}
