import React, { useRef, useEffect } from "react";

function TrustBox() {
	// Create a reference to the <div> element which will represent the TrustBox
	const ref = useRef(null);

	useEffect(() => {
		// If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
		// If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
		// When it is, it will automatically load the TrustBox.

		if (window.Trustpilot) {
			window.Trustpilot.loadFromElement(ref.current, true);
		}
	}, []);

	return (
		<div
			ref={ref} // We need a reference to this element to load the TrustBox in the effect.
			className="trustpilot-widget" // Renamed this to className.
			data-locale="en-US"
			data-template-id="56278e9abfbbba0bdcd568bc"
			data-businessunit-id="62559d1daaf3ad9a5492115d"
			data-style-height="52px"
			data-style-width="100%"
			data-theme="light"
			data-min-review-count="10"
			style={{ margin: "25px 0 0" }}
		>
			<a href="https://www.trustpilot.com/review/instagepractice.com" target="_blank" rel="noreferrer">
				Trustpilot
			</a>
		</div>
	);
}

export default TrustBox;
