import * as THREE from "three";

const bookingURL = process.env.REACT_APP_BOOKING_BACKEND_URL;

// HDR loader
// const hdrTextureURL = new URL(bookingURL + "/getFile?file=emptyroom_hdr.hdr");

// const loader = new RGBELoader();
// loader.load(hdrTextureURL, function(texture) {
//     scene.background = texture;
// });
// HDR Loader End

// dictionarty to store character names, voice, and asset url
export const characterDict = {
	"Sales Rep": {
		name: "Sales Rep",
		voice: "Ayanda"
	},
	Nina: {
		name: "Nina",
		voice: "Ayanda",
		url: `${bookingURL}/getmodel?file=NewNinaTextured_July23_1_meshOpt`, // was NewNinaTextured_Apr19_19_meshOpt
		background: `${bookingURL}/getFile?file=zoomBackground_Blur.jpg`,
		backgroundBySetup: {
			default: `${bookingURL}/getFile?file=zoomBackground_Blur.jpg`,
			"cougar-drilling-systems": `${bookingURL}/getFile?file=johnBG_june14th.png`
		},
		urlAnimations: `${bookingURL}/getmodel?file=NewNinaAnimations_FixedAnims_2_meshOpt`, // was Nina_New_Animations_Rework_Looping_meshOpt

		animations: {
			ActiveListeningAnimName: "Listen_Confident",
			TalkingAnimName: ["Talk_Confident", "Talk_Nonchalant", "Talk_Defensive", "Talk_Happy_F"],
			TalkingTransitionAnimName: ["Look around_Relax"],
			PassiveListeningName: "Look around_Relax",
			ThinkingAnimNames: ["Planning", "Considering_F", "Listen and think"],
			GreetingAnimName: "Confident smile",
			LisnetingtToOtherAnimName: "Listen to conversation",
			ReactionAnimsName: ["Fall asleep", "Phubbing", "Annoyed_M", "Upset_M", "Confused_M", "Miffed_F", "Pleased_F", "Optimistic_F"],
			AdditiveAnimsNames: [
				"NodAdditive",
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			],
			NodAnimsName: [
				"NodAdditive",
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			]
		},
		dataAmount: 59,
		cameraPosition: [0.01, 1.29, 0.44],
		cameraRotation: [-0.075, 0, 0],
		characterPosition: [0, 0, 0],
		characterRotation: [0, 0, 0],
		exposureLvl: 0.875,
		hdrmap: "hayloft_1k.hdr", // was this: "kloofendal_28d_misty_1k.hdr"
		leftEyeBlinkIndex: 16,
		rightEyeBlinkIndex: 17,
		leftEyeLookUpIndex: 20,
		rightEyeLookUpIndex: 21,
		leftEyeLookLeftIndex: 22,
		leftEyeLookRightIndex: 24,
		rightEyeLookLeftIndex: 23,
		rightEyeLookRightIndex: 25,
		rightEyeLookDownIndex: 28,
		leftEyeLookDownIndex: 29,
		lookAtUsersRightEyeX: new THREE.Vector3(0.04649332228553691, 1.3062401143054696, 0.20003356205560474),
		lookAtUsersLeftEyeX: new THREE.Vector3(-0.0647633477828883, 1.3467697276421051, 0.2000167984101652),
		lookAtUsersMouthX: new THREE.Vector3(0.003887159959280814, 1.307949107947884, 0.20010129263298798),
		lookForwardX: new THREE.Vector3(0.000943848152473354, 1.316022403630272, 0.2),
		lookUpX: new THREE.Vector3(0.0005336979099983818, 1.3395430779419015, 0.2000167984101652),

		lookAtUsersRightEyeXMicro: new THREE.Vector3(0.018445369353022913, 1.3051177518205948, 0.2),
		lookAtUsersLeftEyeXMicro: new THREE.Vector3(-0.005146655315978899, 1.3037258696502634, 0.2),
		lookForwardXMicro: new THREE.Vector3(0.0015799337170773, 1.301206685594847, 0.2),
		lookUpXMicro: new THREE.Vector3(0.0, 1.314716999997694, 0.2),

		eyeMaxRots: [-2.161694397560396, -1.1358222689488604, -1.9564082202234296, -1.4593004893063235], // minX, maxX, minY, maxY

		trackUpMinY: 1.295,
		trackUpMaxY: 1.36,
		trackRightMinX: 0.0,
		trackRightMaxX: 0.1,
		trackLeftMinX: 0.0,
		trackLeftMaxX: -0.1,

		minHeadRotation: -0.1466419777928663,
		maxHeadRotation: -0.5643929751289339,

		lightSettings: {
			HemisphereLight: {
				name: "HemisphereLight",
				color: "#b9a046",
				intensity: 0.21, // was 1.3
				position: [0.75, -0.4, 0.866]
			},

			AmbientLight: {
				name: "AmbientLight",
				color: "#ffffff",
				intensity: 0.37, // was 1.3
				position: [0.75, -0.4, 0.866]
			},
			DirectionalLight: {
				name: "DirectionalLight",
				color: "#eadcae",
				intensity: 0.71, //* Math.PI, //was 0.19
				position: [1, -0.4, 0.866]
			}
		}
	},
	Azilea: {
		name: "Nina",
		voice: "Ayanda",
		url: `${bookingURL}/getmodel?file=Nina_Textured_New_13_meshOpt`,
		background: `${bookingURL}/getFile?file=zoomBackground_Blur.jpg`,
		backgroundBySetup: {
			default: `${bookingURL}/getFile?file=zoomBackground_Blur.jpg`,
			"cougar-drilling-systems": `${bookingURL}/getFile?file=johnBG_june14th.png`
		},
		urlAnimations: `${bookingURL}/getmodel?file=Nina_New_Animations_Reactions_2_meshOpt`,

		animations: {
			ActiveListeningAnimName: "Listen_Confident",
			TalkingAnimName: ["Talk_Confident", "Talk_Nonchalant", "Talk_Defensive", "Talk_Happy_F"],
			PassiveListeningName: "Look around_Relax",
			ThinkingAnimNames: ["Planning", "Considering_F", "Listen and think"],
			GreetingAnimName: "Confident smile",
			LisnetingtToOtherAnimName: "Listen to conversation",
			ReactionAnimsName: ["Fall asleep", "Phubbing", "Annoyed_M", "Upset_M", "Confused_M", "Miffed_F", "Pleased_F", "Optimistic_F"],
			AdditiveAnimsNames: [
				"NodAdditive",
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			],
			NodAnimsName: [
				"NodAdditive",
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			]
		},
		dataAmount: 59,
		cameraPosition: [0.01, 1.38, 0.33],
		cameraRotation: [-0.075, 0, 0],
		characterPosition: [0, 0, 0],
		characterRotation: [0, 0, 0],
		exposureLvl: 0.6,
		hdrmap: "kloofendal_28d_misty_1k.hdr",
		lightSettings: {
			HemisphereLight: {
				name: "HemisphereLight",
				color: "#82774F",
				intensity: 0.4, // was 1.3
				position: [0.75, 0, 0.866]
			},

			AmbientLight: {
				name: "AmbientLight",
				color: "#B2AB85",
				intensity: 0.4, // was 1.3
				position: [0.75, 0, 0.866]
			},
			DirectionalLight: {
				name: "DirectionalLight",
				color: "#C7C0A9",
				intensity: 0.2, //* Math.PI, //was 0.19
				position: [1, 0.4, 0.866]
			}
		}
	},
	Colleen: {
		name: "Colleen",
		voice: "Ayanda",
		url: `${bookingURL}/getmodel?file=Colleen_v1.5_added earring`,
		background: `${bookingURL}/getFile?file=Colleen_BG_V1.0.png`,
		backgroundBySetup: {
			default: `${bookingURL}/getFile?file=Penstore Background2.png`,
			"cougar-drilling-systems": `${bookingURL}/getFile?file=johnBG_june14th.png`
		},
		urlAnimations: `${bookingURL}/getmodel?file=NinaAnimationsApr11`,

		animations: {
			ActiveListeningAnimName: "Listen_Confident",
			TalkingAnimName: "Talk_Confident",
			PassiveListeningName: "Look around_Relax",
			ThinkingAnimNames: ["Planning", "Considering_F", "Listen and think"],
			GreetingAnimName: "Confident smile",
			LisnetingtToOtherAnimName: "Listen to conversation",
			ReactionAnimsName: ["Annoyed_M", "Upset_M", "Confused_M", "Miffed_F", "Pleased_F", "Optimistic_F"],
			NodAnimsName: [
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			]
		},
		dataAmount: 66,
		cameraPosition: [0, 1.2, 0.3],
		cameraRotation: [0, 0, 0],
		characterPosition: [0, 0, 0],
		characterRotation: [0, 0, 0],
		exposureLvl: 0.9,
		hdrmap: "emptyroom_hdr.hdr",
		lightSettings: {
			HemisphereLight: {
				name: "HemisphereLight",
				color: "#82774F",
				intensity: 1
			},
			AmbientLight: {
				name: "AmbientLight",
				color: "#B2AB85",
				intensity: 1
			},
			DirectionalLight: {
				name: "DirectionalLight",
				color: "#C7C0A9",
				intensity: 0.16, //* Math.PI,
				position: [0.5, 0, 0.866]
			}
		}
	},
	Myra: {
		name: "Myra",
		voice: "Ayanda",
		url: `${bookingURL}/getmodel?file=NewMyra_Textured_July23_4_meshOpt`, // was this: NewMyraTextured_V1_5_meshOpt
		background: `${bookingURL}/getFile?file=classroomBG_blurred.jpg`,
		backgroundBySetup: {
			default: `${bookingURL}/getFile?file=Penstore Background2.png`,
			"cougar-drilling-systems": `${bookingURL}/getFile?file=johnBG_june14th.png`
		},
		urlAnimations: `${bookingURL}/getmodel?file=NewMyraAnimated_V1_7_meshOpt`, // was this: urlAnimations: bookingURL + "/getmodel?file=AzileaAnimationsApr11",

		animations: {
			ActiveListeningAnimName: "Listen and think_FULL",
			TalkingAnimName: ["Talk_Confident", "Talk_Nonchalant", "Talk_Defensive", "Talk_Happy_F"],
			PassiveListeningName: "Look front",
			ThinkingAnimNames: ["Planning"],
			GreetingAnimName: "Greet and nod",
			LisnetingtToOtherAnimName: "Listen to conversation",
			ReactionAnimsName: ["Fall asleep", "Phubbing", "Annoyed_M", "Upset_M", "Miffed_F", "Pleased_F", "Optimistic_F"],
			AdditiveAnimsNames: [
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			],
			NodAnimsName: [
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			]
		},
		dataAmount: 66,
		cameraPosition: [0.0, 1.29, 0.5],
		cameraRotation: [0, 0, 0],
		characterPosition: [0, 0, 0],
		characterRotation: [0, 0, 0],
		exposureLvl: 0.4,
		hdrmap: "hayloft_1k.hdr",
		leftEyeBlinkIndex: 16, // was 14
		rightEyeBlinkIndex: 17, // was 15
		leftEyeLookUpIndex: 26,
		rightEyeLookUpIndex: 27,
		leftEyeLookLeftIndex: 22,
		leftEyeLookRightIndex: 24,
		rightEyeLookLeftIndex: 23,
		rightEyeLookRightIndex: 25,
		rightEyeLookDownIndex: 29,
		leftEyeLookDownIndex: 28,
		lookAtUsersRightEyeX: new THREE.Vector3(0.043879606872168006, 1.3170536463389648, 0.25),
		lookAtUsersLeftEyeX: new THREE.Vector3(-0.06629844531777353, 1.3350653626969726, 0.25),
		lookAtUsersMouthX: new THREE.Vector3(0.004598736091406235, 1.3003832280076173, 0.25),
		lookForwardX: new THREE.Vector3(-0.006898104137109419, 1.3216523824303712, 0.25),
		lookUpX: new THREE.Vector3(0.0, 1.3604554674744884, 0.25),

		lookAtUsersRightEyeXMicro: new THREE.Vector3(0.013029752258984395, 1.3178201023541993, 0.25),
		lookAtUsersLeftEyeXMicro: new THREE.Vector3(-0.009005858179003933, 1.313047788159961, 0.25),
		lookForwardXMicro: new THREE.Vector3(0.004981964099023441, 1.3189697863770509, 0.25),
		lookUpXMicro: new THREE.Vector3(0.010538770209472675, 1.3272091885408204, 0.25),

		eyeMaxRots: [-1.9153105959869607, -1.2648264180619224, -1.7844390162703259, -1.5089238207952045], // minX, maxX, minY, maxY

		trackUpMinY: 1.30431938895509,
		trackUpMaxY: 1.3630307276200133,
		trackRightMinX: 0.0,
		trackRightMaxX: 0.1,
		trackLeftMinX: 0.0,
		trackLeftMaxX: -0.1,

		minHeadRotation: -0.1406989872623406,
		maxHeadRotation: -0.15542915670313884,

		lightSettings: {
			HemisphereLight: {
				name: "HemisphereLight",
				color: "#ede8d4",
				intensity: 0.03,
				position: [0.75, 0.4, 0.866]
			},
			AmbientLight: {
				name: "AmbientLight",
				color: "#ecebe4",
				intensity: 0.1,
				position: [0.75, 0.4, 0.866]
			},
			DirectionalLight: {
				name: "DirectionalLight",
				color: "#b1dddc",
				intensity: 0.5, //* Math.PI,
				position: [0.75, 0.4, 1.5]
			}
		}
	},
	John: {
		name: "John",
		voice: "Arthur",
		url: `${bookingURL}/getmodel?file=NewJohn_Textured_July22_11_meshOpt`, // old hair was: NewJohn_Textured_July_22_2_meshOpt or new hair was: NewJohn_Textured_July_22_11_meshOpt
		background: `${bookingURL}/getFile?file=JohnPortraitBG.jpg`, // original was this: "/getFile?file=Penstore Background1.png",
		// hdrBackground: Background(bookingURL + "/getFile?file=emptyroom_hdr.hdr"),

		backgroundBySetup: {
			default: `${bookingURL}/getFile?file=John_Background2_Blur.jpg`, // original was this: "/getFile?file=Penstore Background1.png",
			"cougar-drilling-systems": `${bookingURL}/getFile?file=johnBG_june14th.png`
		},

		urlAnimations: `${bookingURL}/getmodel?file=NewJohn_Animations_Apr16_2_meshOpt`, // was JohnAnimations_Looping_1_meshOpt

		animations: {
			ActiveListeningAnimName: "Listen and think_FULL",
			TalkingAnimName: ["Talk_Confident", "Talk_Nonchalant", "Talk_Defensive", "Talk_Happy_F"],
			PassiveListeningName: "Look front",
			ThinkingAnimNames: ["Planning", "Considering_F"],
			GreetingAnimName: "NewGreeting",
			LisnetingtToOtherAnimName: "Listen to conversation",
			ReactionAnimsName: ["Fall asleep", "Phubbing", "Annoyed_M", "Upset_M", "Confused_M", "Miffed_F", "Pleased_F", "Optimistic_F"],
			AdditiveAnimsNames: [
				"NodAdditive",
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			],
			NodAnimsName: [
				"NodAdditive",
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			]
		},
		dataAmount: 48,
		cameraPosition: [0.01, 1.43, 0.5],
		cameraRotation: [-0.05, 0, 0],
		characterPosition: [0, 0, 0],
		characterRotation: [0, 0, 0],
		exposureLvl: 0.9,
		hdrmap: "hayloft_1k.hdr",
		leftEyeBlinkIndex: 16,
		rightEyeBlinkIndex: 17,
		leftEyeLookUpIndex: 26,
		rightEyeLookUpIndex: 27,
		leftEyeLookLeftIndex: 22,
		leftEyeLookRightIndex: 24,
		rightEyeLookLeftIndex: 23,
		rightEyeLookRightIndex: 25,
		rightEyeLookDownIndex: 28,
		leftEyeLookDownIndex: 29,
		lookAtUsersRightEyeX: new THREE.Vector3(0.04642695706965706, 1.4569070851968358, 0.2),
		lookAtUsersLeftEyeX: new THREE.Vector3(-0.05825406665216813, 1.4514149765500295, 0.2),
		lookAtUsersMouthX: new THREE.Vector3(-0.008351015011762705, 1.4304825237993954, 0.2),
		lookForwardX: new THREE.Vector3(-0.005085975483658019, 1.4499124639720715, 0.2),
		lookUpX: new THREE.Vector3(-0.008351015011762705, 1.471371872501563, 0.2),

		lookAtUsersRightEyeXMicro: new THREE.Vector3(0.0038445369353022913, 1.447177518205948, 0.2),
		lookAtUsersLeftEyeXMicro: new THREE.Vector3(-0.005146655315978899, 1.445258696502634, 0.2),
		lookForwardXMicro: new THREE.Vector3(0.0010799337170773, 1.445206685594847, 0.2),
		lookUpXMicro: new THREE.Vector3(0.0, 1.449716999997694, 0.2),

		eyeMaxRots: [-2.2329293536893413, -1.0317965570891918, -1.813096756828812, -1.5056284010860839],

		trackUpMinY: 1.445,
		trackUpMaxY: 1.49,
		trackRightMinX: 0.0,
		trackRightMaxX: 0.1,
		trackLeftMinX: 0.0,
		trackLeftMaxX: -0.1,

		minHeadRotation: -0.1406989872623406,
		maxHeadRotation: -0.15542915670313884,

		lightSettings: {
			HemisphereLight: {
				name: "HemisphereLight",
				color: "#bde5ea",
				intensity: 0.12,
				position: [0.75, 0.4, 0.866]
			},

			AmbientLight: {
				name: "AmbientLight",
				color: "#e8c892",
				intensity: 0.23,
				position: [0.75, 0.4, 0.866]
			},
			DirectionalLight: {
				name: "DirectionalLight",
				color: "#e0b2da",
				intensity: 0.24, //* Math.PI,
				position: [0.75, 1, 3]
			}
		}
	},
	David: {
		name: "David",
		voice: "Arthur",
		url: `${bookingURL}/getmodel?file=NewDavid_Textured_NoEyeBoneTargets_2_meshOpt`, // was 2_NewDavid_Textured_NewerLashes_1_meshOpt
		background: `${bookingURL}/getFile?file=BookshelfLivingroom2_Blur.jpg`, // original was this: "/getFile?file=Penstore Background1.png",
		// hdrBackground: Background(bookingURL + "/getFile?file=emptyroom_hdr.hdr"),

		backgroundBySetup: {
			default: `${bookingURL}/getFile?file=John_Background2_Blur.jpg`, // original was this: "/getFile?file=Penstore Background1.png",
			"cougar-drilling-systems": `${bookingURL}/getFile?file=johnBG_june14th.png`
		},

		urlAnimations: `${bookingURL}/getmodel?file=NewDavid_Animated_NoEyeBoneController_1_meshOpt`, // was NewDavid_Animated_3_Looping_meshOpt
		animations: {
			ActiveListeningAnimName: "Listen and think_FULL",
			TalkingAnimName: ["Talk_Confident", "Talk_Nonchalant", "Talk_Defensive", "Talk_Happy_F"],
			PassiveListeningName: "Look front",
			ThinkingAnimNames: ["Planning"],
			GreetingAnimName: "NewGreeting",
			LisnetingtToOtherAnimName: "Listen to conversation",
			ReactionAnimsName: ["Fall asleep", "Phubbing", "Annoyed_M", "Upset_M", "Confused_M", "Miffed_F", "Pleased_F", "Optimistic_F"],
			AdditiveAnimsNames: [
				"NodAdditive",
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			],
			NodAnimsName: [
				"NodAdditive",
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			]
		},
		performanceModeAvatarPic: `${bookingURL}/getFile?file=David_Performance_Mode_Avatar.png`,
		dataAmount: 48,
		cameraPosition: [0.01, 1.46, 0.465],
		cameraRotation: [-0.1, 0, 0],
		characterPosition: [0, 0, 0],
		characterRotation: [0, 0, 0],
		exposureLvl: 0.7,
		hdrmap: "hayloft_1k.hdr",
		leftEyeBlinkIndex: 16, // was 14
		rightEyeBlinkIndex: 17, // was 15
		leftEyeLookUpIndex: 26,
		rightEyeLookUpIndex: 27,
		leftEyeLookLeftIndex: 22,
		leftEyeLookRightIndex: 24,
		rightEyeLookLeftIndex: 23,
		rightEyeLookRightIndex: 25,
		rightEyeLookDownIndex: 29,
		leftEyeLookDownIndex: 28,
		lookAtUsersRightEyeX: new THREE.Vector3(0.06602994316332489, 1.4899018304460845, 0.2),
		lookAtUsersLeftEyeX: new THREE.Vector3(-0.0846140336506889, 1.4886763796607274, 0.2),
		lookAtUsersMouthX: new THREE.Vector3(-0.01458467794759791, 1.4730304599305828, 0.2),
		lookForwardX: new THREE.Vector3(-0.01458467794759791, 1.484599340884105, 0.2),
		lookUpX: new THREE.Vector3(-0.01458467794759791, 1.496327426058596, 0.2),

		lookAtUsersRightEyeXMicro: new THREE.Vector3(0.0038445369353022913, 1.487177518205948, 0.2),
		lookAtUsersLeftEyeXMicro: new THREE.Vector3(-0.005146655315978899, 1.486558696502634, 0.2),
		lookForwardXMicro: new THREE.Vector3(0.0010799337170773, 1.4871599340884105, 0.2),
		lookUpXMicro: new THREE.Vector3(0.0, 1.491716999997694, 0.2),

		eyeMaxRots: [-2.1499136027292596, -1.1498837293445334, -1.9189083162685665, -1.4395332543282147], // minX, maxX, minY, maxY

		trackUpMinY: 1.47,
		trackUpMaxY: 1.5,
		trackRightMinX: 0.0,
		trackRightMaxX: 0.1,
		trackLeftMinX: 0.0,
		trackLeftMaxX: -0.1,

		minHeadRotation: -0.027754955676898292,
		maxHeadRotation: -0.0636582293060458,

		lightSettings: {
			HemisphereLight: {
				name: "HemisphereLight",
				color: "#e3bc6d",
				intensity: 0.03,
				position: [0.75, -0.4, 0.866]
			},
			AmbientLight: {
				name: "AmbientLight",
				color: "#ddd2ac",
				intensity: 0.3,
				position: [0.75, -0.4, 0.866]
			},
			DirectionalLight: {
				name: "DirectionalLight",
				color: "#cdb27a",
				intensity: 0.1, //* Math.PI,
				position: [0.75, -1, 3]
			}
		}
	},
	Ian: {
		name: "Ian",
		voice: "Arthur",
		url: `${bookingURL}/getmodel?file=IanTextured_NoHair_meshOpt`,
		background: `${bookingURL}/getFile?file=Bookshelf_noBlur.jpg`,
		backgroundBySetup: {
			default: `${bookingURL}/getFile?file=Ian_BG_Blur.jpg`,
			"cougar-drilling-systems": `${bookingURL}/getFile?file=johnBG_june14th.png`
		},
		urlAnimations: `${bookingURL}/getmodel?file=JohnAnimations_NewRoll2_meshOptComp`,

		animations: {
			ActiveListeningAnimName: "Listen and think_FULL",
			TalkingAnimName: "Talk_Confident",
			PassiveListeningName: "Look front",
			ThinkingAnimNames: ["Planning", "Considering_F"],
			GreetingAnimName: "NormalBase_NormNod_July2021_1",
			LisnetingtToOtherAnimName: "Listen to conversation",
			ReactionAnimsName: ["Fall asleep", "Phubbing", "Annoyed_M", "Upset_M", "Confused_M", "Miffed_F", "Pleased_F", "Optimistic_F"],
			NodAnimsName: [
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			]
		},
		dataAmount: 66,
		cameraPosition: [0, 1.5, 0.35],
		cameraRotation: [0, 0, 0],
		characterPosition: [0, 0, 0],
		characterRotation: [0, 0, 0],
		exposureLvl: 0.9,
		hdrmap: "emptyroom_hdr.hdr",
		lightSettings: {
			HemisphereLight: {
				name: "HemisphereLight",
				color: "#82774F",
				intensity: 1
			},
			AmbientLight: {
				name: "AmbientLight",
				color: "#B2AB85",
				intensity: 1
			},
			DirectionalLight: {
				name: "DirectionalLight",
				color: "#C7C0A9",
				intensity: 0.16, //* Math.PI,
				position: [0.5, 0, 0.866]
			}
		}
	},

	Edward: {
		name: "Edward",
		voice: "Brian",
		url: `${bookingURL}/getmodel?file=Edward_v1.5`,
		background: `${bookingURL}/getFile?file=Colleen_BG_V1.0.png`,
		backgroundBySetup: {
			default: `${bookingURL}/getFile?file=Penstore Background2.png`,
			"cougar-drilling-systems": `${bookingURL}/getFile?file=johnBG_june14th.png`
		},
		urlAnimations: `${bookingURL}/getmodel?file=NinaAnimationsApr11`,

		animations: {
			ActiveListeningAnimName: "Listen_Confident",
			TalkingAnimName: "Talk_Confident",
			PassiveListeningName: "Look around_Relax",
			ThinkingAnimNames: ["Planning", "Considering_F", "Listen and think"],
			GreetingAnimName: "Confident smile",
			LisnetingtToOtherAnimName: "Listen to conversation",
			ReactionAnimsName: ["Annoyed_M", "Upset_M", "Confused_M", "Miffed_F", "Pleased_F", "Optimistic_F"],
			NodAnimsName: [
				"NormalBase_NormNod_July2021_1",
				"NormalBase_NormNod_July2021_2",
				"NormalBase_PosNod_July2021_1",
				"NormalBase_PosNod_July2021_2"
			]
		},
		dataAmount: 66,
		cameraPosition: [0, 1.3, 0.5],
		cameraRotation: [0, 0, 0],
		characterPosition: [0, 0, 0],
		characterRotation: [0, 0, 0],
		exposureLvl: 0.9,
		hdrmap: "emptyroom_hdr.hdr",
		lightSettings: {
			HemisphereLight: {
				name: "HemisphereLight",
				color: "#82774F",
				intensity: 1
			},
			AmbientLight: {
				name: "AmbientLight",
				color: "#B2AB85",
				intensity: 1
			},
			DirectionalLight: {
				name: "DirectionalLight",
				color: "#C7C0A9",
				intensity: 0.16, //* Math.PI,
				position: [0.5, 0, 0.866]
			}
		}
	}
};
