import React, { useState, useEffect, useMemo, useContext, createContext, useRef } from "react";
import { FaCheck, FaTimes } from "react-icons/fa/index.esm.js";
import { FiChevronRight, FiInfo } from "react-icons/fi/index.esm.js";
import r from "../../styleModules/reportStyles.module.css";
import checkmark from "../../images/checkmark.png";
import { CommentIcon } from "../SecondaryComponents/CommentModal.js";
import InfoToolTip from "./InfoToolTip.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";

// const TooltipContext = createContext();
// export const useTooltip = () => useContext(TooltipContext);

export default function ProcessTable({ pointsArray, title, altStatuses, scores }) {
	const [activeTooltip, setActiveTooltip] = useState(null);
	const tooltipValue = useMemo(() => ({ activeTooltip, setActiveTooltip }), [activeTooltip, setActiveTooltip]);
	let statusOptions = ["No", "Partial", "Yes"];
	const altStatusOptions = ["Novice", "Intermediate", "Competent"];
	if (altStatuses) {
		statusOptions = altStatusOptions;
	}

	const { runTranslation, adminAccess, tenantId, userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Satisfactory: "Satisfactory",
		Unsatisfactory: "Unsatisfactory",
		Exceptional: "Exceptional",
		Feedback: "Feedback",
		Dialogue: "Dialogue"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage]);

	const statusDict = {
		No: {
			text: pageText.Unsatisfactory,
			darkColor: "#7d7d7d",
			lightColor: "#AFAFAF",
			score: 1
		},
		Novice: {
			text: pageText.Unsatisfactory,
			darkColor: "#7d7d7d",
			lightColor: "#AFAFAF"
		},
		Partial: {
			text: pageText.Satisfactory,
			darkColor: "#87BAC0",
			lightColor: "#4BA6AD",
			score: 2
		},
		Intermediate: {
			text: pageText.Satisfactory,
			darkColor: "#87BAC0",
			lightColor: "#4BA6AD"
		},
		Yes: {
			text: pageText.Exceptional,
			darkColor: "#4BA6AD",
			lightColor: "#87BAC0",
			score: 3
		},
		Competent: {
			text: pageText.Exceptional,
			darkColor: "#4BA6AD",
			lightColor: "#87BAC0"
		}
	};

	const calculateAverageStatus = (array, returnType) => {
		let totalScore = 0;
		array.forEach((item) => {
			totalScore += statusDict[item.status]?.score || 0;
		});
		const averageScore = Math.round(totalScore / array.length);
		let text = "";
		let darkColor = "";
		let lightColor = "";
		switch (averageScore) {
			case 1:
				text = pageText.Unsatisfactory;
				darkColor = "#7d7d7d";
				lightColor = "#AFAFAF";
				break;
			case 2:
				text = pageText.Satisfactory;
				darkColor = "#87BAC0";
				lightColor = "#4BA6AD";
				break;
			case 3:
				text = pageText.Exceptional;
				darkColor = "#4BA6AD";
				lightColor = "#87BAC0";
				break;
			default:
				text = pageText.Unsatisfactory;
				darkColor = "#7d7d7d";
				lightColor = "#AFAFAF";
		}

		if (returnType === "text") {
			return text;
		}
		if (returnType === "darkColor") {
			return darkColor;
		}
		if (returnType === "lightColor") {
			return lightColor;
		}
	};

	const [expandedRows, setExpandedRows] = useState([]);
	const [hoveredRow, setHoveredRow] = useState(null);

	useEffect(() => {
		// Initialize expandedRows state with false values
		setExpandedRows(pointsArray.map(() => false));
	}, [pointsArray]);

	const toggleRow = (index) => {
		const newExpandedRows = [...expandedRows];
		newExpandedRows[index] = !newExpandedRows[index];
		setExpandedRows(newExpandedRows);
	};

	return (
		<>
			{title && <p className={r.reportTableTitle}>{title}</p>}
			{/* <TooltipContext.Provider value={tooltipValue}> */}
			<div className={r.reportTable}>
				{pointsArray.map((item, index) => (
					<React.Fragment key={item.short ? item.short : `fallback-${index}`}>
						<div className={`${r.reportTableRow} ${expandedRows[index] ? r.reportTableToggledRow : ""}`}>
							<div
								role="button"
								tabIndex={0}
								className={`${r.reportTableHeader} ${expandedRows[index] ? r.reportTableToggledHeader : ""}`}
								onMouseEnter={() => setHoveredRow(index)}
								onMouseLeave={() => setHoveredRow(null)}
								onClick={() => toggleRow(index)}
								onKeyDown={(event) => {
									if (event.key === "Enter" || event.key === " ") {
										toggleRow(index);
									}
								}}
							>
								<div
									className={r.questionColumn}
									style={{ width: expandedRows[index] ? "95%" : window.innerWidth > 1000 ? "65%" : "50%" }}
								>
									{item.short ? item.short.split("_")[0] : ""}
								</div>
								<div
									className={r.scoreColumn}
									style={{ width: expandedRows[index] ? "0%" : window.innerWidth > 1000 ? "30%" : "45%" }}
								>
									{!expandedRows[index] && (
										<>
											<div
												className={r.scoreContainer}
												style={{
													backgroundColor:
														item.checklist && !item.status
															? calculateAverageStatus(item.checklist, "darkColor")
															: statusDict[item.status]?.darkColor
												}}
											>
												<div
													className={r.scoreIconCircle}
													style={{
														backgroundColor:
															item.checklist && !item.status
																? calculateAverageStatus(item.checklist, "lightColor")
																: statusDict[item.status]?.lightColor
													}}
												>
													{scores ? (
														<p className={r.scoreIcon}>{statusDict[item.status]?.score}</p>
													) : item.status !== "No" && item.status !== "Novice" ? (
														<FaCheck className={r.scoreIcon} />
													) : (
														<FaTimes className={r.scoreIcon} />
													)}
												</div>
												{item.checklist && !item.status
													? calculateAverageStatus(item.checklist, "text")
													: statusDict[item.status]?.text}
											</div>
											{/* <InfoToolTip item_index={index} useTooltip={useTooltip}>
													<ScoreBreakdown statusOptions={statusOptions} statusDict={statusDict} scores={scores} />
												</InfoToolTip> */}
										</>
									)}
								</div>
								<div className={r.chevronColumn}>
									<FiChevronRight className={expandedRows[index] || hoveredRow === index ? r.chevronDown : r.chevronRight} />
								</div>
							</div>
							{item.checklist &&
								expandedRows[index] &&
								item.checklist.map((point, pointIndex) => (
									<div key={pointIndex} className={r.reportTableDropdown}>
										<div className={r.reportTableDropdownTop}>
											<div className={r.reportTableTranscript}>
												<p className={r.reportTableDropdownTitle}>
													{pointIndex + 1}. {point.item}
												</p>
												<p className={r.reportTableTranscriptText}>
													{point.statement !== null &&
													point.statement !== "" &&
													point.statement !== "null" &&
													point.statement !== undefined
														? point.statement.startsWith('"') && point.statement.endsWith('"')
															? point.statement
															: `"${point.statement}"`
														: "-"}
												</p>
											</div>
											<div className={r.scoreBreakdown}>
												{[...statusOptions].reverse().map((status, index) => (
													<div
														key={index}
														className={r.scoreContainer}
														style={{ backgroundColor: statusDict[status]?.darkColor }}
													>
														<div
															className={r.scoreIconCircle}
															style={{
																backgroundColor:
																	(item.checklist && !item.status ? point.status : item.status) === status
																		? statusDict[status]?.lightColor
																		: "transparent"
															}}
														>
															{(item.checklist && !item.status ? point.status : item.status) === status &&
																((item.checklist && !item.status ? point.status : item.status) === "No" ||
																(item.checklist && !item.status ? point.status : item.status) === "Novice" ? (
																	<FaTimes className={r.scoreIcon} />
																) : (
																	<FaCheck className={r.scoreIcon} />
																))}
														</div>
														{statusDict[status]?.text}
													</div>
												))}
											</div>
										</div>
										<div className={r.reportTableFeedback}>
											<div className={r.statusGradeFeedbackContainer}>
												<p className={r.reportTableDropdownTitle}>{pageText.Feedback}</p>
												<p className={r.statusGradeFeedbackText}>
													{item.checklist && !item.status ? point.feedback : item.feedback}
												</p>
											</div>
										</div>
										<hr className={r.reportTableDropdownDivider} />
									</div>
								))}
							{!item.checklist && expandedRows[index] && (
								<div className={r.reportTableDropdown}>
									<div className={r.reportTableDropdownTop}>
										<div className={r.reportTableTranscript}>
											<p className={r.reportTableDropdownTitle}>{pageText.Dialogue}:</p>
											<p className={r.reportTableTranscriptText}>
												{item.statement !== null &&
												item.statement !== "" &&
												item.statement !== "null" &&
												item.statement !== undefined
													? item.statement.startsWith('"') && item.statement.endsWith('"')
														? item.statement
														: `"${item.statement}"`
													: "-"}
											</p>
										</div>
										<div className={r.scoreBreakdown}>
											{[...statusOptions].reverse().map((status, index) => (
												<div
													key={index}
													className={r.scoreContainer}
													style={{ backgroundColor: statusDict[status]?.darkColor }}
												>
													<div
														className={r.scoreIconCircle}
														style={{
															backgroundColor: item.status === status ? statusDict[status]?.lightColor : "transparent"
														}}
													>
														{item.status === status &&
															(item.status === "No" || item.status === "Novice" ? (
																<FaTimes className={r.scoreIcon} />
															) : (
																<FaCheck className={r.scoreIcon} />
															))}
													</div>
													{statusDict[status]?.text}
												</div>
											))}
										</div>
									</div>
									<div className={r.reportTableFeedback}>
										<div className={r.statusGradeFeedbackContainer}>
											<p className={r.reportTableDropdownTitle}>{pageText.Feedback}:</p>
											<p className={r.statusGradeFeedbackText}>{item.feedback}</p>
										</div>
									</div>
								</div>
							)}
						</div>
					</React.Fragment>
				))}
			</div>
			{/* </TooltipContext.Provider> */}
		</>
	);
}
