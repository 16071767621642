import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import AggregateStatsList from "./AggregateStatsList.js";
import { SimActivity } from "./SimActivity.js";
import Dashboard from "../Setup/Dashboard.js";
import styles from "../../styleModules/adminSystemStyles.module.css";

export const url = process.env.REACT_APP_BACKEND_STATIC_URL;

function AdminPage() {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const navigate = useNavigate();

	const { user, adminAccess, showSidebar, setShowSidebar, setSidebarContent, adminLinkList, activeAdminLink, setActiveAdminLink } =
		useContext(AppContext);

	useEffect(() => {
		if (user) {
			if (!adminAccess) {
				navigate("/dashboard");
			} else {
				if (activeAdminLink === "") {
					setActiveAdminLink("Create Simulation");
				}
				setSidebarContent("AdminView");
				// setShowSidebar(true);
			}
		}
	}, [user]);

	return (
		<>
			<Helmet>
				<title>InStage Admin</title>
				<meta name="description" content="Admin Page" />
				<meta name="robots" content="noindex" />
			</Helmet>

			<div className={styles.adminPage}>{adminLinkList[activeAdminLink] ? adminLinkList[activeAdminLink].component || <></> : null}</div>
		</>
	);
}

export default AdminPage;
