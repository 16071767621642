import React, { useState, useEffect, useContext, useRef, createContext, useCallback } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { FiCheck, FiX, FiCopy, FiMoreHorizontal, FiTrash2 } from "react-icons/fi/index.esm.js";
import { IoClose } from "react-icons/io5/index.esm.js";
import { createPopper } from "@popperjs/core";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import s from "../../styleModules/setupStyles.module.css";
import styles from "../../styleModules/adminSystemStyles.module.css";
import styles2 from "../../styleModules/modalStyles.module.css";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { BaseTable, Column } from "../SecondaryComponents/BaseTable.jsx";
import ModalWrapper from "../SecondaryComponents/ModalWrapper.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";

import circles from "../../images/circles.png";

function UrlCell({ render, reportLink, shareLink }) {
	const navigate = useNavigate();
	const [isCopied, setIsCopied] = useState(false);

	return (
		<div className={styles.urlCell}>
			{render === "button" && (
				<button type="button" className={styles.urlButton} onClick={() => navigate(reportLink)}>
					Go
				</button>
			)}

			{render === "icon" && (
				<>
					{isCopied ? (
						<FiCheck
							className={styles.copiedIcon}
							onClick={() => {
								setIsCopied(true);
								navigator.clipboard.writeText(shareLink);
							}}
						/>
					) : (
						<FiCopy
							className={styles.copyIcon}
							onClick={() => {
								setIsCopied(true);
								navigator.clipboard
									.writeText(shareLink)
									.then(() => {
										setIsCopied(true);
										// console.log("URL copied to clipboard:", shareLink);
										setTimeout(() => {
											setIsCopied(false);
										}, 1000); // Reset the "Copied" message after 2 seconds
									})
									.catch((error) => {
										console.error("Failed to copy URL to clipboard:", error);
									});
							}}
						/>
					)}
				</>
			)}
		</div>
	);
}

const DropdownContext = createContext();

function DropdownProvider({ children }) {
	const [activeMenu, setActiveMenu] = useState(null);

	return <DropdownContext.Provider value={{ activeMenu, setActiveMenu }}>{children}</DropdownContext.Provider>;
}

const useOutsideClick = (ref, callback) => {
	const handleClick = (e) => {
		if (ref.current && !ref.current.contains(e.target)) {
			callback();
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleClick);

		return () => {
			document.removeEventListener("click", handleClick);
		};
	});
};

const FiMoreHorizontalWithRef = React.forwardRef((props, ref) => (
	<div ref={ref} style={{ width: "100%" }}>
		<FiMoreHorizontal {...props} />
	</div>
));

function DropdownContent({ setSelectedSimulationId, simulation_id, setShowDeleteModal, canDelete, menuRef, buttonRect, activeMenu, setActiveMenu }) {
	return activeMenu
		? ReactDOM.createPortal(
				<div
					className={styles.dropdownContent}
					ref={menuRef}
					onClick={(event) => event.stopPropagation()}
					style={{
						position: "fixed",
						top: `${buttonRect.top}px`,
						left: `${buttonRect.right}px`
					}}
				>
					{canDelete && (
						<p
							className={styles.dropdownContentText}
							style={{ borderTopLeftRadius: "0.25rem", borderTopRightRadius: "0.25rem" }}
							onClick={() => {
								setSelectedSimulationId(simulation_id);
								setShowDeleteModal(true);
							}}
						>
							<FiTrash2 />
							Delete
						</p>
					)}
					{/* <p
          className={styles.dropdownContentText}
          style={{ borderBottomLeftRadius: "0.25rem", borderBottomRightRadius: "0.25rem" }}
          onClick={() => setActiveMenu(null)}
      >
          Cancel
      </p> */}
				</div>,
				document.body
		  )
		: null;
}

function ManageCell({ setSelectedSimulationId, setShowDeleteModal, instage_id, user_instage_id, isAdmin, simulation_id, isGlobal }) {
	const { activeMenu, setActiveMenu } = useContext(DropdownContext);
	const btnRef = useRef();
	const menuRef = useRef();
	const buttonRect = btnRef.current ? btnRef.current.getBoundingClientRect() : { top: 0, right: 0 };
	const canDelete = !isGlobal && (isAdmin || instage_id === user_instage_id);

	useOutsideClick(menuRef, () => {
		if (activeMenu) {
			setActiveMenu(null);
		}
	});

	const handleButtonClick = (simulation_id) => (event) => {
		event.stopPropagation();
		if (activeMenu === simulation_id) {
			setActiveMenu(null); // Close the menu if it's the active one.
		} else {
			setActiveMenu(simulation_id); // Otherwise, open this menu and close any others.
		}
	};

	useEffect(() => {
		let popperInstance = null;
		if (activeMenu && btnRef.current && menuRef.current) {
			popperInstance = createPopper(btnRef.current, menuRef.current, {
				placement: "right-start",
				modifiers: [
					{
						name: "offset",
						options: {
							offset: [0, 2]
						}
					}
				]
			});
		}
		return () => {
			if (popperInstance) {
				popperInstance.destroy();
				popperInstance = null;
			}
		};
	}, [activeMenu]);

	return (
		<div className={styles.urlCell} style={{ position: "relative", width: "100%" }}>
			<FiMoreHorizontalWithRef
				ref={btnRef}
				className={`${canDelete ? styles.copyIcon : styles.copyIconDisabled} ${activeMenu === simulation_id ? styles.copyIconActive : ""}`}
				style={{ width: "100%" }}
				disabled={!canDelete}
				onClick={handleButtonClick(simulation_id)}
			/>
			<DropdownContent
				setSelectedSimulationId={setSelectedSimulationId}
				simulation_id={simulation_id}
				setShowDeleteModal={setShowDeleteModal}
				canDelete={canDelete}
				menuRef={menuRef}
				buttonRect={buttonRect}
				activeMenu={activeMenu === simulation_id}
				setActiveMenu={setActiveMenu}
			/>
		</div>
	);
}

function SetupNameCellRenderer(props) {
	const value = props.rowData?.setup_output?.assignment_name || props.cellData;
	return <div className={styles.tableData}>{value}</div>;
}

function SetupTypeCellRenderer(props) {
	const { resumeAssistAccess } = useContext(AppContext);

	const { cellData, rowData } = props;

	let value = cellData;
	if (rowData.global) {
		value = `InStage ${cellData}`;
	}
	if (rowData.setup_type === "discovery_spin") {
		value = "Discovery SPIN";
	}
	if (rowData.setup_type === "interview" && resumeAssistAccess) {
		value = "Job Prep";
	}
	return <div className={styles.tableData}>{value.charAt(0).toUpperCase() + value.slice(1)}</div>;
}

function SimulationIdCellRenderer(props) {
	const { tenantId } = useContext(AppContext);

	let value = props.cellData;
	const { setup_type } = props.rowData;
	let url = "";
	switch (setup_type) {
		case "interview":
			url = "/setup/interview/"; // videos done
			break;
		case "discovery":
			url = "/setup/discovery/"; // videos done
			break;
		case "discovery_spin":
			url = "/setup/discovery_spin/";
			break;
		case "closing":
			url = "/setup/closing/";
			break;
		case "presentation":
			url = "/setup/presentation/"; // videos done
			break;
		case "retail":
			url = "/setup/retail/";
			break;
		case "intro":
			url = "/setup/intro/";
			break;
		case "freestyle":
			url = "/setup/freestyle/";
			break;
		case "patient":
			url = "/setup/patient/";
		default:
			break;
	}

	let reportLink = url + value;
	if (props.rowData.global) {
		url = props.rowData.setup_output.setup_url;
		reportLink = url;
	}
	let shareLink = `/share${url}${value}/accId-${tenantId}`;
	if (value === "cougar-drilling-systems") {
		reportLink = "/setup/cougar-drilling-systems";
		shareLink = "/share" + "/setup/cougar-drilling-systems" + `/accId-${tenantId}`;
	}

	const reportID = value;
	value = <UrlCell render="button" reportLink={reportLink} shareLink={shareLink} />;
	return <div className={styles.tableData}>{value}</div>;
}

function SimulationIdIconCellRenderer(props) {
	const { tenantId } = useContext(AppContext);

	let value = props.cellData;
	const currentDomain = window.location.origin;
	const { setup_type } = props.rowData;
	let url = "";
	switch (setup_type) {
		case "interview":
			url = "/setup/interview/";
			break;
		case "discovery":
			url = "/setup/discovery/";
			break;
		case "discovery_spin":
			url = "/setup/discovery_spin/";
			break;
		case "closing":
			url = "/setup/closing/";
			break;
		case "presentation":
			url = "/setup/presentation/";
			break;
		case "retail":
			url = "/setup/retail/";
			break;
		case "intro":
			url = "/setup/intro/";
			break;
		case "freestyle":
			url = "/setup/freestyle/";
			break;
		case "patient":
			url = "/setup/patient/";
		default:
			break;
	}

	let reportLink = currentDomain + url + value;
	if (props.rowData.global) {
		url = props.rowData.setup_output.setup_url;
		reportLink = currentDomain + url;
	}
	let shareLink = `${currentDomain}/share${url}${value}/accId-${tenantId}`;
	if (value === "cougar-drilling-systems") {
		reportLink = `${currentDomain}/setup/cougar-drilling-systems`;
		shareLink = `${currentDomain}/share` + "/setup/cougar-drilling-systems" + `/accId-${tenantId}`;
	}

	const reportID = value;
	value = <UrlCell render="icon" reportLink={reportLink} shareLink={shareLink} />;
	return <div className={styles.tableData}>{value}</div>;
}

function ManageCellRenderer({ cellData, rowData, setSelectedSimulationId, setShowDeleteModal, instage_id, localUser, adminAccess }) {
	const simulation_id = cellData;

	const value = (
		<ManageCell
			isGlobal={rowData.global}
			setSelectedSimulationId={setSelectedSimulationId}
			setShowDeleteModal={setShowDeleteModal}
			instage_id={instage_id}
			user_instage_id={localUser?.id}
			isAdmin={adminAccess}
			simulation_id={simulation_id}
		/>
	);

	return <div className={styles.tableData}>{value}</div>;
}
//DEPRECATED
export default function SimulationList() {
	// const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	// const { localUser, tenantId, adminAccess, tenantMetaData } = useContext(AppContext);

	// const url = process.env.REACT_APP_BACKEND_STATIC_URL;
	// const instage_id = localUser ? localUser.id : null;

	// const tenantOverrideList = [
	// 	"002-95806-67997-85676" // B2B Professional
	// ];
	// const cougarSim = { setup_name: "Cougar Drilling Systems", setup_type: "Custom Discovery", simulation_id: "cougar-drilling-systems" };
	// // if page is /admin
	// const isAdminPage = window.location.href.includes("admin");

	// const [data, setData] = useState([]);
	// const [showDeleteModal, setShowDeleteModal] = useState(false);
	// const [selectedSimulationId, setSelectedSimulationId] = useState(-1);

	// const fetchData = async (company, submitted) => {
	// 	// console.log("adminAccess",adminAccess)
	// 	// if (adminAccess && isAdminPage) {
	// 	//     const result = await axiosLimitedGet(url + "/api/dashboard/simulationsByTenant", 1, { params: { tenantId } });
	// 	//     //console.log(result.data);
	// 	//     result.data.sort((a, b) => (a.global > b.global ? -1 : 1));

	// 	//     //filter out the data

	// 	//     if (tenantOverrideList.includes(tenantId)) {
	// 	//         setData([cougarSim, ...result.data]);
	// 	//     } else {
	// 	//         setData(result.data);
	// 	//     }
	// 	// } else {
	// 	const result = await axiosLimitedGet(`${url}/api/dashboard/simulationsByInstageId`, 1, { params: { instage_id } });

	// 	// console.log(result.data);
	// 	// order the data where global is set to true first
	// 	result.data.sort((a, b) => (a.global > b.global ? -1 : 1));
	// 	if (tenantMetaData?.setup_type_list) {
	// 		// filter out the data3
	// 		// console.log(tenantMetaData?.setup_type_list);
	// 		// console.log(result.data);
	// 		result.data = result.data.filter((item) => tenantMetaData?.setup_type_list.includes(item.setup_type));
	// 	}
	// 	// filter out the data
	// 	// const filteredData = result.data.filter(item => item.incomplete_percentage !== '%');
	// 	if (tenantOverrideList.includes(tenantId)) {
	// 		setData([cougarSim, ...result.data]);
	// 	} else {
	// 		setData(result.data);
	// 	}
	// 	// }
	// 	// resetFilters();
	// };

	// const deleteSimulation = async (simulation_id) => {
	// 	const result = await axiosLimitedDelete(`${url}/api/saved/simulation`, 1, { params: { simulation_id } });
	// 	// console.log(result.data);
	// 	// order the data where global is set to true first
	// 	fetchData();
	// };
	// useEffect(() => {
	// 	fetchData();
	// }, [tenantId, localUser, adminAccess, tenantMetaData]);

	// const headers = ["Session Name", "Type", "Launch", "Copy", "Manage"];

	// const columnWidths = {
	// 	"Session Name": "300px",
	// 	Type: "200px",
	// 	Launch: "100px",
	// 	Copy: "100px",
	// 	Manage: "100px"
	// };

	// const filteredData = data.filter((item) => {
	// 	const result = true;
	// 	return result;
	// });
	return <></>;
	return (
		<DropdownProvider>
			<div className={styles.adminPageContainer}>
				{showDeleteModal && (
					<ModalWrapper closeModal={() => setShowDeleteModal(false)}>
						<div className={styles2.modal}>
							<img src={circles} alt="Circles" className={styles2.modalCircles} />
							<div className={`${styles2.modalHeaderIconContainer} ${styles2.modalWarningIconContainer}`}>
								<FiTrash2 className={styles2.modalHeaderIcon} />
							</div>
							<IoClose className={styles2.modalCloseIcon} title="Close icon" onClick={() => setShowDeleteModal(false)} />
							<div className={styles2.modalTextContainer}>
								<h2 className={styles2.modalTitle}>Delete simulation</h2>
								<p className={styles2.modalText}>Are you sure you want to delete this simulation? This action cannot be undone.</p>
							</div>
							<div className={styles2.modalBtnContainer}>
								<button type="button" className={styles2.modalCancelBtn} onClick={() => setShowDeleteModal(false)}>
									Cancel
								</button>
								<button
									type="button"
									className={styles2.modalWarningBtn}
									disabled={false}
									onClick={() => {
										deleteSimulation(selectedSimulationId);
										setShowDeleteModal(false);
									}}
								>
									Delete
								</button>
							</div>
						</div>
					</ModalWrapper>
				)}

				<h1 className={s.dashboardHeading} style={{ margin: "0 0 25px" }}>
					Your Sessions
				</h1>

				{data.length === 0 && (
					<div className={styles.spinnerContainer}>
						<LoadingSpinner height={75} width={75} thickness={8} color="#00a9af" />
					</div>
				)}

				{data.length > 0 && (
					<BaseTable
						className={styles.tableContainer}
						textAlign="left"
						data={filteredData}
						rowKey="key"
						fixed
						maxHeight={Infinity}
						headerHeight={0}
						headers={headers}
						classNameHeader={styles.tableHeaderRow}
						columnWidths={columnWidths}
					>
						<Column title="setup_name" key="setup_name" dataKey="setup_name" textAlign="left" cellRenderer={SetupNameCellRenderer} />
						<Column title="setup_type" key="setup_type" dataKey="setup_type" textAlign="center" cellRenderer={SetupTypeCellRenderer} />
						<Column
							title="simulation_id"
							key="simulation_id"
							dataKey="simulation_id"
							textAlign="center"
							cellRenderer={SimulationIdCellRenderer}
						/>
						<Column
							title="simulation_id"
							key="simulation_id"
							dataKey="simulation_id"
							textAlign="center"
							cellRenderer={SimulationIdIconCellRenderer}
						/>
						<Column
							title="manage"
							key="simulation_id"
							dataKey="simulation_id"
							textAlign="center"
							cellRenderer={(props) => (
								<ManageCellRenderer
									{...props}
									localUser={localUser}
									adminAccess={adminAccess}
									instage_id={instage_id}
									setShowDeleteModal={setShowDeleteModal}
									setSelectedSimulationId={setSelectedSimulationId}
								/>
							)}
						/>
					</BaseTable>
				)}
			</div>
		</DropdownProvider>
	);
}

export const getEmptySims = () => {
	const excludedTypes = ["patient", "freestyle", "retail"];
	// console.log("getEmptySims():", excludedTypes);
	return excludedTypes;
};
