import React, { useState, useEffect, useRef, useContext } from "react";
import { FiChevronRight } from "react-icons/fi/index.esm.js";
import { FaLock } from "react-icons/fa/index.esm.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import r from "../../styleModules/reportStyles.module.css";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import TypingText from "../UtilityFunctions/TypingText.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import QuestionAnswerTable from "./QuestionAnswerTable.js";
import QuestionSummaryTable from "./QuestionSummaryTable.js";
import ProcessTable from "./ProcessTable.js";
import { similarityPercentageLevenshtein, compareStringsUsingCosine, compareStringsUsingNGrams } from "../UtilityFunctions/textCompare.js";
import usePollingIntervals from "../UtilityFunctions/usePollingIntervals";
import Overall from "./Overall.js";
import Communication from "./Communication.js";
import ReflectionSummary from "./ReflectionSummary.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

const presentation_process_points = [
	{
		short: "Introduction",
		checklist: [
			{ item: "Engaging Hook", criteria: "Does the presenter effectively capture the audience's attention from the start?" },
			{ item: "Purpose Statement", criteria: "Is the objective or purpose of the presentation clearly communicated?" },
			{ item: "Overview", criteria: "Does the presenter provide a brief summary or outline of the presentation's content?" }
		],
		feedback: "",
		status: ""
	},
	{
		short: "Body",
		checklist: [
			{ item: "Argument Statement", criteria: "Does the presenter state their arguments or points clearly?" },
			{
				item: "Evidence Presentation",
				criteria: "Does the presenter provide concrete evidence, examples, or data to support each argument?"
			},
			{
				item: "Audience Engagement Points",
				criteria:
					"Does the presenter use rhetorical questions, interactive elements, or other methods to engage the audience during each section?"
			},
			{ item: "Section Summary", criteria: "Does the presenter summarize or encapsulate the main points at the end of each section?" }
		],
		feedback: "",
		status: ""
	},
	{
		short: "Conclusion",
		checklist: [
			{ item: "Recap", criteria: "Does the presenter effectively summarize the main points or arguments?" },
			{
				item: "Closing Statement",
				criteria: "Does the presenter make a strong, impactful final statement that encapsulates the purpose of the presentation?"
			},
			{
				item: "Call to Action/Next Steps",
				criteria:
					"Does the presenter guide the audience on what should come next, whether it's a call to action or a suggestion for further research or thought?"
			}
		],
		feedback: "",
		status: ""
	}
];

export default function ReportSection({ typingRefs, activeReportTab, sessionData, assignmentData, speechMetrics, setSpeechMetrics }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const { adminAccess, tenantId, tenantPlan, customFeatures, showLockedModal, userLanguage, performTranslation, translateDictTex, runTranslation } =
		useContext(AppContext);

	// Data states
	const [outcome, setOutcome] = useState("");
	const [summary, setSummary] = useState("");
	const [positives, setPositives] = useState("");
	const [improvements, setImprovements] = useState("");
	const [questionResponses, setQuestionResponses] = useState([]);
	const [questionSummary, setQuestionSummary] = useState([]);
	const [processPoints, setProcessPoints] = useState([]);
	const [learningPoints, setLearningPoints] = useState([]);
	const [preliminaries, setPreliminaries] = useState([]);
	const [recap, setRecap] = useState("");
	const [takeaways, setTakeaways] = useState([]);
	const [generalSummary, setGeneralSummary] = useState();
	const [competencySummary, setCompetencySummary] = useState();

	// Grade states
	const [score, setScore] = useState();
	const [contentGrade, setContentGrade] = useState();
	const [deliveryGrade, setDeliveryGrade] = useState();
	const [completionGrade, setCompletionGrade] = useState();
	const [otherGrade, setOtherGrade] = useState();
	const [preliminariesGrade, setPreliminariesGrade] = useState();
	const [spin1Grade, setSpin1Grade] = useState();
	const [spin2Grade, setSpin2Grade] = useState();
	const [budgetGrade, setBudgetGrade] = useState();
	const [fab1Grade, setFab1Grade] = useState();
	const [fab2Grade, setFab2Grade] = useState();
	const [objection1Grade, setObjection1Grade] = useState();
	const [objection2Grade, setObjection2Grade] = useState();
	const [closingGrade, setClosingGrade] = useState();
	const [tempGrades, setTempGrades] = useState({
		content: null,
		delivery: null,
		completion: null,
		other: null,

		// Both Discovery and Closing
		preliminaries: null,

		// Discovery
		spin1: null,
		spin2: null,
		budget: null,

		// Closing
		fab1: null,
		fab2: null,
		objection1: null,
		objection2: null,
		closing: null
	});
	const [overallRating, setOverallRating] = useState();
	const [preliminariesRating, setPreliminariesRating] = useState();
	const [process1Rating, setProcess1Rating] = useState();
	const [process2Rating, setProcess2Rating] = useState();
	const [pendingUpdates, setPendingUpdates] = useState({});
	const [inputError, setInputError] = useState(false);

	// Loading states
	const [questionResponsesLoading, setQuestionResponsesLoading] = useState(true);
	const [evaluationLoading, setEvaluationLoading] = useState(true);
	const [questionSummaryLoading, setQuestionSummaryLoading] = useState(true);
	const [processPointsLoading, setProcessPointsLoading] = useState(true);
	const [learningPointsLoading, setLearningPointsLoading] = useState(true);
	const [preliminariesLoading, setPreliminariesLoading] = useState(true);
	const [generalSummaryLoading, setGeneralSummaryLoading] = useState(true);
	const [competencySummaryLoading, setCompetencySummaryLoading] = useState(true);
	const [questionSummaryPercent, setQuestionSummaryPercent] = useState(0);
	const [processPointsPercent, setProcessPointsPercent] = useState(0);
	const [learningPointsPercent, setLearningPointsPercent] = useState(0);
	const [preliminariesPercent, setPreliminariesPercent] = useState(0);

	const { setPollingInterval, clearPollingInterval } = usePollingIntervals();
	const [RenderedText, setRenderedText] = useState(false);
	// Static Translation
	const LocalLanguageDict = {
		ReportInterviewFor: "Report: Interview for",
		NoQuestionsFound: "No questions or responses found.",
		ItemsToLearn: "Items to Learn",
		Process: "Process",
		What: "What",
		SoWhat: "So what",
		NowWhat: "Now what"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [hasTranslatedText, setHasTranslatedText] = useState(false);
	// Static Usage
	useEffect(() => {
		async function translateText() {
			await performTranslation(pageText, setPageText);
		}
		if (!hasTranslatedText) {
			translateText();
			setHasTranslatedText(true);
		}
	}, [userLanguage, sessionData]);

	useEffect(() => {
		setRenderedText((prev) => !prev);
	}, [pageText]);

	useEffect(() => {
		runTranslation();
		// console.log("Run Translation");
	}, [userLanguage]);

	// process_points
	const [ProcessPointsTranslated, setProcessPointsTranslated] = useState([sessionData.process_points]);
	// learning_points
	const [LearningPointsTranslated, setLearningPointsTranslated] = useState([sessionData.learning_points]);

	const translatePoints = async (points, setTranslatedPoints) => {
		if (!points) {
			return;
		}
		const textsToTranslate = {};
		points.forEach((point) => {
			if (point.short) {
				textsToTranslate[point.short] = point.short;
			}
			if (point.feedback) {
				textsToTranslate[point.feedback] = point.feedback; // Add feedback to the texts to translate
			}
			if (point.statement) {
				textsToTranslate[point.statement] = point.statement; // Add feedback to the texts to translate
			}
			if (point.checklist) {
				point.checklist.forEach((item) => {
					if (item.item) {
						textsToTranslate[item.item] = item.item;
					}
					if (item.criteria) {
						textsToTranslate[item.criteria] = item.criteria;
					}
					if (item.statement) {
						textsToTranslate[item.statement] = item.statement;
					}
					if (item.feedback) {
						textsToTranslate[item.feedback] = item.feedback;
					}
				});
			}
		});

		await performTranslation(textsToTranslate, (translatedTexts) => {
			const translatedPoints = points.map((point) => {
				const updatedPoint = {
					...point,
					short: point.short ? translatedTexts[point.short] : point.short,
					feedback: point.feedback ? translatedTexts[point.feedback] : point.feedback,
					statement: point.statement ? translatedTexts[point.statement] : point.statement
				};

				if (point.checklist) {
					updatedPoint.checklist = point.checklist.map((item) => ({
						...item,
						item: translatedTexts[item.item],
						criteria: translatedTexts[item.criteria],
						statement: item.statement ? translatedTexts[item.statement] : undefined,
						feedback: item.feedback ? translatedTexts[item.feedback] : undefined
					}));
				}

				return updatedPoint;
			});

			setTranslatedPoints(translatedPoints);
			// console.log("TranslatedPoints: ", translatedPoints);
		});
	};

	useEffect(() => {
		// console.log("ProcessPointsOriginal: ", sessionData.process_points);
		if (sessionData.process_points) {
			translatePoints(sessionData.process_points, setProcessPointsTranslated);
		}
	}, [userLanguage, sessionData.process_points]);

	useEffect(() => {
		// console.log("LearningPointsOriginal: ", sessionData.learning_points);
		if (sessionData.learning_points) {
			translatePoints(sessionData.learning_points, setLearningPointsTranslated);
		}
	}, [userLanguage, sessionData.learning_points]);

	// Additional useEffect to log the translated points after state update
	useEffect(() => {
		// console.log("ProcessPointsTranslated: ", ProcessPointsTranslated);
	}, [ProcessPointsTranslated]);

	useEffect(() => {
		// console.log("LearningPointsTranslated: ", LearningPointsTranslated);
	}, [LearningPointsTranslated]);

	const setupConfigurations = {
		discovery_spin: {
			gradingCriteria: [
				{
					key: "preliminaries",
					label: "Opening",
					grade: tempGrades.preliminaries ?? preliminariesGrade,
					possibleTotal: 21
				},
				{
					key: "spin1",
					label: "Situation 1",
					grade: tempGrades.spin1 ?? spin1Grade,
					possibleTotal: 12
				},
				{
					key: "spin2",
					label: "Situation 2",
					grade: tempGrades.spin2 ?? spin2Grade,
					possibleTotal: 12
				},
				{
					key: "budget",
					label: "Budget",
					grade: tempGrades.budget ?? budgetGrade,
					possibleTotal: 21
				}
			]
		},
		closing: {
			gradingCriteria: [
				{ key: "preliminaries", label: "Opening", grade: tempGrades.preliminaries ?? preliminariesGrade, possibleTotal: 21 },
				{ key: "fab1", label: "FAB-P 1", grade: tempGrades.fab1 ?? fab1Grade, possibleTotal: 12 },
				{ key: "fab2", label: "FAB-P 2", grade: tempGrades.fab2 ?? fab2Grade, possibleTotal: 12 },
				{ key: "objection1", label: "Objection 1", grade: tempGrades.objection1 ?? objection1Grade, possibleTotal: 15 },
				{ key: "objection2", label: "Objection 2", grade: tempGrades.objection2 ?? objection2Grade, possibleTotal: 15 },
				{ key: "closing", label: "Closing", grade: tempGrades.closing ?? closingGrade, possibleTotal: 9 }
			]
		}
	};

	const currentSetupConfig = setupConfigurations[sessionData.setup_type];

	const gradingScores = {
		No: 1,
		Partial: 2,
		Yes: 3
	};

	const calculateGrade = (array) => {
		let grade = 0;
		array.forEach((item) => {
			grade += gradingScores[item.status] || 0;
		});
		return grade;
	};

	const getRating = (value, total) => {
		const percentage = (value / total) * 100;
		if (percentage >= 80) return "Excellent";
		if (percentage >= 70) return "Good";
		if (percentage >= 50) return "Satisfactory";
		return "Below Expectation";
	};

	const setRatings = ({ currentSetupType, preliminariesValue, process1Value, process2Value, overallValue }) => {
		let process1Keys;
		let process2Keys;

		if (sessionData.setup_type === "discovery_spin") {
			process1Keys = ["spin1", "spin2", "budget"];
		} else if (sessionData.setup_type === "closing") {
			process1Keys = ["fab1", "fab2", "closing"];
			process2Keys = ["objection1", "objection2"];
		}

		const possibleTotals = {
			preliminaries: setupConfigurations[currentSetupType].gradingCriteria.find((item) => item.key === "preliminaries").possibleTotal,
			process1: setupConfigurations[currentSetupType].gradingCriteria
				.filter((item) => process1Keys.includes(item.key))
				.reduce((acc, item) => acc + item.possibleTotal, 0),
			overall: setupConfigurations[currentSetupType].gradingCriteria.reduce((acc, item) => acc + item.possibleTotal, 0)
		};

		if (process2Keys) {
			possibleTotals.process2 = setupConfigurations[currentSetupType].gradingCriteria
				.filter((item) => process2Keys.includes(item.key))
				.reduce((acc, item) => acc + item.possibleTotal, 0);
		}

		const preliminariesRatingLookup = getRating(preliminariesValue, possibleTotals.preliminaries);
		const process1RatingLookup = getRating(process1Value, possibleTotals.process1);
		const process2RatingLookup = process2Keys ? getRating(process2Value, possibleTotals.process2) : null;
		const overallRatingLookup = getRating(overallValue, possibleTotals.overall);

		setPreliminariesRating(preliminariesRatingLookup);
		setProcess1Rating(process1RatingLookup);
		setProcess2Rating(process2RatingLookup);
		setOverallRating(overallRatingLookup);
	};

	function matchAudioToSummary(sessionData, question_summary) {
		// console.log(question_summary)
		// console.log(sessionData.speech_data)
		// console.log(sessionData.audio_files)
		const { speech_data } = sessionData;
		if (!speech_data || speech_data.length === 0) {
			return question_summary;
		}
		for (let i = 0; i < question_summary.length; i++) {
			// console.log(i)
			const item = question_summary[i];

			for (let j = 0; j < item.combined_messages.length; j++) {
				const message = item.combined_messages[j];
				if (message.role == "user") {
					let highestSimilaritySpeechItem = {};
					let currentPerc = 0;
					// console.log(message.text)
					for (let k = 0; k < speech_data.length; k++) {
						const cosineSimilarityResult = compareStringsUsingCosine(message.text, speech_data[k].transcript) * 100;
						const nGramResult = compareStringsUsingNGrams(message.text, speech_data[k].transcript, 3) * 100;

						const newPerc = (cosineSimilarityResult + nGramResult) / 2;
						if (newPerc > currentPerc) {
							currentPerc = newPerc;
							highestSimilaritySpeechItem = speech_data[k];
						}
					}
					// console.log(currentPerc)
					if (currentPerc > 30) {
						message.speech_data = {
							file_name: highestSimilaritySpeechItem.file_name
								? `${url}/Audio2?key=${encodeURI(highestSimilaritySpeechItem.file_name)}`
								: null,
							video_file_name: highestSimilaritySpeechItem.video_file_name
								? `${url}/Audio2?key=${encodeURI(highestSimilaritySpeechItem.video_file_name)}`
								: null,
							transcript: highestSimilaritySpeechItem.transcript
						};
					}
				}
			}
		}
		return question_summary;
	}

	const getEvaluation = async ({ currentSessionData, question_summary, processPointsData, preliminariesData }) => {
		let { summary_feedback } = currentSessionData;
		let { outcome } = currentSessionData;

		const apiPath = `/api/report/${sessionData.setup_type}`;

		// If summary_feedback and outcome have already been generated, load it from session data
		if (summary_feedback && outcome) {
			// console.log("Loading outcome and summary feedback from session data");
			return { outcome, summary_feedback };
		}
		// If status is 'generating', skip the new request and go directly to polling
		if (sessionData.summary_feedback_status === "generating") {
			// console.log("Outcome and summary feedback are generating, starting polling...");
		} else {
			// Otherwise, initiate a request for new outcome and summary feedback
			// console.log("Generating new outcome and summary feedback...");
			const data = {
				instage_id: currentSessionData.instage_id,
				session_data: {
					audience: currentSessionData.audience,
					chat_history: currentSessionData.chat_history,
					company: currentSessionData.company,
					company_description_short: currentSessionData.company_description_short,
					backstory: currentSessionData.backstory,
					buyer_title: currentSessionData.buyer_title,
					call_notes: currentSessionData.call_notes,
					session_id: currentSessionData.session_id,
					setup_type: currentSessionData.setup_type,
					process_points: processPointsData || currentSessionData.process_points,
					learning_points: currentSessionData.learning_points,
					preliminaries: preliminariesData || currentSessionData.preliminaries,
					job_question_list: currentSessionData.job_question_list,
					short_summary: currentSessionData.short_summary,
					job_function: currentSessionData.job_function,
					experience_level: currentSessionData.experience_level,
					industry: currentSessionData.industry,
					main_responsibilities: currentSessionData.main_responsibilities,
					skills_required: currentSessionData.skills_required,
					speech_data: currentSessionData.speech_data,
					question_summary_status: currentSessionData.question_summary_status,
					setup_output: currentSessionData.setup_output,
					presentation_details: currentSessionData.presentation_details,
					topic: currentSessionData.topic,
					objective: currentSessionData.objective,
					audience_role: currentSessionData.audience_role,
					user_role: currentSessionData.user_role,
					intro_details: currentSessionData.intro_details,
					visit_type: currentSessionData.visit_type,
					patient_summary: currentSessionData.patient_summary,
					patient_bio: currentSessionData.patient_bio,
					patient_medical: currentSessionData.patient_medical,
					pitch_topic: currentSessionData.pitch_topic,
					pitch_details: currentSessionData.pitch_details,
					product: currentSessionData.product
				},
				question_summary: question_summary || null,
				process_points: processPointsData || null,
				preliminaries: preliminariesData || null
			};
			try {
				const resp = await axiosLimitedPost(`${url}${apiPath}/Evaluation`, data, 1, {}, 180000);
				if (resp.data === "Not enough chat history") {
					// console.log("Not enough chat history to generate outcome and summary feedback");
					return Promise.resolve("Not enough chat history");
				}
			} catch (error) {
				console.error("Error in requesting outcome and summary feedback:", error);
				return Promise.reject(error);
			}
		}

		// Polling function for both new and generating outcome and summary feedback
		return (onComplete) => {
			const intervalId = setPollingInterval(async () => {
				try {
					const params = { session_id: sessionData.session_id };
					// console.log("Checking outcome and summary feedback in database...");
					const resp = await axiosLimitedGet(`${url}${apiPath}/Evaluation`, 1, { params });
					// If outcome and summary feedback are available, clear the interval and invoke onComplete
					if (resp.data.outcome && resp.data.summary_feedback && resp.data.outcome !== "") {
						clearPollingInterval(intervalId);
						onComplete({ outcome: resp.data.outcome, summary_feedback: resp.data.summary_feedback });
					}
				} catch (error) {
					console.error("Error during polling for outcome and summary feedback:", error);
					clearPollingInterval(intervalId);
				}
			}, 5000);
		};
	};

	const getQuestionSummary = async (sessionData) => {
		// If question summary is already generated, load from session data
		if (sessionData.question_summary && sessionData.question_summary.length > 0) {
			// console.log("Loading question summary from session data");
			return sessionData.question_summary;
		}

		// Start polling if status is 'generating', else initiate a new request
		if (sessionData.question_summary_status === "generating") {
			// console.log("Question summary is generating, starting polling...");
		} else {
			// Make a new request for question summary
			// console.log("Generating question summary...");
			const data = {
				instage_id: sessionData.instage_id,
				session_data: {
					setup_type: sessionData.setup_type,
					session_id: sessionData.session_id,
					setup_output: sessionData.setup_output,
					chat_history: sessionData.chat_history,
					audience: sessionData.audience,
					job_question_list: sessionData.job_question_list,
					question_summary_status: sessionData.question_summary_status,
					speech_data: sessionData.speech_data,
					short_summary: sessionData.short_summary,
					job_function: sessionData.job_function,
					experience_level: sessionData.experience_level,
					industry: sessionData.industry,
					main_responsibilities: sessionData.main_responsibilities,
					skills_required: sessionData.skills_required
				}
			};
			try {
				await axiosLimitedPost(`${url}/api/report/${sessionData.setup_type}/QuestionSummary`, data, 1, {}, 180000);
			} catch (error) {
				console.error("Error in requesting question summary:", error);
				return null;
			}
		}

		// Polling function for both new and generating question summaries
		return new Promise((resolve, reject) => {
			const params = { session_id: sessionData.session_id };
			const intervalId = setPollingInterval(async () => {
				try {
					const resp = await axiosLimitedGet(`${url}/api/report/${sessionData.setup_type}/QuestionSummary`, 1, { params });
					// If question summary is available, clear the interval and resolve
					if (resp.data.question_summary && resp.data.question_summary.length > 0) {
						clearPollingInterval(intervalId);
						// console.log("Question summary available.");
						resolve(resp.data.question_summary);
					}
				} catch (error) {
					clearPollingInterval(intervalId);
					console.error("Error during polling for question summary:", error);
					reject(error);
				}
			}, 3000);
		});
	};

	const getPreliminaries = async (sessionData) => {
		const preliminariesArray = sessionData.preliminaries;

		const apiPath = `/api/report/${sessionData.setup_type}`;

		// If feedback has already been generated, load it from session data
		if (sessionData.preliminaries_status === "generated" && preliminariesArray && preliminariesArray.length > 0) {
			// console.log("Loading preliminaries from session data");
			return (onComplete) => onComplete(preliminariesArray);
		}

		// If status is 'generating', skip the new request and go directly to polling
		if (sessionData.preliminaries_status === "generating") {
			// console.log("Preliminaries are generating, starting polling...");
		} else {
			// Otherwise, initiate a request for new preliminaries
			// console.log("Requesting new preliminaries...");
			const data = {
				instage_id: sessionData.instage_id,
				session_data: {
					session_id: sessionData.session_id,
					setup_type: sessionData.setup_type,
					chat_history: sessionData.chat_history,
					company: sessionData.company
				}
			};

			try {
				const resp1 = await axiosLimitedPost(`${url}${apiPath}/Preliminaries`, data, 1, {}, 60000);
				// console.log(resp1.data);

				if (resp1.data === "Not enough chat history") {
					console.log("Not enough chat history to generate preliminaries");
					return;
				}
			} catch (error) {
				console.error("Error in requesting preliminaries:", error);
				return;
			}
		}

		// Polling function for both new and generating preliminaries
		return (onComplete) => {
			const intervalId = setPollingInterval(async () => {
				try {
					const params = { session_id: sessionData.session_id };
					console.log("Checking preliminaries in database...");
					const resp = await axiosLimitedGet(`${url}${apiPath}/Preliminaries`, 1, { params });

					// If preliminaries are available, clear the interval and invoke onComplete
					if (resp.data.preliminaries && resp.data.preliminaries[0]) {
						clearPollingInterval(intervalId);
						onComplete(resp.data.preliminaries);
					}
				} catch (error) {
					console.error("Error during polling for preliminaries:", error);
					clearPollingInterval(intervalId);
				}
			}, 5000);
		};
	};

	async function getProcessPoints(sessionData) {
		const processPointsArray = ProcessPointsTranslated;

		const apiPath = `/api/report/${sessionData.setup_type}`;

		// If already generated, load it from session data
		if (sessionData.process_points_status === "generated" && processPointsArray && processPointsArray.length > 0) {
			// console.log("Loading process points from session data");
			return (onComplete) => onComplete(processPointsArray);
		}
		// If status is 'generating', skip the new request and go directly to polling
		if (sessionData.process_points_status === "generating") {
			// console.log("Process points are generating, starting polling...");
		} else {
			// Otherwise, initiate a request for new process points
			// console.log("Requesting new process points...");
			const data = {
				instage_id: sessionData.instage_id,
				session_data: {
					session_id: sessionData.session_id,
					setup_type: sessionData.setup_type,
					chat_history: sessionData.chat_history,
					intro_details: sessionData.intro_details,
					audience_role: sessionData.audience_role,
					user_role: sessionData.user_role,
					audience: sessionData.audience,
					company: sessionData.company,
					company_description_short: sessionData.company_description_short,
					backstory: sessionData.backstory,
					buyer_title: sessionData.buyer_title,
					call_notes: sessionData.call_notes,
					process_points: sessionData.process_points,
					learning_points: sessionData.learning_points,
					job_question_list: sessionData.job_question_list,
					short_summary: sessionData.short_summary,
					job_function: sessionData.job_function,
					experience_level: sessionData.experience_level,
					industry: sessionData.industry,
					main_responsibilities: sessionData.main_responsibilities,
					skills_required: sessionData.skills_required,
					speech_data: sessionData.speech_data,
					question_summary_status: sessionData.question_summary_status,
					setup_output: sessionData.setup_output,
					presentation_details: sessionData.presentation_details,
					topic: sessionData.topic,
					objective: sessionData.objective,
					visit_type: sessionData.visit_type,
					patient_summary: sessionData.patient_summary,
					patient_bio: sessionData.patient_bio,
					patient_medical: sessionData.patient_medical,
					buyer_situation_1: sessionData.buyer_situation_1,
					buyer_situation_2: sessionData.buyer_situation_2,
					buyer_need_1: sessionData.buyer_need_1,
					buyer_need_2: sessionData.buyer_need_2,
					buyer_objection_1: sessionData.buyer_objection_1,
					buyer_objection_2: sessionData.buyer_objection_2
				},
				...(sessionData.setup_type === "presentation" ? { process_points_array: presentation_process_points } : {})
			};
			try {
				const resp1 = await axiosLimitedPost(`${url}${apiPath}/ProcessPoints`, data, 1, {}, 60000);
				console.log(resp1.data);
				if (resp1.data === "Not enough chat history") {
					console.log("Not enough chat history to generate process points");
					return Promise.resolve("Not enough chat history");
				}
			} catch (error) {
				console.error("Error in requesting process points:", error);
				return Promise.reject(error);
			}
		}
		// Polling function for both new and generating process points
		return (onComplete) => {
			const intervalId = setPollingInterval(async () => {
				try {
					const params = { session_id: sessionData.session_id };
					console.log("Checking process points in database...");
					const resp = await axiosLimitedGet(`${url}${apiPath}/ProcessPoints`, 1, { params });
					// If process points are available, clear the interval and invoke onComplete
					if (resp.data.process_points && resp.data.process_points[0]) {
						clearPollingInterval(intervalId);
						onComplete(resp.data.process_points);
					}
				} catch (error) {
					console.error("Error during polling for process points:", error);
					clearPollingInterval(intervalId);
				}
			}, 5000);
		};
	}

	async function getLearningPoints(sessionData) {
		const learningPointsArray = LearningPointsTranslated; // sessionData.learningPoints
		// If already generated, load it from session data
		if (sessionData.learning_points_status === "generated" && learningPointsArray && learningPointsArray.length > 0) {
			console.log("Loading learning points from session data");
			return (onComplete) => onComplete(learningPointsArray);
		}
		// If status is 'generating', skip the new request and go directly to polling
		if (sessionData.learning_points_status === "generating") {
			console.log("Learning points are generating, starting polling...");
		} else {
			// Otherwise, initiate a request for new learning points
			console.log("Requesting new learning points...");
			const data = {
				instage_id: sessionData.instage_id,
				session_data: {
					session_id: sessionData.session_id,
					setup_type: sessionData.setup_type,
					chat_history: sessionData.chat_history,
					intro_details: sessionData.intro_details,
					audience_role: sessionData.audience_role,
					user_role: sessionData.user_role,
					audience: sessionData.audience,
					company: sessionData.company,
					company_description_short: sessionData.company_description_short,
					backstory: sessionData.backstory,
					buyer_title: sessionData.buyer_title,
					call_notes: sessionData.call_notes,
					process_points: sessionData.process_points,
					learning_points: sessionData.learning_points,
					job_question_list: sessionData.job_question_list,
					short_summary: sessionData.short_summary,
					job_function: sessionData.job_function,
					experience_level: sessionData.experience_level,
					industry: sessionData.industry,
					main_responsibilities: sessionData.main_responsibilities,
					skills_required: sessionData.skills_required,
					speech_data: sessionData.speech_data,
					question_summary_status: sessionData.question_summary_status,
					setup_output: sessionData.setup_output,
					presentation_details: sessionData.presentation_details,
					topic: sessionData.topic,
					objective: sessionData.objective
				}
			};
			try {
				const resp1 = await axiosLimitedPost(`${url}/api/report/${sessionData.setup_type}/LearningPoints`, data, 1, {}, 60000);
				console.log(resp1.data);
				if (resp1.data === "Not enough chat history") {
					console.log("Not enough chat history to generate learning points");
					return Promise.resolve("Not enough chat history");
				}
			} catch (error) {
				console.error("Error in requesting learning points:", error);
				return Promise.reject(error);
			}
		}
		// Polling function for both new and generating learning points
		return (onComplete) => {
			const intervalId = setPollingInterval(async () => {
				try {
					const params = { session_id: sessionData.session_id };
					console.log("Checking learning points in database...");
					const resp = await axiosLimitedGet(`${url}/api/report/${sessionData.setup_type}/LearningPoints`, 1, { params });
					// If learning points are available, clear the interval and invoke onComplete
					if (resp.data.learning_points && resp.data.learning_points[0]) {
						clearPollingInterval(intervalId);
						onComplete(resp.data.learning_points);
					}
				} catch (error) {
					console.error("Error during polling for learning points:", error);
					clearPollingInterval(intervalId);
				}
			}, 5000);
		};
	}

	const getQuestionResponses = async (sessionData) => {
		const { question_responses, question_responses_status } = sessionData;

		// If question responses already exist, return them
		if (sessionData.question_responses_status === "generated" && question_responses && question_responses.length > 0) {
			console.log("Loading question responses from session data");
			return (onComplete) => onComplete(question_responses);
		}

		// If status is 'generating', skip the new request and go directly to polling
		if (sessionData.question_responses_status === "generating") {
			console.log("Question responses are generating, starting polling...");
		} else {
			// Otherwise, initiate a request for new question responses
			console.log("Generating question responses...");
			const data = {
				instage_id: sessionData.instage_id,
				session_data: {
					audience: sessionData.audience,
					chat_history: sessionData.chat_history,
					company: sessionData.company,
					company_description_short: sessionData.company_description_short,
					backstory: sessionData.backstory,
					buyer_title: sessionData.buyer_title,
					call_notes: sessionData.call_notes,
					session_id: sessionData.session_id,
					setup_type: sessionData.setup_type,
					presentation_details: sessionData.presentation_details,
					topic: sessionData.topic,
					objective: sessionData.objective,
					audience_role: sessionData.audience_role,
					product: sessionData.product
				}
			};

			try {
				await axiosLimitedPost(`${url}/api/report/${sessionData.setup_type}/QuestionResponses`, data, 1, {}, 180000);
			} catch (error) {
				console.error("Error in requesting question responses:", error);
				return Promise.reject(error);
			}
		}

		// Polling function for generating question responses
		return (onComplete) => {
			const intervalId = setPollingInterval(async () => {
				try {
					const params = { session_id: sessionData.session_id };
					console.log("Checking question responses in database...");
					const resp = await axiosLimitedGet(`${url}/api/report/${sessionData.setup_type}/QuestionResponses`, 1, { params });
					// If question responses are available, clear the interval and resolve
					if (resp.data.question_responses && resp.data.question_responses.length > 0) {
						clearPollingInterval(intervalId);
						onComplete(resp.data.question_responses);
					}
				} catch (error) {
					console.error("Error during polling for question responses:", error);
					clearPollingInterval(intervalId);
				}
			}, 3000);
		};
	};

	const getGeneralSummary = async ({ currentSessionData }) => {
		let { summary_feedback } = currentSessionData;

		const apiPath = `/api/report/${sessionData.setup_type}`;

		// If already generated, load it from session data
		if (summary_feedback) {
			console.log("Loading summary feedback from session data");
			return summary_feedback;
		}
		// If status is 'generating', skip the new request and go directly to polling
		if (sessionData.summary_feedback_status === "generating") {
			console.log("Summary feedback are generating, starting polling...");
		} else {
			// Otherwise, initiate a request for new data
			console.log("Generating new summary feedback...");
			const data = {
				instage_id: sessionData.instage_id,
				session_data: {
					session_id: sessionData.session_id,
					setup_type: sessionData.setup_type,
					audience: sessionData.audience,
					chat_history: sessionData.chat_history,
					experience_type: sessionData.experience_type,
					experience_type_description: sessionData.experience_type_description,
					experience_details: sessionData.experience_details
				}
			};
			try {
				const resp = await axiosLimitedPost(`${url}${apiPath}/GeneralSummary`, data, 1, {}, 180000);
				if (resp.data === "Not enough chat history") {
					console.log("Not enough chat history to generate summary feedback");
					return Promise.resolve("Not enough chat history");
				}
			} catch (error) {
				console.error("Error in requesting summary feedback:", error);
				return Promise.reject(error);
			}
		}

		// Polling function for both new and generating
		return (onComplete) => {
			const intervalId = setPollingInterval(async () => {
				try {
					const params = { session_id: sessionData.session_id };
					console.log("Checking summary feedback in database...");
					const resp = await axiosLimitedGet(`${url}${apiPath}/GeneralSummary`, 1, { params });

					// If available, clear the interval and invoke onComplete
					if (resp.data.summary_feedback && resp.data.summary_feedback.what) {
						clearPollingInterval(intervalId);
						onComplete(resp.data.summary_feedback);
					}
				} catch (error) {
					console.error("Error during polling for summary feedback:", error);
					clearPollingInterval(intervalId);
				}
			}, 5000);
		};
	};

	const getCompetencySummary = async ({ currentSessionData }) => {
		let { competency_summary } = currentSessionData;

		const apiPath = `/api/report/${sessionData.setup_type}`;

		// If already generated, load it from session data
		if (competency_summary) {
			console.log("Loading competency summary from session data");
			return competency_summary;
		}
		// If status is 'generating', skip the new request and go directly to polling
		if (sessionData.competency_summary_status === "generating") {
			console.log("Competency summary already generating, starting polling...");
		} else {
			// Otherwise, initiate a request for new data
			console.log("Generating new competency summary...");
			const data = {
				instage_id: sessionData.instage_id,
				session_data: {
					session_id: sessionData.session_id,
					setup_type: sessionData.setup_type,
					audience: sessionData.audience,
					chat_history: sessionData.chat_history,
					experience_type: sessionData.experience_type,
					experience_type_description: sessionData.experience_type_description,
					experience_details: sessionData.experience_details,
					competency_list: sessionData.competency_list
				}
			};
			try {
				const resp = await axiosLimitedPost(`${url}${apiPath}/CompetencySummary`, data, 1, {}, 180000);
				console.log(resp.data);
				if (resp.data === "Not enough chat history") {
					console.log("Not enough chat history to generate competency summary");
					return Promise.resolve("Not enough chat history");
				}
			} catch (error) {
				console.error("Error in requesting competency summary:", error);
				return Promise.reject(error);
			}
		}

		// Polling function for both new and generating
		return (onComplete) => {
			const intervalId = setPollingInterval(async () => {
				try {
					const params = { session_id: sessionData.session_id };
					console.log("Checking competency summary in database...");
					const resp = await axiosLimitedGet(`${url}${apiPath}/CompetencySummary`, 1, { params });

					// If available, clear the interval and invoke onComplete
					if (resp.data.competency_summary) {
						clearPollingInterval(intervalId);
						onComplete(resp.data.competency_summary);
					}
				} catch (error) {
					console.error("Error during polling for competency summary:", error);
					clearPollingInterval(intervalId);
				}
			}, 5000);
		};
	};

	const updateState = (setState, value, loadingSetter = null) => {
		setState(value);
		if (loadingSetter) loadingSetter(false);
	};

	const fetchDataAndUpdateState = async (fetchFn, successFn, loadingSetter = null, errorFn = null) => {
		try {
			const result = await fetchFn();
			if (typeof result === "function") {
				// If the result is a function, it means polling is required
				const data = await new Promise((resolve) => {
					result((data) => {
						successFn(data);
						if (loadingSetter) loadingSetter(false);
						resolve(data);
					});
				});
				return data;
			} else {
				successFn(result);
				if (loadingSetter) loadingSetter(false);
				return result;
			}
		} catch (error) {
			console.error("Fetch error:", error);
			if (errorFn) errorFn(error);
			if (loadingSetter) loadingSetter(false);
			throw error;
		}
	};

	const setupHandlers = {
		interview: async (sessionData) => {
			let question_summary;
			await fetchDataAndUpdateState(
				() => getQuestionSummary(sessionData),
				(result) => {
					question_summary = matchAudioToSummary(sessionData, result);
					updateState(setQuestionSummary, question_summary, setQuestionSummaryLoading);

					if (sessionData.question_summary_override) {
						const override = sessionData.question_summary_override;
						updateState(setScore, override.total);
						updateState(setContentGrade, override.content);
						updateState(setDeliveryGrade, override.delivery);
						updateState(setCompletionGrade, override.completion);
						updateState(setOtherGrade, override.other);
					} else if (question_summary) {
						const filteredSummary = question_summary.filter((item) => item.type !== "Opening" && item.type !== "Closing");
						const summaryLength = filteredSummary.length;
						const calculateAverage = (field) => {
							if (summaryLength > 0) {
								return Math.round(filteredSummary.reduce((acc, item) => acc + (item.assessment?.[field] || 0), 0) / summaryLength);
							}
						};
						updateState(setScore, calculateAverage("total_grade"));
						updateState(setContentGrade, calculateAverage("content"));
						updateState(setDeliveryGrade, calculateAverage("delivery"));
						updateState(setCompletionGrade, calculateAverage("completion"));
						updateState(setOtherGrade, calculateAverage("other"));
					}
				},
				setQuestionSummaryLoading
			);

			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData, question_summary }),
				(result) => {
					updateState(setOutcome, result.outcome);
					updateState(setPositives, result.summary_feedback.positives);
					updateState(setImprovements, result.summary_feedback.improvements);
				},
				setEvaluationLoading
			);
		},
		discovery_spin: async (sessionData) => {
			const [preliminariesData, processPointsData] = await Promise.all([
				fetchDataAndUpdateState(
					() => getPreliminaries(sessionData),
					(result) => updateState(setPreliminaries, result, setPreliminariesLoading),
					setPreliminariesLoading
				),
				fetchDataAndUpdateState(
					() => getProcessPoints(sessionData),
					(result) => updateState(setProcessPoints, result, setProcessPointsLoading),
					setProcessPointsLoading
				)
			]);

			if (sessionData.grade_overrides) {
				const override = sessionData.grade_overrides[sessionData.setup_type];
				const preliminariesValue = override.preliminaries;
				const process1Value = override.spin1 + override.spin2 + override.budget;
				const overallValue = preliminariesValue + process1Value;
				updateState(setSpin1Grade, override.spin1);
				updateState(setSpin2Grade, override.spin2);
				updateState(setBudgetGrade, override.budget);
				updateState(setPreliminariesGrade, preliminariesValue);
				setRatings({
					currentSetupType: sessionData.setup_type,
					preliminariesValue,
					process1Value,
					process2Value: 0,
					overallValue
				});
			} else {
				const gradeKeys = ["spin1", "spin2", "budget"];
				const gradeValues = gradeKeys.map((key) => {
					if (key === "budget") {
						return calculateGrade(
							processPointsData.filter(
								(item) => (item.short && item.short.includes("budget")) || (item.short && item.short.includes("next_steps"))
							)
						);
					}
					return calculateGrade(processPointsData.filter((item) => item.short && item.short.includes(key)));
				});
				const [spin1Value, spin2Value, budgetValue] = gradeValues;
				const preliminariesValue = calculateGrade(preliminariesData);
				updateState(setPreliminariesGrade, preliminariesValue);
				updateState(setSpin1Grade, spin1Value);
				updateState(setSpin2Grade, spin2Value);
				updateState(setBudgetGrade, budgetValue);
				const process1Value = spin1Value + spin2Value + budgetValue;
				const overallValue = preliminariesValue + process1Value;
				setRatings({
					currentSetupType: sessionData.setup_type,
					preliminariesValue,
					process1Value,
					process2Value: 0,
					overallValue
				});
			}

			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData, preliminariesData, processPointsData }),
				(result) => {
					updateState(setOutcome, result.outcome);
					updateState(setSummary, result.summary_feedback.overall.summary);
				},
				setEvaluationLoading
			);
		},
		closing: async (sessionData) => {
			const [preliminariesData, processPointsData] = await Promise.all([
				fetchDataAndUpdateState(
					() => getPreliminaries(sessionData),
					(result) => updateState(setPreliminaries, result, setPreliminariesLoading),
					setPreliminariesLoading
				),
				fetchDataAndUpdateState(
					() => getProcessPoints(sessionData),
					(result) => updateState(setProcessPoints, result, setProcessPointsLoading),
					setProcessPointsLoading
				)
			]);

			if (sessionData.grade_overrides) {
				const override = sessionData.grade_overrides[sessionData.setup_type];
				const preliminariesValue = override.preliminaries;
				const process1Value = override.fab1 + override.fab2 + override.closing;
				const process2Value = override.objection1 + override.objection2;
				const overallValue = preliminariesValue + process1Value + process2Value;
				updateState(setFab1Grade, override.fab1);
				updateState(setFab2Grade, override.fab2);
				updateState(setObjection1Grade, override.objection1);
				updateState(setObjection2Grade, override.objection2);
				updateState(setClosingGrade, override.closing);
				updateState(setPreliminariesGrade, preliminariesValue);
				setRatings({
					currentSetupType: sessionData.setup_type,
					preliminariesValue,
					process1Value,
					process2Value,
					overallValue
				});
			} else {
				const gradeKeys = ["fab1", "fab2", "objection1", "objection2", "closing"];
				const gradeValues = Array.isArray(processPointsData)
					? gradeKeys.map((key) => calculateGrade(processPointsData.filter((item) => item.short && item.short.includes(key))))
					: gradeKeys.map(() => 0);
				const [fab1Value, fab2Value, objection1Value, objection2Value, closingValue] = gradeValues;
				const preliminariesValue = Array.isArray(preliminariesData) ? calculateGrade(preliminariesData) : 0;
				updateState(setPreliminariesGrade, preliminariesValue);
				updateState(setFab1Grade, fab1Value);
				updateState(setFab2Grade, fab2Value);
				updateState(setObjection1Grade, objection1Value);
				updateState(setObjection2Grade, objection2Value);
				updateState(setClosingGrade, closingValue);
				const process1Value = fab1Value + fab2Value + closingValue;
				const process2Value = objection1Value + objection2Value;
				const overallValue = preliminariesValue + process1Value + process2Value;
				setRatings({
					currentSetupType: sessionData.setup_type,
					preliminariesValue,
					process1Value,
					process2Value,
					overallValue
				});
			}
			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData, preliminariesData, processPointsData }),
				(result) => {
					updateState(setOutcome, result.outcome);
					updateState(setSummary, result.summary_feedback?.overall?.summary || "");
				},
				setEvaluationLoading
			);
		},
		intro: async (sessionData) => {
			const [processPointsData] = await Promise.all([
				fetchDataAndUpdateState(
					() => getProcessPoints(sessionData),
					(result) => updateState(setProcessPoints, result, setProcessPointsLoading),
					setProcessPointsLoading
				)
			]);

			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData, processPointsData }),
				(result) => {
					updateState(setOutcome, result.outcome);
					updateState(setPositives, result.summary_feedback.positives);
					updateState(setImprovements, result.summary_feedback.improvements);
				},
				setEvaluationLoading
			);
		},
		patient: async (sessionData) => {
			const [processPointsData] = await Promise.all([
				fetchDataAndUpdateState(
					() => getProcessPoints(sessionData),
					(result) => updateState(setProcessPoints, result, setProcessPointsLoading),
					setProcessPointsLoading
				)
			]);

			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData, processPointsData }),
				(result) => {
					updateState(setOutcome, result.outcome);
					updateState(setPositives, result.summary_feedback.positives);
					updateState(setImprovements, result.summary_feedback.improvements);
				},
				setEvaluationLoading
			);
		},
		discovery: async (sessionData) => {
			await Promise.all([
				fetchDataAndUpdateState(
					() => getProcessPoints(sessionData),
					(result) => updateState(setProcessPoints, result, setProcessPointsLoading),
					setProcessPointsLoading
				),
				fetchDataAndUpdateState(
					() => getLearningPoints(sessionData),
					(result) => updateState(setLearningPoints, result, setLearningPointsLoading),
					setLearningPointsLoading
				)
			]);

			await fetchDataAndUpdateState(
				() => getQuestionResponses(sessionData),
				(result) => updateState(setQuestionResponses, result, setQuestionResponsesLoading),
				setQuestionResponsesLoading
			);

			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData }),
				(result) => {
					updateState(setOutcome, result.outcome || "");
					updateState(setPositives, result.summary_feedback?.positives || "");
					updateState(setImprovements, result.summary_feedback?.improvements || "");
				},
				setEvaluationLoading
			);
		},
		presentation: async (sessionData) => {
			await fetchDataAndUpdateState(
				() => getProcessPoints(sessionData),
				(result) => updateState(setProcessPoints, result, setProcessPointsLoading),
				setProcessPointsLoading
			);

			await fetchDataAndUpdateState(
				() => getQuestionResponses(sessionData),
				(result) => updateState(setQuestionResponses, result, setQuestionResponsesLoading),
				setQuestionResponsesLoading
			);

			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData }),
				(result) => {
					updateState(setOutcome, result.outcome);
					updateState(setPositives, result.summary_feedback.positives);
					updateState(setImprovements, result.summary_feedback.improvements);
				},
				setEvaluationLoading
			);
		},
		retail: async (sessionData) => {
			await fetchDataAndUpdateState(
				() => getQuestionResponses(sessionData),
				(result) => updateState(setQuestionResponses, result, setQuestionResponsesLoading),
				setQuestionResponsesLoading
			);

			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData }),
				(result) => {
					updateState(setOutcome, result.outcome || "");
					updateState(setPositives, result.summary_feedback?.positives || "");
					updateState(setImprovements, result.summary_feedback?.improvements || "");
				},
				setEvaluationLoading
			);
		},
		freestyle: async (sessionData) => {
			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData }),
				(result) => {
					updateState(setOutcome, result.outcome || "");
					updateState(setPositives, result.summary_feedback?.positives || "");
					updateState(setImprovements, result.summary_feedback?.improvements || "");
				},
				setEvaluationLoading
			);
		},
		pitch: async (sessionData) => {
			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData }),
				(result) => {
					updateState(setOutcome, result.outcome || "");
					updateState(setPositives, result.summary_feedback?.positives || "");
					updateState(setImprovements, result.summary_feedback?.improvements || "");
				},
				setEvaluationLoading
			);
		},
		business_pitch: async (sessionData) => {
			await fetchDataAndUpdateState(
				() => getEvaluation({ currentSessionData: sessionData }),
				(result) => {
					updateState(setOutcome, result.outcome || "");
					updateState(setPositives, result.summary_feedback?.positives || "");
					updateState(setImprovements, result.summary_feedback?.improvements || "");
				},
				setEvaluationLoading
			);
		},
		reflection: async (sessionData) => {
			await fetchDataAndUpdateState(
				() => getGeneralSummary({ currentSessionData: sessionData }),
				(result) => {
					updateState(setGeneralSummary, {
						[pageText.What]: result.what || "",
						[pageText.SoWhat]: result.so_what || "",
						[pageText.NowWhat]: result.now_what || ""
					});
					updateState(setRecap, result.recap || "");
					updateState(setTakeaways, result.takeaways || "");
				},
				setGeneralSummaryLoading
			);

			if (sessionData.competency_list && sessionData.competency_list.length > 0) {
				await fetchDataAndUpdateState(
					() => getCompetencySummary({ currentSessionData: sessionData }),
					(result) => {
						updateState(setCompetencySummary, result);
					},
					setCompetencySummaryLoading
				);
			}
		}
	};

	const fetchReportData = async (sessionData) => {
		const { setup_type } = sessionData;
		if (setupHandlers[setup_type]) {
			await setupHandlers[setup_type](sessionData);
		} else {
			console.error("Unsupported setup type:", setup_type);
		}
	};

	useEffect(() => {
		fetchReportData(sessionData);
	}, [sessionData]);

	return (
		<TypingText refHolder={typingRefs}>
			<div className={r.reportSection}>
				{activeReportTab?.id === "overall" && (
					<Overall
						sessionData={sessionData}
						evaluationLoading={evaluationLoading}
						questionSummaryPercent={questionSummaryPercent}
						setQuestionSummaryPercent={setQuestionSummaryPercent}
						currentSetupConfig={currentSetupConfig}
						score={score}
						setScore={setScore}
						tempGrades={tempGrades}
						setTempGrades={setTempGrades}
						contentGrade={contentGrade}
						setContentGrade={setContentGrade}
						deliveryGrade={deliveryGrade}
						setDeliveryGrade={setDeliveryGrade}
						completionGrade={completionGrade}
						setCompletionGrade={setCompletionGrade}
						otherGrade={otherGrade}
						setOtherGrade={setOtherGrade}
						preliminariesGrade={preliminariesGrade}
						setPreliminariesGrade={setPreliminariesGrade}
						spin1Grade={spin1Grade}
						setSpin1Grade={setSpin1Grade}
						spin2Grade={spin2Grade}
						setSpin2Grade={setSpin2Grade}
						budgetGrade={budgetGrade}
						setBudgetGrade={setBudgetGrade}
						fab1Grade={fab1Grade}
						setFab1Grade={setFab1Grade}
						fab2Grade={fab2Grade}
						setFab2Grade={setFab2Grade}
						objection1Grade={objection1Grade}
						setObjection1Grade={setObjection1Grade}
						objection2Grade={objection2Grade}
						setObjection2Grade={setObjection2Grade}
						closingGrade={closingGrade}
						setClosingGrade={setClosingGrade}
						overallRating={overallRating}
						preliminariesRating={preliminariesRating}
						process1Rating={process1Rating}
						process2Rating={process2Rating}
						setRatings={setRatings}
						summary={summary}
						outcome={outcome}
						setOutcome={setOutcome}
						positives={positives}
						improvements={improvements}
					/>
				)}
				{activeReportTab?.id === "summary" && (
					<ReflectionSummary
						sessionData={sessionData}
						summary={generalSummary}
						summaryLoading={generalSummaryLoading}
						recap={recap}
						takeaways={takeaways}
					/>
				)}
				{activeReportTab?.id === "competencies" && (
					<ReflectionSummary sessionData={sessionData} summary={competencySummary} summaryLoading={competencySummaryLoading} />
				)}
				{activeReportTab?.id === "communication" && (
					<Communication
						typingRefs={typingRefs}
						sessionData={sessionData}
						speechMetrics={speechMetrics}
						setSpeechMetrics={setSpeechMetrics}
					/>
				)}
				{activeReportTab?.id === "question_summary" && (
					<div className={r.reportSectionContainer}>
						{questionSummaryLoading && (
							<div className={r.spinnerContainer}>
								<LoadingSpinner height={50} width={50} thickness={5} color="#00a9af" />
							</div>
						)}
						{!questionSummaryLoading && questionSummary && questionSummary.length > 0 && (
							<QuestionSummaryTable
								sessionData={sessionData}
								questionSummary={questionSummary}
								setQuestionSummary={setQuestionSummary}
							/>
						)}
					</div>
				)}
				{activeReportTab?.id === "process" && (
					<div className={r.reportSectionContainer}>
						{sessionData.setup_type !== "patient" && !["discovery_spin", "closing"].includes(sessionData.setup_type) && (
							<>
								<p className={r.reportSectionHeading}>{pageText.Process}</p>
								{processPointsLoading && <LoadingBar state={processPointsPercent} setter={setProcessPointsPercent} />}
								{!processPointsLoading && processPoints.length > 0 && <ProcessTable pointsArray={processPoints} />}
							</>
						)}
						{sessionData.setup_type === "patient" && (
							<>
								{processPointsLoading && <LoadingBar state={processPointsPercent} setter={setProcessPointsPercent} />}
								{!processPointsLoading && processPoints.length > 0 && (
									<>
										{console.log(processPoints)}
										{processPoints.some((item) => item.short && item.short.includes("opening")) && (
											<ProcessTable
												pointsArray={processPoints.filter((item) => item.short && item.short.includes("opening"))}
												title="Opening"
												altStatuses
											/>
										)}
										{processPoints.some((item) => item.short && item.short.includes("working")) && (
											<ProcessTable
												pointsArray={processPoints.filter((item) => item.short && item.short.includes("working"))}
												title="Working"
												altStatuses
											/>
										)}
										{processPoints.some((item) => item.short && item.short.includes("closing")) && (
											<ProcessTable
												pointsArray={processPoints.filter((item) => item.short && item.short.includes("closing"))}
												title="Closing"
												altStatuses
											/>
										)}
										{processPoints.some((item) => item.short && item.short.includes("communication")) && (
											<ProcessTable
												pointsArray={processPoints.filter((item) => item.short && item.short.includes("communication"))}
												title="Communication"
												altStatuses
											/>
										)}
									</>
								)}
							</>
						)}
						{["discovery_spin", "closing"].includes(sessionData.setup_type) && (
							<>
								{preliminariesLoading && (
									<LoadingBar state={preliminariesPercent} setter={setPreliminariesPercent} marginBottom="2rem" />
								)}
								{!preliminariesLoading && Array.isArray(preliminaries) && preliminaries.length > 0 && (
									<ProcessTable pointsArray={preliminaries} title="Opening" scores />
								)}
								{processPointsLoading && <LoadingBar state={processPointsPercent} setter={setProcessPointsPercent} />}
								{!processPointsLoading && Array.isArray(processPoints) && processPoints.length > 0 && (
									<>
										{processPoints.some((item) => item.short && item.short.includes("spin1")) && (
											<ProcessTable
												scores
												pointsArray={processPoints.filter((item) => item.short.includes("spin1"))}
												title="Situation 1"
											/>
										)}
										{processPoints.some((item) => item.short && item.short.includes("spin2")) && (
											<ProcessTable
												scores
												pointsArray={processPoints.filter((item) => item.short.includes("spin2"))}
												title="Situation 2"
											/>
										)}
										{processPoints.some((item) => item.short && item.short.includes("budget")) && (
											<ProcessTable
												scores
												pointsArray={processPoints.filter(
													(item) => item.short.includes("budget") || item.short.includes("next_steps")
												)}
												title="Budget & Closing"
											/>
										)}
										{processPoints.some((item) => item.short && item.short.includes("fab1")) && (
											<ProcessTable
												scores
												pointsArray={processPoints.filter((item) => item.short.includes("fab1"))}
												title="FAB-P 1"
											/>
										)}
										{processPoints.some((item) => item.short && item.short.includes("fab2")) && (
											<ProcessTable
												scores
												pointsArray={processPoints.filter((item) => item.short.includes("fab2"))}
												title="FAB-P 2"
											/>
										)}
										{processPoints.some((item) => item.short && item.short.includes("objection1")) && (
											<TypingText refHolder={typingRefs}>
												<ProcessTable
													scores
													pointsArray={processPoints.filter((item) => item.short.includes("objection1"))}
													title="Objection 1"
												/>
											</TypingText>
										)}
										{processPoints.some((item) => item.short && item.short.includes("objection2")) && (
											<TypingText refHolder={typingRefs}>
												<ProcessTable
													scores
													pointsArray={processPoints.filter((item) => item.short.includes("objection2"))}
													title="Objection 2"
												/>
											</TypingText>
										)}
										{processPoints.some((item) => item.short && item.short.includes("closing")) && (
											<ProcessTable
												scores
												pointsArray={processPoints.filter((item) => item.short.includes("closing"))}
												title="Closing"
											/>
										)}
									</>
								)}
							</>
						)}
						{sessionData.setup_type === "discovery" && (
							<>
								<p className={r.reportSectionHeading}>{pageText.ItemsToLearn}</p>
								{learningPointsLoading && <LoadingBar state={learningPointsPercent} setter={setLearningPointsPercent} />}
								{!learningPointsLoading && learningPoints.length > 0 && <ProcessTable pointsArray={learningPoints} />}
							</>
						)}
					</div>
				)}
				{activeReportTab?.id === "question_responses" && (
					<div className={r.reportSectionContainer}>
						{questionResponsesLoading && (
							<div className={r.spinnerContainer}>
								<LoadingSpinner height={50} width={50} thickness={5} color="#00a9af" />
							</div>
						)}
						{!questionResponsesLoading && questionResponses.length > 0 && Object.keys(questionResponses[0]).length > 0 && (
							<QuestionAnswerTable feedbackArray={questionResponses} />
						)}
						{!questionResponsesLoading && questionResponses.length > 0 && Object.keys(questionResponses[0]).length === 0 && (
							<p className={r.reportText}>{pageText.NoQuestionsFound}</p>
						)}
					</div>
				)}
			</div>
		</TypingText>
	);
}

function LoadingBar({ state, setter, marginBottom }) {
	useEffect(() => {
		const intervalId = setInterval(() => {
			setter((oldPercent) => {
				if (oldPercent >= 100) {
					clearInterval(intervalId);
					return 100;
				}
				return oldPercent + 100 / (2 * 60); // 2 minutes in seconds
			});
		}, 1000); // every second

		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<div className={r.loadingBar} style={{ marginBottom: marginBottom || "0" }}>
			<div className={r.loadingFill} style={{ width: `${state}%` }}>
				<p className={r.loadingText}>Generating...</p>
			</div>
		</div>
	);
}
