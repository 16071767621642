import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaUser } from "react-icons/fa/index.esm.js";
import { FiCircle, FiAlertTriangle, FiThumbsUp, FiThumbsDown, FiRotateCcw, FiUser } from "react-icons/fi/index.esm.js";
import { IoBookOutline, IoClose } from "react-icons/io5/index.esm.js";
import { IoMdCheckboxOutline } from "react-icons/io";
import { MdGeneratingTokens } from "react-icons/md";
import { AdminPortal } from "@frontegg/react";
// import { BsListCheck } from "react-icons/bs/index.esm.js";

import s from "../../styleModules/secondaryStyles.module.css";
import r from "../../styleModules/reportStyles.module.css";
// import styles2 from "../../styleModules/modalStyles.module.css";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import SidebarAccordion from "./SidebarAccordion.js";
import { LoadingSpinner } from "./LoadingSpinner.jsx";

import { cleanResponse } from "../UtilityFunctions/cleanResponse.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { decryptWithAES } from "../UtilityFunctions/DataUtility.js";
import { chatEventEmitter } from "../UtilityFunctions/eventEmitter.js";

import TranscriptV2 from "../Report/TranscriptV2.js";
import { DialogueFeedbackModal, useSendScore, ReplayConfirmModal } from "../Setup/SetupElements/SetupModals.js";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export default function Sidebar({ onSend, activeReport }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const sendScore = useSendScore();
	const {
		adminAccess,
		showSidebar,
		localUser,
		handleNavigate,
		setShowSidebar,
		sidebarContent,
		sidebarContentView,
		setSidebarContentView,
		setupData,
		activeSessionData,
		blinkButtons,
		setBlinkButtons,
		adminLinkList,
		activeAdminLink,
		setActiveAdminLink,
		tenantMetaData,
		userLanguage,
		translateDictText
	} = useContext(AppContext);

	const navigate = useNavigate();
	const location = useLocation();
	//  const textareaRef = useRef(null);

	const [chatMessagesNEW, setChatMessagesNEW] = useState([]);
	// const [selectedSimulationId, setSelectedSimulationId] = useState(-1);
	const [showFeedbackModal, setShowFeedbackModal] = useState(false);
	const [hoveredParagraphIndex, setHoveredParagraphIndex] = useState(null);
	const [feedbackStatus, setFeedbackStatus] = useState(false);
	const [allowSimControls, setAllowSimControls] = useState(false);
	const [selectedMetadata, setSelectedMetadata] = useState(null);
	const [selectedPromptId, setSelectedPromptId] = useState(null);
	const [isFeedbackClicked, setIsFeedbackClicked] = useState([]);

	const [selectedReplayMessage, setSelectedReplayMessage] = useState(null);
	const [showReplayConfirmModal, setShowReplayConfirmModal] = useState(false);
	// MOBILE DEVICE CONTROLS
	const sidebarRef = useRef(null);
	const typingRefs1 = useRef(null);
	const checklistBtnRef = useRef(null);
	const resourcesBtnRef = useRef(null);

	function togglePauseSim() {
		// console.log("side nav togglePauseSim");
		chatEventEmitter.emit("togglePause");
	}

	const handleFeedbackClick = (index, status, metadata) => {
		setIsFeedbackClicked((prevState) => {
			const newState = [...prevState];
			newState[index] = true;
			return newState;
		});
		setShowFeedbackModal(true);
		setFeedbackStatus(status);
		togglePauseSim();
		if (metadata) {
			setSelectedMetadata(metadata);
			setSelectedPromptId(metadata.promptLayer_request_id);
			sendScore(metadata.promptLayer_request_id, status);
		}
	};
	const handleReplayClick = (message) => {
		togglePauseSim();

		const data = {
			session_id: activeSessionData.session_id,
			speechId: message.speechId
		};
		setSelectedReplayMessage(data);
		console.log(data, message);
		setShowReplayConfirmModal(true);
	};
	const ReplaySection = async () => {
		const resp = await axiosLimitedPost(`${url}/api/sessionData/replay`, selectedReplayMessage, 1);
		console.log(resp);
		// refresh the page
		window.location.reload();
	};
	// Create a function that blinks the hover state of checklist and resources buttons three times
	const blink = (ref) => {
		if (ref.current === null || ref.current === undefined) return;
		ref.current.classList.add(s.sidebarBtnHover);
		setTimeout(() => {
			ref.current.classList.remove(s.sidebarBtnHover);
			setTimeout(() => {
				ref.current.classList.add(s.sidebarBtnHover);
				setTimeout(() => {
					ref.current.classList.remove(s.sidebarBtnHover);
					setTimeout(() => {
						ref.current.classList.add(s.sidebarBtnHover);
						setTimeout(() => {
							ref.current.classList.remove(s.sidebarBtnHover);
						}, 500);
					}, 500);
				}, 500);
			}, 500);
		}, 500);
	};

	// When blinkButtons is true, blink the checklist and resources buttons
	useEffect(() => {
		if (window.innerWidth > 768 && blinkButtons) {
			blink(checklistBtnRef);
			blink(resourcesBtnRef);
		}
		setBlinkButtons(false);
	}, [blinkButtons]);

	const transcriptRef = useRef();
	useEffect(() => {
		// console.log("Component mounted", transcriptRef.current);
		return () => {
			// console.log("Component will unmount", transcriptRef.current);
		};
	}, []);
	useEffect(() => {
		// console.log("Before update:", transcriptRef.current);

		if (transcriptRef.current) {
			// console.log("scrolling to bottom, ", transcriptRef.current, transcriptRef.current.scrollTop, transcriptRef.current.scrollHeight);
			transcriptRef.current.scrollTop = transcriptRef.current.scrollHeight;
			// console.log("After update:", transcriptRef.current);
		}
	}, [chatMessagesNEW]);

	useEffect(() => {
		// Function to update chatMessagesNEW when an 'updateChatMessages' event is emitted
		const handleAddMessage = (newMessage) => {
			setChatMessagesNEW(newMessage);
		};

		// Attach the event listener
		chatEventEmitter.on("updateChatMessages", handleAddMessage);
		// event listener for updateMediaControlsAllowed
		chatEventEmitter.on("updateMediaControlsAllowed", setAllowSimControls);

		// Cleanup: Remove the event listener when the component unmounts
		return () => {
			chatEventEmitter.off("updateChatMessages", handleAddMessage);
		};
	}, []);

	const interviewChecklist = [
		{
			short: "Open Confidently",
			long: "Introduce yourself and briefly explain your background."
		},
		{
			short: "Use STAR Method",
			long: "For behavioral questions, use the Situation, Task, Action, Result structure."
		},
		{
			short: "Tailor Responses",
			long: "Align answers with the role and company."
		},
		{
			short: "Ask Thoughtful Questions",
			long: "Ask questions that demonstrate your interest in the role and company."
		},
		{
			short: "Tone & Clarity",
			long: "Speak clearly and maintain an engaging tone."
		},
		{
			short: "Stay Positive",
			long: "Keep responses professional and positive."
		},
		{
			short: "Close Graciously",
			long: "Thank interviewer and ask about next steps."
		}
	];

	const presentationChecklist = [
		{
			short: "Start Strong",
			long: "Open with a hook to engage the audience."
		},
		{
			short: "Structure Clearly",
			long: "Organize content logically with a beginning, middle, and end."
		},
		{
			short: "Emphasize Key Points",
			long: "Highlight main ideas and support with examples or data."
		},
		{
			short: "Maintain Engagement",
			long: "Vary your tone and pace, and ask rhetorical or direct questions to keep the audience involved."
		},
		{
			short: "Close Effectively",
			long: "Summarize key takeaways and invite questions or actions."
		}
	];

	const retailChecklist = [
		{
			short: "Greet Warmly",
			long: "Start the interaction with a friendly greeting to set a positive tone."
		},
		{
			short: "Understand Needs",
			long: "Ask open-ended questions to discover the customer's needs and preferences."
		},
		{
			short: "Present Products",
			long: "Tailor the presentation of products or services to align with the customer's expressed needs."
		},
		{
			short: "Handle Objections",
			long: "Address any concerns or objections with empathy and knowledge."
		},
		{
			short: "Close the Sale",
			long: "Summarize key benefits and ask for the sale, offering assistance for any final questions or needs."
		}
	];

	async function manageBilling() {
		const data = {
			customerId: tenantMetaData.stripeId,
			locale: "en"
		};
		const key = process.env.REACT_APP_BACKEND_KEY;
		const config = {};
		config.headers = { Authorization: `Bearer ${key}` };
		const resp = await axiosLimitedPost(`${url}/api/stripe/create-customer-portal-session`, data, 1, {});
		const parsedData = JSON.parse(decryptWithAES(resp.data));
		handleNavigate(parsedData.url, true);
	}
	const disable_retry = activeSessionData?.setup_output?.disable_retry || false;

	return (
		<div
			id="sidebar"
			ref={sidebarRef}
			className={`${s.sidebar} ${showSidebar ? s.sidebarExpanded : ""} ${
				showSidebar && ["simulation", "report"].some((path) => location.pathname.includes(path)) ? s.sidebarExpandedSim : ""
			} `}
			style={{
				height: showSidebar
					? window.innerWidth > 1000 && ["simulation", "report"].some((path) => location.pathname.includes(path))
						? "calc(100vh - 180px)"
						: window.innerWidth <= 1000 && location.pathname.includes("report")
						? "calc(100vh - 100px)"
						: "calc(100vh - 7.5rem - 88px)"
					: "0",
				top: showSidebar
					? location.pathname.includes("simulation") || (window.innerWidth <= 1000 && location.pathname.includes("report"))
						? "5.75rem"
						: "7.5rem"
					: "0",
				right: showSidebar
					? location.pathname.includes("simulation") || (window.innerWidth <= 1000 && location.pathname.includes("report"))
						? "2%"
						: "7rem"
					: "0"
			}}

			// onTouchStart={handleTouchStart}
			// onTouchMove={handleTouchMove}
			// onTouchEnd={handleTouchEnd}
		>
			{showReplayConfirmModal && (
				<ReplayConfirmModal
					title="Go back to this point "
					subTitle="Your progress after this point will be lost"
					setShowConfirmModal={setShowReplayConfirmModal}
					handleModalCancel={() => {}}
					handleModalConfirm={ReplaySection}
				/>
			)}
			{sidebarContent === "AvaView" && (
				<>
					{/* <div className={s.sidebarBtnContainer}>
						<button
							type="button"
							className={`${s.sidebarBtn} ${sidebarContentView === "transcript" ? s.sidebarBtnActive : ""} ${
								activeSessionData === null ? s.sidebarBtnDisabled : ""
							}`}
							style={{ borderTopLeftRadius: "0.5rem", borderBottomLeftRadius: "0.5rem" }}
							disabled={activeSessionData === null}
							onClick={() => {
								setShowSidebar(sidebarContentView !== "transcript");
								setSidebarContentView("transcript");
							}}
						>
							<MdGeneratingTokens className={s.sidebarBtnIcon} />
							<span className={s.sidebarBtnLabel}>Transcript</span>
						</button>
						<button
							type="button"
							ref={checklistBtnRef}
							className={`${s.sidebarBtn} ${sidebarContentView === "checklist" ? s.sidebarBtnActive : ""}`}
							onClick={() => {
								setShowSidebar(sidebarContentView !== "checklist");

								setSidebarContentView("checklist");
							}}
						>
							<IoMdCheckboxOutline className={s.sidebarBtnIcon} />
							<span className={s.sidebarBtnLabel}>Checklist</span>
						</button>
						<button
							type="button"
							ref={resourcesBtnRef}
							className={`${s.sidebarBtn} ${sidebarContentView === "resources" ? s.sidebarBtnActive : ""}`}
							style={{ borderTopRightRadius: "0.5rem", borderBottomRightRadius: "0.5rem" }}
							onClick={() => {
								setShowSidebar(sidebarContentView !== "resources");

								setSidebarContentView("resources");
							}}
						>
							<IoBookOutline className={s.sidebarBtnIcon} />
							<span className={s.sidebarBtnLabel}>Resources</span>
						</button>
					</div> */}
					<div ref={transcriptRef} key="sidebarContainer" className={s.sidebarContentContainer}>
						{sidebarContentView === "transcript" && (
							<div key="transcriptContainer" className={s.sidebarContentSection}>
								{activeReport?.audience[0]}
								{chatMessagesNEW.map((message, index) => {
									const messageContent = cleanResponse(message.content);
									const splitContent = messageContent.split(":");
									const label = splitContent[0];
									let content = splitContent[1];
									// remove start and end " from content
									content = content.slice(1, -1);
									content = content.slice(1);
									const messageid = `message ${index}`;
									const icon =
										message.role === "assistant" ? (
											<img
												className={r.transcriptPortrait}
												src={`https://avatarportraits.s3.ca-central-1.amazonaws.com/${label || "John"}.png`}
												alt="portrait"
											/>
										) : (
											<FaUser className={r.transcriptUserIcon} />
										);

									return (
										<div key={messageid} className={s.messageContainer} onMouseEnter={() => setHoveredParagraphIndex(index)}>
											<div className={`${s.avaChatMessage} ${message.role === "assistant" ? s.assistant : s.user}`}>
												<div style={{ display: "flex", justifyContent: "space-between", paddingBottom: "10px" }}>
													<div className={r.speakerIcon}>
														{icon}
														{label}
													</div>
													{allowSimControls &&
														message.role === "assistant" && ( // hoveredParagraphIndex === index &&
															<span>
																{!isFeedbackClicked[index] && (
																	<FiThumbsUp
																		className={r.thumbsUpIcon}
																		onClick={() => {
																			handleFeedbackClick(index, true, message.metadata);
																		}}
																	/>
																)}
																{!isFeedbackClicked[index] && (
																	<FiThumbsDown
																		className={r.thumbsDownIcon}
																		onClick={() => {
																			handleFeedbackClick(index, false, message.metadata);
																		}}
																	/>
																)}
																{!disable_retry && index !== 0 && (
																	<div className={r.tooltipContainer}>
																		<FiRotateCcw
																			className={r.thumbsUpIcon}
																			onClick={() => {
																				handleReplayClick(message);
																			}}
																		/>
																		<span style={{ width: "90px" }} className={r.tooltipText}>
																			Restart
																			<br />
																			Here
																		</span>
																	</div>
																)}

																{showFeedbackModal && (
																	<DialogueFeedbackModal
																		instage_id={localUser.id}
																		isAdmin={adminAccess}
																		setShowFeedbackModal={setShowFeedbackModal}
																		feedbackStatus={feedbackStatus}
																		selectedPromptId={selectedMetadata.promptLayer_request_id}
																		metadata={selectedMetadata}
																	/>
																)}
															</span>
														)}
												</div>

												{content}
											</div>
										</div>
									);
								})}
							</div>
						)}
						{sidebarContentView === "checklist" && (
							<>
								{/* <div className={s.sidebarContentHeader}>
									<p className={s.sidebarContentHeading}>Checklist</p>
								</div> */}

								{setupData && (
									<div className={s.sidebarContentSection}>
										{setupData && setupData.setup_objective && (
											<div className={s.checklistSection}>
												<p className={s.checklistTitle}>Objective</p>
												<div className={s.checklistTextContainer}>
													<FiCircle className={s.checklistIcon} />
													<p className={s.checklistText}>
														{setupData
															? (
																	setupData.setup_objective.charAt(0).toUpperCase() +
																	setupData.setup_objective.slice(1)
															  ).replace(".", "")
															: null}
													</p>
												</div>
											</div>
										)}

										{setupData.process_points && setupData.process_points.length > 0 && (
											<div className={s.checklistSection}>
												<p className={s.checklistTitle}>Process</p>
												{setupData.process_points.map((item, index) => {
													return (
														<div key={index} className={s.checklistItem}>
															<div className={s.checklistTextContainer}>
																<FiCircle className={s.checklistIcon} />
																<p className={s.checklistText}>{item.short}</p>
															</div>
														</div>
													);
												})}
											</div>
										)}

										{setupData.learning_points && setupData.learning_points.length > 0 && (
											<div className={s.checklistSection}>
												<p className={s.checklistTitle}>Items to Learn</p>
												{setupData.learning_points.map((item, index) => {
													return (
														<div key={index} className={s.checklistItem}>
															<div className={s.checklistTextContainer}>
																<FiCircle className={s.checklistIcon} />
																<p className={s.checklistText}>{item.short}</p>
															</div>
														</div>
													);
												})}
											</div>
										)}

										{setupData.setup_type.includes("interview") && (
											<div className={s.checklistSection}>
												<p className={s.checklistTitle}>Reminders</p>
												{interviewChecklist.map((item, index) => {
													return (
														<div key={index} className={s.checklistItem}>
															<div className={s.checklistTextContainer}>
																<FiCircle className={s.checklistIcon} />
																<p className={s.checklistText}>{item.short}</p>
															</div>
															<p className={s.checklistTextSmall}>{item.long}</p>
														</div>
													);
												})}
											</div>
										)}

										{setupData.setup_type.includes("presentation") && (
											<div className={s.checklistSection}>
												<p className={s.checklistTitle}>Reminders</p>
												{presentationChecklist.map((item, index) => {
													return (
														<div key={index} className={s.checklistItem}>
															<div className={s.checklistTextContainer}>
																<FiCircle className={s.checklistIcon} />
																<p className={s.checklistText}>{item.short}</p>
															</div>
															<p className={s.checklistTextSmall}>{item.long}</p>
														</div>
													);
												})}
											</div>
										)}

										{setupData.setup_type.includes("retail") && (
											<div className={s.checklistSection}>
												<p className={s.checklistTitle}>Reminders</p>
												{retailChecklist.map((item, index) => {
													return (
														<div key={index} className={s.checklistItem}>
															<div className={s.checklistTextContainer}>
																<FiCircle className={s.checklistIcon} />
																<p className={s.checklistText}>{item.short}</p>
															</div>
															<p className={s.checklistTextSmall}>{item.long}</p>
														</div>
													);
												})}
											</div>
										)}
									</div>
								)}
							</>
						)}
						{sidebarContentView === "resources" && (
							<>
								{/* <div className={s.sidebarContentHeader}>
									<p className={s.sidebarContentHeading}>Resources</p>
								</div> */}

								<div className={s.sidebarContentSection}>
									{setupData && setupData.setup_type !== "interview" && (
										<div className={s.sidebarCallout}>
											<FiAlertTriangle className={s.sidebarCalloutIcon} />
											<p className={s.sidebarCalloutText}>If you don't know how to answer a question, just improvise!</p>
										</div>
									)}
									{/* {setupData && setupData.setup_type === "interview" && (
									<div className={s.sidebarCallout}>
										<FiAlertTriangle className={s.sidebarCalloutIcon} />
										<p className={s.sidebarCalloutText}>This interview simulation is not a true representation of any mentioned company's process.</p>
									</div>
									)} */}

									{setupData && setupData.setup_type === "retail" && (
										<div className={s.sidebarCallout}>
											<FiAlertTriangle className={s.sidebarCalloutIcon} />
											<p className={s.sidebarCalloutText}>If they ask to “see” something, just roleplay!</p>
										</div>
									)}

									{setupData && (
										<>
											{setupData.job_summary !== undefined && (
												<SidebarAccordion
													refHolder={typingRefs1}
													title="Job Summary"
													job_summary={setupData && setupData.job_summary ? setupData.job_summary : ""}
													initialActive={false}
												/>
											)}
											{setupData.call_notes !== undefined && (
												<SidebarAccordion
													refHolder={typingRefs1}
													title="Intel About Buyer"
													content={setupData && setupData.call_notes ? setupData.call_notes.split("\n") : ""}
													description={false}
													initialActive={false}
												/>
											)}
											{setupData.product_list && (
												<SidebarAccordion
													refHolder={typingRefs1}
													title="Our Products & Services"
													content={setupData && setupData.product_list ? setupData.product_list : ""}
													description={true}
													initialActive={false}
												/>
											)}
											{setupData.company_description_short && (
												<SidebarAccordion
													refHolder={typingRefs1}
													title="Our Company Info"
													content={
														setupData && setupData.company_description_short ? setupData.company_description_short : ""
													}
													initialActive={false}
												/>
											)}
											{setupData.value_prop && (
												<SidebarAccordion
													refHolder={typingRefs1}
													title="Our Value Proposition"
													content={setupData && setupData.value_prop ? setupData.value_prop : ""}
													description={true}
													initialActive={false}
												/>
											)}
											{setupData.process_points && (
												<SidebarAccordion
													refHolder={typingRefs1}
													title="Our Process"
													contentObject={setupData && setupData.process_points ? setupData.process_points : ""}
													description={true}
													initialActive={false}
												/>
											)}
											{setupData.features && (
												<SidebarAccordion
													refHolder={typingRefs1}
													title="Our Features"
													content={setupData && setupData.features ? setupData.features : ""}
													description={true}
													initialActive={false}
												/>
											)}
											{setupData.CompetitorsAndDifferentiation && (
												<SidebarAccordion
													refHolder={typingRefs1}
													title="Competitors & Differentiation"
													contentObject={
														setupData && setupData.CompetitorsAndDifferentiation
															? setupData.CompetitorsAndDifferentiation
															: ""
													}
													description={true}
													initialActive={false}
												/>
											)}
										</>
									)}
									{/* {activeSessionData && (
										<SidebarAccordion
											refHolder={typingRefs1}
											title="Customer Backstory"
											content={activeSessionData ? activeSessionData.backstory : ""}
											initialActive={false}
										/>
									)} */}
								</div>
							</>
						)}
					</div>
				</>
			)}
			{sidebarContent === "ReportsView" && activeReport && activeReport.transcript_data && (
				<div className={s.sidebarContentContainer}>
					{activeReport ? (
						<>
							{/* {console.log("Active Report: ", activeReport)}
							<p>TEST: {activeReport.deepgram_data?.videofileKey}</p>
							<p>TEST: {activeReport.deepgram_data?.fileKey}</p> */}
							<TranscriptV2
								disable_retry={disable_retry}
								fileKey={activeReport.deepgram_data?.fileKey}
								videofileKey={activeReport.deepgram_data?.videofileKey}
								audioFileExists={activeReport.deepgram_data?.audioFileExists}
								videoFileExists={activeReport.deepgram_data?.videoFileExists}
								audience={activeReport.audience}
								transcriptv3={activeReport.transcriptv3}
								setup_type={activeReport.setup_type}
								audioFiles={activeReport.audio_files}
								session_id={activeReport.session_id}
								simulation_fk={activeReport.simulation_fk}
							/>
						</>
					) : (
						<div className={s.spinnerContainer}>
							<LoadingSpinner height={50} width={50} thickness={5} color={"#00a9af"} />
						</div>
					)}
				</div>
			)}
			{sidebarContent === "AdminView" && (
				<div className={s.sidebarContentContainer}>
					{Object.entries(adminLinkList).map(([linkText, linkData]) => (
						<div
							key={linkText}
							className={
								window.location.href.includes("admin") && activeAdminLink === linkText
									? `${s.adminLinkCard} ${s.activeAdminLinkCard}`
									: s.adminLinkCard
							}
							onClick={() => {
								if (linkData.component) {
									setActiveAdminLink(linkText);
									handleNavigate(`/admin`);
								} else if (linkData.billing) {
									manageBilling();
								} else {
									handleNavigate("#/admin-box/users", true);
									AdminPortal.show();
								}
							}}
						>
							{linkData.icon}
							{linkText}
						</div>
					))}
				</div>
			)}
		</div>
	);
}
