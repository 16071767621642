const Competencies = [
	{
		name: "Collaboration",
		label: "Collaboration",
		description:
			"A person that is strong in collaboration can work effectively with others, proactively seek the opinions, ideas and expertise of others, give credit to others for their ideas, strengths and contributions, and seek to improve working relationships.",
		examples: [
			"Work effectively with others",
			"Proactively seek the opinions, ideas and expertise of others",
			"Give credit to others for their ideas, strengths and contributions"
		],
		group: "Communication and collaboration"
	},
	{
		name: "Communication",
		label: "Communication",
		description:
			"A person with strong communication can communicate ideas effectively for audience and circumstances, use clear and concise language, and listen attentively to others.",
		examples: ["Communicate ideas effectively for audience and circumstances", "Use clear and concise language", "Listen attentively to others"],
		group: "Communication and collaboration"
	},
	{
		name: "Creativity",
		label: "Creativity",
		description: "The capacity to generate novel ideas, think outside the box, and develop innovative solutions to problems.",
		examples: ["Generate novel ideas", "Think outside the box", "Develop innovative solutions to problems"],
		group: "Self-management and personal development"
	},
	{
		name: "Critical Thinking",
		label: "Critical Thinking",
		description:
			"A person with critical thinking can identify multiple feasible options or solutions to problems, make evidence-based decisions and/or recommendations, seek to understand the 'big picture' root problem or purpose for actions, and explore the implications of proposed solutions to problems.",
		examples: [
			"Identify multiple feasible options or solutions to problems",
			"Make evidence-based decisions and/or recommendations",
			"Seek to understand the 'big picture' root problem or purpose for actions"
		],
		group: "Innovation and Problem Solving"
	},
	{
		name: "Implementation",
		label: "Implementation",
		description:
			"A person that practices implementation can identify concrete steps necessary to complete projects, manage their own deadlines, and fulfill responsibilities.",
		examples: ["Identify concrete steps necessary to complete projects", "Manage their own deadlines", "Fulfill responsibilities"],
		group: "Innovation and Problem Solving"
	},
	{
		name: "Information and Data Literacy",
		label: "Information and Data Literacy",
		description:
			"In today's world, big data is bigger than ever. No matter your industry, it's what drives decisions and bottom lines. The future of work will require individuals and organizations to identify and synthesize 'good' data to form meaningful conclusions.",
		examples: ["Identify and synthesize 'good' data", "Form meaningful conclusions from data", "Utilize data to drive decisions"],
		group: "Technology and Digital Proficiency"
	},
	{
		name: "Leadership",
		label: "Leadership",
		description:
			"The ability to guide, inspire, and motivate others towards a common goal, while demonstrating integrity, empathy, and the capacity to make decisions in the best interest of the team or organization.",
		examples: [
			"Guide and inspire others towards a common goal",
			"Demonstrate integrity and empathy",
			"Make decisions in the best interest of the team or organization"
		],
		group: "Self-management and personal development"
	},
	{
		name: "Lifelong Learning",
		label: "Lifelong Learning",
		description:
			"A person focused on lifelong learning and career development can make plans to achieve learning goals, explore how personal values and interests align with occupational demands, tasks, goals and environments, seek learning opportunities, both formal and informal, and take the initiative to connect with others about career opportunities.",
		examples: [
			"Make plans to achieve learning goals",
			"Explore how personal values and interests align with occupational demands, tasks, goals and environments",
			"Seek learning opportunities, both formal and informal"
		],
		group: "Self-management and personal development"
	},
	{
		name: "Problem-solving",
		label: "Problem-solving",
		description:
			"The ability to break down intricate problems, evaluate multiple sources of information, and develop and execute effective solutions.",
		examples: ["Break down intricate problems", "Evaluate multiple sources of information", "Develop and execute effective solutions"],
		group: "Innovation and Problem Solving"
	},
	{
		name: "Resilience",
		label: "Resilience",
		description:
			"The ability to adapt and maintain composure in the face of challenges, setbacks, and changing circumstances while managing stress in a healthy manner.",
		examples: ["Adapt to changing circumstances", "Maintain composure under pressure", "Bounce back from setbacks"],
		group: "Self-management and personal development"
	},
	{
		name: "Self-Assessment",
		label: "Self-Assessment",
		description:
			"A person that practices self-assessment can acknowledge the limits of their own knowledge, skills and abilities, seek feedback on performance, and have an accurate sense of what they contribute.",
		examples: [
			"Acknowledge the limits of their own knowledge, skills and abilities",
			"Seek feedback on performance",
			"Have an accurate sense of what they contribute"
		],
		group: "Self-management and personal development"
	},
	{
		name: "Self-Management",
		label: "Self-Management",
		description:
			"A person that can successfully self-manage can approach workplace situations with a positive attitude, manage their own reactions/emotions, cope with workplace pressures, incorporate feedback into workplace performance, and demonstrate integrity and ethics in the workplace.",
		examples: ["Approach workplace situations with a positive attitude", "Manage their own reactions/emotions", "Cope with workplace pressures"],
		group: "Self-management and personal development"
	},
	{
		name: "Technological Agility",
		label: "Technological Agility",
		description:
			"With artificial intelligence, robots and automation on the rise, grasping and adapting to new technologies will be increasingly important in our future workplaces. Advocating for the use of new tech and incorporating it into our daily work can help individuals and organizations stay one step ahead.",
		examples: ["Grasp and adapt to new technologies", "Advocate for the use of new tech", "Incorporate new technologies into daily work"],
		group: "Technology and Digital Proficiency"
	}
];

export default Competencies;
