/**
 * Class representing the data related to Avatar's speech.
 * @property {Array} audioQueue - The queue of audio data.
 * @property {Array} wordQueue - The queue of words.
 * @property {Array} visemeQueue - The queue of visemes.
 * @property {Array} animation - The array of animations.
 * @property {string} text - The text of the speech.
 * @property {number} speechId - The ID of the speechData.
 * @property {string} gptId - The gptId of the speechData.
 * @property {boolean} generationComplete - Flag to check if the generation is complete.
 * @property {boolean} playing - Flag to check if the speech is currently playing.
 * @property {boolean} played - Flag to check if the speech has been played.
 * @property {number} playbackTime - The playback time of the speech.
 * @property {AudioBufferSourceNode} source - The source responsible for playback.
 * @property {string} spokenText - The spoken text of the speech.
 * @property {number} pauseTime - The pause time of the speech.
 * @property {any} synthesisError - The error occurred during synthesis.
 * @property {Date} createdTime - The time when the speech data was created.
 * @property {Date} startTime - The time when the speech started.
 * @property {boolean} synthesisStarted - Flag to check if the synthesis has started.
 * @property {boolean} synthesisCompleted - Flag to check if the synthesis has completed.
 * @property {Date} synthesisStartedTime - The time when the synthesis started.
 * @property {Date} endTime - The time when the speech ended.
 */
class AvatarSpeechData {
	/**
	 * Create a new AvatarSpeechData object.
	 */
	constructor(gptId, text) {
		/**
		 * The queue of audio data.
		 * @type {Array}
		 */
		this.audioQueue = [];

		/**
		 * The queue of words.
		 * @type {Array}
		 */
		this.wordQueue = [];

		/**
		 * The queue of visemes.
		 * @type {Array}
		 */
		this.visemeQueue = [];

		/**
		 * The array of animations.
		 * @type {Array}
		 */
		this.animation = [];

		/**
		 * The text of the speech.
		 * @type {string}
		 */
		this.text = text;

		/**
		 * The spoken text of the speech.
		 * @type {string}
		 */
		this.spokenText = "";
		/**
		 * The ID of the speech.
		 * @type {number}
		 */
		this.speechId = 0;

		/**
		 * The gptId of the speechData.
		 * @type {number}
		 */
		this.gptId = gptId;

		/**
		 * Flag to check if the generation is complete.
		 * @type {boolean}
		 */
		this.generationComplete = false;

		/**
		 * Flag to check if the speech is currently playing.
		 * @type {boolean}
		 */
		this.playing = false;

		/**
		 * Flag to check if the speech has been played.
		 * @type {boolean}
		 */
		this.played = false;

		/**
		 * The playback time of the speech.
		 * @type {number}
		 */
		this.playbackTime = 0;

		/**
		 * The pause time of the speech.
		 * @type {number}
		 */
		this.pauseTime = 0;
		/**
		 * source responsible for playback
		 * @type {AudioBufferSourceNode}
		 */
		this.source = null;
		/**
		 * The error occurred during synthesis.
		 * @type {any}
		 */
		this.synthesisError = null;
		/**
		 * The time when the speech data was created.
		 * @type {Date}
		 */
		this.createdTime = Date.now();
		/**
		 * The time when the speech started.
		 * @type {Date}
		 */
		this.startTime = null;
		/**
		 * Flag to check if the synthesis has started.
		 * @type {boolean}
		 */
		this.synthesisStarted = false;
		/**
		 * Flag to check if the synthesis has completed.
		 * @type {boolean}
		 */
		this.synthesisCompleted = false;

		/**
		 * The time when the synthesis started.
		 * @type {Date}
		 */
		this.synthesisStartedTime = -1;
		/**
		 * The time when the synthesis finished.
		 * @type {Date}
		 */
		this.synthesisCompleteTime = -1;
		/**
		 * The time when the speech ended.
		 * @type {Date}
		 */
		this.endTime = -1;
		this.synthesisDuration = -1;
		this.timeStamp = null;
		this.attempt = 1;
	}

	startGeneration() {
		this.generationComplete = false;
		this.synthesisStarted = true;
		this.synthesisStartedTime = Date.now();
	}

	finishGeneration() {
		this.generationComplete = true;
		this.synthesisCompleted = true;

		this.synthesisCompleteTime = Date.now();
	}

	onAudioStart() {
		this.startTime = Date.now();
		this.playing = true;
	}

	onAudioEnd() {
		// this.clearData()
		if (this.endTime === -1) {
			this.endTime = Date.now();

			this.playing = false;
			this.played = true;
			this.spokenText = this.text; // TODO Maybe remove
			return true;
		}
		return false;
	}

	/**
	 * Function to clear the arrays and queue to save on RAM.
	 */
	clearData() {
		// console.log("clear speech data")
		this.playing = false;
		// this.endTime = Date.now();

		this.audioQueue = [];
		this.wordQueue = [];
		this.visemeQueue = [];
		this.animation = [];
	}

	/**
	 * Static function to convert an object to an instance of AvatarSpeechData.
	 * @param {Object} data - The data object.
	 * @return {AvatarSpeechData} The instance of AvatarSpeechData.
	 */
	static fromObject(data) {
		const avatarSpeechData = new AvatarSpeechData(data.gptId, data.text);
		Object.assign(avatarSpeechData, data);
		// Manually convert date fields back to Date objects
		if (data.createdTime) avatarSpeechData.createdTime = new Date(data.createdTime);
		if (data.startTime) avatarSpeechData.startTime = new Date(data.startTime);
		if (data.endTime) avatarSpeechData.endTime = new Date(data.endTime);
		if (data.synthesisStartedTime) avatarSpeechData.synthesisStartedTime = new Date(data.synthesisStartedTime);
		if (data.synthesisCompleteTime) avatarSpeechData.synthesisCompleteTime = new Date(data.synthesisCompleteTime);
		if (data.synthesisCompleteTime && data.synthesisStartedTime) {
			avatarSpeechData.synthesisDuration = avatarSpeechData.synthesisCompleteTime - avatarSpeechData.synthesisStartedTime;
		}
		return avatarSpeechData;
	}
}

export default AvatarSpeechData;
