import React, { useRef, useState, useCallback, useContext, useEffect } from "react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import Slider from "react-slick";
import styles from "../../styleModules/generateStyles.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import monsterLogo from "../../images/monsterLogo.png";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

export default function JobOptions({ job_title, options, setSelectedJobTitle, handleDetailsChange }) {
	const { axiosLimitedGet } = useAxiosLimited();
	const { setSetupData, userLanguage, translateDictText, performTranslation } = useContext(AppContext);
	let sliderRef = useRef(null);
	const [selectedOption, setSelectedOption] = useState(null);

	// Static Translation
	const LocalLanguageDict = {
		CustomJobTitle: "Custom Job Title",
		PostedJobs: "Posted Jobs"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// Usage
	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	const memoizedHandleDetailsChange = useCallback(handleDetailsChange, []);

	const next = () => {
		sliderRef.slickNext();
	};
	const previous = () => {
		sliderRef.slickPrev();
	};

	const defaultOption = {
		title: job_title,
		companyName: "Company - TBD",
		location: "Location - TBD"
	};

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: Math.min(3, options.length),
		swipeToSlide: true,
		initialSlide: 0,
		arrows: false
	};

	const htmlToPlainText = (html) => {
		const tempElement = document.createElement("div");
		tempElement.innerHTML = html;

		const text = tempElement.innerHTML
			.replace(/&nbsp;/g, " ") // Replace &nbsp; with a space
			.replace(/<\/p>/g, "\n\n") // Add new lines after each paragraph
			.replace(/<\/li>/g, "\n") // Add new lines after each list item
			.replace(/<li>/g, "- ") // Convert list items to dashes
			.replace(/<br>/g, "\n") // Convert line breaks to new lines
			.replace(/<strong>/g, "*") // Convert opening strong tags to asterisks
			.replace(/<\/strong>/g, "*") // Convert closing strong tags to asterisks
			.replace(/\n\s*\n/g, "\n\n") // Replace multiple newlines with two newlines
			.replace(/&amp;/g, "&") // Replace &amp; with &
			.replace(/<\/?[^>]+(>|$)/g, ""); // Final step - Remove remaining HTML tags
		return text.trim();
	};

	const handleOptionClick = async (option) => {
		setSelectedJobTitle({ label: option.title, value: option.title });
		memoizedHandleDetailsChange({ target: { value: "Loading job details..." } });
		setSelectedOption(option);

		const response = await axiosLimitedGet(`${url}/api/monster/job-posting`, 1, {
			params: { postingId: option.postingId }
		});
		// console.log(response.data);
		const { jobBody, sanitizedJobBody, monsterUrl, combinedJobViewUrl } = response.data;

		const plainTextContent = htmlToPlainText(jobBody);
		const finalContent = `${plainTextContent}\n\nTo apply to this job, use this link: ${monsterUrl}`;

		memoizedHandleDetailsChange({ target: { value: finalContent } });
		setSetupData((prevSetupData) => ({
			...prevSetupData,
			job_title: option.title,
			monsterUrl
		}));
	};

	return (
		<div className={`${styles.jobOptions} ${job_title !== "" && options?.length > 0 ? styles.show : ""}`}>
			<div className={styles.jobOptionsHeadingContainer}>
				<h2 className={styles.jobOptionsDefaultHeading}>{pageText.CustomJobTitle}</h2>
				<h2 className={styles.jobOptionsMonsterHeading}>{pageText.PostedJobs}</h2>
			</div>
			<div className={styles.jobCarouselContainer}>
				<div
					className={`${styles.jobCard} ${styles.defaultCard} ${selectedOption === null ? styles.selectedJob : ""}`}
					onClick={() => {
						setSelectedOption(null);
						memoizedHandleDetailsChange({ target: { value: "" } });
						setSetupData((prevSetupData) => ({
							...prevSetupData,
							monsterUrl: ""
						}));
					}}
					onKeyDown={(event) => {
						if (event.key === "Enter" || event.key === " ") {
							setSelectedOption(null);
							memoizedHandleDetailsChange({ target: { value: "" } });
							setSetupData((prevSetupData) => ({
								...prevSetupData,
								monsterUrl: ""
							}));
						}
					}}
					role="button"
					tabIndex={0}
				>
					<h3 className={styles.jobCardTitle}>{defaultOption.title}</h3>
					<p className={styles.jobCardText}>{defaultOption.companyName}</p>
					<p className={styles.jobCardText}>{defaultOption.location}</p>
				</div>
				{options.length < 3 ? (
					options.map((option, index) => (
						<div
							key={index}
							className={styles.jobCardWrapper}
							style={{ marginLeft: index === 0 ? "5%" : "0" }}
							onClick={() => handleOptionClick(option)}
							onKeyDown={(event) => {
								if (event.key === "Enter" || event.key === " ") handleOptionClick(option);
							}}
							role="button"
							tabIndex={0}
						>
							<div className={`${styles.jobCard} ${styles.monsterCard} ${selectedOption === option ? styles.selectedJob : ""}`}>
								<h3 className={styles.jobCardTitle}>{option.title}</h3>
								<p className={styles.jobCardText}>{option.companyName}</p>
								<p className={styles.jobCardText}>{option.location}</p>
								<img src={monsterLogo} alt="Monster logo" className={styles.monsterLogo} />
							</div>
						</div>
					))
				) : (
					<>
						<button className={styles.carouselNav} type="button" aria-label="Previous Slide" onClick={previous}>
							<FiChevronLeft />
						</button>
						<Slider
							{...settings}
							className={styles.jobCarousel}
							ref={(slider) => {
								sliderRef = slider;
							}}
						>
							{options.map((option, index) => (
								<div key={index} className={styles.jobCardWrapper} onClick={() => handleOptionClick(option)}>
									<div className={`${styles.jobCard} ${styles.monsterCard} ${selectedOption === option ? styles.selectedJob : ""}`}>
										<h3 className={styles.jobCardTitle}>{option.title}</h3>
										<p className={styles.jobCardText}>{option.companyName}</p>
										<p className={styles.jobCardText}>{option.location}</p>
										<img src={monsterLogo} alt="Monster logo" className={styles.monsterLogo} />
									</div>
								</div>
							))}
						</Slider>
						<button className={styles.carouselNav} type="button" aria-label="Next Slide" onClick={next}>
							<FiChevronRight />
						</button>
					</>
				)}
			</div>
		</div>
	);
}
