import retailBackground from "../../images/Backgrounds/retailBackground.webp";
import discoveryBackground from "../../images/Backgrounds/discoveryBackground.webp";
import decisionBackground from "../../images/Backgrounds/decisionBackground.webp";
import presentationBackground from "../../images/Backgrounds/presentationBackground.webp";
import cougarDrillingSystemsBackground from "../../images/Backgrounds/cougarDrillingSystemsBackground.webp";
import dunderMifflinBackground from "../../images/Backgrounds/dunderMifflinBackground.webp";
import splashBG from "../../images/Backgrounds/splashBG.png";
import { process_preliminaries, process_opening, process_closing, process_SPIN, process_BANT } from "../ProcessList.js";
import { learning_points_array } from "../LearningPoints.js";

const SetupList = [
	{
		dashboard: true,
		setup_type: "interview",
		setup_name: "Job Interview",
		setup_background: splashBG,
		setup_description: "Practice interviewing for any job or fine tune your resume & cover letter."
	},
	{
		dashboard: false,
		setup_type: "discovery",
		setup_name: "Discovery Meeting",
		setup_background: discoveryBackground,
		setup_description: "Assume the role of a sales rep in a B2B discovery meeting with a potential client."
	},
	{
		dashboard: true,
		setup_type: "discovery_spin",
		setup_name: "Discovery Meeting",
		setup_background: discoveryBackground,
		setup_description: "Assume the role of a sales rep in a B2B discovery meeting with a potential client."
	},
	{
		dashboard: false,
		setup_type: "closing",
		setup_name: "Closing Meeting",
		setup_background: decisionBackground,
		setup_description: "Close the deal in a B2B sales meeting with a potential client."
	},
	{
		dashboard: false,
		setup_type: "retail",
		setup_name: "Retail Meeting",
		setup_background: discoveryBackground,
		setup_description: "Simulate a customer service interaction in a retail setting as the store employee."
	},
	{
		dashboard: true,
		setup_type: "presentation",
		setup_name: "Presentation",
		setup_background: presentationBackground,
		setup_description: "Deliver a presentation to a practice partner and handle a subsequent Q&A session."
	},
	{
		dashboard: false,
		setup_type: "intro",
		setup_name: "Self-Introduction",
		setup_background: splashBG,
		setup_description: "Introduce yourself in a concise manner and answer some follow-up questions."
	},
	{
		dashboard: false,
		setup_type: "freestyle",
		setup_name: "Freestyle",
		setup_background: splashBG,
		setup_description: "Engage in a freestyle conversation based on your own described scenario."
	},
	{
		dashboard: false,
		setup_type: "patient",
		setup_name: "Patient Interview",
		setup_background: splashBG,
		setup_description: "Conduct an intake interview as a healthcare provider with a patient."
	},
	{
		dashboard: false,
		setup_type: "pitch",
		setup_name: "Elevator Pitch",
		setup_background: splashBG,
		setup_description: "Deliver a concise, impactful elevator pitch to a prospective stakeholder."
	},
	{
		dashboard: false,
		setup_type: "reflection",
		setup_name: "Reflection",
		setup_background: splashBG,
		setup_description: "Reflect on your experiential learning with guidance from a partner."
	},
	{
		dashboard: false,
		setup_type: "business_pitch",
		setup_name: "Business Pitch",
		setup_background: splashBG,
		setup_description: "Pitch your business idea to a potential investor."
	}
];

export default SetupList;
