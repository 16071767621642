export const PLANS = {
	level1: {
		name: "Starter",
		features: {
			proReport: false,
			reportComments: false,
			cloudRecordings: false,
		},
		// ... other plan details
	},
	level2: {
		name: "Pro",
		features: {
			proReport: true,
			reportComments: true,
			cloudRecordings: true,
		},
		// ... other plan details
	},
	level3: {
		name: "Enterprise",
		features: {
			proReport: true,
			reportComments: true,
			cloudRecordings: true,
		},
		// ... other plan details
	},
};
