import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import s from "../../styleModules/simulationStyles.module.css";
import { obfuscate } from "../UtilityFunctions/DataUtility.js";

export function SimFinished({ uploadProgress, setUploadProgressState, currentSession, setCurrentSession, showSidebar }) {
	const location = useLocation();
	const [searchParams, setSearchParams] = useSearchParams();
	const { simId } = useParams();
	const posthog = usePostHog();

	const { userLanguage, translateDictText, performTranslation, localUser, handleNavigate } = useContext(AppContext);

	const [TextRenderState, setTextRenderState] = useState(false);

	// Static Translation
	const LocalLanguageDict = {
		GenerateReport: "Generate Report",
		TryAgain: "Try Again",
		ThisWillTakeAFewMinutes: "This will take a few minutes",
		Submit: "Submit",
		SimulationFinished: "Simulation Finished",
		SavingProgress: "Saving progress"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	const [hasSavedValues, setHasSavedValues] = useState(false);

	useEffect(() => {
		// Needed for refresh to still show generate button
		// Save values to local storage if they don't already exist

		if (localStorage.getItem("uploadProgress") === null) {
			localStorage.setItem("uploadProgress", uploadProgress);
			// console.log("1 Saved Upload Progress... ", uploadProgress);
		}

		if (localStorage.getItem("currentSession") === null) {
			localStorage.setItem("currentSession", JSON.stringify(currentSession));
			// console.log("1 Saved Current Session... ", currentSession);
		}

		setHasSavedValues(true);

		return () => {
			localStorage.removeItem("uploadProgress");
			localStorage.removeItem("currentSession");
			// console.log("1 Removed Upload Progress... ", localStorage.getItem("uploadProgress"));
			// console.log("1 Removed Current Session... ", localStorage.getItem("currentSession"));
		};
	}, [uploadProgress, currentSession]);

	useEffect(() => {
		// Retrieve values from local storage on mount

		const savedUploadProgress = localStorage.getItem("uploadProgress");

		const savedCurrentSession = localStorage.getItem("currentSession");

		if (savedUploadProgress !== null) {
			setUploadProgressState(parseInt(savedUploadProgress, 10));
		}

		if (savedCurrentSession && savedCurrentSession !== "undefined") {
			setCurrentSession(JSON.parse(savedCurrentSession));
		}

		// console.log("2 Saved Upload Progress... ", savedUploadProgress);
		// console.log("2 Saved Current Session... ", savedCurrentSession);
		return () => {
			localStorage.removeItem("uploadProgress");
			localStorage.removeItem("currentSession");
			console.log("2 Removed Upload Progress... ", localStorage.getItem("uploadProgress"));
			console.log("2 Removed Current Session... ", localStorage.getItem("currentSession"));
		};
	}, [hasSavedValues]);

	const handleGenerateReport = () => {
		const paramsString = searchParams.toString();
		// Clear local storage before generating a new report
		localStorage.removeItem("uploadProgress");
		localStorage.removeItem("currentSession");

		if (window.location.pathname.includes("preview")) {
			try {
				posthog?.capture("instage_preview_generate_report", {
					instage_id: localUser.id
				});
			} catch (error) {
				console.error("Posthog error:", error);
			}
			handleNavigate(`/preview-report/${obfuscate(currentSession.session_id.toString())}`, true);
		} else {
			handleNavigate(`/report/${obfuscate(currentSession.session_id.toString())}`, true);
		}
	};
	// useEffect(() => {
	// 	runTranslation();
	// });

	return (
		<div
			className={s.simPage}
			style={{
				// padding:
				// 	window.innerWidth > 768 ? (!showSidebar ? "1.25rem 1.25rem 0" : "1.25rem calc(1.25rem + 25%) 0 1.25rem") : "inherit",
				padding: window.innerWidth > 1000 ? "1.25rem 27% 0 2%" : "1.25rem 2% 0",
				zIndex: 1
			}}
		>
			<div className={s.simEndedContainer}>
				<div className={s.simEndedContent}>
					<h1 className={s.simEndedHeading}>{pageText.SimulationFinished}</h1>
					{uploadProgress > 0 && uploadProgress < 100 && (
						<div className={s.loadingBarContainer}>
							{/* <div className="progress-bar" style={{ width: `${uploadProgress}%`, background: "blue" }}>
									{uploadProgress}%
								</div> */}
							<div className={s.loadingBar}>
								<div className={s.loadingFill} style={{ width: `${uploadProgress}%` }}>
									<p className={s.loadingText}>{pageText.SavingProgress}...</p>
								</div>
							</div>
						</div>
					)}
					<div className={s.simEndedBtnContainer}>
						{uploadProgress >= 100 ||
							(uploadProgress === 0 && (
								<>
									{!window.location.pathname.includes("preview") && (
										<button
											type="button"
											className={s.simEndedCancelBtn}
											onClick={() => {
												let newPathname = location.pathname.replace(`/${simId}`, "");
												newPathname = window.location.pathname.includes("simulation")
													? newPathname.replace("simulation", "setup")
													: newPathname;

												handleNavigate(window.location.origin + newPathname, true);
											}}
										>
											{pageText.TryAgain}
										</button>
									)}
									<div className={s.simEndedBtnGroup}>
										<button
											data-attr="modals_generate_report"
											type="button"
											className={s.simEndedConfirmBtn}
											onClick={() => handleGenerateReport()}
										>
											{pageText.GenerateReport}
										</button>
										<p className={s.simEndedBtnNote}>{pageText.ThisWillTakeAFewMinutes}</p>
									</div>{" "}
								</>
							))}
					</div>
				</div>
			</div>
		</div>
	);
}
