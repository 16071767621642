// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popupVideoStyles-module-mic-icon {
	transition: opacity 2s ease-in-out;
	opacity: 0;
}

.popupVideoStyles-module-mic-icon.popupVideoStyles-module-visible {
	opacity: 1;
}

.popupVideoStyles-module-mic-icon.popupVideoStyles-module-hidden {
	opacity: 0;
}

.popupVideoStyles-module-replayIcon {
	/* position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -10%); */

	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;
	font-size: 10rem; /* adjust size as needed */
	color: #808080;
}

.popupVideoStyles-module-replayIcon:hover {
	color: rgb(0, 169, 175);
}

.popupVideoStyles-module-video {
	position: relative;
	visibility: visible;
	max-height: 200px;
	margin: 0;
	border-radius: 8px;
}

.popupVideoStyles-module-videoToggles {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	position: absolute;
	right: 0;
}

.popupVideoStyles-module-toggleButtons {
	display: flex;
	justify-content: space-between;
	margin-right: 1rem;
	width: 100%;
	font-size: 24px;
	opacity: 0.8;
}

.popupVideoStyles-module-toggleButtons:hover {
	opacity: 1;
	cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/styleModules/popupVideoStyles.module.css"],"names":[],"mappings":"AAAA;CACC,kCAAkC;CAClC,UAAU;AACX;;AAEA;CACC,UAAU;AACX;;AAEA;CACC,UAAU;AACX;;AAEA;CACC;;;uCAGsC;;CAEtC,aAAa;CACb,uBAAuB;CACvB,mBAAmB;;CAEnB,eAAe;CACf,gBAAgB,EAAE,0BAA0B;CAC5C,cAAc;AACf;;AAEA;CACC,uBAAuB;AACxB;;AAEA;CACC,kBAAkB;CAClB,mBAAmB;CACnB,iBAAiB;CACjB,SAAS;CACT,kBAAkB;AACnB;;AAEA;CACC,aAAa;CACb,yBAAyB;CACzB,qBAAqB;CACrB,kBAAkB;CAClB,QAAQ;AACT;;AAEA;CACC,aAAa;CACb,8BAA8B;CAC9B,kBAAkB;CAClB,WAAW;CACX,eAAe;CACf,YAAY;AACb;;AAEA;CACC,UAAU;CACV,eAAe;AAChB","sourcesContent":[".mic-icon {\n\ttransition: opacity 2s ease-in-out;\n\topacity: 0;\n}\n\n.mic-icon.visible {\n\topacity: 1;\n}\n\n.mic-icon.hidden {\n\topacity: 0;\n}\n\n.replayIcon {\n\t/* position: absolute; \n    top: 50%; \n    left: 50%; \n    transform: translate(-50%, -10%); */\n\n\tdisplay: flex;\n\tjustify-content: center;\n\talign-items: center;\n\n\tcursor: pointer;\n\tfont-size: 10rem; /* adjust size as needed */\n\tcolor: #808080;\n}\n\n.replayIcon:hover {\n\tcolor: rgb(0, 169, 175);\n}\n\n.video {\n\tposition: relative;\n\tvisibility: visible;\n\tmax-height: 200px;\n\tmargin: 0;\n\tborder-radius: 8px;\n}\n\n.videoToggles {\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\talign-items: flex-end;\n\tposition: absolute;\n\tright: 0;\n}\n\n.toggleButtons {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\tmargin-right: 1rem;\n\twidth: 100%;\n\tfont-size: 24px;\n\topacity: 0.8;\n}\n\n.toggleButtons:hover {\n\topacity: 1;\n\tcursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mic-icon": `popupVideoStyles-module-mic-icon`,
	"visible": `popupVideoStyles-module-visible`,
	"hidden": `popupVideoStyles-module-hidden`,
	"replayIcon": `popupVideoStyles-module-replayIcon`,
	"video": `popupVideoStyles-module-video`,
	"videoToggles": `popupVideoStyles-module-videoToggles`,
	"toggleButtons": `popupVideoStyles-module-toggleButtons`
};
export default ___CSS_LOADER_EXPORT___;
