import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import styles from "../../styleModules/generateStyles.module.css";
import { AudienceContainer } from "./AudienceContainer.js";
import { FiChevronRight } from "react-icons/fi/index.esm.js";

export default function SetupDetails({
	setupData,
	typingRefs1,
	audienceOptions,
	personalityOptions,
	audience,
	personalities,
	audienceRole,
	detailsArray,
	hoveredAvatar,
	setHoveredAvatar,
	handlePersonalityChange,
	changeAvatar
}) {
	const { localUser, adminAccess, runTranslation, translateDictText, userLanguage, performTranslation, setAvailableAvatars, availableAvatars } =
		useContext(AppContext);
	const [showMore, setShowMore] = useState(false);

	// Static Translation
	const LocalLanguageDict = {
		ShowMore: "Show More",
		ShowLess: "Show Less"
	};

	const [pageTextStatic, setPageTextStatic] = useState({
		...LocalLanguageDict
	});

	// Set default personality for starting avatar. Important for translation.
	useEffect(() => {
		console.log("SetupData Personality: ", setupData.personality);
		console.log("SetupData Personality: ", setupData.audience);
		if (
			setupData.personality !== "Playful" &&
			setupData.personality !== "Cheeky" &&
			setupData.personality !== "Angry" &&
			setupData.personality !== "Reserved" &&
			setupData.personality !== "Elusive"
		) {
			const timer = setTimeout(() => {
				changeAvatar({ newAudience: setupData.audience, newPersonality: setupData.personality });
			}, 1000); // 1000 milliseconds = 1 second

			return () => clearTimeout(timer); // Cleanup the timer when the component unmounts
		}
	}, []);

	useEffect(() => {
		// console.log("SetupData Audience: ", setupData.audience);
		if (!availableAvatars.includes(setupData.audience[0])) {
			changeAvatar({ newAudience: [availableAvatars[0]], newPersonality: setupData.personality });
			console.log("SetupData Audience personality: ", setupData.personality);
		}
		// if (setupData.audience) {
		// 	changeAvatar({ newAudience: [audienceOptions[0]], newPersonality: "Playful" });
		// }
	}, [setupData.audience]);

	// Usage Static
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageTextStatic, setPageTextStatic);
		};
		translateText();
	}, [userLanguage, showMore]);

	// Dynamic Translation
	const [pageText, setPageText] = useState([...detailsArray]);

	const [hasTranslatedText, setHasTranslatedText] = useState(false);

	// Function to perform translation with retry logic
	const performTranslationWithRetry = async (texts, retries = 3) => {
		for (let attempt = 0; attempt < retries; attempt++) {
			try {
				const translatedTexts = await translateDictText(texts, userLanguage);
				return translatedTexts;
			} catch (error) {
				console.error(`Error translating texts (attempt ${attempt + 1}):`, error);
				if (attempt === retries - 1) {
					throw error; // Rethrow the error if all retries fail
				}
			}
		}
	};

	// Usage Dynamic
	useEffect(() => {
		// console.log("detailsArray ", detailsArray);

		async function performTranslationDynamic() {
			// Function to extract all text fields for translation
			const extractTexts = (items) => {
				return items.flatMap((item) => {
					const texts = setupData.setup_type === "patient" ? [item.heading] : [];
					if (Array.isArray(item.text)) {
						texts.push(...item.text.flatMap((subItem) => (typeof subItem === "object" ? extractTexts([subItem]) : subItem)));
					} else if (typeof item.text === "object" && item.text !== null) {
						texts.push(...Object.values(item.text));
					} else {
						texts.push(item.text);
					}
					return texts;
				});
			};

			const texts = extractTexts(detailsArray);
			if (!texts || texts.length === 0) {
				console.error("texts is undefined or empty");
				return;
			}

			try {
				const translatedTexts = await performTranslationWithRetry(texts);
				let translatedIndex = 0;

				// Function to update text fields with translated texts
				const updateTexts = (items) => {
					return items.map((item) => {
						let updatedItem = { ...item };
						if (setupData.setup_type === "patient") {
							updatedItem.heading = translatedTexts[translatedIndex++];
						}

						if (Array.isArray(item.text)) {
							updatedItem.text = item.text.map((subItem) => {
								if (typeof subItem === "object") {
									return updateTexts([subItem])[0];
								}
								return translatedTexts[translatedIndex++] || subItem;
							});
						} else if (typeof item.text === "object" && item.text !== null) {
							updatedItem.text = Object.fromEntries(
								Object.entries(item.text).map(([key, value]) => [key, translatedTexts[translatedIndex++] || value])
							);
						} else {
							updatedItem.text = translatedTexts[translatedIndex++] || item.text;
						}

						return updatedItem;
					});
				};

				// Update state with new details array
				const updatedDetailsArray = updateTexts(detailsArray);
				setPageText(updatedDetailsArray);
			} catch (error) {
				console.error("Error translating texts after retries:", error);
			}
		}

		setTimeout(() => {
			// stale state problem goes away using inner function
			if (!hasTranslatedText) {
				// console.log("SetupInfoTranslating");
				if (detailsArray.length > 0) {
					performTranslationDynamic();
					setHasTranslatedText(true);
				}
			}
		}, 500);
	}, [userLanguage, detailsArray]);

	return (
		<div className={styles.inputDetailsContainer}>
			{pageText.map((item, index) => {
				if (item.text === null || item.text === undefined || item.text === "") {
					return null;
				}
				if (setupData?.setup_type === "patient" && item.heading === "Patient Medical Information" && !adminAccess) {
					return null;
				}
				if (index === 0 || showMore) {
					return (
						<div key={index} className={styles.inputDetails}>
							<p className={styles.inputHeading}>{item.heading}</p>
							{(() => {
								if (Array.isArray(item.text)) {
									return item.text.map((element, index) => {
										if (typeof element === "object") {
											return (
												<p key={index} className={styles.inputText}>
													{index + 1}: {element.name}
												</p>
											);
										}
										return (
											<p key={index} className={styles.inputText}>
												• {element}
											</p>
										);
									});
								}
								if (typeof item.text === "string") {
									return item.text.split("\n").map((line, index) => (
										<p key={index} className={styles.inputText}>
											{line.startsWith("- ") ? `• ${line.slice(2)}` : line}
										</p>
									));
								}
								if (typeof item.text === "object") {
									const orderedKeys = ["date_of_birth", "gender_assigned_at_birth", "height", "weight", "allergies", "other"];
									const itemKeys = Object.keys(item.text);
									const shouldOrder = orderedKeys.every((key) => itemKeys.includes(key));

									if (shouldOrder) {
										return orderedKeys.map((key, index) => {
											const value = item.text[key];
											if (typeof value === "string" && value.includes("\n")) {
												return value.split("\n").map((line, subIndex) => (
													<p key={`${index}-${subIndex}`} className={styles.inputText}>
														{line}
													</p>
												));
											}
											return (
												<p key={index} className={styles.inputText}>
													{`${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}: ${value}`}
												</p>
											);
										});
									}

									return Object.entries(item.text)
										.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
										.map(([key, value], index) => {
											if (typeof value === "string" && value.includes("\n")) {
												return value.split("\n").map((line, subIndex) => (
													<p key={`${index}-${subIndex}`} className={styles.inputText}>
														{line}
													</p>
												));
											}
											return (
												<p key={index} className={styles.inputText}>
													{`${key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}: ${value}`}
												</p>
											);
										});
								}
								return item.text;
							})()}
							{index === 0 && (
								<div className={styles.showMoreContainer}>
									<button type="button" className={styles.showMoreButton} onClick={() => setShowMore(!showMore)}>
										{!showMore ? `${pageTextStatic.ShowMore}` : `${pageTextStatic.ShowLess}`}
										<FiChevronRight className={showMore ? styles.chevronDown : styles.chevronRight} />
									</button>
								</div>
							)}
						</div>
					);
				}
				return null;
			})}
			<div className={styles.inputDetails}>
				<p className={styles.inputHeading}>{audienceRole}</p>
				{setupData?.creator_instage_id === localUser.id ? (
					<div className={styles.avatarCarousel}>
						{audienceOptions?.map((option, index) => {
							const selected = audience && audience.length > 0 && audience[0] === option;

							return (
								<div
									key={index}
									tabIndex={0}
									role="button"
									aria-label={option}
									onMouseOver={() => setHoveredAvatar(option)}
									onMouseOut={() => setHoveredAvatar(null)}
									onClick={() => {
										const selectedOptionPersonality = personalities[option];
										changeAvatar({ newAudience: [option], newPersonality: selectedOptionPersonality });
									}}
									onKeyDown={(event) => {
										if (event.key === "Enter") {
											const selectedOptionPersonality = personalities[option];
											changeAvatar({ newAudience: [option], newPersonality: selectedOptionPersonality });
										}
									}}
									className={`${styles.avatarContainer} ${selected && styles.selectedAvatarContainer}`}
								>
									<AudienceContainer
										selected={selected}
										audience={[option]}
										typingRefs1={typingRefs1}
										isHovered={hoveredAvatar === option}
										personalityOptions={personalityOptions}
										personality={personalities[option]}
										handlePersonalityChange={handlePersonalityChange}
									/>
								</div>
							);
						})}
					</div>
				) : (
					<div className={styles.avatarContainerNoHover} style={{ maxWidth: "12rem" }}>
						<AudienceContainer
							audience={setupData.audience}
							personality={setupData.personality}
							typingRefs1={typingRefs1}
							setupData={setupData}
							fixedPersonality
						/>
					</div>
				)}
			</div>
		</div>
	);
}
