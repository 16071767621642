const WIL_Types = [
	{
		name: "Applied Research",
		label: "Applied Research",
		description:
			"Students work on real-world projects or research initiatives in collaboration with industry partners, applying their knowledge to solve practical problems."
	},
	{
		name: "Capstone Project",
		label: "Capstone Project",
		description:
			"Students complete a comprehensive project in their final year, integrating and applying the knowledge and skills acquired throughout their program."
	},
	{
		name: "Clinical/Practicum",
		label: "Clinical/Practicum",
		description:
			"Students in healthcare, education, or social services programs gain supervised, hands-on experience in a professional setting, developing practical skills and professional competencies."
	},
	{
		name: "Co-op",
		label: "Co-op",
		description:
			"Students alternate between academic study and paid work experience in their field, typically over multiple semesters, to gain extensive professional experience and build networks."
	},
	{
		name: "Entrepreneurial WIL",
		label: "Entrepreneurial WIL",
		description:
			"Students engage in entrepreneurial activities, such as developing and implementing business plans or starting their own ventures, to gain hands-on experience in entrepreneurship."
	},
	{
		name: "Field Placement",
		label: "Field Placement",
		description:
			"Students work in a professional setting related to their field of study, gaining practical experience and applying classroom knowledge in a real-world environment."
	},
	{
		name: "Field Trip",
		label: "Field Trip",
		description:
			"Students participate in organized visits to relevant locations, such as companies, organizations, or natural settings, to observe and learn from real-world examples related to their field of study."
	},
	{
		name: "Industry Project",
		label: "Industry Project",
		description:
			"Students work on real-world projects or research initiatives in collaboration with industry partners, applying their knowledge to solve practical problems."
	},
	{
		name: "Lab",
		label: "Lab",
		description:
			"Students engage in hands-on, practical learning experiences in a controlled environment, such as a science laboratory or computer lab, to develop technical skills and apply theoretical knowledge."
	},
	{
		name: "Performance",
		label: "Performance",
		description:
			"Students in the performing arts, such as music, theater, or dance, participate in live performances to develop their skills, gain stage experience, and showcase their talents."
	},
	{
		name: "Service-Learning WIL",
		label: "Service-Learning WIL",
		description:
			"Students participate in community service projects that align with their academic goals, developing a deeper understanding of social issues and civic responsibility."
	},
	{
		name: "Simulation",
		label: "Simulation",
		description:
			"Students engage in simulated scenarios or use specialized equipment to replicate real-world situations, allowing them to practice decision-making and problem-solving skills in a safe environment."
	}
];

export default WIL_Types;
