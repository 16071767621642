import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FiThumbsUp, FiThumbsDown } from "react-icons/fi";
import { GiPerspectiveDiceSixFacesRandom } from "react-icons/gi";
import s from "../../styleModules/setupStyles.module.css";
import { AppContext } from "../UtilityFunctions/AppContext.js";

export function AudienceContainer({ selected, audience, isHovered, personalityOptions, personality, handlePersonalityChange, fixedPersonality }) {
	const { adminAccess, setupData, translateDictText, userLanguage, performTranslation } = useContext(AppContext);
	const { setupType } = useParams();

	const LocalLanguageDict = {
		HRCoordinator: "HR Coordinator",
		Buyer: "Buyer",
		Manager: "Manager",
		VicePresident: "Vice President",
		CEO: "CEO",
		Partner: "Partner",
		Investor: "Investor",
		Interviewer: "Interviewer"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	// useEffect(() => {
	// 	console.log("Selected: ", selected);
	// }, []);

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Audience Titles");
			translateText();
		}
	}, [userLanguage, pageText]);

	useEffect(() => {
		// Example: Update some state or perform a side effect
		// console.log("Personality options changed:", personalityOptions);
		// You could also update some state here if needed
		// setYourState(someNewValue);
	}, [personalityOptions]);

	const audienceData = {
		John: {
			default_personality: "Playful",
			roles: {
				interview: pageText.HRCoordinator,
				discovery: pageText.Buyer,
				discovery_spin: pageText.Buyer,
				closing: pageText.Buyer,
				presentation: pageText.Partner,
				pitch: pageText.Partner,
				business_pitch: pageText.Investor,
				retail: pageText.Buyer,
				reflection: pageText.Interviewer
			}
		},
		Myra: {
			default_personality: "Angry",
			roles: {
				interview: pageText.Manager,
				discovery: pageText.Buyer,
				discovery_spin: pageText.Buyer,
				closing: pageText.Buyer,
				presentation: pageText.Partner,
				pitch: pageText.Partner,
				business_pitch: pageText.Investor,
				retail: pageText.Buyer,
				reflection: pageText.Interviewer
			}
		},
		David: {
			default_personality: "Cheeky",
			roles: {
				interview: pageText.VicePresident,
				discovery: pageText.Buyer,
				discovery_spin: pageText.Buyer,
				closing: pageText.Buyer,
				presentation: pageText.Partner,
				pitch: pageText.Partner,
				business_pitch: pageText.Investor,
				retail: pageText.Buyer,
				reflection: pageText.Interviewer
			}
		},
		Nina: {
			default_personality: "Reserved",
			roles: {
				interview: pageText.CEO,
				discovery: pageText.Buyer,
				discovery_spin: pageText.Buyer,
				closing: pageText.Buyer,
				presentation: pageText.Partner,
				pitch: pageText.Partner,
				business_pitch: pageText.Investor,
				retail: pageText.Buyer,
				reflection: pageText.Interviewer
			}
		},
		Random: {
			default_personality: "???",
			roles: {
				interview: "???",
				discovery: "???",
				discovery_spin: "???",
				closing: "???",
				presentation: "???",
				pitch: "???",
				business_pitch: pageText.Investor,
				retail: "???",
				reflection: "???"
			}
		}
	};

	const personalityTips = {
		Playful: {
			dos: ["Incorporate humor appropriately to build rapport.", "Share relevant anecdotes to illustrate points."],
			donts: ["Avoid scripts or formal language.", "Avoid dampening the conversation's energy with dry material."]
		},
		Sincere: {
			dos: ["Engage emotionally and show enthusiasm for the subject.", "Be adaptable in conversation, matching her supportive tone."],
			donts: [
				"Avoid confrontation and aggressive selling or debating techniques.",
				"Do not dismiss the importance of interpersonal connection."
			]
		},
		Reserved: {
			dos: ["Be succinct", "Provide thorough, well-structured arguments or presentations."],
			donts: ["Avoid vagueness or imprecision", "Avoid small talk that is not relevant to the subject matter."]
		},
		Cheeky: {
			dos: ["Provide clear, logical arguments.", "Use succinct, no-frills language in discussions."],
			donts: ["Avoid embellishments or unfounded optimism.", "Don't be overly idealistic without evidence."]
		},
		Angry: {
			dos: [
				"Use clear and concise language to express concerns or dissatisfaction.",
				"Acknowledge the validity of their feelings before offering solutions or alternatives."
			],
			donts: [
				"Avoid ignoring or dismissing their concerns as this can escalate the situation.",
				"Do not patronize or use a condescending tone, which may be perceived as dismissive."
			]
		},
		Elusive: {
			dos: [
				"Encourage open dialogue by asking open-ended questions.",
				"Provide multiple options or scenarios to help them articulate their preferences more clearly."
			],
			donts: [
				"Avoid pressing for overly specific answers too quickly, which may cause further withdrawal.",
				"Do not corner or force a direct answer, as this can lead to resistance or disengagement."
			]
		}
	};

	return (
		<>
			{audience &&
				audience.length > 0 &&
				Array.isArray(audience) &&
				audience.map((option, index) => {
					const currentPersonality = personality || audienceData[option].default_personality;
					const { dos, donts } = personalityTips[currentPersonality] || {};

					return (
						<div key={index} className={s.simAvatar}>
							{option === "Random" ? (
								<GiPerspectiveDiceSixFacesRandom data-testid="random-icon" className={s.simAvatarPortrait} />
							) : isHovered ? (
								<video
									disableRemotePlayback
									muted
									autoPlay
									loop
									src={`https://avatarportraits.s3.ca-central-1.amazonaws.com/${option}PortraitAnimation.mp4`}
									className={`${s.simAvatarPortrait} ${s.simAvatarAnimation}`}
								/>
							) : (
								<img
									className={s.simAvatarPortrait}
									src={`https://avatarportraits.s3.ca-central-1.amazonaws.com/${option}Portrait.png`}
									alt={option}
								/>
							)}
							<div className={s.simAvatarTextContainer}>
								<p className={s.simAvatarName}>{option}</p>
								{audienceData[option].roles[setupType] && <p className={s.simAvatarTitle}>{audienceData[option].roles[setupType]}</p>}
								{option === "Random" || fixedPersonality ? (
									<div className={s.simAvatarDetail}>{personality || audienceData[option].default_personality}</div>
								) : (
									<select
										className={`${s.simAvatarDetail} ${selected || isHovered ? s.simAvatarDetailSelect : ""}`}
										onClick={(e) => e.stopPropagation()}
										onChange={(e) => handlePersonalityChange(option, e.target.value)}
										value={personality || audienceData[option].default_personality}
									>
										{personalityOptions.map((personalityOption) => (
											<option key={personalityOption.value} value={personalityOption.value}>
												{personalityOption.label}
											</option>
										))}
									</select>
								)}
							</div>
						</div>
					);
				})}
		</>
	);
}
