import React, { useState, useEffect, useContext, useRef } from "react";
import { FaCheck, FaTimes } from "react-icons/fa/index.esm.js";
import { FiChevronRight } from "react-icons/fi/index.esm.js";
import { IoWarning } from "react-icons/io5/index.esm.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import r from "../../styleModules/reportStyles.module.css";
import checkmark from "../../images/checkmark.png";
import { CommentIcon } from "../SecondaryComponents/CommentModal.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { TranscriptElement } from "./TranscriptElement.js";

import AudioButton from "./AudioButton.js";

export default function QuestionSummaryTable({ sessionData, questionSummary, setQuestionSummary }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const url = process.env.REACT_APP_BACKEND_STATIC_URL;

	const [expandedRows, setExpandedRows] = useState([]);
	const [hoveredRow, setHoveredRow] = useState(null);
	const [completed, setCompleted] = useState(0);
	const [editableGrades, setEditableGrades] = useState({});
	const [pendingUpdates, setPendingUpdates] = useState({});
	const [inputError, setInputError] = useState(false);
	const [tempQuestionSummary, setTempQuestionSummary] = useState(questionSummary);

	const { adminAccess, userLanguage, translateDictText, performTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Content: "Content",
		Delivery: "Delivery",
		Completion: "Completion",
		Other: "Other",
		Score: "Score",
		Save: "Save",
		Feedback: "Feedback",
		Dialogue: "Dialogue",
		OnlyAdminsSeeScores: "Only admins see scores"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	const [dynamicPageText, setDynamicPageText] = useState({});
	const [translationAttempts, setTranslationAttempts] = useState(0);

	// Usage
	useEffect(() => {
		// console.log("questionSummary", questionSummary);
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage, sessionData, questionSummary]); // Depend on pageText to trigger re-translation

	// Dynamic Usage
	useEffect(() => {
		if (translationAttempts < 4) {
			const textsToTranslate = {};
			tempQuestionSummary.forEach((item) => {
				textsToTranslate[item.text] = item.text;
				textsToTranslate[item.type] = item.type;
				if (item.assessment) {
					textsToTranslate[item.assessment.content_grade_1_feedback] = item.assessment.content_grade_1_feedback;
					textsToTranslate[item.assessment.content_grade_2_feedback] = item.assessment.content_grade_2_feedback;
				}
			});

			performTranslation(textsToTranslate, (translatedTexts) => {
				setDynamicPageText(translatedTexts);

				const updatedQuestionSummary = tempQuestionSummary.map((item) => ({
					...item,
					text: translatedTexts[item.text],
					type: translatedTexts[item.type],
					assessment: item.assessment
						? {
								...item.assessment,
								content_grade_1_feedback: translatedTexts[item.assessment.content_grade_1_feedback],
								content_grade_2_feedback: translatedTexts[item.assessment.content_grade_2_feedback]
						  }
						: item.assessment
				}));

				setQuestionSummary(updatedQuestionSummary);
				// console.log("updatedQuestionSummary", updatedQuestionSummary);
				setTranslationAttempts((prev) => prev + 1);
			});
		}
	}, [tempQuestionSummary, performTranslation, dynamicPageText]);

	const minSpeakTime = 10;

	useEffect(() => {
		// Initialize expandedRows state with false values
		setExpandedRows(questionSummary.map(() => false));

		// Count the number of completed questions in questionSummary by checking how many have a status of true, speak_time >= minSpeakTime, and relevance.toLowerCase !== "Not Relevant"
		let completedCount = 0;
		questionSummary.forEach((item) => {
			if (item.status && item.speak_time >= minSpeakTime && item.relevance && item.relevance.toLowerCase() !== "not relevant") {
				completedCount += 1;
			}
		});
		setCompleted(completedCount);

		// Initialize editableGrades
		const initialGrades = {};
		questionSummary.forEach((item, index) => {
			initialGrades[index] = item.assessment?.total_grade || 0;
		});
		setEditableGrades(initialGrades);
	}, [questionSummary]);

	const validateAndSaveGrade = async (index) => {
		const parsedGrade = parseInt(editableGrades[index], 10);

		if (Number.isNaN(parsedGrade) || parsedGrade < 0 || parsedGrade > 100) {
			console.log("Invalid input"); // You can replace this with a more user-friendly error-handling mechanism
			setInputError(true);
			return;
		}

		// If the validation passes, mark this row as no longer pending
		const newPendingUpdates = { ...pendingUpdates };
		delete newPendingUpdates[index];
		setPendingUpdates(newPendingUpdates);

		const data = {
			session_data: {
				audience: sessionData.audience,
				chat_history: sessionData.chat_history,
				company: sessionData.company,
				company_description_short: sessionData.company_description_short,
				backstory: sessionData.backstory,
				buyer_title: sessionData.buyer_title,
				call_notes: sessionData.call_notes,
				session_id: sessionData.session_id,
				setup_type: sessionData.setup_type,
				process_points: sessionData.process_points,
				learning_points: sessionData.learning_points,
				job_question_list: sessionData.job_question_list,
				short_summary: sessionData.short_summary,
				job_function: sessionData.job_function,
				experience_level: sessionData.experience_level,
				industry: sessionData.industry,
				main_responsibilities: sessionData.main_responsibilities,
				skills_required: sessionData.skills_required,
				speech_data: sessionData.speech_data,
				question_summary_status: sessionData.question_summary_status,
				setup_output: sessionData.setup_output,
				presentation_details: sessionData.presentation_details,
				topic: sessionData.topic,
				objective: sessionData.objective,
				audience_role: sessionData.audience_role
			},
			question_index: index,
			new_grade: parsedGrade
		};

		try {
			const resp = await axiosLimitedPost(`${url}/api/report/${sessionData.setup_type}/UpdateQuestionGrade`, data, 1, {}, 10000);
			if (resp.status === 200) {
				const updatedQuestionSummary = resp.data;
				setQuestionSummary(updatedQuestionSummary);
				// console.log("updatedQuestionSummary", updatedQuestionSummary);
			}
		} catch (error) {
			console.log("Error updating grade:", error);
		}
	};

	const toggleRow = (index) => {
		const newExpandedRows = [...expandedRows];
		newExpandedRows[index] = !newExpandedRows[index];
		setExpandedRows(newExpandedRows);
	};

	function getColor(grade, type) {
		if (grade === null || grade === undefined) {
			return "#16013E"; // Default color
		}
		if (grade < 50) {
			if (type === "dark") {
				return "#7d7d7d";
			} else {
				return "#AFAFAF";
			}
		}
		if (grade >= 50 && grade < 80) {
			if (type === "dark") {
				return "#87BAC0";
			} else {
				return "#c2c3c2";
			}
		}
		if (grade >= 80) {
			if (type === "dark") {
				return "#4BA6AD";
			} else {
				return "#87BAC0";
			}
		}
	}
	const [assistantTranscript, setAssistantTranscript] = useState({});
	const [userTranscript, setUserTranscript] = useState({});

	useEffect(() => {
		const objectA = {};
		const objectB = {};

		sessionData.transcriptv3.forEach((item) => {
			if (item.speaker === 0) {
				const copy = { ...item };
				copy.start = item.start / 1000;
				copy.end = item.end / 1000;

				objectA[copy.speechId] = copy;
			} else {
				const copy = { ...item };
				copy.start = item.start / 1000;
				copy.end = item.end / 1000;
				objectB[copy.transcriptId] = copy;
			}
		});

		setAssistantTranscript(objectA);
		setUserTranscript(objectB);
	}, [sessionData.transcriptv3]);

	const [isAudioAvailable, setIsAudioAvailable] = useState(false);
	const [isAudioReady, setIsAudioReady] = useState(false);

	const disable_retry = sessionData?.setup_output?.disable_retry || false;
	const AudioRef = useRef(null);
	const AudioRefCurrent = AudioRef.current;
	const currentTimeRef = useRef(0);

	const fileKey = sessionData.deepgram_data?.fileKey;
	const audioFileExists = sessionData.deepgram_data?.audioFileExists;
	const audioFiles = sessionData.audio_files;

	// Check if any of the audio files are video files
	useEffect(() => {
		// const hasAudioFiles = audioFiles?.some((file) => file.endsWith("fullAudioFile.wav"));

		const hasAudioFiles = fileKey !== undefined;
		// console.log("setIsAudioAvailable", "fileKey", fileKey, "hasAudioFiles", hasAudioFiles);

		if (audioFileExists !== undefined) {
			setIsAudioAvailable(hasAudioFiles && audioFileExists === true);
		} else {
			setIsAudioAvailable(hasAudioFiles);
		}

		const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
		if (hasAudioFiles) {
			setIsAudioReady(true);
		}
	}, [audioFiles, audioFileExists, fileKey]);

	useEffect(() => {
		// console.log(sessionData.deepgram_data);
		// console.log("fileKey", fileKey, audioFileExists);
		if (AudioRef.current && fileKey && fileKey !== "" && audioFileExists === true) {
			// console.log("src set", `${url}/Audio2?key=${encodeURI(fileKey)}`);
			AudioRef.current.src = `${url}/Audio2?key=${encodeURI(fileKey)}`;
			const handleTimeUpdate = () => {
				if (AudioRef.current) {
					currentTimeRef.current = AudioRef.current.currentTime;
				}
			};
			AudioRef.current.addEventListener("timeupdate", handleTimeUpdate);

			// Cleanup function
			return () => {
				if (AudioRef.current) {
					AudioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
				}
			};
		}
	}, [AudioRefCurrent, fileKey, audioFileExists]);
	const handleParagraphClick = (time) => {
		if (AudioRef.current && isAudioReady) {
			if (!AudioRef.current.paused) {
				AudioRef.current.pause();
			} else {
				AudioRef.current.currentTime = time;
				AudioRef.current.play().catch((e) => {
					console.error("Error attempting to play audio:", e);
				});
			}

			// Update the active paragraph index after a delay to allow the audio currentTime to update
			setTimeout(() => {}, 0);
		}
	};

	// useEffect(() => {
	// 	runTranslation();
	// });
	return (
		<div className={r.reportTable}>
			{isAudioAvailable && (
				<audio
					ref={AudioRef}
					canplay={isAudioReady.toString()}
					onLoadedData={() => setIsAudioReady(true)}
					onError={(e) => {
						setTimeout(() => {
							if (e.target.error) {
								console.error("error with audio", e.target);

								console.error("error with audio", e.target.error);

								console.error("error with audio", e.target.error.code);
							} else {
								console.error("error with audio", e);
							}
						}, 2);

						// setIsAudioAvailable(false);
					}}
					style={{ display: isAudioReady ? "block" : "none" }}
				>
					<source type="audio/wav" />
					Your browser does not support the audio element.
				</audio>
			)}
			{questionSummary &&
				questionSummary.map((item, index) => {
					const interweavedMessages = [];
					if (item.speechId && item.speechId.length > 0) {
						for (let i = 0; i < item.speechId.length; i++) {
							interweavedMessages.push({ speechId: item.speechId[i] });
							if (item.transcriptId[i]) {
								interweavedMessages.push({ transcriptId: item.transcriptId[i] });
							}
						}
					}

					return (
						<React.Fragment key={item.text}>
							<div className={`${r.reportTableRow} ${expandedRows[index] ? r.reportTableToggledRow : ""}`}>
								<div
									role="button"
									tabIndex={0}
									className={`${r.reportTableHeader} ${expandedRows[index] ? r.reportTableToggledHeader : ""}`}
									onMouseEnter={() => setHoveredRow(index)}
									onMouseLeave={() => setHoveredRow(null)}
									onClick={() => toggleRow(index)}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											toggleRow(index);
										}
									}}
								>
									<div
										className={r.questionColumn}
										style={{ width: expandedRows[index] ? "65%" : window.innerWidth > 1000 ? "65%" : "50%" }}
									>
										<div className={r.questionColumnType}>
											<span>{item.type}:</span>{" "}
										</div>
										{window.innerWidth > 1000 && (
											<div
												className={`${r.questionColumnText} ${
													expandedRows[index] ? r.nonFixedHeightCell : r.fixedHeightCell
												}`}
											>
												<span>{item.text}</span>
											</div>
										)}
									</div>
									{adminAccess && (
										<div
											className={r.scoreColumn}
											style={{ width: expandedRows[index] ? "30%" : window.innerWidth > 1000 ? "30%" : "45%" }}
										>
											{item.type !== "Opening" && item.type !== "Closing" ? (
												<div
													className={r.scoreContainer}
													style={{
														backgroundColor: getColor(item.assessment?.total_grade, "dark"),
														justifyContent: "center"
													}}
												>
													{/* <div
													className={r.scoreIconCircle}
													style={{ backgroundColor: getColor(item.assessment?.total_grade, "light") }}
												>
													{item.assessment?.total_grade >= 50 ? (
														<FaCheck className={r.scoreIcon} />
													) : (
														<FaTimes className={r.scoreIcon} />
													)}
												</div> */}
													<span>Score: {item.assessment?.total_grade || "-"}</span>
												</div>
											) : (
												<></>
											)}
										</div>
									)}
									<div className={r.chevronColumn}>
										<FiChevronRight className={expandedRows[index] || hoveredRow === index ? r.chevronDown : r.chevronRight} />
									</div>
								</div>

								{expandedRows[index] && (
									<div className={r.reportTableDropdown} style={{ display: window.innerWidth > 1000 ? "flex" : "" }}>
										<div className={r.reportTableTranscript}>
											<p className={r.reportTableDropdownTitle} style={{ paddingLeft: "0rem" }}>
												{pageText.Dialogue}:
											</p>
											{item.combined_messages?.map((message, index) => (
												<div key={`row${index}`} className={r.questionColumn}>
													{/* <p><a href={"http://localhost:5000/audio3/" + message.speech_data.file_name} target="blank">Audio</a></p> */}
													<div className={r.questionColumnType} key={`Links${index}`}>
														{message.role === "assistant" ? (
															<p style={{ color: "rgb(170, 170, 170)", fontStyle: "italic" }} />
														) : (
															<AudioButton item={{ index, audio: message.speech_data?.file_name }} />
														)}
													</div>
													<div className={r.questionColumnText} key={`messages${index}`}>
														{message.role === "assistant" ? (
															<p style={{ color: "rgb(170, 170, 170)", fontStyle: "italic" }}>{message.text}</p>
														) : (
															<p>{message.text}</p>
														)}
													</div>
												</div>
											))}

											{item.speechId && item.transcriptId && (
												<div key={`ids${item.text}`}>
													{interweavedMessages.map((ids, index2) => (
														<div key={`ids${ids.speechId}${ids.transcriptId}`}>
															{index2 !== 0 && <br />}

															<div
																style={{ marginRight: "5%", position: "relative", cursor: "pointer" }}
																onClick={() => handleParagraphClick(assistantTranscript[ids.speechId].start)}
															>
																{ids.speechId &&
																	assistantTranscript[ids.speechId] &&
																	assistantTranscript[ids.speechId].text && (
																		<TranscriptElement
																			transcript={assistantTranscript[ids.speechId]}
																			audience={sessionData.audience}
																			setup_type={sessionData.setup_type}
																			index={index}
																			simulation_fk={sessionData.simulation_fk}
																			disable_retry={disable_retry}
																			session_id={sessionData.session_id}
																			isAudioReady={isAudioReady}
																			isTable={true}
																		/>
																	)}
															</div>

															<div
																style={{ marginRight: "5%", position: "relative", cursor: "pointer" }}
																onClick={() => handleParagraphClick(userTranscript[ids.transcriptId].start)}
															>
																{ids.transcriptId &&
																	userTranscript[ids.transcriptId] &&
																	userTranscript[ids.transcriptId].text && (
																		<TranscriptElement
																			transcript={userTranscript[ids.transcriptId]}
																			audience={sessionData.audience}
																			setup_type={sessionData.setup_type}
																			index={index}
																			simulation_fk={sessionData.simulation_fk}
																			disable_retry={disable_retry}
																			session_id={sessionData.session_id}
																			isAudioReady={isAudioReady}
																			isTable={true}
																		/>
																	)}
															</div>
														</div>
													))}
												</div>
											)}
										</div>

										{adminAccess && item.type !== "Opening" && item.type !== "Closing" && (
											<div className={r.reportTableScoreAndFeedback}>
												<div className={r.statusGradeFeedbackContainer}>
													<p className={r.reportTableDropdownTitle}>{pageText.Feedback}:</p>
													<p className={r.statusGradeFeedbackText}>{item.assessment?.content_grade_1_feedback}</p>
													<p className={r.statusGradeFeedbackText}>{item.assessment?.content_grade_2_feedback}</p>
												</div>
												<div className={r.statusGradeContainer}>
													<div className={r.statusGradeRow}>
														<div className={r.statusGradeName}>
															<span>{pageText.Content}:</span>
														</div>
														<div className={r.statusGradeValue}>
															{item.assessment?.content !== null && item.assessment?.content !== undefined ? (
																<>
																	<span style={{ color: getColor(item.assessment?.content, "dark") }}>
																		{item.assessment?.content}{" "}
																	</span>
																	<span>/ 100</span>
																</>
															) : (
																"-"
															)}
														</div>
													</div>
													<div className={r.statusGradeRow}>
														<div className={r.statusGradeName}>
															<span>{pageText.Delivery}:</span>
														</div>
														<div className={r.statusGradeValue}>
															{item.assessment?.delivery !== null && item.assessment?.delivery !== undefined ? (
																<>
																	<span style={{ color: getColor(item.assessment?.delivery, "dark") }}>
																		{item.assessment?.delivery}{" "}
																	</span>
																	<span>/ 100</span>
																</>
															) : (
																"-"
															)}
														</div>
													</div>
													<div className={r.statusGradeRow}>
														<div className={r.statusGradeName}>
															<span>{pageText.Completion}:</span>
														</div>
														<div className={r.statusGradeValue}>
															{item.assessment?.completion !== null && item.assessment?.completion !== undefined ? (
																<>
																	<span style={{ color: getColor(item.assessment?.completion, "dark") }}>
																		{item.assessment?.completion}{" "}
																	</span>
																	<span>/ 100</span>
																</>
															) : (
																"-"
															)}
														</div>
													</div>
													<hr className={r.statusGradeDivider} />
													<div className={r.statusGradeRow}>
														<div className={r.statusGradeName}>
															<span>{pageText.Score}:</span>
														</div>
														<div
															className={r.statusGradeValue}
															style={{ color: getColor(item.assessment?.total_grade, "dark") }}
														>
															{`${item.assessment?.total_grade}` || "-"}
														</div>
													</div>
													{inputError && (
														<div className={r.statusGradeRow}>
															<div className={r.statusGradeError}>Must be 0-100</div>
														</div>
													)}
													{pendingUpdates[index] && (
														<div className={r.statusGradeRow}>
															<button className={r.saveGradeBtn} onClick={() => validateAndSaveGrade(index)}>
																{pageText.Save}
															</button>
														</div>
													)}
													<p className={r.statusGradeNote}>
														{item.type !== "Opening" && item.type !== "Closing" ? (
															<>*{pageText.OnlyAdminsSeeScores}</>
														) : (
															<></>
														)}
													</p>
												</div>
											</div>
										)}
										{!adminAccess && (
											<>
												<div className={r.reportTableScoreAndFeedback}>
													{item.type !== "Opening" && item.type !== "Closing" ? (
														<div className={r.reportTableFeedbackContainer}>
															<div className={r.statusGradeFeedbackContainer}>
																<p className={r.reportTableDropdownTitle}>Feedback:</p>
																<p className={r.statusGradeFeedbackText}>
																	{item.assessment?.content_grade_1_feedback}
																</p>
																<p className={r.statusGradeFeedbackText}>
																	{item.assessment?.content_grade_2_feedback}
																</p>
															</div>
														</div>
													) : (
														<></>
													)}
												</div>
											</>
										)}
									</div>
								)}
							</div>
						</React.Fragment>
					);
				})}
		</div>
	);
}
