import React from "react";

export const BaseTable = (props) => {
	let { children, data, fixed, textAlign = "center", headers, columnWidths } = props;

	if (!Array.isArray(children)) {
		children = [children];
	}

	// const getWidth = (header) => {
	// 	if (columnWidths.hasOwnProperty(header)) {
	// 	  if(submitted){
	// 		// use fixed width when submitted is true
	// 		return columnWidths[header].fixed;
	// 	  } else {
	// 		// use percentage width when submitted is false
	// 		return `${columnWidths[header].percent}%`;
	// 	  }
	// 	} else {
	// 	  return 'auto';
	// 	}
	// }

	return (
		<div className={props.className} ref={props.ref}>
			<table style={{ width: fixed ? "auto" : "100%", textAlign: textAlign, tableLayout: fixed ? "fixed" : "auto" }}>
				<thead>
					<tr>
						{headers &&
							headers.map((header, index) => {
								let widthValue = "auto";
								if (columnWidths.hasOwnProperty(header)) {
									widthValue = typeof columnWidths[header] === "number" ? `${columnWidths[header]}%` : columnWidths[header];
								}
								return (
									<th
										key={index}
										className={props.classNameHeader}
										style={{
											textAlign: index === 0 ? " left" : "center",
											width: widthValue,
										}}
									>
										{header}
									</th>
								);
							})}
					</tr>
				</thead>
				<tbody>
					{data.map((row, index) => {
						return (
							<tr key={index} className={props.classNameRow}>
								{children.map((child, index2) => {
									let shouldRender = true;
									if (child?.props?.hasOwnProperty("active") && child?.props?.active === false) {
										shouldRender = false;
									}
									let cellProps = {
										rowIndex: index,
										rowData: row,
										container: { _data: data },
										cellData: row[child?.props?.dataKey],
									};
									if (shouldRender) {
										if (child?.props?.cellRenderer) {
											return (
												<td
													key={index2}
													style={{
														boxSizing: "border-box",
														width: child?.props?.width ? `${child.props.width}%` : "auto",
														textAlign: child?.props?.textAlign ? child.props.textAlign : "left",
													}}
												>
													{child.props.cellRenderer(cellProps)}
												</td>
											);
										} else {
											return <td key={index2}>{cellProps.cellData}</td>;
										}
									}
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export const Column = (props) => {
	const { title, flexGrow } = props;

	return (
		<div style={{ flexGrow: flexGrow }}>
			{/* {cellRenderer && cellRenderer(props)} */}
			{title}
		</div>
	);
};
