import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";
import styles from "../../styleModules/simulationStyles.module.css";
import { useSpeechSynthesizer } from "../SecondaryComponents/useSpeechSynthesizer";
import { useSpeechRecognizer } from "../SecondaryComponents/useSpeechRecognizer";
import { useAuraSpeechSynthesizer } from "../SecondaryComponents/useAuraSpeechSynthesizer";
import { useVapiCall } from "../SecondaryComponents/useVapiCall";

import useLiveData from "../Simulation/UseLiveData.js";
import { useSocket } from "../Simulation/UseSocket.js";
import { sleep } from "../UtilityFunctions/sleep.js";
import { useDeviceSettings } from "../UtilityFunctions/useDeviceSettings.js";

const sdk = require("microsoft-cognitiveservices-speech-sdk");

function AudioTest() {
	const audioContext = useRef(null);
	const userMediaStreamRef = useRef(null);
	const activeSessionDataRef = useRef(null);
	const scriptNodeRef = useRef(null);
	const microphoneRef = useRef(null);
	const [auraSpeakText, setAuraSpeakText] = useState(""); // Add this line to create a state for the input text
	const [selectedVoice, setSelectedVoice] = useState("aura-luna-en"); // Add this line to create a state for the selected voice
	const [checkboxState, setCheckboxState] = useState(false);

	const [messageToSend, setMessageToSend] = useState("");

	const voices = useMemo(
		() => [
			{ label: "Luna", value: "aura-luna-en" },
			{ label: "Stella", value: "aura-stella-en" },
			{ label: "Athena", value: "aura-athena-en" },
			{ label: "Hera", value: "aura-hera-en" },
			{ label: "Orion", value: "aura-orion-en" },
			{ label: "Arcas", value: "aura-arcas-en" },
			{ label: "Perseus", value: "aura-perseus-en" },
			{ label: "Angus", value: "aura-angus-en" },
			{ label: "Orpheus", value: "aura-orpheus-en" },
			{ label: "Helios", value: "aura-helios-en" },
			{ label: "Zeus", value: "aura-zeus-en" }
		],
		[]
	);
	const deepGramOnRef = useRef(false);

	const [showButtons, setShowButtons] = useState(false);
	const [deepgramTranscript, setDeepgramTranscript] = useState("");

	const [audioContextstate, setAudioContextstate] = useState("null");
	useEffect(() => {
		audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
		audioContext.current.onstatechange = () => {
			console.log("The AudioContext state has changed to:", audioContext.current.state);
			setAudioContextstate(audioContext.current.state);
		};
	}, []);

	const {
		isCallActive: vapiIsCallActive,
		message: vapiMessage,
		startCall: vapiStartCall,
		sendMessage: vapiSendMessage,
		stopCall: vapiStopCall,
		toggleMute: vapiToggleMute,
		isMuted: vapiIsMuted
	} = useVapiCall();
	const { selectedDevices, GetMedia, mediaInitialized } = useDeviceSettings();

	const { isListening, interimTranscript, transcript: azureTranscript, message, startListening, stopListening } = useSpeechRecognizer();

	const { speak } = useAuraSpeechSynthesizer();
	// Renaming the functions while destructuring
	const {
		speakV2,
		speak: startSpeaking,
		pause: pauseAudio,
		resume: resumeAudio,
		status
	} = useSpeechSynthesizer({ audioContext, handleVisemeReceived, handleWordReceived });
	const { connectionStatus, deepgramStatus, sendMessage, addEventListener, removeEventListener } = useSocket({
		getTimeStamp: () => new Date().toLocaleTimeString(),
		url: process.env.REACT_APP_BACKEND_LIVE_URL,
		stopDeepGram,
		handleDeepgramConnection,

		handleGptResponse: function handleGptResponse() {},
		handleSpeechStarted: function handleSpeechStarted() {},
		handleUtteranceEnd: function handleUtteranceEnd() {},
		handleTranscript: function handleTranscript({ transcript, duration, is_final, speech_final }) {
			setDeepgramTranscript(transcript);
			if (speech_final) {
				//	speakV2(1, "test for delayed audio playback");
			}
		},
		handleConnect: function handleConnect() {},
		handleDisconnect,
		handleGptUserStatementComplete: function handleGptUserStatementComplete() {}
	});

	function handleVisemeReceived(viseme) {}
	function handleWordReceived(word) {}
	// console.log(sdk.SpeechSynthesisOutputFormat);
	const buttons = Array.from({ length: 37 }, (_, index) => (
		<div key={sdk.SpeechSynthesisOutputFormat[index]}>
			<button
				type="button"
				key={index + sdk.SpeechSynthesisOutputFormat[index]}
				onClick={() => {
					const formatName = sdk.SpeechSynthesisOutputFormat[index];
					const formattedName = formatName.replace(/[0-9]/g, " $&").trim();
					if (audioContext.current.state === "suspended") {
						audioContext.current.resume().then(() => {
							startSpeaking(0, `This is a test for voice number ${formattedName}`, "Nina", 0, index);
						});
					} else {
						startSpeaking(0, `This is a test for voice number ${formattedName}`, "Nina", 0, index);
					}
				}}
			>
				Voice {sdk.SpeechSynthesisOutputFormat[index]}
			</button>
			{index % 2 === 0 && <br />}
		</div>
	));

	/**
	 * handleDisconnect is a function that is called when a connection is disconnected.
	 * It sets the connection status to 'Disconnected'.
	 */
	function handleDisconnect() {}

	async function handleDeepgramConnection(connected) {
		if (connected) {
			// If media stream has a video track, create a new stream with just the audio track
			let audioStream;
			if (userMediaStreamRef.current.getVideoTracks().length > 0) {
				const audioTracks = userMediaStreamRef.current.getAudioTracks();

				// console.log('Microphone device: ' + audioTracks[0].label);
				// console.log('Sample rate: ' + audioTracks[0].getSettings().sampleRate);

				audioStream = new MediaStream(audioTracks);
			} else {
				audioStream = userMediaStreamRef.current;
				const audioTracks = userMediaStreamRef.current.getAudioTracks();
			}
			//  console.log("startDeepGram track: ", audioStream.getTracks());

			// vad.start();

			microphoneRef.current = new MediaRecorder(audioStream);
			// processStream(audioStream);
			scriptNodeRef.current = {};
			scriptNodeRef.current.startRecording = false;
			// scriptNodeRef.current.node = myWorkletNode;
			// scriptNodeRef.current.source = source;
			scriptNodeRef.current.printCount = 0;
			await microphoneRef.current.start(500);
			microphoneRef.current.onstart = () => {
				// console.log("client: microphone opened");
			};

			microphoneRef.current.onstop = () => {
				// console.log("client: microphone closed");
				// finishAudioFile()
			};

			microphoneRef.current.ondataavailable = (e) => {
				// console.log("client: microphone data available",e.data?.size);
				if (deepGramOnRef.current === false) {
					console.error("cant send data to deepgram since not connected");
					return;
				}
				const audioBlob = e.data;

				//    audioBitsRef.current.push(audioBlob);

				//  console.log("client: sent data to websocket",e.data.size);

				try {
					let { printCount } = scriptNodeRef.current;
					if (e.data.size > 0) {
						if (printCount < 3) {
							// console.log("sending packet", e.data);
							const reader = new FileReader();
							reader.onload = function () {
								// const arrayBuffer = this.result;
								//  const array = new Uint8Array(arrayBuffer);
								// console.log(array.slice(0, 40));
							};
							reader.readAsArrayBuffer(e.data);
							printCount += 1;
							scriptNodeRef.current.printCount = printCount;
						}

						sendMessage("packet-sent", e.data);
					} else if (e.data.size === 0) {
						console.error("cant send blank data to deepgram", e.data);
					}
				} catch (error) {
					console.error(error);
				}
			};
			// setMicrophoneStarted(true);
		} else {
			deepGramOnRef.current = false;
		}
	}

	/**
	 * Starts the DeepGram service.
	 *
	 * This function sets the deepGramOnRef.current to true, emits a "connect-deepgram" event to the socket,
	 * and starts the MediaRecorder with a timeslice of 500ms. It also sets up event handlers for the MediaRecorder's
	 * start, stop, and dataavailable events. If the dataavailable event is fired and deepGramOnRef.current is false,
	 * the function returns early. If the dataavailable event is fired and the data size is greater than 0, it emits
	 * a "packet-sent" event to the socket with the data. If the data size is 0, it logs an error.
	 *
	 * Finally, it sets the microphoneStarted state to true.
	 *
	 * TODO: Add more information if needed.
	 *
	 * @async
	 */
	async function startDeepGram() {
		await GetMedia(userMediaStreamRef, false, () => {});

		console.log("startDeepGram");
		deepGramOnRef.current = true;
		sendMessage("connect-deepgram");
		// setAllowSimControls(true);

		await sleep(500);
		if (deepGramOnRef.current === false) {
			sendMessage("connect-deepgram");
		}
		await sleep(500);
	}

	/**
	 * Stops the DeepGram service.
	 *
	 * This function sets the deepGramOnRef.current to false, stops the MediaRecorder if it's running,
	 * emits a "disconnect-deepgram" event to the socket, and sets the microphoneStarted state to false.
	 *
	 * @async
	 */
	async function stopDeepGram() {
		console.log("stopDeepGram");

		// Set deepGramOnRef.current to false
		deepGramOnRef.current = false;
		if (scriptNodeRef.current) {
			if (scriptNodeRef.current.node) {
				scriptNodeRef.current.node.port.onmessage = undefined;
				scriptNodeRef.current.node.disconnect();
				scriptNodeRef.current.node = undefined;
			}
			if (scriptNodeRef.current.source) {
				scriptNodeRef.current.source.disconnect();
				scriptNodeRef.current.source = undefined;
			}
		}

		// If the MediaRecorder is running, stop it
		if (microphoneRef.current) {
			microphoneRef.current.stop();
			microphoneRef.current.onstart = undefined;
			microphoneRef.current.onstop = undefined;
			microphoneRef.current.ondataavailable = undefined;
			microphoneRef.current = undefined;
		}

		try {
			// Emit a "disconnect-deepgram" event to the socket
			sendMessage("disconnect-deepgram");
		} catch (e) {
			// Log any errors
			console.error(e);
		}

		// Set the microphoneStarted state to false
		// setMicrophoneStarted(false);
	}
	return (
		<>
			<Helmet>
				<title>InStage Admin</title>
				<meta name="description" content="Admin Page" />
				<meta name="robots" content="noindex" />
			</Helmet>

			<div
				className={styles.simPage}
				style={{
					padding: "20px 25% 0 90px",
					zIndex: 1
				}}
			>
				Test page <br />
				<p>
					Deepgram Transcript:
					<span style={{ border: deepgramTranscript === "" ? "" : "2px solid blue" }}>{deepgramTranscript}</span>
				</p>
				<p>
					azure Transcript:
					<span style={{ border: interimTranscript === "" ? "" : "2px solid green" }}>{interimTranscript}</span>
				</p>
				<table style={{ border: "1px solid black" }}>
					<thead style={{ border: "1px solid black" }}>
						<tr>
							<th>Parameter</th>
							<th>Value</th>
						</tr>
					</thead>
					<tbody>
						<tr style={{ border: "1px solid black" }}>
							<td>Audio Context State:</td>
							<td>{audioContextstate}</td>
						</tr>
						<tr style={{ border: "1px solid black" }}>
							<td>Status:</td>
							<td>{status}</td>
						</tr>
						<tr style={{ border: "1px solid black" }}>
							<td>Websocket Status:</td>
							<td>{connectionStatus}</td>
						</tr>
						<tr style={{ border: "1px solid black" }}>
							<td>Deepgram Status:</td>
							<td>{deepgramStatus}</td>
						</tr>
					</tbody>
				</table>
				<br />
				<button
					type="button"
					onClick={() => {
						speak(auraSpeakText, selectedVoice, checkboxState);
					}}
				>
					aura speak
				</button>
				<input type="checkbox" id="transcribeCheck" checked={checkboxState} onChange={(e) => setCheckboxState(e.target.checked)} />
				<label htmlFor="transcribeCheck">:transcribe</label>
				<input
					type="text"
					value={auraSpeakText}
					onChange={(e) => setAuraSpeakText(e.target.value)} // Add this input element to capture the text
					placeholder="Enter text for Aura to speak"
				/>
				<select
					value={selectedVoice}
					onChange={(e) => setSelectedVoice(e.target.value)} // Add this select element to choose the voice
				>
					{voices.map((option, index) => (
						<option key={`${option.label}`} value={option.value}>
							{option.label}
						</option>
					))}
				</select>
				<br />
				<br />
				<div>
					{!vapiIsCallActive && (
						<button type="button" onClick={vapiStartCall}>
							Start Call
						</button>
					)}
					{vapiIsCallActive && (
						<button type="button" onClick={vapiStopCall}>
							Stop Call
						</button>
					)}
					<button type="button" onClick={vapiToggleMute}>
						{vapiIsMuted ? "Unmute" : "Mute"}
					</button>
					<input type="text" value={messageToSend} onChange={(e) => setMessageToSend(e.target.value)} placeholder="Enter message to send" />
					<button
						type="button"
						onClick={() => {
							vapiSendMessage(messageToSend);
							setMessageToSend("");
						}}
					>
						Send Message
					</button>
					<br />
					<p style={{ border: vapiMessage === "" ? "" : "2px solid green" }}> vapiMessage : {vapiMessage}</p>
				</div>
				<br />
				{deepgramStatus === "Connected" && (
					<button
						type="button"
						onClick={() => {
							stopDeepGram();
						}}
					>
						Stop DeepGram
					</button>
				)}
				{deepgramStatus === "Disconnected" && (
					<button
						type="button"
						onClick={() => {
							startDeepGram();
						}}
					>
						Start DeepGram
					</button>
				)}
				<br />
				<button
					type="button"
					onClick={() => {
						console.log("audioContext.current.state", audioContext.current.state);
						if (audioContext.current.state === "suspended") {
							audioContext.current.resume().then(() => {
								speakV2(0, "v1 this is a test for playing audio");
							});
						} else {
							speakV2(0, "this is a test for playing audio");
						}

						// setTimeout(()=>{startSpeaking(1,"this is a test to verify there is no popping sounds!")},3040)
					}}
				>
					Speak
				</button>
				<br />
				{isListening === true && (
					<button
						type="button"
						onClick={() => {
							stopListening();
						}}
					>
						Stop stt
					</button>
				)}
				{isListening === false && (
					<button
						type="button"
						onClick={() => {
							startListening();
						}}
					>
						Start Azure stt
					</button>
				)}
				<br />
				<input type="checkbox" id="showButtons" name="showButtons" value={showButtons} onChange={(e) => setShowButtons(!showButtons)} />
				<label htmlFor="showButtons">:show buttons</label>
				<br />
				{showButtons && buttons}
				{/* {status !== "paused" && <button onClick={pauseAudio}>Pause</button>}
				{status === "paused" && <button onClick={resumeAudio}>Resume</button>} */}
			</div>
		</>
	);
}
export default AudioTest;
