import React, { useMemo, useState, useEffect, useContext, createContext } from "react";
import { openDB } from "idb";

// Create a context for the device settings
const DeviceSettingsContext = createContext(null);
async function initDB() {
	return openDB("simulation", 1, {
		upgrade(db) {
			db.createObjectStore("simulation_db");
		}
	});
}
// Provider component that wraps your app and makes the device settings object available to any child component that calls `useDeviceSettings()`.
export function DeviceSettingsProvider({ children }) {
	const [devices, setDevices] = useState({ video: [], audio: [], speaker: [] });
	const [selectedDevices, setSelectedDevices] = useState({ video: null, audio: null, speaker: null });
	const [audioSettings, setAudioSettings] = useState({ gainControl: false, noiseSuppression: false });

	const [mediaInitialized, setMediaInitialized] = useState(false);

	const initializeSettings = async (videoOn = false) => {
		const db = await initDB();

		const savedVideoDevice = await db.get("simulation_db", "saved_video_device");
		const savedAudioDevice = await db.get("simulation_db", "saved_audio_device");
		const savedSpeakerDevice = await db.get("simulation_db", "saved_speaker_device");

		// console.log(savedVideoDevice, savedAudioDevice, savedSpeakerDevice);
		const savedAudioSettings = await db.get("simulation_db", "audioSettings");

		navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
			const videoDevices = deviceInfos.filter((device) => device.kind === "videoinput");
			const audioDevices = deviceInfos.filter((device) => device.kind === "audioinput");
			const speakerDevices = deviceInfos.filter((device) => device.kind === "audiooutput");

			// Automatically set the default devices if available
			const defaultVideoDevice = videoDevices[0] ? { value: videoDevices[0].deviceId, label: videoDevices[0].label } : null;
			const defaultAudioDevice = audioDevices[0] ? { value: audioDevices[0].deviceId, label: audioDevices[0].label } : null;
			const defaultSpeakerDevice = speakerDevices[0] ? { value: speakerDevices[0].deviceId, label: speakerDevices[0].label } : null;
			setDevices({
				video: videoDevices.map((device) => ({ value: device.deviceId, label: device.label })),
				audio: audioDevices.map((device) => ({ value: device.deviceId, label: device.label })),
				speaker: speakerDevices.map((device) => ({ value: device.deviceId, label: device.label }))
			});

			const savedDeviced = {
				video: defaultVideoDevice,
				audio: defaultAudioDevice,
				speaker: defaultSpeakerDevice
			};

			// Set saved devices if they exist
			if (savedVideoDevice) {
				const videoDevice = videoDevices.find((device) => device.deviceId === savedVideoDevice.value);
				savedDeviced.video = videoDevice ? { value: videoDevice.deviceId, label: videoDevice.label } : savedDeviced.video;
				// console.log("we have saved video", videoDevices, savedDeviced.video);
			}
			if (savedAudioDevice) {
				const audioDevice = audioDevices.find((device) => device.deviceId === savedAudioDevice.value);
				savedDeviced.audio = audioDevice ? { value: audioDevice.deviceId, label: audioDevice.label } : savedDeviced.audio;
				// console.log("we have saved audio", audioDevices, savedDeviced.audio);
			}
			if (savedSpeakerDevice) {
				const speakerDevice = speakerDevices.find((device) => device.deviceId === savedSpeakerDevice.value);
				savedDeviced.speaker = speakerDevice ? { value: speakerDevice.deviceId, label: speakerDevice.label } : savedDeviced.speaker;
			}
			// Set saved audio settings if they exist
			if (savedAudioSettings) {
				setAudioSettings(savedAudioSettings);
			}

			setSelectedDevices(savedDeviced);
			setMediaInitialized(true);
		});
	};
	async function GetMedia(userMediaStreamRef, askforVideo, setVideoOn) {
		console.log("getMEDIA", askforVideo);
		try {
			const audioDeviceId = selectedDevices.audio && selectedDevices.audio.value !== "" ? { exact: selectedDevices.audio.value } : undefined;
			let videoDeviceId = selectedDevices.video && selectedDevices.video.value !== "" ? { exact: selectedDevices.video.value } : undefined;
			console.log("videoDeviceId", videoDeviceId);
			console.log("audioDeviceId", audioDeviceId);
			const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
			if (isIOS) {
				videoDeviceId = undefined;
			}
			const mediaStream = await navigator.mediaDevices.getUserMedia({
				audio: {
					deviceId: audioDeviceId,
					// ...additionalAudioConstraints,
					channelCount: 1,
					echoCancellation: true,
					autoGainControl: true,
					noiseSuppression: true
				},
				video: videoDeviceId
					? {
							deviceId: videoDeviceId
					  }
					: askforVideo
			});
			console.log("setting media stream", mediaStream, mediaStream.getTracks());
			userMediaStreamRef.current?.getTracks().forEach((track) => console.log(track));
			// eslint-disable-next-line no-param-reassign
			userMediaStreamRef.current = mediaStream;
			// setStream(mediaStream);
		} catch (error) {
			if (askforVideo) {
				console.error(error);
				const audioDeviceId =
					selectedDevices.audio && selectedDevices.audio.value !== "" ? { exact: selectedDevices.audio.value } : undefined;

				const mediaStream = await navigator.mediaDevices.getUserMedia({
					audio: {
						deviceId: audioDeviceId,

						// ...additionalAudioConstraints,
						channelCount: 1,
						echoCancellation: true,
						autoGainControl: true,
						noiseSuppression: true
					},
					video: false
				});
				setVideoOn(false);
				console.log("setting media stream no vid", mediaStream, mediaStream.getTracks());

				userMediaStreamRef.current?.getTracks().forEach((track) => console.log(track));
				// eslint-disable-next-line no-param-reassign
				userMediaStreamRef.current = mediaStream;
			}
			console.error("Error accessing media devices.", error);
		}
	}
	useEffect(() => {
		// console.log(selectedDevices);
	}, [selectedDevices]);
	useEffect(() => {
		// console.log("devices", devices);
	}, [devices]);

	/**
	 * Asynchronously saves the selected device setting to the IndexedDB.
	 * It updates the state with the new selected device for the given type.
	 *
	 * @param {string} type The type of device to save (e.g., 'video', 'audio', 'speaker').
	 * @param {Object} value The device object containing the deviceId and label.
	 */
	const saveDeviceSetting = async (type, value) => {
		// Update the selected devices state with the new value
		setSelectedDevices((prev) => ({
			...prev,
			[type]: value
		}));

		// Open the IndexedDB database
		const db = await openDB("simulation", 1);

		// Save the device setting in the 'simulation_db' object store
		await db.put("simulation_db", value, `saved_${type}_device`);
	};
	// Any other logic and functions you need
	// Memoize the context value
	const contextValue = useMemo(
		() => ({
			mediaInitialized,
			initializeSettings,
			GetMedia,
			devices,
			selectedDevices,
			audioSettings,
			setDevices,
			setSelectedDevices,
			setAudioSettings,
			saveDeviceSetting
			// Any other state or functions you want to expose
		}),
		[devices, selectedDevices, audioSettings, selectedDevices.audio, selectedDevices.video]
	);

	return <DeviceSettingsContext.Provider value={contextValue}>{children}</DeviceSettingsContext.Provider>;
}

// Hook for child components to get the device settings object and re-render when it changes.
export const useDeviceSettings = (videoOn = false) => {
	const context = useContext(DeviceSettingsContext);
	if (context === undefined) {
		throw new Error("useDeviceSettings must be used within a DeviceSettingsProvider");
	}
	useEffect(() => {
		if (!context.devices.video.length && !context.devices.audio.length && !context.devices.speaker.length) {
			context.initializeSettings(videoOn);
		}
	}, [context]);
	return context;
};
