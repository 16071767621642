import React, { useState, useEffect } from "react";
import useTypewriterEffect from "../SimulationOld/useTypewriterEffect.js";
import styles from "./TypingText.module.css";

function TypingText({ refHolder, typingSpeed, children, onComplete, skipable = true }) {
	let text = "";
	if (typeof children === "string") {
		text = children;
	}
	const [index, setIndex] = useState(-1);
	const [start, setStart] = useState(false);
	const [delay, setDelay] = useState(0);

	const [typedText, completed] = useTypewriterEffect(text, start, typingSpeed, true, delay, skipable);

	useEffect(() => {
		if (refHolder) {
			let indexTemp = 0;
			if (!refHolder.current) {
				refHolder.current = [];
				indexTemp = 0;
			} else {
				indexTemp = refHolder.current.length;
			}

			refHolder.current.push({ text, completed: false });
			setIndex(indexTemp);
			// console.log(refHolder.current);
		}
		return () => {
			if (refHolder && refHolder.current) {
				// remove index from refHolder
				refHolder.current.splice(index, 1);
			}
		};
	}, []);

	useEffect(() => {
		let interval;
		if (index !== -1 && refHolder && refHolder.current) {
			if (index === 0) {
				setDelay(1500);
				setStart(true);
			} else {
				interval = setInterval(() => {
					// console.log(refHolder.current[index-1])
					if (refHolder.current[index - 1].completed) {
						setStart(true);
					}
					if (refHolder.current[index].completed) {
						clearInterval(interval);
					}
				}, 100);
			}
		}
		return () => clearInterval(interval);
	}, [index, refHolder]);

	useEffect(() => {
		if (completed) {
			if (refHolder && refHolder.current && refHolder.current[index]) {
				if (refHolder.current[index].completed === false) {
					if (onComplete) {
						onComplete();
					}
					refHolder.current[index].completed = true;
				}
			}
		}
	}, [completed, refHolder, index, onComplete]);

	// use effect that runs only once
	useEffect(() => {
		if (children) {
			// console.log(children)
			// console.log(typeof(children))
			if (typeof children === "object") {
				// console.log(children.length)
			}
		}
	}, [children]);

	if (typeof children === "string") {
		return (
			<span>
				{typedText}
				{!completed && start && <span className={styles.blinking_caret} />}
			</span>
		);
	}

	return <>{start && <>{children}</>}</>;
}

export default TypingText;
