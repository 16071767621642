import React, { useState, useEffect, useContext } from "react";
import { FaCheck, FaTimes } from "react-icons/fa/index.esm.js";
import { FiChevronRight } from "react-icons/fi/index.esm.js";
import r from "../../styleModules/reportStyles.module.css";
import checkmark from "../../images/checkmark.png";
import { CommentIcon } from "../SecondaryComponents/CommentModal.js";
import { cleanResponse } from "../UtilityFunctions/cleanResponse.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";

export default function QuestionAnswerTable({ feedbackArray }) {
	const statusOptions = ["No", "Partial", "Yes"];

	const { userLanguage, performTranslation, tenantMetaData, runTranslation } = useContext(AppContext);

	// Static Translation
	const LocalLanguageDict = {
		Satisfactory: "Satisfactory",
		Unsatisfactory: "Unsatisfactory",
		Exceptional: "Exceptional",
		Feedback: "Feedback",
		Dialogue: "Dialogue"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict
	});

	useEffect(() => {
		runTranslation();
		// console.log("Run Translation");
	}, [userLanguage]);

	// Usage
	useEffect(() => {
		const translateText = async () => {
			await performTranslation(pageText, setPageText);
		};
		translateText();
	}, [userLanguage, feedbackArray]);

	const [feedbackArrayTranslated, setFeedbackArrayTranslated] = useState([feedbackArray]);

	const translatePoints = async (points, setTranslatedPoints) => {
		if (!points) {
			return;
		}
		const textsToTranslate = {};
		points.forEach((point) => {
			textsToTranslate[point.question] = point.question;
			if (point.feedback) {
				textsToTranslate[point.feedback] = point.feedback; // Add feedback to the texts to translate
			}
			if (point.statement) {
				textsToTranslate[point.statement] = point.statement;
			}
		});

		await performTranslation(textsToTranslate, (translatedTexts) => {
			const translatedPoints = points.map((point) => ({
				...point,
				question: translatedTexts[point.question],
				feedback: translatedTexts[point.feedback],
				statement: translatedTexts[point.statement]
			}));

			setTranslatedPoints(translatedPoints);
			// console.log("TranslatedPoints: ", translatedPoints);
		});
	};

	useEffect(() => {
		// console.log("FeedbackArrayOriginal: ", feedbackArray);
		translatePoints(feedbackArray, setFeedbackArrayTranslated);
	}, [userLanguage, feedbackArray]);

	// Additional useEffect to log the translated points after state update
	// useEffect(() => {
	// 	console.log("ProcessPointsTranslated: ", setFeedbackArrayTranslated);
	// }, [feedbackArrayTranslated]);

	const statusDict = {
		No: {
			text: pageText.Unsatisfactory,
			darkColor: "#7d7d7d",
			lightColor: "#AFAFAF",
			score: 1
		},
		Partial: {
			text: pageText.Satisfactory,
			darkColor: "#87BAC0",
			lightColor: "#4BA6AD",
			score: 2
		},
		Yes: {
			text: pageText.Exceptional,
			darkColor: "#4BA6AD",
			lightColor: "#87BAC0",
			score: 3
		}
	};

	const [expandedRows, setExpandedRows] = useState([]);
	const [hoveredRow, setHoveredRow] = useState(null);

	useEffect(() => {
		// Initialize expandedRows state with false values
		// console.log("FeedbackArray: ", feedbackArray);
		setExpandedRows(feedbackArray.map(() => false));
	}, [feedbackArray]);

	const toggleRow = (index) => {
		const newExpandedRows = [...expandedRows];
		newExpandedRows[index] = !newExpandedRows[index];
		setExpandedRows(newExpandedRows);
	};

	return (
		<div className={r.reportTable}>
			{feedbackArrayTranslated.map((item, index) => {
				if (item.question && item.statement) {
					return (
						<React.Fragment key={`feedback-${item.question}`}>
							<div className={`${r.reportTableRow} ${expandedRows[index] ? r.reportTableToggledRow : ""}`}>
								<div
									role="button"
									tabIndex={0}
									className={`${r.reportTableHeader} ${expandedRows[index] ? r.reportTableToggledHeader : ""}`}
									onMouseEnter={() => setHoveredRow(index)}
									onMouseLeave={() => setHoveredRow(null)}
									onClick={() => toggleRow(index)}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											toggleRow(index);
										}
									}}
								>
									<div
										className={r.questionColumn}
										style={{ width: expandedRows[index] ? "95%" : window.innerWidth > 1000 ? "65%" : "50%" }}
									>
										{cleanResponse(item.question)}
									</div>
									<div
										className={r.scoreColumn}
										style={{ width: expandedRows[index] ? "0%" : window.innerWidth > 1000 ? "30%" : "45%" }}
									>
										{!expandedRows[index] && (
											<div className={r.scoreContainer} style={{ backgroundColor: statusDict[item.status]?.darkColor }}>
												<div className={r.scoreIconCircle} style={{ backgroundColor: statusDict[item.status]?.lightColor }}>
													{item.status !== "No" && item.status !== "Novice" ? (
														<FaCheck className={r.scoreIcon} />
													) : (
														<FaTimes className={r.scoreIcon} />
													)}
												</div>
												{statusDict[item.status]?.text}
											</div>
										)}
									</div>
									<div className={r.chevronColumn}>
										<FiChevronRight className={expandedRows[index] || hoveredRow === index ? r.chevronDown : r.chevronRight} />
									</div>
								</div>
								{expandedRows[index] && (
									<div className={r.reportTableDropdown}>
										<div className={r.reportTableDropdownTop}>
											<div className={r.reportTableTranscript}>
												<p className={r.reportTableDropdownTitle}>{pageText.Dialogue}:</p>
												<p className={r.reportTableTranscriptText}>
													{item.statement !== null &&
													item.statement !== "" &&
													item.statement !== "null" &&
													item.statement !== undefined
														? `"${item.statement}"`
														: "-"}
												</p>
											</div>
											<div className={r.scoreBreakdown}>
												{[...statusOptions].reverse().map((status, index) => (
													<div
														key={index}
														className={r.scoreContainer}
														style={{ backgroundColor: statusDict[status]?.darkColor }}
													>
														<div
															className={r.scoreIconCircle}
															style={{
																backgroundColor:
																	item.status === status ? statusDict[status]?.lightColor : "transparent"
															}}
														>
															{item.status === status &&
																(item.status === "No" || item.status === "Novice" ? (
																	<FaTimes className={r.scoreIcon} />
																) : (
																	<FaCheck className={r.scoreIcon} />
																))}
														</div>
														{statusDict[status].text}
													</div>
												))}
											</div>
										</div>
										<div className={r.reportTableFeedback}>
											<div className={r.statusGradeFeedbackContainer}>
												<p className={r.reportTableDropdownTitle}>{pageText.Feedback}:</p>
												<p className={r.statusGradeFeedbackText}>{item.feedback}</p>
											</div>
										</div>
									</div>
								)}
							</div>
						</React.Fragment>
					);
				}
			})}
		</div>
	);
}
