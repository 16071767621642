import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { useAxiosLimited, axiosRetryGet } from "../UtilityFunctions/axiosRetry.js";
import Select from "react-select";
import debounce from "lodash.debounce";
import { useFeatureFlagEnabled } from "posthog-js/react";
import LanguageDict from "./LanguageDict.js";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { useAuth } from "@frontegg/react";

const url = process.env.REACT_APP_BACKEND_STATIC_URL;

const customStyles = {
	control: (provided, state) => ({
		...provided,
		height: "2.75rem",
		borderRadius: "0.5rem",
		border: "1px solid var(--gray-300, #d0d5dd)",
		backgroundColor: "var(--base-white, #fff)",
		boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
		cursor: "pointer",
		outline: state.isFocused ? "2px solid #00a9af" : "none",
		"&:hover": {
			outline: "2px solid #00a9af"
		},
		transition: "none" // Ensure no transitions
	}),
	input: (provided) => ({
		...provided,
		margin: "0", // Remove any margin that might be applied by default
		cursor: "text",
		transition: "none" // Ensure no transitions
	}),
	placeholder: (provided) => ({
		...provided,
		color: "#aaaaaa", // Customize the placeholder text color
		transition: "none" // Ensure no transitions
	})
};

export default function JobTitleSelect({ selectRef, selectedJobTitle, setSelectedJobTitle, handleTitleChange, handleDetailsChange, setJobOptions }) {
	const { axiosLimitedGet } = useAxiosLimited();
	const monsterSearchEnabled = useFeatureFlagEnabled("monsterSearch");

	const [options, setOptions] = useState([]);
	const [inputValue, setInputValue] = useState(selectedJobTitle?.value);
	const { userLanguage, translateSingleText } = useContext(AppContext);

	const [placeHolderJobTitleText, setPlaceHolderJobTitleText] = useState(LanguageDict.InterviewJobTitlePlaceholder);
	const [placeHolderJobTitleDropDownText, setPlaceHolderJobTitleDropDownText] = useState(LanguageDict.InterviewJobTitleDropDownPlaceholder);

	useEffect(() => {
		const translateText = async () => {
			const translatedTitle = await translateSingleText(placeHolderJobTitleText, userLanguage);
			const translatedDropDown = await translateSingleText(placeHolderJobTitleDropDownText, userLanguage);
			setPlaceHolderJobTitleText(translatedTitle);
			setPlaceHolderJobTitleDropDownText(translatedDropDown);
		};

		translateText();
	}, [userLanguage]);

	const fetchJobTitles = useCallback(
		debounce(async (input) => {
			try {
				const response = await axiosLimitedGet(`${url}/api/interview/job-titles`, 1, {
					params: { q: input }
				});
				const jobTitles = response.data.map((job) => ({ label: job.name, value: job.name }));
				// Update the options, ensuring the user's input is the first option if it's not empty
				setOptions((prevOptions) => {
					if (input) {
						const userInputOption = { label: input, value: input };
						return [userInputOption, ...jobTitles];
					}
					return jobTitles;
				});
			} catch (error) {
				console.error("Failed to fetch job titles", error);
			}
		}, 200),
		[]
	);

	const searchJobs = useCallback(
		async (input) => {
			if (!monsterSearchEnabled || !input || input.length === 0) {
				setJobOptions([]);
				return;
			}
			const response = await axiosLimitedGet(`${url}/api/monster/search-jobs`, 1, {
				params: { title: input }
			});
			// console.log("Job options", response.data);
			setJobOptions(response.data);
		},
		[setJobOptions, monsterSearchEnabled]
	);

	const memoizedHandleTitleChange = useCallback(handleTitleChange, []);
	const memoizedHandleDetailsChange = useCallback(handleDetailsChange, []);

	const handleInputChange = (newValue) => {
		setInputValue(newValue);
		// Immediately set the user's input as the first option if it's not empty
		if (newValue) {
			setOptions([{ label: newValue, value: newValue }]);
		} else {
			setOptions([]);
		}
	};

	const handleChange = (selectedOption) => {
		setJobOptions([]);
		if (selectedOption?.value !== selectedJobTitle?.value) {
			setSelectedJobTitle(selectedOption);
			searchJobs(selectedOption?.value || "");
			memoizedHandleTitleChange({ target: { value: selectedOption ? selectedOption.value : "" } });
		}
	};

	const handleKeyDown = (event) => {
		if (event.key === "Enter" && inputValue) {
			setSelectedJobTitle({ label: inputValue, value: inputValue });
			memoizedHandleTitleChange({ target: { value: inputValue } });

			// Programmatically blur the select component to close the dropdown
			if (selectRef.current) {
				selectRef.current.blur();
			}
		}
	};

	const handleBlur = () => {
		if (inputValue && inputValue !== selectedJobTitle?.value) {
			setSelectedJobTitle({ label: inputValue, value: inputValue });
			searchJobs(inputValue);
			memoizedHandleTitleChange({ target: { value: inputValue } });
		}
	};

	useEffect(() => {
		if (inputValue && inputValue !== selectedJobTitle?.value) {
			fetchJobTitles(inputValue);
		} else {
			setOptions([]);
			// setJobOptions([]);
		}
	}, [inputValue, selectedJobTitle, fetchJobTitles]);

	useEffect(() => {
		memoizedHandleDetailsChange({ target: { value: "" } });
	}, [selectedJobTitle, memoizedHandleDetailsChange]);

	return (
		<Select
			ref={selectRef}
			value={selectedJobTitle}
			onInputChange={handleInputChange}
			onChange={handleChange}
			options={options}
			noOptionsMessage={({ input }) => placeHolderJobTitleDropDownText}
			placeholder={placeHolderJobTitleText}
			isClearable
			onKeyDown={handleKeyDown}
			onBlur={handleBlur}
			styles={customStyles}
			getOptionLabel={(option) => (option.value === inputValue ? `${option.label} (custom title)` : option.label)}
		/>
	);
}
